
<div class="card">

    <div class="card-header">
      <p class="text-uppercase display-7 lead">
        <strong>Compila il Questionario</strong>
      </p>
    </div>

    <div class="card-body">
      <app-spinner *ngIf="!surveyFormReady" status="loading"></app-spinner>
      <form *ngIf="surveyFormReady" [formGroup]="surveyForm">

        <ng-container class="text-bold text-uppercase" *ngFor="let section of surveyFormStructure.sections">
          <p class="text-uppercase display-8 my-2 lead">
            <strong>{{section.title}}</strong>
          </p> 
          <ng-container *ngFor="let item of section.questions">
          <div class="form-group">
            <label>{{item.label}}</label>
            <ng-container [ngSwitch]="item.type">
              <ng-container *ngSwitchCase="'textarea'">
                <textarea
                        [formControlName]="item.name"
                        class="form-control form-control-sm w-100"
                        rows="3"
                ></textarea>
                <val-errors [controlName]="item.name"></val-errors>
              </ng-container>

              <ng-container *ngSwitchCase="'number'">
                <input
                        [formControlName]="item.name"
                        [type]="'number'"
                        class="form-control form-control-sm w-100"
                        rows="3"
                />
                <val-errors [controlName]="item.name"></val-errors>
              </ng-container>

              <ng-container *ngSwitchCase="'select'">
                <ng-select [formControlName]="item.name" class="form-control-sm">
                    <ng-option 
                      *ngFor="let option of item.options"
                      [value]="option.value">{{ option.label }}
                    </ng-option>
                </ng-select>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
        <div class="mt-3">
          <button
            (click)="submit()"
            *ngIf="!saving"
            [disabled]="!this.surveyForm.valid"
            class="btn btn-primary btn-block"
          >
            <i class="fa fa-check-circle mr-2"></i> Invia questionario 
          </button>
      </div>
      </form>
    </div>

    <div class="card-footer">
    </div>

</div>