<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-dark"
                   image="assets/img/flats/settings.png"
                   title="Gestione incentivazione"
>
    <div class="row align-items-end">
        <div class="col-4">
            <label>Seleziona il segmento</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="fas fa-list-alt"></i>
                <select class="form-control" [(ngModel)]="this.segmento">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let seg of this.startup.segmenti" [value]="seg.value">{{seg.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-4">
            <label>Seleziona un operatore</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="fas fa-search"></i>
                <select class="form-control" [(ngModel)]="this.operatore">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let op of this.startup.operatori" [value]="op.value">{{op.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-4">
            <label>Seleziona periodo</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="far fa-calendar-alt"></i>
                <select class="form-control" [(ngModel)]="this.periodo">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let per of this.startup.periodi" [value]="per.value">{{per.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-12">
            <hr>
            <button *ngIf="data" class="btn btn-success float-right" [disabled]="data.bloccata" (click)="save();">Salva Modifiche</button>
            <button class="btn btn-warning" (click)="calculate()">Calcola Provvigioni</button>
            <button class="btn bg-info ml-2" (click)="download()">Scarica Dettaglio</button>
            <button  *ngIf="data" class="btn bg-danger ml-2" (click)="toggleLock()"><span *ngIf="data.bloccata">Sblocca</span><span *ngIf="!data.bloccata">Blocca</span> Gara</button>
<!--            <button class="btn bg-pink ml-2" (click)="update()">Aggiorna Report</button>-->
<!--            <button class="btn bg-danger ml-2" (click)="storni()">Carica Storni</button>-->
            <button class="btn bg-primary ml-2" (click)="duplicate()">Duplica</button>
        </div>
    </div>
</app-master-header>

<app-master-subheader #subheader [operator]="true" cardClass="bg-gradient-dark"
                      imgClass="p-1 bg-gradient-light img-circle elevation-1"
                      [url]="urlSubheader"></app-master-subheader>

<div class="row" *ngIf="false">
    <div class="col-6 offset-3">
        <h1 class="display-6">Gare</h1>
        <p class="lead">
            Nella tabella Gare definiamo le gare del mese in corso, al momento è prevista l'impostazione di più gare, ma il calcolo su più gare non è stato testato.
            Il calcolo si divide in:
        </p>
        <ul class="lead">
            <li>Volume Dealer</li>
            <ul>
                <li>I compensi di gara vengono determinati in base alle soglie di ogni dealer, si può quindi applicare questo calcolo sia per i dealer, che per gli agenti se i loro compensi si basano su quanto fa ogni dealer</li>
            </ul>
            <li>Volume Agente</li>
            <ul>
                <li>I compensi di gara vengono determinati in base alle soglie di ogni agente</li>
            </ul>
            <li>Volume Master</li>
            <ul>
                <li>I compensi di gara vengono determinati in base al volume totale delle sim attivate nel mese</li>
            </ul>
        </ul>
    </div>
</div>

<ng-container *ngIf="data">
    <div *ngFor="let row of data.struttura" class="row">
        <div *ngFor="let col of row.cols" [ngClass]="col.class">
            <app-hot *ngFor="let card of col.rows" [title]="card.title" [image]="card.image"
                     [buttons]="card.buttons"
                     [rows]="getRows(card)"
                     [cols]="getCols(card)"
                    (triggerAction)="this.callAction($event)"
            >

            </app-hot>
        </div>
    </div>
</ng-container>

<app-modal title="Guida" #helpModal>
    asd
</app-modal>

<app-modal [title]="modal.title" #messageModal>
    <div class="text-center">
        <i class="far mb-2" style="font-size: 3rem;" [ngClass]="modal.icon"></i>
        <h1 class="display-6">{{modal.message}}</h1>
        <p class="lead">{{modal.description}}</p>
    </div>
</app-modal>


<app-modal title="Duplica Gara" #dupModal>
    <div class="form-group">
        <label>Seleziona il mese in cui duplicare la pista</label>
        <div class="form-group form-group-icon form-group-dark mb-0">
            <i class="far fa-calendar-alt"></i>
            <select class="form-control" [(ngModel)]="this.dupMonth">
                <ng-container *ngIf="this.startup">
                    <option *ngFor="let per of this.startup.periodi" [value]="per.value">{{per.label}}</option>
                </ng-container>
            </select>
        </div>
    </div>
    <button class="btn btn-sm btn-success btn-block" (click)="saveGara()">Duplica la pista!</button>
</app-modal>

<app-modal title="Aggiorna Report" #upModal>
    <div class="form-group">
        <label>Incolla l'excel</label>
        <textarea class="form-control" [(ngModel)]="excelReport"></textarea>
    </div>
    <button class="btn btn-sm btn-success btn-block" (click)="saveReport()">Aggiorna Report</button>
</app-modal>


<app-modal title="Carica Storni" #storniModal>
    <div class="form-group">
        <label>Incolla l'excel</label>
        <textarea class="form-control" [(ngModel)]="excelStorni"></textarea>
    </div>
    <button class="btn btn-sm btn-success btn-block" (click)="caricaStorni()">Carica Storni</button>
</app-modal>
