import { BaseModel } from './base-model';
import { MetaPagination } from './meta-pagination';

export class SuretyRequest extends BaseModel {
    id: number;
    confirmation_date: string;
    file: string;
    ka_id: number;
    dealer_id: number;
    dealer: string;
    ka: string;
    note: string;
    status: string;
    created_at: string;
    created_by: number;
    updated_at: string;
    updated_by: number;
    is_deleted: number;

    fromArray(data: SuretyRequest[]) {
        const array: SuretyRequest[] = [];
        for (const item of data) {
            array.push(new SuretyRequest(item));
        }
        return array;
    }
}

export class SuretyRequestsPagination extends BaseModel {
    data: SuretyRequest[];
    _meta: MetaPagination;
    _links: Array<any>;
}
