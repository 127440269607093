import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

const version = 'v3';

@Injectable({
    providedIn: 'root',
})
export class WhatsappGeneratorService {
    constructor(private request: RequestService, public alertService: AlertService) {}

    // Wa
    public getWaList(params?): Observable<any> {
        return this.request.get(version + '/whatsapp/list', params);
    }

    public postWa(payload: any) {
        return this.request.post('v3/whatsapp/create', payload);
    }

    public getSlugList() {
        return this.request.get('v3/whatsapp/slug-list');
    }

    delete(id: number) {
        return this.request.delete(version + `/whatsapp/delete/${id}`);
    }

    // Wa subscription
    public getWaSubscription(params?): Observable<any> {
        return this.request.get(version + '/whatsapp-subscription/whatsapp-detail', params);
    }

    public getDcSelect(): Observable<any> {
        return this.request.get(version + '/whatsapp-subscription/dc-select');
    }

    public getAmSelect(params?): Observable<any> {
        return this.request.get(version + '/whatsapp-subscription/am-select', params);
    }

    public getkaSelect(params?): Observable<any> {
        return this.request.get(version + '/whatsapp-subscription/agent-select', params);
    }

    public postWaAssigned(payload): Observable<any> {
        return this.request.post(version + '/whatsapp-subscription/whatsapp-assigned', payload);
    }

    public putWhatsapp(id, body: {}) {
        return this.request.put(version + '/whatsapp-subscription/update/' + id, body);
    }

    deleteWaAssigned(id: number) {
        return this.request.delete(version + `/whatsapp-subscription/delete/${id}`);
    }

    // Wa Log

    public getWaLogById(params?): Observable<any> {
        return this.request.get(version + '/whatsapp-log/whatsapp-log', params);
    }

    // Alert
    public successAlert(text) {
        this.alertService.show('', 'File ' + text, { classname: 'bg-success text-light', delay: 2000 });
    }

    public errorAlert(message?) {
        this.alertService.show('Errore nel caricamento del file ', message ? message : 'Riprova di nuovo!', {
            classname: 'bg-danger text-light',
            delay: 2000,
        });
    }
}
