<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadList()">
        <div class="form-row">
            <!-- First Row -->
            <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                                            id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0" *show="['masteragent', 'operator', 'submaster', 'master']">
                <div class="form-group">
                    <label translate for="key_account">Key Account</label>
                    <app-agent-select (onChange)="filter.id_agente = $event"></app-agent-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0" *show="['operator', 'submaster', 'master']">
                <div class="form-group">
                    <label translate for="area_manager" >Area Manager</label>
                    <app-master-agent-select (onChange)="filter.id_masteragente = $event"></app-master-agent-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">Product</label>
                    <input [(ngModel)]="filter.product" name="product" class="form-control"/>
                </div>
            </div>
            <!-- Second Row -->
            <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date</label>
                    <input
                            id="documentDate"
                            type="text"
                            daterangepicker
                            autocomplete="off"
                            [options]="pickerOptions"
                            [(ngModel)]="filter.date"
                            name="date"
                            class="form-control"
                            (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="fase">Fase</label>
                    <div style="position: relative">
                        <select [(ngModel)]="filter.fase" name="fase" class="form-control">
                            <option value="Da Accettare">Da Accettare</option>
                            <option value="Accettata">Accettata</option>
                            <option value="Chiusa">Chiusa</option>
                            <option value="Annullata">Annullata</option>
                        </select>
                        <div *ngIf="filter.fase"
                             style="position: absolute; right: 8px; top: 50%; transform: translateY(-50%)">
                            <button style="border: none; background: none; cursor: pointer" type="button"
                                    (click)="clearFase()">
                                <i style="color: #000">&times;</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="nome_opportunita">Nome opportunità</label>
                    <input [(ngModel)]="filter.nome_opportunita" name="nome_opportunita" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                </div>
            </div>
        </div>

        <!-- Tabella -->
    </form>
</div>

<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>A2A Contratti</h4>
    </div>
    <div class="card-body p-0 rounted-bottom table-responsive">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box"
                     name="table-orders" [fullScreen]="false">
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th>Espandi</th>
                <th translate>Company name</th>
                <th translate>Key Account</th>
                <th translate>Area Manager</th>
                <th translate>Nome opportunità</th>
                <th translate>Prodotto</th>
                <th translate>SEPA</th>
                <th translate>Data sottoscrizione</th>
                <th translate>Fase</th>
                <th translate>Stato</th>
            </tr>
            </thead>
            <tbody class="bg-gradiend-white">
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders"
                         [fullScreen]="false">
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngIf="a2aList.length == 0">
                <td translate colspan="10" class="text-center">List is empty</td>
            </tr>
            <ng-container *ngFor="let item of a2aList">
                <tr>
                    <td>
                        <button class="btn btn-outline-info btn-sm text-center" type="button"
                                (click)="item.show = !item.show" role="button" aria-expanded="false" translate>
                            <i class="fas fa-plus mr-0" *ngIf="!item.show"></i>
                            <i class="fas fa-minus mr-0" *ngIf="item.show"></i>
                        </button>
                    </td>
                    <td>
                        <a [routerLink]="['/dealer', item.dealer_id]" target="_blank">{{ item.company_name }}</a>
                    </td>
                    <td *ngIf="item.ragsociale_agente != null">
                        <a [routerLink]="['/dealer', item.id_agente]" target="_blank">
                            {{ item.ragsociale_agente }}
                        </a>
                    </td>
                    <td *ngIf="item.ragsociale_agente == null">-</td>
                    <td *ngIf="item.ragsociale_masteragente != null">
                        <a [routerLink]="['/dealer', item.id_masteragente]" target="_blank">
                            {{ item.ragsociale_masteragente }}
                        </a>
                    </td>
                    <td *ngIf="item.id_masteragente == null">-</td>
                    <!-- <td>
                        {{ item.sottocanale === null ? '-' : item.sottocanale }}
                    </td> -->
                    <td>
                        {{ item.nome_opportunita === null ? '-' : item.nome_opportunita }}
                    </td>
                    <td>
                        {{ item.product === null ? '-' : item.product }}
                    </td>
                    <td>
                        {{ item.sepa === null ? '-' : item.sepa }}
                    </td>
                    <!-- <td>
                        {{ item.esito_ammissibilita_richiesta === null ? '-' : item.esito_ammissibilita_richiesta }}
                    </td> -->
                    <td>
                        {{ item?.data_sottoscrizione_ordine | amDateFormat : 'DD/MM/YYYY' }}
                    </td>
                    <td>
                        {{ item.fase === null ? '-' : item.fase }}
                    </td>
                    <td>
                        {{ item.stato === null ? '-' : item.stato }}
                    </td>
                </tr>
                <tr *ngIf="item.show">
                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box"
                                 name="updateSpinner" [fullScreen]="false">
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <td colspan="9">
                        <div class="">
                            <h4 class="mt-4" translate>Dati aggiuntivi</h4>
                            <hr/>
                            <div class="row">
                                <!-- Prima colonna -->
                                <div class="col-md-3 mb-3">
                                    <p class="text-uppercase mb-3"><strong>Informazioni generali:</strong></p>
                                    <p class="mb-2"><strong>Nome
                                        Account:</strong><br/>{{ item.nome_account === null ? '-' : item.nome_account }}
                                    </p>
                                    <p class="mb-2"><strong>Provincia
                                        account:</strong><br/>{{ item.account_personale_provincia === null ? '-' : item.account_personale_provincia }}
                                    </p>
                                    <p class="mb-2"><strong>Codice
                                        MGM:</strong><br/>{{ item.codice_mgm_univoco === null ? '-' : item.codice_mgm_univoco }}
                                    </p>
                                    <p class="mb-2">
                                        <strong>Operatore:</strong><br/>{{ item.operatore === null ? '-' : item.operatore }}
                                    </p>
                                    <p class="mb-2">
                                        <strong>Pod/Pdr:</strong><br/>{{ item.pod_pdr === null ? '-' : item.pod_pdr }}
                                    </p>
                                </div>
                                <!-- seconda colonna -->
                                <div class="col-md-3 mb-3">
                                    <p class="text-uppercase mb-3"><strong>Dettagli di vendita:</strong></p>
                                    <p class="mb-2"><strong>Mercato di
                                        provenienza:</strong><br/>{{ item.mercato_di_provenienza === null ? '-' : item.mercato_di_provenienza }}
                                    </p>
                                    <p class="mb-2"><strong>Canale di
                                        Vendita:</strong><br/>{{ item.canale_vendita === null ? '-' : item.canale_vendita }}
                                    </p>
                                    <p class="mb-2"><strong>Codice
                                        PDV:</strong><br/>{{ item.codice_pdv === null ? '-' : item.codice_pdv }}</p>
                                    <p class="mb-2"><strong>Modalità di
                                        Vendita:</strong><br/>{{ item.modalita_vendita === null ? '-' : item.modalita_vendita }}
                                    </p>
                                    <p class="mb-2">
                                        <strong>Commodity:</strong><br/>{{ item.commodity === null ? '-' : item.commodity }}
                                    </p>
                                </div>
                                <!-- Terza colonna -->
                                <div class="col-md-3 mb-3">
                                    <p class="text-uppercase mb-3"><strong>Stato e Validazione:</strong></p>
                                    <p class="mb-2"><strong>Stato
                                        Blacklist:</strong><br/>{{ item.stato_blacklist === null ? '-' : item.stato_blacklist }}
                                    </p>
                                    <p class="mb-2"><strong>Stato Credit
                                        Check:</strong><br/>{{ item.stato_credit_check === null ? '-' : item.stato_credit_check }}
                                    </p>
                                    <p class="mb-2"><strong>Stato
                                        Validazione:</strong><br/>{{ item.stato_validazione === null ? '-' : item.stato_validazione }}
                                    </p>
                                    <p class="mb-2">
                                        <strong>Processo:</strong><br/>{{ item.processo === null ? '-' : item.processo }}
                                    </p>
                                    <p class="mb-2">
                                        <strong>Data Validazione:</strong><br/>{{ item.validity_start_date === null ? '-' : item.validity_start_date }}
                                    </p>
                                    <p class="mb-2">
                                        <strong>Data Attivazione:</strong><br/>{{ item.activation_date === null ? '-' : item.activation_date }}
                                    </p>
                                    
                                </div>
                                <!-- quarta colonna --> 
                                <!-- validty_start_date - activation_date -->
                                <div class="col-md-3 mb-3">
                                    <p class="text-uppercase mb-3"><strong>Annullamento:</strong></p>
                                    <p class="mb-2"><strong>Causale
                                        annullamento:</strong><br/>{{ item.causale_annullamento === null ? '-' : item.causale_annullamento }}
                                    </p>
                                    <p class="mb-2"><strong>Data
                                        annullamento:</strong><br/>{{ item.causale_annullamento === null ? '-' : item.causale_annullamento }}
                                    </p>
                                    <p class="mb-2">
                                        <strong>Descrizione:</strong><br/>{{ item.causale_annullamento_estesa === null ? '-' : item.causale_annullamento_estesa }}
                                    </p>
                                    <div *ngIf="isNewDealerIdVisible(item.operatore)" class="form-group">
                                        <p><strong>Modifica Rag. Sociale</strong></p>
                                        <div class="row">
                                            <div class="col-6 d-flex justify-content-start align-items-center pr-0">
                                                <app-retailer-typeahead (onChange)="newDealerId = $event"
                                                                        id="companyName"
                                                                        class="w-100"></app-retailer-typeahead>
                                            </div>
                                            <div class="col-6 d-flex justify-content-start align-items-center">
                                                <button (click)="updateDealer(item.id)"
                                                        class="btn btn-info text-center ml-3">
                                                    <i class="fa fa-save cursor-pointer m-auto"
                                                       style="color: white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
    <span
    ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b
    >{{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.<a class="link"
                                                                                       (click)="exportA2aList()"> Esporta la lista completa</a></span
    >
        <ngb-pagination
                *ngIf="ordersPagination.pageCount > 1"
                size="sm"
                [pageSize]="ordersPagination.perPage"
                [maxSize]="10"
                [(page)]="ordersPagination.currentPage"
                [collectionSize]="ordersPagination.totalCount"
                (pageChange)="loadList()"
                [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>
