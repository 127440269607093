import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';
import {DealerView} from '@models/dealer-view';


export class Kdcube extends BaseModel{

    id: string;
    company_name: string;
    id_dealer: number;
    id_operator: string;
    id_invoice: string;
    id_stripe: string;
    from: string;
    to: string;
    service: string;
    time_range: number;
    payment_type: string;
    price: string;
    auto_renew: number;
    created_at: string;
    is_deleted: string;
    key_account_id?: number;
    key_account_ragsociale?: string;
    area_manager_id?: number;
    piva: string;
    area_manager_ragsociale?: string;
    renewed?: string;
    rinnovato: string;

    // public static serviceTranslate = [
    //     {
    //         label: 'Tutti i servizi',
    //         key: 'all'
    //     }
    // ]

    static serviceTranslate = {
        'all': 'Tutti i servizi',
    };

    static paymentTranslate = {
        'sdd-transfer': 'Bonifico',
        'shop': 'KDShop',
        'stripe': 'Stripe',
        'free': 'Omaggio',
    };

    get serviceTranslate(){
        return Kdcube.serviceTranslate[this.service];
    }

    get paymentTranslate(){
        return Kdcube.paymentTranslate[this.payment_type];
    }


    fromArray(result: []): Kdcube[] {
        const array: Kdcube[] = [];
        for (const item of result) {
            array.push(new Kdcube(item));
        }
        return array;
    }

}

export class KdcubeProvider extends BaseModel {
    data: Kdcube[];
    _meta: MetaPagination;
    _links: Array<any>;
}