<div class="card bg-gradient-dark">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img
                class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                width="160px"
                src="assets/img/flats/gear.png"
            />
            <div class="d-block w-100 pl-lg-3">
                <h1 class="display-4" translate>Mail Log</h1>
                <hr />
                <form (ngSubmit)="loadMailLog()">
                    <div class="form-row">
                        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
                            <label>Nome</label>
                            <input
                                type="text"
                                class="form-control"
                                [(ngModel)]="filter.name"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
                            <label>Data</label>
                            <input
                                id="date"
                                type="text"
                                daterangepicker
                                autocomplete="off"
                                [options]="pickerOptions"
                                [(ngModel)]="filter.date"
                                name="date"
                                class="form-control"
                                (selected)="generateDateRangeString($event.start, $event.end)"
                            />
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
                            <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>Liste dei LOG</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th translate>ID</th>
                    <th translate>Nome</th>
                    <th translate>Mail ID</th>
                    <th translate>Date</th>
                    <th class="text-center">Template Mail</th>
                </tr>
            </thead>
            <tbody class="bg-gradient-white">
                <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <tr *ngFor="let item of mailLog">
                    <td>
                        {{ item.id }}
                    </td>
                    <td>
                        {{ item.nome }}
                    </td>
                    <td>
                        {{ item.mail_id }}
                    </td>
                    <td>
                        {{ item?.created_at | amDateFormat : 'DD/MM/YYYY HH:mm' }}
                    </td>
                    <td class="text-center">
                        <button class="btn btn-link" (click)="showModal(item)">
                            <i class="fa fa-eye text-info"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="mailLog?.length <= 0">
                    <td class="text-muted text-center" colspan="5" translate>List is empty</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="mailLog?.length > 0">
        <span
            ><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
            {{ this.ordersPagination.totalCount <= 1 ? 'Log' : ('Log' | translate) }}.
            <a class="link">Esporta la lista completa</a>
        </span>
        <ngb-pagination
            *ngIf="ordersPagination.pageCount > 1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadMailLog()"
            [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<!-- MODALE -->

<ng-template #mailTemplate let-modal>
    <div class="modal-header bg-gradient-dark">
        <h4 class="modal-title clamp-title" translate>{{ 'Template Utilizzato' }}</h4>
        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div [innerHTML]="htmlContent | safeHtml"></div>
    </div>
</ng-template>
