import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  ENDPOINT = 'vendor';

  constructor(public request: RequestService) {

  }

  getList(params = {}) {
    return this.request.get(`${this.ENDPOINT}/list`, params);
  }

  getSegments(params = {}) {
    return this.request.get(`${this.ENDPOINT}/segments`, params);
  }

}
