import { VisiteService } from '@services/visite.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from '@services/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgenteService } from '@services/agente.service';
import { CalendarOptions } from '@fullcalendar/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { AgentVisitsService } from '@services/agent-visits.service';
import { environment } from '@environments/environment';
import convert from 'client-side-image-resize';
import { Helpers } from '@helpers/helpers';
import { UserService } from '@services/user.service';
import { Profile } from '@models/user';
import { AuthenticationService } from '@services/authentication.service';

@Component({
    selector: 'app-agent-visite-index',
    templateUrl: './agent-visite-index.component.html',
    styleUrls: ['./agent-visite-index.component.css'],
})
export class AgentVisiteIndexComponent implements OnInit {
    busy: Subscription;
    busyModal: Subscription;
    startup: {
        agente: '';
        periodi: any;
        periodo: any;
    };

    selectedDateRange = {
        from: '',
        to: '',
    };

    filterForm = {
        dateRange: '',
    };

    urlSubheader: string;
    id: any;
    urlTabellaDealers: any;
    table = {
        rows: [],
        columns: [],
    };

    visiteProgrammate = {
        rows: [],
        columns: [],
    };

    @ViewChild('visitFormModal') visitFormModal;

    defaultView = true;
    global: any;
    timeline: any = [];
    public baseAwsUrl = environment.baseUrl;
    currentLocation = {
        lat: null,
        lng: null,
    };
    maxDate;
    visitForm: FormGroup;
    visitFormMode = 'new';
    visitFormReady = false;
    visitFormDataReady = false;
    visitFormStructure: any = [];
    visitFormInitialData: any = [];
    saving = false;
    profile: Profile;
    dealerAdditionalInfoCollapse = true;
    priorities = [];

    get visitDocuments() {
        return (this.visitForm?.get('documents') as FormArray).controls;
    }

    get controlRequired() {
        if (this.profile && this.profile.operatori.includes('Eolo')) {
            return [Validators.required];
        }

        return [];
    }

    get showControl() {
        if (this.profile && this.profile.operatori.includes('Eolo')) {
            return true;
        }

        return false;
    }
    get kenaShowControl() {
        if (this.profile && !this.profile.operatori.includes('Eolo')) {
            return true;
        }

        return false;
    }

    get dealerAdditionError() {
        return (
            this.visitForm &&
            this.visitForm.get('dealerAdditional') &&
            this.visitForm.get('dealerAdditional').valid &&
            this.visitForm.get('dealerAdditionalJson').valid
        );
    }

    constructor(
        private modalService: NgbModal,
        private dashboardService: DashboardService,
        private agentVisitsService: AgentVisitsService,
        private service: VisiteService,
        private agenteService: AgenteService,
        public formBuilder: FormBuilder,
        private dateRangePickerOptions: DaterangepickerConfig,
        public route: ActivatedRoute,
        private authenticationService: AuthenticationService,
    ) {
        this.maxDate = {
            year: parseInt(moment().format('YYYY')),
            month: parseInt(moment().format('MM')),
            day: parseInt(moment().format('DD')),
        };
        this.id = this.route.snapshot.paramMap.get('id');
        this.dateRangePickerOptions.settings.locale.format = 'DD/MM/YYYY';
        this.setDefaultRange();
        this.profile = this.authenticationService.currentUserValue.profile;
    }

    ngOnInit() {
        this.loadVisitSchedule();
    }

    refresh() {
        this.busy = this.service
            .salesAgentOverview(this.selectedDateRange.from, this.selectedDateRange.to)
            .subscribe((data) => {
                this.timeline = data.timeline;
                this.global = data.overview;
                this.refreshTable();
                this.loadVisitSchedule();
            });
    }

    setDefaultRange() {
        this.setSelectedDateRange(moment().startOf('month'), moment());
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        if (from.isSame(moment().startOf('month')) && to.isSame(moment())) {
            this.defaultView = true;
        } else {
            this.defaultView = false;
        }

        const selectedDateRange = this.getDateString(from, to);
        this.filterForm.dateRange = selectedDateRange;
        this.refresh();
    }

    loadVisitSchedule() {
        this.agenteService.visiteProgrammate().subscribe((data) => {
            this.visiteProgrammate = data.result;
        });
    }

    trigger(event: any) {
        this.visitFormReady = false;
        this.visitFormDataReady = false;
        this.visitFormMode = 'new';
        this.modalService.open(this.visitFormModal, { size: 'lg', scrollable: true });
        this.agentVisitsService.getVisitFormStructure({ dealerId: event.value.id }).subscribe((data) => {
            this.visitFormStructure = data.additionalStructure;
            this.visitFormInitialData = data.initialValues;
            this.priorities = data.priorities;
            this.generateFormStructure(null, event.value.id);
        });
    }

    triggerProgrammata(event: any) {
        this.visitFormReady = false;
        this.visitFormDataReady = false;
        this.visitFormMode = 'scheduled';
        this.modalService.open(this.visitFormModal, { size: 'lg', scrollable: true });

        this.agentVisitsService
            .getVisitFormStructure({ dealerId: event.value.dealer_id, scheduledId: event.value.id })
            .subscribe((data) => {
                this.visitFormStructure = data.additionalStructure;
                this.visitFormInitialData = data.initialValues;
                this.priorities = data.priorities;
                this.generateFormStructure(event.value.id, event.value.dealer_id);
            });
    }

    triggerCustom() {
        this.visitFormReady = false;
        this.visitFormDataReady = false;
        this.visitFormMode = 'unknown';
        this.modalService.open(this.visitFormModal, { size: 'lg', scrollable: true });

        this.agentVisitsService.getVisitFormStructure().subscribe((data) => {
            this.visitFormStructure = data.additionalStructure;
            this.visitFormInitialData = data.initialValues;
            this.generateFormStructure(null, null);
        });
    }

    sendReply(item: any) {
        this.busy = this.agentVisitsService
            .postResponse({
                id_visita: item.id,
                testo: item.reply,
            })
            .subscribe((data) => {
                this.refresh();
            });
    }

    generateFormStructure(scheduled_id: any, dealer_id: any, dealer_name = null) {
        this.visitFormReady = false;
        this.visitFormDataReady = false;

        if (dealer_id) {
            this.visitForm = this.formBuilder.group({
                schedule_id: [scheduled_id, []],
                dealer_id: [dealer_id, [Validators.required]],
                closed_activity: ['No', [Validators.required]],
                address: [null, this.controlRequired],
                address_number: [null, this.controlRequired],
                city_name: [null, this.controlRequired],
                cap: [null, this.controlRequired],
                province_name: [null],
                dealerAdditional: this.formBuilder.group({
                    name: [null, this.controlRequired],
                    surname: [null, this.controlRequired],
                    phone: [null, this.controlRequired],
                    mobile: [null, this.controlRequired],
                    email: [null, this.controlRequired],
                    closing_days: [null, [Validators.required]],
                    brands: [null, [Validators.required]],
                    advertising: [null, [Validators.required]],
                }),
                dealerAdditionalJson: this.formBuilder.group({}),
                additional: this.formBuilder.group({}),
                documents: new FormArray([]),
            });

            if (this.kenaShowControl) {
                const dealerAdditional = this.visitForm.get('dealerAdditionalJson') as FormGroup;
                dealerAdditional.addControl('satispay', new FormControl(null, Validators.required));
                dealerAdditional.addControl('amex', new FormControl(null, Validators.required));
                dealerAdditional.addControl('main_brand', new FormControl(null, Validators.required));
                dealerAdditional.addControl('main_bran_qty', new FormControl(null, Validators.required));
                dealerAdditional.addControl('phone_qty', new FormControl(null, Validators.required));
                dealerAdditional.addControl('phone_types', new FormControl(null, Validators.required));
                dealerAdditional.addControl('phone_fnc_qty', new FormControl(null, Validators.required));
                dealerAdditional.addControl('has_insurance', new FormControl(null, Validators.required));
                dealerAdditional.addControl('insurance_info', new FormControl(null, Validators.required));
                dealerAdditional.addControl('business_qty', new FormControl(null, Validators.required));
                dealerAdditional.addControl('dealer_type', new FormControl(null, Validators.required));
            }

            this.visitForm.controls.closed_activity.valueChanges.subscribe(this.closedActivityChange.bind(this));

            this.visitForm.patchValue(this.visitFormInitialData);
        } else {
            this.visitFormMode = 'unknown';
            this.visitForm = this.formBuilder.group({
                schedule_id: [scheduled_id, []],
                dealer_id: [null, []],
                closed_activity: ['No', []],
                shop: ['', [Validators.required]],
                address: ['', [Validators.required]],
                address_number: [null, [Validators.required]],
                city_name: [null, [Validators.required]],
                province_name: [null],
                cap: [null, [Validators.required]],
                additional: this.formBuilder.group({}),
                documents: new FormArray([]),
            });

            this.visitForm.patchValue(this.visitFormInitialData);
        }

        const additionalGroup = this.visitForm.get('additional') as FormGroup;

        this.visitFormStructure.forEach((item) => {
            additionalGroup.addControl(
                item.name,
                new FormControl(item.value, item.required ? [Validators.required] : []),
            );
        });

        this.dealerAdditionalInfoCollapse = true;
        this.visitFormDataReady = true;
        this.visitFormReady = true;
        this.saving = null;
    }

    closedActivityChange(value: any) {
        const scheduled_id = this.visitForm.controls.schedule_id.value;
        const dealer_id = this.visitForm.controls.dealer_id.value;

        if (value === 'Sì') {
            this.visitFormDataReady = false;
            this.visitForm = this.formBuilder.group({
                schedule_id: [scheduled_id, []],
                dealer_id: [dealer_id, [Validators.required]],
                closed_activity: ['Sì', [Validators.required]],
            });
            this.visitForm.controls.closed_activity.valueChanges.subscribe(this.closedActivityChange.bind(this));
        } else {
            this.generateFormStructure(scheduled_id, dealer_id);
        }
    }

    addDocument(form: FormGroup) {
        const documents = form.get('documents') as FormArray;
        documents.push(
            new FormGroup({
                file: new FormControl(''),
                file_name: new FormControl(''),
            }),
        );
    }

    removeDocument(form: FormGroup, index) {
        const documents = form.get('documents') as FormArray;
        return documents.removeAt(index);
    }

    handleFileInput(file, form: FormGroup) {
        convert({
            file: file,
            height: 1024,
            type: 'jpeg',
        })
            .then((resp) => {
                this.compress(resp)
                    .then((compressedFile) => {
                        const file: File = compressedFile;
                        form.get('file').setValue(file);
                        form.get('file_name').setValue(file?.name);
                    })
                    .catch((error) => {
                        console.error('Errore durante la compressione:', error);
                    });
            })
            .catch((error) => {
                console.error(`Errore nella conversione del file: ${error}`);
            });
    }

    handleFileRemove(form: FormGroup) {
        form.get('file').setValue(null);
        form.get('file_name').setValue('');
    }

    saveVisit() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.currentLocation.lat = position.coords.latitude;
                this.currentLocation.lng = position.coords.longitude;

                this.postVisit();
            },
            (error) => {
                this.currentLocation.lat = null;
                this.currentLocation.lng = null;

                this.postVisit();
            },
        );
    }

    postVisit() {
        const data = this.visitForm.value;
        data.current_lat = this.currentLocation.lat;
        data.current_lng = this.currentLocation.lng;

        if (data.current_lat !== null) {
            data.current_lat = data.current_lat.toString();
        }
        if (data.current_lng !== null) {
            data.current_lng = data.current_lng.toString();
        }

        const formData = new FormData();
        Helpers.convertDictToFormData(formData, data);

        this.saving = true;
        this.agentVisitsService.postVisit(formData).subscribe(
            (data) => {
                this.modalService.dismissAll();
                this.visitForm.reset();
                this.refresh();
                this.saving = false;
            },
            (error) => {
                this.saving = false;
            },
        );
    }

    private getDateString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format('YYYY-MM-DD');
        this.selectedDateRange.to = end.format('YYYY-MM-DD');
        return (
            start.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            end.format(localDateFormat)
        );
    }

    private refreshTable() {
        this.busy = this.dashboardService.get(`/visite-tabella`).subscribe((data) => {
            this.table = data.result;
        });
    }

    setAddress($event: any) {
        this.visitForm.patchValue({
            address: $event.address,
            address_number: $event.streetNumber === null ? 'snc' : $event.streetNumber,
            city_name: $event.city,
            province_name: $event.province,
            cap: $event.postalCode,
        });
    }

    compress(file: File): Promise<File> {
        return new Promise((resolve, reject) => {
            const width = 500;
            const height = 300;
            const targetFileSizeKB = 400;
            const fileName = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src =
                    event.target.result instanceof ArrayBuffer
                        ? this.arrayBufferToBase64(event.target.result)
                        : (event.target.result as string);
                img.onload = () => {
                    const elem = document.createElement('canvas');
                    elem.width = width;
                    elem.height = height;
                    const ctx = elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    let quality = 0.9;
                    const compressImage = () => {
                        ctx.canvas.toBlob(
                            (blob) => {
                                if (blob.size <= targetFileSizeKB * 1024 || quality <= 0.1) {
                                    const compressedFile = new File([blob], fileName, {
                                        type: 'image/jpeg',
                                        lastModified: Date.now(),
                                    });
                                    resolve(compressedFile);
                                } else {
                                    quality -= 0.1;
                                    compressImage();
                                }
                            },
                            'image/jpeg',
                            quality,
                        );
                    };
                    compressImage();
                };
                img.onerror = (error) => reject(error);
            };
            reader.onerror = (error) => reject(error);
        });
    }

    arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    handleClose(modal) {
        try {
            modal.dismiss('Cross click');
        } catch (error) {
            console.error('Errore durante la chiusura della modale:', error);
        }
    }
}
