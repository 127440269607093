import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';
import {DealerView} from '@models/dealer-view';

export class Flixbus extends BaseModel{

    public static listStatus = [
        {
            label: 'completato',
            key: 'completed'
        },
        {
            label: 'in attesa',
            key: 'pending'
        }
    ];
    
    id: number;
    company_name: string;
    dealer_id: number;
    name: string;
    created_at: string;
    status: string;
    data: string;
    transaction_id:number;

    

    fromArray(result: []): Flixbus[] {
        const array: Flixbus[] = [];
        for (const item of result) {
            array.push(new Flixbus(item));
        }
        return array;
    }
}

export class FlixbusProvider extends BaseModel {
    data: Flixbus[];
    _meta: MetaPagination;
    _links: Array<any>;
}