import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.css']
})
export class ConfirmPopupComponent implements OnInit {

  @Output() confirm = new EventEmitter();

  constructor(
    public modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  confirmUpdate() {
    this.confirm.emit();
    this.dismissModal();
  }
}
