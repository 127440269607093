import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { MailLog } from '@models/mail-generator';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from '@services/request.service';
import { AuthenticationService } from '@services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { MailGeneratorService } from '@services/mail-generator.service';
import { User } from '@models/user';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';

@Component({
    selector: 'app-mail-log',
    templateUrl: './mail-log.component.html',
    styleUrls: ['./mail-log.component.css'],
})
export class MailLogComponent implements OnInit {
    @ViewChild('mailTemplate') private mailTemplate;
    ordersPagination: MetaPagination;
    mailLog: MailLog[];
    isDealer = false;
    isEdit = true;
    mailId: number;
    htmlContent: string;
    subMasterList: [] = [];
    selectedSubmaster: any;
    filter = {
        name: '',
        date: '',
    };

    apiDate = {
        from: '',
        to: '',
    };

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        },
    };

    constructor(
        private route: ActivatedRoute,
        public request: RequestService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        public formBuilder: FormBuilder,
        public mailService: MailGeneratorService,
        private dateRangePickerOptions: DaterangepickerConfig,
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.isDealer = user && user.profile.role === 'dealer';
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.mailId = params['id'];
        });
        this.loadMailLog();
        this.loadDcSelect();
    }

    loadMailLog() {
        const params = {
            mailId: this.mailId,
        };

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        if (this.filter.name) {
            params['name'] = this.filter.name;
        }

        if (this.filter.date) {
            params['from'] = this.apiDate?.from;
            params['to'] = this.apiDate?.to;
        }

        this.mailService.getMailLogById(params).subscribe(
            (response: any) => {
                this.mailLog = response;
                this.mailLog = new MailLog().fromArray(response.data);
                this.ordersPagination = response._meta;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    loadDcSelect() {
        this.spinner.show('table-orders');

        this.mailService.getDcSelect().subscribe(
            (response: any) => {
                this.subMasterList = response;
                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    showModal(item: any) {
        this.modalService.open(this.mailTemplate, { size: 'xl', scrollable: true });
        this.htmlContent = item.html;
    }

    dismissModal() {
        this.modalService.dismissAll();
        this.loadMailLog();
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.date =
            startDate.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            endDate.format(localDateFormat);
    }
}
