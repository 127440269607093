import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';

export class Olivetti extends BaseModel {
  public static orderStatus = [
    {
      label: 'In attesa',
      key: 'pending',
    },
    {
      label: 'Confermato',
      key: 'confirmed',
    },
    {
      label: 'Cancellato',
      key: 'canceled',
    },
    {
      label: 'Gestito',
      key: 'handled',
    },
    {
      label: 'Ko',
      key: 'ko',
    },
  ];

  static orderStatusType = {
    pending: 'In attesa',
    confirmed: 'Confermato',
    canceled: 'Cancellato',
    handled: 'Gestito',
    ko: 'Ko',
  };

  constructor(attributes?: any) {
    super(attributes);

    if (this.data !== null && this.data !== undefined) {
      const jsonData = JSON.parse(this.data);
      this.address = jsonData['address'];
      this.city = jsonData['city'];
      this.company_name = jsonData['company_name'];
      this.referral_name = jsonData['referral_name'];
      this.referral_surname = jsonData['referral_surname'];
      this.referral_email = jsonData['referral_email'];
      this.phone = jsonData['phone'];
      this.email = jsonData['email'];
      this.printer_model = jsonData['printer_model'];
      this.interest = jsonData['interest'];
      this.privacy = jsonData['privacy'];
      this.province = jsonData['province'];
      this.vat = jsonData['vat'];
      this.zip_code = jsonData['zip_code'];
      this.formType = jsonData['formType'];
      this.name = jsonData['name'];
      this.surname = jsonData['surname'];
    }
  }

  notes: string;
  name: string;
  surname: string;
  data: string;
  id: number;
  dealer: string;
  dealer_id: number;
  created_at: string;
  created_by: string;
  update_at: string;
  update_by: string;
  show = false;
  status: string;
  formType: string;
  company_name: string;
  referral_name: string;
  referral_surname: string;
  referral_email: string;
  phone: string;
  email: string;
  printer_model: string;
  interest: string;
  address: string;
  city: string;
  province: string;
  privacy: boolean;
  vat: string;
  zip_code: string;

  get orderStatus() {
    return Olivetti.orderStatusType[this.status];
  }

  fromArray(result: []): Olivetti[] {
    const array: Olivetti[] = [];
    for (const item of result) {
      array.push(new Olivetti(item));
    }
    return array;
  }
}

export class OlivettiProvider extends BaseModel {
  data: Olivetti[];
  _meta: MetaPagination;
  _links: Array<any>;
}
