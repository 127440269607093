import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {EPayService} from '@services/e-pay.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
    ShopCredit,
    ShopCreditPagination,
    ShopCreditReport,
    ShopDealerCredit,
    ShopDealerCreditPagination
} from '@models/e-pay/shop-dealer-credit';
import {MetaPagination} from '@models/meta-pagination';
import {ActivatedRoute} from '@angular/router';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';

@Component({
    selector: 'app-shop-credit',
    templateUrl: './shop-credit.component.html',
    styleUrls: ['./shop-credit.component.css']
})
export class ShopCreditComponent implements OnInit {
    dealers: ShopDealerCredit[] = [];
    currency = EPayService.currency;
    localFormat = EPayService.datetimeLocalFormat;
    statuses = EPayService.creditStatuses;
    selectedDealer: ShopDealerCredit;
    transactionModal: NgbModalRef;
    report: ShopCreditReport;
    @ViewChild('transactionTemplate') private transactionTemplate;
    @ViewChild('detailTemplate') private detailTemplate;
    dealerId = null;
    credits: ShopCredit[] = [];
    pagination: MetaPagination;
    deleteCreditOptions: ConfirmOptionsService;
    selectedCredit: ShopCredit;
    transactionForm = new FormGroup({
        amount: new FormControl('', Validators.required),
        note: new FormControl('')
    });
    transactionBusy: any;
    services = [
        {
            label: 'KDRicarica',
            key: 'e-pay',
            path: 'e-commerce'
        },
        {
            label: 'KDTickets',
            key: 'ticket',
            path: 'ticket'
        },
        {
            label: 'Stampa',
            key: 'printing',
            path: 'printing'
        },
        {
            label: 'Flixbus',
            key: 'flixbus',
            path: 'flixbus'
        },
        {
            label: 'PEC',
            key: 'pec',
            path: 'pec'
        },
        {
            label: 'Gift Card',
            key: 'amilon',
            path: 'amilon'
        },
        {
            label: 'Visure',
            key: 'certificate',
            path: 'certificate'
        },
        {
            label: 'Raccomandate',
            key: 'shipping',
            path: 'shipping'
        },
        {
            label: 'Raccomandate',
            key: 'ready-mail',
            path: 'ready-mail'
        },
        {
            label: 'Ticket',
            key: 'ticket',
            path: 'ticket'
        },
        {
            label: 'Pacchi',
            key: 'package',
            path: 'package'
        },
        {
            label: 'Bollettini',
            key: 'pay',
            path: 'pay'
        },
    ];
    filterDate = '';
    apiDate = {
        from: null,
        to: null
    };

    constructor(private spinner: NgxSpinnerService,
                private ePayService: EPayService,
                public modalService: NgbModal,
                public route: ActivatedRoute,
                private dateRangePickerOptions: DaterangepickerConfig) {
        this.dealerId = this.route.snapshot.paramMap.get('id');
        this.deleteCreditOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    ngOnInit(): void {
        this.loadReport(this.dealerId);
        if (this.dealerId) {
            this.loadTransactions();
        } else {
            this.loadDealers();
        }
    }

    showModal(credit?: ShopCredit) {
        this.selectedCredit = credit;
        this.transactionForm.setValue({
            amount: credit ? Number(credit?.amount) : '',
            note: ''
        });
        this.transactionModal = this.modalService.open(this.transactionTemplate, {size: 'md'});
        this.transactionModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showDetailModal(credit?: ShopCredit) {
        this.selectedCredit = credit;
        this.modalService.open(this.detailTemplate, {size: 'md'});
    }

    loadDealers(dealerId?) {
        this.spinner.show('table-credit');
        const params = {};
        if (this.pagination?.currentPage) {
            params['page'] = this.pagination?.currentPage;
        }
        if (dealerId) {
            params['dealer_id'] = dealerId;
        }
        this.ePayService.getDealersWithCredit(params).subscribe((response: ShopDealerCreditPagination) => {
            this.dealers = response.data;
            this.pagination = response._meta;
            this.spinner.hide('table-credit');
        }, err => {
            this.spinner.hide('table-credit');
        });
    }

    loadReport(dealerId?) {
        this.spinner.show('report');
        const params = {};
        if (this.pagination?.currentPage) {
            params['page'] = this.pagination?.currentPage;
        }
        if (dealerId) {
            params['dealer_id'] = dealerId;
        }
        this.ePayService.getCreditReport(params).subscribe((response: ShopCreditReport) => {
            this.report = response;
            this.spinner.hide('report');
        }, err => {
            this.spinner.hide('report');
        });
    }

    loadData(dealerId?) {
        this.loadReport(dealerId);
        setTimeout(() => {
            this.loadDealers(dealerId);
        });
    }

    loadTransactions() {
        this.spinner.show('table-credit');
        const params = {
            dealer_id: this.dealerId
        };
        if (this.pagination?.currentPage) {
            params['page'] = this.pagination?.currentPage;
        }
        if (this.apiDate?.from && this.apiDate?.to) {
            params['from'] = this.apiDate?.from;
            params['to'] = this.apiDate?.to;
        }
        this.ePayService.getCredits(params).subscribe((response: ShopCreditPagination) => {
            this.credits = response.data;
            this.pagination = response._meta;
            this.credits.forEach((item) => {
                const service = this.services.find((data) => data.key === item.service);
                item.serviceType = service ? service : null;
            });
            this.spinner.hide('table-credit');
        }, error => {
            this.spinner.hide('table-credit');
        });
    }

    removeTransaction(id: number) {
        this.ePayService.deleteCredit(id).subscribe(() => {
            this.loadTransactions();
            this.ePayService.successAlert('Removed Transaction!');
        }, (err) => {
            this.ePayService.errorAlert(err);
        });
    }

    saveTransaction() {
        const credit = new ShopCredit();
        credit.amount = this.transactionForm.value.amount;
        credit.dealer_id = this.dealerId;
        credit.note = this.transactionForm.value.note;
        this.transactionBusy = this.changeTransaction(credit).subscribe((response: ShopCredit) => {
            this.loadTransactions();
            this.transactionForm.reset();
            this.ePayService.successAlert('Changed Transaction!');
            this.modalService.dismissAll();
        }, err => {
            this.transactionForm.reset();
            this.ePayService.errorAlert(err);
            this.modalService.dismissAll();
        });
    }

    changeTransaction(credit: ShopCredit) {
        return this.selectedCredit ? this.ePayService.putDealerCredits(this.selectedCredit?.id, credit)
            : this.ePayService.postDealerCredits(credit);
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filterDate = startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
        return this.loadTransactions();
    }

    clearDateFiler() {
        this.filterDate = null;
        this.apiDate.from = null;
        this.apiDate.to = null;
        return this.loadTransactions();
    }
}
