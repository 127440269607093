import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CityService} from '@services/city.service';

@Component({
    selector: 'app-cluster-select',
    templateUrl: './cluster-select.component.html',
    styleUrls: ['./cluster-select.component.scss'],
})
export class ClusterSelectComponent implements OnInit {
    @Input()
    small = false;


    @Input()
    model: any;
    data: [] = [];
    loading = false;

    @Output() onChange = new EventEmitter();

    constructor(private service: CityService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.loading = true;
        this.service
            .clusters()
            .subscribe((data) => {
                this.data = data;
                this.loading = false;
            });
    }
}
