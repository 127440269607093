<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add Call Report</h4>
    <button
            (click)="dismissModal()"
            aria-label="Close"
            class="close"
            type="button"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div [ngBusy]="modalBusy" class="modal-body">
    <div class="row mb-2">
        <div class="col-4">
            <h4>
                <i class="fas fa-store text-info mr-2"></i>{{lead ? lead?.company_owner : call?.lead.company_owner}}
            </h4>
        </div>
        <div class="col-4 text-center">
            <h4>
                <i class="fas fa-headset text-info mr-2"></i>{{operator ? operator?.profile?.ragione_sociale : call?.caller_name}}
            </h4>
        </div>
        <div class="col-4 d-flex justify-content-end">
            <h4>
                <i class="fas fa-phone text-success mr-2"></i> {{phoneNumber}}
            </h4>
        </div>
    </div>
    <form (ngSubmit)="save()" [formGroup]="callForm">
        <div class="form-row">
            <div class="col-4 form-group">
                <label for="startDate" translate>Start datetime</label>
                <input class="form-control" formControlName="start_time" id="startDate" type="datetime-local">
                <val-errors controlName="start_time">
                    <ng-template valError="required">
                        <span translate>Start datetime is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-4 form-group">
                <label for="duration" translate>Duration</label>
                <input class="form-control" formControlName="duration" id="duration" mask="00:00:00"
                       placeholder="00 : 00 : 00" type="text">
                <val-errors controlName="duration">
                    <ng-template valError="required">
                        <span translate>Duration is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-4 form-group">
                <label for="phone" translate>Phone</label>
                <ng-select (change)="changePhoneNumber($event)" [(ngModel)]="phoneNumber" formControlName="destination"
                           id="phone">
                    <ng-option [value]="lead?.cell_phone">{{lead?.cell_phone}}</ng-option>
                    <ng-option [value]="lead?.phone">{{lead?.phone}}</ng-option>
                </ng-select>
                <val-errors controlName="destination">
                    <ng-template valError="required">
                        <span translate>Phone number is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 form-group">
                <label translate>Call Status</label>
                <div *ngFor="let status of callStatuses; index as i" class="form-check form-check-inline mr-4">
                    <input [id]="'call-'+status.key" [value]="status.key" class="form-check-input" formControlName="call_status"
                           name="call_status" type="radio">
                    <label [for]="'call-'+status.key" class="form-check-label" translate>
                        {{status.name}}
                    </label>
                </div>
            </div>
            <!-- <div class="col-4 form-group">
                <label translate>Feedback Status</label>
                <div *ngFor="let status of feedbackStatuses; last as isLast" class="form-check form-check-inline mr-4">
                    <input [id]="status.name" [value]="status.key" class="form-check-input" formControlName="feedback_status"
                           name="feedback_status" type="radio">
                    <label [for]="status.name" class="form-check-label" translate>
                        {{status.name}}
                    </label>
                </div>
            </div> -->
            <div *ngIf="lead.table_url" class="col-12">
                <p>
                    <button (click)="showTable = !showTable" aria-controls="collapseExample" class="btn btn-outline-primary"
                            type="button">
                        Mostra Dati
                    </button>
                </p>
                <div #collapse="ngbCollapse" [ngbCollapse]="showTable" class="mt-2 border">
                    <data-builder-table [url]="lead.table_url"></data-builder-table>
                </div>
            </div>
            <div class="col-12 form-group">
                <label for="note" translate>Note</label>
                <editor [init]="{base_url: '/tinymce', suffix: '.min'}" formControlName="response"
                        id="note"></editor>
            </div>
            <div *ngIf="questions?.length>0" class="col-12">
                <h5 translate>Questions:</h5>
                <div class="question-container">
                    <ul class="list-group list-group-flush">
                        <li *ngFor="let answer of answersValue.controls; index as i" class="list-group-item px-0"
                            formArrayName="answers">
                            {{questions[i].info.title}}
                            <div [formGroupName]="i">
                                <div *ngIf="questions[i].info.question_type == questionTypes[0].key"
                                     class="float-right">
                                    <div *ngFor="let answer of questionTypes[0].answers"
                                         class="form-check form-check-inline">
                                        <input [formControlName]="'text'+questions[i].id" [id]="answer.key+i" [name]="'text'+questions[i].id"
                                               [value]="answer.key" class="form-check-input"
                                               type="radio">
                                        <label [for]="answer.key" class="form-check-label" translate>
                                            {{answer.name}}
                                        </label>
                                    </div>
                                </div>
                                <p [innerHTML]="questions[i].info.text" class="text-muted"></p>
                                <div *ngIf="questions[i].info.question_type == questionTypes[1].key" class="mt-2">
                                    <editor [formControlName]="'text'+questions[i].id"
                                            [init]="{base_url: '/tinymce', suffix: '.min'}"></editor>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="col-12">
            <div class="col-12">
                <div class="row">
                    <div class="col-auto">
                        <button (click)="showInfo = !showInfo" aria-controls="collapseExample" class="btn btn-outline-primary"
                                type="button">
                            Mostra Dati Dealer
                        </button>
                    </div>
                    <div class="col-auto">
                        <button (click)="showReports = !showReports" aria-controls="collapseExample" class="btn btn-outline-primary"
                                type="button">
                            Mostra Reports
                        </button>
                    </div>
                    <div class="col-auto">
                        <button (click)="showKenaData = !showKenaData" aria-controls="collapseExample" class="btn btn-outline-primary"
                                type="button">
                            Mostra Dati Kena
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div #collapseInfo="ngbCollapse" [ngbCollapse]="showInfo" class="mt-2">
                    <app-dealer-detail [id]="lead.dealer_id"></app-dealer-detail>
                </div>
            </div>
            <div class="col-12">
                <div #collapseReports="ngbCollapse" [ngbCollapse]="showReports" class="mt-2 border">
                    <app-report-timeline [dealerId]="lead.dealer_id" [hideHeader]="true"></app-report-timeline>
                </div>
            </div>
            <div class="col-12">
                <div #collapseKena="ngbCollapse" [ngbCollapse]="showKenaData" class="mt-2">
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <app-autoload-keypoints
                                    url="-detail/negozio-keypoints-kena?id={{lead.dealer_id}}"
                            ></app-autoload-keypoints>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="card bg-gradient-light">
                                <div class="card-header px-1">
                                    <app-datepicker-range-popup
                                            (onSelect)="kenaRiepilogo.setUrl('-detail/negozio-riepilogo-kena?id='+lead.dealer_id+'&from='+ $event.from + '&to=' + $event.to)"
                                            [from]="riepilogoPeriod.from"
                                            [to]="riepilogoPeriod.to"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-0">
                                    <app-autoload-table-riepilogo
                                        #kenaRiepilogo
                                        url="-detail/negozio-riepilogo-kena?id={{lead.dealer_id}}"
                                    ></app-autoload-table-riepilogo>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <app-autoload-table
                                    [hideFooterDescription]="true"
                                    [small]="true"
                                    url="-detail/negozio-dettaglio-kena?id={{lead.dealer_id}}"
                            ></app-autoload-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <button [disabled]="callForm.invalid" class="btn btn-success btn-block" type="submit">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>

        </div>
    </form>
</div>
