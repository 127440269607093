import {AfterViewInit, Component, EventEmitter, Injector, OnInit, Output, ViewChild,} from '@angular/core';

import {User} from '@models/user';
import {AuthenticationService} from '@app/services/authentication.service';
import {NavigationEnd, Route, Router, Routes,} from '@angular/router';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import * as $ from 'jquery';
import * as AdminLte from 'admin-lte';
import {BaseModel} from '@models/base-model';
import {Menu} from '@models/menu';

class MenuItem extends BaseModel{
    icon: any;
    label: any;
    path: string;
    badge: number;
    activeClass: string[];
    children: any[];
    externalUrl: boolean;
    new: boolean;
}

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
    currentUser: User;
    currentMenu: Menu;
    currentRoute = '';

    @ViewChild('mainSidebar', {static: false}) mainSidebar;
    @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();

    menu: MenuItem[];

    get me() {
        return this.currentUser.profile;
    }

    constructor(
        public authenticationService: AuthenticationService,
        public router: Router
    ) {
        this.authenticationService.currentUser.subscribe(
            (x) => (this.currentUser = x)
        );
        this.authenticationService.currentMenu.subscribe(
            (x) => (this.currentMenu = x)
        );

        let routes = this.router.config[0].children;
        routes = MenuSidebarComponent.getRoutesToShow(routes);

        this.menu = this.convertRouteToMenu(routes);
    }

    ngAfterViewInit() {
        this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);

        this.currentRoute = this.router.url;
        this.router.events
            .pipe(
                distinctUntilChanged(),
                filter((item: NavigationEnd) => {
                    return item instanceof NavigationEnd;
                })
            )
            .subscribe((val: NavigationEnd) => {
                this.currentRoute = val.url;
            });

        $('[data-widget="treeview"]').each(function() {
            AdminLte.Treeview._jQueryInterface.call($(this), 'init');
            AdminLte.Layout._jQueryInterface.call($('body'));
            AdminLte.PushMenu._jQueryInterface.call($('[data-widget="pushmenu"]'));
        });
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    ngOnInit(): void {
    }

    private static getRoutesToShow(routes: Routes): Routes {
        return routes.filter((i) => i.data.menu === true);
    }

    private convertRouteToMenu(routes: Routes, basePath = '') {
        return routes
            .filter((route) => this.canActivate(route))
            .map((route) => {
                let item = new MenuItem({
                    icon: route.data.icon,
                    label: route.data.label,
                    path: basePath.concat('/', route.path),
                    activeClass: ['active'],
                    badge: 0,
                    new: false,
                    children: [],
                });

                if (route.hasOwnProperty('children') && route.children.length > 0) {
                    item.children = MenuSidebarComponent.getRoutesToShow(route.children);
                    item.children = this.convertRouteToMenu(item.children, item.path);
                    item.path = null;
                    item.activeClass = [];
                }

                if (route.hasOwnProperty('data')) {
                    if (route.data.hasOwnProperty('badges')) {
                        if (this.currentMenu && this.currentMenu.hasOwnProperty('badges') && this.currentMenu.badges.hasOwnProperty(route.data.badges)) {
                            item.badge = this.currentMenu.badges[route.data.badges];
                        }
                    }

                    if (route.data.hasOwnProperty('sms')) {
                        if (this.currentUser.profile.abilitato_sms) {
                            item.path = 'http://panel.master-sms.it';
                            item.externalUrl = true;
                        }
                    }

                    if (route.data.hasOwnProperty('new') && route.data.new) {
                        item.new = true;
                    }
                }

                return item;
            });
    }

    private canActivate(route: Route) {
        if (route.hasOwnProperty('data')) {
            if (route.data.hasOwnProperty('roles')) {
                if (!(route.data.roles.indexOf(this.me.role) !== -1)) {
                    return false;
                }
            }
            if (route.data.hasOwnProperty('vendor')) {
                if (!(this.me.operatori.some((e) => route.data.vendor.includes(e)))) {
                    return false;
                }
            }
        }
        return true;
    }

    getLink(route: any) {
        if (route.hasOwnProperty('children')) {
            return [];
        }

        return ['/'.concat(route.path)];
    }
}
