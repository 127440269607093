import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CuService {

  constructor(private request: RequestService, public alertService: AlertService) {

  }

  public getCuOrders(params?): Observable<any> {
    return this.request.get('invoice/orders', params);
  }

  addCuSubscription(data) {
    return this.request.post(`invoice/create-order`, data);
  }

  massiveUpload(formData: FormData) {
    return this.request.post(`invoice/massive-upload`, formData);
  }

  deleteCuSubscription(id: number) {
    return this.request.delete(`invoice/delete/${id}`);
  }

  public successAlert(text) {
    this.alertService.show(
      '',
      'Successfully ' + text,
      { classname: 'bg-success text-light', delay: 2000 }
    );
  }

  public errorAlert(message?) {
    this.alertService.show(
      'An error occurred!',
      message ? message : 'Try again!',
      { classname: 'bg-danger text-light', delay: 2000 }
    );
  }

}
