import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';

@Injectable({
    providedIn: 'root'
})
export class VisiteService {
    ENDPOINT = 'visites';

    constructor(public request: RequestService) {
    }

    globalSituation(from, to, download = false) {
        if (download) {
            return this.request.get(`${this.ENDPOINT}/global-situation/${from}/${to}/1`, {}, {responseType: 'blob'});
        }
        return this.request.get(`${this.ENDPOINT}/global-situation/${from}/${to}`);
    }

    resultsDetail(from, to, id, event) {
        return this.request.get(`${this.ENDPOINT}/results-detail/${from}/${to}/${id}/${event}`);
    }

    salesAgentOverview(from, to, id = null, download = false) {
        if (id) {
            if(download) {
                return this.request.get(`${this.ENDPOINT}/sales-agent-overview/${from}/${to}/${id}/1`, {}, {responseType: 'blob'});
            }

            return this.request.get(`${this.ENDPOINT}/sales-agent-overview/${from}/${to}/${id}`);
        }
        return this.request.get(`${this.ENDPOINT}/sales-agent-overview/${from}/${to}`);
    }

    salesAgentCalendar(period, id = null) {
        if (id) {
            return this.request.get(`${this.ENDPOINT}/sales-agent-calendar/${period}/${id}`);
        }
        return this.request.get(`${this.ENDPOINT}/sales-agent-calendar/${period}`);
    }

    retailersNeverVisited(id) {
        return this.request.get(`${this.ENDPOINT}/retailers-never-visited/${id}`);
    }

    // salesAgentPlanning(id = null, params?) {
    //     if (id) {
    //         return this.request.get(`${this.ENDPOINT}/sales-agent-planning`, params);
    //     }
    //     return this.request.get(`${this.ENDPOINT}/sales-agent-planning/${id}`, params);
    // }

    salesAgentPlanning(id = null, params?) {
        if (id) {
            return this.request.get(`${this.ENDPOINT}/sales-agent-planning/${id}`, params);
        }
            return this.request.get(`${this.ENDPOINT}/sales-agent-planning`, params);
    }
}
