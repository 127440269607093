import { Component, OnInit } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { User } from '@models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { AuthenticationService } from '@services/authentication.service';
import { Integra } from '@app/models/integra';
import { Moment } from 'moment';
import * as moment from 'moment';
import { IntegraService } from '@app/services/integra.service';
import { RequestService } from '@app/services/request.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-integra',
  templateUrl: './integra.component.html',
  styleUrls: ['./integra.component.css'],
})
export class IntegraComponent implements OnInit {
  integraList: Integra[] = [];
  ordersPagination: MetaPagination = new MetaPagination();
  isDealer = false;
  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  filter = {
    id: '',
    company_name: '',
    dealer_id: '',
    vat: '',
    phone: '',
    email: '',
    status: '',
    created_at: '',
    date: '',
  };

  apiDate = {
    from: '',
    to: '',
  };

  constructor(
    public request: RequestService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private dateRangePickerOptions: DaterangepickerConfig,
    private integraService: IntegraService,
    public route: ActivatedRoute,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadList();
    setTimeout(() => {});
  }

  loadList() {
    this.spinner.show('table-orders');
    const params = {};

    params['service'] = 'integra';

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.integraService.getIntegraList(params).subscribe(
      (response: any) => {
        this.integraList = new Integra().fromArray(response.data);
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  downloadZip(id) {
    this.integraService.getIntegraPractice(id).subscribe((data) => {
      let rows = data.data;
      //da mappare l'output
      let output = rows.map((row) => {
        return {
          Id: row.id,
          'Ragione Sociale': row.company_name,
        };
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'pratica-integra.xlsx');
    });
  }
}
