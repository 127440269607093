<div class="accordion">
    <div class="card">
        <div class="card-header bg-dark">
            <h4 class="mb-0" translate>
                <i
                    (click)="toggleCollapse()"
                    style="cursor: pointer"
                    class="fa m-auto mr-3"
                    [ngClass]="{ 'fa-chevron-down': isCollapsed, 'fa-chevron-up': !isCollapsed }"
                ></i>
                Inserisci una nuova nota
            </h4>
        </div>
        <div *ngIf="!isCollapsed">
            <div class="card-body">
                <div>
                    <form [formGroup]="datiForm" (ngSubmit)="saveNote()">
                        <div class="form-row">
                            <div class="col-lg-3 col-md-12 mb-3 mb-md-0">
                                <div class="form-group">
                                    <label translate>Periodo</label>
                                    <app-month-range-picker
                                        (selected)="setSelectedDate($event)"
                                        [singleMonthPicker]="true"
                                    ></app-month-range-picker>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mb-3 mb-md-0">
                                <div class="form-group">
                                    <label translate>Ruolo</label>
                                    <select formControlName="role" class="form-control">
                                        <option value="area-manager">Area Manager</option>
                                        <option value="key-account">Key Account</option>
                                        <option value="dealer">Dealer</option>
                                        <option value="generale">Generale</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mb-3 mb-md-0">
                                <div class="form-group">
                                    <label>Operatori</label>
                                    <app-vendor-select
                                        #vendorSelect
                                        (onChange)="setVendor($event)"
                                        [multiple]="true"
                                    ></app-vendor-select>
                                </div>
                            </div>
                            <div class="col-12 mt-2 mb-0">
                                <div class="form-group">
                                    <label for="description">Note</label>
                                    <editor id="description" [init]="tinyMceConfig" formControlName="description">
                                    </editor>
                                </div>
                            </div>
                            <div class="col-xl-12">
                                <button
                                    type="submit"
                                    class="btn px-3 btn-secondary"
                                    [disabled]="datiForm.invalid || isLoading"
                                >
                                    Salva
                                    <span *ngIf="isLoading" class="fa fa-spinner fa-spin ml-2"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <h4 class="mb-0">Note KPI</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th style="width: 10%">ID</th>
                    <th style="width: 10%" translate>Periodo</th>
                    <th style="width: 40%" translate>Descrizione</th>
                    <th style="width: 15%" translate>Data di creazione</th>
                    <th style="width: 25%" translate>Ruolo</th>
                    <th style="width: 25%" translate>Operatori</th>
                    <th style="width: 5%" class="text-center"></th>
                    <th style="width: 5%" class="text-center"></th>
                </tr>
            </thead>
            <tbody>
                <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <tr *ngIf="kpiNotes.length == 0">
                    <td translate colspan="7" class="text-center">List is empty</td>
                </tr>
                <tr *ngFor="let item of kpiNotes; let i = index">
                    <td>
                        {{ item.id }}
                    </td>
                    <td>{{ item?.month }}/{{ item?.year }}</td>
                    <td [innerHTML]="truncateText(item.description)"></td>
                    <td>
                        {{ item?.created_at | amDateFormat : 'DD/MM/YYYY HH:mm' }}
                    </td>
                    <td>
                        {{ item?.role }}
                    </td>
                    <td>
                        {{ item?.id_operatori !== '' ? item?.id_operatori : '-' }}
                    </td>
                    <td class="text-right">
                        <button class="btn btn-link" (click)="showDetailModal(item)">
                            <i class="fas fa-pencil-alt text-info"></i>
                        </button>
                    </td>
                    <td class="text-center">
                        <button
                            type="button"
                            class="close text-danger"
                            aria-label="Close"
                            [swal]="deleteSubscriptionOptions"
                            *ngIf="isEdit"
                            (confirm)="deleteKpiNotes(item?.id)"
                        >
                            <p aria-hidden="true">&times;</p>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="kpiNotes?.length > 0">
        <span
            ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b>
            {{ this.ordersPagination.totalCount <= 1 ? 'KPI Note' : ('KPI notes' | translate) }}
        </span>
        <ngb-pagination
            *ngIf="ordersPagination.pageCount > 1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadNotes()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>

<!-- modale -->

<ng-template #detailTemplate let-modal>
    <div class="modal-header">
        <h4>Modifica Kpi</h4>
        <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editForm" (ngSubmit)="updateKpiNotes($event)">
            <div class="form-row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-12 mt-auto mb-0">
                    <div class="form-group">
                        <label translate>Periodo</label>
                        <app-month-range-picker
                            (selected)="updateSelectedDate($event)"
                            [singleMonthPicker]="true"
                            [defaultSingleMonth]="defaultSingleMonth"
                        ></app-month-range-picker>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-12 mt-auto mb-0">
                    <div class="form-group">
                        <label translate>Ruolo</label>
                        <select formControlName="editRole" class="form-control">
                            <option value="area-manager">Area Manager</option>
                            <option value="key-account">Key Account</option>
                            <option value="dealer">Dealer</option>
                            <option value="generale">Generale</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-12 mt-auto mb-0">
                    <div class="form-group">
                        <label>Operatori</label>
                        <app-vendor-select
                            (onChange)="setEditVendor($event)"
                            [multiple]="true"
                            [vendors]="selectedData?.id_operatori"
                            #editVendorSelect
                        ></app-vendor-select>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-12">
                    <label for="editDescription">Descrizione</label>
                    <editor id="editDescription" [init]="tinyMceConfig" formControlName="editDescription"></editor>
                </div>
            </div>

            <button type="submit" class="btn btn-primary" [disabled]="!editForm.valid">Salva modifiche</button>
        </form>
    </div>
</ng-template>
