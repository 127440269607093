/* tslint:disable:variable-name */

import { BaseModel } from "./base-model";

export class Transaction extends BaseModel {
  id: number;
  id_dealer: number;
  operator: string;
  descrizione: string;
  segno: number;
  valore: number;
  scadenza: string;
  saldato: number;
  id_pro_forma_dealer: number;
  deleted: number;
  created: string;
  date_saldo: string;
    invoice_types: string;

  set checked(val) {
    this.saldato = val ? 1 : 0;
  }

  get checked(): boolean {
    return this.saldato === 1;
  }

  get isAccredito() {
    return this.segno === 0;
  }

  get isAddebbito() {
    return this.segno === 1;
  }

  fromArray(result: []) {
    const array: Transaction[] = [];
    for (const item of result) {
      array.push(new Transaction(item));
    }
    return array;
  }
}
