import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    PbxCampaign,
    PbxCampaignOperator,
    PbxCampaignOperatorPagination,
    PbxCampaignReport,
    PbxQuestionReport
} from '@models/pbx/pbx-campaign';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {MetaPagination} from '@models/meta-pagination';
import {PbxQuestionCategory} from '@models/pbx/pbx-question';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-pbx-campaign-details',
    templateUrl: './pbx-campaign-details.component.html',
    styleUrls: ['./pbx-campaign-details.component.css']
})
export class PbxCampaignDetailsComponent implements OnInit {
    campaign: PbxCampaign;
    deleteCampaignOptions: ConfirmOptionsService;
    modalBusy: any;
    @ViewChild('campaignFormTemplate') private campaignFormTemplate;
    campaignForm = new FormGroup({
        name: new FormControl('', Validators.required),
        description: new FormControl(''),
        date_range: new FormControl('', Validators.required),
        start_date: new FormControl('', Validators.required),
        end_date: new FormControl('', Validators.required)
    });
    defaultConfig = new TinyMCE();
    campaignModal: NgbModalRef;
    @Output() deletedCampaign = new EventEmitter<boolean>();
    report: PbxCampaignReport;
    questions: PbxQuestionReport[] = [];
    questionCategories: PbxQuestionCategory[] = [];
    operators: PbxCampaignOperator[] = [];
    operatorsPagination: MetaPagination;
    localFormat = 'DD/MM/YYYY';
    operatorFilter: '';
    status = {
        active: PbxCampaignService.activeCampaign,
        expired: PbxCampaignService.expiredCampaign,
        on_hold: PbxCampaignService.onHoldCampaign
    };
    constructor(private pbxCampaignService: PbxCampaignService,
                private dateRangePickerConfig: DaterangepickerConfig,
                public modalService: NgbModal,
                private spinner: NgxSpinnerService) {
    }

    ngOnInit(): void {
        this.deleteCampaignOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
        this.pbxCampaignService.changedCampaign.subscribe((data: PbxCampaign) => {
            if (data) {
                this.campaign = data;
                this.operatorFilter = '';
                this.loadCampaignReport();
                setTimeout(() => {
                    this.loadOperatorsReport();
                    this.loadQuestionsReport();
                });
            }
        });
    }

    deleteCampaign(id: number) {
        this.pbxCampaignService.deleteCampaign(id).subscribe(() => {
            this.deletedCampaign.emit(true);
            this.pbxCampaignService.successAlert('Deleted Campaign!');
        }, err => {
            this.pbxCampaignService.errorAlert(err.message);
        });
    }

    setDateRange(start: any, end: any) {
        const from = moment(start).format(this.dateRangePickerConfig.settings.locale.format);
        const to = moment(end).format(this.dateRangePickerConfig.settings.locale.format);
        const startDate = moment(start).format(this.dateRangePickerConfig.settings.locale.apiFormat);
        const endDate = moment(end).format(this.dateRangePickerConfig.settings.locale.apiFormat);
        const dateRange = from + this.dateRangePickerConfig.settings.locale.separator + to;
        this.campaignForm.get('start_date').setValue(startDate);
        this.campaignForm.get('end_date').setValue(endDate);
        this.campaignForm.get('date_range').setValue(dateRange);
    }

    showCampaignModal() {
        this.loadCampaignModalData();
        this.campaignModal = this.modalService.open(this.campaignFormTemplate, {size: 'xl'});
        this.campaignModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    loadCampaignModalData() {
        this.campaignForm.get('name').setValue(this.campaign.name);
        this.campaignForm.get('description').setValue(this.campaign.description);
        this.setDateRange(this.campaign.start_date, this.campaign.end_date);
    }

    editCampaign() {
        const campaign = new PbxCampaign();
        campaign.name = this.campaignForm.value.name;
        campaign.description = this.campaignForm.value.description;
        campaign.start_date = this.campaignForm.value.start_date;
        campaign.end_date = this.campaignForm.value.end_date;
        this.modalBusy = this.pbxCampaignService.putCampaign(this.campaign.id, campaign).subscribe((response: PbxCampaign) => {
            this.campaign = response;
            this.pbxCampaignService.successAlert('Updated Campaign!');
            this.modalService.dismissAll();
        }, err => {
            this.pbxCampaignService.errorAlert(err.message);
            this.modalService.dismissAll();
        });
    }

    loadCampaignReport() {
        this.spinner.show('campaign-widget');
        this.pbxCampaignService.getCampaignReport(this.campaign.id).subscribe((response: PbxCampaignReport) => {
            this.report = response;
            this.spinner.hide('campaign-widget');
        }, err => {
            this.report = new PbxCampaignReport();
            this.spinner.hide('campaign-widget');
        });
    }

    loadQuestionsReport() {
        this.spinner.show('questions-report');
        this.pbxCampaignService.getQuestionsReport(this.campaign.id).subscribe((response: PbxQuestionReport[]) => {
            this.questions = response;
            this.checkCategoriesList();
            this.spinner.hide('questions-report');
        }, err => {
            this.questions = [];
            this.spinner.hide('questions-report');
        });
    }

    loadOperatorsReport() {
        this.spinner.show('table-operators');
        const params = {};
        if (this.operatorsPagination?.currentPage) {
            params['page'] = this.operatorsPagination?.currentPage;
        }
        if (this.operatorFilter) {
            params['caller_name'] = this.operatorFilter;
        }
        this.pbxCampaignService.getOperatorsReport(this.campaign.id, params).subscribe((response: PbxCampaignOperatorPagination) => {
            this.operators = response.data;
            // console.log(this.operators);
            // debugger;
            this.operatorsPagination = response._meta;
            this.spinner.hide('table-operators');
        }, err => {
            this.operators = [];
            this.questionCategories = [];
            this.operatorsPagination = new MetaPagination();
            this.spinner.hide('table-operators');
        });
    }

    checkCategoriesList() {
        this.questionCategories = [];
        const categories = PbxCampaignService.questionCategories;
        this.questions.forEach(question => {
            const index = categories.findIndex(data => data.key === question.info.category);
            if (index > -1 && !this.questionCategories?.find(data => data.key === question.info.category)) {
                this.questionCategories.push(categories[index]);
            }
        });
    }

    percentage(a: any, b: any) {
        return Number(a) > 0 ? (Number(b) / Number(a) * 100).toFixed(0) : '--';
    }
}
