import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';

export class Integra extends BaseModel {
  constructor(attributes?: any) {
    super(attributes);

    if (this.data !== null && this.data !== undefined) {
      const jsonData = JSON.parse(this.data);
      //reporter
      this.firstname = jsonData['reporter']['firstname'];
      this.lastname = jsonData['reporter']['lastname'];
      this.reporter_email = jsonData['reporter']['reporter_email'];

      //customer
      this.name = jsonData['customer']['name'];
      this.surname = jsonData['customer']['surname'];
      this.birthdate = jsonData['customer']['birthdate'];
      this.customer_email = jsonData['customer']['customer_email'];
      this.city = jsonData['customer']['city'];
      this.address = jsonData['customer']['address'];
      this.phone = jsonData['customer']['phone'];

      this.loan_amount_requested = jsonData['customer']['loan_amount_requested'];
      this.purpose = jsonData['customer']['purpose'];
      this.job = jsonData['customer']['job'];
      this.permanent_occupation = jsonData['customer']['permanent_occupation'];
      this.employees_number = jsonData['customer']['employees_number'];
      this.retired_occupation = jsonData['customer']['retired_occupation'];
      this.occupation_from_date = jsonData['customer']['occupation_from_date'];
      this.monthly_income = jsonData['customer']['monthly_income'];
      this.on_going_commitments = jsonData['customer']['on_going_commitments'];
      this.on_going_loans = jsonData['customer']['on_going_loans'];
      this.last_three_years_late_payments = jsonData['customer']['last_three_years_late_payments'];
      this.last_three_months_declined_loan_request = jsonData['customer']['last_three_months_declined_loan_request'];
    }
  }

  // reporter
  id: number;
  company_name: string;
  dealer_id: number;
  firstname: string;
  lastname: string;
  reporter_email: string;
  notes: string;
  created_at: string;
  created_by: string;
  update_at: string;
  update_by: string;
  data: string;
  status: string;
  show = false;

  //customer
  name: string;
  surname: string;
  birthdate: string;
  customer_email: string;
  city: string;
  address: string;
  phone: string;
  loan_amount_requested: any;
  purpose: string;
  job: string;
  permanent_occupation: string;
  employees_number: string;
  retired_occupation: any;
  occupation_from_date: string;
  monthly_income: any;
  on_going_commitments: string;
  on_going_loans: boolean;
  last_three_years_late_payments: boolean;
  last_three_months_declined_loan_request: boolean;

  fromArray(result: []): Integra[] {
    const array: Integra[] = [];
    for (const item of result) {
      array.push(new Integra(item));
    }
    return array;
  }
}

export class IntegraProvider extends BaseModel {
  data: Integra[];
  _meta: MetaPagination;
  _links: Array<any>;
}
