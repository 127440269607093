import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlloEcTimService } from '@app/services/controllo-ec-tim.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { EcTim } from '@app/models/ec-tim';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-dealer-ec-detail-table',
  templateUrl: './dealer-ec-detail-table.component.html',
  styleUrls: ['./dealer-ec-detail-table.component.scss']
})

export class DealerEcDetailTableComponent implements OnInit, OnChanges {
  @ViewChild('chargesModalTemplate') private chargesModalTemplate;
  @Input() data: EcTim[];
  @Input() ecStatuses: any;
  @Input() isLoading: boolean;
  paid_date: Date;

  @Output() refreshEvent = new EventEmitter();

  @ViewChild('popContent') private popContent;

  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };

  invoiceId: any;
  invoiceDate: any;
  invoiceNumber:any;
  currentInvoice:any;

  selectAllByDealer: boolean;

  selectedItem: any;

  selectAllChecked: boolean = false;

  constructor(
    private service: ControlloEcTimService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {
    const currentDate = moment();
    this.invoiceDate = {
      year: currentDate.year(),
      month: currentDate.month() + 1, // La funzione month() di moment.js ritorna il mese da 0 a 11
      day: currentDate.date()
    };
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (changes.data.currentValue) {
        this.spinner.hide('table');
      } else {
        this.spinner.show('table');
      }
    }

    if (changes.isLoading) {
      if (changes.isLoading.currentValue) {
        this.spinner.hide('table');
      }
      this.spinner.show('table');
    }
  }

  openPopover(popOver: any, invoiceId, item, key, selectItems = [], itemId) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = invoiceId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }

  save(value) {

    let invoiceArray: [{ id: any; body: {}; }];

    if (value == 2) {
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        centered: true
      });

      modalRef.result.then(
        (confirm) => {
          const body = {};
          body[this.popupConfig['key']] = value;
          body['paid_date'] = confirm;

          invoiceArray = [{ id: this.popupConfig.id, body: body }]

          this.spinner.show('popupSpinner');
          this.updateInvoice(invoiceArray);

        },
        (close) => { });
    } else {

      const body = {};
      body[this.popupConfig['key']] = value;

      invoiceArray = [{ id: this.popupConfig.id, body: body }]

      this.spinner.show('popupSpinner');

      this.updateInvoice(invoiceArray);
    }
  }

  updateInvoice(selectedInvoices) {

    this.service.putEcTimInvoice(selectedInvoices).subscribe(
      (response) => {
        this.refreshEvent.emit();
        this.spinner.hide('popupSpinner');
      },
      (err) => {
        alert(err + ", impossibile proseguire.");
        this.refreshEvent.emit();
        this.spinner.hide('popupSpinner');
      },
    );

  }


  addCharges(invoice : any){
    this.currentInvoice = invoice;
    this.invoiceNumber = invoice.sectional_number;
    this.modalService.open(this.chargesModalTemplate, { size: 'md' });
    this.invoiceId = invoice.id;
  }

  dismissModal() {
    this.modalService.dismissAll();
    this.refreshEvent.emit();
  }

  setSelectedDate($event: any) {
    const date = moment($event);
    date.subtract(1, 'M');
    this.invoiceDate = date.format('DD-MM-YYYY');
  }

  sendChargesData() {
    this.isLoading = true;
    this.spinner.show('table');
    const payload = {
      invoiceId: this.invoiceId,
      invoiceNumber: this.invoiceNumber,
      invoiceDate: this.invoiceDate,
    };

    this.service.postCharges(payload).subscribe(
      (response) => {
        this.service.successAlert('inviata correttamente');
        this.isLoading = false;
        this.spinner.hide('table');
        this.dismissModal();
      },
      (error) => {
        this.isLoading = false;
        this.spinner.hide('table');
        this.service.errorAlert(error.message);
      }
    );
  }


  isSelected(invoice: any, selectedInvoices: any[]): boolean {
    return selectedInvoices.includes(invoice);
  }

  toggleSelectAllInvoices(selected: boolean, item: any) {

    let selectedInvoices = item.invoices;

    selectedInvoices.forEach((invoice) => {
      invoice.isSelected = selected
    })

    item.isSelectedAll = selected;

  }
}
