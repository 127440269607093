import { CostsManagementComponent } from './pages/gestione-incentivazione/costs-management/costs-management.component';

import { KenaVisiteComponent } from './pages/kena-visite/kena-visite.component';
import { MonthRangePickerComponent } from './widgets/month-range-picker/month-range-picker.component';
import { KenaRecapComponent } from './pages/kena-recap/kena-recap.component';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeIT from '@angular/common/locales/it';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketListComponent } from './pages/tickets/list/ticket-list.component';
import { TicketDetailComponent } from './pages/tickets/detail/ticket-detail.component';
import { NgArrayPipesModule, NgObjectPipesModule, NgPipesModule } from 'angular-pipes';
import { InvoiceListComponent } from './pages/invoices/list/invoice-list.component';
import { FaqListComponent } from './pages/faq/list/faq-list.component';
import { DocumentsListComponent } from './pages/documents/list/documents-list.component';
import { ValdemortModule } from 'ngx-valdemort';
import { ErrorLogService } from '@helpers/error-log.service';
import { GlobalErrorHandler } from '@helpers/global-error.handler';
import { ToastsContainerComponent } from '@widgets/toasts-container/toasts-container.component';
import { ShopIndexComponent } from './pages/shop/index/shop-index.component';
import { SafePipe } from '@helpers/safe.pipe';
import { MomentModule } from 'ngx-moment';
import 'moment/locale/it';
import { TicketModalComponent } from '@app/pages/tickets/modal/ticket-modal.component';
import { DocumentsModalComponent } from './pages/documents/modal/documents-modal.component';
import { PbxListComponent } from './pages/pbx/list/pbx-list.component';
import { NotFoundComponent } from './pages/main/not-found/not-found.component';
import { ResetPasswordComponent } from './pages/main/reset-password/reset-password.component';
import { CreditoIndexComponent } from './pages/credito/index/credito-index.component';
import { ProductComponent } from './pages/shop/partials/product/product.component';
import { CartComponent } from './pages/shop/partials/cart/cart.component';
import { ShopMenuComponent } from './pages/shop/partials/shop-menu/shop-menu.component';
import { ShopSubProductsPickerComponent } from './pages/shop/partials/sub-products/shop-sub-products-picker.component';
import { ShopBuyComponent } from './pages/shop/buy/shop-buy.component';
import { AddressComponent } from './pages/partials/address/address.component';
import { ShopDetailComponent } from './pages/shop/detail/shop-detail.component';
import { DocumentsCreateModalComponent } from './pages/documents/create-modal/documents-create-modal.component';
import { ShopListComponent } from './pages/shop/list/shop-list.component';
import { VideoIndexComponent } from './pages/video/index/video-index.component';
import { VisiteAgenteIndexComponent } from './pages/visite-agente/index/visite-agente-index.component';
import { DashboardRiepilogoComponent } from './pages/dashboard/partials/riepilogo/dashboard-riepilogo.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MomentDateFormatter } from '@helpers/datepicker.formatter';
import { DatepickerAdapter } from '@helpers/datepicker.adapter';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxFileDropModule } from 'ngx-file-drop';
import { InvoiceDetailComponent } from './pages/invoices/detail/invoice-detail.component';
import { PeriodSelectorComponent } from './pages/partials/period-selector/period-selector.component';
import { DataRangeComponent } from './pages/partials/data-range/data-range.component';
import { TableComponent } from './pages/partials/table/table.component';
import { FormComponent } from './pages/partials/form/form.component';
import { DialogFieldEditComponent } from './pages/partials/dialog-field-edit/dialog-field-edit.component';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { BasicAuthInterceptor } from '@helpers/basic-auth.interceptor';
import { NgBusyModule } from 'ng-busy';
import { ShopBlockedComponent } from './pages/shop/blocked/shop-blocked.component';
import { ShopPosComponent } from './pages/shop/pos/shop-pos.component';
import { AutocompleteAddressComponent } from './widgets/autocomplete-address/autocomplete-address.component';
import { ShopPrintingProductComponent } from './pages/shop/partials/printing-product/shop-printing-product.component';
import { MasterDashboardComponent } from './pages/master-dashboard/master-dashboard.component';
import { MasterCardComponent } from './pages/master-dashboard/partials/master-card/master-card.component';
import { AutoloadChartComponent } from './pages/master-dashboard/partials/autoload-chart/autoload-chart.component';
import { AutoloadRiepilogoComponent } from './pages/master-dashboard/partials/autoload-riepilogo/autoload-riepilogo.component';
import { AutoloadPuntiVenditaComponent } from './pages/master-dashboard/partials/autoload-punti-vendita/autoload-punti-vendita.component';
import { AutoloadTargetComponent } from './pages/master-dashboard/partials/autoload-target/autoload-target.component';
import { AutoloadFatturatoComponent } from './pages/master-dashboard/partials/autoload-fatturato/autoload-fatturato.component';
import { AutoloadOfferteComponent } from './pages/master-dashboard/partials/autoload-offerte/autoload-offerte.component';
import { MasterHeaderComponent } from './pages/master-dashboard/partials/master-header/master-header.component';
import { MasterSubheaderComponent } from './pages/master-dashboard/partials/master-subheader/master-subheader.component';
import { MasterProduzionePersaComponent } from './pages/master-produzione-persa/master-produzione-persa.component';
import { AutoloadTableComponent } from './pages/master-dashboard/partials/autoload-table/autoload-table.component';
import { MasterAnalisiFasceComponent } from './pages/master-analisi-fasce/master-analisi-fasce.component';
import { MasterAnalisiPlafondComponent } from './pages/master-analisi-plafond/master-analisi-plafond.component';
import { MasterAnalisiSimComponent } from './pages/master-analisi-sim/master-analisi-sim.component';
import { MasterClassificaComponent } from './pages/master-classifica/master-classifica.component';
import { MasterConfrontoComponent } from './pages/master-confronto/master-confronto.component';
import { MasterNuoveAffiliazioniComponent } from './pages/master-nuove-affiliazioni/master-nuove-affiliazioni.component';
import { MasterMappaComponent } from './pages/master-mappa/master-mappa.component';
import { AgmCoreModule } from '@agm/core';
import { MasterEmailComponent } from './pages/master-email/master-email.component';
import { MasterEmailDettaglioComponent } from './pages/master-email-dettaglio/master-email-dettaglio.component';
import { GestioneIncentivazioneComponent } from './pages/gestione-incentivazione/gestione-incentivazione.component';
import { HotTableModule } from '@handsontable/angular';
import { HotComponent } from '@widgets/hot/hot.component';
import { ModalComponent } from '@widgets/modal/modal.component';
import { GestioneFatturazioneComponent } from './pages/gestione-fatturazione/gestione-fatturazione.component';
import { MasterVisiteIndexComponent } from './pages/master-visite/master-visite-index/master-visite-index.component';
import { MasterVisiteDetailComponent } from './pages/master-visite/master-visite-detail/master-visite-detail.component';
import { AgentVisiteIndexComponent } from './pages/agent-visite/agent-visite-index/agent-visite-index.component';
import { SafeHtmlPipe } from '@helpers/safe-html.pipe';
import { MasterDashboardTabComponent } from './pages/master-dashboard/master-dashboard-tab/master-dashboard-tab.component';
import { GestioneStorniComponent } from './pages/gestione-incentivazione/gestione-storni/gestione-storni.component';
import { GestioneReportComponent } from './pages/gestione-incentivazione/gestione-report/gestione-report.component';
import { ModalQuestionsComponent } from './widgets/modal-questions/modal-questions.component';
import { DatepickerRangePopupComponent } from './widgets/datepicker-range.popup/datepicker-range.popup.component';
import { MasterDealerDettaglioComponent } from './pages/master-dealer-dettaglio/master-dealer-dettaglio.component';
import { ChartsModule } from 'ng2-charts';
import { AutoloadChartjsComponent } from './widgets/autoload-chartjs/autoload-chartjs.component';
import { AutoloadTableRiepilogoComponent } from './widgets/autoload-table-riepilogo/autoload-table-riepilogo.component';
import { AutoloadKeypointsComponent } from './widgets/autoload-keypoints/autoload-keypoints.component';
import { ShowDirective } from './directives/show.directive';
import { DontShowDirective } from './directives/dont-show.directive';
import { IsVendorEnabledDirective } from './directives/is-vendor-enabled.directive';
import { LaddaModule } from 'angular2-ladda';
import { MasterPlanningComponent } from './pages/master-planning/master-planning.component';
import { MasterTicketsComponent } from './pages/master-tickets/master-tickets.component';
import { MasterKpiComponent } from './pages/master-kpi/master-kpi.component';
import { SpinnerComponent } from './widgets/spinner/spinner.component';
import { SpinnerDirective } from './directives/spinner.directive';
import { NegozioSelectComponent } from './widgets/negozio-select/negozio-select.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgenteSelectComponent } from './widgets/agente-select/agente-select.component';
import { Daterangepicker } from 'ng2-daterangepicker';
import { AssetsPipe } from './helpers/assets.pipe';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DragDropListModule } from 'ng-drag-drop-list';
import { DisableControlDirective } from './directives/disable-input.directive';
import { CbiGeneratorComponent } from './pages/cbi-generator/cbi-generator.component';
import { AffilationsComponent } from './pages/affiliations/affilations.component';
import { InvoiceManagementComponent } from './pages/invoice-management/invoice-management.component';
import { TicketManagementComponent } from './pages/ticket-management/ticket-management.component';
import { RetailerTypeaheadComponent } from './widgets/retailer-typeahead/retailer-typeahead.component';
import { AffilationModalComponent } from './pages/affiliations/affilation-modal/affilation-modal.component';
import { RetailerManagementComponent } from './pages/retailer-management/retailer-management.component';
import { StateSelectComponent } from './widgets/state-select/state-select.component';
import { ProvinceSelectComponent } from './widgets/province-select/province-select.component';
import { CitySelectComponent } from './widgets/city-select/city-select.component';
import { VendorSelectComponent } from './widgets/vendor-select/vendor-select.component';
import { AgentSelectComponent } from './widgets/agent-select/agent-select.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StaffSelectComponent } from './widgets/staff-select/staff-select.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IsVendorDisabledDirective } from './directives/is-vendor-disabled.directive';
import { PlanPercentageComponent } from './pages/master-planning/plan-percentage/plan-percentage.component';
import { RealComponent } from '@app/pages/master-planning/real/real.component';
import { AgentsPerformanceComponent } from '@app/pages/master-planning/agents-performance/agents-performance.component';
import { KenaRecapAffiliationsComponent } from '@app/pages/kena-recap/kena-recap-affiliations/kena-recap-affiliations.component';
import { KenaRecapSalesComponent } from '@app/pages/kena-recap/kena-recap-sales/kena-recap-sales.component';
import { LoyaltyCardComponent } from './pages/loyalty-card/loyalty-card.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { CampaignModalComponent } from './pages/campaigns/campaign-modal/campaign-modal.component';
import { DataBuilderTableComponent } from './widgets/data-builder-table/data-builder-table.component';
import { ShopOrderDashboardComponent } from './pages/shop-order-dashboard/shop-order-dashboard.component';
import { PbxCampaignModalComponent } from './pages/retailer-management/pbx-campaign-modal/pbx-campaign-modal.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { KenaRecapSalesMnpComponent } from './pages/kena-recap/kena-recap-sales-mnp/kena-recap-sales-mnp.component';
import { PbxCampaignComponent } from '@app/pages/pbx/pbx-campaign/pbx-campaign.component';
import { PbxLeadsComponent } from './pages/pbx/pbx-leads/pbx-leads.component';
import { PbxCampaignDetailsComponent } from './pages/pbx/pbx-campaign-details/pbx-campaign-details.component';
import { PbxCallsComponent } from './pages/pbx/pbx-calls/pbx-calls.component';
import { PbxScheduleCallModalComponent } from './pages/pbx/pbx-schedule-call-modal/pbx-schedule-call-modal.component';
import { PbxCallModalComponent } from './pages/pbx/pbx-call-modal/pbx-call-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { TimeToTextPipe } from '@helpers/time-to-text.pipe';
import { PbxQuestionsComponent } from './pages/pbx/pbx-questions/pbx-questions.component';
import { PbxAnswersModalComponent } from '@app/pages/pbx/pbx-answers-modal/pbx-answers-modal.component';
import { TicketDashboardComponent } from './pages/ticket-dashboard/ticket-dashboard.component';
import { HideDashboardDirective } from '@app/directives/hide-dashboard.directive';
import { ShowDashboardDirective } from '@app/directives/show-dashboard.directive';
import { RichTextTruncateComponent } from './widgets/rich-text-truncate/rich-text-truncate.component';
import { ProductsComponent } from '@app/pages/e-pay/products/products.component';
import { ProductModalComponent } from '@app/pages/e-pay/products/product-modal/product-modal.component';
import { BrandsComponent } from './pages/e-pay/brands/brands.component';
import { BrandModalComponent } from './pages/e-pay/brand-modal/brand-modal.component';
import { ProductDetailComponent } from './pages/e-pay/products/product-detail/product-detail.component';
import { MasterEmailCardComponent } from './pages/master-email/master-email-card/master-email-card.component';
import { OrdersComponent } from './pages/e-pay/orders/orders.component';
import { ReceiptComponent } from './pages/e-pay/receipt/receipt.component';
import { DataBuilderModalComponent } from './widgets/data-builder-modal/data-builder-modal.component';
import { AgentGpsTrackingComponent } from './pages/agent-gps-tracking/agent-gps-tracking.component';
import { CategoryComponent } from './pages/shop/category/category.component';
import { CategoryModalComponent } from './pages/shop/category/category-modal/category-modal.component';
import { OperatorComponent } from './pages/shop/operator/operator.component';
import { OperatorModalComponent } from './pages/shop/operator/operator-modal/operator-modal.component';
import { ShopProductComponent } from './pages/shop/shop-product/shop-product.component';
import { ShopProductModalComponent } from './pages/shop/shop-product/shop-product-modal/shop-product-modal.component';
import { ShopProductDetailComponent } from './pages/shop/shop-product/shop-product-detail/shop-product-detail.component';
import { StandSelectComponent } from '@widgets/stand-select/stand-select.component';
import { AgmDirectionModule } from 'agm-direction';
import { EpaySalesComponent } from './pages/e-pay/epay-sales/epay-sales.component';
import { MasterWappCardComponent } from '@app/pages/master-email/master-wapp-card/master-wapp-card.component';
import { DealerMapComponent } from './pages/dealer-map/dealer-map.component';
import { MasterAgentSelectComponent } from './widgets/master-agent-select/master-agent-select.component';
import { EMailCampaignComponent } from './pages/e-mail-campaign/e-mail-campaign.component';
import { EMailCampaignModalComponent } from './pages/e-mail-campaign/e-mail-campaign-modal/e-mail-campaign-modal.component';
import { ShopProductTemplateComponent } from './pages/shop/shop-product/shop-product-template/shop-product-template.component';
import { ProductVariantDetailComponent } from './pages/shop/shop-product/product-variant-detail/product-variant-detail.component';
import { SmsSalesComponent } from './pages/sms-sales/sms-sales.component';
import { ReportTimelineComponent } from './pages/master-dealer-dettaglio/report-timeline/report-timeline.component';
import { ShopProductInstallmentsComponent } from './pages/shop/shop-product-installments/shop-product-installments.component';
import { ShopSalesComponent } from './pages/shop/shop-sales/shop-sales.component';
import { ChangePasswordComponent } from './pages/main/change-password/change-password.component';
import { PopupComponent } from './pages/popup/popup.component';
import { ShopOrderHistoryComponent } from './pages/shop-order-history/shop-order-history.component';
import { ShopOrderDetailComponent } from './pages/shop-order-history/shop-order-detail/shop-order-detail.component';
import { EditPopupComponent } from './pages/shop-order-history/edit-popup/edit-popup.component';
import { CountdownModule } from 'ngx-countdown';
import { ShopCreditComponent } from './pages/shop-credit/shop-credit.component';
import { CreditModalComponent } from './pages/shop-credit/credit-modal/credit-modal.component';
import { ShopAccessComponent } from './pages/shop-access/shop-access.component';
import { ShopAccessModalComponent } from './pages/shop-access/shop-access-modal/shop-access-modal.component';
import { DigitalSignatureComponent } from './pages/kd-orders/digital-signature/digital-signature.component';
import { CertificatesComponent } from './pages/kd-orders/certificates/certificates.component';
import { ShippingComponent } from './pages/kd-orders/shipping/shipping.component';
import { PackagesComponent } from './pages/kd-orders/packages/packages.component';
import { PecComponent } from './pages/kd-orders/pec/pec.component';
import { TicketsComponent } from './pages/kd-orders/tickets/tickets.component';
import { PrintingListComponent } from './pages/kd-orders/printing-list/printing-list.component';
import { RacesComponent } from './pages/races/races.component';
import { RacesFormComponent } from './pages/races/races-form/races-form.component';
import { GroupComponent } from './pages/group/group.component';
import { GroupModalComponent } from './pages/group/group-modal/group-modal/group-modal.component';
import { GroupDealerModalComponent } from './pages/group/group-modal/group-modal/group-dealer-modal/group-dealer-modal.component';
import { RacesFilesModalComponent } from './pages/races/races-form/races-files-modal/races-files-modal.component';
import { RacesDealersComponent } from './pages/races/races-form/races-dealers/races-dealers.component';
import { LoginVerificationComponent } from './pages/login-verification/login-verification.component';
import { PostalSlipComponent } from './pages/kd-orders/postal-slip/postal-slip.component';
import { DealerDetailComponent } from '@app/pages/master-dealer-dettaglio/dealer-detail/dealer-detail.component';
import { KdSalesComponent } from '@app/pages/kd-orders/kd-sales/kd-sales.component';
import { InvoiceTypesComponent } from './pages/invoices/invoice-types/invoice-types.component';
import { InvoiceUploadComponent } from './pages/invoices/invoice-upload/invoice-upload.component';
import { SuretyCreditComponent } from './pages/surety-credit/surety-credit.component';
import { BulkAssignDealerComponent } from './pages/bulk-assign-dealer/bulk-assign-dealer.component';
import { UserListComponent } from '@app/pages/user-list/user-list.component';
import { ChannelSelectComponent } from '@widgets/channel-select/channel-select.component';
import { ClusterSelectComponent } from '@widgets/cluster-select/cluster-select.component';
import { ReadyMailComponent } from '@app/pages/kd-orders/ready-mail/ready-mail.component';
import { AmazonCounterComponent } from '@app/pages/kd-orders/amazon-counter/amazon-counter.component';
import { SpidIrComponent } from './pages/kd-orders/spid-ir/spid-ir.component';
import { AmexComponent } from './pages/kd-orders/amex/amex.component';
import { SddComponent } from './pages/kd-orders/sdd/sdd.component';
import { SddModalComponent } from './pages/kd-orders/sdd/sdd-modal/sdd-modal.component';
import { SpidCreditComponent } from './pages/spid-credit/spid-credit.component';
import { RiaComponent } from './pages/kd-orders/ria/ria.component';
import { TextPopupComponent } from './widgets/text-popup/text-popup.component';
import { AmexModalComponent } from './pages/kd-orders/amex/amex-modal/amex-modal.component';
import { A2aComponent } from './pages/a2a/a2a.component';
import { PbxModalLeadsComponent } from './pages/pbx/pbx-leads/pbx-modal-leads/pbx-modal-leads.component';
import { KdTaxComponent } from './pages/kd-orders/kd-tax/kd-tax.component';
import { SurveyComponent } from './pages/master-dealer-dettaglio/survey/survey.component';
import { CuComponent } from './pages/cu/cu.component';
import { CuModalComponent } from './pages/cu/cu-modal/cu-modal.component';
import { FlixbusComponent } from './pages/flixbus/flixbus.component';
import { PartnershipComponent } from './pages/partnership/partnership.component';
import { PartnershipModalComponent } from './pages/partnership/partnership-modal/partnership-modal.component';
import { ControlloEcTimComponent } from './pages/controllo-ec-tim/controllo-ec-tim.component';
import { DealerEcDetailTableComponent } from './pages/controllo-ec-tim/dealer-ec-detail-table/dealer-ec-detail-table.component';
import { ConfirmPopupComponent } from './pages/controllo-ec-tim/confirm-popup/confirm-popup.component';
import { ConfirmModalComponent } from './pages/controllo-ec-tim/confirm-modal/confirm-modal.component';
import { OrdersControlComponent } from './pages/orders-control/orders-control.component';
import { OrdersControlBonificoComponent } from './pages/orders-control/orders-control-bonifico/orders-control-bonifico.component';
import { OrdersControlCartaDiCreditoComponent } from './pages/orders-control/orders-control-carta-di-credito/orders-control-carta-di-credito.component';
import { OrdersControlCompensazioneComponent } from './pages/orders-control/orders-control-compensazione/orders-control-compensazione.component';
import { IntegraComponent } from './pages/kd-orders/integra/integra.component';
import { BankAccountComponent } from './pages/invoice/bank-account/bank-account.component';
import { BankTransaction } from './models/invoice/bank-transaction';
import { BankTransactionComponent } from './pages/invoice/bank-transaction/bank-transaction.component';
import { CalendarComponent } from './pages/invoice/calendar/calendar.component';
import { DocumentModalComponent } from './pages/invoice/document-modal/document-modal.component';
import { DocumentViewComponent } from './pages/invoice/document-view/document-view.component';
import { DocumentsComponent } from './pages/invoice/documents/documents.component';
import { InvoiceDashboardComponent } from './pages/invoice/invoice-dashboard/invoice-dashboard.component';
import { InvoiceReportComponent } from './pages/invoice/invoice-report/invoice-report.component';
import { PaymentComponent } from './pages/invoice/payment/payment.component';
import { PaymentModalComponent } from './pages/invoice/payment-modal/payment-modal.component';
import { PaymentViewComponent } from './pages/invoice/payment-view/payment-view.component';
import { SubjectReportComponent } from './pages/invoice/subject-report/subject-report.component';
import { SubjectModalComponent } from './pages/invoice/subject-modal/subject-modal.component';
import { SubjectsComponent } from './pages/invoice/subjects/subjects.component';
import { SubjectViewComponent } from './pages/invoice/subject-view/subject-view.component';
import { OrdersControlContrassegnoComponent } from './pages/orders-control/orders-control-contrassegno/orders-control-contrassegno.component';
import { OrdersControlRimessaAgenteComponent } from './pages/orders-control/orders-control-rimessa-agente/orders-control-rimessa-agente.component';
import { OrdersControlOmaggioComponent } from './pages/orders-control/orders-control-omaggio/orders-control-omaggio.component';
import { OrdersControlCompensazioneAnticipataComponent } from './pages/orders-control/orders-control-compensazione-anticipata/orders-control-compensazione-anticipata.component';
import { OrdersControlAssegnoVistaCorriereComponent } from './pages/orders-control/orders-control-assegno-vista-corriere/orders-control-assegno-vista-corriere.component';
import { CdcModalComponent } from './pages/orders-control/orders-control-carta-di-credito/cdc-modal/cdc-modal.component';
import { BonificoModalComponent } from './pages/orders-control/orders-control-bonifico/bonifico-modal/bonifico-modal.component';
import { CdcModalImportComponent } from './pages/orders-control/orders-control-carta-di-credito/cdc-modal-import/cdc-modal-import.component';
import { ContrassegnoModalComponent } from './pages/orders-control/orders-control-contrassegno/contrassegno-modal/contrassegno-modal.component';
import { GiftCardComponent } from './pages/kd-orders/gift-card/gift-card.component';
import { CuMassiveUpdateModalComponent } from './pages/cu/cu-massive-update-modal/cu-massive-update-modal.component';
import { VisureCameraliComponent } from './pages/kd-orders/visure-camerali/visure-camerali.component';
import { VisureCatastaliComponent } from './pages/kd-orders/visure-catastali/visure-catastali.component';
import { KdcubeComponent } from './pages/kdcube/kdcube.component';
import { A2aContrattiComponent } from './pages/a2a-contratti/a2a-contratti.component';
import { KpiTypeComponent } from './pages/kpi-type/kpi-type.component';
import { KpiAnalisiComponent } from './pages/kpi-analisi/kpi-analisi.component';
import { KpiSelectComponent } from './widgets/kpi-select/kpi-select.component';
import { OlivettiComponent } from './pages/kd-orders/olivetti/olivetti.component';
import { KaskoComponent } from './pages/kd-orders/kasko/kasko.component';
import { DealerOverviewComponent } from './widgets/dealer-overview/dealer-overview.component';
import { KpiRecapComponent } from './pages/kpi-recap/kpi-recap.component';
import { DvaComponent } from '@app/pages/kd-orders/dva/dva.component';

import { ShopAffiliationListComponent } from './pages/shop-affiliation-list/shop-affiliation-list.component';
import { ShopListDetailComponent } from './pages/shop-affiliation-list/shop-list-detail/shop-list-detail.component';
import { KpiNotesComponent } from './pages/kpi-notes/kpi-notes.component';
import { SuretyOrdersComponent } from './pages/surety-credit/surety-orders/surety-orders.component';
import { MailGeneratorComponent } from './pages/mail-generator/mail-generator.component';
import { MailDetailComponent } from './pages/mail-generator/mail-detail/mail-detail.component';
import { MailLogComponent } from './pages/mail-generator/mail-log/mail-log.component';
import { SubMasterSelectComponent } from './widgets/submaster-select/submaster-select.component';
import { CalendarioVisiteComponent } from './pages/calendario-visite/calendario-visite.component';
import { WhatsappGeneratorComponent } from './pages/whatsapp-generator/whatsapp-generator.component';
import { WhatsappLogComponent } from './pages/whatsapp-generator/whatsapp-log/whatsapp-log.component';
import { WhatsappDetailComponent } from './pages/whatsapp-generator/whatsapp-detail/whatsapp-detail.component';
import { MatchRagsocialeComponent } from './pages/match-ragsociale/match-ragsociale.component';
import { SuretyRequestsComponent } from './pages/surety-requests/surety-requests.component';
import { RendicontiAddebitoComponent } from './pages/rendiconti-addebito/rendiconti-addebito.component';
import { KdAssicuraComponent } from './pages/kd-orders/kd-assicura/kd-assicura.component';
import { KpiDashboardComponent } from './pages/kpi-dashboard/kpi-dashboard.component';
import { BusinessUnitsComponent } from './pages/business-plan/business-units/business-units.component';
import { BusinessUnitEditorComponent } from './pages/business-plan/business-unit-editor/business-unit-editor.component';
import {
    BusinessUnitViewerComponent
} from '@app/pages/business-plan/business-plan-viewer/business-unit-viewer.component';

FullCalendarModule.registerPlugins([
    // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin,
]);

registerLocaleData(localeIT, 'it');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        ProfileComponent,
        DashboardComponent,
        MessagesDropdownMenuComponent,
        NotificationsDropdownMenuComponent,
        TicketListComponent,
        TicketDetailComponent,
        InvoiceListComponent,
        FaqListComponent,
        DocumentsListComponent,
        ToastsContainerComponent,
        ShopIndexComponent,
        SafePipe,
        SafeHtmlPipe,
        TimeToTextPipe,
        TicketModalComponent,
        DocumentsModalComponent,
        PbxListComponent,
        NotFoundComponent,
        ResetPasswordComponent,
        CreditoIndexComponent,
        ProductComponent,
        CartComponent,
        ShopMenuComponent,
        ShopSubProductsPickerComponent,
        ShopBuyComponent,
        AddressComponent,
        ShopDetailComponent,
        DocumentsCreateModalComponent,
        ShopListComponent,
        VideoIndexComponent,
        VisiteAgenteIndexComponent,
        DashboardRiepilogoComponent,
        InvoiceDetailComponent,
        PeriodSelectorComponent,
        DataRangeComponent,
        TableComponent,
        FormComponent,
        DialogFieldEditComponent,
        ShopBlockedComponent,
        ShopPosComponent,
        AutocompleteAddressComponent,
        ShopPrintingProductComponent,
        MasterDashboardComponent,
        MasterCardComponent,
        AutoloadChartComponent,
        AutoloadRiepilogoComponent,
        AutoloadPuntiVenditaComponent,
        AutoloadTargetComponent,
        AutoloadFatturatoComponent,
        AutoloadOfferteComponent,
        MasterHeaderComponent,
        MasterSubheaderComponent,
        MasterProduzionePersaComponent,
        AutoloadTableComponent,
        MasterAnalisiFasceComponent,
        MasterAnalisiPlafondComponent,
        MasterAnalisiSimComponent,
        MasterClassificaComponent,
        MasterConfrontoComponent,
        MasterNuoveAffiliazioniComponent,
        MasterMappaComponent,
        MasterEmailComponent,
        MasterEmailDettaglioComponent,
        GestioneIncentivazioneComponent,
        HotComponent,
        ModalComponent,
        GestioneFatturazioneComponent,
        MasterVisiteIndexComponent,
        MasterVisiteDetailComponent,
        AgentVisiteIndexComponent,
        MasterDashboardTabComponent,
        GestioneStorniComponent,
        GestioneReportComponent,
        ModalQuestionsComponent,
        DatepickerRangePopupComponent,
        MasterDealerDettaglioComponent,
        AutoloadChartjsComponent,
        AutoloadTableRiepilogoComponent,
        AutoloadKeypointsComponent,
        HideDashboardDirective,
        ShowDashboardDirective,
        ShowDirective,
        DontShowDirective,
        SpinnerDirective,
        DisableControlDirective,
        MasterPlanningComponent,
        MasterTicketsComponent,
        MasterKpiComponent,
        SpinnerComponent,
        NegozioSelectComponent,
        AgenteSelectComponent,
        CalendarioVisiteComponent,
        AssetsPipe,
        AffilationsComponent,
        IsVendorEnabledDirective,
        IsVendorDisabledDirective,
        CbiGeneratorComponent,
        InvoiceManagementComponent,
        TicketManagementComponent,
        RetailerTypeaheadComponent,
        AffilationModalComponent,
        StateSelectComponent,
        ProvinceSelectComponent,
        CitySelectComponent,
        ChannelSelectComponent,
        ClusterSelectComponent,
        VendorSelectComponent,
        AgentSelectComponent,
        StaffSelectComponent,
        RetailerManagementComponent,
        UserListComponent,
        MonthRangePickerComponent,
        KenaRecapComponent,
        PlanPercentageComponent,
        AgentsPerformanceComponent,
        KenaVisiteComponent,
        RealComponent,
        CostsManagementComponent,
        KenaRecapSalesComponent,
        KenaRecapSalesMnpComponent,
        KenaRecapAffiliationsComponent,
        LoyaltyCardComponent,
        CampaignsComponent,
        CampaignModalComponent,
        DataBuilderTableComponent,
        ShopOrderDashboardComponent,
        PbxCampaignModalComponent,
        PbxCampaignComponent,
        PbxLeadsComponent,
        PbxCampaignDetailsComponent,
        PbxCallsComponent,
        PbxScheduleCallModalComponent,
        PbxCallModalComponent,
        PbxAnswersModalComponent,
        PbxQuestionsComponent,
        RichTextTruncateComponent,
        TicketDashboardComponent,
        ProductsComponent,
        ProductModalComponent,
        BrandsComponent,
        BrandModalComponent,
        ProductDetailComponent,
        MasterEmailCardComponent,
        DataBuilderModalComponent,
        ProductDetailComponent,
        OrdersComponent,
        ReceiptComponent,
        AgentGpsTrackingComponent,
        CategoryComponent,
        CategoryModalComponent,
        OperatorComponent,
        OperatorModalComponent,
        ShopProductComponent,
        ShopProductModalComponent,
        ShopProductDetailComponent,
        StandSelectComponent,
        EpaySalesComponent,
        MasterWappCardComponent,
        DealerMapComponent,
        MasterAgentSelectComponent,
        SubMasterSelectComponent,
        EMailCampaignComponent,
        EMailCampaignModalComponent,
        ShopProductTemplateComponent,
        ProductVariantDetailComponent,
        SmsSalesComponent,
        ReportTimelineComponent,
        ShopProductInstallmentsComponent,
        ShopSalesComponent,
        ChangePasswordComponent,
        PopupComponent,
        PopupComponent,
        ShopOrderHistoryComponent,
        ShopOrderDetailComponent,
        EditPopupComponent,
        ShopCreditComponent,
        CreditModalComponent,
        ShopAccessComponent,
        ShopAccessModalComponent,
        DigitalSignatureComponent,
        CertificatesComponent,
        ShippingComponent,
        PackagesComponent,
        PecComponent,
        TicketsComponent,
        PrintingListComponent,
        RacesComponent,
        RacesFormComponent,
        GroupComponent,
        GroupModalComponent,
        GroupDealerModalComponent,
        RacesFilesModalComponent,
        RacesFilesModalComponent,
        RacesDealersComponent,
        LoginVerificationComponent,
        PostalSlipComponent,
        DealerDetailComponent,
        KdSalesComponent,
        InvoiceTypesComponent,
        InvoiceUploadComponent,
        SuretyCreditComponent,
        BulkAssignDealerComponent,
        ReadyMailComponent,
        AmazonCounterComponent,
        SpidIrComponent,
        AmexComponent,
        SddComponent,
        SddModalComponent,
        SpidCreditComponent,
        RiaComponent,
        KdTaxComponent,
        TextPopupComponent,
        AmexModalComponent,
        A2aComponent,
        PbxModalLeadsComponent,
        SurveyComponent,
        CuComponent,
        CuModalComponent,
        FlixbusComponent,
        PartnershipComponent,
        PartnershipModalComponent,
        ControlloEcTimComponent,
        DealerEcDetailTableComponent,
        ConfirmPopupComponent,
        ConfirmModalComponent,
        OrdersControlComponent,
        OrdersControlBonificoComponent,
        OrdersControlCartaDiCreditoComponent,
        OrdersControlCompensazioneComponent,
        IntegraComponent,
        OlivettiComponent,
        BankAccountComponent,
        // BankTransaction,
        BankTransactionComponent,
        CalendarComponent,
        DocumentModalComponent,
        DocumentViewComponent,
        DocumentsComponent,
        InvoiceDashboardComponent,
        InvoiceReportComponent,
        PaymentComponent,
        PaymentModalComponent,
        PaymentViewComponent,
        SubjectReportComponent,
        SubjectModalComponent,
        SubjectsComponent,
        SubjectViewComponent,
        OrdersControlContrassegnoComponent,
        OrdersControlRimessaAgenteComponent,
        OrdersControlOmaggioComponent,
        OrdersControlCompensazioneAnticipataComponent,
        OrdersControlAssegnoVistaCorriereComponent,
        CdcModalComponent,
        BonificoModalComponent,
        CdcModalImportComponent,
        ContrassegnoModalComponent,
        GiftCardComponent,
        CuMassiveUpdateModalComponent,
        VisureCameraliComponent,
        VisureCatastaliComponent,
        A2aContrattiComponent,
        KpiTypeComponent,
        KpiAnalisiComponent,
        KpiRecapComponent,
        KpiSelectComponent,
        KpiNotesComponent,
        KpiDashboardComponent,
        KdcubeComponent,
        A2aContrattiComponent,
        KaskoComponent,
        DealerOverviewComponent,
        ShopAffiliationListComponent,
        ShopListDetailComponent,
        SuretyOrdersComponent,
        DvaComponent,
        MailGeneratorComponent,
        MailLogComponent,
        MailDetailComponent,
        WhatsappGeneratorComponent,
        WhatsappDetailComponent,
        WhatsappLogComponent,
        MatchRagsocialeComponent,
        SuretyRequestsComponent,
        RendicontiAddebitoComponent,
        KdAssicuraComponent,
        BusinessUnitsComponent,
        BusinessUnitEditorComponent,
        BusinessUnitViewerComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        NgbModule,
        NgArrayPipesModule,
        NgObjectPipesModule,
        FormsModule,
        NgPipesModule,
        ValdemortModule,
        FullCalendarModule,
        MomentModule,
        NgxSpinnerModule,
        NgSelectModule,
        NgxFileDropModule,
        NgBusyModule,
        CommonModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAU1bDjb98aRhTpRq6EVDHkuK1Y-r7jxA0',
            /* apiKey is required, unless you are a
            premium customer, in which case you can
            use clientId
            */
        }),
        AgmDirectionModule,
        HotTableModule,
        ChartsModule,
        LaddaModule,
        GooglePlaceModule,
        SweetAlert2Module.forRoot(),
        Daterangepicker,
        DragDropListModule,
        // NgBusyModule.forRoot(new BusyConfig({
        //     message: 'Caricamento...',
        //     backdrop: true,
        //     minDuration: 600
        // }))
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        EditorModule,
        NgxMaskModule.forRoot(),
        CountdownModule,
    ],
    entryComponents: [SpinnerComponent, ConfirmModalComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'it' },
        { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
        { provide: NgbDateAdapter, useClass: DatepickerAdapter },
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        GlobalErrorHandler,
        ErrorLogService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
