import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MetaPagination } from '@app/models/meta-pagination';
import { User } from '@app/models/user';
import { Whatsapp, WhatsappProvider } from '@app/models/whatsapp-generator';
import { AuthenticationService } from '@app/services/authentication.service';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { WhatsappGeneratorService } from '@app/services/whatsapp-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-whatsapp-generator',
    templateUrl: './whatsapp-generator.component.html',
})
export class WhatsappGeneratorComponent implements OnInit {
    form: FormGroup;
    @ViewChild('waModalTemplate') private waModalTemplate;
    slugList: [] = [];
    selectedSendingType: string = null;
    selectedReceiver: string = null;
    ordersPagination: MetaPagination;
    waList: Whatsapp[];
    isDealer = false;
    deleteSubscriptionOptions: ConfirmOptionsService;
    isEdit = true;

    roleSelected: { am: boolean; ka: boolean; dc: boolean };

    receiversDict: { [key: string]: string } = {
        masterAgent: 'Area Manager',
        keyAccount: 'Key Account',
        subMaster: 'Direttore Commerciale',
        dealer: 'dealer',
    };

    receivers = [
        { key: 'Agente', value: 'masterAgent' },
        { key: 'Key Account', value: 'keyAccount' },
        { key: 'Direttore Commerciale', value: 'subMaster' },
    ];

    operators = [
        { value: 16, key: 'A2A' },
        { value: 6, key: 'KENA' },
        { value: 11, key: 'TIM' },
    ];

    sendingTypes = [
        { value: 'number', key: 'Numero' },
        { value: 'group', key: 'Gruppo' },
        { value: 'multiple', key: 'Multi' },
    ];

    selectedOperator: number;
    selectedDest: string;

    filters = {
        nome: null,
        slug: null,
        date: null,
    };

    apiDate = {
        from: '',
        to: '',
    };

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        },
    };

    constructor(
        private dateRangePickerOptions: DaterangepickerConfig,
        public modalService: NgbModal,
        public formBuilder: FormBuilder,
        private spinner: NgxSpinnerService,
        private service: WhatsappGeneratorService,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.isDealer = user && user.profile.role === 'dealer';
        });

        this.deleteSubscriptionOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla',
        });

        this.service.getSlugList().subscribe((res) => {
            this.slugList = res;
        });
    }

    ngOnInit(): void {
        this.loadList();
        this.form = this.formBuilder.group({
            nome: ['', Validators.required],
            operatori: ['', Validators.required],
            tipo_destinatario: ['', Validators.required],
            destinazione: ['', Validators.required],
            tipo_invio: ['', Validators.required],
            slug: ['', Validators.required],
        });
    }

    loadList() {
        this.spinner.show('table-orders');

        const params = {};

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        if (this.selectedOperator) {
            params['operator_id'] = this.selectedOperator;
        }

        if (this.selectedDest) {
            params['destinatario'] = this.selectedDest;
        }

        if (this.filters.nome) {
            params['nome'] = this.filters.nome;
        }

        if (this.filters.slug) {
            params['slug'] = this.filters.slug;
        }

        if (this.selectedSendingType) {
            params['tipo_invio'] = this.selectedSendingType;
        }

        if (this.filters.date) {
            params['from'] = this.apiDate?.from;
            params['to'] = this.apiDate?.to;
        }

        this.service.getWaList(params).subscribe(
            (res) => {
                this.waList = new Whatsapp().fromArray(res.data);
                this.ordersPagination = res._meta;
                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    save() {
        if (this.form.valid) {
            const operatoriArray = this.form.value.operatori;
            const operatoriObj = {};
            operatoriArray.forEach((value, index) => {
                operatoriObj[`operatore${index + 1}`] = value;
            });

            const payload = {
                nome: this.form.value.nome,
                slug: this.form.value.slug,
                tipo_invio: this.form.value.tipo_invio,
                tipo_destinatario: this.selectedReceiver,
                destinazione: this.form.value.destinazione,
                operatori: JSON.stringify(operatoriObj),
            };

            this.service.postWa(payload).subscribe(
                (response: any) => {
                    this.service.successAlert('Salvato con successo');
                    this.dismissModal();
                },
                (err) => {
                    this.service.errorAlert(err);
                    this.dismissModal();
                },
            );
        }
    }

    deleteWa(id: number) {
        this.service.delete(id).subscribe(
            (res) => {
                const index = this.waList.findIndex((dealer) => dealer.id === id);
                if (index > -1) {
                    this.service.successAlert('Deleted Service!');
                    this.loadList();
                }
            },
            (err) => {
                console.log('Error deleting: ', err.message);
                this.service.errorAlert(err.message);
            },
        );
    }

    dismissModal() {
        this.form.reset();
        this.modalService.dismissAll();
        this.loadList();
    }

    showModal(): void {
        this.modalService.open(this.waModalTemplate, { size: 'lg' });
    }

    onSendingTypeChange(event: any) {}

    setOperators(event: any) {
        if (event) {
            this.form.controls['operatori'].setValue(event);
        }
    }

    setRecievers() {
        const destinatariSelezionati = this.form.get('tipo_destinatario').value;
        const destinatariObj = {};
        destinatariSelezionati.forEach((value, index) => {
            destinatariObj[`destinatario${index + 1}`] = value;
        });
        this.selectedReceiver = JSON.stringify(destinatariObj);
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filters.date =
            startDate.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            endDate.format(localDateFormat);
    }

    watchLog(item: any) {
        const id = item.id;
        this.router.navigate(['/wa-log', id]);
    }

    watchDetail(item: any) {
        const id = item.id;
        const sendType = item.tipo_invio;
        this.roleSelected = item.role;

        this.router.navigate([
            '/wa-subscription',
            id,
            sendType,
            {
                am: this.roleSelected.am,
                ka: this.roleSelected.ka,
                dc: this.roleSelected.dc,
            },
        ]);
    }
}
