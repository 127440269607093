import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';
import {DealerView} from '@models/dealer-view';

export class VisureCamerali extends BaseModel {
    id: number;
    company_name: string;
    service: string;
    dealer_id: string;
    date: string;
    created_at: string;
    denominazione: string;
    tipo_visura: string;
    transaction_id: string;
    status: string;
    prezzo: string;

    public static statusBadge = [
        {
            label: 'Completato',
            key: 'completed',
        },
        {
            label: 'In attesa',
            key: 'pending',
        },
        {
            label: 'Dati disponibili',
            key: 'Dati disponibili',
        }
    ]

    public static tipoVisura = [
        {
          lable: "Ordinaria impresa individuale",
          key: "ordinaria-impresa-individuale"
        },
        {
          lable: "Storica impresa individuale",
          key: "storica-impresa-individuale"
        },
        {
          lable: "Ordinaria societa capitale",
          key: "ordinaria-societa-capitale"
        },
        {
          lable: "Storica societa capitale",
          key: "storica-societa-capitale"
        }
    ]

    static tipoVisuraTitle = {
        'ordinaria-impresa-individuale' : 'Ordinaria impresa individuale',
        'storica-impresa-individuale' : 'Storica impresa individuale',
        'ordinaria-societa-capitale' : 'Ordinaria societa capitale',
        'storica-societa-capitale' : 'Storica societa capitale'
    }

    get tipoVisura(){
        return VisureCamerali.tipoVisuraTitle[this.tipo_visura];
    }

    fromArray(result: []): VisureCamerali[] {
        const array: VisureCamerali[] = [];
        for (const item of result) {
            array.push(new VisureCamerali(item));
        }
        return array;
    }
}

export class VisureCameraliProvider extends BaseModel {
    data: VisureCamerali[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class VisureCatastali extends BaseModel {
    id: number;
    company_name: string;
    service: string;
    dealer_id: string;
    date: string;
    created_at: string;
    richiedente: string;
    tipo_richiesta: string;
    operazione: string;
    transaction_id: string;
    status: string;
    prezzo: string;

    public static statusBadge = [
        {
            label: 'Completato',
            key: 'completed',
        },
        {
            label: 'In attesa',
            key: 'pending',
        },
        {
            label: 'Evasa',
            key: 'evasa',
        }
    ]

    public static tipoRichiesta = [
        {
          lable: "Visura soggetto",
          key: "visura-soggetto"
        },
        {
          lable: "Visura immobile",
          key: "visura-immobile"
        }
    ]

    static tipoRichiestaTitle = {
        'visura-soggetto' : 'Visura soggetto',
        'visura-immobile' : 'Visura immobile'
    }

    get tipoRichiesta(){
        return VisureCatastali.tipoRichiestaTitle[this.tipo_richiesta];
    }

    fromArray(result: []): VisureCatastali[] {
        const array: VisureCatastali[] = [];
        for (const item of result) {
            array.push(new VisureCatastali(item));
        }
        return array;
    }
}

export class VisureCatastaliProvider extends BaseModel {
    data: VisureCatastali[];
    _meta: MetaPagination;
    _links: Array<any>;
}

