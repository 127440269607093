<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadAdvices()">
    <div class="form-row">
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="companyName">Company name</label>
          <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="companyName"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Dealer ID</label>
          <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control" />
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Status</label>
          <!--STATUS -->
          <ng-select
            [clearable]="true"
            class="disableClear"
            [(ngModel)]="filter.status"
            [items]="kdTaxStatus"
            bindLabel="label"
            name="status"
            id="status"
            bindValue="key"
            [searchable]="true"
          ></ng-select>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Date</label>
          <input
            id="documentDate"
            autocomplete="off"
            type="text"
            daterangepicker
            [options]="pickerOptions"
            [(ngModel)]="filter.date"
            name="date"
            class="form-control"
            (selected)="generateDateRangeString($event.start, $event.end)"
          />
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <button class="btn btn-info btn-block" translate type="submit">Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Tabella -->

<div class="card">
  <div class="card-header">
    <h4 class="mb-0">KD TAX</h4>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="default"
      color="#fff"
      type="square-jelly-box"
      name="table-orders"
      [fullScreen]="false"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th translate>Id</th>
          <th translate>Company name</th>
          <th translate>Note</th>
          <th translate>Date</th>
          <th translate>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ngx-spinner
          bdColor="rgba(0, 0, 0, 0.8)"
          size="default"
          color="#fff"
          type="square-jelly-box"
          name="orders"
          [fullScreen]="false"
        >
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngIf="kdTaxAdvices.length == 0">
          <td translate colspan="7" class="text-center">List is empty</td>
        </tr>
        <tr *ngFor="let advice of kdTaxAdvices">
          <td>
            {{ advice?.id }}
          </td>
          <td>
            <a [routerLink]="['/dealer', advice?.dealer_id]" target="_blank">{{ advice.company_name }}</a>
          </td>
          <!-- text-nowrap text-left truncate overflow-hidden -->
          <td *ngIf="advice?.notes">
            <button
              href="javascript:void(0)"
              class="btn px-0 bg-transparent text-left"
              (click)="openPopover(statusPopover, advice?.id, advice?.notes, 'notes')"
              #statusPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContentNotes"
              popoverTitle="Modifica nota"
            >
              <span class="d-inline-block text-truncate" style="width: 200px">{{ advice?.notes }}</span>
            </button>
          </td>
          <td *ngIf="advice?.notes == '' || advice?.notes == null">
            <button
              href="javascript:void(0)"
              class="btn text-primary px-0 bg-transparent"
              (click)="openPopover(statusPopover, advice?.id, advice?.notes, 'notes')"
              #statusPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContentNotes"
              popoverTitle="Scrivi una nota"
            >
              Aggiungi una nota >
            </button>
          </td>
          <td>
            {{ advice?.created_at | amDateFormat : 'DD/MM/YYYY HH:mm' }}
          </td>
          <td>
            <button
              href="javascript:void(0)"
              class="btn btn-sm px-3 btn-block"
              (click)="openPopover(typePopover, advice?.id, advice?.status, 'status', kdTaxStatus)"
              #typePopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContentStatus"
              popoverTitle="Cambia valore"
              [ngClass]="{
                'btn-info': advice.status === 'pending',
                'btn-success': advice.status === 'confirmed',
                'btn-danger': advice.status === 'cancelled'
              }"
            >
              {{ advice?.kdTaxStatusLabel }}
            </button>
          </td>
          <td class="text-center">
            <a href="{{ advice?.budget_file_path }}"><i class="fas fa-download text-primary"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" *ngIf="kdTaxAdvices?.length > 0">
    <span
      ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b>
      {{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.
      <a class="link" (click)="exportSdd()">Esporta la lista completa</a>
    </span>
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      (pageChange)="loadAdvices()"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>

<ng-template #popContentStatus>
  <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
<ng-template #popContentNotes>
  <app-text-popup [config]="popupConfig" (onSave)="save($event)"></app-text-popup>
</ng-template>
