import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-kd-sales',
    templateUrl: './kd-sales.component.html',
    styleUrls: ['./kd-sales.component.css']
})
export class KdSalesComponent implements OnInit {


    urlClassificaItalia: string;
    urlClassificaAgenti: string;
    urlClassificaRegioni: string;

    constructor() {
    }

    ngOnInit(): void {
        // this.urlClassificaItalia = `shop-orders/sales`;
        // this.urlClassificaAgenti = `shop-orders/ranking-manager`;
        // this.urlClassificaRegioni = `shop-orders/ranking-region`;
    }

    updateUrls(operatore) {
        this.urlClassificaItalia = `shop-orders/kd-sales?vendor=${operatore}`;
        this.urlClassificaAgenti = `shop-orders/kd-ranking-manager?vendor=${operatore}`;
        this.urlClassificaRegioni = `shop-orders/kd-ranking-region?vendor=${operatore}`;
    }

}
