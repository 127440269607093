<script src="kpi-dashboard.component.ts"></script>
<div class="card bg-gradient-dark fixed-header">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img
                class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1"
                src="assets/img/flats/gear.png"
                width="160px"
            />
            <div class="d-block w-100 pl-lg-3">
                <h1 class="display-4">Dashboard KPI</h1>
                <hr />
                <div class="form-row">
                    <div class="col-12 col-md-3 mb-2 mb-md-0">
                        <label>Seleziona mese e anno</label>
                        <app-month-range-picker
                            [singleMonthPicker]="true"
                            (selected)="setSelectedDateRange($event)"
                            [defaultMonthRange]="defaultMonth"
                            [disabled]="pafToggle"
                        ></app-month-range-picker>
                    </div>
                    <div class="col-12 col-md-1 d-flex justify-content-start align-items-end">
                        <div class="custom-control custom-switch">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch1"
                                (change)="togglePaf()"
                            />
                            <label class="custom-control-label" for="customSwitch1">PAF</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-12 col-md-6 mb-4 mb-md-0">
            <div class="card h-100">
                <div class="card-body d-flex p-3 flex-column justify-content-center align-items-center h-100">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="container-fluid">
                        <div class="col-12 d-flex justify-content-center flex-column align-items-center mb-3">
                            <img src="/assets/img/flats/handshake.png" height="64" />
                            <p class="lead text-md text-center mt-2">Affiliazioni VS Target</p>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded mr-2 p-2"
                            >
                                <img src="/assets/img/brands/kena.png" height="20" />
                                <ng-container *ngIf="dashboardData?.affiliazioni.kena.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.affiliazioni.kena.paf <
                                                    dashboardData?.affiliazioni.kena.target,
                                                'text-success':
                                                    dashboardData?.affiliazioni.kena.paf >=
                                                    dashboardData?.affiliazioni.kena.target
                                            }"
                                        >
                                            {{ dashboardData?.affiliazioni.kena.paf }}
                                        </span>
                                        vs
                                        {{ dashboardData?.affiliazioni.kena.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.affiliazioni.kena.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.affiliazioni.kena.value <
                                                    dashboardData?.affiliazioni.kena.target,
                                                'text-success':
                                                    dashboardData?.affiliazioni.kena.value >=
                                                    dashboardData?.affiliazioni.kena.target
                                            }"
                                        >
                                            {{ dashboardData?.affiliazioni.kena.value }}
                                        </span>
                                        vs
                                        {{ dashboardData?.affiliazioni.kena.target }}
                                    </p>
                                </ng-container>
                            </div>
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded mr-2 p-2"
                            >
                                <img src="/assets/img/brands/tim.png" height="20" />
                                <ng-container *ngIf="dashboardData?.affiliazioni.tim.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.affiliazioni.tim.paf <
                                                    dashboardData?.affiliazioni.tim.target,
                                                'text-success':
                                                    dashboardData?.affiliazioni.tim.paf >=
                                                    dashboardData?.affiliazioni.tim.target
                                            }"
                                        >
                                            {{ dashboardData?.affiliazioni.tim.paf }}
                                        </span>
                                        vs
                                        {{ dashboardData?.affiliazioni.tim.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.affiliazioni.tim.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.affiliazioni.tim.value <
                                                    dashboardData?.affiliazioni.tim.target,
                                                'text-success':
                                                    dashboardData?.affiliazioni.tim.value >=
                                                    dashboardData?.affiliazioni.tim.target
                                            }"
                                        >
                                            {{ dashboardData?.affiliazioni.tim.value }}
                                        </span>
                                        vs
                                        {{ dashboardData?.affiliazioni.tim.target }}
                                    </p>
                                </ng-container>
                            </div>
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded p-2"
                            >
                                <img src="/assets/img/brands/a2a.png" height="20" />
                                <ng-container *ngIf="dashboardData?.affiliazioni.a2a.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.affiliazioni.a2a.paf <
                                                    dashboardData?.affiliazioni.a2a.target,
                                                'text-success':
                                                    dashboardData?.affiliazioni.a2a.paf >=
                                                    dashboardData?.affiliazioni.a2a.target
                                            }"
                                        >
                                            {{ dashboardData?.affiliazioni.a2a.paf }}
                                        </span>
                                        vs
                                        {{ dashboardData?.affiliazioni.a2a.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.affiliazioni.a2a.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.affiliazioni.a2a.value <
                                                    dashboardData?.affiliazioni.a2a.target,
                                                'text-success':
                                                    dashboardData?.affiliazioni.a2a.value >=
                                                    dashboardData?.affiliazioni.a2a.target
                                            }"
                                        >
                                            {{ dashboardData?.affiliazioni.a2a.value }}
                                        </span>
                                        vs
                                        {{ dashboardData?.affiliazioni.a2a.target }}
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6 mb-4 mb-md-0">
            <div class="card h-100">
                <div class="card-body h-100 d-flex justify-content-center flex-column align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="container-fluid">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                            <img src="/assets/img/flats/diagram.png" height="64" />
                            <p class="lead text-md text-center mt-2">Attivazioni VS Target</p>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded mr-2 p-2"
                            >
                                <img src="/assets/img/brands/kena.png" height="20" />
                                <ng-container *ngIf="dashboardData?.attivazioni.tim.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.attivazioni.kena.paf <
                                                    dashboardData?.attivazioni.kena.target,
                                                'text-success':
                                                    dashboardData?.attivazioni.kena.paf >=
                                                    dashboardData?.attivazioni.kena.target
                                            }"
                                        >
                                            {{ dashboardData?.attivazioni.kena.paf }}
                                        </span>
                                        vs
                                        {{ dashboardData?.attivazioni.kena.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.attivazioni.tim.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.attivazioni.kena.value <
                                                    dashboardData?.attivazioni.kena.target,
                                                'text-success':
                                                    dashboardData?.attivazioni.kena.value >=
                                                    dashboardData?.attivazioni.kena.target
                                            }"
                                        >
                                            {{ dashboardData?.attivazioni.kena.value }}
                                        </span>
                                        vs
                                        {{ dashboardData?.attivazioni.kena.target }}
                                    </p>
                                </ng-container>
                            </div>
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded mr-2 p-2"
                            >
                                <img src="/assets/img/brands/tim.png" height="20" />
                                <ng-container *ngIf="dashboardData?.attivazioni.a2a.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.attivazioni.tim.paf <
                                                    dashboardData?.attivazioni.tim.target,
                                                'text-success':
                                                    dashboardData?.attivazioni.tim.paf >=
                                                    dashboardData?.attivazioni.tim.target
                                            }"
                                        >
                                            {{ dashboardData?.attivazioni.tim.paf }}
                                        </span>
                                        vs
                                        {{ dashboardData?.attivazioni.tim.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.attivazioni.a2a.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.attivazioni.tim.value <
                                                    dashboardData?.attivazioni.tim.target,
                                                'text-success':
                                                    dashboardData?.attivazioni.tim.value >=
                                                    dashboardData?.attivazioni.tim.target
                                            }"
                                        >
                                            {{ dashboardData?.attivazioni.tim.value }}
                                        </span>
                                        vs
                                        {{ dashboardData?.attivazioni.tim.target }}
                                    </p>
                                </ng-container>
                            </div>
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded p-2"
                            >
                                <img src="/assets/img/brands/a2a.png" height="20" />
                                <ng-container *ngIf="dashboardData?.attivazioni.a2a.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.attivazioni.a2a.paf <
                                                    dashboardData?.attivazioni.a2a.target,
                                                'text-success':
                                                    dashboardData?.attivazioni.a2a.paf >=
                                                    dashboardData?.attivazioni.a2a.target
                                            }"
                                        >
                                            {{ dashboardData?.attivazioni.a2a.paf }}
                                        </span>
                                        vs
                                        {{ dashboardData?.attivazioni.a2a.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.attivazioni.a2a.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.attivazioni.a2a.value <
                                                    dashboardData?.attivazioni.a2a.target,
                                                'text-success':
                                                    dashboardData?.attivazioni.a2a.value >=
                                                    dashboardData?.attivazioni.a2a.target
                                            }"
                                        >
                                            {{ dashboardData?.attivazioni.a2a.value }}
                                        </span>
                                        vs
                                        {{ dashboardData?.attivazioni.a2a.target }}
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="card h-100">
                <div class="card-body h-100 d-flex justify-content-center flex-column align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="container-fluid">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                            <img src="/assets/img/flats/box.png" height="64" />
                            <p class="lead text-md text-center mt-2">InPost</p>
                        </div>
                        <div
                            class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                        >
                            <ng-container *ngIf="dashboardData?.package.paf">
                                <p class="text-md text-center">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger': dashboardData?.package.paf < dashboardData?.package.target,
                                            'text-success': dashboardData?.package.paf >= dashboardData?.package.target
                                        }"
                                    >
                                        {{ dashboardData?.package.paf }}
                                    </span>
                                    vs
                                    {{ dashboardData?.package.target }}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="!dashboardData?.package.paf">
                                <p class="text-md text-center">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger': dashboardData?.package.value < dashboardData?.package.target,
                                            'text-success':
                                                dashboardData?.package.value >= dashboardData?.package.target
                                        }"
                                    >
                                        {{ dashboardData?.package.value }}
                                    </span>
                                    vs
                                    {{ dashboardData?.package.target }}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="card h-100">
                <div class="card-body h-100 d-flex justify-content-center flex-column align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="container-fluid">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                            <img src="/assets/img/flats/cash.png" height="64" />
                            <p class="lead text-md text-center mt-2">Plafond Compensato</p>
                        </div>
                        <div
                            class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                        >
                            <ng-container *ngIf="dashboardData?.plafond.paf">
                                <p class="text-md text-center">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger': dashboardData?.plafond.paf < dashboardData?.plafond.target,
                                            'text-success': dashboardData?.plafond.paf >= dashboardData?.plafond.target
                                        }"
                                    >
                                        {{ dashboardData?.plafond.paf | currency : '€' }}
                                    </span>
                                    vs
                                    {{ dashboardData?.plafond.target | currency : '€' }}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="!dashboardData?.plafond.paf">
                                <p class="text-md text-center">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger': dashboardData?.plafond.value < dashboardData?.plafond.target,
                                            'text-success':
                                                dashboardData?.plafond.value >= dashboardData?.plafond.target
                                        }"
                                    >
                                        {{ dashboardData?.plafond.value | currency : '€' }}
                                    </span>
                                    vs
                                    {{ dashboardData?.plafond.target | currency : '€' }}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="card h-100">
                <div class="card-body h-100 d-flex justify-content-center flex-column align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="container-fluid">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                            <img src="/assets/img/flats/pie-chart.png" height="64" />
                            <p class="text-md lead text-center mt-2">Volumi eCommerce</p>
                        </div>
                        <div
                            class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                        >
                            <ng-container *ngIf="dashboardData?.ecommerce.paf">
                                <p class="text-md text-center">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger':
                                                dashboardData?.ecommerce.paf < dashboardData?.ecommerce.target,
                                            'text-success':
                                                dashboardData?.ecommerce.paf >= dashboardData?.ecommerce.target
                                        }"
                                    >
                                        {{ dashboardData?.ecommerce.paf | currency : '€' }}
                                    </span>
                                    vs
                                    {{ dashboardData?.ecommerce.target | currency : '€' }}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="!dashboardData?.ecommerce.paf">
                                <p class="text-md text-center">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger':
                                                dashboardData?.ecommerce.value < dashboardData?.ecommerce.target,
                                            'text-success':
                                                dashboardData?.ecommerce.value >= dashboardData?.ecommerce.target
                                        }"
                                    >
                                        {{ dashboardData?.ecommerce.value | currency : '€' }}
                                    </span>
                                    vs
                                    {{ dashboardData?.ecommerce.target | currency : '€' }}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row mb-3">
        <div class="col-md-6 col-12 mb-3 mb-md-0">
            <div class="card h-100">
                <div class="card-body d-flex p-3 justify-content-center align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="row w-100 h-100 justify-content-center align-items-center">
                        <div class="col-md-4 col-12 text-center">
                            <div class="container-fluid">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                                    <img src="/assets/img/flats/email.png" height="64" />
                                    <p class="lead text-md mt-2">Mail avviso execution</p>
                                </div>
                                <div
                                    class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                                >
                                    <p class="text-md">{{ dashboardData?.mail_execution }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 text-center mb-md-0 mb-4">
                            <div class="container-fluid">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                                    <img src="/assets/img/flats/phone-receiver.png" height="64" />
                                    <p class="lead text-md mt-2">Produzione post call</p>
                                </div>
                                <div
                                    class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                                >
                                    <p class="text-md">{{ dashboardData?.post_call_prod }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 text-center mb-md-0 mb-4">
                            <div class="container-fluid">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                                    <img src="/assets/img/flats/whatsapp.png" height="64" />
                                    <p class="lead text-md mt-2">Iscritti Whatsapp</p>
                                </div>
                                <div
                                    class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                                >
                                    <p class="text-md">
                                        {{ dashboardData?.whatsapp.value }} vs {{ dashboardData?.whatsapp.target }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mb-md-0 mb-3">
            <div class="card h-100">
                <div class="card-body p-3 d-flex justify-content-center align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="row w-100 h-100 justify-content-center align-items-center">
                        <div class="col-md-4 col-12 text-center mb-md-0 mb-4">
                            <div class="container-fluid">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                                    <img src="/assets/img/flats/payment.png" height="64" />
                                    <p class="lead text-md mt-2">Ricariche</p>
                                </div>
                                <div
                                    class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                                >
                                    <p class="text-md">{{ dashboardData?.ricariche }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 text-center mb-md-0 mb-4">
                            <div class="container-fluid">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                                    <img src="/assets/img/flats/files.png" height="64" />
                                    <p class="lead text-md mt-2">Bollettini</p>
                                </div>
                                <div
                                    class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                                >
                                    <p class="text-md">{{ dashboardData?.bollettini }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 text-center mb-md-0 mb-4">
                            <div class="container-fluid">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center mb-3">
                                    <img src="/assets/img/flats/writing.png" height="64" />
                                    <p class="lead text-md mt-2">Assicurazioni</p>
                                </div>
                                <div
                                    class="col-12 d-flex flex-column justify-content-center align-items-center border rounded p-2"
                                >
                                    <p class="text-md">
                                        {{ dashboardData?.assicurazioni }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr />
    <div class="row mb-3">
        <div class="col-12 col-md-4 mb-md-0 mb-3">
            <div class="card h-100">
                <div class="card-body p-3 d-flex justify-content-center align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="container-fluid">
                        <div class="col-12 d-flex justify-content-center align-items-center mb-4">
                            <img src="https://kdcube.it/images/logo.webp" height="64" />
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div class="col-6 border rounded text-center mb-2 mr-2">
                                <p class="lead text-md">ATTIVI</p>
                                <p class="text-md">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger':
                                                dashboardData?.kdcube.active.value < dashboardData?.kdcube.active.total,
                                            'text-success':
                                                dashboardData?.kdcube.active.value >= dashboardData?.kdcube.active.total
                                        }"
                                    >
                                        {{ dashboardData?.kdcube.active.value }}
                                    </span>
                                    vs
                                    {{ dashboardData?.kdcube.active.total }}
                                </p>
                            </div>
                            <div class="col-6 border rounded text-center mb-2">
                                <p class="lead text-md">IN SCADENZA</p>
                                <p class="text-md text-warning font-weight-bold">
                                    {{ dashboardData?.kdcube.expiring }}
                                </p>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div class="col-6 border rounded text-center mr-2">
                                <p class="lead text-md">SCADUTI</p>
                                <p
                                    class="text-md font-weight-bold"
                                    [ngClass]="{ 'text-danger': dashboardData?.kdcube.expired > 0 }"
                                >
                                    {{ dashboardData?.kdcube.expired }}
                                </p>
                            </div>
                            <div class="col-6 border rounded text-center">
                                <p class="lead text-md">PROMO</p>
                                <p
                                    class="text-md font-weight-bold"
                                    [ngClass]="{ 'text-success': dashboardData?.kdcube.promo > 0 }"
                                >
                                    {{ dashboardData?.kdcube.promo }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-4 mb-md-0 mb-3">
            <div class="card h-100">
                <div class="card-body p-3 d-flex justify-content-center align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="container-fluid">
                        <div class="col-12 d-flex justify-content-center flex-column align-items-center mb-3">
                            <img src="/assets/img/brands/tim.png" height="64" />
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded mr-2 p-2"
                            >
                                <p class="lead text-md text-center">AL</p>
                                <ng-container *ngIf="dashboardData?.tim.al.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger': dashboardData?.tim.al.paf < dashboardData?.tim.al.target,
                                                'text-success':
                                                    dashboardData?.tim.al.paf >= dashboardData?.tim.al.target
                                            }"
                                        >
                                            {{ dashboardData?.tim.al.paf }}</span
                                        >
                                        vs {{ dashboardData?.tim.al.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.tim.al.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.tim.al.value < dashboardData?.tim.al.target,
                                                'text-success':
                                                    dashboardData?.tim.al.value >= dashboardData?.tim.al.target
                                            }"
                                        >
                                            {{ dashboardData?.tim.al.value }}</span
                                        >
                                        vs {{ dashboardData?.tim.al.target }}
                                    </p>
                                </ng-container>
                            </div>
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded mr-2 p-2"
                            >
                                <p class="lead text-md text-center">MNP</p>
                                <ng-container *ngIf="dashboardData?.tim.mnp.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.tim.mnp.paf < dashboardData?.tim.mnp.target,
                                                'text-success':
                                                    dashboardData?.tim.mnp.paf >= dashboardData?.tim.mnp.target
                                            }"
                                        >
                                            {{ dashboardData?.tim.mnp.paf }}</span
                                        >
                                        vs {{ dashboardData?.tim.mnp.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.tim.mnp.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.tim.mnp.value < dashboardData?.tim.mnp.target,
                                                'text-success':
                                                    dashboardData?.tim.mnp.value >= dashboardData?.tim.mnp.target
                                            }"
                                        >
                                            {{ dashboardData?.tim.mnp.value }}</span
                                        >
                                        vs {{ dashboardData?.tim.mnp.target }}
                                    </p>
                                </ng-container>
                            </div>
                            <div
                                class="col-4 d-flex flex-column justify-content-center align-items-center p-0 border rounded p-2"
                            >
                                <p class="lead text-md text-center">FISSO</p>
                                <ng-container *ngIf="dashboardData?.tim.fisso.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.tim.fisso.paf < dashboardData?.tim.fisso.target,
                                                'text-success':
                                                    dashboardData?.tim.fisso.paf >= dashboardData?.tim.fisso.target
                                            }"
                                        >
                                            {{ dashboardData?.tim.fisso.paf }}</span
                                        >
                                        vs {{ dashboardData?.tim.fisso.target }}
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!dashboardData?.tim.fisso.paf">
                                    <p class="text-md text-center">
                                        <span
                                            class="font-weight-bold"
                                            [ngClass]="{
                                                'text-danger':
                                                    dashboardData?.tim.fisso.value < dashboardData?.tim.fisso.target,
                                                'text-success':
                                                    dashboardData?.tim.fisso.value >= dashboardData?.tim.fisso.target
                                            }"
                                        >
                                            {{ dashboardData?.tim.fisso.value }}</span
                                        >
                                        vs {{ dashboardData?.tim.fisso.target }}
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-12 mb-md-0 mb-3">
            <div class="card h-100">
                <div class="card-body p-3 d-flex flex-column justify-content-center align-items-center">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="container-fluid">
                        <div class="col-12 d-flex justify-content-center flex-column align-items-center mb-3">
                            <img src="/assets/img/brands/a2a.png" height="64" />
                        </div>

                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <div
                                class="col-6 d-flex flex-column justify-content-center align-items-center p-0 border rounded mr-2 p-2"
                            >
                                <p class="lead text-md text-cemter">
                                    LUCE
                                    <span class="text-sm"
                                        >(Tot: <strong>{{ dashboardData?.a2a.luce.totale }}</strong
                                        >)</span
                                    >
                                </p>
                                <div class="row w-100">
                                    <div class="col-12 col-lg-6 text-center">
                                        <p class="lead text-md">
                                            RID:
                                            <span style="font-weight: normal">{{ dashboardData?.a2a.luce.rid }}</span>
                                        </p>
                                    </div>
                                    <div class="col-12 col-lg-6 text-center">
                                        <p class="lead text-md">
                                            BP:
                                            <span style="font-weight: normal">{{ dashboardData?.a2a.luce.bp }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-6 d-flex flex-column justify-content-center align-items-center p-0 border rounded mr-2 p-2"
                            >
                                <p class="lead text-md text-center">
                                    GAS
                                    <span class="lead text-sm"
                                        >(Tot: <strong>{{ dashboardData?.a2a.gas.totale }}</strong
                                        >)</span
                                    >
                                </p>
                                <div class="row w-100">
                                    <div class="col-12 col-lg-6 text-center">
                                        <p class="lead text-md">
                                            RID:
                                            <span style="font-weight: normal">{{ dashboardData?.a2a.gas.rid }}</span>
                                        </p>
                                    </div>
                                    <div class="col-12 col-lg-6 text-center">
                                        <p class="lead text-md">
                                            BP: <span style="font-weight: normal">{{ dashboardData?.a2a.gas.bp }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="col-12 d-flex justify-content-center align-items-center border rounded mt-3">
                            <ng-container *ngIf="dashboardData?.a2a.paf">
                                <p class="text-md text-center">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger': dashboardData?.a2a.paf < dashboardData?.a2a.target,
                                            'text-success': dashboardData?.a2a.paf >= dashboardData?.a2a.target
                                        }"
                                    >
                                        {{ dashboardData?.a2a.paf }}</span
                                    >
                                    vs {{ dashboardData?.a2a.target }}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="!dashboardData?.a2a.paf">
                                <p class="text-md text-center">
                                    <span
                                        class="font-weight-bold"
                                        [ngClass]="{
                                            'text-danger':
                                                dashboardData?.a2a.gas.totale + dashboardData?.a2a.luce.totale <
                                                dashboardData?.a2a.target,
                                            'text-success':
                                                dashboardData?.a2a.gas.totale + dashboardData?.a2a.luce.totale >=
                                                dashboardData?.a2a.target
                                        }"
                                    >
                                        {{ dashboardData?.a2a.gas.totale + dashboardData?.a2a.luce.totale }}</span
                                    >
                                    vs {{ dashboardData?.a2a.target }}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card d-flex flex-column" style="max-height: 450px">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-start align-items-center">
                            <img src="/assets/img/brands/kena.png" height="64" />
                        </div>
                    </div>
                </div>
                <div class="card-body p-0 d-flex flex-column" style="overflow: hidden; height: calc(100% - 56px)">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="table-container">
                        <table class="table table-sm mb-0">
                            <thead class="border">
                                <tr>
                                    <th class="col-6">Tipo</th>
                                    <th class="col-2 text-center">Valore</th>
                                    <th class="col-2 text-center">Target</th>
                                    <th class="col-2 text-center">&Delta;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of dashboardData?.kena">
                                    <td class="col-6">{{ item.type }}</td>
                                    <td class="col-2 text-center">{{ item.value }}</td>
                                    <td class="col-2 text-center">{{ item.target }}</td>
                                    <td
                                        class="col-2 font-weight-bold text-center"
                                        [ngClass]="{
                                            'text-danger': item.value < item.target,
                                            'text-success': item.value >= item.target,
                                            'text-warning': item.target == 0 && item.value == 0
                                        }"
                                    >
                                        <span *ngIf="item.value - item.target > 0">+</span
                                        >{{ item.value - item.target }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-6">
            <div class="card d-flex flex-column" style="max-height: 450px">
                <div class="card-header">
                    <div class="row">
                        <div class="col-2 d-flex justify-content-start align-items-center">
                            <img src="/assets/img/flats/chart.png" height="64" />
                            <p class="lead ml-3">Visite</p>
                        </div>
                        <div class="col-6"></div>
                        <div class="col-4 d-flex flex-column h-full w-full justify-content-center align-items-end">
                            <p class="lead text-md">
                                Totale:
                                <span class="font-weight-bold text-md">{{ dashboardData?.visite.totale_visite }}</span>
                            </p>
                            <p class="lead text-md">
                                Media:
                                <span class="font-weight-bold text-md">{{
                                    dashboardData?.visite.totale_media_visite
                                }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body p-0 d-flex flex-column" style="overflow: hidden; height: calc(100% - 56px)">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="table-container">
                        <table class="table table-sm mb-0">
                            <thead>
                                <tr>
                                    <th>Key Account</th>
                                    <th class="text-center">Visite</th>
                                    <th class="text-center">Media</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of dashboardData?.visite.ka">
                                    <td>{{ item.ragsociale }}</td>
                                    <td class="text-center">{{ item.tot_visite }}</td>
                                    <td
                                        class="font-weight-bold text-center"
                                        [ngClass]="{
                                            'text-danger': item.media_visite < 4,
                                            'text-success': item.media_visite >= 4
                                        }"
                                    >
                                        {{ item.media_visite }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-12">
            <div class="card d-flex flex-column" style="max-height: 450px">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4 d-flex justify-content-start align-items-center">
                            <img src="/assets/img/flats/chart.png" height="64" />
                            <p class="lead ml-3">Visite Nuove Liste</p>
                        </div>
                        <div class="col-4"></div>
                        <div class="col-4 d-flex flex-column h-full w-full justify-content-center align-items-end">
                            <p class="lead text-md">
                                Totale Programmate:
                                <span class="font-weight-bold text-md">{{
                                    dashboardData?.nuove_liste?.total_visit_schedule
                                }}</span>
                            </p>
                            <p class="lead text-md">
                                Totale Visitate:
                                <span class="font-weight-bold text-md">{{
                                    dashboardData?.nuove_liste?.total_visited
                                }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body p-0 d-flex flex-column" style="overflow: hidden; height: calc(100% - 56px)">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="dashboard"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <div class="table-container">
                        <table class="table table-sm mb-0">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th class="text-center">Creata il</th>
                                    <th class="text-center">Contatti</th>
                                    <th class="text-center">Programmate</th>
                                    <th class="text-center">Visitati</th>
                                    <th class="text-center">Affiliati</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of dashboardData?.nuove_liste?.data">
                                    <tr class="hoverable-td" (click)="openListDetail(item.id)">
                                        <td>{{ item.name_list }}</td>
                                        <td class="text-center">{{ item.created_at | amDateFormat : 'DD/MM/YYYY' }}</td>
                                        <td class="text-center">{{ item.count_list }}</td>
                                        <td class="text-center">{{ item.visit_schedule }}</td>
                                        <td class="text-center">{{ item.visited }}</td>
                                        <td class="text-center">{{ item.affiliated }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--    <div class="row">-->
<!--        <div class="col-12">-->
<!--            <div class="card d-flex flex-column" style="max-height: 450px">-->
<!--                <div class="card-header">-->
<!--                    <div class="row">-->
<!--                        <div class="col-4 d-flex justify-content-start align-items-center">-->
<!--                            <img src="/assets/img/flats/chart.png" height="64" />-->
<!--                            <p class="lead ml-3">Dettaglio Ricariche</p>-->
<!--                        </div>-->
<!--                        <div class="col-4"></div>-->
<!--                        <div class="col-4 d-flex flex-column h-full w-full justify-content-center align-items-end">-->
<!--                            <p class="lead text-md">-->
<!--                                Totale Ricariche:-->
<!--                                <span class="font-weight-bold text-md">0,00€</span>-->
<!--                            </p>-->
<!--                            <p class="lead text-md">-->
<!--                                Totale Incassi:-->
<!--                                <span class="font-weight-bold text-md">0,00€</span>-->
<!--                            </p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="card-body p-0 d-flex flex-column" style="overflow: hidden; height: calc(100% - 56px)">-->
<!--                    <ngx-spinner-->
<!--                        bdColor="rgba(0, 0, 0, 0.8)"-->
<!--                        size="default"-->
<!--                        color="#fff"-->
<!--                        type="square-jelly-box"-->
<!--                        name="dashboard"-->
<!--                        [fullScreen]="false"-->
<!--                    >-->
<!--                        <p style="color: white">Caricamento...</p>-->
<!--                    </ngx-spinner>-->
<!--                    <div class="table-container">-->
<!--                        <table class="table table-sm mb-0">-->
<!--                            <thead>-->
<!--                                <tr>-->
<!--                                    <th></th>-->
<!--                                </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                                &lt;!&ndash; <ng-container *ngFor="">-->
<!--                                    <tr class="hoverable-td">-->
<!--                                        <td></td>-->
<!--                                    </tr>-->
<!--                                </ng-container> &ndash;&gt;-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
