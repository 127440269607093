import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { EcTim, EcTimTotals } from '@app/models/ec-tim';
import { ControlloEcTimService } from '@app/services/controllo-ec-tim.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as XLSX from 'xlsx';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-controllo-ec-tim',
  templateUrl: './controllo-ec-tim.component.html',
  styleUrls: ['./controllo-ec-tim.component.scss']
})

export class ControlloEcTimComponent implements OnInit {

  data: EcTim[];

  @ViewChild('timFormTemplate') private timFormTemplate;

  totalsData: EcTimTotals = {
    da_saldare: 0,
    in_corso: 0,
    saldato: 0
  };

  isLoading: boolean = false;

  filters = {
    dealer_id: '',
    welding_status: '',
    date: '',
    number_doc: '',
    year: '',
  };

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  apiDate = {
    from: '',
    to: '',
  };

  ecStatuses = [
    {
      label: 'DA SALDARE',
      key: "0"
    },
    {
      label: 'IN CORSO',
      key: "1"
    },
    {
      label: 'SALDATO',
      key: "2"
    }
  ];

  get isInvoiceSelected(): boolean {
    let isSelected = false;

    if (this.data) {
      this.data.forEach((dealer) => {
        dealer.invoices.forEach((invoice) => {
          if (invoice.isSelected) {
            isSelected = true;
          }
        });
      });

      return isSelected;
    }
  }

  constructor(
    private service: ControlloEcTimService,
    private dateRangePickerOptions: DaterangepickerConfig,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.filters.welding_status = '0';

    const currentYear = new Date().getFullYear();
    const startDate = moment(`${currentYear}-01-01`, 'YYYY-MM-DD');
    const endDate = moment(`${currentYear}-12-31`, 'YYYY-MM-DD');

    this.generateDateRangeString(startDate, endDate);

    this.loadList();
    if (this.data && this.data.length > 0) {
      this.data.forEach((e) => {
        e.isSelectedAll = false;
        e.invoices.forEach((i) => {
          i.isSelected = false;
        })
      })
    }
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {

    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;

    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filters.date =
      startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  loadList() {
    this.isLoading = true;
    this.spinner.show('mainSpinner');
    const params = {};

    if (this.filters.dealer_id) {
      params['dealer_id'] = this.filters.dealer_id;
    }

    if (this.filters.welding_status) {
      params['welding_status'] = this.filters.welding_status;
    }

    if (this.filters.number_doc) {
      params['number_doc'] = this.filters.number_doc;
    }

    if (this.filters.year) {
      params['year'] = this.filters.year;
    }

    if (this.filters.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.service.getTotals(params).subscribe((res) => {
      this.totalsData = {
        da_saldare: res.da_saldare ? res.da_saldare : 0,
        in_corso: res.in_corso ? res.in_corso : 0,
        saldato: res.saldato ? res.saldato : 0
      };
    })

    this.service.getList(params).subscribe(
      (res: EcTim[]) => {
        this.data = res;
        this.isLoading = false;
        this.spinner.hide('mainSpinner');

      },
      (err) => {
        console.log('Error', err);
        this.isLoading = false;
        this.spinner.hide('mainSpinner');

      }
    );
  }

  // saveMultiple(value) {
  //   for (const dealerId in this.selectedInvoicesByDealer) {
  //     if (this.selectedInvoicesByDealer.hasOwnProperty(dealerId)) {

  //       const selectedInvoices = this.selectedInvoicesByDealer[dealerId];

  //       if (value == 2) {
  //         const modalRef = this.modalService.open(ConfirmModalComponent, {
  //           centered: true,
  //         });

  //         modalRef.result.then(
  //           (confirm) => {

  //             selectedInvoices.map((invoice) => {
  //               invoice.welding_status = value;
  //               invoice.paid_date = confirm;
  //             })
  //           })

  //         this.updateInvoice(selectedInvoices);

  //       } else {
  //         const modalRef = this.modalService.open(ConfirmModalComponent, {
  //           centered: true,
  //         });

  //         modalRef.result.then(
  //           (confirm) => {

  //             selectedInvoices.map((invoice) => {
  //               invoice.welding_status = value;
  //             })
  //           })

  //         this.updateInvoice(selectedInvoices);
  //       }
  //     }
  //   }
  // }

  export() {

    let output = [];
    let isSelected = false;
    this.data.forEach((row) => {
      const dealer_id = row.dealer_id;
      const dealer_name = row.dealer_name;
      const dealer_sdd_data = row.dealer_sdd_data;

      row.invoices.forEach((invoice) => {
        if (invoice.isSelected) {
          isSelected = true;

          const formattedDate = this.formatDate(invoice.created_at);
          const formattedSignatureDate = this.formatSignatureDate(dealer_sdd_data.signature_date);

          const newRow = {
            'Documento': 'Estratto Conto',
            'Numero Estratto Conto': invoice.number,
            'Serie': 'TIM',
            'Data Documento': formattedDate,
            'Ragione Sociale': dealer_name,
            'Stato': invoice.welding_status === '0' ? 'DA SALDARE' : invoice.welding_status === '1' ? 'IN CORSO' : invoice.welding_status === '2' ? 'SALDATO' : '',
            'Iban': dealer_sdd_data.iban,
            'Mandato': dealer_id,
            'Totale': invoice.total,
            'Data Firma Mandato': formattedSignatureDate
          }
          output.push(newRow);
        }
      });
    });

    if (!isSelected) {

      alert("Selezionare almeno un record!");
    } else {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-ec-tim.xlsx');

    }
  }

  massUpdatePaid(selectedInvoices, paidDate) {

    if (selectedInvoices && selectedInvoices.length > 0 && paidDate) {

      selectedInvoices.map((invoice) => {
        invoice.welding_status = 2;
        invoice.paid_date = paidDate;
      });

      this.updateInvoice(selectedInvoices);
    } else {
      console.log("impossibile eseguire l'update");
    }
  }

  massPendingUpdate() {
    const selectedInvoices = this.getSelectedInvoices();

    if (selectedInvoices && selectedInvoices.length > 0) {
      selectedInvoices.map((invoice) => {
        invoice.body.welding_status = 1
      })
      this.updateInvoice(selectedInvoices);
    } else {
      console.log("impossibile eseguire l'update")
    }
  }

  openSaldaAddebitiModal() {

    const selectedInvoices = this.getSelectedInvoices();

    if (selectedInvoices.length > 0) {

      const modalRef = this.modalService.open(ConfirmModalComponent, { size: 'md' });

      modalRef.componentInstance.selectedInvoices = selectedInvoices;

      modalRef.result.then((paidDate) => {
        this.massUpdatePaid(selectedInvoices, paidDate);
      }).catch(() => {
      });
    } else {
      alert("Selezionare almeno una fattura!");
    }
  }

  getSelectedInvoices() {
    const selectedInvoices = [];

    this.data.forEach((e) => {
      e.invoices.forEach((i) => {
        if (i.isSelected) {
          selectedInvoices.push(i);
        }
      });
    });

    const newSelectedInvoices = selectedInvoices.map((item) => ({
      id: item.id, body: { welding_status: item.welding_status }
    }))

    return newSelectedInvoices;
  }

  updateInvoice(selectedInvoices) {
    this.isLoading = true;
    this.service.putEcTimInvoice(selectedInvoices).subscribe((res) => {
      if (res) {
        this.loadList();
        this.isLoading = false;
      }
    },
      (err) => {
        this.isLoading = false;
        alert(err + ", impossibile proseguire.");
      }
    )
  }

  showModal() {
    this.modalService.open(this.timFormTemplate, { size: 'md' });
  }

  formatDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  formatSignatureDate(signatureDate) {
    if (signatureDate) {
      const parts = signatureDate.split('-');
      if (parts.length === 3) {
        const day = parts[2];
        const month = parts[1];
        const year = parts[0];
        return `${day}/${month}/${year}`;
      }
    }
    return signatureDate;
  }
}