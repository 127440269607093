import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {DataService} from '@services/data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  returnUrl: string;
  loading = false;
  busy: Subscription;
  status = 0;
  email: string = null;

  constructor(
    private renderer: Renderer2,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'login-page');

    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required])
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    if (this.loginForm.valid) {
      // const formData = new FormData();
      // formData.append('email', this.loginForm.controls.email.value);

      this.busy = this.dataService.post('/reset-password', this.loginForm.value)
        .subscribe((result: any) => {
          if (result.success) {
            this.status = 1;
            this.email = result.result;
          } else {
            this.status = 0;
          }
        });
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-page');
  }
}
