<app-master-header (onChange)="this.updateUrls($event.operatore, $event.periodo)"
                   [ngBusy]="busy"
                   [operatori]="true"
                   [periodoSingle]="true"
                   cardClass="bg-gradient-light"
                   image="assets/img/flats/placeholder.png"
                   title="Nuove Affiliazioni"
>
</app-master-header>

<app-master-subheader [free]="true" [url]="urlSubheader" cardClass="bg-gradient-1"></app-master-subheader>

<ul #nav="ngbNav" class="nav-tabs mg-tabs" ngbNav>
    <li ngbNavItem>
        <a ngbNavLink translate="">Richieste</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    image="assets/img/flats/ribbon.png"
                    title="Richieste"
            >
                <data-builder-table [url]="urlAffiliazioniTabella"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem>
        <a ngbNavLink translate="">Nuove Affiliazioni</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    image="assets/img/flats/ribbon.png"
                    title="Nuove Affiliazioni"
            >
                <data-builder-table [url]="urlNegoziTabella"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <ng-container *isVendorDisabled="'Eolo'">
        <li *show="['master', 'submaster', 'masteragent', 'operator']" ngbNavItem>
            <a ngbNavLink>Classifica per <span translate>Key Account</span></a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        image="assets/img/flats/employee.png"
                        title="Classifica Key Account"
                >
                    <data-builder-table [url]="urlAgenti"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
    </ng-container>
    <ng-container *isVendorEnabled="['Eolo']">
        <li *show="['master', 'masteragent', 'operator', 'agent']" ngbNavItem>
            <a ngbNavLink>Classifica per <span translate>Key Account</span></a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        image="assets/img/flats/employee.png"
                        title="Classifica Key Account"
                >
                    <data-builder-table [url]="urlAgenti"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
    </ng-container>

    <ng-container *isVendorDisabled="'Eolo'">
        <li *show="['master', 'submaster', 'operator']" ngbNavItem>
            <a ngbNavLink>Classifica per <span translate>Area Manager</span></a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        image="assets/img/flats/global-network.png"
                        title="Classifica Area Manager"
                >
                    <data-builder-table [url]="urlRegioni"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
    </ng-container>
    <ng-container *isVendorEnabled="['Eolo']">
        <li *show="['master', 'masteragent', 'operator']" ngbNavItem>
            <a ngbNavLink>Classifica per <span translate>Area Manager</span></a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        image="assets/img/flats/global-network.png"
                        title="Classifica Area Manager"
                >
                    <data-builder-table [url]="urlRegioni"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
    </ng-container>
    <li *show="['master', 'submaster',  'operator', 'agent','masteragent']" ngbNavItem>
        <a ngbNavLink>Trend</a>
        <ng-template ngbNavContent>
            <div class="mb-2">
                <button (click)="downloadDailyTrend()" [ladda]="laddaTrendDaily" class="btn btn-sm btn-success"><i
                        class="fas fa-download"></i> Trend Giornaliero
                </button>
                <button (click)="downloadMonthlyTrend()" [ladda]="laddaTrendMonthly"
                        class="btn btn-sm btn-success ml-2"><i class="fas fa-download"></i> Trend Mensile
                </button>
            </div>

            <app-master-card
                    [height]="null"
                    bodyClass="p-0"
                    image="assets/img/flats/global-network.png"
                    title="Trend Aperture"
            >
                <app-autoload-chartjs [url]="urlTrend" brand="v2"
                                      type="line"></app-autoload-chartjs>
            </app-master-card>

            <div class="card bg-gradient-light">
                <div class="card-header">
                    <app-month-range-picker
                            (selected)="
                                this.updateChart($event);
                                this.eoloChart.setBrand('v2');
                                this.eoloChart.setUrl('/openings/trend-range?vendor='+this.operatore+'&from='+this.chartPeriod.from+'&to='+this.chartPeriod.to);"
                            [defaultMonthRange]="[chartPeriod.from, chartPeriod.to]"
                            id="date"></app-month-range-picker>
                </div>
                <div class="card-body p-1">
                    <app-autoload-chartjs #eoloChart brand="v2" type="line"></app-autoload-chartjs>
                </div>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>