<app-master-header
        cardClass="bg-gradient-light"
        headerBackground="assets/img/italy.jpg"
        title="Classifica"
        [operatori]="true"
        (onChange)="this.updateUrls($event.operatore);"
>
</app-master-header>

<ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs">
    <li ngbNavItem>
        <a ngbNavLink translate>{{stand ? 'Venditori' : 'Negozi' }}</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    [title]="'Classifica ' + (stand ? 'Venditori' : 'Negozi')"
                    image="assets/img/flats/ribbon.png"
            >
                <data-builder-table [url]="urlClassificaItalia"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <ng-container *isVendorDisabled="'Eolo'">
        <li ngbNavItem *show="['master', 'masteragent', 'operator', 'submaster']">
            <a ngbNavLink translate>{{stand ? 'Presidi' : 'Key Account' }}</a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        [title]="'Classifica ' + (stand ? 'Presidi' : 'Key Account')"
                        image="assets/img/flats/employee.png"
                >
                    <data-builder-table [url]="urlClassificaAgenti"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
        <li ngbNavItem *show="['master', 'operator', 'submaster']">
            <a ngbNavLink translate>Area Manager</a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        title="Classifica Area Manager"
                        image="assets/img/flats/global-network.png"
                >
                    <data-builder-table [url]="urlClassificaAreaManager"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
        <li ngbNavItem *show="['master', 'masteragent', 'operator']">
            <a ngbNavLink>Regioni</a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        title="Classifica Regioni"
                        image="assets/img/flats/global-network.png"
                >
                    <data-builder-table [url]="urlClassificaRegioni"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
    </ng-container>
    <ng-container *isVendorEnabled="['Eolo']">
        <li ngbNavItem *show="['master', 'masteragent', 'operator', 'agent', 'submaster']">
            <a ngbNavLink translate>{{stand ? 'Presidi' : 'Key Account' }}</a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        [title]="'Classifica ' + (stand ? 'Presidi' : 'Key Account')"
                        image="assets/img/flats/employee.png"
                >
                    <data-builder-table [url]="urlClassificaAgenti"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
        <li ngbNavItem *show="['master', 'operator', 'masteragent', 'submaster']">
            <a ngbNavLink translate>Area Manager</a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        title="Classifica Area Manager"
                        image="assets/img/flats/global-network.png"
                >
                    <data-builder-table [url]="urlClassificaAreaManager"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
        <li ngbNavItem *show="['master', 'masteragent', 'operator']">
            <a ngbNavLink>Regioni</a>
            <ng-template ngbNavContent>
                <app-master-card
                        bodyClass="p-0"
                        title="Classifica Regioni"
                        image="assets/img/flats/global-network.png"
                >
                    <data-builder-table [url]="urlClassificaRegioni"></data-builder-table>
                </app-master-card>
            </ng-template>
        </li>
    </ng-container>
</ul>
<div [ngbNavOutlet]="nav"></div>
