<app-master-header
    *show="['agent']"
    cardClass="bg-gradient-info"
    image="assets/img/flats/placeholder.png"
    title="Programmazione Visite"
    style="height: 30vh !important"
>
    <p class="lead">
        In questa sezione puoi programmare le visite sul calendario. Successivamente dovrai solo confermarle nel piano
        visite giornaliero.
    </p>
</app-master-header>

<app-master-header
    *show="['operator', 'master', 'masteragent', 'submaster']"
    cardClass="bg-gradient-light"
    image="assets/img/flats/placeholder.png"
    title="Pianificazione Visite"
    style="height: 30vh !important"
>
    <div class="row">
        <div class="col-8 d-flex align-items-center">
            <p class="lead">
                In questa sezione puoi visualizzare le visite pianificate sul calendario, seleziona un
                <strong>Key Account</strong> per visualizzare la pianificazione
            </p>
        </div>
        <div class="col-4">
            <div>
                <label>Key Account</label>
                <app-agent-select (onChange)="onAgentChange($event)" id="agent"></app-agent-select>
            </div>
        </div>
    </div>
</app-master-header>

<div class="container-fluid p-0" style="height: 70vh !important">
    <div class="d-lg-flex">
        <div *show="['agent']" class="col-12 col-lg-4 pl-0">
            <div class="card" style="height: 100% !important">
                <div class="card-header text-lg mb-2 bg-gradient-info p-3">
                    <p>Pianifica una nuova visita</p>
                </div>

                <div class="card-body d-flex flex-column justify-content-between">
                    <!-- Affiliato -->
                    <div class="card">
                        <div class="card-header">
                            <p class="font-weight-bold text-lg">Affiliato</p>
                        </div>
                        <div class="card-body">
                            <form [formGroup]="affiliatedForm" (ngSubmit)="save(true)" class="mt-2">
                                <div class="form-group">
                                    <label>Dealer</label>
                                    <app-negozio-select
                                        #affiliatedNegozioSelect
                                        [small]="false"
                                        bindLabel="nameWithLocation"
                                        (onChange)="onAffiliatedDealerChange($event)"
                                    ></app-negozio-select>
                                </div>
                                <div class="form-group">
                                    <label>Data</label>
                                    <input
                                        class="form-control"
                                        placeholder="gg-mm-aaaa"
                                        ngbDatepicker
                                        #data="ngbDatepicker"
                                        formControlName="date"
                                        (click)="data.toggle()"
                                    />
                                </div>
                                <button
                                    class="btn btn-block btn-warning btn-sm"
                                    [disabled]="!affiliatedForm.valid"
                                    [ladda]="savingVisit"
                                    type="submit"
                                >
                                    <i class="fa fa-save m-auto"></i><span class="ml-1">Salva</span>
                                </button>
                            </form>
                        </div>
                        <div class="card-footer">
                            <p class="text-muted text-sm">Pianifica una visita per un Dealer già affiliato</p>
                        </div>
                    </div>

                    <!-- Non Affiliato -->
                    <div class="card">
                        <div class="card-header">
                            <p class="font-weight-bold text-lg">Non Affiliato</p>
                        </div>
                        <div class="card-body">
                            <form [formGroup]="notAffiliatedForm" (ngSubmit)="save(false)" class="mt-2">
                                <div class="form-group">
                                    <label>Ragione Sociale</label>
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        formControlName="retailer"
                                    />
                                </div>
                                <div class="form-group">
                                    <label>Data</label>
                                    <input
                                        class="form-control"
                                        placeholder="gg-mm-aaaa"
                                        ngbDatepicker
                                        #dateTwo="ngbDatepicker"
                                        formControlName="date"
                                        (click)="dateTwo.toggle()"
                                    />
                                </div>
                                <button
                                    class="btn btn-block btn-warning btn-sm"
                                    [disabled]="!notAffiliatedForm.valid"
                                    [ladda]="savingVisit"
                                    type="submit"
                                >
                                    <i class="fa fa-save m-auto"></i><span class="ml-1">Salva</span>
                                </button>
                            </form>
                        </div>
                        <div class="card-footer">
                            <p class="text-muted text-sm">Pianifica una visita per un Dealer non ancora affiliato</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col p-0" style="height: 70vh; overflow-y: auto">
            <div *ngIf="!loadCalendar" class="card bg-gradient-light">
                <div class="card-body p-0">
                    <p class="text-lg text-uppercase text-center py-5 text-secondary">
                        Seleziona un <strong>Key Account</strong> e verifica la pianificazione
                    </p>
                </div>
            </div>

            <div *ngIf="loadCalendar" class="card bg-gradient-light">
                <div class="card-body p-0">
                    <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="calendar"
                        [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <full-calendar [options]="calendarOptions" #calendar></full-calendar>
                </div>
            </div>
        </div>
    </div>
</div>

<swal
    #deleteSwal
    title="Vuoi cancellare questa visita?"
    text="Sei sicuro di voler cancellare questa visita programmata?"
    [showCancelButton]="true"
    icon="warning"
    confirmButtonText="Si"
    cancelButtonText="Annulla"
    [customClass]="{
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-default'
    }"
    (confirm)="deleteEvent()"
>
</swal>

<app-modal #removeEvent>
    <p class="lead">Vuoi cancellare questa visita programmata?</p>
    <button class="btn btn-block btn-danger" (click)="delete()">Si, cancella!</button>
</app-modal>
