import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StoreService} from '@services/store.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {StoreOperator, StoreOperatorPagination} from '@models/store/store-operator';
import {StoreBrand, StoreBrandPagination} from '@models/store/store-product';

@Component({
    selector: 'app-operator',
    templateUrl: './operator.component.html',
    styleUrls: ['./operator.component.css']
})
export class OperatorComponent implements OnInit {
    filter = {
        name: ''
    };
    brands: StoreBrand[] = [];
    brandsPagination: MetaPagination;
    selectedBrand;
    @ViewChild('operatorFormTemplate') private operatorFormTemplate;
    deleteOperatorOptions: ConfirmOptionsService;

    constructor(public modalService: NgbModal,
                private storeService: StoreService,
                private spinner: NgxSpinnerService) {
        this.deleteOperatorOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    ngOnInit(): void {
        this.loadBrands();
    }

    showModal(brand?) {
        this.selectedBrand = brand;
        this.modalService.open(this.operatorFormTemplate, {size: 'md'});
    }

    deleteBrand(id: number) {
        this.storeService.deleteBrand(id).subscribe(() => {
            const index = this.brands.findIndex(data => data.id === id);
            if (index > -1) {
                this.brands.splice(index, 1);
                this.storeService.successAlert('Deleted Brand!');
            }
        }, (err) => {
            this.storeService.errorAlert(err.message);
        });
    }

    loadBrands() {
        this.spinner.show('table-operators');
        const params = {};
        if (this.brandsPagination?.currentPage) {
            params['page'] = this.brandsPagination?.currentPage;
        }
        if (this.filter.name) {
            params['nome'] = this.filter.name;
        }
        this.storeService.getBrands(params).subscribe((response: StoreBrandPagination) => {
            this.brands = response.data;
            this.brandsPagination = response._meta;
            this.spinner.hide('table-operators');
        }, err => {
            this.spinner.hide('table-operators');
        });
    }
}
