<div class="row position-relative">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="detail" [fullScreen]="false">
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <div class="col-12 col-xl-4">
    <table class="table table-bordered table-sm collapse-table position-relative">
      <thead>
        <tr class="bg-gradient-dark">
          <th colspan="2" class="font-weight-normal">Dati Ordine</th>
        </tr>
      </thead>
      <tbody>
        <!--            <tr>-->
        <!--                <th scope="row">Codice RT</th>-->
        <!--                <td class="text-right">{{order?.detail?.operator_code?.tiscali}}</td>-->
        <!--            </tr>-->
        <tr>
          <th scope="row">Operatori Abilitati</th>
          <td class="text-right">
            <img *ngFor="let vendor of order?.vendors" src="assets/img/small_brands/{{ vendor.name }}.png" class="mr-1" height="18px" />
          </td>
        </tr>
        <tr>
          <th scope="row">Codice Kena</th>
          <td class="text-right">{{ order?.detail?.operator_code?.kena }}</td>
        </tr>
        <!--            <tr>-->
        <!--                <th scope="row">Costo Spedizione</th>-->
        <!--                <td class="text-right">-->
        <!--                    <a href="javascript:void(0)" [disablePopover]="!order?.can_edit" [class.disabled]="!order?.can_edit"-->
        <!--                       (click)="openPopover(shippingPopover,order?.id,order?.detail?.shipping_total,-->
        <!--                            'spese_di_spedizione','order')"-->
        <!--                       #shippingPopover="ngbPopover" autoClose="outside" triggers="manual"-->
        <!--                       [ngbPopover]="popContent" popoverTitle="Change value">-->
        <!--                        <span *ngIf="order?.detail?.shipping_total">{{order?.detail?.shipping_total|currency:currency}}</span>-->
        <!--                        <span *ngIf="!order?.detail?.shipping_total">{{0|currency:currency}}</span>-->
        <!--                    </a>-->
        <!--                </td>-->
        <!--            </tr>-->
        <tr>
          <th scope="row">Operatore che ha gestito l'ordine</th>
          <td class="text-right">{{ order?.detail?.operator }}</td>
        </tr>
        <tr>
          <th scope="row">Operatore che ha gestito il plafond</th>
          <td class="text-right">{{ order?.detail?.operator_plafond }}</td>
        </tr>
        <tr>
          <th scope="row">Fido Disponibile</th>
          <td class="text-right">
            {{ order?.surety_total | currency : '€' }}
          </td>
        </tr>
        <tr *ngIf="order?.surety_total >= order.amount">
          <th scope="row">Ordine con fido</th>
          <td class="text-right">
            <a
              href="javascript:void(0)"
              [disablePopover]="!order?.can_edit"
              (click)="openSuretyPopover(suretyPopover, order?.id, order?.surety, 'welding_date', weldingOptions)"
              #suretyPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContent"
              popoverTitle="Cambia valore"
            >
              <ng-container *ngIf="order?.surety !== null">
                <i class="text-success fas fa-check"></i>
                {{ order.surety.welding_date | amDateFormat : 'DD/MM/YYYY' }}
              </ng-container>
              <i class="text-danger fas fa-times" *ngIf="order?.surety === null"></i>
            </a>
          </td>
        </tr>
        <tr *ngIf="order.is_sim">
          <th scope="row">Documento di trasporto</th>
          <td class="text-right">
            <button class="btn btn-primary btn-sm float-right" type="button" (click)="downloadDdt()">Scarica DDT</button>
          </td>
        </tr>
        <tr>
          <th scope="row">Fatture in cloud</th>
          <td class="text-right">
            <button *ngIf="!order.fic_id" class="btn btn-primary btn-sm float-right" type="button" [disabled]="loadingInvoice" (click)="createInvoice()">Crea fattura</button>
            <a *ngIf="order.fic_id" target="_blank" href="https://secure.fattureincloud.it/invoices-view-{{ order.fic_id }}">Vai alla fattura</a>
          </td>
        </tr>
        <tr>
          <th scope="row">Fattura in XML</th>
          <td>
            <button class="btn btn-warning btn-sm float-right ml-2" type="button" (click)="downloadXml()" [disabled]="loadingXml">Scarica XML</button>
          </td>
        </tr>
        <tr>
          <th scope="row">
            Numero Fattura: <span style="color: green">{{ order.fic_id ? order.fic_id : '-' }}</span>
          </th>
          <td class="d-flex justify-content-between align-items-center">
            <div class="row w-100 pr-0">
              <div class="col-10 d-flex justify-content-center align-items-center pr-0">
                <div class="form-group w-100">
                  <label for="newFicId" style="font-size: small">Inserisci/Modifica n. fattura</label>
                  <input type="text" id="newFicId" class="form-control" [(ngModel)]="newFicId" />
                </div>
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center pr-0 pt-2">
                <button class="btn btn-info btn-sm" (click)="openConfirmFicIdModal(order.id)"><i class="fa fa-save cursor-pointer mr-0"></i></button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 col-xl-4">
    <table class="table table-bordered table-sm collapse-table position-relative">
      <thead>
        <tr>
          <th colspan="2" class="font-weight-normal bg-gradient-dark">Dati Pagamento</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="order?.payment === 0">
          <th>Contabile</th>
          <td class="text-right">
            <a [href]="order?.detail?.file_path" target="_blank" *ngIf="order?.detail?.file_path">Scarica</a>
            <span *ngIf="!order?.detail?.file_path">-</span>
            <button class="btn btn-link mr-0 pr-0" *ngIf="order?.can_edit" (click)="showFileModal()">
              <i class="fas fa-file-upload text-success"></i>
            </button>
          </td>
        </tr>
        <tr>
          <th>Credito Dealer</th>
          <td class="text-right">
            <a [href]="'gestione-crediti/gestione-credito/' + order?.dealer?.id" target="_blank">Vai</a>
          </td>
        </tr>
        <tr>
          <th>Codice Tracking</th>
          <td class="text-right">
            <a
              href="javascript:void(0)"
              [disablePopover]="!order?.can_edit"
              [class.disabled]="!order?.can_edit"
              (click)="openPopover(trackingPopover, order?.id, order?.detail?.tracking, 'tracking', 'order')"
              #trackingPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContent"
              popoverTitle="Change value"
            >
              {{ order?.detail?.tracking ? order?.detail?.tracking : '-' }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table table-bordered table-sm collapse-table position-relative">
      <thead>
        <tr>
          <th colspan="2" class="font-weight-normal bg-gradient-dark">Dati Spedizione</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Indirizzo</th>
          <td class="text-right">{{ order.detail.address.indirizzo }} {{ order.detail.address.civico }}</td>
        </tr>
        <tr>
          <th>Città</th>
          <td class="text-right">{{ order.detail.address.comune }}, {{ order.detail.address.provincia }}, {{ order.detail.address.cap }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 col-xl-4">
    <table class="table table-bordered table-sm collapse-table position-relative">
      <thead>
        <tr>
          <th class="font-weight-normal bg-gradient-dark">Note</th>
          <th class="font-weight-normal bg-gradient-dark" colspan="2">Operator Note</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ order?.note }}</td>
          <td>{{ order?.note_op }}</td>
          <td class="text-right px-2" *ngIf="order?.can_edit">
            <button class="btn btn-link p-0 m-0" (click)="showNoteModal(order)">
              <i class="far fa-sticky-note text-success"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table table-bordered table-sm collapse-table position-relative" *ngIf="order.has_spid || order.has_wallet">
      <thead>
        <tr>
          <th class="font-weight-normal bg-gradient-dark">Prodotti</th>
          <th class="font-weight-normal bg-gradient-dark" colspan="2">Gestione</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="order.has_spid">
          <td>SPID</td>
          <td>
            <button [ngClass]="{ 'btn-primary': !order.spid_assigned, 'btn-success': order.spid_assigned }" class="btn btn-sm" [disabled]="true">
              {{ order.spid_assigned ? 'Accreditato' : 'Non accreditato' }}
            </button>
          </td>
        </tr>
        <tr *ngIf="order.has_wallet">
          <td>KDWallet</td>
          <td>
            <button [ngClass]="{ 'btn-primary': !order.wallet_assigned, 'btn-success': order.wallet_assigned }" class="btn btn-sm" [disabled]="true">
              {{ order.wallet_assigned ? 'Accreditato' : 'Non accreditato' }}
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <button (click)="setPaid()" [ngClass]="{ 'btn-primary': !order.paid, 'btn-success': order.paid }" class="btn btn-sm">
              {{ order.paid ? 'Pagato' : 'Non Pagato' }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-12 col-xl-6" *ngIf="order?.is_sim">
    <table class="table table-bordered table-sm collapse-table position-relative">
      <thead>
        <tr>
          <th colspan="2" class="font-weight-normal bg-gradient-dark">Dati Spedizione</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Referente</th>
          <td class="text-right">{{ order?.detail?.address?.referente }}</td>
        </tr>
        <tr>
          <th scope="row">Via</th>
          <td class="text-right">{{ order?.detail?.address?.indirizzo }}</td>
        </tr>
        <tr>
          <th scope="row">Comune</th>
          <td class="text-right">{{ order?.detail?.address?.comune }}</td>
        </tr>
        <tr>
          <th scope="row">Provincia</th>
          <td class="text-right">{{ order?.detail?.address?.provincia }}</td>
        </tr>
        <tr>
          <th scope="row">CAP</th>
          <td class="text-right">{{ order?.detail?.address?.cap }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 col-xl-6" *ngIf="order?.is_sim">
    <table class="table table-bordered table-sm collapse-table position-relative">
      <thead>
        <tr>
          <th colspan="4" class="font-weight-normal bg-gradient-dark">
            Pacchetti Assegnati
            <button class="btn btn-success btn-sm float-right" type="button" translate (click)="replace()" [disabled]="!order?.can_edit || !order?.convalid" *ngIf="order?.is_sim">
              Riassegna Seriali
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Lotto</th>
          <th scope="row">Data</th>
          <th scope="row" class="text-center">Pacchetto N°</th>
          <th scope="row" class="text-center">N° Sim</th>
        </tr>
        <tr *ngFor="let package of order?.packages">
          <td>{{ package?.lotto }}</td>
          <td>{{ package?.data_assegnazione | amDateFormat : localFormat }}</td>
          <td class="text-center">{{ package?.numero }}</td>
          <td class="text-center">{{ package?.sim }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12">
    <table class="table table-bordered table-sm collapse-table product-table">
      <thead>
        <tr>
          <th class="font-weight-normal bg-gradient-dark" colspan="9">
            <span>Carrello</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">#</th>
          <th scope="row"></th>
          <th scope="row">Nome</th>
          <th scope="row">Descrizione</th>
          <th scope="row" class="text-center">Quantita</th>
          <th scope="row" class="text-center">Costo Unitario</th>
          <th scope="row" class="text-right">Iva</th>
          <th scope="row" class="text-right">Costo Totale</th>
          <th class="text-right" *ngIf="order?.can_edit"></th>
        </tr>
        <tr *ngFor="let item of order?.items">
          <td>{{ item.product_id }}</td>
          <td class="text-center">
            <img
              (click)="item.product.thumb = !item.product.thumb"
              src="{{ item?.product.image }}"
              class="img-fluid link"
              [width]="item.product.thumb ? 50 : 500"
            />
          </td>
          <td>
            <p>{{ item?.product?.name }}</p>
            <p class="text-sm" *ngIf="item.product.sku">sku: {{ item.product.sku }}</p>
            <p class="text-sm" *ngIf="item.product.ean">ean: {{ item.product.ean }}</p>
            <p class="text-sm" *ngIf="item.product.sconto">
              sconto pro: {{ item.product.sconto }}% - {{ item?.price * ((100 - item.product.sconto) * 0.01) | currency : '€ ' }}
            </p>
          </td>
          <td>
            <app-rich-text-truncate [text]="item?.product?.description" [charLimit]="10"></app-rich-text-truncate>
          </td>
          <td class="text-center quantity-col">
            <ng-container>
              <button class="btn btn-link m-0 p-0" (click)="decreaseProductQuantity(item)" *ngIf="order?.can_edit">
                <i class="fa fa-minus text-info"></i>
              </button>
              <span class="px-3">{{ item?.quantity }}</span>
              <button class="btn btn-link m-0 p-0" (click)="increaseProductQuantity(item)" *ngIf="order?.can_edit">
                <i class="fa fa-plus text-info"></i>
              </button>
            </ng-container>
          </td>
          <td class="text-right">
            <a
              href="javascript:void(0)"
              [disablePopover]="!order?.can_edit"
              [class.disabled]="!order?.can_edit"
              (click)="openPopover(paymentPopover, item?.id, item?.price, 'prezzo', 'product')"
              #paymentPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContent"
              popoverTitle="Change value"
            >
              <span *ngIf="item?.price">{{ item?.price | currency : currency }}</span>
            </a>
          </td>
          <td class="text-right">{{ item?.vat | currency : currency }}</td>
          <td class="text-right">{{ item?.total_price | currency : currency }}</td>
          <td class="text-right" *ngIf="order?.can_edit">
            <button class="btn btn-link" [swal]="deleteDocumentOptions" (confirm)="deleteProduct(item.id)">
              <i class="fa fa-trash text-danger"></i>
            </button>
          </td>
        </tr>

        <tr *ngFor="let tax of order.taxes">
          <th scope="row" colspan="5">{{ tax.prettyDescription }}</th>
          <td class="text-right">
            <a
              href="javascript:void(0)"
              [disablePopover]="!order?.can_edit"
              [class.disabled]="!order?.can_edit"
              (click)="openPopover(paymentPopover, tax.id, tax?.total_price, 'total_price', 'tax')"
              #paymentPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContent"
              popoverTitle="Change value"
            >
              <span *ngIf="tax?.total_price">{{ tax?.total_price | currency : currency }}</span>
            </a>
          </td>
          <td class="text-right"></td>
          <td class="text-right">{{ tax.total_price | currency : '€ ' }}</td>
          <td class="text-right">
            <button class="btn btn-link" [swal]="deleteDocumentOptions" (confirm)="deleteTax(tax.id)">
              <i class="fa fa-trash text-danger"></i>
            </button>
          </td>
        </tr>

        <tr>
          <th scope="row" colspan="7">Totale</th>
          <td class="text-right text-bold">{{ order.amount | currency : '€ ' }}</td>
          <td class="text-right"></td>
        </tr>
      </tbody>
    </table>
    <a href="javascript:void(0)" translate (click)="showProductModal()" *ngIf="order?.can_edit">Add Product</a>
    <a href="javascript:void(0)" (click)="showCostModal()" class="ml-4" translate *ngIf="order?.can_edit">Aggiungi Costo</a>
  </div>
</div>

<ng-template #popContent>
  <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
<ng-template #productFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add product</h4>
    <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [ngBusy]="busyProduct">
    <form (ngSubmit)="addProduct()" [formGroup]="productForm">
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="product" translate>Product</label>
          <ng-select id="product" (search)="loadProducts($event?.term)" [loading]="productLoading" formControlName="product" placeholder="Search by name or id">
            <ng-option *ngFor="let product of products" [value]="product"> [{{ product?.id }}] {{ product?.nome }} </ng-option>
          </ng-select>
        </div>
        <div class="col-12 col-xl-6 form-group">
          <label for="variant" translate>Variant</label>
          <ng-select id="variant" (search)="loadProducts($event?.term)" [loading]="productLoading" formControlName="variant">
            <ng-option *ngFor="let variant of variants" [value]="variant"> [{{ variant?.id }}] {{ variant?.name }} </ng-option>
          </ng-select>
        </div>
        <div class="col-12 col-xl-6 form-group">
          <label for="quantity" translate>Quantity</label>
          <input type="number" id="quantity" class="form-control" formControlName="quantity" />
        </div>
        <div class="col-12" *ngIf="selectedProduct">
          <ul class="list-group list-group-flush">
            <li class="list-group-item px-0">
              <span [innerHTML]="selectedProduct?.descrizione"></span>
            </li>
            <li class="list-group-item px-0">
              <span translate>Unit price</span>
              <span class="float-right">
                <strong>{{ unitProductPrice | currency : currency }}</strong></span
              >
            </li>
            <li class="list-group-item px-0">
              <span translate>Total price</span>
              <span class="float-right">
                <strong>{{ totalProductPrice | currency : currency }}</strong></span
              >
            </li>
          </ul>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-success btn-block" [disabled]="productForm.invalid"><i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #costFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Aggiungi Costo</h4>
    <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [ngBusy]="busyCost">
    <form (ngSubmit)="addCost()" [formGroup]="costForm">
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="type">Tipo</label>
          <ng-select id="type" (change)="loadCostType()" formControlName="cost" placeholder="Seleziona un tipo">
            <ng-option *ngFor="let type of costTypes" [value]="type?.id">
              {{ type?.name }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-12 form-group">
          <label for="price">Prezzo</label>
          <input type="number" id="price" class="form-control" formControlName="total_price" />
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-success btn-block" [disabled]="costForm.invalid"><i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #fileFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Upload file</h4>
    <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [ngBusy]="busyFile">
    <form [formGroup]="fileForm" (ngSubmit)="uploadFile()">
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="xmlFile" translate>File</label>
          <div class="custom-file" *ngIf="!file">
            <input id="xmlFile" type="file" class="custom-file-input" (change)="handleFileInput($event?.target?.files[0])" />
            <label class="custom-file-label" for="xmlFile" translate> Upload file... </label>
          </div>
          <div *ngIf="file">
            <span class="text-break">{{ file }}</span>
            <button class="btn btn-xs btn-warning float-right" type="button" (click)="handleFileRemoveModal()">
              <i class="fa fa-times m-0 px-1"></i>
            </button>
          </div>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-success btn-block" [disabled]="fileForm.invalid"><i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #noteFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Note</h4>
    <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="saveNote()" [formGroup]="noteForm">
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="operatorNote" translate>Operator Note</label>
          <textarea class="form-control" id="operatorNote" rows="5" formControlName="note_op"></textarea>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-success btn-block" [disabled]="noteForm.invalid"><i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #confirmFicIdTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">Conferma operazione</h4>
    <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Sei sicuro di voler salvare il n. fattura <strong>{{ newFicId }}</strong> per l'ordine n. <strong>{{ _orderId }}</strong
      >?
    </p>
  </div>
  <div class="col-12">
    <button class="btn btn-success btn-block mb-2" (click)="saveFicId(_orderId)"><i class="fa fa-check-circle mr-2 cursor-pointer"></i> <span translate>Save</span></button>
  </div>
</ng-template>
