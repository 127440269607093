<div class="modal-body" [ngBusy]="busy">
    <form [formGroup]="sddForm" (ngSubmit)="save()">
        <div class="form-row p-4">
            <div class="col-md-6 col-sm-12 mt-auto mb-0" *ngIf="!data">
                <label class="lead" translate>Company name</label>
                <app-retailer-typeahead (onChange)="setDealerId($event)"></app-retailer-typeahead>
                <val-errors controlName="dealer_id">
                    <ng-template valError="required">
                        <span translate>Company name is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-md-6 col-sm-12 mt-auto mb-0" *ngIf="!data">
                <label class="lead" translate>Type</label>
                <ng-select formControlName="type" [items]="sddTypesStatus" bindLabel="label" id="key" bindValue="key">
                </ng-select>
                <val-errors controlName="Type">
                    <ng-template valError="required">
                        <span translate>Type is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <!-- <div class="col-md-6 col-sm-12 mt-auto mb-0" *ngIf="!data">
                <label class="lead">Key Account</label>
                <app-agent-select (onChange)="setKaId($event)"></app-agent-select>
                <val-errors controlName="ka_id">
                    <ng-template valError="required">
                        <span translate>Key Account is required</span>
                    </ng-template>
                </val-errors>
            </div> -->

            <div class="col-md-6 col-sm-12 mt-2 mb-0" *ngIf="!data">
                <label class="lead" translate>Iban</label>
                <input class="form-control" formControlName="iban" />
                <val-errors controlName="iban">
                    <ng-template valError="required">
                        <span translate>Iban is required</span>
                    </ng-template>
                    <ng-template valError="pattern">
                        <span translate>Iban errors</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-md-6 col-sm-12 mt-auto mb-0" *ngIf="!data">
                <label class="lead" translate>Iban type</label>
                <ng-select
                    formControlName="iban_type"
                    [items]="sddIbanTypeStatus"
                    bindLabel="label"
                    id="key"
                    bindValue="key"
                >
                </ng-select>
                <val-errors controlName="iban_type">
                    <ng-template valError="required">
                        <span translate>Type is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-md-6 col-sm-12 mt-auto mb-0" *ngIf="!data">
                <label class="lead" translate>Payment type</label>
                <ng-select
                    formControlName="payments_type"
                    [items]="sddPaymentsTypeStatus"
                    bindLabel="label"
                    id="key"
                    bindValue="key"
                >
                </ng-select>
                <val-errors controlName="payments_type">
                    <ng-template valError="required">
                        <span translate>Type is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-md-6 col-sm-12 mt-2 mb-0">
                <label class="lead" translate>Signature date</label>
                <div class="input-group">
                    <input
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        formControlName="signature_date"
                        ngbDatepicker
                        #d="ngbDatepicker"
                    />
                    <div class="input-group-append">
                        <button class="btn btn-info" (click)="d.toggle()" type="button">
                            <i class="fas fa fa-calendar mr-0"></i>
                        </button>
                    </div>
                </div>
                <val-errors controlName="dateRange">
                    <ng-template valError="required">
                        <span translate>Date is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-md-6 col-sm-12 mt-2 mb-0">
                <div class="form-group">
                    <label class="lead">Scegli il file</label>
                    <div class="custom-file">
                        <input
                            type="file"
                            class="custom-file-input"
                            id="validatedCustomFile"
                            (change)="handleFileInput($event.target.files)"
                        />
                        <label class="custom-file-label" for="validatedCustomFile">Scegli il file</label>
                    </div>
                    <val-errors controlName="file">
                        <ng-template valError="required"> Il file è obbligatorio </ng-template>
                    </val-errors>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 pr-0 mb-0 mt-4">
                <label for=""></label>
                <button class="btn btn-info btn-block" type="submit" [disabled]="sddForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
                </button>
            </div>
        </div>
    </form>
</div>
