<div class="modal-body">
    <form [formGroup]="pbxInputForm" (ngSubmit)="save()">
      <div class="col-md-12 col-sm-12 mt-2 mb-0">
        <div class="form-group mb-0">
          <label class="lead">Report Campagne</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input cursor-hand"
              id="validatedCustomFile"
              formControlName="fileInput"
              (change)="onFileSelected($event)"
            />
            <label class="custom-file-label text-green" *ngIf="file">{{ file.name }}</label>
            <label
              class="custom-file-label"
              *ngIf="!file"
              for="validatedCustomFile"
              >Scegli il file</label
            >
          </div>
          <val-errors controlName="file">
            <ng-template valError="required">
              Il file è obbligatorio
            </ng-template>
          </val-errors>
        </div>
        <label for=""></label>
        <p class="text-danger mb-2">Prima di caricare il report assicurati che la colonna del <strong>DEALER ID</strong> sia la prima!!!</p>
        <button class="btn btn-info btn-block mb-2" type="submit">
          <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
        </button>
      </div>
    </form>
  </div>
  