import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {NgxSpinnerService} from 'ngx-spinner';
import * as XLSX from 'xlsx';
import {VisiteService} from '@services/visite.service';
import {RequestService} from '@services/request.service';

@Component({
    selector: 'app-master-visite-index',
    templateUrl: './master-visite-index.component.html',
    styleUrls: ['./master-visite-index.component.css'],
})
export class MasterVisiteIndexComponent implements OnInit {
    selectedDateRange = {
        from: '',
        to: '',
    };

    filterForm = {
        dateRange: '',
    };

    rows: [];
    global: any;

    defaultView = true;

    loading = false;
    laddaExcel = false;

    constructor(
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig,
        private service: VisiteService,
        private requestService: RequestService,
    ) {
        this.dateRangePickerOptions.settings.locale.format = 'DD/MM/YYYY';
        this.setDefaultRange();
    }

    ngOnInit() {
    }

    refresh() {
        this.loading = true;
        this.service
            .globalSituation(this.selectedDateRange.from, this.selectedDateRange.to)
            .subscribe((data) => {
                    this.rows = data.rows;
                    this.global = data.global;
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                });
    }

    setDefaultRange() {
        this.setSelectedDateRange(moment().startOf('month'), moment());
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        if (from.isSame(moment().startOf('month')) && to.isSame(moment())) {
            this.defaultView = true;
        } else {
            this.defaultView = false;
        }

        const selectedDateRange = this.getDateString(from, to);
        this.filterForm.dateRange = selectedDateRange;
        this.refresh();
    }

    exportList() {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rows);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'export.xlsx');
    }

    downloadExcel() {
        this.laddaExcel = true;
        this.service
            .globalSituation(this.selectedDateRange.from, this.selectedDateRange.to, true)
            .subscribe((data) => {
                    this.requestService.downloadFile(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    this.laddaExcel = false;
                },
                (error) => {
                    this.laddaExcel = false;
                });
    }

    private getDateString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format('YYYY-MM-DD');
        this.selectedDateRange.to = end.format('YYYY-MM-DD');
        return (
            start.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            end.format(localDateFormat)
        );
    }
}
