<div
  [ngBusy]="busy"
  ngbRadioGroup
  [(ngModel)]="activeTab"
  (ngModelChange)="changeBrand()"
  *ngIf="operatori.length > 0"
  class="btn-group btn-group-toggle flex-wrap w-100 elevation-1 rounded bg-gradient-light"
>
  <label *ngFor="let op of operatori" class="btn py-2" ngbButtonLabel [ngClass]="{ 'active btn-light': activeTab === op }">
    <img [src]="getImage(op)" height="30px" />
    <input ngbButton type="radio" [value]="op" />
  </label>
</div>

<div class="card bg-gradient-light mt-3">
  <div class="card-body">
    <div class="row align-items-end">
      <div class="col-12 col-lg-2">
        <label>Seleziona un periodo</label>
        <div class="form-group form-group-icon mb-0">
          <i class="far fa-calendar-alt"></i>
          <select class="form-control border" [(ngModel)]="this.periodo">
            <ng-container *ngIf="this.startup">
              <option *ngFor="let per of this.periodi" [value]="per.value">{{ per.label }}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-2">
        <label>Seleziona un sottocanale</label>
        <div class="form-group form-group-icon mb-0">
          <i class="fas fa-cog form-input-icon"></i>
          <select class="form-control border" [(ngModel)]="this.canale">
            <ng-container *ngIf="this.curStartup">
              <option *ngFor="let canale of this.curStartup.canali" [value]="canale">{{ canale }}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-2" *show="['master', 'submaster', 'operator']">
        <label>Seleziona un <span translate>Master Agent</span></label>
        <app-master-agent-select (onChange)="setMasterAgent($event)" #masterAgentSelect></app-master-agent-select>
      </div>
      <div class="col-12 col-lg-2" *show="['master', 'submaster', 'masteragent', 'operator']">
        <label>Seleziona un <span translate>Agent</span></label>
        <app-agent-select (onChange)="setAgent($event)" #agentSelect></app-agent-select>
      </div>
      <div class="col-12 col-lg-2">
        <label>Proiezione</label>
        <div class="form-group form-group-icon mb-0">
          <i class="fas fa-chart-line form-input-icon"></i>
          <select class="form-control border" [(ngModel)]="this.proiezione">
            <option value="No">No</option>
            <option value="Si">Si</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-2 text-right">
        <p class="text-md font-italic">
          I dati sono aggiornati al

          <ng-container *ngIf="this.curStartup">
            <b>{{ this.curStartup.ultimo_update }}</b>
          </ng-container>
        </p>
      </div>
    </div>
  </div>
</div>

<app-master-dashboard-tab
  *ngIf="ready"
  [background]="background"
  [brand]="getBrandUrl(activeTab)"
  [urlPuntiVendita]="urlPuntiVendita"
  [curStartup]="curStartup"
  [urlFatturato]="urlFatturato"
  [urlRiepilogo]="urlRiepilogo"
  [urlTarget]="urlTarget"
  [urlVendite]="urlVendite"
  [urlOfferte]="urlOfferte"
></app-master-dashboard-tab>
