import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OrdersControl } from '@app/models/orders-control';
import { OrdersControlService } from '@app/services/orders-control.service';
import { ShopOrdini } from '@app/models/orders-control';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-orders-control-carta-di-credito',
  templateUrl: './orders-control-carta-di-credito.component.html',
  styleUrls: ['./orders-control-carta-di-credito.component.css'],
})
export class OrdersControlCartaDiCreditoComponent implements OnInit {
  @Input() filterParams: any;
  @Input() currentDate: any;
  @Output() updateCompleted: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('cdcModalTemplate') private cdcModalTemplate: any;
  @ViewChild('cdcModalImportTemplate') private cdcModalImportTemplate;

  reportControlOrders: OrdersControl[] = [];
  viewData: ShopOrdini;
  insertData: ShopOrdini;
  orderId: any = ''; // Variabile per memorizzare l'ID dell'ordine selezionato
  transactionId: any = '';

  status = [
    {
      label: 'Da Saldare',
      key: '0',
    },
    {
      label: 'Saldato',
      key: '1',
    },
  ];

  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };

  constructor(private ordersService: OrdersControlService, private spinner: NgxSpinnerService, public modalService: NgbModal) {}

  ngOnInit(): void {
    this.loadOrders();
    setTimeout(() => {});
  }

  public loadOrders(hideSpinner = false) {
    this.spinner.show('table-orders');
    const params = {};

    if (this.filterParams.dealer_id) {
      params['dealer_id'] = this.filterParams.dealer_id;
    }
    if (this.filterParams.date) {
      params['from_date'] = this.currentDate.from;
      params['to_date'] = this.currentDate.to;
    }

    if (this.filterParams.saldato) {
      params['saldato'] = this.filterParams.saldato;
    }

    this.ordersService.getReportOrdersControlCarta(params).subscribe(
      (response: any) => {
        this.reportControlOrders = response;
        this.updateCompleted.emit();
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.updateCompleted.emit();
        this.spinner.hide('table-orders');
        console.log('errore');
      },
    );
  }

  openPopover(popOver: any, orderId, item, key, selectItems = []) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }

  save(value) {
    const body = {};
    body[this.popupConfig['key']] = value;
    this.spinner.show('popupSpinner');
    return this.changeOrder(this.popupConfig.id, body);
  }

  statusClick(orderId, newStatus) {
    const body = {
      saldato: newStatus, // Supponendo che saldato sia il campo da aggiornare
    };
    this.changeOrder(orderId, body);
  }

  showModal(order?: ShopOrdini) {
    this.orderId = order?.id;
    this.modalService.open(this.cdcModalTemplate, { size: 'lg' });
  }

  showModalImport(data?: ShopOrdini) {
    this.insertData = data;
    this.modalService.open(this.cdcModalImportTemplate, { size: data ? 'md' : 'lg' });
  }

  changeOrder(orderId, body) {
    this.ordersService.putReportStatus(orderId, body).subscribe(
      (response) => {
        this.loadOrders(true);
        this.spinner.hide('popupSpinner');
        this.updateCompleted.emit();
      },
      (err) => {
        this.loadOrders(true);
        this.modalService.dismissAll();
        this.spinner.hide('popupSpinner');
      },
    );
  }
}
