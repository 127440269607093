import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Group, GroupDealers} from '@models/group';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {GroupService} from '@services/group.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-group-dealer-modal',
    templateUrl: './group-dealer-modal.component.html',
    styleUrls: ['./group-dealer-modal.component.css']
})
export class GroupDealerModalComponent implements OnInit {
    groupForm: FormGroup;
    @Input() groupId: number;
    @Input() modal: NgbModalRef;
    @Output() addDealer = new EventEmitter<boolean>();
    busy: any;

    constructor(
        private groupService: GroupService,
    ) {
    }

    ngOnInit(): void {
        this.groupForm = new FormGroup({
            dealer_id: new FormControl('', Validators.required),
        });
    }

    dismissModal() {
        this.groupForm.reset();
        this.modal.dismiss();
    }

    save() {
        const dealer = new GroupDealers();
        dealer.group_id = this.groupId;
        dealer.dealer_id = this.groupForm.get('dealer_id').value;
        this.busy = this.groupService.addGroupDealers(dealer).subscribe((response) => {
            this.addDealer.emit(response);
            this.dismissModal();
            this.groupService.successAlert("added Dealer!");
        }, err => {
            this.dismissModal();
            this.groupService.errorAlert("Dealer already exists in this group!");
        });
    }

    selectDealer($event) {
        this.groupForm.get('dealer_id').setValue($event);
    }
}
