import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaPagination } from '@app/models/meta-pagination';
import { User } from '@app/models/user';
import { VisureCatastali } from '@app/models/visure';
import { AuthenticationService } from '@app/services/authentication.service';
import { RequestService } from '@app/services/request.service';
import { VisureService } from '@app/services/visure.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-visure-catastali',
  templateUrl: './visure-catastali.component.html',
  styleUrls: ['./visure-catastali.component.css']
})
export class VisureCatastaliComponent implements OnInit {

  orders: VisureCatastali[] = [];
  ordersPagination: MetaPagination = new MetaPagination();
  isDealer = false;
  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    }
  };
  filter = {
    id: '',
    company_name: '',
    dealer_id: '',
    transaction_id: '',
    richiedente: '', 
    tipo_richiesta:'',
    created_at: '',
    date: '',
  };
  apiDate = {
    from: '',
    to: '',
  };
  status = [
    {
      lable: "Completato",
      key: "completed"
    },
    {
      lable: "In attesa",
      key: "pending"
    },
    {
      lable: "Dati disponibili",
      key: "Dati disponibili"
    }
  ]
  tipoRichiestaLabel = VisureCatastali.tipoRichiesta;
  statuses = VisureCatastali.statusBadge;

  constructor(
    public request: RequestService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private dateRangePickerOptions: DaterangepickerConfig,
    private visureService: VisureService,
    public route: ActivatedRoute,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadList();
    setTimeout(() => {});
  }

  loadList(){
    this.spinner.show('table-orders');
    const params = {};

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }

    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }

    if (this.filter.richiedente) {
      params['richiedente'] = this.filter.richiedente;
    }

    if (this.filter.transaction_id) {
      params['transaction_id'] = this.filter.transaction_id;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    if (this.filter.tipo_richiesta) {
      params['tipo_richiesta'] = this.filter.tipo_richiesta;
    }

    this.visureService.getVisureCatastaliList(params).subscribe((response: any) => {
      this.orders = new VisureCatastali().fromArray(response.data);
      this.ordersPagination = response._meta;
      this.spinner.hide('table-orders');
    }, err => {
      this.spinner.hide('table-orders');
    });
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat)
    + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  exportVisureCatastali() {
    const params = {
        'per-page': 0
    };

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }

    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }

    if (this.filter.richiedente) {
      params['richiedente'] = this.filter.richiedente;
    }

    if (this.filter.transaction_id) {
      params['transaction_id'] = this.filter.transaction_id;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    if (this.filter.tipo_richiesta) {
      params['tipo_richiesta'] = this.filter.tipo_richiesta;
    }

    this.visureService
      .getVisureCameraliList(params)
      .subscribe((data) => {
          let rows = data.data;

          let output = rows.map((row) => {
          return {
            'Dealer': row.company_name,
            'Data': row.created_at,
            'Richiedente': row.denominazione,
            'Tipo di richiesta': row.tipoRichiesta,
            'Id transazione': row.transaction_id,
            'Stato': row.status,
          };
        });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-visure-camerali.xlsx');
    });
  }

}
