import { Component, OnDestroy, OnInit } from '@angular/core';
import { MetaPagination } from '@app/models/meta-pagination';
import { SuretyOrdersList, SuretyOrdersListPagination } from '@app/models/surety';
import { SuretyService } from '@app/services/surety.service';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { Moment } from 'moment/moment';
import * as moment from 'moment';

@Component({
  selector: 'app-surety-orders',
  templateUrl: './surety-orders.component.html',
  styleUrls: ['./surety-orders.component.css'],
})
export class SuretyOrdersComponent implements OnInit, OnDestroy {
  ordersList: SuretyOrdersList[];
  pagination: MetaPagination;

  apiDateWelded = {
    from: null,
    to: null,
  };

  apiDateCreated = {
    from: null,
    to: null,
  };

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  suretyTypes: { label: string; value: string }[] = [
    {
      label: 'Kena',
      value: 'plafond-kena',
    },
    {
      label: 'Prodotti',
      value: 'prodotti',
    },
  ];

  suretyTypeSelectedValue: string = null;
  suretyTypeSelectedLabel: string = null;
  dealerId: number = null;

  filter = {
    dealerId: null,
    welding_date: null,
    created: null,
    type: null,
    welded: null,
    stato: null,
  };

  statuses = [
    {
      label: 'In Revisione',
      value: 1,
    },
    {
      label: 'In Attesa',
      value: 2,
    },
    {
      label: 'Gestito',
      value: 3,
    },
    {
      label: 'Tornata in sede',
      value: 4,
    },
    {
      label: 'Annullato',
      value: 5,
    },
  ];

  weldedStatuses = [
    {
      label: 'Si',
      value: '1',
    },
    {
      label: 'No',
      value: '0',
    },
  ];

  constructor(private suretyService: SuretyService, private spinner: NgxSpinnerService, private dateRangePickerOptions: DaterangepickerConfig) {}

  ngOnInit(): void {
    this.loadOrders();
  }

  loadOrders() {
    this.spinner.show('table');

    const params = {};
    if (this.pagination?.currentPage) {
      params['page'] = this.pagination?.currentPage;
    }
    if (this.filter.welding_date) {
      params['from_welded'] = this.apiDateWelded.from;
      params['to_welded'] = this.apiDateWelded.to;
    }
    if (this.filter.created) {
      params['from_created'] = this.apiDateCreated.from;
      params['to_created'] = this.apiDateCreated.to;
    }
    if (this.filter.dealerId) {
      params['dealer_id'] = this.filter.dealerId;
    }
    if (this.filter.type) {
      params['type'] = this.filter.type;
    }
    if (this.filter.welded) {
      params['welded'] = this.filter.welded;
    }
    if (this.filter.stato) {
      params['stato'] = this.filter.stato;
    }

    this.suretyService.getSuretyOrders(params).subscribe(
      (response: SuretyOrdersListPagination) => {
        this.ordersList = response.data;
        this.pagination = response._meta;
        this.spinner.hide('table');
      },
      (error) => {
        console.log('Orders Error: ', error);
        this.spinner.hide('table');
      },
    );
  }

  onSuretyChange() {
    if (this.suretyTypeSelectedValue == null) {
      this.suretyTypeSelectedLabel = null;
      this.suretyTypeSelectedValue = null;
      this.filter.type = null;
    } else {
      let selectedType = this.suretyTypes.find((type) => type.value === this.suretyTypeSelectedValue);
      this.suretyTypeSelectedLabel = selectedType ? selectedType.label : '';
      this.filter.type = this.suretyTypeSelectedValue;
    }
  }

  onDealerChange(event) {
    this.filter.dealerId = event;
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment, welded: boolean) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    if (welded) {
      this.apiDateWelded.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
      this.apiDateWelded.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
      this.filter.welding_date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    } else {
      this.apiDateCreated.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
      this.apiDateCreated.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
      this.filter.created = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }
  }

  ngOnDestroy(): void {
    this.dealerId = null;
    this.suretyTypeSelectedValue = null;
  }
}
