import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '@services/authentication.service';
import {User} from '@models/user';

@Component({
    selector: 'app-master-classifica',
    templateUrl: './master-classifica.component.html',
    styleUrls: ['./master-classifica.component.css']
})
export class MasterClassificaComponent implements OnInit {
    busy: Subscription;

    urlClassificaItalia: string;
    urlClassificaAgenti: string;
    urlClassificaAreaManager: string;
    urlClassificaRegioni: string;

    private user: User;
    stand = false;

    constructor(
        private authService: AuthenticationService) {
        this.authService.currentUser.subscribe((user) => (this.user = user));

        this.stand = this.user && this.user.profile.operatori.includes('Kena Mobile Stands');
    }

    ngOnInit() {
    }

    updateUrls(operatore) {
        this.urlClassificaItalia = `sales/ranking-shops?vendor=${operatore}`;
        this.urlClassificaAgenti = `sales/ranking-manager?vendor=${operatore}`;
        this.urlClassificaAreaManager = `sales/ranking-area-manager?vendor=${operatore}`;
        this.urlClassificaRegioni = `sales/ranking-region?vendor=${operatore}`;
    }
}
