import {Component, OnInit} from '@angular/core';
import {InvoiceService} from '@services/invoice.service';
import {ProFormaType} from '@models/invoice';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-invoice-types',
    templateUrl: './invoice-types.component.html',
    styleUrls: ['./invoice-types.component.css']
})
export class InvoiceTypesComponent implements OnInit {

    proFormaTypes: ProFormaType[] = [];
    proFormaTypeForm: FormGroup;

    showForm = false;

    loadingForm = false;
    loading = false;

    constructor(
        private invoiceService: InvoiceService,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.refresh();

        this.proFormaTypeForm = this.formBuilder.group({
            descrizione: [null, [Validators.required]],
            tipo: [0, [Validators.required]],
            id_operatore: [null, []]
        });
    }

    refresh() {
        this.loading = true;
        this.invoiceService.listProFormaTypes()
            .subscribe((data: ProFormaType[]) => {
                this.proFormaTypes = data;
                this.loading = false;
            });
    }

    setOperator($event: any) {
        this.proFormaTypeForm.patchValue({id_operatore: $event});
    }

    submit() {
        if (this.proFormaTypeForm.valid) {
            this.loadingForm = true;
            this.invoiceService
                .createProFormaType(this.proFormaTypeForm.value)
                .subscribe((result: any) => {
                    this.loadingForm = false;
                    this.showForm = false;
                    this.proFormaTypeForm.reset();
                    this.refresh();
                });
        }
    }

    showFormCard() {
        this.proFormaTypeForm.patchValue({
            id_operatore: null,
            descrizione: null,
            tipo: 0
        })
        this.showForm = true;
    }
}
