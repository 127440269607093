import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ShopAccess} from '@app/pages/shop-access/shop-access.type';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ShopAccessService} from '@services/shop-access.service';
import {Moment} from 'moment';
import {Group, GroupProvider} from '@models/group';
import {GroupService} from '@services/group.service';
import {MetaPagination} from '@models/meta-pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {group} from '@angular/animations';
import {ConfirmOptionsService} from '@services/confirm-options.service';


@Component({
    selector: 'app-group-modal',
    templateUrl: './group-modal.component.html',
    styleUrls: ['./group-modal.component.css']
})
export class GroupModalComponent implements OnInit {

    @Input() data: Group;
    @Output() refresh = new EventEmitter<boolean>();

    groupForm: FormGroup;
    busy: any;
    deleteGroupDealers: ConfirmOptionsService;
    @ViewChild('groupDealerTemplate') private groupDealerTemplate;
    dealerModal: NgbModalRef;
    dealers: any[] = [];


    constructor(
        public modalService: NgbModal,
        private dateRangePickerOptions: DaterangepickerConfig,
        private spinner: NgxSpinnerService,
        private groupService: GroupService
    ) {
        this.deleteGroupDealers = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }


    ngOnInit(): void {
        if (!this.data) {
            this.groupForm = new FormGroup({
                company_name: new FormControl('', Validators.required),
                dealer_id: new FormControl('', Validators.required),
                type: new FormControl('', Validators.required),
                name: new FormControl('', Validators.required)
            });
        } else {
            this.groupForm = new FormGroup({
                    type: new FormControl(this.data?.type, Validators.required),
                    name: new FormControl(this.data?.name, Validators.required)
                }
            );
            this.dealers = this.data?.group_dealers || [];
        }
    }


    save() {
        const form = this.groupForm.value;
        const group = new Group();

        group.type = form?.type;
        group.name = form?.name;
        group.company_name = form?.company_name;
        group.dealer_id = form?.dealer_id;

        if (this.data) {
            return this.updateGroup(group);
        }
        return this.addGroup(group);
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    addGroup(selectedGroup: Group) {
        this.busy = this.groupService.addGroup(selectedGroup).subscribe((response: Group) => {
            this.groupService.successAlert('Added Group!');
            this.refresh.emit(true);
            this.dismissModal();
        }, err => {
            this.groupService.errorAlert(err.message);
            this.refresh.emit(true);
            this.dismissModal();
        });
    }

    updateGroup(selectedGroup: Group) {
        this.busy = this.groupService.updateGroup(this.data.id, selectedGroup).subscribe((response: Group) => {
            this.groupService.successAlert('Updated Group!');
            this.refresh.emit(true);
            this.dismissModal();
        }, err => {
            this.groupService.errorAlert(err.message);
            this.refresh.emit(true);
            this.dismissModal();
        });

    }

    setDealerId(dealerId: any) {
        this.groupForm.get('dealer_id').setValue(dealerId);
    }


    deleteGroupDealer(id: any) {
        this.groupService.deleteGroupDealers(id).subscribe((response) => {
            const index = this.dealers.findIndex(x => x.id === id);
            if (index >= 0) {
                this.dealers.splice(index, 1);
            }
        });
    }

    showModal() {
        this.dealerModal = this.modalService.open(this.groupDealerTemplate, {size: 'md'});
    }

    addDealer(event: any) {
        this.dealers.push(event);
    }
}
