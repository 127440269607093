import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { AlertService } from '@services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ControlloEcTimService {

  constructor(private request: RequestService, public alertService: AlertService) { }

  getList(params?): Observable<any> {
    return this.request.get('v3/estratti-conto-tim/list', params);
  }

  getTotals(params?): Observable<any> {
    return this.request.get('v3/estratti-conto-tim/totals', params)
  }

  putEcTimInvoice(dataArray) {
    return this.request.put('v3/estratti-conto-tim/mass-update', dataArray);
  }

  postCharges(payload){
    return this.request.post('v3/estratti-conto-tim/add-charges', payload);
  }


  public successAlert(text) {
    this.alertService.show('', 'Transazione ' + text, { classname: 'bg-success text-light', delay: 2000 });
  }

  public errorAlert(message?) {
    this.alertService.show('Errore ', message ? message : 'Riprova di nuovo!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }
}
