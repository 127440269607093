<div class="row">
    <div class="col-12" *isVendorEnabled="['Kena Mobile']">
        <app-dashboard-riepilogo
                bgClass="bg-kena-gradient"
                logo="/assets/img/brands/kena.png"
                title="Kena Mobile"
                dataUrl="/get-home-kena"
        ></app-dashboard-riepilogo>
    </div>
    <div class="col-12" *isVendorEnabled="['Tim']">
        <app-dashboard-riepilogo
                bgClass="bg-tim-gradient"
                logo="/assets/img/brands/tim.png"
                title="Tim Mobile"
                dataUrl="/get-home-tim"
        ></app-dashboard-riepilogo>
    </div>
    <div class="col-12" *isVendorEnabled="['Eni gas e luce']">
        <app-dashboard-riepilogo
                bgClass="bg-eni-gradient"
                logo="/assets/img/brands/eni.png"
                title="Eni gas e luce"
                dataUrl="/get-home-eni"
        ></app-dashboard-riepilogo>
    </div>
</div>

<div class="row">
    <div class="col-xl-12 col-xxl-4">
        <div class="card bg-gradient-light">
            <div class="card-body p-0">
                <full-calendar [options]="calendarOptions"></full-calendar>
                <!--                <full-calendar themeSystem="bootstrap" defaultView="dayGridMonth" [events]="events" [firstDay]="1"-->
                <!--                               [plugins]="calendarPlugins"-->
                <!--                               locale="it"></full-calendar>-->
            </div>
        </div>
    </div>
    <div class="col-xl-12 col-xxl-8">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="card-title">I tuoi contatti di supporto</h1>
            </div>
            <div class="row">

                <div class="col-lg-2 col-6 user" *ngFor="let employee of employees">
                    <img class="elevation-1" style="height: 100px; width: 100px; "
                         [src]="employee?.image_path" alt="User Image">
                    <a class="users-list-name mt-1" href="#">{{employee?.ful_name}}</a>
                    <span class="users-list-date">{{employee?.department}}</span>
                    <span class="users-list-date" *ngIf="employee?.phone_number"><a
                            href="tel:{{employee?.phone_number}}"><i
                            class="fa fa-phone mr-1"></i> Chiama</a></span>
                    <span class="users-list-date"><a href="mailto:{{employee?.email}}"><i
                            class="fa fa-envelope mr-1"></i> Invia mail</a></span>
                </div>
                <div class="col-12">
                    <h1 class="display-6 mb-0 pl-3">Direzione</h1>
                    <hr class="mt-0">
                </div>
                <div class="col-lg-2 col-6 user" *ngFor="let employee of direction">
                    <img class="elevation-1" style="height: 100px; width: 100px; "
                         [src]="employee?.image_path" alt="User Image">
                    <a class="users-list-name mt-1" href="#">{{employee?.ful_name}}</a>
                    <span class="users-list-date">{{employee?.department}}</span>
                    <span class="users-list-date" *ngIf="employee?.phone_number"><a
                            href="tel:{{employee?.phone_number}}"><i
                            class="fa fa-phone mr-1"></i> Chiama</a></span>
                    <span class="users-list-date"><a href="mailto:{{employee?.email}}"><i
                            class="fa fa-envelope mr-1"></i> Invia mail</a></span>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #modal let-modal class="bg-gradient-light">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Fatturazione Elettronica</h4>
    </div>
    <div class="modal-body">
        <p class="lead">Gentile Partner,</p>
        <p class="lead">a breve partirà il nuovo sistema di emissione della fattura compensi che ti consentirà di
            riceverli in più sicurezza, velocizzare i processi amministrativi ed evitare discrepanze e o errori nella
            trasmissione delle fatture elettroniche.</p>
        <br>
        <p class="lead">Per essere allineato con il nuovo sistema di Fatturazione Elettronica e percepire i compensi ti
            basterà seguire questi semplici passaggi:</p>
        <br>
        <ul class="lead">
            <li>aggiornare la tua anagrafica e il tuo indirizzo di Posta Elettronica Certificata (PEC) cliccando sul
                pulsante di seguito;
            </li>
            <li>cliccare sul link ricevuto via pec per confermare l'attivazione del servizio.</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button class="btn bg-gradient-info" [routerLink]="'/profile'">Aggiorna i miei dati</button>
    </div>
</ng-template>
<!--<ng-template #popupNewModal let-modal class="bg-gradient-light">-->
<!--    <div class="modal-header">-->
<!--        <h4 class="modal-title" id="modal-basic-title1" translate>{{popupNews?.title ? popupNews?.title : 'News'}}</h4>-->
<!--    </div>-->
<!--    <div class="modal-body" [innerHTML]="popupNews?.description | safeHtml">-->
<!--    </div>-->
<!--    <div class="modal-footer">-->
<!--        <button class="btn bg-gradient-info" (click)="dismissPopupModal()" translate>Close</button>-->
<!--    </div>-->
<!--</ng-template>-->
