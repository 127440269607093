import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class BusinessPlanService {
    constructor(private request: RequestService, public alertService: AlertService) {
    }

    public getBusinessUnits(params?): Observable<any> {
        return this.request.get('v3/bp-business-unit', params);
    }

    getBusinessPlan(year: number|string, mode: string, compare: boolean): Observable<any> {
        return this.request.get(`v3/bp-business-unit/plan/${year}/${mode}/${compare}`);
    }

    getBusinessUnit(id: number|string, year: number|string, mode: string, compare: boolean): Observable<any> {
        return this.request.get(`v3/bp-business-unit/${id}/${year}/${mode}/${compare}`);
    }

    saveBusinessUnit(payload: any) {
        return this.request.post('v3/bp-business-unit', payload);
    }

    updateBusinessUnit(payload: any) {
        return this.request.patch(`v3/bp-business-unit/${payload.id}`, payload);
    }

    deleteBusinessUnit(id: any) {
        return this.request.delete(`v3/bp-business-unit/${id}`);
    }

    saveRowType(payload: any) {
        return this.request.post('v3/bp-rows-type', payload);
    }

    updateRowType(payload: any) {
        return this.request.patch(`v3/bp-rows-type/${payload.id}`, payload);
    }

    deleteRowType(id: any) {
        return this.request.delete(`v3/bp-rows-type/${id}`);
    }

    moveUpRowType(id: any) {
        return this.request.get(`v3/bp-rows-type/move-up/${id}`);
    }

    moveDownRowType(id: any) {
        return this.request.get(`v3/bp-rows-type/move-down/${id}`);
    }

    saveRow(payload: any) {
        return this.request.post('v3/bp-rows', payload);
    }

    updateRow(payload: any) {
        return this.request.patch(`v3/bp-rows/${payload.id}`, payload);
    }

    deleteRow(id: any) {
        return this.request.delete(`v3/bp-rows/${id}`);
    }
}