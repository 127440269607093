import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

const version = 'v3';

@Injectable({
  providedIn: 'root',
})
export class GiftCardService {
  constructor(private request: RequestService, public alertService: AlertService) {}

  public getGiftCardList(params?): Observable<any> {
    return this.request.get(version + '/gift-card/list', params);
  }
}
