import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-master-dashboard-tab',
    templateUrl: './master-dashboard-tab.component.html',
    styleUrls: ['./master-dashboard-tab.component.css']
})
export class MasterDashboardTabComponent implements OnInit {
    @Input() brand: any;
    @Input() background: any;
    @Input() urlOfferte: any;
    @Input() curStartup: any;
    @Input() urlPuntiVendita: any;
    @Input() urlFatturato: any;
    @Input() urlRiepilogo: any;
    @Input() urlTarget: any;
    @Input() urlVendite: any;

    constructor() {
    }

    ngOnInit() {
    }

}
