import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {City} from '@app/models/geo';
import {DealerService} from '@services/dealer.service';

@Component({
    selector: 'app-channel-select',
    templateUrl: './channel-select.component.html',
    styleUrls: ['./channel-select.component.scss'],
})
export class ChannelSelectComponent implements OnInit {
    @Input()
    small = false;


    @Input()
    model: any;
    data: [] = [];
    loading = false;

    @Output() onChange = new EventEmitter();

    constructor(private service: DealerService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.loading = true;
        this.service
            .channels()
            .subscribe((data) => {
                this.data = data;
                this.loading = false;
            });
    }
}
