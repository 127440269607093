import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OlivettiService {
  constructor(private request: RequestService, public alertService: AlertService) {}

  public getOlivettiList(params?): Observable<any> {
    return this.request.get('v3/olivetti/list', params);
  }

  public updateOlivettiRecord(id, body: {}) {
    return this.request.put('v3/olivetti/update/' + id, body);
  }
}
