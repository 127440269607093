import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "@services/data.service";
import { Faq, FaqCategory } from "@models/contract/faq";
import { forkJoin, Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FaqService } from "@app/services/faq.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ConfirmOptionsService } from "@app/services/confirm-options.service";
import {TinyMCE} from '@models/tinyMCE';

@Component({
  selector: "app-faq-list",
  templateUrl: "./faq-list.component.html",
  styleUrls: ["./faq-list.component.scss"],
})
export class FaqListComponent implements OnInit {
  busy: Subscription;
  page = 1;
  pageSize = 9;

  filter: string = null;
  categoryFilter: FaqCategory;

  @ViewChild("categoryFormTemplate") private categoryFormTemplate;
  @ViewChild("faqFormTemplate") private faqFormTemplate;

  selectedCategory: FaqCategory;
  selectedFaq: Faq;

  categoryModalTitle: string;
  faqModalTitle: string;

  categories: FaqCategory[] = [];

  categoryFormModal: NgbModalRef;
  faqFormModal: NgbModalRef;

  busyCategoryFormModal: Subscription;
  busyFaqFormModal: Subscription;

  categoryForm: FormGroup;
  faqForm: FormGroup;
  show: {};

  tinyMceConfig = {
      base_url: '/tinymce',
      suffix: '.min',
      plugins: ['lists', 'advlist', 'autolink', 'link',
          'image', 'charmap', 'print', 'preview', 'anchor',
          'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'paste','code', 'help', 'wordcount'],
      toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
  };

  defaultConfig = new TinyMCE();

  get categoryConfirmDeleteOptions() {
    return new ConfirmOptionsService({
      title: "Elimina questa categoria",
      text: "Sei sicuro di voler rimuovere questo categoria?",
    });
  }

  get faqConfirmDeleteOptions() {
    return new ConfirmOptionsService({
      title: "Elimina questa faq",
      text: "Sei sicuro di voler rimuovere questa faq?",
    });
  }

  get originFaqs(): Faq[] {
    if (this.categories) {
      let faqs = this.categories
        .map((e) => e.faqs)
        .reduce((a, b) => a.concat(b), []);

      return faqs;
    }

    return [];
  }

  get faqs(): Faq[] {
    if (this.categories) {
      let faqs = this.originFaqs;

      if (this.filter) {
        const filterValue = this.filter.toLowerCase();

        faqs = faqs.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(filterValue) !== -1 ||
            item.risposta.toLowerCase().indexOf(filterValue) !== -1
          );
        });
      }

      if (this.categoryFilter) {
        faqs = faqs.filter(
          (item) => item.id_categoria === this.categoryFilter.id
        );
      }

      return faqs;
    }

    return [];
  }

  constructor(
    private service: FaqService,
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.busy = this.service
      .getCategories()
      .subscribe((categories: FaqCategory[]) => {
        this.categories = categories;
        this.show = this.originFaqs
          .reduce(function(map, obj) {
              map[obj.id] = false;
              return map;
          }, {});
      });

    this.categoryForm = this.formBuilder.group({
      id_vendor: [null, []],
      title: [null, [Validators.required]],
    });

    this.faqForm = this.formBuilder.group({
      title: [null, [Validators.required]],
      risposta: [null, [Validators.required]],
      id_categoria: [null, []],
    });
  }

  showFaq(faq: Faq) {
    this.selectedFaq = faq;
    //this.modalService.open(this.faqModal, { size: "lg" });
  }

  showCreateFaqModal(category: FaqCategory) {
    this.selectedFaq = new Faq({ id_categoria: category.id });
    this.faqModalTitle = `Aggiungi documento alla categoria ${category.title}`;
    this.faqFormModal = this.modalService.open(this.faqFormTemplate, {
      size: "lg",
    });
    this.faqFormModal.result.then((res) => {}).catch((res) => {});
  }

  saveFaq(faq: Faq) {
    const formData = new FormData();
    const properties = ["id_categoria", "title", "risposta"];
    properties.forEach((prop) => {
      if (faq && faq[prop]) {
        formData.append(prop, faq[prop]);
      }
    });

    let isNew = faq.id ? false : true;
    let request = isNew
      ? this.service.saveFaq(formData)
      : this.service.updateFaq(faq.id, formData);

    this.busyFaqFormModal = request.subscribe((data: any) => {
      let savedFaq = new Faq(data);
      savedFaq.isNew = isNew;

      this.updateLocalStateWithDocument(savedFaq);
      this.faqFormModal.close();
    });
  }

  deleteFaq(faq: Faq) {
    let request = this.service.deleteFaq(faq.id);

    this.busy = request.subscribe(
      (data: any) => {
        if (data === null) {
          this.deleteLocalStateFaq(faq);
          this.toastr.success("Faq eliminata con successo", "Notifica");
        }
      },
      (err: any) =>
        this.toastr.error(
          "Si è verificato un errore durante l'eliminazione della faq",
          "Errore"
        )
    );
  }

  showUpdateFaqModal(faq: Faq) {
    this.selectedFaq = new Faq(faq);
    this.faqModalTitle = `Aggiorna la faq ${faq.title}`;
    this.faqFormModal = this.modalService.open(this.faqFormTemplate, {
      size: "lg",
    });
    this.faqFormModal.result.then((res) => {}).catch((res) => {});
  }

  deleteLocalStateFaq(faq: Faq) {
    let categoryIndex = this.categories.findIndex(function (o) {
      return o.id === faq.id_categoria;
    });

    if (categoryIndex !== -1) {
      let documentIndex = this.categories[categoryIndex].faqs.findIndex(
        function (o) {
          return o.id === faq.id;
        }
      );

      if (documentIndex !== -1) {
        this.categories[categoryIndex].faq_count--;
        this.categories[categoryIndex].faqs.splice(documentIndex, 1);
      }
    }
  }

  updateLocalStateWithDocument(faq: Faq) {
    for (let i in this.categories) {
      if (this.categories[i].id == faq.id_categoria) {
        if (faq.isNew) {
          let hadNoVideos = this.categories[i].faq_count == 0;
          let hadLoadedVideos =
            this.categories[i].faq_count > 0 &&
            this.categories[i].faqs.length > 0;
          if (hadNoVideos || hadLoadedVideos) {
            this.categories[i].faqs.unshift(faq);
          }
          this.categories[i].faq_count++;
        } else {
          for (let j in this.categories[i].faqs) {
            if (this.categories[i].faqs[j].id == faq.id) {
              this.categories[i].faqs[j] = faq;
              return;
            }
          }
        }
        return;
      }
    }
  }

  showCreateCategoryModal() {
    this.selectedCategory = new FaqCategory();
    this.categoryModalTitle = "Nuova categoria";
    this.categoryFormModal = this.modalService.open(this.categoryFormTemplate, {
      size: "lg",
    });
    this.categoryFormModal.result.then((res) => {}).catch((res) => {});
  }

  showUpdateCategoryModal(category: FaqCategory) {
    this.selectedCategory = new FaqCategory(category);
    this.categoryModalTitle = `Aggiorna la categoria ${category.title}`;
    this.categoryFormModal = this.modalService.open(this.categoryFormTemplate, {
      size: "lg",
    });
    this.categoryFormModal.result.then((res) => {}).catch((res) => {});
  }

  saveCategory(category: FaqCategory) {
    const formData = new FormData();
    const properties = ["title", "id_vendor"];
    properties.forEach((prop) => {
      if (category && category[prop]) {
        formData.append(prop, category[prop]);
      }
    });

    let isNew = category.id ? false : true;
    let request = isNew
      ? this.service.saveCategory(formData)
      : this.service.updateCategory(category.id, formData);

    this.busyCategoryFormModal = request.subscribe(
      (data: any) => {
        let savedCategory = new FaqCategory(data);
        savedCategory.isNew = isNew;

        this.updateLocalStateCategory(savedCategory);
        this.categoryFormModal.close();
        this.toastr.success(
          `Categoria ${isNew ? "creata" : "aggiornata"} con successo`,
          "Notifica"
        );
      },
      (err: any) =>
        this.toastr.error(
          `Si è verificato un errore durante la ${
            isNew ? "creazione" : "aggiornamento"
          } della categoria`,
          "Errore"
        )
    );
  }

  deleteCategory(category: FaqCategory) {
    let request = this.service.deleteCategory(category.id);

    this.busyCategoryFormModal = request.subscribe(
      (data: any) => {
        if (data === null) {
          this.deleteLocalStateCategory(category);
          this.toastr.success("Categoria eliminata con successo", "Notifica");
        }
      },
      (err: any) =>
        this.toastr.error(
          "Si è verificato un errore durante l'eliminazione della categoria",
          "Errore"
        )
    );
  }

  updateLocalStateCategory(category: FaqCategory) {
    for (let i in this.categories) {
      if (this.categories[i].id == category.id) {
        this.categories[i] = category;
        return;
      }
    }
    let newCategory = this.categories.find((c) => {
      return c.id !== category.id;
    });
    if (newCategory || this.categories.length === 0) {
      this.categories.push(category);
    }
  }

  deleteLocalStateCategory(category: FaqCategory) {
    let index = this.categories.findIndex(function (o) {
      return o.id === category.id;
    });
    if (index !== -1) {
      this.categories.splice(index, 1);
    }
  }
}
