<app-spinner [status]="status"></app-spinner>

<ng-container *ngIf="status === 'hidden'">
    <div class="row align-items-center" *ngFor="let row of data; let i = index;">
        <div class="col-xl-2 text-center">
            <img *ngIf="row.image" [src]="row.image" width="42px">
        </div>
        <div class="col">
            <p class="mb-0" style="font-size: 16px;">
                {{row.label}} <span class="text-sm text-secondary" *ngIf="row.desc && row.type === 'volume'">{{row.desc}}</span>
                <span class="float-right" *ngIf="row.type === 'volume'"><strong>{{row.current}}</strong>/{{row.target}}</span>
                <span class="float-right" *ngIf="row.type === 'quantita'"><strong>{{row.current}}</strong></span>
                <span class="float-right" *ngIf="row.type === 'volume-quantita'"><strong>{{row.current}}</strong>/{{row.target}}</span>
            </p>
            <div class="progress border rounded">
                <div class="progress-bar" [ngClass]="row.progress_color" aria-valuenow="100" aria-valuemin="0"
                     aria-valuemax="100" [style.width.%]="row.perc">{{row.perc}}%
                </div>
            </div>
        </div>
        <div class="col-xl-2 text-center">
            <i [ngClass]="row.icon" style="font-size: 24px;"></i>
        </div>
        <div class="col-xl-12">
            <hr class="my-3" *ngIf="i < data.length-1">
        </div>
    </div>
</ng-container>

