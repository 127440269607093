import { Component, OnInit, ViewChild } from "@angular/core";
import { Dealer } from "@models/dealer";
import { Setting } from "@models/setting";
import { Transaction } from "@models/transaction";
import { DealerService } from "@services/dealer.service";
import { SettingsService } from "@services/settings.service";
import { TransactionService } from "@services/transaction.service";
import { ModalComponent } from "@widgets/modal/modal.component";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
  selector: "app-cbi-generator",
  templateUrl: "./cbi-generator.component.html",
  styleUrls: ["./cbi-generator.component.css"],
})
export class CbiGeneratorComponent implements OnInit {
  busy: Subscription;
  dealers: Dealer[];
  cbiDisabled = true;
  shopSetting = new Setting({setting_value: 'yes'});
  filter: string;

  additionalData: { balance: number; transactions: number }[];

  get filteredDealers(): Dealer[] {
    if (this.dealers) {
      let dealers = this.dealers;

      if (this.filter) {
        const filterValue = this.filter.toLowerCase();

        dealers = dealers.filter((item) => {
          return (
            item.company_name.toLowerCase().indexOf(filterValue) !== -1
          );
        });
      }

      return dealers;
    }

    return [];
  }


  get totalVoices() {
    if (this.additionalData) {
      return Object.values(this.additionalData).reduce((a, b) => {
        return a + b.transactions;
      }, 0);
    }
    return 0;
  }

  get balance() {
    if (this.additionalData) {
      return Object.values(this.additionalData).reduce((a, b) => {
        return a + b.balance;
      }, 0);
    }
    return 0;
  }

  constructor(
    private service: DealerService,
    private settingsService: SettingsService,
    private toastr: ToastrService,
    private transactionService: TransactionService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.cbiDisabled = true;
    this.busy = this.service
      .listByUnweldedTransactions()
      .subscribe((data: any) => {
        this.dealers = new Dealer().fromArray(data);

        this.additionalData = [];
        this.dealers.forEach((item) => {
          this.additionalData[item.id] = {
            balance: 0,
            transactions: 0,
          };
        });

        this.refreshSetting();
      });
  }

  refreshSetting() {
    this.busy = this.settingsService.get('masterattivazioni', 'shop_enabled').subscribe((data) => {
      this.shopSetting = new Setting(data);
    })
  }

  toggleAllTransactions($event: any, dealer: Dealer) {
    switch ($event.currentTarget.checked) {
      case true:
        dealer.unweldedTransactions.forEach((element) => {
          element.checked = true;
        });
        break;
      case false:
        dealer.unweldedTransactions.forEach((element) => {
          element.checked = false;
        });
        break;
    }

    this.updateDealerBalance(dealer);
  }

  updateDealerBalance(dealer: Dealer) {
    this.additionalData[dealer.id].balance = dealer.unweldedTransactions
      .filter((a) => a.checked)
      .reduce((a, b) => {
        return a + (b.segno === 0 ? b.valore : -b.valore);
      }, 0);
    this.additionalData[
      dealer.id
    ].transactions = dealer.unweldedTransactions.filter(
      (a) => a.checked
    ).length;
    
    this.cbiDisabled = true;
  }

  previewCbi() {
    let data = this.dealers
      .filter((item) => {
        return this.additionalData[item.id].transactions > 0;
      })
      .map((item) => {
        return {
          id: item.id,
          company_name: item.company_name,
          iban: item.iban,
          balance: this.additionalData[item.id].balance,
          transactions: item.unweldedTransactions.filter((tr) => tr.checked).map(tr => tr.descrizione),
        };
      });

    let negative = data.filter((item) => item.balance <= 0);

    if(negative.length > 0){
      this.toastr.error('Hai selezionato un dealer con bilancio negativo o pari a 0.', 'Errore');
    }
    else {
      this.busy = this.transactionService.previewCbi(data);

      this.cbiDisabled = false;
    }
  }

  saveCbi() {
    let data = this.dealers
      .filter((item) => {
        return this.additionalData[item.id].transactions > 0;
      })
      .map((item) => {
        return {
          id: item.id,
          company_name: item.company_name,
          iban: item.iban,
          balance: this.additionalData[item.id].balance,
          transactions: item.unweldedTransactions.filter((tr) => tr.checked).map(tr => tr.id),
        };
      });


    this.busy = this.transactionService.saveCbi(data).subscribe((data) => {
      if(data.success) {
        this.refresh();
        this.toastr.show("Il CBI è stato generato correttamente.",'Ottimo');
      }
      else {
        this.toastr.error("C'è stato un'errore nella creazione del bonifico.",'Errore');
      }
    });
  }

  toggleShopSetting() {
    this.busy = this.settingsService.toggle('masterattivazioni', 'shop_enabled').subscribe((data) => {
      if(data.success) {
        this.refreshSetting();
      }
      else {
        this.toastr.error("C'è stato nel bloccare il negozio.",'Errore');
      }
    });
  }
}
