import {Component, OnInit, ViewChild} from '@angular/core';
import {EMailCampaignService} from '@services/e-mail-campaign.service';
import {EMailCampaign, EMailCampaignPagination} from '@models/e-mail-campaign';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';

@Component({
    selector: 'app-e-mail-campaign',
    templateUrl: './e-mail-campaign.component.html',
    styleUrls: ['./e-mail-campaign.component.css']
})
export class EMailCampaignComponent implements OnInit {
    campaigns: EMailCampaign[] = [];
    @ViewChild('reportTemplate') private reportTemplate;
    selectedCampaign: EMailCampaign;
    report = {
        isRecipients: false,
        isClicks: false,
        isOpens: false,
        isContent: false
    };
    currentPage = 1;
    totalItems = 0;
    localFormat = 'DD/MM/YYYY';
    filter = {
        date: '',
        type: ''
    };
    selectedDateRange = {
        from: '',
        to: ''
    };
    types = [
        {
            label: 'Regular',
            key: 'regular'
        },
        {
            label: 'Plaintext',
            key: 'plaintext'
        },
        {
            label: 'Absplit',
            key: 'absplit'
        },
        {
            label: 'RSS',
            key: 'rss'
        },
        {
            label: 'Variate',
            key: 'variate'
        }
    ];

    constructor(private eMailCampaignService: EMailCampaignService,
                private spinner: NgxSpinnerService,
                public modalService: NgbModal,
                private dateRangePickerOptions: DaterangepickerConfig) {
    }

    ngOnInit(): void {
        this.loadCampaigns();
    }

    loadCampaigns() {
        const params = {};
        if (this.currentPage) {
            params['page'] = this.currentPage;
        }
        if (this.selectedDateRange.from) {
            params['from'] = this.selectedDateRange.from;
        }
        if (this.selectedDateRange.to) {
            params['to'] = this.selectedDateRange.to;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        this.spinner.show('campaign-table');
        this.eMailCampaignService.getCampaigns(params).subscribe((response: EMailCampaignPagination) => {
            this.campaigns = response.data;
            this.totalItems = response.total_items;
            this.currentPage = response.current_page;
            this.spinner.hide('campaign-table');
        }, err => {
            this.spinner.hide('campaign-table');
        });
    }

    showReportModal(campaign: EMailCampaign, isRecipients = false, isClicks = false, isOpens = false, isContent = false) {
        this.report.isRecipients = isRecipients;
        this.report.isClicks = isClicks;
        this.report.isOpens = isOpens;
        this.report.isContent = isContent;
        this.selectedCampaign = campaign;
        this.modalService.open(this.reportTemplate, {size: 'xl'});
    }

    private getDateRangeString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.selectedDateRange.to = end.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        return start.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + end.format(localDateFormat);
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        const selectedDateRange = this.getDateRangeString(from, to);
        this.filter.date = selectedDateRange;
    }
}
