import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { UserService } from './user.service';
const endpoint = 'submasters';

@Injectable({
    providedIn: 'root',
})
export class SubmasterService {
    constructor(public request: RequestService, public userService: UserService) {}

    list(params = {}) {
        return this.userService.list(endpoint, params);
    }

    getDealer(id, params = {}) {
        return this.userService.getDealer(endpoint, id, params);
    }

    selectOptions(params = {}) {
        return this.request.get('submasters/select-options', params);
    }
}
