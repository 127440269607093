import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {Observable} from 'rxjs';
import {ShopAccess, ShopAccessProvider} from '@app/pages/shop-access/shop-access.type';
import {AlertService} from '@services/alert.service';

@Injectable({
    providedIn: 'root'
})
export class ShopAccessService {
    private endpoint = 'shop-access';

    constructor(private request: RequestService, private alertService: AlertService) {
    }

    getListOfItems(params: object): Observable<ShopAccessProvider> {
        return this.request.get(`${this.endpoint}`, params);
    }

    addSubscription(data) {
        return this.request.post(`${this.endpoint}`, data);
    }

    updateSubscription(id, data) {
        return this.request.put(`${this.endpoint}/${id}`, data);
    }

    public deleteSubscription(id: number) {
        return this.request.delete(`${this.endpoint}/${id}`);
    }

    public getDashboardReport() {
        return this.request.get(`${this.endpoint}/dashboard/report`);
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
