import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';

export class Kpi extends BaseModel {
  id: number;
  title: string;
  description: string;
  image: string;
  slug: string;
  category: string;
  created_at: string;
  created_by: string;
  update_at: string;
  update_by: string;
  is_deleted: number;
  from: string;
  to: string;
  revenue: any;
  revenue_type: string;

  fromArray(result: []): Kpi[] {
    const array: Kpi[] = [];
    for (const item of result) {
      array.push(new Kpi(item));
    }
    return array;
  }
}

export class KpiProvider extends BaseModel {
  data: Kpi[];
  _meta: MetaPagination;
  _links: Array<any>;
}

export class KpiNotes extends BaseModel {
  id: number;
  month: string;
  year: string;
  role: string;
  description: string;
  id_operatori: string;
  category: string;
  created_at: string;
  created_by: string;
  update_at: string;
  update_by: string;
  is_deleted: number;

  fromArray(result: []): KpiNotes[] {
    const array: KpiNotes[] = [];
    for (const item of result) {
      array.push(new KpiNotes(item));
    }
    return array;
  }
}

export class KpiNotesProvider extends BaseModel {
  data: KpiNotes[];
  _meta: MetaPagination;
  _links: Array<any>;
}

// export class KpiAnalisi extends BaseModel {
//   achieved_revenue: number;
//   kpis: [{ category: string; items: KpiItem[] }];
//   paf_revenue: number;
//   paf_revenue_perc: string;
//   total_revenue: number;

//   fromArray(result: []): KpiAnalisi[] {
//     const array: KpiAnalisi[] = [];
//     for (const item of result) {
//       array.push(new KpiAnalisi(item));
//     }
//     return array;
//   }
// }

// export class KpiAnalisiProvider extends BaseModel {
//   data: KpiAnalisi[];
//   _meta: MetaPagination;
//   _links: Array<any>;
// }

// export interface KpiItem {
//   achieved: string;
//   achieved_paf: number;
//   category: string;
//   created_at: string;
//   created_by: string;
//   dealer_id: string;
//   from: number;
//   id: string;
//   is_deleted: string;
//   month: string;
//   revenue: string;
//   revenue_type: string;
//   target: string;
//   to: null;
//   type: {
//     category: string;
//     created_at: string;
//     created_by: string;
//     description: string;
//     id: string;
//     image: string;
//     is_deleted: string;
//     notes: string;
//     slug: string;
//     title: string;
//     updated_at: string;
//     updated_by: string;
//   };
//   type_id: string;
//   updated_at: string;
//   updated_by: string | null;
//   value: string;
//   value_paf: number;
//   value_paf_perc: string;
//   year: string;
//   description: string;
//   image: string;
//   notes: string;
//   slug: string;
//   title: string;
// }
