import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dealer } from '@app/models/dealer';
import { SubmasterService } from '@app/services/submaster.service';

@Component({
    selector: 'app-submaster-select',
    templateUrl: './submaster-select.component.html',
    styleUrls: ['./submaster-select.component.css'],
})
export class SubMasterSelectComponent implements OnInit {
    @Input()
    small = false;
    @Input()
    disabled = false;

    @Input()
    submaster: any;

    @Input()
    vendors: [];

    data: Dealer[] = [];

    @Output() onChange = new EventEmitter();

    loading = false;

    constructor(private service: SubmasterService) {}

    ngOnInit(): void {
        if (this.submaster) {
            this.submaster = this.submaster.toString();
        }

        this.loading = true;
        this.service.selectOptions().subscribe((data) => {
            this.data = new Dealer().fromArray(data);
            this.loading = false;
        });
    }

    public reset() {
        this.submaster = null;
    }
}
