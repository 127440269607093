<div class="modal-body">
  <div class="col-md-12 d-flex">
    <div class="col-md-6">
      <button class="btn-primary btn-block btn" (click)="confirmUpdate()">Si</button>
    </div>
    <div class="col-md-6">
      <button class="btn-primary btn-block btn" (click)="dismissModal()">No</button>
    </div>
  </div>
</div>
