import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-shop-sales',
    templateUrl: './shop-sales.component.html',
    styleUrls: ['./shop-sales.component.css']
})
export class ShopSalesComponent implements OnInit {


    urlClassificaItalia: string;
    urlClassificaAgenti: string;
    urlClassificaRegioni: string;
    type = 'smartphones';
    operatore;

    constructor() {
    }

    ngOnInit(): void {
        // this.urlClassificaItalia = `shop-orders/sales`;
        // this.urlClassificaAgenti = `shop-orders/ranking-manager`;
        // this.urlClassificaRegioni = `shop-orders/ranking-region`;
    }

    updateUrls(operatore) {
        this.operatore = operatore;
        this.urlClassificaItalia = `shop-orders/sales?vendor=${this.operatore}&type=${this.type}`;
        this.urlClassificaAgenti = `shop-orders/ranking-manager?vendor=${this.operatore}&type=${this.type}`;
        this.urlClassificaRegioni = `shop-orders/ranking-region?vendor=${this.operatore}&type=${this.type}`;
    }

}
