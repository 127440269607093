<div class="card">
    <div class="card-header">
        <div class="card-title">Business Units</div>
        <div class="card-tools">
            <button class="btn btn-success btn-sm" (click)="showModal()">Nuova Business Unit</button>
        </div>
    </div>
    <div class="card-body p-0">
        <app-spinner *ngIf="this.loading" status='loading'></app-spinner>
        <table class="table" *ngIf="!this.loading">
            <thead>
            <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Descrizione</th>
                <th></th>
            </tr>
            </thead>
            <tbody *ngIf="this.provider">
            <tr *ngFor="let row of this.provider.data">
                <td>{{ row.id }}</td>
                <td><a [routerLink]="['/business-plan/business-units/edit/' + row.id]" [queryParams]="{'mode': 'actual'}">{{ row.name }}</a></td>
                <td>{{ row.description }}</td>
                <td class="text-right">
                    <a (click)="edit(row)" class="link"><i class="fas fa-pencil-alt text-primary mr-2"></i></a>
                    <a [swal]="confirmDeleteOptions"
                       (confirm)="delete(row)" class="link"><i class="fa fa-times text-danger"></i></a>
                </td>
            </tr>
            </tbody>
            <tbody *ngIf="!this.provider || this.provider.data.length === 0">
            <tr>
                <td colspan="4" class="text-center">Non ci sono dati da visualizzare</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
        <ngb-pagination
            *ngIf="this.provider"
            [(page)]="this.provider._meta.currentPage"
            [pageSize]="this.provider._meta.perPage"
            [boundaryLinks]="true" size="sm"
            [collectionSize]="this.provider._meta.pageCount*this.provider._meta.perPage"
            [maxSize]="5"
            (pageChange)="getBusinessUnits($event)"
            [ellipses]="false"
        >
            <ng-template ngbPaginationFirst>««</ng-template>
            <ng-template ngbPaginationPrevious>«</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            <ng-template ngbPaginationNext>»</ng-template>
            <ng-template ngbPaginationLast>»»</ng-template>
        </ngb-pagination>
    </div>
</div>

<ng-template #formTemplate let-modal>
    <div class="modal-header">
        <h4>
            <span *ngIf="form.get('id').value">Aggiorna </span>
            <span *ngIf="!form.get('id').value">Crea </span>
            Business Unit
        </h4>
        <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form" (ngSubmit)="save($event)">
            <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" formControlName="name" placeholder="" />
            </div>
            <div class="form-group">
                <label>Descrizione</label>
                <textarea rows="3" class="form-control" formControlName="description"></textarea>
            </div>

            <button type="submit" class="btn btn-primary" [disabled]="!this.form.valid || this.loading">
                <span *ngIf="form.get('id').value">Aggiorna</span>
                <span *ngIf="!form.get('id').value">Crea</span>
            </button>
        </form>
    </div>
</ng-template>