import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupService } from '@services/group.service';
import { Group, GroupProvider } from '@models/group';
import { MetaPagination } from '@models/meta-pagination';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '@services/request.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { fromArray } from 'rxjs/internal/observable/fromArray';
import * as XLSX from 'xlsx';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css'],
})
export class GroupComponent implements OnInit {
  groups: Group[] = [];
  groupPagination: MetaPagination;
  selectedFile = null;
  @ViewChild('groupFormTemplate') private groupFormTemplate;
  @ViewChild('csvTemplate') private csvTemplate;

  selectedData: Group;
  deleteGroupOptions: ConfirmOptionsService;
  localFormat = 'dd/MM/yyyy HH:MM';

  filter = {
    dealer: '',
    type: '',
    name: '',
    date: '',
  };

  isEdit = true;
  itemTypes = [];

  constructor(
    public modalService: NgbModal,
    public request: RequestService,
    private spinner: NgxSpinnerService,
    private groupService: GroupService,
    private dateRangePickerOptions: DaterangepickerConfig,
    public alertService: AlertService,
  ) {
    this.deleteGroupOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
    });
  }

  ngOnInit(): void {
    this.loadTypes();
    this.loadGroup();
  }

  loadTypes() {
    this.groupService.getTypes().subscribe((res) => {
      this.itemTypes = res;
    });
  }

  loadGroup() {
    this.spinner.show('table-documents');
    const params = {};
    if (this.groupPagination?.currentPage) {
      params['page'] = this.groupPagination?.currentPage;
    }
    if (this.filter.dealer) {
      params['dealer_id'] = this.filter.dealer;
    }
    if (this.filter.type) {
      // use filter.type
      params['type'] = this.filter.type;
    }
    if (this.filter.name) {
      params['name'] = this.filter.name;
    }

    this.groupService.getGroup(params).subscribe(
      (response: GroupProvider) => {
        this.groups = response.data;
        this.groupPagination = response._meta;
        this.spinner.hide('table-documents');
        this.loadTypes();
      },
      (err) => {
        this.spinner.hide('table-documents');
      },
    );
  }

  deleteGroup(id: number) {
    this.groupService.deleteGroup(id).subscribe(
      () => {
        const index = this.groups.findIndex((dealer) => dealer.id === id);
        if (index > -1) {
          this.loadGroup();
          this.groupService.successAlert('Deleted Group!');
        }
      },
      (err) => {
        this.groupService.errorAlert(err.message);
      },
    );
    this.loadGroup();
  }

  showModal(data?: Group) {
    this.selectedData = data;
    this.modalService.open(this.groupFormTemplate, { size: data ? 'md' : 'md' });
  }

  showCsvModal() {
    this.modalService.open(this.csvTemplate, { size: 'md' });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  handleSelectedFile(file: any) {
    if (file && file[0]) {
      const document = file[0];
      const payload = new FormData();
      payload.append('file', document);
      this.selectedFile = payload;
    }
  }

  uploadCsv() {
    this.spinner.show('csvUpload');
    if (this.selectedFile) {
      this.groupService.uploadCsv(this.selectedFile).subscribe(
        (res) => {
          this.spinner.hide('csvUpload');
          this.modalService.dismissAll();
          this.alertService.show('', 'File importato correttamente!', {
            classname: 'bg-success text-light',
            delay: 3000,
          });
        },
        (err) => {
          console.log('Errore upload: ', err);
          this.spinner.hide('csvUpload');
          this.modalService.dismissAll();
        },
      );
    }
  }

  downloadCsvTemplate() {
    const data = [
      { id_gruppo: '', id_dealer: '' },
    ];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: ['id_gruppo', 'id_dealer'] });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const w: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([w], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'dealer_groups.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

}
