import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable({
  providedIn: "root",
})
export class FaqService {
  ENDPOINT = "faqs";

  constructor(public request: RequestService) {}

  saveFaq(data) {
    return this.request.post(`${this.ENDPOINT}/question`, data);
  }

  updateFaq(id, data) {
    return this.request.put(`${this.ENDPOINT}/question/${id}`, data);
  }

  deleteFaq(id) {
    return this.request.delete(`${this.ENDPOINT}/question/${id}`);
  }

  getCategories() {
    return this.request.get(
      `${this.ENDPOINT}/category?expand=faqs`
    );
  }

  saveCategory(data) {
    return this.request.post(
      `${this.ENDPOINT}/category?expand=faqs`,
      data
    );
  }

  updateCategory(id, data) {
    return this.request.post(
      `${this.ENDPOINT}/category/${id}?expand=faqs`,
      data
    );
  }

  deleteCategory(id) {
    return this.request.delete(`${this.ENDPOINT}/category/${id}`);
  }
}
