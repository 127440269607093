import { Component, OnInit, ViewChild } from '@angular/core';
import { KdAssicura, KdAssicuraProvider, KdAssicuraType } from '@app/models/kd-assicura';
import { MetaPagination } from '@app/models/meta-pagination';
import { KdAssicuraService } from '@app/services/kd-assicura.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-kd-assicura',
    templateUrl: './kd-assicura.component.html',
})
export class KdAssicuraComponent implements OnInit {
    kdAssicuraStatuses = KdAssicura.kdAssicuraStatuses;
    list: KdAssicura[] = [];
    ordersPagination: MetaPagination = new MetaPagination();
    isEdit = true;
    kdAssicuraTypes = KdAssicura.kdAssicuraTypes;

    filter = {
        id: '',
        dealer_id: '',
        status: '',
        created_at: '',
    };

    apiDate = {
        from: '',
        to: '',
    };

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        },
    };

    @ViewChild('popContent') private popContent;
    popupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };

    constructor(
        private spinner: NgxSpinnerService,
        private service: KdAssicuraService,
        private dateRangePickerOptions: DaterangepickerConfig,
    ) {}

    ngOnInit(): void {
        this.loadList();
    }

    loadList() {
        this.spinner.show('table-orders');

        const params = {};

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.created_at) {
            params['from'] = this.apiDate.from;
            params['to'] = this.apiDate.to;
        }

        this.service.getList(params).subscribe(
            (res) => {
                this.list = new KdAssicura().fromArray(res.data);
                this.ordersPagination = res._meta;
                this.spinner.hide('table-orders');
            },
            (err) => {
                console.log('Errore nel caricamento della lista: ', err);
                this.spinner.hide('table-orders');
            },
        );
    }

    generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.created_at =
            startDate.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            endDate.format(localDateFormat);
    }

    openPopover(popOver: any, orderId, item, key, selectItems = []) {
        this.popupConfig.inputValue = item;
        this.popupConfig.id = orderId;
        this.popupConfig.key = key;
        this.popupConfig.popover = popOver;
        this.popupConfig.isSelect = selectItems?.length > 0;
        this.popupConfig.items = selectItems;
        popOver.open();
    }

    save(value) {
        const body = {};
        body['id'] = this.popupConfig.id;
        body[this.popupConfig['key']] = value;
        this.spinner.show('popupSpinner');
        return this.changeOrder(body);
    }

    changeOrder(body) {
        this.service.updateOrder(body).subscribe(
            (response) => {
                this.loadList();
                this.spinner.hide('popupSpinner');
            },
            (err) => {
                console.log("Errore durante l'upload: ", err);
                this.loadList();
                this.spinner.hide('popupSpinner');
            },
        );
    }

    getStatus(key: string): string {
        const status = this.kdAssicuraStatuses.find((status) => status.key === key);
        return status.label;
    }

    getIcon(key: string): string {
        const type: KdAssicuraType = this.kdAssicuraTypes.find((type: KdAssicuraType) => type.key === key);
        return type.icon;
    }

    getType(key: string): string {
        const type: KdAssicuraType = this.kdAssicuraTypes.find((type: KdAssicuraType) => type.key === key);
        return type.value;
    }

    exportList() {
        this.spinner.show('table-orders');

        const params = {};

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.created_at) {
            params['from'] = this.apiDate.from;
            params['to'] = this.apiDate.to;
        }

        this.service.getList(params).subscribe({
            next: (response: KdAssicuraProvider) => {
                let rows = [];

                response.data.forEach((request: KdAssicura) => {
                    const typeData = request.data;

                    rows.push({
                        ID: request.id,
                        'Dealer ID': request.dealer_id,
                        Tipo: this.getType(typeData?.type),
                        Comune: typeData?.comune ? typeData.comune : '-',
                        Provincia: typeData?.provincia ? typeData.provincia : '-',
                        Cap: typeData?.cap ? typeData.cap : '-',
                        Targa: typeData?.registration_plate ? typeData.registration_plate.toUpperCase() : '-',
                        'Data di Nascita': typeData?.birth_date ? typeData.birth_date : '-',
                        Professione: typeData?.profession ? typeData.profession : '-',
                        Nazione: typeData?.nation ? typeData.nation : '-',
                        Proprietario: typeData?.owner ? typeData.owner : '-',
                        Utilizzo: typeData?.usage ? typeData.usage : '-',
                        Nome: typeData?.name ? typeData.name : '-',
                        Microchip: typeData?.microchip ? typeData.microchip : '-',
                        Stato: request.status,
                        'Data Creazione': request.created_at,
                    });
                });
                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                XLSX.writeFile(wb, 'kd_assicura_export.xlsx');
                this.spinner.hide('table-orders');
            },
            error: (_) => {
                this.spinner.hide('table-orders');
            },
        });
    }
}
