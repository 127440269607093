<div class="modal-body">
    <form (ngSubmit)="save()" [formGroup]="cdcImport">
        <div class="col-md-12 col-sm-12 mt-2 mb-0">
            <div class="form-group mb-0">
                <label class="lead">Distinta pagamenti</label>
                <div class="custom-file">
                    <input
                            (change)="onFileSelected($event)"
                            class="custom-file-input cursor-hand"
                            formControlName="fileInput"
                            id="validatedCustomFile"
                            type="file"
                    />
                    <label *ngIf="file" class="custom-file-label text-green">{{ file.name }}</label>
                    <label
                            *ngIf="!file"
                            class="custom-file-label"
                            for="validatedCustomFile"
                    >Scegli il file</label
                    >
                </div>
                <val-errors controlName="file">
                    <ng-template valError="required">
                        Il file è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
            <button [disabled]="loadingExport === true" class="btn btn-info btn-block mt-3 mb-4" type="submit">
                <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
                <span *ngIf="showSpinner" class="spinner-border ml-2 spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
        </div>
    </form>
</div>