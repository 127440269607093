import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
    DealerSuretyCreditPagination,
    SuretyCredit,
    SuretyCreditPagination,
    SuretyCreditReport,
    SuretyOrdersListPagination,
} from '@models/surety';
import { HttpParams } from '@angular/common/http';
import { SuretyRequest, SuretyRequestsPagination } from '@app/models/surety-request';

const credits = 'surety-credit';
const requests = 'surety-requests';

@Injectable({
    providedIn: 'root',
})
export class SuretyService {
    constructor(private request: RequestService, public alertService: AlertService) {}

    public getCreditReport(params?): Observable<SuretyCreditReport> {
        return this.request.get(credits + '/report', params).pipe(
            map((response: any) => {
                return new SuretyCreditReport(response);
            }),
        );
    }

    public getCredits(params?): Observable<SuretyCreditPagination> {
        return this.request.get(credits + '/transaction', params).pipe(
            map((response: any) => {
                return new SuretyCreditPagination(response);
            }),
        );
    }

    public getDealersWithCredit(params?): Observable<DealerSuretyCreditPagination> {
        return this.request.get(credits, params).pipe(
            map((response: any) => {
                return new DealerSuretyCreditPagination(response);
            }),
        );
    }

    public postDealerCredits(body: SuretyCredit): Observable<SuretyCredit> {
        return this.request.post(credits, body).pipe(
            map((response: any) => {
                return new SuretyCredit(response);
            }),
        );
    }

    public putDealerCredits(id, body: SuretyCredit): Observable<SuretyCredit> {
        return this.request.put(credits + '/' + id, body).pipe(
            map((response: any) => {
                return new SuretyCredit(response);
            }),
        );
    }

    public deleteCredit(id: number) {
        return this.request.delete(credits + '/' + id);
    }

    public successAlert(text) {
        this.alertService.show('', 'Successfully ' + text, { classname: 'bg-success text-light', delay: 2000 });
    }

    public errorAlert(message?) {
        this.alertService.show('An error occurred!', message ? message : 'Try again!', {
            classname: 'bg-danger text-light',
            delay: 2000,
        });
    }

    public getSuretyOrders(params?: any): Observable<SuretyOrdersListPagination> {
        let httpParams = new HttpParams();
        if (params) {
            Object.keys(params).forEach((key) => {
                if (params[key] !== null && params[key] !== undefined) {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }

        return this.request.get(credits + '/surety-orders', { params: httpParams });
    }

    public createSuretyRequest(body: FormData): Observable<SuretyRequest> {
        return this.request.post(requests, body).pipe(
            map((response: any) => {
                return new SuretyRequest(response);
            }),
        );
    }

    public updateSuretyRequest(id, body: SuretyRequest): Observable<SuretyRequest> {
        return this.request.put(requests + '/' + id, body).pipe(
            map((response: any) => {
                return new SuretyRequest(response);
            }),
        );
    }

    public getSuretyRequests(params?): Observable<SuretyRequestsPagination> {
        return this.request.get(requests + '/list', params).pipe(
            map((response: any) => {
                return new SuretyRequestsPagination(response);
            }),
        );
    }

    public deleteSuretyRequest(id: number) {
        return this.request.delete(requests + '/' + id);
    }

    public getSuretyRequestContract(id: number): any {
        return this.request.get(requests + `/get-contract?id=${id}`);
    }
}
