import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ShopOrderService} from '@services/shop-order.service';
import {ShopOrderList, ShopOrderListPagination} from '@models/shop-order';
import {Moment} from 'moment';
import {CountdownComponent, CountdownConfig} from 'ngx-countdown';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-shop-order-history',
    templateUrl: './shop-order-history.component.html',
    styleUrls: ['./shop-order-history.component.css']
})
export class ShopOrderHistoryComponent implements OnInit {
    currency = '€';
    localTimeFormat = 'DD/MM/YYYY HH:mm';
    ordersPagination: MetaPagination;
    orders: ShopOrderList[] = [];
    filter = {
        order_id: null,
        dealer_id: null,
        agent_id: null,
        date: '',
        status: null,
        payment: '',
        product_type: '',
        product_name: '',
        note: '',
        note_op: '',
        ka_kena: null,
        ka_tim: null,
    };
    apiDate = {
        from: '',
        to: ''
    };
    statuses = ShopOrderService.statuses;
    payments = ShopOrderService.payments;
    product_types = ShopOrderService.product_types;
    plafondStatuses = [
        {
            label: 'Gestito',
            key: 1
        },
        {
            label: 'Da gestire',
            key: 0
        }
    ];
    selectedOrder: ShopOrderList;
    @ViewChild('noteFormTemplate') private noteFormTemplate;
    @ViewChild('popContent') private popContent;
    popupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null
    };
    selectedContentId = null;
    @ViewChild('countdown') counter: CountdownComponent;
    countdownConfig: CountdownConfig = {
        leftTime: 60,
        format: 'mm:ss'
    };
    selectedStatus = null;
    lastSectional= null;

    loadingSectionals = false;

    constructor(public modalService: NgbModal,
                private spinner: NgxSpinnerService,
                private toast: ToastrService,
                private shopOrderService: ShopOrderService,
                private dateRangePickerOptions: DaterangepickerConfig) {
    }

    ngOnInit(): void {
        this.filter.status = -2;
        this.loadOrders();
        this.getLastSectional();
    }

    refreshOrders(event) {
        if (event && event?.action === 'done') {
            this.restartCounter();
            this.loadOrders(true);
        }
    }

    restartCounter() {
        this.counter.restart();
    }

    loadOrders(hideSpinner = false, isEdit = false) {
        const params = {};
        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }
        if (this.filter.order_id) {
            params['id'] = this.filter.order_id;
        }
        if (this.filter.dealer_id) {
            params['id_dealer'] = this.filter.dealer_id;
        }
        if (this.filter.agent_id) {
            params['id_agente'] = this.filter.agent_id;
        }
        if (this.filter.ka_kena) {
            params['ka_kena'] = this.filter.ka_kena;
        }
        if (this.filter.ka_tim) {
            params['ka_tim'] = this.filter.ka_tim;
        }
        if (this.apiDate?.from && this.apiDate?.to) {
            params['from'] = this.apiDate?.from;
            params['to'] = this.apiDate?.to;
        }
        if (this.filter?.status) {
            if (this.filter?.status === -1) {
                params['handled'] = 1;
            } else if (this.filter?.status === -2) {
                params['per-page'] = 50;
                params['handled'] = 0;
            } else {
                params['stato'] = this.filter.status;
            }

        }
        if (this.filter?.payment !== '') {
            params['pagamento'] = this.filter.payment;
        }
        if (this.filter?.note) {
            params['note'] = this.filter.note;
        }
        if (this.filter?.note_op) {
            params['note_op'] = this.filter.note_op;
        }
        if (this.filter?.product_name) {
            params['product_name'] = this.filter.product_name;
        }
        if (this.filter?.product_type !== '') {
            params['product_type'] = this.filter.product_type;
        }
        if (!hideSpinner) {
            this.spinner.show('table-orders');
        }
        if (isEdit) {
            this.spinner.show('detail');
        }
        params['expand'] = 'detail,packages,items,taxes';
        this.shopOrderService.getShopOrderList(params).subscribe((response: ShopOrderListPagination) => {
            this.ordersPagination = response._meta;
            this.orders = response.data;
            this.selectedStatus = this.filter?.status;
            this.spinner.hide('table-orders');
            if (hideSpinner && this.selectedContentId) {
                setTimeout(() => {
                    const openContent = document.getElementById(this.selectedContentId);
                    if (openContent) {
                        openContent.classList.add('show');
                        document.getElementById(this.selectedOrder?.id.toString()).setAttribute('aria-expanded', 'true');
                    }
                });
            }
            if (isEdit) {
                this.spinner.hide('detail');
            }
        }, err => {
            this.spinner.hide('table-orders');
            this.spinner.hide('detail');
        });
    }


    resetFilter() {
        this.filter = {
            order_id: null,
            dealer_id: null,
            agent_id: null,
            ka_kena: null,
            ka_tim: null,
            date: '',
            status: '',
            payment: '',
            product_name: '',
            product_type: '',
            note: '',
            note_op: ''
        };
        this.apiDate = {
            from: '',
            to: ''
        };
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.date = startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }

    save(value) {
        const body = {};
        body[this.popupConfig['key']] = value;
        this.spinner.show('popupSpinner');
        return this.changeOrder(this.popupConfig.id, body);
    }

    changeOrder(orderId, body) {
        this.shopOrderService.putShopOrder(orderId, body).subscribe((response) => {
            this.loadOrders(true);
            this.spinner.hide('popupSpinner');
        }, err => {
            this.loadOrders(true);
            this.modalService.dismissAll();
            this.spinner.hide('popupSpinner');
        });
    }

    openPopover(popOver: any, orderId, item, key, selectItems = []) {
        this.popupConfig.inputValue = item;
        this.popupConfig.id = orderId;
        this.popupConfig.key = key;
        this.popupConfig.popover = popOver;
        this.popupConfig.isSelect = selectItems?.length > 0;
        this.popupConfig.items = selectItems;
        popOver.open();
    }

    loadDetails(order, id) {
        if (this.selectedContentId !== id) {
            const openContent = document.getElementById(this.selectedContentId);
            if (openContent) {
                openContent.classList.replace('show', 'hide');
                document.getElementById(this.selectedOrder?.id.toString()).setAttribute('aria-expanded', 'false');
            }
            const content = document.getElementById(id);
            this.selectedContentId = id;
            if (!content.classList.contains('show')) {
                this.selectedOrder = order;
            }
        } else {
            this.selectedContentId = null;
        }
    }

    convalidOrder(order) {
        const body = {
            convalida: order.convalid ? 1 : 0
        };
        return this.changeOrder(order.id, body);
    }

    getLastSectional() {
        this.lastSectional = null;

        this.shopOrderService.getLastSectional()
            .subscribe((response) => {
                console.log(response);
                this.lastSectional = response;
            }, err => {
                this.lastSectional = null;
            });
    }

    syncSectional() {
        this.loadingSectionals = true;
        // this.lastSectional = null;
        this.shopOrderService.syncSectional(this.lastSectional)
            .subscribe((response) => {
                this.loadingSectionals = false;
                this.lastSectional = response;
                this.toast.success(`L'ultimo numero è ${response}`, 'Sincronizzazione completata');
            }, err => {
                this.loadingSectionals = false;
                this.getLastSectional();
                this.toast.error('Errore durante la sincronizzazione', 'Errore');
            });
    }
}