import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaPagination } from '@app/models/meta-pagination';
import { ShopAffiliationList } from '@app/models/shop-affiliation-list';
import { User } from '@app/models/user';
import { AuthenticationService } from '@app/services/authentication.service';
import { RequestService } from '@app/services/request.service';
import { ShopAffiliantionService } from '@app/services/shop-affiliation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-affiliation-list',
  templateUrl: './shop-affiliation-list.component.html',
  styleUrls: ['./shop-affiliation-list.component.scss'],
})
export class ShopAffiliationListComponent implements OnInit {
  @ViewChild('shopListUploadTemplate') private shopListUploadTemplate;
  ordersPagination: MetaPagination;
  isDealer = false;
  loadingFile = false;
  isEdit = true;
  shopListUpload: FormGroup;
  file: File | null = null;
  shopList: ShopAffiliationList[] = [];
  deleteSubscriptionOptions: ConfirmOptionsService;
  shopSelectItem: any;
  filter = {
    list: '',
    agent_id: '',
  };

  isEditing = false;
  selectedItemId: number | null = null;
  selectedListId: number | null = null;

  classificaAm: any;
  classificaKa: any;

  showKeyAccountSelect = false;
  sortColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  selectedListaItem: any;
  listIdExport: any;
  listExported: any;

  constructor(
    public request: RequestService,
    private authenticationService: AuthenticationService,
    public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    private shopAffiliationService: ShopAffiliantionService,
    public route: ActivatedRoute,
    private router: Router,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });

    this.deleteSubscriptionOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
    });

  }

  ngOnInit(): void {
    this.shopListUpload = this.formBuilder.group({
      fileInput: [null, [Validators.required]],
      name_list: ['', [Validators.required]],
      description: [''],
    });

    this.loadList();
    this.shopSelect();
    this.loadClassificaAm();
    this.loadClassificaKa();

  }

  loadList() {
    this.spinner.show('table-orders');
    const params = {};

    if (this.filter.list) {
      params['filterList'] = this.filter.list;
    }

    if (this.filter.agent_id) {
      params['filterAgent'] = this.filter.agent_id;
    }

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    this.shopAffiliationService.getShopAffiliationList(params).subscribe(
      (response: any) => {
        this.shopList = new ShopAffiliationList().fromArray(response.data);
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  loadClassificaAm() {
    this.spinner.show('table-orders');
    const params = {};

    if (this.filter.list) {
      params['filterList'] = this.filter.list;
    }

    this.shopAffiliationService.getClassificaAm(params).subscribe(
      (response: any) => {
        this.classificaAm = response;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  loadClassificaKa() {
    this.spinner.show('table-orders');
    const params = {};

    if (this.filter.list) {
      params['filterList'] = this.filter.list;
    }

    this.shopAffiliationService.getClassificaKa(params).subscribe(
      (response: any) => {
        this.classificaKa = response;
        // this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  filterList() {
    if (this.selectedListaItem !== null) {
      this.filter.list = this.selectedListaItem;
    } else {
      this.filter.list = null;
    }
    this.loadList();
    this.loadClassificaAm();
    this.loadClassificaKa();
  }

  shopSelect() {
    this.spinner.show('table-orders');

    this.shopAffiliationService.getShopSelect().subscribe(
      (response: any) => {
        this.shopSelectItem = response;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  toggleKeyAccountSelect(event: any) {
    this.showKeyAccountSelect = event.nextId === 'ngb-nav-2';
  }

  // onAgentChange(agentId: any): void {
  //     this.filter.agent_id = agentId;
  //     console.log('agentId', agentId);
  //     this.loadList();
  // }

  sortClassifica(column: string, data: any[]) {
    const isAscending = this.sortColumn === column && this.sortDirection === 'asc';

    this.sortDirection = isAscending ? 'desc' : 'asc';
    this.sortColumn = column;

    data.sort((a, b) => {
      const valueA = isAscending ? a[column] : b[column];
      const valueB = isAscending ? b[column] : a[column];

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
  }

  sortClassificaAm(column: string) {
    this.sortClassifica(column, this.classificaAm.result);
  }

  sortClassificaKa(column: string) {
    this.sortClassifica(column, this.classificaKa.result);
  }


  toggleEdit(itemId: number) {
    this.selectedItemId = itemId;  }

  saveName(list: ShopAffiliationList) {
    const body = { name_list: list.name_list };
    this.spinner.show('popupSpinner');
    this.changeListName(list.id, body);
  }

  changeListName(orderId, body) {
    this.shopAffiliationService.updateListName(orderId, body).subscribe(
      (response) => {
        this.selectedItemId = null;
        this.loadList();
        this.spinner.hide('popupSpinner');
      },
      (err) => {
        this.selectedItemId = null;
        this.loadList();
        this.modalService.dismissAll();
        this.spinner.hide('popupSpinner');
      },
    );
  }


  // showModal(list?: ShopAffiliationList) {
  //   this.modalService.open(this.shopListUploadTemplate, { size: 'md' });
  // }

  showModal(list?: ShopAffiliationList) {
    if (list && list.id) {
      console.log('Modal aperta per l\'aggiornamento, ID lista:', list.id);
      this.selectedListId = list.id;
      this.isEditing = true;
    } else {
      this.selectedListId = null;
      this.isEditing = false;
    }

    this.modalService.open(this.shopListUploadTemplate, { size: 'md' });
  }


  dismissModal() {
    this.modalService.dismissAll();
    this.file = null;
    this.shopListUpload.value.name_list = null;
    this.shopListUpload.value.description = null;
    this.selectedListId = null;
    this.loadList();
    this.loadingFile = false;
  }

  onFileSelected(event: any) {
    if (event) {
      this.file = event.target.files[0];
    }
  }

  save() {
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('name_list', this.shopListUpload.value.name_list);
      formData.append('description', this.shopListUpload.value.description);

      if (this.selectedListId) {
        formData.append('id', this.selectedListId.toString());
      }

      this.loadingFile = true;
      this.shopAffiliationService.uploadFile(formData).subscribe(
        (response: ShopAffiliationList) => {
          this.shopAffiliationService.successAlert('importato correttamente');
          this.dismissModal();

        },
        (err) => {
          this.shopAffiliationService.errorAlert(err);
          this.dismissModal();
        },
      );
    }
  }

  watchDetail(list) {
    const listId = list.id;
    this.router.navigate(['/shop-list-detail', listId]);
  }


  deleteList(id: number) {
    this.shopAffiliationService.deleteListSubscription(id).subscribe(
      () => {
        const index = this.shopList.findIndex((dealer) => dealer.id === id);
        if (index > -1) {
          this.shopAffiliationService.successAlert('Deleted Service!');
          this.loadList();
        }
      },
      (err) => {
        this.shopAffiliationService.errorAlert(err.message);
      },
    );
  }

  exportReportList(listId?: number) {
    const params = {
      'per-page': 0,
    };

    if (listId) {
      params['filterList'] = listId;

      this.shopAffiliationService.getClassificaKa(params).subscribe(
        (response: any) => {
          this.processExportResponse(response);
        },
        (err) => {
          this.shopAffiliationService.errorAlert(err);
        }
      );
    } else {

      this.shopAffiliationService.exportMassiveReport(params).subscribe(
        (response: any) => {
          this.processExportResponse(response);
        },
        (err) => {
          this.shopAffiliationService.errorAlert(err);
        }
      );
    }
  }


  private processExportResponse(response: any) {

    const rows = response.result;
    const output = rows.map((row, index) => {

      return {
        'Nr.': index + 1 ,
        'Key Account': row.keyaccount,
        'Area Manager': row.area_manager,
        'Visite da Programmare': row.totale_visite_da_programmare,
        'Visite Programmate': row.visite_programmate_totali,
        'Visite Effettuate': row.visite_totali,
        'Attività Chiuse': row.totale_attivita_chiuse,
        'Nostri Affiliati': row.totale_nostri_affiliati,
        'Non Interessati': row.totale_non_interessati,
        'Altro': row.totale_altro,
        'Affiliati Totali': row.totale_affiliati,
        'Totale Assegnati': row.totale_assegnati
      };
    });

    // Creazione del foglio di lavoro
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
      ['Nome Lista: ', response.name_list],  // A1: Nome Lista
      ['Totale Contatti: ', response.total_contact_list],  // A2: Totale Contatti
    ]);

    // Aggiungo la tabella dei dati a partire da A4
    XLSX.utils.sheet_add_json(ws, output, { origin: 'A4' });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Scrivo il file Excel
    XLSX.writeFile(wb, 'export-shop-list-total.xlsx');

    this.shopAffiliationService.successAlert('Report exported successfully');
  }




}
