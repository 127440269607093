<!--HEADER-->
<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/shopping-cart.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Shop Access</h1>
                <hr>
                <form (ngSubmit)="loadShopAccess()">
                    <div class="row align-items-end">
                        <div class="col-lg-4 col-12"><label translate>Dealer</label>
                            <app-retailer-typeahead (onChange)="filter.dealer=$event"></app-retailer-typeahead>
                        </div>

                        <div class="col-lg-4 col-12"><label translate>Tipo Pagamento</label>
                            <ng-select [(ngModel)]="filter.paymentType" [items]="paymentTypes" bindLabel="lable"
                                       name="paymentType" id="paymentType"
                                       bindValue="key" [searchable]="true"
                            ></ng-select>
                        </div>

                        <div class="col-lg-4 col-12"><label translate>Rinnovo Automatico</label>
                            <ng-select [(ngModel)]="filter.autoRenew" [items]="autoRenew" bindLabel="lable"
                                       name="autoRenew" id="autoRenew"
                                       bindValue="key" [searchable]="true"
                            ></ng-select>
                        </div>

                        <div class="col-lg-4 col-12"><label translate>Dealer Pro</label>
                            <ng-select [clearable]="false" class="disableClear" [(ngModel)]="filter.isDeleted"
                                       [items]="subscribed" bindLabel="lable"
                                       name="subscribed" id="subscribed"
                                       bindValue="key" [searchable]="true"
                            ></ng-select>
                        </div>

                        <div class="col-12 col-lg-4">
                            <label translate>Date Range</label>
                            <input id="documentDate" type="text" daterangepicker [options]="pickerOptions"
                                   [(ngModel)]="filter.date" name="date" class="form-control"
                                   (selected)="generateDateRangeString($event.start, $event.end,'date')"
                            />
                        </div>

                        <div class="col-lg-4 col-12">
                            <button class="btn btn-info btn-block" type="submit"
                                    translate>Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="dashboard"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body">
        <div class="row">
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/ceo.png" height="64"/>
                <p class="lead mt-2" translate>Dealer Pro Attivi</p>
                <h1 class="display-5 mb-0">{{dashboardReport.total_subscribed}}</h1>
            </div>
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/coins.png" height="64"/>
                <p class="lead mt-2" translate>Abbonati con 3 mnp</p>
                <h1 class="display-5 mb-0">{{dashboardReport.total_credit}}</h1>
            </div>
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/cash.png" height="64"/>
                <p class="lead mt-2" translate>Abbonati con Stripe</p>
                <h1 class="display-5 mb-0">{{dashboardReport.total_stripe}}</h1>
            </div>
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/money-loss.png" height="64"/>
                <p class="lead mt-2" translate>Disdette questo mese</p>
                <h1 class="display-5 mb-0">{{dashboardReport.total_unsubscribed}}</h1>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-10 col-12">
                <h4 class="mb-0" translate *ngIf = "isEdit">Abbonati</h4>
                <h4 class="mb-0" translate *ngIf = "!isEdit">Disattivi</h4>
            </div>
            <div class="col-lg-2 col-12">
                <button class="btn btn-success btn-sm float-right" type="button" translate *show="['master']"
                        (click)="showModal()">
                    Aggiungi Abbonamento
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Negozio</th>
<!--                <th translate class="text-left">Service</th>-->
                <th translate>Tipo Pagamento</th>
                <th translate>Dal</th>
                <th translate>al</th>
                <th translate>Rinnovo Automatico</th>
                <th class="text-right" *show="['master']">
                </th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-documents"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>

            <tr *ngFor="let subscription of shopAccess">
                <a class="mr-0 pr-0 ml-2 pl-2 pt-2" [routerLink]="['/dealer',subscription.id_dealer]"><td class="pt-2 mr-0 pr-0 ml-2 pl-2" translate="">{{(subscription.company_name)}}</td></a>
<!--                <td class="ml-1 pl-1" translate="">{{(subscription.service)}}</td>-->
                <td translate>{{(subscription.payment_type)}}</td>
                <td>{{subscription.from | amDateFormat: 'DD/MM/YYYY'}}</td>
                <td>{{subscription.to  | amDateFormat: 'DD/MM/YYYY'}}</td>
                <td>
                    <span class="text-success"
                          *ngIf="subscription.auto_renew === 1 || subscription.auto_renew === '1'">
                        <i class="text-success fas fa-check"></i>
                    </span>
                    <span class="text-danger" *ngIf="subscription.auto_renew === 0|| subscription.auto_renew === '0'">
                        <i class="text-danger fas fa-times"></i>
                    </span>
                </td>

                <td *show="['master']">
                    <button class="btn btn-link" (click)="showModal(subscription)" *ngIf="isEdit">
                        <i class=" fa fa-edit text-muted"></i>
                    </button>

                    <button class="btn btn-link" [swal]="deleteSubscriptionOptions" *ngIf="isEdit"
                            (confirm)="deleteSubscription(subscription.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>

                </td>
            </tr>

            </tbody>
        </table>
    </div>

    <div class="card-footer" *ngIf="shopAccess?.length>0">
   <span><span translate>Ci sono </span><b> {{ this.shopAccessPagination?.totalCount }}</b>
       {{this.shopAccessPagination?.totalCount <= 1 ? 'abbonato' : 'abbonati'|translate}}.</span>
        <ngb-pagination *ngIf="shopAccessPagination?.pageCount>1"
                        size="sm"
                        [pageSize]="shopAccessPagination.perPage"
                        [maxSize]="10"
                        [(page)]="shopAccessPagination.currentPage"
                        [collectionSize]="shopAccessPagination.totalCount"

                        (pageChange)="loadShopAccess()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>

</div>

<ng-template #shopAccessFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedData ? 'Modifica Abbonamento' : 'Aggiungi Abbonamento'}}</h4>
        <button (click)="dismissModal()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-shop-access-modal [data]="selectedData" (refresh)="loadShopAccess()"></app-shop-access-modal>
</ng-template>
