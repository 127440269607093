import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';

export class ShopAccess {
    public static paymentTypes = [
        {
            lable: '3 mnp',
            key: 'credit'
        },
        {
            lable: 'Stripe',
            key: 'stripe'
        },
        {
            lable: 'Bonifico Bancario',
            key: 'sdd-transfer'
        },
        {
            lable: 'Omaggio',
            key: 'free'
        }
    ];
    public static paymentTypesPair = {
        'credit': '3 mnp',
        'stripe': 'Stripe',
        'free': 'Omaggio',
        'sdd-transfer': 'Bonifico Bancario',
    };
    public static autoRenew = [
        {
            lable: 'Yes',
            key: '1'
        },
        {
            lable: 'No',
            key: '0'
        }
    ];
    public static serviceTypes = [
        // {
        //     lable: 'E-pay',
        //     key: 'e-pay',
        // },
        // {
        //     lable: 'Ticket',
        //     key: 'ticket',
        // },
        // {
        //     lable: 'Printing Service',
        //     key: 'printing',
        // },
        // {
        //     lable: 'Tech Service',
        //     key: 'tech',
        // },
        // {
        //     lable: 'Shipping Service',
        //     key: 'shipping',
        // },
        // {
        //     lable: 'Certificate Service',
        //     key: 'certificate',
        // },
        // {
        //     lable: 'Package Service',
        //     key: 'package',
        // },
        {
            lable: 'Dealer Pro',
            key: 'all',
        },

    ];
    id: number;
    id_company: number;
    id_operator: number;
    id_dealer: number;
    from: string;
    to: string;
    service: string;
    time_range: number;
    payment_type: string;
    price: string;
    auto_renew: any;
    created_at: string;
    is_deleted: any;
    company_name: string;
    /*
        public static rangeTypes = [
            {
                lable: "1 Month",
                key: "1"
            },
            {
                lable: "6 Months",
                key: "6"
            },
            {
                lable: "12 Months",
                key: "12"
            },
            {
                lable: "Custom date",
                key: "custom"
            }
        ];
    */

}

export class ShopAccessProvider extends BaseModel {
    data: ShopAccess[];
    _meta: MetaPagination;
    _links: Array<any>;
}
