import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import * as moment from 'moment';
import {Moment} from 'moment';
import {MetaPagination} from '@models/meta-pagination';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {RequestService} from '@services/request.service';
import {ShopAccessService} from '@services/shop-access.service';
import {ShopAccess, ShopAccessProvider} from '@app/pages/shop-access/shop-access.type';

@Component({
    selector: 'app-shop-access',
    templateUrl: './shop-access.component.html',
    styleUrls: ['./shop-access.component.css']
})
export class ShopAccessComponent implements OnInit {
    shopAccess: ShopAccess[] = [];
    shopAccessPagination: MetaPagination;

    deleteSubscriptionOptions: ConfirmOptionsService;

    @ViewChild('shopAccessFormTemplate') private shopAccessFormTemplate;

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
            'Custom': [],
        }
    };

    busy: any;

    filter = {
        dealer: '',
        paymentType: '',
        autoRenew: 0,
        isDeleted: 0,
        category: '',
        date: '',
        from: '',
        to: '',
        rangeTypes: ''
    };

    subscribed = [
        {
            lable: 'Attivi',
            key: 0
        },
        {
            lable: 'Cancellati',
            key: 1
        },

    ];

    apiDates = {
        date: {
            from: '',
            to: ''
        }
    };

    services = ShopAccess.serviceTypes;
    paymentTypes = ShopAccess.paymentTypes;
    autoRenew = ShopAccess.autoRenew;

    selectedData: ShopAccess;

    dashboardReport = {
        total_subscribed: 0,
        total_credit: 0,
        total_stripe: 0,
        total_unsubscribed: 0
    };

    isEdit = true;


    constructor(public modalService: NgbModal,
                public request: RequestService,
                private spinner: NgxSpinnerService,
                private dateRangePickerOptions: DaterangepickerConfig,
                private shopAccessService: ShopAccessService) {
        this.deleteSubscriptionOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    ngOnInit(): void {
        this.loadShopAccess();
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    loadShopAccess() {
        this.spinner.show('table-documents');
        const params = {
            'service': 'all'
        };
        if (this.shopAccessPagination?.currentPage) {
            params['page'] = this.shopAccessPagination?.currentPage;
        }
        if (this.filter.dealer) {
            params['id_dealer'] = this.filter.dealer;
        }
        if (this.filter.paymentType) {
            params['payment_type'] = this.filter.paymentType;
        }

        if (this.filter.autoRenew) {
            params['auto_renew'] = this.filter.autoRenew;
        }
        if (this.filter.date) {
            params['from'] = this.apiDates.date.from;
            params['to'] = this.apiDates.date.to;
        }

        this.shopAccessService.getDashboardReport().subscribe((response => {
            this.dashboardReport.total_subscribed = response.total_subscribed;
            this.dashboardReport.total_credit = response.total_credit;
            this.dashboardReport.total_stripe = response.total_stripe;
            this.dashboardReport.total_unsubscribed = response.total_unsubscribed;
        }));

        //params['time_range'] = this.filter.rangeTypes;
        params['is_deleted'] = this.filter.isDeleted;
        this.shopAccessService.getListOfItems(params).subscribe((response: ShopAccessProvider) => {
            this.isEdit = this.filter.isDeleted != 1;
            this.shopAccess = response.data;
            this.shopAccessPagination = response._meta;
            this.spinner.hide('table-documents');
        }, err => {
            this.spinner.hide('table-documents');
        });
    }

    deleteSubscription(id: number) {
        this.shopAccessService.deleteSubscription(id).subscribe(() => {
            const index = this.shopAccess.findIndex(dealer => dealer.id === id);
            if (index > -1) {
                this.shopAccessService.successAlert('Deleted Service!');
                this.loadShopAccess();
            }
        }, (err) => {
            this.shopAccessService.errorAlert(err.message);
        });
    }

    showModal(data?: ShopAccess) {
        this.selectedData = data;
        this.modalService.open(this.shopAccessFormTemplate, {size: data ? 'md' : 'lg'});
    }

    selectedDate(value, date: Moment) {
        this.filter[value] = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.apiDates[value] = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment, key) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDates[key].from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDates[key].to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter[key] = startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }

}
