<div class="card bg-gradient-gray">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <div
                class="d-none d-xl-inline-flex justify-content-center align-items-center img-circle bg-gradient-green p-1 elevation-1"
                style="width: 180px; height: 160px"
            >
                <i class="fab fa-whatsapp display-1"></i>
            </div>
            <div class="d-block w-100 pl-lg-3">
                <h1 class="display-4" translate>Dettaglio Whatsapp</h1>
                <hr />
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <h4 class="mb-0" translate>Lista Assegnati</h4>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-success" (click)="showModal(); loadAmSelect(); loadKaSelect(); loadDcSelect()">
                    <i class="fa fa-plus mr-2"></i>Assegna
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th translate>ID</th>
                    <th translate>Nome</th>
                    <th *ngIf="this.sendType == 'Numero'" translate>Numero</th>
                    <th translate>Date</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="bg-gradient-white">
                <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <tr *ngFor="let item of listDetail">
                    <td>
                        {{ item.id }}
                    </td>
                    <td>
                        {{ item.nome }}
                    </td>
                    <td *ngIf="this.sendType == 'Numero'" class="d-flex align-content-center">
                        {{ item.number }}
                        <button
                            href="javascript:void(0)"
                            class="btn btn-sm text-uppercase"
                            (click)="openPopover(plafondStatusPopover, item?.id, item?.number, 'number')"
                            #plafondStatusPopover="ngbPopover"
                            autoClose="outside"
                            triggers="manual"
                            [ngbPopover]="popContent"
                            popoverTitle="Cambia valore"
                        >
                            <i class="fas fa-edit mb-1 text-info"></i>
                        </button>
                    </td>
                    <td>
                        {{ item?.created_at | date : 'dd/MM/yyyy HH:MM:ss' }}
                    </td>
                    <td>
                        <button
                            type="button"
                            class="close text-danger"
                            aria-label="Close"
                            [swal]="deleteSubscriptionOptions"
                            *ngIf="isEdit"
                            (confirm)="delete(item.id)"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="listDetail?.length <= 0">
                    <td class="text-muted text-center" colspan="6" translate>List is empty</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="listDetail?.length > 0">
        <span
            ><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
            {{ this.ordersPagination.totalCount <= 1 ? 'Risultato' : ('Risultati' | translate) }}.
            <a class="link">Esporta la lista completa</a>
        </span>
        <ngb-pagination
            *ngIf="ordersPagination.pageCount > 1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadListDetail()"
            [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #assegnaWaTemplate let-modal>
    <div class="modal-header bg-gradient-dark">
        <h4 class="modal-title clamp-title" translate>{{ 'Assegna' }}</h4>
        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs">
            <li *ngIf="role.dc" ngbNavItem>
                <a ngbNavLink translate>{{ 'Direttore Commerciale' }}</a>
                <ng-template ngbNavContent>
                    <div class="card">
                        <div class="card-header d-flex justify-content-between">
                            <h4 class="mb-0" translate>Seleziona uno o più Direttore Commerciale</h4>

                            <div class="col-3">
                                <ng-select
                                    [items]="operator"
                                    placeholder="Seleziona un operatore..."
                                    name="subscribed"
                                    bindLabel="name"
                                    bindValue="id"
                                    [(ngModel)]="selectedOperator"
                                    [searchable]="true"
                                    (change)="onOperatorChange($event)"
                                ></ng-select>
                            </div>
                            <button class="btn btn-primary ml-auto" [disabled]="!enableSaveButton" (click)="save()">
                                Salva
                            </button>
                        </div>
                        <div class="card-body p-0 rounded-bottom table-responsive">
                            <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name="table-orders"
                                [fullScreen]="false"
                            >
                                <p style="color: white">Caricamento...</p>
                            </ngx-spinner>
                            <table class="table table-sm table-striped">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style="width: 45%" translate>Nome</th>
                                        <th style="width: 50%" translate>Numero</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-gradient-white">
                                    <ngx-spinner
                                        bdColor="rgba(0, 0, 0, 0.8)"
                                        size="default"
                                        color="#fff"
                                        type="square-jelly-box"
                                        name="orders"
                                        [fullScreen]="false"
                                    >
                                        <p style="color: white">Caricamento...</p>
                                    </ngx-spinner>
                                    <tr *ngFor="let dc of subMasterGroup">
                                        <td>
                                            <input
                                                type="checkbox"
                                                [checked]="dc.dc_checked"
                                                (change)="toggleSelection($event.target, dc.id, dc.telefono, 'sub')"
                                            />
                                        </td>
                                        <td>
                                            {{ dc.ragsociale }}
                                        </td>
                                        <td>
                                            {{ dc.telefono }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="subMasterGroup?.length <= 0">
                                        <td class="text-muted text-center" colspan="3" translate>List is empty</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <p class="text-sm text-muted">* Info aggiuntive</p>
                        </div>
                    </div>
                </ng-template>
            </li>

            <li *ngIf="role.am" ngbNavItem>
                <a ngbNavLink translate>{{ 'Area Manager' }}</a>
                <ng-template ngbNavContent>
                    <div class="card">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <h5 class="mb-0" translate>Seleziona uno o più Area Manager</h5>
                            <div class="col-12 col-lg-3 col-md-3">
                                <app-master-agent-select
                                    (onChange)="onMasterChange($event)"
                                    id="masterAgent"
                                ></app-master-agent-select>
                            </div>
                            <div class="">
                                <ng-select
                                    [items]="operator"
                                    placeholder="Seleziona un operatore..."
                                    name="subscribed"
                                    bindLabel="name"
                                    bindValue="id"
                                    [(ngModel)]="selectedOperator"
                                    [searchable]="true"
                                    (change)="onOperatorChange($event)"
                                ></ng-select>
                            </div>
                            <button class="btn btn-primary ml-auto" [disabled]="!enableSaveButton" (click)="save()">
                                Salva
                            </button>
                        </div>
                        <div class="card-body p-0 rounded-bottom table-responsive">
                            <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name="table-orders"
                                [fullScreen]="false"
                            >
                                <p style="color: white">Caricamento...</p>
                            </ngx-spinner>
                            <table class="table table-sm table-striped">
                                <thead>
                                    <tr>
                                        <th style="width: 5%" translate>
                                            <input
                                                type="checkbox"
                                                [(ngModel)]="selectAllMasterAgent"
                                                (change)="selectAllMasterAgents($event)"
                                            />
                                        </th>
                                        <th style="width: 45%" translate>Nome</th>
                                        <th style="width: 50%" translate>Numero</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-gradient-white">
                                    <ngx-spinner
                                        bdColor="rgba(0, 0, 0, 0.8)"
                                        size="default"
                                        color="#fff"
                                        type="square-jelly-box"
                                        name="orders"
                                        [fullScreen]="false"
                                    >
                                        <p style="color: white">Caricamento...</p>
                                    </ngx-spinner>
                                    <tr *ngFor="let am of masterAgentGroup">
                                        <td>
                                            <input
                                                type="checkbox"
                                                [checked]="am.am_checked"
                                                (change)="toggleSelection($event.target, am.id, am.telefono, 'master')"
                                            />
                                        </td>
                                        <td>
                                            {{ am.ragsociale }}
                                        </td>
                                        <td>
                                            {{ am.email }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="masterAgentGroup?.length <= 0">
                                        <td class="text-muted text-center" colspan="3" translate>List is empty</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <p class="text-sm text-muted">* Info aggiuntive</p>
                        </div>
                    </div>
                </ng-template>
            </li>

            <li ngbNavItem *ngIf="role.ka">
                <a ngbNavLink translate>{{ 'Key Account' }}</a>
                <ng-template ngbNavContent>
                    <div class="card">
                        <div class="card-header d-flex justify-content-between">
                            <h4 class="mb-0" translate>Seleziona uno o più Key Account</h4>
                            <div class="col-12 col-lg-3 col-md-3">
                                <app-agent-select (onChange)="onAgentChange($event)" id="agent"></app-agent-select>
                            </div>
                            <div class="">
                                <ng-select
                                    [items]="operator"
                                    placeholder="Seleziona un operatore..."
                                    name="subscribed"
                                    bindLabel="name"
                                    bindValue="id"
                                    [(ngModel)]="selectedOperator"
                                    [searchable]="true"
                                    (change)="onOperatorChange($event)"
                                ></ng-select>
                            </div>
                            <button class="btn btn-primary ml-auto" [disabled]="!enableSaveButton" (click)="save()">
                                Salva
                            </button>
                        </div>
                        <div class="card-body p-0 rounded-bottom table-responsive">
                            <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name="table-orders"
                                [fullScreen]="false"
                            >
                                <p style="color: white">Caricamento...</p>
                            </ngx-spinner>
                            <table class="table table-sm table-striped">
                                <thead>
                                    <tr>
                                        <th style="width: 5%" translate>
                                            <input
                                                type="checkbox"
                                                [(ngModel)]="selectAllAgent"
                                                (change)="selectAllAgents($event)"
                                            />
                                        </th>
                                        <th style="width: 45%" translate>Nome</th>
                                        <th style="width: 50%" translate>Numero</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-gradient-white">
                                    <ngx-spinner
                                        bdColor="rgba(0, 0, 0, 0.8)"
                                        size="default"
                                        color="#fff"
                                        type="square-jelly-box"
                                        name="orders"
                                        [fullScreen]="false"
                                    >
                                        <p style="color: white">Caricamento...</p>
                                    </ngx-spinner>
                                    <tr *ngFor="let ka of agentGroup">
                                        <td>
                                            <input
                                                type="checkbox"
                                                [checked]="ka.ka_checked"
                                                (change)="toggleSelection($event.target, ka.id, ka.telefono, 'master')"
                                            />
                                        </td>
                                        <td>
                                            {{ ka.ragsociale }}
                                        </td>
                                        <td>
                                            {{ ka.telefono }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="agentGroup?.length <= 0">
                                        <td class="text-muted text-center" colspan="3" translate>List is empty</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <p class="text-sm text-muted">* Info aggiuntive</p>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav"></div>
    </div>
</ng-template>

<ng-template #popContent>
    <app-edit-popup [config]="popupConfig" (onSave)="update($event)"></app-edit-popup>
</ng-template>
