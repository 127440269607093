import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestService } from './request.service';
import { AlertService } from '@services/alert.service';

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    ENDPOINT = 'invoices';

    constructor(public request: RequestService, public alertService: AlertService) {}

    list(params) {
        return this.request.get(`${this.ENDPOINT}/list`, params, { observe: 'response' }).pipe(
            map((data: HttpResponse<any>) => {
                return {
                    rows: data.body,
                    pagination: {
                        totalCount: parseInt(data.headers.get('X-Pagination-Total-Count')),
                        page: parseInt(data.headers.get('X-Pagination-Current-Page')),
                        pageSize: parseInt(data.headers.get('X-Pagination-Per-Page')),
                        pages: parseInt(data.headers.get('X-Pagination-Page-Count')),
                    },
                };
            }),
        );
    }

    get(id) {
        return this.request.get(`${this.ENDPOINT}/view`, {
            id: id,
            expand: 'company,company.city,company.province,lines,lines.sublines,lines.descrizione,lines.monthName',
        });
    }

    getGroupList() {
        return this.request.get(`${this.ENDPOINT}/group-list`);
    }

    unlockGroup(id) {
        return this.request.get(`${this.ENDPOINT}/unlock-group/${id}`);
    }

    blockGroup(id) {
        return this.request.get(`${this.ENDPOINT}/block-group/${id}`);
    }

    listInvoiceGroups() {
        return this.request.get(`${this.ENDPOINT}/invoice-groups`);
    }

    postPreviewTemplate(payload) {
        return this.request.post(`${this.ENDPOINT}/preview-template`, payload);
    }

    getTemplateProForma() {
        return this.request.get(`${this.ENDPOINT}/template-invoices`, {}, { responseType: 'blob' });
    }

    listProFormaTypes() {
        return this.request.get(`${this.ENDPOINT}/list-types`);
    }

    createProFormaType(payload) {
        return this.request.post(`${this.ENDPOINT}/create-type`, payload);
    }

    overview() {
        return this.request.get(`${this.ENDPOINT}/overview`);
    }

    regenInvoice(id) {
        return this.request.post(`${this.ENDPOINT}/regen-invoice/${id}`);
    }

    updateInvoice(id, data) {
        return this.request.put(`${this.ENDPOINT}/${id}`, data);
    }

    updateInvoiceLine(id, data) {
        return this.request.put(`${this.ENDPOINT}/update-line/${id}`, data);
    }

    updateInvoiceDetailLine(id, data) {
        return this.request.put(`${this.ENDPOINT}/update-detail/${id}`, data);
    }

    sendToSDI(id) {
        return this.request.post(`${this.ENDPOINT}/send-to-sdi/${id}`);
    }

    updateSdiStatus(id) {
        return this.request.post(`${this.ENDPOINT}/update-sdi-status/${id}`);
    }

    updateInvoiceTransaction(payload) {
        return this.request.post(`${this.ENDPOINT}/update-invoice-transaction`, payload);
    }

    addBollo(invoiceId) {
        return this.request.post(`${this.ENDPOINT}/add-bollo`, invoiceId);
    }

    createInvoiceDetail(payload) {
        return this.request.post(`${this.ENDPOINT}/create-invoice-detail`, payload);
    }

    deleteInvoiceDetail(id: number) {
        return this.request.delete(`${this.ENDPOINT}/delete-invoice-detail/${id}`);
    }

    redoInvoice(id: number, $event: any) {
        return this.request.post(`${this.ENDPOINT}/redo-invoice/${id}`, {
            description: $event,
        });
    }

    mergeDocuments(selectedInvoices: any[]) {
        return this.request.post(`${this.ENDPOINT}/merge-documents`, {
            documents: selectedInvoices,
        });
    }

    public successAlert(text) {
        this.alertService.show('', 'Transazione ' + text, { classname: 'bg-success text-light', delay: 2000 });
    }

    public errorAlert(message?) {
        this.alertService.show('Errore ', message ? message : 'Riprova di nuovo!', {
            classname: 'bg-danger text-light',
            delay: 2000,
        });
    }

   updateDestinatario(params?) {
        return this.request.put(`${this.ENDPOINT}/update-destinatario`, params);
    }
}
