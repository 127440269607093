import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';


export class RendicontiAddebito extends BaseModel {
  id: number;
  company_name: string;
  tipo: string;
  numero: string;
  totale: string;
  date: string;
  dealer_id: number;
  file: string;
  documentNumber: string;
  period: string;
  year: number;
  total : string;



  fromArray(result: []): RendicontiAddebito[] {
    const array: RendicontiAddebito[] = [];
    for (const item of result) {
      array.push(new RendicontiAddebito(item));
    }
    return array;
  }
}

export class RendicontiAddebitoProvider extends BaseModel {
  data: RendicontiAddebito[];
  _meta: MetaPagination;
  _links: Array<any>;
}
