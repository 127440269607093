<div class="login-box" [ngBusy]="busy">
    <div class="card">
        <div class="card-body login-card-body  rounded elevation-1">
            <img src="assets/img/logo_full.png" width="100%" class="mb-5">
            <div class="text-muted mb-4" translate *ngIf="isGuestUser">Hai richiesto un cambio password, prosegui per effettuare la modifica.</div>
            <div class="text-muted mb-4" translate *ngIf="!isGuestUser">La password che stavi usando è scaduta. Cambiala perfavore.</div>
            <form (ngSubmit)="changePassword()" [formGroup]="passwordForm">
                <div class="form-row">
                    <div class="col-12 mb-4" *ngIf="!isGuestUser">
                        <div class="input-group">
                            <input
                                    formControlName="old_password"
                                    type="password"
                                    class="form-control"
                                    placeholder="Vecchia password"
                            />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-key"></span>
                                </div>
                            </div>
                        </div>
                        <val-errors controlName="old_password">
                            <ng-template valError="required">
                                <span translate>Password is required</span>
                            </ng-template>
                        </val-errors>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="input-group">
                            <input
                                    formControlName="new_password"
                                    type="password"
                                    class="form-control"
                                    placeholder="Nuova password"
                            />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-key"></span>
                                </div>
                            </div>
                        </div>
                        <val-errors controlName="new_password">
                            <ng-template valError="required">
                                <span translate>Password is required</span>
                            </ng-template>
                        </val-errors>
                        <val-errors controlName="new_password">
                            <ng-template valError="hasNumber">
                                <span translate>At least one number</span>
                            </ng-template>
                        </val-errors>
                        <val-errors controlName="new_password">
                            <ng-template valError="hasCapitalCase">
                                <span translate>At least one uppercase letter</span>
                            </ng-template>
                        </val-errors>
                        <val-errors controlName="new_password">
                            <ng-template valError="hasSmallCase">
                                <span translate>At least one lowercase letter</span>
                            </ng-template>
                        </val-errors>
                        <val-errors controlName="new_password">
                            <ng-template valError="hasSpecialCharacters">
                                <span translate>At least one special character</span>
                            </ng-template>
                        </val-errors>
                        <val-errors controlName="new_password">
                            <ng-template valError="minlength">
                            </ng-template>
                        </val-errors>
                    </div>
                    <div class="col-12 mb-2">
                        <div class="input-group">
                            <input
                                    formControlName="password_repeat"
                                    type="password"
                                    class="form-control"
                                    placeholder="Conferma la nuova password"
                            />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-key"></span>
                                </div>
                            </div>
                        </div>
                        <val-errors controlName="password_repeat">
                            <ng-template valError="required">
                                <span translate>Confirm password is required</span>
                            </ng-template>
                        </val-errors>
                        <val-errors controlName="password_repeat">
                            <ng-template valError="passwordNotMatch">
                                <span translate>Passwords must match</span>
                            </ng-template>
                        </val-errors>
                    </div>
                    <div class="col-12 mb-2 text-right">
                        <a (click)="logout()" href="javascript:void(0)" translate>Logout</a>
                    </div>
                    <div class="col-12">
                        <button type="submit" [disabled]="passwordForm.invalid"
                                class="btn bg-gradient-dark btn btn-block" translate>
                            Change Password
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
