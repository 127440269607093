import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from "@services/authentication.service";
import {User} from "@models/user";

@Directive({
    selector: '[dontShow]'
})
export class DontShowDirective implements OnInit{
    private user: User;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthenticationService
    ) {
        this.authService.currentUser.subscribe(user => this.user = user);
    }

    @Input() set dontShow(roles: string[]){
        if (this.user && !roles.includes(this.user.profile.role)) {
            // If condition is true add template to DOM
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            // Else remove template from DOM
            this.viewContainer.clear();
        }

    }

    ngOnInit(): void {
    }
}
