<div class="card bg-gradient-dark elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadKpiList()">
        <div class="form-row">
            <div class="col-xl-2 col-md-2 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label>Titolo</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="filters.title"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </div>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label>Slug</label>
                    <ng-select
                        class="form-control-md"
                        [(ngModel)]="filters.slug"
                        [ngModelOptions]="{ standalone: true }"
                    >
                        <ng-option *ngFor="let slug of slugList" [value]="slug">{{ slug }}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label>Categoria</label>
                    <ng-select
                        class="form-control-md"
                        [(ngModel)]="filters.category"
                        [ngModelOptions]="{ standalone: true }"
                    >
                        <ng-option *ngFor="let category of categoryList" [value]="category">{{ category }}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label>Data</label>
                    <input
                        id="documentDate"
                        type="text"
                        daterangepicker
                        autocomplete="off"
                        [options]="pickerOptions"
                        [(ngModel)]="filters.date"
                        name="date"
                        class="form-control"
                        (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-12 mt-auto mb-0"></div>
        </div>
    </form>
</div>
<div class="accordion">
    <div class="card">
        <div class="card-header bg-dark">
            <h4 class="mb-0" translate>
                <i
                    (click)="toggleCollapse()"
                    style="cursor: pointer"
                    class="fa m-auto mr-3"
                    [ngClass]="{ 'fa-chevron-down': isCollapsed, 'fa-chevron-up': !isCollapsed }"
                ></i>
                Inserisci un nuovo KPI
            </h4>
        </div>
        <div *ngIf="!isCollapsed">
            <div class="card-body">
                <div>
                    <form [formGroup]="datiForm" (ngSubmit)="salvaDati()">
                        <div class="form-row">
                            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                                <div class="form-group">
                                    <label for="titolo">Titolo</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="title"
                                        placeholder="Inserisci un titolo..."
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                                <div class="form-group">
                                    <label for="slug">Slug</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="slug"
                                        placeholder="Inserisci uno slug"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                                <div class="form-group">
                                    <label for="category">Categoria</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="category"
                                        placeholder="Inserisci la categoria"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                                <div class="form-group">
                                    <label for="immagine">Immagine</label>
                                    <div class="custom-file">
                                        <input
                                            type="file"
                                            class="custom-file-input"
                                            formControlName="image"
                                            (change)="onFileChange($event)"
                                            accept="image/*"
                                        />
                                        <label class="custom-file-label">
                                            {{ selectedFileName || 'Seleziona un file' }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                                <div class="form-group">
                                    <label for="descrizione">Descrizione</label>
                                    <editor
                                        id="descrizione"
                                        [init]="tinyMceConfig"
                                        formControlName="description"
                                    ></editor>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                                <div class="form-group">
                                    <label for="notes">Note</label>
                                    <editor id="notes" [init]="tinyMceConfig" formControlName="notes"></editor>
                                </div>
                            </div>
                            <div class="col-xl-12">
                                <button
                                    type="submit"
                                    class="btn px-3 btn-secondary"
                                    [disabled]="
                                        !datiForm.get('title').valid ||
                                        !datiForm.get('slug').valid ||
                                        !datiForm.get('category').valid
                                    "
                                >
                                    Salva il nuovo KPI
                                    <span *ngIf="isLoading" class="fa fa-spinner fa-spin ml-2"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <h4 class="mb-0">KPI</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th style="width: 8%">N°</th>
                    <th style="width: 10%" translate>Logo</th>
                    <th style="width: 10%" translate>Titolo</th>
                    <th style="width: 25%" translate>Descrizione</th>
                    <th style="width: 15%" translate>Slug</th>
                    <th style="width: 15%" translate>Categoria</th>
                    <th style="width: 15%" translate>Data di creazione</th>
                    <th style="width: 5%" class="text-center"></th>
                    <th style="width: 5%" class="text-center"></th>
                </tr>
            </thead>
            <tbody>
                <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <tr *ngIf="kpiList.length == 0">
                    <td translate colspan="8" class="text-center">List is empty</td>
                </tr>
                <tr *ngFor="let item of kpiList; let i = index">
                    <td>
                        {{ i + 1 }}
                    </td>
                    <td>
                        <img [src]="item?.image" alt="Logo" class="logo-image" style="width: 50px; height: auto" />
                    </td>
                    <td>
                        {{ item?.title }}
                    </td>
                    <td>
                        {{ item?.description }}
                    </td>
                    <td>
                        {{ item?.slug }}
                    </td>
                    <td>
                        {{ item?.category }}
                    </td>
                    <td>
                        {{ item?.created_at | amDateFormat : 'DD/MM/YYYY HH:mm' }}
                    </td>
                    <td class="text-right">
                        <button class="btn btn-link" (click)="showDetailModal(item)">
                            <i class="fa fa-eye text-info"></i>
                        </button>
                    </td>
                    <td class="text-center">
                        <button
                            type="button"
                            class="close text-danger"
                            aria-label="Close"
                            [swal]="deleteSubscriptionOptions"
                            *ngIf="isEdit"
                            (confirm)="deleteKpi(item?.id)"
                        >
                            <p aria-hidden="true">&times;</p>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="kpiList?.length > 0">
        <span
            ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b>
            {{ this.ordersPagination.totalCount <= 1 ? 'KPI' : ('KPI' | translate) }}
            .
            <!-- <a class="link" >Esporta la lista completa</a> -->
        </span>
        <ngb-pagination
            *ngIf="ordersPagination.pageCount > 1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadKpiList()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>

<ng-template #detailTemplate let-modal>
    <div class="modal-header">
        <h4>Modifica Kpi</h4>
        <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editForm" (ngSubmit)="updateKpi($event)">
            <div class="form-row">
                <div class="col-xl-4 col-md-4 col-12 mt-auto mb-0">
                    <div class="form-group">
                        <label for="editTitle">Titolo</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="editTitle"
                            placeholder="Modifica titolo..."
                        />
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 col-12 mt-auto mb-0">
                    <div class="form-group">
                        <label for="editCategory">Categoria</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="editCategory"
                            placeholder="Modifica la categoria..."
                        />
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 col-12 mt-auto mb-0">
                    <div class="form-group">
                        <label for="editSlug">Slug</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="editSlug"
                            placeholder="Modifica slug..."
                        />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="editDescription">Descrizione</label>
                <editor id="editDescription" [init]="tinyMceConfig" formControlName="editDescription"></editor>
            </div>
            <div class="form-group">
                <label for="editNotes">Note</label>
                <editor id="editNotes" [init]="tinyMceConfig" formControlName="editNotes"></editor>
            </div>
            <div class="form-group">
                <label for="editLogo">Logo</label>
                <div class="input-logo">
                    <div class="custom-file">
                        <input
                            type="file"
                            class="custom-file-input"
                            formControlName="editImage"
                            (change)="onFileChange($event)"
                            accept="image/*"
                        />
                        <label class="custom-file-label">
                            {{ selectedFileName || 'Seleziona un file' }}
                        </label>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="!this.editForm.valid">Salva modifiche</button>
        </form>
    </div>
</ng-template>
