import {Component, EventEmitter, OnInit} from '@angular/core';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {PbxCampaign} from '@models/pbx/pbx-campaign';
import {PbxLead, PbxLeadListPagination} from '@models/pbx/pbx-lead';
import {MetaPagination} from '@models/meta-pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {PbxCall, PbxCallListPagination} from '@models/pbx/pbx-call';

@Component({
    selector: 'app-pbx-campaign',
    templateUrl: './pbx-campaign.component.html',
    styleUrls: ['./pbx-campaign.component.css']
})
export class PbxCampaignComponent implements OnInit {
    campaigns: PbxCampaign[] = [];
    questionsLoading = false;
    campaignId: number;
    selectedCampaign: PbxCampaign;
    leads: PbxLead[] = [];
    leadsPagination: MetaPagination;
    calls: PbxCall[] = [];
    callsPagination: MetaPagination;
    busy: any;
    isActiveCampaign = false;

    constructor(
        private pbxCampaignService: PbxCampaignService,
        private spinner: NgxSpinnerService) {
    }

    ngOnInit(): void {
        this.loadCampaigns();
    }

    loadCampaigns(term?: any) {
        this.questionsLoading = true;
        let params;
        if (term) {
            params = {name: term};
        }
        this.pbxCampaignService.getCampaigns(params).subscribe(response => {
            this.campaigns = response.data;
            if (response.data?.length > 0) {
                this.campaignId = response.data[0].id;
                if (!term) {
                    this.loadPage(this.campaigns[0]);
                }
            }
            this.questionsLoading = false;

        }, err => {
            this.questionsLoading = false;
        });
    }

    loadPage(campaign) {
        if (campaign) {
            this.isActiveCampaign = campaign.status === PbxCampaignService.activeCampaign;
            this.selectedCampaign = campaign;
            this.pbxCampaignService.changedCampaign.next(campaign);
        }
    }

    loadLeads(filter?) {
        if (this.selectedCampaign) {
            this.spinner.show('table-leads');
            const params = {};
            if (this.leadsPagination?.currentPage) {
                params['page'] = this.leadsPagination?.currentPage;
            }
            if (filter?.company_name) {
                params['company_name'] = filter?.company_name;
            }
            if (filter?.region_id) {
                params['region_id'] = filter?.region_id;
            }
            if (filter?.province_id) {
                params['province_id'] = filter?.province_id;
            }
            if (filter?.city_id) {
                params['city_id'] = filter?.city_id;
            }
            this.pbxCampaignService.getLeads(this.selectedCampaign?.id, params).subscribe((response: PbxLeadListPagination) => {
                this.leads = response.data;
                this.leadsPagination = response._meta;
                this.spinner.hide('table-leads');
            }, err => {
                this.spinner.hide('table-leads');
            });
        }
    }

    loadCalls(filter?) {
        if (this.selectedCampaign) {
            this.spinner.show('table-calls');
            const params = {campaign_id: this.selectedCampaign?.id};

            if (filter?.company_name) {
                params['company_name'] = filter?.company_name;
            }
            if (filter?.region_id) {
                params['region_id'] = filter?.region_id;
            }
            if (filter?.province_id) {
                params['province_id'] = filter?.province_id;
            }
            if (filter?.city_id) {
                params['city_id'] = filter?.city_id;
            }

            if (this.callsPagination?.currentPage) {
                params['page'] = this.callsPagination?.currentPage;
            }
            this.pbxCampaignService.getCalls(params).subscribe((response: PbxCallListPagination) => {
                this.calls = response.data;
                this.callsPagination = response._meta;
                this.spinner.hide('table-calls');
            }, err => {
                this.spinner.hide('table-calls');
            });
        }
    }

    emptyCampaign(event) {
        if (event) {
            this.loadCampaigns();
        }
    }
}
