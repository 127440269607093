// @ts-ignore
import {version} from '../../package.json';

export const environment = {
    production: true,
    title: 'KDistribution',
    baseUrl: 'https://api.master-attivazioni.it/',
    assetsUrl: 'https://ewr1.vultrobjects.com/kdistribution/static/',
    baseAwsUrl: 'https://ewr1.vultrobjects.com',
    apiUrl: 'https://api.master-attivazioni.it/v1',
    dashboardUrl: 'https://api.master-attivazioni.it/dashboard',
    operatorUrl: 'https://api.master-attivazioni.it/operator',
    language: 'it',
    startDate: '2021-01',
    whatsAppUrl: 'https://wa.me/393925027344',
    telegramUrl: 'https://t.me/mastergrouptlc',
    company: {
        url: 'https://kdistribution.it',
        name: 'KDistribution'
    },
    version
};
