import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SddService {
    [x: string]: any;

    constructor(private request: RequestService, public alertService: AlertService) {}

    public getSddOrders(params?): Observable<any> {
        return this.request.get('sdd/orders', params);
    }

    public getSddContract(id: number): Observable<any> {
        return this.request.get(`sdd/get-contract?id=${id}`);
    }

    public putSddOrder(id, body: {}) {
        return this.request.put('sdd/update/' + id, body);
    }

    addSddSubscription(data) {
        return this.request.post(`sdd/create`, data);
    }

    deleteSddSubscription(id: number) {
        return this.request.delete(`sdd/delete/${id}`);
    }

    public successAlert(text) {
        this.alertService.show('', 'Successfully ' + text, { classname: 'bg-success text-light', delay: 2000 });
    }

    public errorAlert(message?) {
        this.alertService.show('An error occurred!', message ? message : 'Try again!', {
            classname: 'bg-danger text-light',
            delay: 2000,
        });
    }
}