<div class="modal-body" [ngBusy]="busy">
  <form [formGroup]="cuForm" (ngSubmit)="onSubmit()">
    <div class="form-row p-4">
      <div class="col-md-6 col-sm-12 mt-auto mb-0" *ngIf="!data">
        <!-- <label for="">Company Name</label>
                <input type="text" class="form-control"> -->
        <label class="lead" translate>Company name</label>
        <app-retailer-typeahead (onChange)="setDealerId($event)"></app-retailer-typeahead>
        <val-errors controlName="dealers">
          <ng-template valError="required">
            <span translate>Company name is required</span>
          </ng-template>
        </val-errors>
      </div>
      <div class="col-md-6 col-sm-12 mt-auto mb-0" *ngIf="!data">
        <!-- <label for="">Anno</label>
                <input type="text" class="form-control"> -->
        <label class="lead" translate>Anno</label>
        <input class="form-control" formControlName="year" />
        <val-errors controlName="year">
          <ng-template valError="required">
            <span translate>Year is required</span>
          </ng-template>
          <ng-template valError="pattern">
            <span translate>Year errors</span>
          </ng-template>
        </val-errors>
      </div>
      <div class="col-md-6 col-sm-12 mt-2 mb-0">
        <div class="form-group">
          <label class="lead">Scegli il file</label>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="validatedCustomFile" (change)="handleFileInput($event.target.files)" controlName="file" />
            <label class="custom-file-label" for="validatedCustomFile">Scegli il file</label>
          </div>
          <val-errors controlName="file">
            <ng-template valError="required"> Il file è obbligatorio </ng-template>
          </val-errors>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 pr-0 mb-0 mt-4">
        <label for=""></label>
        <button class="btn btn-info btn-block" type="submit"><i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span></button>
      </div>
    </div>
  </form>
</div>
