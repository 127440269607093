import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Popup, PopupPagination } from '@models/popup';
import { PopupService } from '@services/popup.service';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { TinyMCE } from '@models/tinyMCE';
import {ShopBrand, ShopBrandPagination} from '@models/e-pay/shop-brand';
import { EPayService } from '@app/services/e-pay.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent implements OnInit {
  popups: Popup[] = [];
  brands: ShopBrand[] = [];
  popupsPagination: MetaPagination;
  brandsLoading = false;
  filter = {
    date: '',
  };
  filterApiDate = {
    from: '',
    to: '',
  };
  apiDate = {
    start_date: '',
    end_date: '',
  };
  selectedPopup: Popup;
  busy: any;
  popupForm = new FormGroup({
    title: new FormControl(''),
    description: new FormControl('', Validators.required),
    type: new FormControl('popup', Validators.required),
    start_date: new FormControl('', Validators.required),
    end_date: new FormControl('', Validators.required),
    debug: new FormControl('', Validators.required),
    is_pro: new FormControl('', Validators.required),
    id_kdricarica_brand: new FormControl(''), 
  });
  pickerOptions = {
    singleDatePicker: true,
    ranges: [],
  };
  defaultConfig = new TinyMCE();
  deleteOptions: ConfirmOptionsService;
  localFormat = 'DD/MM/YYYY';
  tinyMceConfig = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: [
      'lists',
      'advlist',
      'autolink',
      'link',
      'image',
      'charmap',
      'print',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'code',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'paste',
      'code',
      'help',
      'wordcount',
    ],
    toolbar:
      'undo redo | formatselect | ' + 'bold italic backcolor | alignleft aligncenter ' + 'alignright alignjustify | bullist numlist outdent indent | ' + 'removeformat | help',
  };
  @ViewChild('popupFormTemplate') private popupFormTemplate;


  constructor(public modalService: NgbModal, private spinner: NgxSpinnerService, private dateRangePickerOptions: DaterangepickerConfig, private popupService: PopupService, private ePayService: EPayService) {
    this.deleteOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro!',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
    });
    this.defaultConfig.menubar = true;
    this.defaultConfig.plugins = [
      'advlist autolink lists link image charmap print preview hr anchor pagebreak',
      'searchreplace wordcount visualblocks visualchars code fullscreen',
      'insertdatetime media nonbreaking save table contextmenu directionality',
      'emoticons template paste textcolor colorpicker textpattern',
    ];
  }

  ngOnInit(): void {
    this.loadPopups();
  }

  loadPopups() {
    this.spinner.show('table-popup');
    const params = {};
    if (this.popupsPagination?.currentPage) {
      params['page'] = this.popupsPagination?.currentPage;
    }
    if (this.filterApiDate?.from) {
      params['from'] = this.filterApiDate.from;
    }
    if (this.filterApiDate?.to) {
      params['to'] = this.filterApiDate.to;
    }
    this.popupService.getPopup(params).subscribe(
      (response: PopupPagination) => {
        this.popups = response.data;
        this.popupsPagination = response._meta;
        this.spinner.hide('table-popup');
      },
      (err) => {
        this.spinner.hide('table-popup');
      },
    );
  }

  showModal(popup?) {
    this.selectedPopup = popup;
    if (this.selectedPopup) {
      this.loadFormData();
    } else {
      this.popupForm.reset();
      this.popupForm.get('start_date').setValue(moment().format(this.dateRangePickerOptions.settings.locale.format));
      this.apiDate.start_date = moment().format(this.dateRangePickerOptions.settings.locale.apiFormat);
    }
    this.modalService.open(this.popupFormTemplate, { size: 'lg' });
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.filterApiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filterApiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  selectedDate(value, date: Moment) {
    const selectedDate = date.format(this.dateRangePickerOptions.settings.locale.format);
    this.apiDate[value] = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.popupForm.get(value).setValue(selectedDate);
  }

  savePopup() {
    const popup = new Popup();
    popup.title = this.popupForm.value.title;
    popup.description = this.popupForm.value.description;
    popup.type = this.popupForm.value.type;
    popup.start_date = this.apiDate.start_date;
    popup.end_date = this.apiDate.end_date;
    popup.debug = this.popupForm.value.debug;
    popup.is_pro = this.popupForm.value.is_pro;
    popup.id_kdricarica_brand = this.popupForm.value.id_kdricarica_brand;
    


    if (this.selectedPopup) {
      return this.changePopup(popup);
    }
    return this.addPopup(popup);
  }

  changePopup(popup: Popup) {
    this.busy = this.popupService.putPopup(this.selectedPopup.id, popup).subscribe(
      (response: Popup) => {
        this.modalService.dismissAll();
        this.popupService.successAlert('Changed Banner Home!');
        this.loadPopups();
      },
      (err) => {
        this.popupService.errorAlert(err);
        this.modalService.dismissAll();
      },
    );
  }

  addPopup(popup: Popup) {
    this.busy = this.popupService.postPopup(popup).subscribe(
      (response: Popup) => {
        this.modalService.dismissAll();
        this.popupService.successAlert('Created');
        this.loadPopups();
      },
      (err) => {
        this.popupService.errorAlert(err);
        this.modalService.dismissAll();
      },
    );
  }

  deletePopup(id: number) {
    this.popupService.deletePopup(id).subscribe(
      () => {
        this.popupService.successAlert('Deleted Document!');
        this.loadPopups();
      },
      (err) => {
        this.popupService.errorAlert(err);
      },
    );
  }

  loadBrands(term?) {
    this.brandsLoading = true;
    const params = {};
    if (term) {
        params['name'] = term;
    }
    this.ePayService.getBrands(params).subscribe((response: ShopBrandPagination) => {
        this.brands = response.data;
        this.brandsLoading = false;
    }, err => {
        this.brandsLoading = false;
    });
}

  loadFormData() {
    this.popupForm.setValue({
      title: this.selectedPopup?.title,
      debug: this.selectedPopup?.debug,
      description: this.selectedPopup?.description,
      is_pro: this.selectedPopup?.is_pro,
      type: this.selectedPopup?.type || 'popup',
      start_date: this.selectedPopup.start_date ? moment(this.selectedPopup.start_date).format(this.dateRangePickerOptions.settings.locale.format) : '',
      end_date: this.selectedPopup.end_date ? moment(this.selectedPopup.end_date).format(this.dateRangePickerOptions.settings.locale.format) : '',
      id_kdricarica_brand: this.selectedPopup?.id_kdricarica_brand || '',
    });
    this.apiDate = {
      start_date: this.selectedPopup.start_date ? moment(this.selectedPopup.start_date).format(this.dateRangePickerOptions.settings.locale.apiFormat) : '',
      end_date: this.selectedPopup.end_date ? moment(this.selectedPopup.end_date).format(this.dateRangePickerOptions.settings.locale.apiFormat) : '',
    };
  }
}
