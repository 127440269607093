import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = environment.title;


    constructor(
        private daterangepickerOptions: DaterangepickerConfig,
        private translateService: TranslateService,
        private titleService: Title
    ) {
        this.titleService.setTitle(environment.title);
        this.translateService.use(environment.language);
        this.daterangepickerOptions.settings = {
            autoUpdateInput: false,
            alwaysShowCalendars: true,
            opens: 'left',
            maxSpan: {year: 2},
            showCustomRangeLabel: false,
            ranges: {
                'Ultimi 7 gg': [moment().subtract(6, 'days'), moment()],
                'Ultimi 30 gg': [moment().subtract(29, 'days'), moment()],
                'Questo Mese': [moment().startOf('month'), moment().endOf('month')],
                'Mese Scorso': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Ultimi 3 mesi': [moment().subtract(2, 'month').startOf('month'), moment().endOf('month')],
                'Ultimi 6 mesi': [moment().subtract(5, 'month').startOf('month'), moment().endOf('month')]
            },
            locale: {
                format: 'DD/MM/YYYY',
                apiFormat: 'YYYY-MM-DD',
                separator: ' - ',
                applyLabel: 'Applicare',
                cancelLabel: 'Annulla',
                fromLabel: 'A',
                toLabel: 'Da',
                customRangeLabel: 'Personalizzata',
                weekLabel: 'S',
                daysOfWeek: [
                    'Do',
                    'Lu',
                    'Ma',
                    'Me',
                    'Gi',
                    'Ve',
                    'Sa'
                ],
                monthNames: [
                    "Gennaio",
                    "Febbraio",
                    "Marzo",
                    "Aprile",
                    "Maggio",
                    "Giugno",
                    "Luglio",
                    "Agosto",
                    "Settembre",
                    "Ottobre",
                    "Novembre",
                    "Dicembre"
                ]
            }
        }
    }
}
