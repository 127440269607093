import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {StoreService} from '@services/store.service';
import {StoreCategory, StoreCategoryPagination} from '@models/store/store-category';
import {
    ProductSerialType,
    ProductSerialTypePagination, StoreBrand, StoreBrandPagination,
    StoreProduct,
    StoreProductPagination, StoreProductVariant, SubProduct
} from '@models/store/store-product';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-shop-product-modal',
    templateUrl: './shop-product-modal.component.html',
    styleUrls: ['./shop-product-modal.component.css']
})
export class ShopProductModalComponent implements OnInit {
    @Input() products: StoreProduct[] = [];
    @Input() selectedProduct: StoreProduct;
    busy: any;
    @ViewChild('variationFormTemplate') private variationFormTemplate;
    variationModal: NgbModalRef;
    standardProductForm = new FormGroup({
        name: new FormControl('', Validators.required),
        serial: new FormControl(''),
        sku: new FormControl(''),
        dealer_price: new FormControl('', Validators.required),
        dealer_pro_discount: new FormControl(0),
        credit_card_percentage: new FormControl(0, Validators.required),
        supplier: new FormControl(''),
        our_price: new FormControl(0),
        short_description: new FormControl(''),
        category_id: new FormControl('', Validators.required),
        brand_id: new FormControl('', Validators.required),
        image_name: new FormControl('', Validators.required),
        operator_id: new FormControl(''),
        serial_type_id: new FormControl(''),
        statement_type: new FormControl(''),
        standard_price: new FormControl(''),
        credit_card_price: new FormControl(''),
        description: new FormControl('', Validators.required),
        vat: new FormControl(''),
        virtual: new FormControl(''),
        shipment: new FormControl(''),
        visible: new FormControl(''),
        min_quantity_free_sped: new FormControl(''),
        compensation: new FormControl(''),
        quantity_per_unit: new FormControl('', [Validators.required, Validators.min(1)]),
        quantity_in_stock: new FormControl(0, [Validators.required, Validators.min(0)]),
        sub_products: new FormControl([]),
        variants: new FormControl([]),
    });
    splitProductForm = new FormGroup({
        name: new FormControl('', Validators.required),
        category_id: new FormControl('', Validators.required),
        brand_id: new FormControl('', Validators.required),
        image_name: new FormControl('', Validators.required),
        standard_price: new FormControl('', [Validators.required, Validators.pattern('([0-9]*[.])?[0-9]+')]),
        credit_card_price: new FormControl('', [Validators.required, Validators.pattern('([0-9]*[.])?[0-9]+')]),
        vat: new FormControl(''),
        virtual: new FormControl(''),
        shipment: new FormControl(''),
        visible: new FormControl(''),
        quantity_per_unit: new FormControl('', [Validators.required, Validators.min(1)]),
        quantity_in_stock: new FormControl('', [Validators.required, Validators.min(1)]),
        min_quantity_free_sped: new FormControl(''),
        description: new FormControl('', Validators.required),
        imei: new FormControl(''),
        installment_payable: new FormControl(''),
        installment_cost: new FormControl(''),
        installment_profit: new FormControl(''),
        credit_card_payable: new FormControl(''),
        credit_card_cost: new FormControl(''),
        credit_card_profit: new FormControl(''),
        sdd_bank_payable: new FormControl(''),
        sdd_bank_cost: new FormControl(''),
        sdd_bank_profit: new FormControl(''),
    });
    variantProductForm = new FormGroup({
        name: new FormControl(''),
        quantity: new FormControl('', Validators.required),
        price: new FormControl('', [Validators.required, Validators.pattern('([0-9]*[.])?[0-9]+')]),
        vat_price: new FormControl('', [Validators.required, Validators.pattern('([0-9]*[.])?[0-9]+')]),
        description: new FormControl(''),
    });
    defaultConfig = new TinyMCE();
    submittedFile: File;
    selectedProductType;
    categoriesLoading: boolean = false;
    serialTypesLoading: boolean = false;
    suppliersLoading: boolean = false;
    categories: StoreCategory[] = [];
    serialTypes: ProductSerialType[] = [];
    productTypes = StoreService.productTypes;
    operators = StoreService.operators;
    statementTypes = StoreService.statementTypes;
    paymentTypes = StoreService.paymentTypes;
    subProducts: StoreProduct[] = [];
    subProductsLoading = false;
    brandsLoading: boolean = false;
    brands: StoreBrand[] = [];
    currency = '€';
    disabledType = false;
    suppliers = StoreService.suppliers;



    constructor(public modalService: NgbModal,
                private storeService: StoreService) {
    }

    ngOnInit(): void {
        this.standardProductForm.addControl('payment_types', this.createPaymentsFormArray());
        this.splitProductForm.addControl('payment_types', this.createPaymentsFormArray());
        this.loadCategories();
        setTimeout(() => {
            this.loadSerialTypes();
            this.loadBrands();
            this.loadSubProducts();
            this.loadData();
        });
    }

    get paymentsArray(): FormArray {
        return this.standardProductForm?.get('payment_types') as FormArray;
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    saveStandardProduct() {
        const product = new StoreProduct();
        product.personalizzabile = this.selectedProductType;
        product.nome = this.standardProductForm.value.name;
        product.serial = this.standardProductForm.value.serial;
        product.dealer_price = this.standardProductForm.value.dealer_price;
        product.dealer_pro_discount = this.standardProductForm.value.dealer_pro_discount;
        product.credit_card_percentage = this.standardProductForm.value.credit_card_percentage;
        product.supplier = this.standardProductForm.value.supplier;
        product.our_price = this.standardProductForm.value.our_price;
        product.short_description = this.standardProductForm.value.short_description;
        product.sku = this.standardProductForm.value.sku;
        product.id_categoria = this.standardProductForm.value.category_id;
        product.id_brand = this.standardProductForm.value.brand_id;
        product.immagine_path = this.standardProductForm.value.image_name;
        product.file = this.submittedFile;
        product.id_operatore = this.standardProductForm.value.operator_id;
        product.id_seriale_tipo = this.standardProductForm.value.serial_type_id;
        product.tipo_estratto_conto = this.standardProductForm.value.statement_type;
        product.prezzo = this.standardProductForm.value.standard_price;
        product.prezzoacquisto = this.standardProductForm.value.credit_card_price;
        product.compenso = this.standardProductForm.value.compensation;
        product.min_quantita_free_sped = this.standardProductForm.value.min_quantity_free_sped;
        product.qty_per_unit = this.standardProductForm.value.quantity_per_unit ?
            Number(this.standardProductForm.value.quantity_per_unit) : null;
        product.quantita = this.standardProductForm.value.quantity_in_stock
            ? Number(this.standardProductForm.value.quantity_in_stock) : '0';
        product.iva = this.standardProductForm.value.vat ? 1 : '0';
        product.speciale = this.standardProductForm.value.virtual ? 1 : '0';
        product.spedizione = this.standardProductForm.value.shipment ? 1 : '0';
        product.visibile = this.standardProductForm.value.visible ? 1 : '0';
        product.descrizione = this.standardProductForm.value.description;
        product.sub_product_ids = [];
        product.variants = this.standardProductForm.value.variants;
        product.payment_types = this.getCheckedPaymentTypes(this.standardProductForm.controls.payment_types['controls']);
        this.selectedSubProducts.forEach(data => {
            product.sub_product_ids.push(data.id);
        });
        if (this.selectedProduct) {
            return this.changeProduct(product);
        }
        return this.addProduct(product);
    }

    saveSplitProduct() {
        const product = new StoreProduct();
        product.personalizzabile = this.selectedProductType;
        product.nome = this.splitProductForm.value.name;
        product.id_categoria = this.splitProductForm.value.category_id;
        product.id_brand = this.splitProductForm.value.brand_id;
        product.immagine_path = this.splitProductForm.value.image_name;
        product.file = this.submittedFile;
        product.prezzo = Number(this.splitProductForm.value.standard_price);
        product.prezzoacquisto = Number(this.splitProductForm.value.credit_card_price);
        product.min_quantita_free_sped = this.splitProductForm.value.min_quantity_free_sped;
        product.qty_per_unit = this.splitProductForm.value.quantity_per_unit;
        product.quantita = this.splitProductForm.value.quantity_in_stock;
        product.iva = this.splitProductForm.value.vat ? 1 : '0';
        product.speciale = this.splitProductForm.value.virtual ? 1 : '0';
        product.spedizione = this.splitProductForm.value.shipment ? 1 : '0';
        product.visibile = this.splitProductForm.value.visible ? 1 : '0';
        product.descrizione = this.splitProductForm.value.description;
        product.payment_types = this.getCheckedPaymentTypes(this.splitProductForm.controls.payment_types['controls']);
        if (!this.selectedProduct) {
            const information = this.generateProductInformationJson();
            product.informazioni = JSON.stringify(information);
        }
        if (this.selectedProduct) {
            return this.changeProduct(product);
        }
        return this.addProduct(product);
    }

    addProduct(product: StoreProduct) {
        this.busy = this.storeService.postProducts(product).subscribe((response: StoreProduct) => {
            this.products.unshift(response);
            this.storeService.successAlert('Created Product!');
            this.dismissModal();
        }, err => {
            this.storeService.errorAlert(err);
            this.dismissModal();
        });
    }

    changeProduct(product: StoreProduct) {
        this.busy = this.storeService.putProducts(this.selectedProduct.id, product).subscribe((response: StoreProduct) => {
            const index = this.products.findIndex(data => data.id === response.id);
            if (index >= 0) {
                this.products[index] = response;
            }
            this.storeService.successAlert('Changed Product!');
            this.dismissModal();
        }, err => {
            this.storeService.errorAlert(err);
            this.dismissModal();
        });
    }

    handleFileInput(file: File) {
        this.submittedFile = file;
        if (this.selectedProductType === this.productTypes[2].key) {
            this.splitProductForm.get('image_name').setValue(file.name);
        } else {
            this.standardProductForm.get('image_name').setValue(file.name);
        }

    }

    handleFileRemoveModal() {
        this.standardProductForm.get('image_name').setValue('');
        this.splitProductForm.get('image_name').setValue('');
        this.submittedFile = null;
    }

    loadCategories(term?) {
        this.categoriesLoading = true;
        const params = {};
        if (term) {
            params['nome'] = term;
        } else if (this.selectedProduct && this.selectedProduct?.id_categoria) {
            params['id'] = this.selectedProduct?.id_categoria;
        }
        this.storeService.getCategories(params).subscribe((response: StoreCategoryPagination) => {
            this.categories = response.data;
            this.categoriesLoading = false;
        }, err => {
            this.categoriesLoading = false;
        });
    }

    loadSerialTypes(term?) {
        this.serialTypesLoading = true;
        const params = {};
        if (term) {
            params['nome'] = term;
        } else if (this.selectedProduct && this.selectedProduct?.id_seriale_tipo) {
            params['id'] = this.selectedProduct?.id_seriale_tipo;
        }
        this.storeService.getSerialTypes(params).subscribe((response: ProductSerialTypePagination) => {
            this.serialTypes = response.data;
            this.serialTypesLoading = false;
        }, err => {
            this.serialTypesLoading = false;
        });
    }

    loadSubProducts(term?) {
        this.subProductsLoading = true;
        const params = {};
        if (term) {
            params['nome'] = term;
        }
        this.storeService.getProducts(params).subscribe((response: StoreProductPagination) => {
            this.subProducts = response.data;
            this.subProductsLoading = false;
        }, err => {
            this.subProductsLoading = false;
        });
    }

    loadBrands(term?) {
        this.brandsLoading = true;
        const params = {};
        if (term) {
            params['nome'] = term;
        } else if (this.selectedProduct && this.selectedProduct?.id_brand) {
            params['id'] = this.selectedProduct?.id_brand;
        }
        this.storeService.getBrands(params).subscribe((response: StoreBrandPagination) => {
            this.brands = response.data;
            this.brandsLoading = false;
        }, err => {
            this.brandsLoading = false;
        });
    }

    loadData() {
        if (this.selectedProduct) {
            this.selectedProductType = this.selectedProduct.personalizzabile.toString();
            if (this.selectedProductType === this.productTypes[2].key) {
                this.splitProductForm.get('name').setValue(this.selectedProduct.nome);
                this.splitProductForm.get('category_id').setValue(Number(this.selectedProduct.id_categoria));
                this.splitProductForm.get('brand_id').setValue(Number(this.selectedProduct.id_brand));
                this.splitProductForm.get('image_name').setValue(this.selectedProduct.immagine_path);
                this.splitProductForm.get('standard_price').setValue(this.selectedProduct.prezzo);
                this.splitProductForm.get('credit_card_price').setValue(this.selectedProduct.prezzoacquisto);
                this.splitProductForm.get('min_quantity_free_sped').setValue(this.selectedProduct.min_quantita_free_sped);
                this.splitProductForm.get('quantity_per_unit').setValue(this.selectedProduct.qty_per_unit);
                this.splitProductForm.get('quantity_in_stock').setValue(this.selectedProduct.quantita);
                this.splitProductForm.get('virtual').setValue(this.selectedProduct.speciale === 1
                    || this.selectedProduct.speciale === '1');
                this.splitProductForm.get('shipment').setValue(this.selectedProduct.spedizione === 1
                    || this.selectedProduct.spedizione === '1');
                this.splitProductForm.get('vat').setValue(this.selectedProduct.iva === 1 || this.selectedProduct.iva === '1');
                this.splitProductForm.get('vat').setValue(this.selectedProduct?.visibile ?
                    Number(this.selectedProduct?.visibile) === 1 : false);
                this.splitProductForm.get('description').setValue(this.selectedProduct.descrizione);
                this.splitProductForm.controls.payment_types['controls'].forEach((item, i) => {
                    const index = this.selectedProduct?.payment_types?.findIndex((data) => data?.name
                        === this.paymentTypes[i]?.key);
                    item.setValue(index >= 0);
                });
            } else {
                const list = [];
                if (this.selectedProduct?.sub_products) {
                    this.selectedProduct?.sub_products.forEach((item: SubProduct) => {
                        list.push(item.product);
                    });
                }
                this.standardProductForm.get('name').setValue(this.selectedProduct?.nome);
                this.standardProductForm.get('serial').setValue(this.selectedProduct?.serial);
                this.standardProductForm.get('sku').setValue(this.selectedProduct?.sku);
                this.standardProductForm.get('dealer_price').setValue(this.selectedProduct?.dealer_price);
                this.standardProductForm.get('dealer_pro_discount').setValue(this.selectedProduct?.dealer_pro_discount);
                this.standardProductForm.get('credit_card_percentage').setValue(this.selectedProduct?.credit_card_percentage);
                this.standardProductForm.get('supplier').setValue(this.selectedProduct?.supplier);
                this.standardProductForm.get('our_price').setValue(this.selectedProduct?.our_price);
                this.standardProductForm.get('short_description').setValue(this.selectedProduct?.short_description);
                this.standardProductForm.get('category_id').setValue(Number((this.selectedProduct?.id_categoria)));
                this.standardProductForm.get('brand_id').setValue(Number(this.selectedProduct?.id_brand));
                this.standardProductForm.get('image_name').setValue(this.selectedProduct?.immagine_path);
                this.standardProductForm.get('operator_id').setValue(this.selectedProduct?.id_operatore
                    ? this.selectedProduct.id_operatore.toString() : '');
                this.standardProductForm.get('serial_type_id').setValue(Number(this.selectedProduct?.id_seriale_tipo));
                this.standardProductForm.get('statement_type').setValue(this.selectedProduct?.tipo_estratto_conto
                    ? this.selectedProduct?.tipo_estratto_conto?.toString() : '');
                this.standardProductForm.get('standard_price').setValue(this.selectedProduct?.prezzo);
                this.standardProductForm.get('credit_card_price').setValue(this.selectedProduct?.prezzoacquisto);
                this.standardProductForm.get('compensation').setValue(this.selectedProduct?.compenso);
                this.standardProductForm.get('min_quantity_free_sped').setValue(this.selectedProduct?.min_quantita_free_sped);
                this.standardProductForm.get('quantity_per_unit').setValue(this.selectedProduct?.qty_per_unit);
                this.standardProductForm.get('quantity_in_stock').setValue(this.selectedProduct?.quantita);
                this.standardProductForm.get('vat').setValue(this.selectedProduct?.iva ?
                    Number(this.selectedProduct?.iva) === 1 : false);
                this.standardProductForm.get('virtual').setValue(this.selectedProduct?.speciale ?
                    Number(this.selectedProduct?.speciale) === 1 : false);
                this.standardProductForm.get('shipment').setValue(this.selectedProduct?.spedizione ?
                    Number(this.selectedProduct?.spedizione) === 1 : false);
                this.standardProductForm.get('visible').setValue(this.selectedProduct?.visibile ?
                    Number(this.selectedProduct?.visibile) === 1 : false);
                this.standardProductForm.get('description').setValue(this.selectedProduct?.descrizione);
                this.standardProductForm.get('sub_products').setValue(list ? list : []);
                this.standardProductForm.get('variants').setValue(this.selectedProduct?.variants ? this.selectedProduct?.variants : []);
                this.standardProductForm.controls.payment_types['controls'].forEach((item, i) => {
                    const index = this.selectedProduct?.payment_types?.findIndex((data) => data?.name
                        === this.paymentTypes[i]?.key);
                    item.setValue(index >= 0);
                });
            }
        } else {
            this.splitProductForm.controls.payment_types['controls'].forEach((item, i) => {
                item.setValue(i < 2);
            });
            this.standardProductForm.controls.payment_types['controls'].forEach((item, i) => {
                item.setValue(i < 2);
            });
        }
    }

    removeSelectedSubProduct(id: any) {
        if (id) {
            const index = this.selectedSubProducts.findIndex(data => data.id === id);
            if (index >= 0) {
                this.selectedSubProducts.splice(index, 1);
                this.standardProductForm.get('sub_products').setValue(this.selectedSubProducts);
            }
        }
    }

    removeVariant(index) {
        if (index >= 0) {
            this.variants.splice(index, 1);
            this.standardProductForm.get('variants').setValue(this.variants);
        }
    }

    showVariantModal() {
        this.refreshVariantForm();
        this.variationModal = this.modalService.open(this.variationFormTemplate, {size: 'md'});
        this.variationModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    calculateVatPrice() {
        if (this.variantProductForm.controls['price'].value && !this.variantProductForm.controls['vat_price'].value) {
            const price = Number(this.variantProductForm.controls['price'].value);
            this.variantProductForm.get('vat_price').setValue(price > 0 ? (22 * price / 100).toFixed(2) : '');
        }
    }

    generateProductInformationJson() {
        return {
            imei: this.splitProductForm.value.imei,
            offerte: null,
            custom: {
                'method_1': {
                    'Rateizzabile in (N° Rate)': this.splitProductForm.value.installment_payable,
                    'Costo Rata/Mese': this.splitProductForm.value.installment_cost,
                    'Profitto dealer su rateizzazione': this.splitProductForm.value.installment_profit,
                    'Entry Ticket': 0
                },
                'method_2': {
                    'Rateizzabile in (N° Rate)': this.splitProductForm.value.credit_card_payable,
                    'Costo Rata/Mese': this.splitProductForm.value.credit_card_cost,
                    'Profitto dealer su rateizzazione': this.splitProductForm.value.credit_card_profit,
                    'Entry Ticket': 0
                },
                'method_3': {
                    'Rateizzabile in (N° Rate)': this.splitProductForm.value.sdd_bank_payable,
                    'Costo Rata/Mese': this.splitProductForm.value.sdd_bank_cost,
                    'Profitto dealer su rateizzazione': this.splitProductForm.value.sdd_bank_profit,
                    'Entry Ticket': 0
                }
            },
            test_mode: false
        };
    }

    saveVariation() {
        const variant = new StoreProductVariant();
        variant.name = this.variantProductForm.value.name;
        variant.price = this.variantProductForm.value.price;
        variant.vat_price = this.variantProductForm.value.vat_price;
        variant.quantity = this.variantProductForm.value.quantity;
        variant.description = this.variantProductForm.value.description;
        const variations = this.variants;
        variations.unshift(variant);
        this.standardProductForm.get('variants').setValue(variations);
        this.variationModal.dismiss();
    }

    refreshVariantForm() {
        this.variantProductForm.setValue({
            name: '',
            quantity: '',
            price: '',
            vat_price: '',
            description: '',
        });
    }

    get imageName() {
        return this.standardProductForm.get('image_name').value;
    }

    get imageNameProduct() {
        return this.splitProductForm.get('image_name').value;
    }

    get selectedSubProducts() {
        return this.standardProductForm.get('sub_products').value as StoreProduct[];
    }

    get variants() {
        return this.standardProductForm.get('variants').value as StoreProductVariant[];
    }

    createPaymentsFormArray() {
        const arr = this.paymentTypes.map(hobby => {
            return new FormControl();
        });
        return new FormArray(arr);
    }

    getCheckedPaymentTypes(array: any[] = []) {
        return array?.map((type, i) => {
            return type.value && this.paymentTypes[i].key;
        })?.filter((item) => item);
    }
}
