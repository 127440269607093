<div class="card bg-gradient-dark" *hideDashboard>
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="../../../assets/img/flats/help.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Tickets</h1>
                <hr>
                <div class="row align-items-end">
                    <div class="col-lg-4 col-12"><label for="validityPeriod" translate>Select date</label>
                        <input id="validityPeriod" class="form-control" type="text" daterangepicker
                               [(ngModel)]="selectedDate"
                               [options]="dateOptions" (selected)="loadNewDate($event?.start, $event?.end)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *showDashboard>
    <h1>Riepilogo Tickets</h1>
    <hr>
</div>

<div class="row">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name='report'
            [fullScreen]="false"
    >
    </ngx-spinner>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/calendar_2.png" height="64"/>
                        <p class="lead mt-2">Ticket inseriti oggi</p>
                        <h1 class="display-5">{{ report ? report?.today.total : 0}}</h1>
                    </div>
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/checked.png" height="64"/>
                        <p class="lead mt-2">Ticket gestiti oggi</p>
                        <h1 class="display-5">{{ report ? report?.today.handled : 0}}</h1>
                    </div>
                    <div class="col-4 text-center">
                        <img src="/assets/img/flats/time.png" height="64"/>
                        <p class="lead mt-2">Ticket da gestire</p>
                        <h1 class="display-5">{{ report ? report?.today.to_handle : 0}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/settings_2.png" height="64"/>
                        <p class="lead mt-2">Ancora in gestione</p>
                        <h1 class="display-5">{{ report ? report?.total.in_handle : 0}}</h1>
                    </div>
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/reply.png" height="64"/>
                        <p class="lead mt-2">In attesa di risposta nostra</p>
                        <h1 class="display-5">{{ report ? report?.total.waiting_our_reply : 0}}</h1>
                    </div>
                    <div class="col-4 text-center">
                        <img src="/assets/img/flats/chat.png" height="64"/>
                        <p class="lead mt-2">In attesa di risposta loro</p>
                        <h1 class="display-5">{{ report ? report?.total.waiting_their_reply : 0}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="card">
            <div class="card-header bg-gradient-dark">
                <h1 class="card-title">Classifica Backoffice</h1>
            </div>
            <div class="card-body p-0">
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                        <th>Operatore</th>
                        <th class="text-center">Gestiti Oggi</th>
                        <th class="text-center">In gestione</th>
                        <th class="text-center">Ticket a cui deve rispondere</th>
                        <th class="text-center">Tempo Medio di Gestione</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of (report ? report.ranking : [])">
                        <td>{{row.operatore}}</td>
                        <td class="text-center">{{row.today_handled}}</td>
                        <td class="text-center" [ngClass]="{'text-danger text-bold': row.in_handle >= 10}">{{row.in_handle}}</td>
                        <td class="text-center">{{row.waiting_our_reply}}</td>
                        <td class="text-center">{{row.handling_time | timeToText}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-header bg-gradient-dark">
                <h1 class="card-title">Classifica Dealers</h1>
            </div>
            <div class="card-body p-0">
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                        <th>Dealer</th>
                        <th class="text-center">Ticket Inseriti</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of (report ? report.dealer_ranking : [])">
                        <td>{{row.dealer}}</td>
                        <td class="text-center">{{row.totale}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>

