import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { User } from '@models/user';
import { RequestService } from '@services/request.service';
import { AuthenticationService } from '@services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RendicontiAddebito } from '@models/rendiconti-addebito';
import { RendicontiAddebitoService } from '@services/rendiconti-addebito.service';
import { Moment } from 'moment/moment';
import * as moment from 'moment/moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';

@Component({
  selector: 'app-rendiconti-addebito',
  templateUrl: './rendiconti-addebito.component.html',
  styleUrls: ['./rendiconti-addebito.component.scss']
})
export class RendicontiAddebitoComponent implements OnInit {
  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };
  ordersPagination: MetaPagination;
  listItem : RendicontiAddebito[] = [];
  isDealer = false;
  isEdit = true;

  filter = {
    id: '',
    company_name: '',
    dealer_id: '',
    created_at: '',
    date: '',
    number_doc:'',
  };

  apiDate = {
    from: '',
    to: '',
  };



  constructor(
    public request: RequestService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private dateRangePickerOptions: DaterangepickerConfig,
    public service: RendicontiAddebitoService,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
    this.isDealer = user && user.profile.role === 'dealer';
  });
  }

  ngOnInit(): void {
    this.loadList();
  }


  loadList(){
    this.spinner.show('table-orders');

    const params = {};

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }

    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }

    if (this.filter.number_doc) {
      params['number_doc'] = this.filter.number_doc;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }


    this.service.getList(params).subscribe(
      (response: any) => {
        this.listItem = response;
        this.listItem = new RendicontiAddebito().fromArray(response.data);
        this.ordersPagination = response._meta;

        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  watchFile(pdfUrl){
    window.open(pdfUrl, '_blank');
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

}
