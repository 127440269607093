import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { ShopBrand } from '@models/e-pay/shop-brand';
import { AuthenticationService } from '@services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { User } from '@models/user';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as XLSX from 'xlsx';
import { SddService } from '@services/sdd.service';
import { Sdd } from '@app/models/sdd';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { RequestService } from '@app/services/request.service';

@Component({
    selector: 'app-sdd',
    templateUrl: './sdd.component.html',
    styleUrls: ['./sdd.component.css'],
})
export class SddComponent implements OnInit {
    sddOrders: Sdd[] = [];
    ordersPagination: MetaPagination = new MetaPagination();
    deleteSubscriptionOptions: ConfirmOptionsService;
    selectedDealerForContract: number = null;

    @ViewChild('sddFormTemplate') private sddFormTemplate;
    @ViewChild('generateContractTemplate') private generateContractTemplate;

    rangeOptions = {
        singleDatePicker: true,
        ranges: [],
    };

    apiDate = '';
    busy: any;
    insertData: Sdd;

    filter = {
        id: '',
        dealer_id: '',
        company_name: '',
        created_by: '',
        update_by: '',
        iban: '',
        iban_type: '',
        payments_type: '',
        status: '',
        type: '',
        signature_date: '',
        created_at: '',
        updated_at: '',
        date: '',
    };

    sddTypesStatus = Sdd.sddTypes;
    sddStausPair = Sdd.sddStatus;
    sddIbanTypeStatus = Sdd.ibanTypes;
    sddPaymentsTypeStatus = Sdd.paymentsTypes;

    @ViewChild('popContent') private popContent;
    popupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };

    @ViewChild('receiptFormTemplate') private receiptFormTemplate;
    brandsLoading = false;
    brands: ShopBrand[] = [];
    isDealer = false;
    isEdit = true;

    constructor(
        public request: RequestService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        private dateRangePickerOptions: DaterangepickerConfig,
        private sddService: SddService,
        public route: ActivatedRoute,
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.isDealer = user && user.profile.role === 'dealer';
        });

        this.deleteSubscriptionOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla',
        });
    }

    ngOnInit(): void {
        this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
        this.loadOrders();
        setTimeout(() => {});
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    loadOrders(hideSpinner = false) {
        this.spinner.show('table-orders');
        const params = {};

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }
        if (this.filter.company_name) {
            params['companyName'] = this.filter.company_name;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        if (this.filter.iban) {
            params['iban'] = this.filter.iban;
        }
        if (this.filter.payments_type) {
            params['payments_type'] = this.filter.payments_type;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.iban_type) {
            params['iban_type'] = this.filter.iban_type;
        }
        if (this.filter.date) {
            params['signature_date'] = this.apiDate;
        }

        this.sddService.getSddOrders(params).subscribe(
            (response: any) => {
                this.sddOrders = new Sdd().fromArray(response.data);
                this.ordersPagination = response._meta;
                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    selectedDate(date: Moment) {
        this.filter.date = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.apiDate = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    }

    deleteSddSubscription(id: number) {
        this.sddService.deleteSddSubscription(id).subscribe(
            () => {
                const index = this.sddOrders.findIndex((dealer) => dealer.id === id);
                if (index > -1) {
                    this.sddService.successAlert('Deleted Service!');
                    this.loadOrders();
                }
            },
            (err) => {
                this.sddService.errorAlert(err.message);
            },
        );
    }

    showModal(data?: Sdd) {
        this.insertData = data;
        this.modalService.open(this.sddFormTemplate, { size: data ? 'md' : 'lg' });
    }

    showContractModal() {
        this.modalService.open(this.generateContractTemplate, { size: 'md' });
    }

    exportSdd() {
        this.spinner.show('table-dealers');
        const params = {
            'per-page': 0,
        };

        if (this.filter.company_name) {
            params['companyName'] = this.filter.company_name;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        if (this.filter.iban) {
            params['iban'] = this.filter.iban;
        }
        if (this.filter.payments_type) {
            params['payments_type'] = this.filter.payments_type;
        }
        if (this.filter.iban_type) {
            params['iban_type'] = this.filter.iban_type;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.date) {
            params['signature_date'] = this.apiDate;
        }

        this.sddService.getSddOrders(params).subscribe((data) => {
            this.spinner.hide('table-dealers');
            let rows = data.data;

            let output = rows.map((row) => {
                const stato = Sdd.sddStausPair[row.status] || ''; // Utilizza la mappatura
                return {
                    'Dealer ID': row.dealer_id,
                    'Ragione Sociale': row.company_name,
                    iban: row.iban,
                    'Tipo di iban': row.iban_type,
                    'Tipo pagamento': row.payments_type,
                    'Tipo di modulo': row.type,
                    'Data Firma': row.signature_date,
                    stato: stato,
                    'Data di creazione': row.created_at,
                };
            });

            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'export-sdd.xlsx');
        });
    }

    save(value) {
        const body = {};
        body[this.popupConfig['key']] = value;
        this.spinner.show('popupSpinner');
        return this.changeOrder(this.popupConfig.id, body);
    }

    changeOrder(orderId, body) {
        this.sddService.putSddOrder(orderId, body).subscribe(
            (response) => {
                this.loadOrders(true);
                this.spinner.hide('popupSpinner');
            },
            (err) => {
                this.loadOrders(true);
                this.modalService.dismissAll();
                this.spinner.hide('popupSpinner');
            },
        );
    }

    openPopover(popOver: any, orderId, item, key, selectItems = []) {
        this.popupConfig.inputValue = item;
        this.popupConfig.id = orderId;
        this.popupConfig.key = key;
        this.popupConfig.popover = popOver;
        this.popupConfig.isSelect = selectItems?.length > 0;
        this.popupConfig.items = selectItems;
        popOver.open();
    }

    setDealerForContract(event) {
        this.selectedDealerForContract = event;
    }

    generateContract() {
        console.log('generate contract');
        console.log('dealer', this.selectedDealerForContract);

        this.sddService.getSddContract(this.selectedDealerForContract).subscribe(
            (res) => {
                const pdfBase64 = res.pdfBase64;
                const byteCharacters = atob(pdfBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            },

            (error) => {
                console.error('Errore durante la generazione del contratto.', error);
            },
        );
    }
}
