import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';
import {DealerView} from '@models/dealer-view';

export class GiftCard extends BaseModel {
    
    id: number;
    dealer_id: number;
    company_name: string;
    transaction_id: number;
    name: string;
    dealer_price: string;
    our_price: string;
    customer_price: string;
    status: string;
    created_at: string;
    created_by: number;


    fromArray(result: []): GiftCard[] {
        const array: GiftCard[] = [];
        for (const item of result) {
            array.push(new GiftCard(item));
        }
        return array;
    }

}

export class GiftCardPagination extends BaseModel {
    data: GiftCard[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class GiftCardProvider extends BaseModel {
    data: GiftCard[];
    _meta: MetaPagination;
    _links: Array<any>;
}