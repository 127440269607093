import { Component, OnInit, ViewChild } from '@angular/core';
import { KdTax } from '@app/models/kd-tax';
import { MetaPagination } from '@app/models/meta-pagination';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { KdOrderService } from '@app/services/kd-order.service';
import { KdTaxService } from '@app/services/kd-tax.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-kd-tax',
  templateUrl: './kd-tax.component.html',
  styleUrls: ['./kd-tax.component.css'],
})
export class KdTaxComponent implements OnInit {
  ordersPagination: MetaPagination = new MetaPagination();

  kdTaxAdvices: KdTax[] = [];

  kdTaxStatus = KdTax.kdTaxTypes;

  deleteSubscriptionOptions: ConfirmOptionsService;

  isEdit = true;

  filter = {
    id: '',
    dealer_id: '',
    company_name: '',
    status: '',
    created_at: '',
    date: '',
  };

  apiDate = {
    from: '',
    to: '',
  };

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  @ViewChild('popContent') private popContent;
  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };

  constructor(
    private spinner: NgxSpinnerService,
    private kdTaxService: KdTaxService,
    private kdOrderService: KdOrderService,
    private dateRangePickerOptions: DaterangepickerConfig,
  ) {}

  ngOnInit() {
    this.loadAdvices();
  }

  loadAdvices(hideSpinner = false) {
    this.spinner.show('table-orders');
    const params = {};

    params['service'] = 'kd-tax';

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.kdTaxService.getAdvices(params).subscribe(
      (response: any) => {
        this.kdTaxAdvices = new KdTax().fromArray(response.data);

        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date =
      startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  openPopover(popOver: any, orderId, item, key, selectItems = []) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }


  exportSdd() {
    this.spinner.show('table-dealers');
    const params = {
      'per-page': 0,
    };

    params['service'] = 'kd-tax';

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['created_at'] = this.filter.created_at;
    }

    this.kdTaxService.getAdvices(params).subscribe((data) => {
      this.spinner.hide('table-dealers');

      let rows = data.data;

      let output = rows.map((row) => {
        return {
          Id: row.id,
          'Ragione Sociale': row.company_name,
          Note: row.notes,
          Data: row.created_at,
          Stato: row.status,
        };
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-kdtax.xlsx');
    });
  }

  save(value) {
    const body = {};
    body[this.popupConfig['key']] = value;
    this.spinner.show('popupSpinner');
    return this.changeAdvice(this.popupConfig.id, body);
  }

  changeAdvice(orderId, body) {
    this.kdOrderService.putKdTaxAdvice(orderId, body).subscribe(
      (response) => {
        this.loadAdvices(true);
        this.spinner.hide('popupSpinner');
      },
      (err) => {
        this.loadAdvices(true);
        this.spinner.hide('popupSpinner');
      },
    );
  }
}
