import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MasterPlanningCalculationService} from '@services/master-planning-calculation.service';
import {MasterPlanningService} from '@services/master-planning.service';
import * as moment from 'moment';
import {forEach} from '@angular-devkit/schematics';

@Component({
    selector: 'app-agents-performance',
    templateUrl: './agents-performance.component.html',
    styleUrls: ['./agents-performance.component.css']
})
export class AgentsPerformanceComponent implements OnInit {
    @ViewChild('scheduleTemplate') private scheduleTemplate;
    @Input() isInSchedule = false;
    @Input() targetType = '';
    @Input() filter;
    @Input() monthsList = [];
    @Input() breakDownTypes = [];
    selectedMonths;
    private modal: NgbModalRef;
    agents = [];
    agentsFilter = [];
    targetTypesList = {affiliations: [], sales: [], revenue: []};
    inScheduleText = 'You are in line with the schedule';
    outOfScheduleText = 'You are not in line with the schedule';
    filtersList = [{title: 'All', value: 'all'}, {title: 'Under performancing', value: 'under'}, {
        title: 'Above performancing',
        value: 'above'
    }];
    filterPerformance = {
        agentId: null,
        performance: this.filtersList[0].value
    };
    title: string;
    busy: any;
    sortAsc = true;

    constructor(public modalService: NgbModal,
                private masterPlanningService: MasterPlanningService,
                private masterPlanningCalculationService: MasterPlanningCalculationService) {

    }

    ngOnInit(): void {
    }
    openModel() {
        this.agentsFilter = this.agents;
        this.modal = this.modalService.open(this.scheduleTemplate, {size: 'xl', centered: true});
        if (!this.targetTypesList[this.targetType] || this.selectedMonths !== this.monthsList) {
            this.agents = [];
            this.getAgentsPerformance();
        }
    }

    getAgentsPerformance(sortAsc = true) {
        const param = {
            from: this.filter.dateRange.start,
            to: this.filter.dateRange.end,
            target_type: this.targetType
        };
        this.busy = this.masterPlanningService.getAgentsPerformance(param).subscribe((response) => {
                this.targetTypesList[this.targetType] = response;
                this.agents = response;
                this.agentsFilter = response;
                this.selectedMonths = this.monthsList;
                this.loadAgentsPerformance();
            },
            error => {
                this.masterPlanningService.errorAlert(error);
            });
    }

    setFormat(num) {
        if (this.targetType !== 'revenue') {
            return this.formatNumber(num);
        }
        return this.currencyFormat(num);
    }

    public formatNumber(num) {
        return this.masterPlanningCalculationService.formatNumber(num);
    }

    public currencyFormat(num) {
        return this.masterPlanningCalculationService.currencyFormat(num);
    }

    formatMonth(date) {
        return this.masterPlanningCalculationService.formatMonth(date);
    }

    isCurrentMonth(month) {
        return moment().isSame(month, 'month');
    }

    setProjectionValue(value) {
        return this.masterPlanningCalculationService.calculateProjectionValue(value);
    }

    filterByName(id: number) {
        this.filter.agentId = id;
        this.loadFilter();
    }

    loadAgentsPerformance() {
        this.agents.forEach(data => {
            let index = 0;
            this.monthsList.forEach(month => {
                if (this.isCurrentMonth(month) && data.dates[month].plan
                    && Number(data.dates[month].plan.value) > Number(this.setProjectionValue(data.dates[month].real))) {
                    index++;
                } else if (data.dates[month].plan && Number(data.dates[month].plan.value) > Number(data.dates[month].real)) {
                    index++;
                }
            });
            data.isUnder = index > 0;
        });
    }

    loadFilter() {
        this.agentsFilter = this.agents;
        if (this.filter.agentId) {
            this.agentsFilter = this.agentsFilter.filter(data => data.id === this.filter.agentId);
        }
        if (this.filter.performance && this.filter.performance !== 'all') {
            const isUnder = this.filter.performance && this.filter.performance === 'under';
            this.agentsFilter = this.agentsFilter.filter(data => data.isUnder === isUnder);
        }
    }

    encodeName(name: string) {
        return name.split('_').join(' ');
    }
}
