<div class="card" [ngClass]="cardClass">
    <div class="card-header" [ngClass]="headerClass" *ngIf="title">
        <h1 class="display-6 mb-0">
            <span translate>{{this.title}}</span>
            <img class="float-right" *ngIf="this.image" [src]="this.image" height="32px">
        </h1>
    </div>
    <div class="card-body" [ngClass]="bodyClass" [style.min-height.px]="height">
        <ng-content></ng-content>
    </div>
</div>
