import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';
import { Amex, SpidCredit, SpidCreditPagination, SpidCreditReport } from '@app/models/kd-orders';
import { map } from 'rxjs/operators';

const v2Version = 'v2';
const v3Version = 'v3';

@Injectable({
  providedIn: 'root',
})
export class KdOrderService {

  constructor(private request: RequestService, public alertService: AlertService) { }

  public getAmexOrders(params?): Observable<any> {
    return this.request.get('v2/amex/orders', params);
  }

  public putAmexOrder(id, body: {}) {
    return this.request.put(v2Version + '/amex/update/' + id, body);
  }

  public putKdTaxAdvice(id, body: {}) {
    return this.request.put(v3Version + '/application/update/' + id, body);
  }

  public amexUploadFile(formData) {
    return this.request.post('v2/amex/file', formData);
  }

  public getReadyMail(params?): Observable<any> {
    return this.request.get('v3/ready-mail/order-list', params);
  }

  public getDigitalSignatures(params?): Observable<any> {
    return this.request.get(v2Version + '/digital-signature/orders', params);
  }

  public getBookingOrdes(params?): Observable<any> {
    return this.request.get(v2Version + '/digital-signature/booked-orders', params);
  }

  public getSpidCredit(params?): Observable<any> {
    return this.request.get('spid-credit/index', params);
  }

  public putDealerCredits(id, body: SpidCredit): Observable<SpidCredit> {
    return this.request.put('spid-credit/' + id, body).pipe(
      map((response: any) => {
        return new SpidCredit(response);
      }),
    );
  }

  public postDealerCredits(body: SpidCredit): Observable<SpidCredit> {
    return this.request.post('spid-credit', body).pipe(
      map((response: any) => {
        return new SpidCredit(response);
      }),
    );
  }

  public getCredits(params?): Observable<SpidCreditPagination> {
    return this.request.get('spid-credit/transaction', params).pipe(
      map((response: any) => {
        return new SpidCreditPagination(response);
      }),
    );
  }

  public getCreditReport(params?): Observable<SpidCreditReport> {
    return this.request.get('spid-credit/report', params).pipe(
      map((response: any) => {
        return new SpidCreditReport(response);
      }),
    );
  }

  public deleteCredit(id: number) {
    return this.request.delete('spid-credit' + '/' + id);
  }

  public successAlert(text) {
    this.alertService.show('', 'Report ' + text, { classname: 'bg-success text-light', delay: 2000 });
  }

  public errorAlert(message?) {
    this.alertService.show('An error occurred!', message ? message : 'Try again!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }

  public getPostalSlip(params?): Observable<any> {
    return this.request.get(v2Version + '/kd-pay/orders', params);
  }

  public getCertificates(params?): Observable<any> {
    return this.request.get(v2Version + '/kd-visure/order-list', params);
  }

  public getShipping(params?): Observable<any> {
    return this.request.get(v2Version + '/kd-speedy/order', params);
  }

  public getPackages(params?): Observable<any> {
    return this.request.get(v2Version + '/package/orders', params);
  }

  public getPrint(params?): Observable<any> {
    return this.request.get(v2Version + '/kd-print/order-list', params);
  }

  public getPec(params?): Observable<any> {
    return this.request.get(v2Version + '/kd-pec/orders', params);
  }

  public getTickets(params?): Observable<any> {
    return this.request.get(v2Version + '/kd-ticket/order', params);
  }
}
