import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { SuretyService } from '@services/surety.service';
import { DealerSuretyCredit, DealerSuretyCreditPagination, SuretyCredit, SuretyCreditPagination, SuretyCreditReport } from '@models/surety';
import { MetaPagination } from '@models/meta-pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment/moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';

@Component({
  selector: 'app-surety-credit',
  templateUrl: './surety-credit.component.html',
  styleUrls: ['./surety-credit.component.css'],
})
export class SuretyCreditComponent implements OnInit, OnDestroy {
  currency = '€';
  dealerId = null;
  suretyTypes: { label: string; value: string }[] = [
    {
      label: 'Kena',
      value: 'plafond-kena',
    },
    {
      label: 'Prodotti',
      value: 'prodotti',
    },
  ];

  totalCurrentCreditsSum: number = 0;

  suretyTypeSelectedValue: string = null;
  suretyTypeSelectedLabel: string = null;

  report: SuretyCreditReport;
  deleteCreditOptions: ConfirmOptionsService;
  pagination: MetaPagination;

  credits: SuretyCredit[] = [];

  transactionModal: NgbModalRef;
  @ViewChild('transactionTemplate') private transactionTemplate;
  @ViewChild('detailTemplate') private detailTemplate;

  selectedCredit: SuretyCredit;
  transactionForm = new FormGroup({
    amount: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
  });

  dealers: DealerSuretyCredit[] = [];

  filterDate = '';
  apiDate = {
    from: null,
    to: null,
  };
  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };

  transactionBusy: any;

  constructor(
    private spinner: NgxSpinnerService,
    private suretyService: SuretyService,
    public modalService: NgbModal,
    public route: ActivatedRoute,
    private dateRangePickerOptions: DaterangepickerConfig,
    private router: Router,
  ) {
    let idParam = this.route.snapshot.paramMap.get('id');
    let suretyTypeParam = this.route.snapshot.paramMap.get('surety_type');

    this.dealerId = idParam !== null ? idParam : null;
    this.suretyTypeSelectedValue = suretyTypeParam !== null ? suretyTypeParam : null;

    let selectedType = this.suretyTypes.find((type) => type.value === suretyTypeParam);
    this.suretyTypeSelectedLabel = selectedType ? selectedType.label : '';

    this.deleteCreditOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro!',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
    });
  }

  ngOnInit(): void {
    if (this.dealerId) {
      this.loadTransactions();
    }
  }

  loadReport(dealerId?) {
    this.spinner.show('report');
    const params = {};
    if (this.pagination?.currentPage) {
      params['page'] = this.pagination?.currentPage;
    }
    if (dealerId) {
      params['dealer_id'] = dealerId;
    }
    if (this.suretyTypeSelectedValue) {
      params['surety_type'] = this.suretyTypeSelectedValue;
    }
    this.suretyService.getCreditReport(params).subscribe(
      (response: SuretyCreditReport) => {
        this.report = response;
        this.spinner.hide('report');
      },
      (err) => {
        this.spinner.hide('report');
      },
    );
  }

  loadDealers(dealerId?) {
    this.spinner.show('table-credit');
    const params = {};
    if (this.pagination?.currentPage) {
      params['page'] = this.pagination?.currentPage;
    }
    if (dealerId) {
      params['dealer_id'] = dealerId;
    }
    if (this.suretyTypeSelectedValue) {
      params['surety_type'] = this.suretyTypeSelectedValue;
    }
    this.suretyService.getDealersWithCredit(params).subscribe(
      (response: DealerSuretyCreditPagination) => {
        this.dealers = response.data;
        this.pagination = response._meta;
        this.spinner.hide('table-credit');
      },
      (err) => {
        this.spinner.hide('table-credit');
      },
    );
  }

  loadTransactions() {
    this.spinner.show('table-credit');
    const params = {
      dealer_id: this.dealerId,
      surety_type: this.suretyTypeSelectedValue,
    };
    if (this.pagination?.currentPage) {
      params['page'] = this.pagination?.currentPage;
    }
    if (this.apiDate?.from && this.apiDate?.to) {
      params['from'] = this.apiDate?.from;
      params['to'] = this.apiDate?.to;
    }
    this.suretyService.getCredits(params).subscribe(
      (response: SuretyCreditPagination) => {
        this.credits = new SuretyCredit().fromArray(response.data);
        this.pagination = response._meta;
        this.spinner.hide('table-credit');
      },
      (error) => {
        this.spinner.hide('table-credit');
      },
    );
  }

  showModal(credit?: SuretyCredit) {
    this.selectedCredit = credit;
    this.transactionForm.setValue({
      amount: credit ? Number(credit?.amount) : '',
      type: credit ? credit?.type : '',
    });
    this.transactionModal = this.modalService.open(this.transactionTemplate, { size: 'md' });
    this.transactionModal.result.then((res) => {}).catch((res) => {});
  }

  selectDealer(event) {
    this.dealerId = event;
    if (this.dealerId !== null) {
      this.loadReport(this.dealerId);
      setTimeout(() => {
        this.loadDealers(this.dealerId);
      });
    } else {
      this.dealerId = null;
    }
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filterDate = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    return this.loadTransactions();
  }

  clearDateFilter() {
    this.filterDate = null;
    this.apiDate.from = null;
    this.apiDate.to = null;
    return this.loadTransactions();
  }

  changeTransaction(credit: SuretyCredit) {
    return this.selectedCredit ? this.suretyService.putDealerCredits(this.selectedCredit?.id, credit) : this.suretyService.postDealerCredits(credit);
  }

  saveTransaction() {
    const credit = new SuretyCredit();
    credit.amount = this.transactionForm.value.amount;
    credit.type = this.transactionForm.value.type;
    credit.dealer_id = this.dealerId;
    this.transactionBusy = this.changeTransaction(credit).subscribe(
      (response: SuretyCredit) => {
        this.loadTransactions();
        this.transactionForm.reset();
        this.suretyService.successAlert('Changed Transaction!');
        this.modalService.dismissAll();
      },
      (err) => {
        this.transactionForm.reset();
        this.suretyService.errorAlert(err);
        this.modalService.dismissAll();
      },
    );
  }

  removeTransaction(id: number) {
    this.suretyService.deleteCredit(id).subscribe(
      () => {
        this.loadTransactions();
        this.suretyService.successAlert('Removed Transaction!');
      },
      (err) => {
        this.suretyService.errorAlert(err);
      },
    );
  }

  save(value) {
    const body = {};
    body[this.popupConfig.key] = value;
    this.spinner.show('popupSpinner');

    const credit = this.credits.find((item) => item.id === this.popupConfig.id);
    credit.welded = value;

    this.suretyService.putDealerCredits(credit.id, credit).subscribe(
      (response) => {
        this.spinner.hide('popupSpinner');
        this.popupConfig.popover.close();
        this.loadTransactions();
        this.loadReport(this.dealerId);
      },
      (err) => {
        this.spinner.hide('popupSpinner');
        this.popupConfig.popover.close();
      },
    );
  }

  openPopover(popOver, credit: SuretyCredit) {
    this.popupConfig.inputValue = credit.welded;
    this.popupConfig.id = credit.id;
    this.popupConfig.key = 'welded';
    this.popupConfig.type = 'welded';
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = true;
    this.popupConfig.items = [
      {
        label: 'Da saldare',
        key: 0,
      },
      {
        label: 'Saldato',
        key: 1,
      },
    ];
    popOver.open();
  }

  onSuretyChange() {
    if (this.suretyTypeSelectedValue == null) {
      this.dealers = null;
      this.suretyTypeSelectedLabel = null;
      this.suretyTypeSelectedValue = null;
    } else {
      let selectedType = this.suretyTypes.find((type) => type.value === this.suretyTypeSelectedValue);
      this.suretyTypeSelectedLabel = selectedType ? selectedType.label : '';
      this.loadReport(this.dealerId);
      if (this.dealerId) {
        this.loadTransactions();
      } else {
        this.loadDealers();
      }
    }
  }

  redirectToSuretyCredit(): void {
    const dealerId = this.dealerId;
    const suretyTypeSelectedValue = this.suretyTypeSelectedValue || '';
    if (dealerId) {
      this.router.navigate(['/gestione-crediti/surety-credit', dealerId, suretyTypeSelectedValue]).then(() => {
        this.loadTransactions();
      });
    }
  }

  ngOnDestroy(): void {
    this.dealerId = null;
    this.suretyTypeSelectedValue = null;
  }
}
