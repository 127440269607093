import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DataBuilderModalService} from '@services/data-builder-modal.service';

@Component({
    selector: 'app-master-analisi-plafond',
    templateUrl: './master-analisi-plafond.component.html',
    styleUrls: ['./master-analisi-plafond.component.css']
})
export class MasterAnalisiPlafondComponent implements OnInit {
    busy: Subscription;
    startup: any;

    urlAnalisiPlafondAgenti: string;
    urlAnalisiPlafondNegozi: string;
    autoloadTableUrl: string;


    constructor(private dashboardService: DashboardService,
                public dbm: DataBuilderModalService,
                public modalService: NgbModal) {
    }

    ngOnInit() {
    }

    updateUrls(operatore) {
        this.busy = this.dashboardService.get(`/analisi-plafond?operatore=${operatore}`)
            .subscribe((data) => {
                this.startup = data.result;
            });

        this.urlAnalisiPlafondAgenti = `/analisi-plafond-agenti?operatore=${operatore}`;
        // this.urlAnalisiPlafondNegozi = `-detail/no-plafond?param=&datasource=pdv_no_plafond&operatore=${operatore}&output=lista_negozi`;
        this.urlAnalisiPlafondNegozi = `plafond/shops-with-less-plafond?vendor=${operatore}`;
    }

    openAutoloadTable(modal, url) {
        this.autoloadTableUrl = url;

        this.modalService.open(modal, {size: 'xl'});
    }
}
