import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CuService } from '@app/services/cu.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cu-massive-update-modal',
  templateUrl: './cu-massive-update-modal.component.html',
  styleUrls: ['./cu-massive-update-modal.component.css']
})
export class CuMassiveUpdateModalComponent implements OnInit {

  @Output() refresh = new EventEmitter<boolean>();

  file: File;
  form: FormGroup;
  documentName: string = '';
  isLoading: boolean = false;

  constructor(
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    private cuService: CuService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      year: [null, [Validators.required]],
      file: [null, Validators.required]
    })
  }

  dismissModal() {
    this.documentName = "";
    this.modalService.dismissAll();
  }

  onSubmit() {
    const form = this.form.value;
    const payload = new FormData();

    payload.append('year', form['year']);
    payload.append('file', this.file);

    return this.uploadZip(payload);
  }

  handleFileInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;

    if (files && files[0]) {
      const document = files[0];
      this.file = document;
      this.documentName = document.name
    }
  }

  uploadZip(payload) {
    this.spinner.show('spinner');
    this.cuService.massiveUpload(payload).subscribe((res) => {
      this.cuService.successAlert("Upload eseguito correttamente!");
      this.refresh.emit();
      this.spinner.hide('spinner');
      this.dismissModal();
    }, err => {
      this.cuService.errorAlert(err.message);
      this.refresh.emit(true);
      this.spinner.hide('spinner');
      this.dismissModal();
    }
    )
  }

}
