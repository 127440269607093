import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ShopAccess, ShopAccessProvider} from '@app/pages/shop-access/shop-access.type';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopAccessService} from '@services/shop-access.service';
import {SubjectPagination} from '@models/invoice/subject';
import * as moment from 'moment';

@Component({
    selector: 'app-shop-access-modal',
    templateUrl: './shop-access-modal.component.html',
    styleUrls: ['./shop-access-modal.component.css']
})
export class ShopAccessModalComponent implements OnInit {
    @Input() data: ShopAccess;
    @Output() refresh = new EventEmitter<boolean>();

    shopAccessForm: FormGroup;


    paymentType = ShopAccess.paymentTypes;
    serviceType = ShopAccess.serviceTypes;

    apiDate = {
        from: '',
        to: ''
    };

    filter = {
        date: '',
        from: '',
        to: '',
    };

    busy: any;

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'One Month': [moment(), moment().add(1, 'month')],
            'Six Months': [moment(), moment().add(6, 'month')],
            'One Year': [moment(), moment().add(12, 'month')],
            'Custom': [],
        }
    };

    constructor(public modalService: NgbModal,
                private dateRangePickerOptions: DaterangepickerConfig,
                private shopAccessService: ShopAccessService) {
    }

    ngOnInit(): void {
        if (!this.data) {
            this.shopAccessForm = new FormGroup({
                company_name: new FormControl(''),
                id_dealer: new FormControl('', Validators.required),
                serviceType: new FormControl('', Validators.required),
                paymentType: new FormControl('', Validators.required),
                autoRenew: new FormControl(false, Validators.required),
                from_date: new FormControl(''),
                to_date: new FormControl(''),
                dateRange: new FormControl('', Validators.required),
                dealers: new FormControl('', Validators.required)
            });
        } else {
            this.shopAccessForm = new FormGroup({
                    paymentType: new FormControl(this.data?.payment_type, Validators.required),
                    autoRenew: new FormControl(this.data?.auto_renew == 1, Validators.required),
                    dateRange: new FormControl('', Validators.required),

                }
            );
            this.generateDateRangeString(moment(this.data.from), moment(this.data.to));
        }
    }

    save() {
        console.log(this.shopAccessForm.value);
        const form = this.shopAccessForm.value;
        const shopAccess = new ShopAccess();
        shopAccess.payment_type = form?.paymentType;
        shopAccess.service = form?.serviceType;
        shopAccess.auto_renew = form?.autoRenew ? 1 : 0;
        shopAccess.company_name = form?.company_name;
        shopAccess.id_dealer = form?.id_dealer;

        shopAccess.from = this.apiDate.from;
        shopAccess.to = this.apiDate.to;

        let fromDate = form?.dateRange;
        let toDate = form?.dateRange;

        fromDate = fromDate.substring(0, 10);
        toDate = toDate.substring(13, 23);

        fromDate = fromDate.replace(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/, '$3-$2-$1');
        toDate = toDate.replace(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/, '$3-$2-$1');

        fromDate = new Date(fromDate);
        toDate = new Date(toDate);

        let rangeDate = moment(this.apiDate.to).diff(moment(this.apiDate.from), 'months', true);
        rangeDate = Math.ceil(rangeDate);

        if (rangeDate == 1) {
            shopAccess.time_range = 1;
        }
        if (rangeDate == 6) {
            shopAccess.time_range = 6;
        }
        if (rangeDate == 12) {
            shopAccess.time_range = 12;
        } else {
            rangeDate = Number(rangeDate);
            shopAccess.time_range = rangeDate;
        }

        if (this.data) {
            return this.updateSubscription(shopAccess);
        }
        return this.addSubscription(shopAccess);

    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    addSubscription(selectedSubscription: ShopAccess) {
        this.busy = this.shopAccessService.addSubscription(selectedSubscription).subscribe((response: ShopAccess) => {
            this.shopAccessService.successAlert('Changed Document!');
            this.refresh.emit(true);
            this.dismissModal();
        }, err => {
            this.shopAccessService.errorAlert(err.message);
            this.refresh.emit(true);
            this.dismissModal();
        });
    }

    updateSubscription(selectedSubscription: ShopAccess) {
        this.busy = this.shopAccessService.updateSubscription(this.data.id, selectedSubscription).subscribe((response: ShopAccess) => {
            this.shopAccessService.successAlert('Changed Document!');
            this.refresh.emit(true);
            this.dismissModal();
        }, err => {
            this.shopAccessService.errorAlert(err.message);
            this.refresh.emit(true);
            this.dismissModal();
        });
    }

    setDealerId(dealerId: any) {
        this.shopAccessForm.get('id_dealer').setValue(dealerId);
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.shopAccessForm.get('dateRange').setValue(startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat));
    }

}
