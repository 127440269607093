import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-documents-create-modal',
    templateUrl: './documents-create-modal.component.html',
    styleUrls: ['./documents-create-modal.component.scss']
})
export class DocumentsCreateModalComponent implements OnInit {
    @ViewChild('documentModal') private documentModal;

    busy: Subscription;
    id: string;
    tipo: string;
    @Output()
    done = new EventEmitter();

    constructor(
        public modalService: NgbModal,
        private dataService: DataService
    ) {
    }

    ngOnInit() {
    }

    public showModal(id: string, type: string = '') {
        this.id = id;
        this.tipo = type;
        this.modalService.open(this.documentModal).result.then((result) => {
        });
    }

    uploadFile($event: any) {
        const file = $event.target.files.item(0);
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('id', this.id);
        formData.append('tipologia', this.tipo);

        this.busy = this.dataService.post(`/carica-documento`,
            formData
        ).subscribe((data: any) => {
            this.modalService.dismissAll();
            this.done.emit(true);
        }, err => {
            this.dataService.errorAlert();
            this.modalService.dismissAll();
        });
    }
}
