import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';
import { Observable, Subject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class RequestService {
    finished$: Subject<boolean>;

    constructor(private http: HttpClient, private authService: AuthenticationService) {}

    get(url, data = {}, additionalParams = {}) {
        return this.http
            .get(`${environment.baseUrl}${url}`, {
                params: data,
                ...additionalParams,
            })
            .pipe(
                map((response: any) => {
                    if (response && response.hasOwnProperty('profile')) {
                        this.authService.updateAccountData(response.profile);
                    }
                    return response;
                }),
            );
    }

    getKdcubeReport(url, data = {}) {
        return this.http
            .get(`${environment.baseUrl}${url}`, {
                params: data,
            })
            .pipe(
                map((response: any) => {
                    if (response && response.hasOwnProperty('profile')) {
                        this.authService.updateAccountData(response.profile);
                    }
                    return response;
                }),
            );
    }

    getKeypointKdcube(url, data = {}) {
        return this.http
            .get(`${environment.baseUrl}${url}`, {
                params: data,
            })
            .pipe(
                map((response: any) => {
                    if (response && response.hasOwnProperty('profile')) {
                        this.authService.updateAccountData(response.profile);
                    }
                    return response;
                }),
            );
    }

    getWithUrl(url, data = {}) {
        return this.http
            .get(url, {
                params: data,
            })
            .pipe(
                map((response: any) => {
                    if (response && response.hasOwnProperty('profile')) {
                        this.authService.updateAccountData(response.profile);
                    }
                    return response;
                }),
            );
    }

    post(url, data = {}, params = {}) {
        return this.http.post(`${environment.baseUrl}${url}`, data, { ...params }).pipe(
            map((response: any) => {
                if (response && response.hasOwnProperty('profile')) {
                    this.authService.updateAccountData(response.profile);
                }
                return response;
            }),
        );
    }

    put(url, data = {}, params = {}) {
        return this.http.put(`${environment.baseUrl}${url}`, data, { params }).pipe(
            map((response: any) => {
                if (response && response.hasOwnProperty('profile')) {
                    this.authService.updateAccountData(response.profile);
                }
                return response;
            }),
        );
    }

    patch(url, data = {}) {
        return this.http.patch(`${environment.baseUrl}${url}`, data).pipe(
            map((response: any) => {
                if (response && response.hasOwnProperty('profile')) {
                    this.authService.updateAccountData(response.profile);
                }
                return response;
            }),
        );
    }

    delete(url) {
        return this.http.delete(`${environment.baseUrl}${url}`).pipe(
            map((response: any) => {
                if (response && response.hasOwnProperty('profile')) {
                    this.authService.updateAccountData(response.profile);
                }
                return response;
            }),
        );
    }

    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downloadFile(data: any, type: string) {
        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);

        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }
}
