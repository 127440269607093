import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {Product} from '@models/product';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'app-shop-index',
    templateUrl: './shop-index.component.html',
    styleUrls: ['./shop-index.component.scss']
})
export class ShopIndexComponent implements OnInit {
    products: Product[] = [];
    originProducts: Product[] = [];
    busy: Subscription;
    page = 1;
    pageSize = 20;
    filter = '';

    constructor(
        public dataService: DataService,
        public router: Router,
    ) {
    }

    ngOnInit() {
        this.busy = this.dataService.get('/get-prodotti')
            .subscribe((data: any) => {
                if (!data.result) {
                    this.router.navigate(['/negozio/bloccato']);
                }
                this.products = new Product().fromArray(data.result);
                this.originProducts = this.products;
            });
    }

}
