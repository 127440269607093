import {Component, OnInit} from '@angular/core';
import {MailService} from '@services/mail.service';
import * as moment from 'moment';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-master-email',
    templateUrl: './master-email.component.html',
    styleUrls: ['./master-email.component.css']
})
export class MasterEmailComponent implements OnInit {
    data = {
        nuovi_attivatori: { today: 0, week: 0, month: 0 },
        improduttivi: { today: 0, week: 0, month: 0 },
        soglia_raggiunta: { today: 0, week: 0, month: 0 },
        prossimo_soglia: { today: 0, week: 0, month: 0 },
        invito_a_fatturare: { today: 0, week: 0, month: 0 },
        plafond: { today: 0, week: 0, month: 0 },
        avanzamento: { today: 0, week: 0, month: 0 },
        avanzamento_versus: { today: 0, week: 0, month: 0 },
        tickets: { today: 0, week: 0, month: 0 },
        nuovi_messaggi: { today: 0, week: 0, month: 0 },
        ordini_sim: { today: 0, week: 0, month: 0 },
        ordini_plafond: { today: 0, week: 0, month: 0 },
        soglie_kena: { today: 0, week: 0, month: 0 },
        no_plafond: { today: 0, week: 0, month: 0 },
        analisi_iniziale: { today: 0, week: 0, month: 0 },
        analisi_avanzamento: { today: 0, week: 0, month: 0 },
        analisi_report: { today: 0, week: 0, month: 0 },
        analisi_overview: { today: 0, week: 0, month: 0 }

    };

    loading = false;
    date: string;

    get formattedDate() {
        return moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }

    constructor(private service: MailService) {
        let now = moment();

        // this.date = new NgbDate(now.year(), now.month(), now.day());
        this.date = now.format('DD-MM-YYYY');
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.loading = true;
        this.service.getOverview(this.formattedDate)
            .subscribe(data => {
                this.data = data;
                this.loading = false;
            });
    }
}
