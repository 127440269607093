import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';

export class KdTax extends BaseModel {
  id: number;
  dealer_id: number;
  company_name: string;

  created_at: string;
  created_by: string;
  update_at: string;
  update_by: string;
  status: string;
  notes: string;
  data: string;
  is_deleted: number;
  budget_file: string;
  budget_file_path: string;

  constructor(attributes?: any) {
    super(attributes);

    if (this.data !== undefined) {
      const jsonData = JSON.parse(this.data);
      this.budget_file_path = jsonData['budget_file_path'];
    }
  }

  public static kdTaxTypes = [
    {
      label: 'In attesa',
      key: 'pending',
    },
    {
      label: 'Respinto',
      key: 'cancelled',
    },
    {
      label: 'Confermato',
      key: 'confirmed',
    },
  ];

  get kdTaxStatusLabel() {
    return KdTax.kdTaxStatusPair[this.status];
  }

  public static kdTaxStatusPair = {
    confirmed: 'Confermato',
    cancelled: 'Respinto',
    pending: 'In attesa',
  };

  fromArray(result: []): KdTax[] {
    const array: KdTax[] = [];
    for (const item of result) {
      array.push(new KdTax(item));
    }
    return array;
  }
}

export class KdTaxProvider extends BaseModel {
  data: KdTax[];
  _meta: MetaPagination;
  _links: Array<any>;
}
