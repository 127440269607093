import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(
    public request: RequestService,
    private alertService: AlertService
  ) {}

  getSurveyFormStructure(id): Observable<any> {
    return this.request.get('/dealer/survey', {id: id}).pipe(
      map((response: any) => {
            return response;
        })
    );
  }

  postSurveyFormStructure(payload) {
    return this.request.post(`/dealer/create-survey`, payload);
  }

  public successAlert(text) {
    this.alertService.show(
        '',
        'Questionario ' + text,
        {classname: 'bg-success text-light', delay: 4000}
    );
  }
  public errorAlert(message?) {
    this.alertService.show(
        'Errore ',
        message ? message : 'Try again!',
        {classname: 'bg-danger text-light', delay: 4000}
    );
  }

}
