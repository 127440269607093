import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KpiNotes } from '@app/models/kpi';
import { MetaPagination } from '@app/models/meta-pagination';
import { AlertService } from '@app/services/alert.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { KpiService } from '@app/services/kpi.service';
import { RequestService } from '@app/services/request.service';
import { VendorSelectComponent } from '@app/widgets/vendor-select/vendor-select.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-kpi-notes',
    templateUrl: './kpi-notes.component.html',
    styleUrls: ['./kpi-notes.component.scss'],
})
export class KpiNotesComponent implements OnInit {
    isCollapsed: boolean = true;
    vendor = null;
    editVendor = null;
    vendors: { key: string; label: string }[] = [
        { key: '6', label: 'Kena' },
        { key: '11', label: 'Tim' },
        { key: '16', label: 'A2A' },
    ];

    ordersPagination: MetaPagination = new MetaPagination();
    @ViewChild('detailTemplate') private detailTemplate;
    @ViewChild(VendorSelectComponent) vendorSelect: VendorSelectComponent;
    @ViewChild(VendorSelectComponent) editVendorSelect: VendorSelectComponent;
    kpiNotes: KpiNotes[] = [];
    selectedData: any;
    datiForm: FormGroup;
    editForm: FormGroup;
    isEdit = true;
    isLoading: boolean = false;

    defaultSingleMonth = moment().subtract('2 months');

    tinyMceConfig = {
        base_url: '/tinymce',
        suffix: '.min',
        plugins: [
            'lists',
            'advlist',
            'autolink',
            'link',
            'image',
            'charmap',
            'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'paste',
            'code',
            'help',
            'wordcount',
        ],
        toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
    };

    get deleteSubscriptionOptions() {
        return new ConfirmOptionsService({
            title: 'Elimina Nota',
            text: 'Sei sicuro di voler rimuovere questa ?',
        });
    }

    constructor(
        private kpiService: KpiService,
        public requestService: RequestService,
        public modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private alertService: AlertService,
    ) {
        this.datiForm = this.formBuilder.group({
            month: ['', Validators.required],
            year: ['', Validators.required],
            description: [''],
            id_operatori: [null, Validators.required],
            role: ['', Validators.required],
        });
        this.editForm = this.formBuilder.group({
            editMonth: ['', Validators.required],
            editYear: ['', Validators.required],
            editDescription: [''],
            editRole: ['', Validators.required],
            editOperators: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.loadNotes();
    }

    loadNotes() {
        this.spinner.show('table-orders');

        const params = {};
        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        this.kpiService.listKpiNotes(params).subscribe(
            (response: any) => {
                this.kpiNotes = new KpiNotes().fromArray(
                    response.data.map((note) => {
                        note.id_operatori = note.id_operatori ? note.id_operatori.split(',') : [];
                        note.id_operatori = note.id_operatori
                            .map((id: string) => {
                                const operator = this.vendors.find((vendor) => vendor.key.toString() === id);
                                return operator ? operator.label : null;
                            })
                            .filter((label) => label !== null);
                        return note;
                    }),
                );
                this.ordersPagination = response._meta;
                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    saveNote() {
        if (this.datiForm.invalid) {
            return;
        }

        this.spinner.show();
        this.isLoading = true;

        const formData = new FormData();
        formData.append('month', this.datiForm.get('month').value);
        formData.append('year', this.datiForm.get('year').value);
        formData.append('description', this.datiForm.get('description').value);
        formData.append('id_operatori', this.datiForm.get('id_operatori').value);
        formData.append('role', this.datiForm.get('role').value);

        this.kpiService.createKpiNotes(formData).subscribe(
            (response: any) => {
                this.datiForm.get('role').reset();
                this.datiForm.get('id_operatori').reset();
                this.datiForm.get('description').reset();
                if (this.vendorSelect) {
                    this.vendorSelect.reset();
                }
                this.vendor = null;
                this.isLoading = false;
                this.spinner.hide();
                this.kpiService.successKpiAlert('con successo!');
                this.loadNotes();
            },
            (error) => {
                this.alertService.show('Attenzione', error, { classname: 'bg-danger text-light', delay: 4000 });
                this.spinner.hide();
                this.isLoading = false;
            },
        );
    }

    showDetailModal(data?: any) {

        const idOperatoriKeys = (data.id_operatori || [])
            .map((label) => {
                const vendor = this.vendors.find((v) => v.label === label);
                return vendor ? parseInt(vendor.key, 10) : null;
            })
            .filter((key) => key !== null);

        this.selectedData = {
            ...data,
            id_operatori: idOperatoriKeys,
        };

        this.editForm.patchValue({
            editMonth: data.month,
            editYear: data.year,
        });

        this.editForm.patchValue({
            editDescription: data?.description || '',
            editRole: data?.role || '',
        });

        this.defaultSingleMonth = moment({ year: data.year, month: data.month-1, day: 1});

        this.modalService.open(this.detailTemplate, { size: 'xl', scrollable: true });
    }

    updateKpiNotes(event: Event) {
        if (this.editForm.valid) {
            const payloadUpdate = {
                month: this.editForm.get('editMonth').value,
                year: this.editForm.get('editYear').value,
                description: this.editForm.get('editDescription').value,
                role: this.editForm.get('editRole').value,
                id_operatori: this.editForm.get('editOperators').value,
            };
            this.kpiService.updateKpiNotes(this.selectedData.id, payloadUpdate).subscribe(
                (response) => {
                    this.editVendor = null;
                    this.modalService.dismissAll();
                    this.loadNotes();
                    this.kpiService.successKpiUpdateAlert('con successo!');
                },
                (error) => {
                    this.modalService.dismissAll();
                    this.kpiService.errorAlert();
                    console.error("Errore durante l'aggiornamento:", error);
                },
            );
        }
    }

    deleteKpiNotes(id: number) {
        this.kpiService.deleteKpiNotes(id).subscribe(
            () => {
                const index = this.kpiNotes.findIndex((dealer) => dealer.id === id);
                if (index > -1) {
                    this.kpiService.successAlert('con successo!');
                    this.loadNotes();
                }
            },
            (err) => {
                this.kpiService.errorAlert(err.message);
            },
        );
    }

    updateSelectedDate($event: any) {
        const selectedMonth = moment($event).format('MM');
        const selectedYear = moment($event).format('YYYY');
        this.editForm.patchValue({
            editMonth: selectedMonth,
            editYear: selectedYear,
        });

    }

    setSelectedDate($event: any) {
        // this.kpiNotesPayload.month = moment($event).format('MM');
        // this.kpiNotesPayload.year = moment($event).format('YYYY');
        const selectedMonth = moment($event).format('MM');
        const selectedYear = moment($event).format('YYYY');
        this.datiForm.patchValue({
            month: selectedMonth,
            year: selectedYear,
        });
    }

    toggleCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
    }

    setVendor($event: any) {
        this.vendor = $event;
        this.datiForm.get('id_operatori').setValue(this.vendor);
    }

    setEditVendor($event: any) {
        this.editVendor = $event;
        this.editForm.get('editOperators').setValue(this.editVendor);
    }

    truncateText(text: string): string {
        if (text.length > 100) {
            return text.substring(0, 100) + '...';
        } else {
            return text;
        }
    }
}
