import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { BusinessPlanService } from '@services/business-plan.service';
import { BusinessUnit, BusinessUnitRow, BusinessUnitRowType } from '@models/business-plan';
import { AlertService } from '@services/alert.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { VideoCategory } from '@models/video';

@Component({
    selector: 'app-business-unit-editor',
    templateUrl: './business-unit-editor.component.html',
    styleUrls: ['./business-unit-editor.component.css'],
})
export class BusinessUnitEditorComponent implements OnInit {
    @ViewChild('formTemplate') private formTemplate;

    modes = {
        'forecast': 'Previsionale',
        'actual': 'Reale',
    }

    editMode = false;
    compareMode = false;

    id: any;
    selectedYear: number;
    selectedMode: string = 'forecast';
    popupConfig: any;
    availableYears = [];

    loading = false;
    businessUnit: BusinessUnit;
    form: FormGroup;
    
    yearPopupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };

    modePopupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };

    rowPopupConfig = {
        type: null,
        key: null,
        id: null,
        inputValue: null,
        month: null,
        type_id: null,
    };

    get confirmDeleteOptions() {
        return new ConfirmOptionsService({
            title: "Elimina riga",
            text: "Sei sicuro di voler rimuovere questa riga?",
        });
    }

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _service: BusinessPlanService,
        private _alertService: AlertService,
        public _modalService: NgbModal,
    ) {
        this._activatedRoute.params.subscribe(params => {
            this.id = params['id'];

            this._activatedRoute.queryParams.subscribe(params => {
                if(params.hasOwnProperty('year')) {
                    this.selectedYear = parseInt(params['year']);
                }
                else {
                    this.selectedYear = new Date().getFullYear();
                }
                if(params.hasOwnProperty('mode')) {
                    this.selectedMode = params['mode'];
                }
                else {
                    this.selectedMode = 'forecast';
                }
                if(params.hasOwnProperty('edit')) {
                    this.editMode = params['edit'] === 'true';
                }
                else {
                    this.editMode = false;
                }

                if(params.hasOwnProperty('compare')) {
                    this.compareMode = params['compare'] === 'true';
                }
                else {
                    this.compareMode = false;
                }

                for (let i = 2024; i <= new Date().getFullYear() + 5; i++) {
                    this.availableYears.push({
                        label: i,
                        key: i
                    });
                }

                this.loadBusinessUnit();
            });
        });
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            id: new FormControl(''),
            bu_id: new FormControl(this.id),
            name: new FormControl('', [Validators.required]),
            type: new FormControl('', [Validators.required]),
            format: new FormControl('', [Validators.required]),
            hidden: new FormControl('0', []),
        });
    }

    openYearPopover(popOver: NgbPopover) {
        this.yearPopupConfig.inputValue = this.selectedYear;
        this.yearPopupConfig.id = this.selectedYear;
        this.yearPopupConfig.key = 'selectedYear';
        this.yearPopupConfig.popover = popOver;
        this.yearPopupConfig.isSelect = true;
        this.yearPopupConfig.items = this.availableYears;
        popOver.open();
    }

    openModePopover(popOver: NgbPopover) {
        this.modePopupConfig.inputValue = this.selectedMode;
        this.modePopupConfig.id = this.selectedMode;
        this.modePopupConfig.key = 'selectedMode';
        this.modePopupConfig.popover = popOver;
        this.modePopupConfig.isSelect = true;
        this.modePopupConfig.items = [
            { label: 'Previsionale', key: 'forecast' },
            { label: 'Reale', key: 'actual' }
        ];
        popOver.open();
    }

    changeYear(year: number) {
        this.selectedYear = year;
        this._router.navigate(['/business-plan/business-units/edit', this.id], { queryParams: { year: year, mode: this.selectedMode }});
    }

    changeMode(mode: any) {
        this.selectedMode = mode;
        this._router.navigate(['/business-plan/business-units/edit', this.id], { queryParams: { year: this.selectedYear, mode: mode }});
    }

    showModal() {
        this._modalService.open(this.formTemplate, { size: 'lg' });
    }

    private loadBusinessUnit() {
        this.loading = true;
        this._service.getBusinessUnit(this.id, this.selectedYear, this.selectedMode, this.compareMode).subscribe((response: BusinessUnit) => {
            this.businessUnit = new BusinessUnit(response);
            this.businessUnit.init(this.selectedYear);
            console.log(this.businessUnit);
            this.loading = false;
        }, () => {
            this.loading = false;
            this._alertService.show('Errore' , 'Errore durante il caricamento della Business Unit', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    save($event: any) {
        if(this.form.invalid) {
            return;
        }

        const payload = this.form.value;

        if(payload.id) {
            return this.update(payload);
        }

        this.loading = true;
        this._service.saveRowType(payload).subscribe((response: BusinessUnit) => {
            this.form.reset();
            this.form.patchValue({ bu_id: this.id });
            this.loadBusinessUnit();
            this._modalService.dismissAll();
            this.loading = false;

            this._alertService.show('Ottimo', 'Riga creata con successo', {
                classname: 'bg-success text-light',
                delay: 3000,
            });
        }, () => {
            this.loading = false;
            this._alertService.show('Errore', 'Errore durante il salvataggio della riga', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    edit(row: BusinessUnitRowType) {
        this.form.patchValue(row);
        this._modalService.open(this.formTemplate, { size: 'lg' });
    }

    delete(row: BusinessUnitRowType) {
        this.loading = true;
        this._service.deleteRowType(row.id).subscribe((response: BusinessUnitRowType) => {
            this.loadBusinessUnit();
            this.loading = false;

            this._alertService.show('Ottimo', 'Business Unit rimossa con successo', {
                classname: 'bg-success text-light',
                delay: 3000,
            });
        }, () => {
            this.loading = false;
            this._alertService.show('Errore', 'Errore durante la rimozione della Business Unit', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    update(payload: any) {
        this.loading = true;
        this._service.updateRowType(payload).subscribe((response: BusinessUnitRowType) => {
            this.form.reset();
            this.loadBusinessUnit();
            this._modalService.dismissAll();
            this.loading = false;

            this._alertService.show('Ottimo', 'Business Unit aggiornata con successo', {
                classname: 'bg-success text-light',
                delay: 3000,
            });
        }, () => {
            this.loading = false;
            this._alertService.show('Errore', 'Errore durante l\'aggiornamento della Business Unit', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    changeRow($event: any) {
        const payload = {
            id: $event.config.id,
            bu_id: this.id,
            type_id: $event.config.type_id,
            month: $event.config.month,
            year: this.selectedYear,
            value: parseFloat($event.value),
            type: this.selectedMode
        }
        if(payload.id) {
            this.loading = true;
            this._service.updateRow(payload).subscribe((response: BusinessUnit) => {
                this.loadBusinessUnit();
                this.loading = false;

                this._alertService.show('Ottimo', 'Riga aggiornata con successo', {
                    classname: 'bg-success text-light',
                    delay: 3000,
                });
            }, () => {
                this.loading = false;
                this._alertService.show('Errore', 'Errore durante il salvataggio della riga', {
                    classname: 'bg-danger text-light',
                    delay: 3000,
                });
            });
        }
        else {
            this.loading = true;
            this._service.saveRow(payload).subscribe((response: BusinessUnit) => {
                this.loadBusinessUnit();
                this.loading = false;

                this._alertService.show('Ottimo', 'Riga creata con successo', {
                    classname: 'bg-success text-light',
                    delay: 3000,
                });
            }, () => {
                this.loading = false;
                this._alertService.show('Errore', 'Errore durante il salvataggio della riga', {
                    classname: 'bg-danger text-light',
                    delay: 3000,
                });
            });
        }
    }

    openRowPopover(rowPopover: NgbPopover, row: BusinessUnitRow) {
        this.rowPopupConfig.inputValue = row.value;
        this.rowPopupConfig.id = row.id;
        this.rowPopupConfig.month = row.month;
        this.rowPopupConfig.type_id = row.type_id;
        this.rowPopupConfig.key = 'row';
        rowPopover.open();
    }

    moveDown(type: BusinessUnitRowType) {
        this.loading = true;
        this._service.moveDownRowType(type.id).subscribe((response: BusinessUnitRowType) => {
            this.loadBusinessUnit();
            this._modalService.dismissAll();
            this.loading = false;

            this._alertService.show('Ottimo', 'Riga spostata con successo', {
                classname: 'bg-success text-light',
                delay: 3000,
            });
        }, () => {
            this.loading = false;
            this._alertService.show('Errore', 'Errore durante il salvataggio della riga', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    moveUp(type: BusinessUnitRowType) {
        this.loading = true;
        this._service.moveUpRowType(type.id).subscribe((response: BusinessUnitRowType) => {
            this.loadBusinessUnit();
            this._modalService.dismissAll();
            this.loading = false;

            this._alertService.show('Ottimo', 'Riga spostata con successo', {
                classname: 'bg-success text-light',
                delay: 3000,
            });
        }, () => {
            this.loading = false;
            this._alertService.show('Errore', 'Errore durante il salvataggio della riga', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    toggleEditMode() {
        this._router.navigate(['/business-plan/business-units/edit', this.id], { queryParams: { year: this.selectedYear, mode: this.selectedMode, edit: !this.editMode, compare: this.compareMode }});
    }

    toggleCompare() {
        this._router.navigate(['/business-plan/business-units/edit', this.id], { queryParams: { year: this.selectedYear, mode: this.selectedMode, edit: this.editMode, compare: !this.compareMode }});
    }
}
