<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav d-md-flex d-block w-sm-100">
        <li class="nav-item">
            <a
                    (click)="toggle()"
                    class="nav-link"
                    data-widget="pushmenu"
                    href="javascript:void(0);"
                    role="button"
            ><i class="fas fa-bars"></i
            ></a>
        </li>

        <!--    <ul class="navbar-nav bg-gradient-orange elevation-1 rounded ml-3 d-none d-sm-block">-->
        <!--      <li class="nav-item border-right">-->
        <!--        <a class="nav-link" (click)="triggerRadio()">-->
        <!--          <i class="text-white fas" [ngClass]="{'fa-pause': this.player && this.player.playing, 'fa-play': !this.player || !this.player.playing}"></i>-->
        <!--        </a>-->
        <!--      </li>-->
        <!--      <li class="nav-item">-->
        <!--        <a class="nav-link text-white">-->
        <!--          Radio Master Group-->
        <!--        </a>-->
        <!--      </li>-->
        <!--    </ul>-->

        <ul
                *ngIf="false"
                class="navbar-nav bg-gradient-1 elevation-1 rounded ml-3 d-none d-sm-block"
        >
            <li class="nav-item border-right">
                <a class="nav-link">
                    <i class="fa fa-medal text-white"></i>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-white">
                    <span class="d-none d-xxl-inline-block mx-1" translate>Punti:</span>
                    <span id="mg-points">{{
                        this.authenticationService.currentUserValue.profile.punti
                        }}</span>
                </a>
            </li>
        </ul>

        <!--    <ul class="navbar-nav bg-gradient-1 elevation-1 rounded ml-3 d-none d-sm-block">-->
        <!--      <li class="nav-item border-right">-->
        <!--        <a class="nav-link">-->
        <!--          <i class="fas fa-paper-plane text-white"></i>-->
        <!--        </a>-->
        <!--      </li>-->
        <!--      <li class="nav-item">-->
        <!--        <a class="nav-link text-white">-->
        <!--          <span>{{this.authenticationService.currentUserValue.profile.sms_disponibili}} sms</span>-->
        <!--        </a>-->
        <!--      </li>-->
        <!--    </ul>-->

        <ng-container *isVendorEnabled="['Tim']">
            <ul
                    *show="['dealer']"
                    class="navbar-nav bg-tim-gradient elevation-1 rounded ml-3"
            >
                <li class="nav-item border-right">
                    <a class="nav-link">
                        <img
                                class=""
                                height="20px"
                                src="/assets/img/small_brands/tim.png"
                        />
                        <!--          <i class="op op-kena op-white op-circle"></i>-->
                    </a>
                </li>
                <li class="nav-item border-right">
                    <a [routerLink]="['/plafond/tim']" class="nav-link text-white">
                        <i class="fa fa-euro-sign"></i>
                        <!--          <span class="d-none d-xxl-inline-block ml-1">Plafond:</span>-->
                        <span class="ml-1">{{
                            this.authenticationService.currentUserValue.profile.plafond_tim
                                | currency: "€":"symbol":"1.0"
                            }}</span>
                    </a>
                </li>
            </ul>
        </ng-container>
        <ng-container *isVendorEnabled="['Kena Mobile']">
            <ul
                    *show="['dealer']"
                    class="navbar-nav bg-kena-gradient elevation-1 rounded ml-3"
            >
                <li class="nav-item border-right">
                    <a class="nav-link">
                        <img
                                class="img-rounded bg-gradient-white"
                                height="20px"
                                src="/assets/img/small_brands/kena.png"
                        />
                        <!--          <i class="op op-kena op-white op-circle"></i>-->
                    </a>
                </li>
                <li class="nav-item border-right">
                    <a [routerLink]="['/plafond/kena']" class="nav-link text-dark">
                        <i class="fa fa-euro-sign"></i>
                        <!--          <span class="d-none d-xxl-inline-block ml-1">Plafond:</span>-->
                        <span class="ml-1">{{
                            this.authenticationService.currentUserValue.profile.plafond_kena
                                | currency: "€":"symbol":"1.0"
                            }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/sim/kena']" class="nav-link text-dark">
                        <i class="fa fa-sim-card"></i>
                        <!--          <span class="d-none d-xxl-inline-block ml-1">Magazzino Sim:</span>-->
                        <span class="ml-1">{{
                            this.authenticationService.currentUserValue.profile.sim_kena
                                | number
                            }}</span>
                    </a>
                </li>
            </ul>
        </ng-container>
        <ng-container *isVendorEnabled="['Tiscali Mobile']">
            <ul
                    *show="['dealer']"
                    class="navbar-nav bg-tiscali-gradient elevation-1 rounded ml-3"
            >
                <li class="nav-item border-right">
                    <a class="nav-link">
                        <img
                                class="img-rounded bg-gradient-white"
                                height="20px"
                                src="/assets/img/small_brands/tiscali.png"
                        />
                    </a>
                </li>
                <li class="nav-item border-right">
                    <a [routerLink]="['/plafond/tiscali']" class="nav-link text-white">
                        <i class="fa fa-euro-sign"></i>
                        <!--          <span class="d-none d-xxl-inline-block ml-1">Plafond:</span>-->
                        <span class="ml-1">{{
                            this.authenticationService.currentUserValue.profile
                                .plafond_tiscali | currency: "€":"symbol":"1.0"
                            }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/sim/tiscali']" class="nav-link text-white">
                        <i class="fa fa-sim-card"></i>
                        <!--          <span class="d-none d-xxl-inline-block ml-1">Magazzino Sim:</span>-->
                        <span class="ml-1">{{
                            this.authenticationService.currentUserValue.profile.sim_tiscali
                                | number
                            }}</span>
                    </a>
                </li>
            </ul>
        </ng-container>

        <!--    <div plyr plyrType="audio"-->
        <!--         (plyrInit)="player = $event"-->
        <!--         [plyrOptions]="plyrOptions"-->
        <!--         [plyrPlaysInline]="true" [plyrCrossOrigin]="true" [plyrSources]="audioSources"-->
        <!--    ></div>-->

        <ng-container *ngIf="false">
            <ng-container *isVendorDisabled="'Kena Mobile Stands'">
                <form *show="['master']" class="form-inline ml-lg-3 mb-lg-0 mb-2">
                    <ng-template #rt let-r="result" let-t="term">
                        <ngb-highlight [result]="r.ragsociale" [term]="t"></ngb-highlight>
                    </ng-template>

                    <div class="input-group input-group-sm w-lg-300">
                        <input
                                [(ngModel)]="agente"
                                [inputFormatter]="formatterAgente"
                                [ngModelOptions]="{ standalone: true }"
                                [ngbTypeahead]="searchAgente"
                                [resultTemplate]="rt"
                                aria-label="Search"
                                class="form-control form-control-navbar"
                                placeholder="Cerca un key account"
                                type="search"
                        />
                        <div class="input-group-append">
                            <button class="btn btn-navbar" type="submit">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </ng-container>
        </ng-container>
    </ul>


    <ul class="navbar-nav ml-auto menu-right">
        <ng-container *isVendorDisabled="'Kena Mobile Stands'">
            <form
                    *show="['master', 'agent', 'masteragent']"
                    class="form-inline ml-lg-3 mb-lg-0 mb-2"
            >
                <ng-template #rt let-r="result" let-t="term">
                    <ngb-highlight [result]="r.ragsociale" [term]="t"></ngb-highlight>
                </ng-template>

                <div class="input-group input-group-sm w-lg-300">
                    <input
                            [(ngModel)]="dealer"
                            [inputFormatter]="formatter"
                            [ngModelOptions]="{ standalone: true }"
                            [ngbTypeahead]="search"
                            [resultTemplate]="rt"
                            aria-label="Search"
                            class="form-control form-control-navbar"
                            placeholder="{{ 'Cerca un negozio' | translate }}"
                            type="search"
                    />
                    <div class="input-group-append">
                        <button class="btn btn-navbar" type="submit">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </ng-container>
        <li class="nav-item d-none d-sm-inline-block rounded">
            <a [routerLink]="['/visite/gestione']" class="nav-link"
            ><i class="fa fa-route text-blue"></i>
                <span class="d-none d-xxl-inline-block ml-1">Visite</span></a
            >
        </li>
    </ul>

    <ul *ngIf="false" class="navbar-nav ml-auto menu-right">
        <li class="nav-item d-none d-sm-inline-block">
            <a [routerLink]="['/supporto/video']" class="nav-link"
            ><i class="fa fa-graduation-cap text-danger"></i>
                <span class="d-none d-xxl-inline-block ml-1">Pillole formative</span></a
            >
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a [href]="whatsAppUrl" class="nav-link" target="_blank"
            ><i class="fab fa-whatsapp text-green"></i>
                <span class="d-none d-xxl-inline-block ml-1" translate
                >Scrivici su Whatsapp</span
                ></a
            >
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a [href]="telegramUrl" class="nav-link" target="_blank"
            ><i class="fab fa-telegram text-blue"></i>
                <span class="d-none d-xxl-inline-block ml-1" translate
                >Scrivici su Telegram</span
                ></a
            >
        </li>
    </ul>

    <!-- SEARCH FORM -->
    <form *ngIf="false" [formGroup]="searchForm" class="form-inline ml-3">
        <div class="input-group input-group-sm">
            <input
                    aria-label="Search"
                    class="form-control form-control-navbar"
                    placeholder="{{ 'Cerca un negozio' | translate }}"
                    type="search"
            />
            <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
    </form>

    <!-- Right navbar links -->
    <ul *ngIf="false" class="navbar-nav ml-auto">
        <!-- Messages Dropdown Menu -->
        <!--    <app-messages-dropdown-menu></app-messages-dropdown-menu>-->
        <!-- Notifications Dropdown Menu -->
        <app-notifications-dropdown-menu></app-notifications-dropdown-menu>
        <!--    <li class="nav-item">-->
        <!--      <a-->
        <!--        class="nav-link"-->
        <!--        (click)="logout()"-->
        <!--      >-->
        <!--        <i class="fas fa-power-off"></i>-->
        <!--      </a>-->
        <!--    </li>-->
    </ul>
</nav>
<!-- /.navbar -->
