<div class="card bg-gradient-dark">
  <div class="card-body">
    <div class="d-flex align-items-lg-center">
      <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
           width="160px"
           src="assets/img/flats/gear.png">
      <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Modifica Ragione Sociale</h1>
        <p class="lead text-lg">In questa sezione puoi modificare le ragioni sociali e quindi applicare tutto il crud sulla tabella match_ragioni_sociali</p>
        <hr>
        <form (ngSubmit)="loadList()">
          <div class="form-row">
            <div class="col-4">
              <div class="form-group">
                <label translate for="companyName">Dealer</label>
                <app-retailer-typeahead (onChange)="filter.id_dealer = $event" id="companyName"></app-retailer-typeahead>
              </div>
            </div>
            <div class="col-4">
               <div class="form-group">
                  <label translate>Rag. Sociale</label>
                  <input [(ngModel)]="filter.ragsoc" name="vat" class="form-control" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label translate>VAT</label>
                <input [(ngModel)]="filter.vat" name="vat" class="form-control" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label translate>Cod Fiscale</label>
                <input [(ngModel)]="filter.codfisc" name="vat" class="form-control" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label translate>Provider</label>
                <ng-select
                  [clearable]="true"
                  class="disableClear"
                  [(ngModel)]="filter.providerType"
                  [items]="providerText"
                  bindLabel="label"
                  name="subscribed"
                  id="subscribed"
                  bindValue="key"
                ></ng-select>
              </div>
            </div>
            <div class="col-4 mt-auto form-group">
                <button class="btn btn-info btn-block" translate type="submit">Filter</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <h4 class="mb-0" translate>Lista Ragioni Sociali</h4>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="default"
      color="#fff"
      type="square-jelly-box"
      name="table-orders"
      [fullScreen]="false"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped">
      <thead>
      <tr>
        <th translate>ID Dealer</th>
        <th translate>Rag. Sociale</th>
        <th translate>Rag. Sociale Aggiornata</th>
        <th translate>P. Iva</th>
        <th translate>Cod. Fiscale</th>
        <th translate>Provider</th>
        <th translate>Date</th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody class="bg-gradient-white">
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        size="default"
        color="#fff"
        type="square-jelly-box"
        name="orders"
        [fullScreen]="false"
      >
        <p style="color: white">Caricamento...</p>
      </ngx-spinner>
      <tr *ngFor="let item of ragsocialeList">
        <td>
          {{ item.id_dealer === null ? '-' : item.id_dealer}}
        </td>
        <td>
          {{ item.ragsociale }}
        </td>
        <td>
          {{ item.ragione_sociale }}
        </td>
        <td>
          {{ item.piva }}
        </td>
        <td>
          {{ item.codfiscale }}
        </td>
        <td>
          {{ item.provider }}
        </td>
        <td>
          {{ item?.created | date:'dd/MM/yyyy HH:MM:ss' }}
        </td>
        <td>
          <button class="btn btn-link"
                  (click)="editRecord(item);"
          >
            <i class=" fa fa-eye text-info"></i>
          </button>
        </td>
        <td class="text-center">
          <button type="button" class="close text-danger" aria-label="Close"
                  [swal]="deleteSubscriptionOptions" *ngIf="isEdit"
                  (confirm)="deleteMatch(item.id)">
            <span aria-hidden="true">&times;</span>
          </button>
        </td>
      </tr>
      <tr *ngIf="ragsocialeList?.length<=0">
        <td class="text-muted text-center" colspan="9" translate>List is empty</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" *ngIf="ragsocialeList?.length>0">
            <span><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
              {{ this.ordersPagination.totalCount <= 1 ? 'Risultato' : 'Risultati'|translate }}.
                <a class="link"
                >Esporta la lista completa</a
                >
            </span>
    <ngb-pagination *ngIf="ordersPagination.pageCount>1"
                    size="sm"
                    [pageSize]="ordersPagination.perPage"
                    [maxSize]="10"
                    [(page)]="ordersPagination.currentPage"
                    [collectionSize]="ordersPagination.totalCount"
                    (pageChange)="loadList()"
                    [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
</div>

<!-- MODALE -->

<ng-template #editRecordTemplate let-modal>
  <div class="modal-header bg-gradient-dark">
    <h4 class="modal-title clamp-title" translate>{{ 'Aggiorna Dati' }}</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [formGroup]="editForm" (ngSubmit)="save()">
    <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-6">
            <label translate for="id_dealer">ID Dealer</label>
            <input id="id_dealer" formControlName="id_dealer" class="form-control" />
          </div>
          <div class="form-group col-6">
            <label translate for="ragsociale">Rag. Sociale</label>
            <input id="ragsociale" formControlName="ragsociale" class="form-control" />
          </div>
          <div class="form-group col-6">
            <label translate for="ragione_sociale">Rag. Sociale Aggiornata</label>
            <input id="ragione_sociale" formControlName="ragione_sociale" class="form-control" />
          </div>
          <div class="form-group col-6">
            <label translate for="piva">P. Iva</label>
            <input id="piva" formControlName="piva" class="form-control" />
          </div>
          <div class="form-group col-6">
            <label translate for="codfiscale">Cod. Fiscale</label>
            <input id="codfiscale" formControlName="codfiscale" class="form-control" />
          </div>
          <div class="form-group col-6">
            <label translate for="provider">Provider</label>
            <ng-select
              id="provider"
              [items]="providerText"
              bindLabel="label"
              bindValue="key"
            ></ng-select>
          </div>
        </div>
    </div>
    <div class="card-footer">
      <div class="form-group col-12 p-0 mb-0">
        <button class="btn btn-secondary my-2" type="submit" [disabled]="!isFormUpdate">
          <span translate>Salva</span>
          <i *ngIf="isSaving" class="fa fa-spinner fa-spin ml-2 text-white"></i>
        </button>
      </div>
    </div>
  </form>
</ng-template>
