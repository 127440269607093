import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {NgxSpinnerService} from 'ngx-spinner';
import {MapTypeStyle} from '@agm/core/services/google-maps-types';
import {environment} from '@environments/environment';
import {AgentVisitsService} from '@services/agent-visits.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

moment.locale(environment.language);

@Component({
    selector: 'app-dealer-map',
    templateUrl: './dealer-map.component.html',
    styleUrls: ['./dealer-map.component.css']
})
export class DealerMapComponent implements OnInit {
    shops = [];
    direction;
    filter = {
        date: '',
        agent_id: '',
        masteragent_id: '',
        shop: '',
        province_id: '',
        region_id: '',
        city_id: '',
        channel: '',
        cluster: '',
        vendor: ''
    };
    apiDate = '';
    keys = [];
    defaultStyles: MapTypeStyle[] = [
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
                {visibility: 'off'}
            ]
        }
    ];
    localFormat = 'DD/MM/YYYY HH:mm:ss';
    report;
    agentStops = [];
    agentStopsPagination;
    agentVisitsPagination;
    isSelectedAgent = false;
    lunchTime = 'lunch-time';
    nightTime = 'night-time';
    mode = 0;
    selectedVisit;
    baseAwsUrl = environment.baseAwsUrl;
    visitsData = [];
    cityData = [];
    tableUrl = null;

    @ViewChild('fileTemplate') private fileTemplate;

    tableCityUrl = null;
    currentTab = 2;

    constructor(
        private agentVisitsService: AgentVisitsService,
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig,
        private modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.selectedDate(moment());
    }

    load() {
        this.spinner.show('table-visits');

        let url = `dealers/map-list?vendor=${this.filter.vendor}`;
        let cityUrl = `cities/map-list?vendor=${this.filter.vendor}`;

        if (this.filter.channel) {
            url += `&filter[default_position]=${this.filter.channel}`;
            cityUrl += `&filter[source_position]=${this.filter.channel}`;
        }

        if (this.filter.cluster) {
            url += `&filter[default_priority]=${this.filter.cluster}`;
            cityUrl += `&filter[default_priority]=${this.filter.cluster}`;
        }
        if (this.filter.agent_id) {
            url += `&filter[default_id_agente]=${this.filter.agent_id}`;
        }

        if (this.filter.masteragent_id) {
            url += `&filter[default_id_areamanager]=${this.filter.masteragent_id}`;
        }

        if (this.filter.province_id) {
            url += `&filter[default_id_provincia]=${this.filter.province_id}`;
            cityUrl += `&filter[default_id_provincia]=${this.filter.province_id}`;
        }
        if (this.filter.region_id) {
            url += `&filter[default_id_regione]=${this.filter.region_id}`;
            cityUrl += `&filter[default_id_regione]=${this.filter.region_id}`;
        }

        this.visitsData = [];
        this.cityData = [];
        this.tableUrl = url;
        this.tableCityUrl = cityUrl;
    }

    selectedDate(date: Moment) {
        this.filter.date = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.apiDate = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    }

    changeRegion(event) {
        this.filter.region_id = event;
        if (!event) {
            this.filter.province_id = '';
        }
    }

    changeProvince(event) {
        this.filter.province_id = event;
        if (!event) {
            this.filter.region_id = '';
            this.filter.city_id = '';
        }
    }

    setMapPins(data: any) {
        this.visitsData = data;
    }

    setVendor($event: any) {
        this.filter.vendor = $event;
        this.load();
    }

    setCityMapPins($event: any) {
        this.cityData = $event;
    }

    getCityIconUrl(city: any) {
        switch (city.default_priority) {
            case 'VERDI':
                return 'http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_green.png';
            case 'GIALLI':
                return 'http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_yellow.png';
            case 'ROSSI':
            case 'NO-SELL':
            default:
                return 'http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_red.png';
        }
    }
}
