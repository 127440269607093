import { CostsManagementComponent } from './pages/gestione-incentivazione/costs-management/costs-management.component';
import { KenaVisiteComponent } from './pages/kena-visite/kena-visite.component';
import { KenaRecapComponent } from './pages/kena-recap/kena-recap.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { ShopIndexComponent } from '@app/pages/shop/index/shop-index.component';
import { FaqListComponent } from '@app/pages/faq/list/faq-list.component';
import { TicketListComponent } from '@app/pages/tickets/list/ticket-list.component';
import { TicketDetailComponent } from '@app/pages/tickets/detail/ticket-detail.component';
import { NotFoundComponent } from '@app/pages/main/not-found/not-found.component';
import { ResetPasswordComponent } from '@app/pages/main/reset-password/reset-password.component';
import { CreditoIndexComponent } from '@app/pages/credito/index/credito-index.component';
import { ShopBuyComponent } from '@app/pages/shop/buy/shop-buy.component';
import { ShopDetailComponent } from '@app/pages/shop/detail/shop-detail.component';
import { ShopListComponent } from '@app/pages/shop/list/shop-list.component';
import { VideoIndexComponent } from '@app/pages/video/index/video-index.component';
import { DocumentsListComponent } from '@app/pages/documents/list/documents-list.component';
import { environment } from '@environments/environment';
import { ShopBlockedComponent } from '@app/pages/shop/blocked/shop-blocked.component';
import { ShopPosComponent } from '@app/pages/shop/pos/shop-pos.component';
import { RoleGuard } from '@guards/role.guard';
import { ROLE_AGENT, ROLE_DEALER, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_OPERATOR, ROLE_SUBMASTER } from '@models/user';
import { MasterDashboardComponent } from '@app/pages/master-dashboard/master-dashboard.component';
import { MasterProduzionePersaComponent } from '@app/pages/master-produzione-persa/master-produzione-persa.component';
import { MasterAnalisiPlafondComponent } from '@app/pages/master-analisi-plafond/master-analisi-plafond.component';
import { MasterAnalisiSimComponent } from '@app/pages/master-analisi-sim/master-analisi-sim.component';
import { MasterClassificaComponent } from '@app/pages/master-classifica/master-classifica.component';
import { MasterNuoveAffiliazioniComponent } from '@app/pages/master-nuove-affiliazioni/master-nuove-affiliazioni.component';
import { MasterEmailComponent } from '@app/pages/master-email/master-email.component';
import { GestioneIncentivazioneComponent } from '@app/pages/gestione-incentivazione/gestione-incentivazione.component';
import { GestioneStorniComponent } from '@app/pages/gestione-incentivazione/gestione-storni/gestione-storni.component';
import { GestioneReportComponent } from '@app/pages/gestione-incentivazione/gestione-report/gestione-report.component';
import { MasterDealerDettaglioComponent } from '@app/pages/master-dealer-dettaglio/master-dealer-dettaglio.component';
import { AgentVisiteIndexComponent } from '@app/pages/agent-visite/agent-visite-index/agent-visite-index.component';
import { MasterVisiteIndexComponent } from '@app/pages/master-visite/master-visite-index/master-visite-index.component';
import { MasterVisiteDetailComponent } from '@app/pages/master-visite/master-visite-detail/master-visite-detail.component';
import { MasterTicketsComponent } from '@app/pages/master-tickets/master-tickets.component';
import { AffilationsComponent } from './pages/affiliations/affilations.component';
import { CbiGeneratorComponent } from './pages/cbi-generator/cbi-generator.component';
import { InvoiceManagementComponent } from './pages/invoice-management/invoice-management.component';
import { TicketManagementComponent } from './pages/ticket-management/ticket-management.component';
import { RetailerManagementComponent } from './pages/retailer-management/retailer-management.component';
import { MasterPlanningComponent } from './pages/master-planning/master-planning.component';
import { MENU_TICKET } from '@models/menu';
import { VendorGuard } from '@guards/vendor.guard';
import { A2A, ENI_GAS_E_LUCE, KENA_MOBILE, KENA_MOBILE_STANDS, SKY, TIM, TISCALI_MOBILE } from '@models/vendor';
import { ShopOrderDashboardComponent } from '@app/pages/shop-order-dashboard/shop-order-dashboard.component';
import { PbxCampaignComponent } from '@app/pages/pbx/pbx-campaign/pbx-campaign.component';
import { PbxQuestionsComponent } from '@app/pages/pbx/pbx-questions/pbx-questions.component';
import { TicketDashboardComponent } from '@app/pages/ticket-dashboard/ticket-dashboard.component';
import { ProductsComponent } from '@app/pages/e-pay/products/products.component';
import { BrandsComponent } from '@app/pages/e-pay/brands/brands.component';
import { OrdersComponent } from '@app/pages/e-pay/orders/orders.component';
import { AgentGpsTrackingComponent } from '@app/pages/agent-gps-tracking/agent-gps-tracking.component';
import { CategoryComponent } from '@app/pages/shop/category/category.component';
import { OperatorComponent } from '@app/pages/shop/operator/operator.component';
import { ShopProductComponent } from '@app/pages/shop/shop-product/shop-product.component';
import { EpaySalesComponent } from '@app/pages/e-pay/epay-sales/epay-sales.component';
import { DealerMapComponent } from '@app/pages/dealer-map/dealer-map.component';
import { EMailCampaignComponent } from '@app/pages/e-mail-campaign/e-mail-campaign.component';
import { ShopSalesComponent } from '@app/pages/shop/shop-sales/shop-sales.component';
import { ChangePasswordComponent } from '@app/pages/main/change-password/change-password.component';
import { PasswordGuard } from '@guards/password.guard';
import { NotFoundGuard } from '@guards/not-found.guard';
import { PopupComponent } from '@app/pages/popup/popup.component';
import { ShopOrderHistoryComponent } from '@app/pages/shop-order-history/shop-order-history.component';
import { ShopCreditComponent } from '@app/pages/shop-credit/shop-credit.component';
import { ShopAccessComponent } from '@app/pages/shop-access/shop-access.component';
import { DigitalSignatureComponent } from '@app/pages/kd-orders/digital-signature/digital-signature.component';
import { CertificatesComponent } from '@app/pages/kd-orders/certificates/certificates.component';
import { ShippingComponent } from '@app/pages/kd-orders/shipping/shipping.component';
import { PackagesComponent } from '@app/pages/kd-orders/packages/packages.component';
import { PecComponent } from '@app/pages/kd-orders/pec/pec.component';
import { TicketsComponent } from '@app/pages/kd-orders/tickets/tickets.component';
import { PrintingListComponent } from '@app/pages/kd-orders/printing-list/printing-list.component';
import { LoginVerificationComponent } from '@app/pages/login-verification/login-verification.component';
import { PostalSlipComponent } from '@app/pages/kd-orders/postal-slip/postal-slip.component';
import { KdSalesComponent } from '@app/pages/kd-orders/kd-sales/kd-sales.component';
import { InvoiceTypesComponent } from '@app/pages/invoices/invoice-types/invoice-types.component';
import { InvoiceUploadComponent } from '@app/pages/invoices/invoice-upload/invoice-upload.component';
import { SuretyCreditComponent } from '@app/pages/surety-credit/surety-credit.component';
import { BulkAssignDealerComponent } from '@app/pages/bulk-assign-dealer/bulk-assign-dealer.component';
import { ReadyMailComponent } from '@app/pages/kd-orders/ready-mail/ready-mail.component';
import { AmazonCounterComponent } from '@app/pages/kd-orders/amazon-counter/amazon-counter.component';
import { SpidIrComponent } from './pages/kd-orders/spid-ir/spid-ir.component';
import { AmexComponent } from './pages/kd-orders/amex/amex.component';
import { SddComponent } from './pages/kd-orders/sdd/sdd.component';
import { UserListComponent } from '@app/pages/user-list/user-list.component';
import { SpidCreditComponent } from './pages/spid-credit/spid-credit.component';
import { RiaComponent } from './pages/kd-orders/ria/ria.component';
import { A2aComponent } from './pages/a2a/a2a.component';
import { KdTaxComponent } from './pages/kd-orders/kd-tax/kd-tax.component';
import { CuComponent } from './pages/cu/cu.component';
import { FlixbusComponent } from './pages/flixbus/flixbus.component';
import { PartnershipComponent } from './pages/partnership/partnership.component';
import { ControlloEcTimComponent } from './pages/controllo-ec-tim/controllo-ec-tim.component';
import { OrdersControlComponent } from './pages/orders-control/orders-control.component';
import { IntegraComponent } from './pages/kd-orders/integra/integra.component';
import { GiftCardComponent } from './pages/kd-orders/gift-card/gift-card.component';
import { KdcubeComponent } from './pages/kdcube/kdcube.component';
import { A2aContrattiComponent } from './pages/a2a-contratti/a2a-contratti.component';
import { KpiTypeComponent } from './pages/kpi-type/kpi-type.component';
import { KpiAnalisiComponent } from './pages/kpi-analisi/kpi-analisi.component';
import { OlivettiComponent } from '@app/pages/kd-orders/olivetti/olivetti.component';
import { KaskoComponent } from './pages/kd-orders/kasko/kasko.component';
import { VisureCameraliComponent } from './pages/kd-orders/visure-camerali/visure-camerali.component';
import { VisureCatastaliComponent } from './pages/kd-orders/visure-catastali/visure-catastali.component';
import { KpiRecapComponent } from './pages/kpi-recap/kpi-recap.component';
import { DvaComponent } from '@app/pages/kd-orders/dva/dva.component';
import { ShopAffiliationListComponent } from './pages/shop-affiliation-list/shop-affiliation-list.component';
import { ShopListDetailComponent } from './pages/shop-affiliation-list/shop-list-detail/shop-list-detail.component';
import { KpiNotesComponent } from './pages/kpi-notes/kpi-notes.component';
import { SuretyOrdersComponent } from '@app/pages/surety-credit/surety-orders/surety-orders.component';

import { MailGeneratorComponent } from '@app/pages/mail-generator/mail-generator.component';
import { MailDetailComponent } from '@app/pages/mail-generator/mail-detail/mail-detail.component';
import { MailLogComponent } from '@app/pages/mail-generator/mail-log/mail-log.component';
import { CalendarioVisiteComponent } from './pages/calendario-visite/calendario-visite.component';
import { WhatsappGeneratorComponent } from './pages/whatsapp-generator/whatsapp-generator.component';
import { WhatsappDetailComponent } from './pages/whatsapp-generator/whatsapp-detail/whatsapp-detail.component';
import { WhatsappLogComponent } from './pages/whatsapp-generator/whatsapp-log/whatsapp-log.component';
import { MatchRagsocialeComponent } from '@app/pages/match-ragsociale/match-ragsociale.component';
import { SuretyRequestsComponent } from './pages/surety-requests/surety-requests.component';
import { RendicontiAddebitoComponent } from '@app/pages/rendiconti-addebito/rendiconti-addebito.component';
import { GroupComponent } from '@app/pages/group/group.component';
import { KdAssicuraComponent } from './pages/kd-orders/kd-assicura/kd-assicura.component';
import { KpiDashboardComponent } from './pages/kpi-dashboard/kpi-dashboard.component';
import { BusinessUnitsComponent } from '@app/pages/business-plan/business-units/business-units.component';
import { BusinessUnitEditorComponent } from '@app/pages/business-plan/business-unit-editor/business-unit-editor.component';
import { BusinessUnitViewerComponent } from '@app/pages/business-plan/business-plan-viewer/business-unit-viewer.component';

const ROUTES: Routes = [
    {
        path: '',
        component: DashboardComponent,
        data: {
            label: 'Home',
            menu: environment.production,
            icon: 'fa fa-tachometer-alt',
        },
    },
    {
        path: 'kpi-dashboard',
        component: KpiDashboardComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Dashboard KPI',
            menu: environment.production,
            icon: 'fa fa-tachometer-alt',
            roles: [ROLE_MASTER],
        },
    },
    {
        path: 'business-plan',
        canActivate: [RoleGuard],
        data: {
            label: 'Business Plan',
            menu: environment.production,
            icon: 'fa fa-location-arrow',
            roles: [ROLE_MASTER],
        },
        children: [
            {
                path: 'business-units',
                component: BusinessUnitsComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Business Units',
                    menu: environment.production,
                    icon: 'fa fa-user-cog',
                    roles: [ROLE_MASTER],
                },
            },
            {
                path: 'business-units/edit/:id',
                component: BusinessUnitEditorComponent,
                canActivate: [RoleGuard],
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_MASTER],
                },
            },
            {
                path: 'business-units/view',
                component: BusinessUnitViewerComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Piano Industriale',
                    menu: environment.production,
                    icon: 'fa fa-user-cog',
                    roles: [ROLE_MASTER],
                },
            },
        ],
    },
    {
        path: 'retailers/list',
        component: RetailerManagementComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Gestione Negozi',
            menu: environment.production,
            icon: 'fa fa-user-cog',
            roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_AGENT],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE, A2A],
        },
    },
    {
        path: 'partners/list',
        component: UserListComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Lista Partner',
            menu: environment.production,
            icon: 'fa fa-user-cog',
            roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER],
        },
    },
    {
        path: '',
        canActivate: [RoleGuard],
        data: {
            label: 'Visite',
            menu: environment.production,
            icon: 'fa fa-location-arrow',
            roles: [ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_MASTER],
        },
        children: [
            {
                path: 'shop-affiliation-list',
                component: ShopAffiliationListComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Gestione Liste',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_MASTER],
                },
            },
            {
                path: 'calendario',
                component: CalendarioVisiteComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Calendario Visite',
                    menu: environment.production,
                    icon: 'fa fa-calendar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER],
                },
            },
            {
                path: 'visite-riepilogo',
                component: MasterVisiteIndexComponent,
                canActivate: [RoleGuard, VendorGuard],
                data: {
                    label: 'Riepilogo Visite',
                    menu: environment.production,
                    icon: 'fa fa-route',
                    roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
                    vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE, A2A],
                },
            },
            {
                path: 'gps-tracking',
                component: AgentGpsTrackingComponent,
                canActivate: [RoleGuard, VendorGuard],
                data: {
                    label: 'GPS tracking',
                    menu: environment.production,
                    icon: 'fa fa-map-marked-alt',
                    roles: [ROLE_MASTER, ROLE_OPERATOR],
                    vendor: [KENA_MOBILE],
                },
            },
        ],
    },
    {
        path: 'visite-riepilogo/:id',
        component: MasterVisiteDetailComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'visite-riepilogo/:id/:visit_id',
        component: MasterVisiteDetailComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'shop-list-detail/:id',
        component: ShopListDetailComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_MASTER],
        },
    },
    {
        path: 'ticket-management',
        component: TicketManagementComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Gestione Ticket',
            menu: environment.production,
            icon: 'fa fa-grip-horizontal',
            roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER],
            badges: MENU_TICKET,
        },
    },
    {
        path: 'shop-order-history',
        component: ShopOrderHistoryComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Orders Management',
            menu: environment.production,
            icon: 'fa fa-cogs',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'dealer-map',
        component: DealerMapComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Map analytics',
            menu: environment.production,
            icon: 'fa fa-map-marked-alt',
            roles: [ROLE_OPERATOR, ROLE_MASTER, ROLE_SUBMASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE, A2A],
        },
    },
    {
        path: 'e-commerce',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'KDRicarica',
            menu: environment.production,
            icon: 'fa fa-wallet',
            roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_DEALER],
            vendor: [KENA_MOBILE],
        },
        children: [
            {
                path: 'sales',
                component: EpaySalesComponent,
                data: {
                    label: 'Sales Dashboard',
                    menu: environment.production,
                    icon: 'fa fa-chart-bar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'products',
                component: ProductsComponent,
                data: {
                    label: 'Products',
                    menu: environment.production,
                    icon: 'fa fa-table',
                    roles: [ROLE_MASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'brands',
                component: BrandsComponent,
                data: {
                    label: 'Brands',
                    menu: environment.production,
                    icon: 'fa fa-table',
                    roles: [ROLE_MASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'orders',
                component: OrdersComponent,
                data: {
                    label: 'Orders',
                    menu: environment.production,
                    icon: 'fa fa-table',
                    roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_DEALER],
                },
            },
        ],
    },
    {
        path: 'invoices',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Invoices',
            menu: environment.production,
            icon: 'fa fa-file-invoice',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
        children: [
            {
                path: 'controllo-ec-tim',
                component: ControlloEcTimComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'EC Tim',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'controllo-ordini',
                component: OrdersControlComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Controllo ordini',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'proforma',
                component: InvoiceManagementComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Lista Proforma',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'proforma-types',
                component: InvoiceTypesComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Tipologie Voci',
                    menu: environment.production,
                    icon: 'fa fa-tools',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'cu',
                component: CuComponent,
                data: {
                    label: 'Le tue CU',
                    menu: environment.production,
                    icon: 'fa fa-file-invoice',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'proforma-upload',
                component: InvoiceUploadComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Carica/Sblocca Fatture',
                    menu: environment.production,
                    icon: 'fa fa-upload',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'sdd',
                component: SddComponent,
                data: {
                    label: 'SDD',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'rendiconti-addebito',
                component: RendicontiAddebitoComponent,
                data: {
                    label: 'Rendiconti di Addebito',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            // start
            // {
            //     path: 'subjects',
            //     component: SubjectsComponent,
            //     data: {
            //         label: 'Subjects',
            //         menu: environment.production,
            //         icon: 'fa fa-file-invoice-dollar',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            // {
            //     path: 'documents',
            //     component: DocumentsComponent,
            //     data: {
            //         label: 'Documents',
            //         menu: environment.production,
            //         icon: 'fa fa-file-invoice-dollar',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            // {
            //     path: 'payments',
            //     component: PaymentComponent,
            //     data: {
            //         label: 'Payments',
            //         menu: environment.production,
            //         icon: 'fa fa-file-invoice-dollar',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            // {
            //     path: 'bank-transaction',
            //     component: BankTransactionComponent,
            //     data: {
            //         label: 'Bank Incomes/Outcomes',
            //         menu: environment.production,
            //         icon: 'fa fa-file-invoice-dollar',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            // {
            //     path: 'bank-account',
            //     component: BankAccountComponent,
            //     data: {
            //         label: 'Bank Accounts',
            //         menu: environment.production,
            //         icon: 'fas fa-university',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            // {
            //     path: 'dashboard',
            //     component: InvoiceDashboardComponent,
            //     data: {
            //         label: 'Dashboard',
            //         menu: environment.production,
            //         icon: 'fa fa-tachometer-alt',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            // {
            //     path: 'report',
            //     component: InvoiceReportComponent,
            //     data: {
            //         label: 'Report',
            //         menu: environment.production,
            //         icon: 'fas fa-chart-pie',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            // {
            //     path: 'calendar',
            //     component: CalendarComponent,
            //     data: {
            //         label: 'Calendar',
            //         menu: environment.production,
            //         icon: 'fas fa-calendar',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            // {
            //     path: 'subject-report',
            //     component: SubjectReportComponent,
            //     data: {
            //         label: 'Subject Report',
            //         menu: environment.production,
            //         icon: 'fas fa-chart-pie',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // }
            //end
        ],
    },
    {
        path: 'affiliazioni',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Affiliazioni',
            menu: environment.production,
            icon: 'fa fa-user-plus',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE, A2A],
        },
        children: [
            {
                path: 'dashboard',
                component: MasterNuoveAffiliazioniComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Dashboard',
                    menu: environment.production,
                    icon: 'fa fa-chart-line',
                    roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'lista',
                component: AffilationsComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Gestione',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_MASTER, ROLE_AGENT, ROLE_OPERATOR],
                },
            },
            {
                path: 'bulk',
                component: BulkAssignDealerComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Assegna Massivamente',
                    menu: environment.production,
                    icon: 'fa fa-user-plus',
                    roles: [ROLE_MASTER, ROLE_OPERATOR],
                },
            },
        ],
    },
    {
        path: 'kpi',
        canActivate: [RoleGuard],
        data: {
            label: 'KPI & Target',
            menu: environment.production,
            icon: 'fa fa-bullseye',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR, ROLE_AGENT],
        },
        children: [
            {
                path: 'tipo-kpi',
                component: KpiTypeComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Tipo KPI',
                    menu: environment.production,
                    icon: 'fa fa-list-alt',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'analisi-kpi',
                component: KpiAnalisiComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Analisi KPI',
                    menu: environment.production,
                    icon: 'fa fa-list-alt',
                    roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR, ROLE_AGENT],
                },
            },
            {
                path: 'recap-kpi',
                component: KpiRecapComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Recap KPI',
                    menu: environment.production,
                    icon: 'fa fa-list-alt',
                    roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'note-kpi',
                component: KpiNotesComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Note KPI',
                    menu: environment.production,
                    icon: 'fa fa-list-alt',
                    roles: [ROLE_MASTER, ROLE_OPERATOR],
                },
            },
        ],
    },
    {
        path: 'negozio',
        canActivate: [RoleGuard],
        data: {
            label: 'Negozio',
            menu: environment.production,
            icon: 'fa fa-shopping-basket',
            roles: [ROLE_DEALER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTER],
        },
        children: [
            {
                path: 'acquista',
                component: ShopIndexComponent,
                canActivate: [VendorGuard],
                data: {
                    label: 'Acquista',
                    menu: environment.production,
                    icon: 'fa fa-shopping-cart',
                    vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
                },
            },
            {
                path: 'carrello',
                component: ShopBuyComponent,
                canActivate: [VendorGuard],
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
                },
            },
            {
                path: 'bloccato',
                component: ShopBlockedComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'pos/:id',
                component: ShopPosComponent,
                canActivate: [VendorGuard],
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
                },
            },
            {
                path: 'dettaglio/:id',
                component: ShopDetailComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'lista-ordini',
                component: ShopListComponent,
                data: {
                    label: 'Lista Ordini',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                },
            },
            {
                path: 'category',
                component: CategoryComponent,
                data: {
                    label: 'Categories',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                    menu: environment.production,
                    icon: 'fa fa-list',
                },
            },
            {
                path: 'brand',
                component: OperatorComponent,
                data: {
                    label: 'Brands',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                    menu: environment.production,
                    icon: 'fa fa-list',
                },
            },
            {
                path: 'product',
                component: ShopProductComponent,
                data: {
                    label: 'Products',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                    menu: environment.production,
                    icon: 'fa fa-list',
                },
            },
        ],
    },
    {
        path: 'e-mail-campaign',
        component: EMailCampaignComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Mailchimp',
            menu: environment.production,
            icon: 'far fa-envelope',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'gestione-crediti',
        canActivate: [RoleGuard],
        data: {
            label: 'Gestione crediti',
            menu: environment.production,
            icon: 'fa fa-wallet',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
        children: [
            {
                path: 'gestione-credito',
                component: CreditoIndexComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Gestione Credito',
                    menu: environment.production,
                    icon: 'fa fa-wallet',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'gestione-credito/:id',
                component: CreditoIndexComponent,
                canActivate: [RoleGuard],
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'shop-credit',
                component: ShopCreditComponent,
                data: {
                    label: 'Shop Credit',
                    menu: environment.production,
                    icon: 'fa fa-euro-sign',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'shop-credit/:id',
                component: ShopCreditComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'spid-credit',
                component: SpidCreditComponent,
                data: {
                    label: 'Spid credit',
                    menu: environment.production,
                    icon: 'fa fa-euro-sign',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'spid-credit/:id',
                component: SpidCreditComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'surety-credit',
                component: SuretyCreditComponent,
                data: {
                    label: 'Gestione Fido',
                    menu: environment.production,
                    icon: 'fa fa-hand-holding-usd',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'surety-orders',
                component: SuretyOrdersComponent,
                data: {
                    label: 'Ordini con fido',
                    menu: environment.production,
                    icon: 'fa fa-clock',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'surety-credit/:id/:surety_type',
                component: SuretyCreditComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
        ],
    },
    {
        path: 'shop-access',
        component: ShopAccessComponent,
        data: {
            label: 'Dealer Pro',
            menu: environment.production,
            icon: 'fa fa-credit-card',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
    },
    // {
    //     path: 'races',
    //     component: RacesComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: 'Gare',
    //         menu: environment.production,
    //         icon: 'fa fa-user',
    //         roles: [ROLE_OPERATOR],
    //     },
    // },
    {
        path: 'group',
        component: GroupComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Gruppi di negozi',
            menu: environment.production,
            icon: 'fa fa-users',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
    },
    {
        path: 'kdcube',
        canActivate: [RoleGuard],
        data: {
            label: 'KDCube',
            menu: environment.production,
            icon: 'fa fa-box',
            roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER],
        },
        children: [
            {
                path: 'kdcube',
                component: KdcubeComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Scadenze Abbonamenti',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER],
                },
            },
            {
                path: 'kd-sales',
                canActivate: [RoleGuard],
                component: KdSalesComponent,
                data: {
                    label: 'Vendite KDCube',
                    menu: environment.production,
                    icon: 'fa fa-chart-bar',
                    roles: [ROLE_OPERATOR, ROLE_SUBMASTER, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_AGENT],
                },
            },
        ],
    },
    {
        path: 'amex',
        component: AmexComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Amex',
            menu: environment.production,
            icon: 'fa fa-list',
            roles: [ROLE_AGENT, ROLE_MASTERAGENT],
        },
    },
    {
        path: 'kd-orders',
        canActivate: [RoleGuard],
        data: {
            label: 'Servizi KD',
            menu: environment.production,
            icon: 'fa fa-list',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
        children: [
            {
                path: 'bollettini',
                component: PostalSlipComponent,
                data: {
                    label: 'Bollettini',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'flixbus',
                component: FlixbusComponent,
                data: {
                    label: 'Flixbus',
                    menu: environment.production,
                    icon: 'fa fa-bus',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            // {
            //     path: 'dva',
            //     component: DvaComponent,
            //     data: {
            //         label: 'Dva',
            //         menu: environment.production,
            //         icon: 'fa fa-box',
            //         roles: [ROLE_OPERATOR, ROLE_MASTER],
            //     },
            // },
            {
                path: 'gift-card',
                component: GiftCardComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Gift card',
                    menu: environment.production,
                    icon: 'fa fa-star',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'kaskopoint',
                component: KaskoComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'KaskoPoint',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'olivetti',
                component: OlivettiComponent,
                data: {
                    label: 'Olivetti',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'ria',
                component: RiaComponent,
                data: {
                    label: 'Ria',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'kd-tax',
                component: KdTaxComponent,
                data: {
                    // label: 'KD Tax',
                    // menu: environment.production,
                    // icon: 'fa fa-list',
                    // roles: [ROLE_OPERATOR],
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'partnership',
                component: PartnershipComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Partnership',
                    menu: environment.production,
                    icon: 'fa fa-user-cog',
                    roles: [ROLE_MASTER],
                },
            },
            {
                path: 'integra',
                component: IntegraComponent,
                data: {
                    // label: 'Integra',
                    // menu: environment.production,
                    // icon: 'fa fa-list',
                    // roles: [ROLE_OPERATOR],
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'spid',
                component: DigitalSignatureComponent,
                data: {
                    label: 'SPID',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'spid-ir',
                component: SpidIrComponent,
                data: {
                    label: 'SPID IR',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'visure-camerali',
                component: VisureCameraliComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'visure-catastali',
                component: VisureCatastaliComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'certificati',
                component: CertificatesComponent,
                data: {
                    label: 'Certificati',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'ready-mail',
                component: ReadyMailComponent,
                data: {
                    label: 'Raccomandate',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'raccomandate',
                component: ShippingComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'packages',
                component: PackagesComponent,
                data: {
                    label: 'Pacchi',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'printing',
                component: PrintingListComponent,
                data: {
                    label: 'KDPrint',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'pec',
                component: PecComponent,
                data: {
                    label: 'Pec',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'tickets',
                component: TicketsComponent,
                data: {
                    label: 'Tickets',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'amazon-counter',
                component: AmazonCounterComponent,
                data: {
                    label: 'Amazon Counter',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'kd-assicura',
                component: KdAssicuraComponent,
                data: {
                    label: 'KDAssicura',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'amex',
                component: AmexComponent,
                data: {
                    label: 'Amex',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTER],
                },
            },
        ],
    },
    {
        path: 'cbi',
        component: CbiGeneratorComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Gestione Pagamenti',
            menu: environment.production,
            icon: 'fa fa-cash-register',
            roles: [ROLE_MASTER],
        },
    },
    {
        path: 'piano-incentivi',
        canActivate: [RoleGuard],
        data: {
            label: 'Incentivazione',
            menu: environment.production,
            icon: 'fa fa-list',
            roles: [ROLE_MASTER],
        },
        children: [
            {
                path: 'settings',
                component: GestioneIncentivazioneComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Imposta Gare',
                    menu: environment.production,
                    icon: 'fa fa-wrench',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'storni',
                component: GestioneStorniComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Storni',
                    menu: environment.production,
                    icon: 'fa fa-search-dollar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'report',
                component: GestioneReportComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Report',
                    menu: environment.production,
                    icon: 'fa fa-list-alt',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'costi',
                component: CostsManagementComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Costi',
                    menu: environment.production,
                    icon: 'fa fa-list-alt',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
        ],
    },
    {
        path: 'a2a',
        data: {
            canActivate: [RoleGuard, VendorGuard],
            label: 'A2A',
            menu: environment.production,
            icon: 'fa fa-list',
            roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_MASTER],
            vendor: [A2A],
        },
        children: [
            {
                path: 'candidature',
                component: A2aComponent,
                data: {
                    label: 'Candidature',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'ordini',
                component: A2aContrattiComponent,
                data: {
                    label: 'Lista Ordini',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_MASTER],
                },
            },
        ],
    },
    // {
    //     path: 'a2a',
    //     component: A2aComponent,
    //     data: {
    //         label: 'A2A',
    //         menu: environment.production,
    //         icon: 'fa fa-list',
    //         roles: [ROLE_OPERATOR],
    //     },
    // },
    // {
    //     path: 'gestione-fatturazione',
    //     component: GestioneFatturazioneComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: 'Fatturazione',
    //         menu: environment.production,
    //         icon: 'fa fa-file-invoice',
    //         roles: [ROLE_OPERATOR, ROLE_MASTER],
    //     },
    // },
    {
        path: 'dashboard',
        component: MasterDashboardComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'kena',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Kena Mobile',
            menu: environment.production,
            icon: 'fa fa-table',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
            vendor: [KENA_MOBILE],
        },
        children: [
            {
                path: 'recap',
                component: KenaRecapComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Recap',
                    menu: environment.production,
                    icon: 'fa fa-border-all',
                    roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'visite',
                component: KenaVisiteComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Visite',
                    menu: environment.production,
                    icon: 'fa fa-map-signs',
                    roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
                },
            },
        ],
    },
    {
        path: 'ragsociale/list',
        component: MatchRagsocialeComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Match Anagrafiche EC',
            menu: environment.production,
            icon: 'fa fa-user-cog',
            roles: [ROLE_MASTER],
        },
    },
    {
        path: 'planning',
        component: MasterPlanningComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Planning',
            menu: environment.production,
            icon: 'fa fa-user',
            roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_AGENT],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE, KENA_MOBILE_STANDS],
        },
    },
    // {
    //     path: 'kpi-old',
    //     component: MasterKpiComponent,
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'KPI & Target',
    //         menu: environment.production,
    //         icon: 'fa fa-bullseye',
    //         roles: [ROLE_AGENT, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
    //         vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE, KENA_MOBILE_STANDS],
    //     },
    // },
    {
        path: 'visite',
        canActivate: [RoleGuard],
        data: {
            label: 'Visite',
            menu: environment.production,
            icon: 'fa fa-route',
            roles: [ROLE_AGENT],
        },
        children: [
            {
                path: 'shop-affiliation-list',
                component: ShopAffiliationListComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Gestione Liste',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_AGENT ],
                },
            },
            {
                path: 'gestione',
                component: AgentVisiteIndexComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Gestione Visite',
                    menu: environment.production,
                    icon: 'fa fa-map-marker-alt',
                    roles: [ROLE_AGENT],
                },
            },
            {
                path: 'panoramica',
                component: MasterVisiteDetailComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Panoramica Visite',
                    menu: environment.production,
                    icon: 'fa fa-wrench',
                    roles: [ROLE_AGENT],
                },
            },
            {
                path: 'calendario',
                component: CalendarioVisiteComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Calendario Visite',
                    menu: environment.production,
                    icon: 'fa fa-calendar',
                    roles: [ROLE_AGENT],
                },
            },
        ],
    },
    {
        path: 'brain',
        component: MasterEmailComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Automatismi',
            menu: environment.production,
            icon: 'fa fa-brain',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
            vendor: [KENA_MOBILE],
        },
    },
    {
        path: 'mail-generator',
        component: MailGeneratorComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Gestione Mail',
            menu: environment.production,
            icon: 'fa fa-envelope',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'subscription/:id',
        component: MailDetailComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
    },
    {
        path: 'log/:id',
        component: MailLogComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
    },
    {
        path: 'wa-generator',
        component: WhatsappGeneratorComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Gestione Whatsapp',
            menu: environment.production,
            icon: 'fab fa-whatsapp',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'wa-subscription/:id/:sendType',
        component: WhatsappDetailComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
    },
    {
        path: 'wa-log/:id',
        component: WhatsappLogComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
    },
    {
        path: 'produzione-persa',
        component: MasterProduzionePersaComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Produzione Persa',
            menu: environment.production,
            icon: 'fa fa-search-minus',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    // {
    //     path: 'analisi-fasce',
    //     component: MasterAnalisiFasceComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: 'Analisi Fasce',
    //         menu: environment.production,
    //         icon: 'fa fa-list-ol',
    //         roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
    //     },
    // },
    {
        path: 'analisi-plafond',
        component: MasterAnalisiPlafondComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Analisi Plafond',
            menu: environment.production,
            icon: 'fa fa-wallet',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
            vendor: [TISCALI_MOBILE, KENA_MOBILE],
        },
    },
    {
        path: 'analisi-sim',
        component: MasterAnalisiSimComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Analisi Sim',
            menu: environment.production,
            icon: 'fa fa-sim-card',
            vendor: [TISCALI_MOBILE, KENA_MOBILE],
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
        },
    },
    {
        path: 'classifica',
        component: MasterClassificaComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Analisi Produzione',
            menu: environment.production,
            icon: 'fa fa-sitemap',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'smartphone-sales',
        canActivate: [RoleGuard],
        component: ShopSalesComponent,
        data: {
            label: 'Vendite Smartphone',
            menu: environment.production,
            icon: 'fa fa-chart-bar',
            roles: [ROLE_OPERATOR, ROLE_SUBMASTER, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_AGENT],
        },
    },
    // {
    //     path: 'confronto',
    //     component: MasterConfrontoComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: 'Confronto',
    //         menu: environment.production,
    //         icon: 'fa fa-random',
    //         roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
    //     },
    // },
    {
        path: 'dealer/:id',
        component: MasterDealerDettaglioComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER],
        },
    },
    {
        path: 'dealer/number/:number',
        component: MasterDealerDettaglioComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER],
        },
    },
    {
        path: 'statistiche-ticket',
        component: MasterTicketsComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Statistiche Tickets',
            menu: environment.production,
            icon: 'fa fa-life-ring',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    // {
    //     path: 'sms',
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'Master SMS',
    //         menu: environment.production,
    //         icon: 'fa fa-sms',
    //         roles: [ROLE_MASTER, ROLE_OPERATOR],
    //         vendor: [KENA_MOBILE],
    //     },
    //     children: [
    //         {
    //             path: 'sales',
    //             component: SmsSalesComponent,
    //             data: {
    //                 label: 'Sales Dashboard',
    //                 menu: environment.production,
    //                 icon: 'fa fa-chart-bar',
    //                 roles: [ROLE_OPERATOR, ROLE_MASTER],
    //             },
    //         },
    //     ]
    // },
    {
        path: 'supporto',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Supporto',
            menu: environment.production,
            icon: 'fa fa-hands-helping',
            roles: [ROLE_DEALER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTER, ROLE_MASTERAGENT],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE, A2A],
        },
        children: [
            {
                path: 'ticket',
                component: TicketListComponent,
                data: {
                    label: 'Lista Ticket',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                },
            },
            {
                path: 'ticket/:id',
                component: TicketDetailComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'domande',
                component: FaqListComponent,
                data: {
                    label: 'Domande Frequenti',
                    menu: environment.production,
                    icon: 'fa fa-question-circle',
                },
            },
            {
                path: 'domande/:category',
                component: FaqListComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'video',
                component: VideoIndexComponent,
                data: {
                    label: 'Video',
                    menu: environment.production,
                    icon: 'fa fa-graduation-cap',
                },
            },
            {
                path: 'documenti',
                component: DocumentsListComponent,
                data: {
                    label: 'Documenti',
                    menu: environment.production,
                    icon: 'fa fa-folder-open',
                },
            },
        ],
    },
    // {
    //     path: 'campaigns',
    //     component: CampaignsComponent,
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'Campaigns',
    //         menu: environment.production,
    //         icon: 'fa fa-medal',
    //         roles: [ROLE_OPERATOR],
    //         vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
    //     }
    // },
    {
        path: 'pbx',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'PBX',
            menu: environment.production,
            icon: 'fa fa-list',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
        children: [
            {
                path: 'campaign',
                component: PbxCampaignComponent,
                data: {
                    label: 'Campaigns',
                    menu: environment.production,
                    icon: 'fa fa-headset ',
                },
            },
            {
                path: 'questions',
                component: PbxQuestionsComponent,
                data: {
                    label: 'Questions',
                    menu: environment.production,
                    icon: 'far fa-question-circle',
                },
            },
        ],
    },
    {
        path: 'popup',
        component: PopupComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Popup e Banner',
            menu: environment.production,
            icon: 'fas fa-exclamation-circle',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'shop-order-dashboard',
        component: ShopOrderDashboardComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Shop Orders Dashboard',
            menu: environment.production,
            icon: 'fa fa-tachometer-alt',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'ticket-dashboard',
        component: TicketDashboardComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Ticket Dashboard',
            menu: environment.production,
            icon: 'fa fa-tachometer-alt',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'profile',
        component: ProfileComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },
    {
        path: 'profile/:id',
        component: ProfileComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },
    {
        path: 'pagina-non-trovata',
        component: NotFoundComponent,
        canActivate: [NotFoundGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },
];

const APP_ROUTES: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: ROUTES,
    },
    {
        path: 'login',
        component: LoginComponent,
        canLoad: [
            {
                menu: false,
                icon: '',
            },
        ],
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canLoad: [
            {
                menu: false,
                icon: '',
            },
        ],
    },
    {
        path: 'login-verification',
        component: LoginVerificationComponent,
        canActivate: [PasswordGuard],
        canLoad: [
            {
                menu: false,
                icon: '',
            },
        ],
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [PasswordGuard],
        canLoad: [
            {
                menu: false,
                icon: '',
            },
        ],
    },
    { path: '**', redirectTo: '/pagina-non-trovata' },
];

@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES, { useHash: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
