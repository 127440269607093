import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';
import {ShopProductCategory} from '@models/e-pay/shop-product-category';
import {ShopBrand} from '@models/e-pay/shop-brand';

export class ShopProduct extends BaseModel {
    id: number;
    name: string;
    price: string;
    dealer_price: string;
    our_price: string;
    type: string;
    ean: string;
    pan: string;
    model: string;
    category_id: number;
    brand_id: number;
    image_name: string;
    image_path: string;
    vat_type: string;
    is_active: any;
    file: File;
    description: string;
    variants: ShopProductVariant[];
    brand: ShopBrand;
    category: ShopProductCategory;
    product_prices: ShopProductPrice[];

    fromArray(result: []) {
        const array: ShopProduct[] = [];
        for (const item of result) {
            array.push(new ShopProduct(item));
        }
        return array;
    }

    get postPayloadProduct() {
        const payload = new FormData();
        this.addToPayload(payload, this.name, 'name');
        this.addToPayload(payload, this.image_name, 'image_name');
        this.addToPayload(payload, this.price, 'price');
        this.addToPayload(payload, this.our_price, 'our_price');
        this.addToPayload(payload, this.dealer_price, 'dealer_price');
        this.addToPayload(payload, this.type, 'type');
        this.addToPayload(payload, this.ean, 'ean');
        this.addToPayload(payload, this.pan, 'pan');
        this.addToPayload(payload, this.model, 'model');
        this.addToPayload(payload, this.category_id, 'category_id');
        this.addToPayload(payload, this.brand_id, 'brand_id');
        this.addToPayload(payload, this.is_active, 'is_active');
        this.addToPayload(payload, this.file, 'file');
        this.addToPayload(payload, this.description, 'description');
        this.addToPayload(payload, this.vat_type, 'vat_type');
        this.addToPayload(payload, this.variants, 'variants');
        this.addToPayload(payload, this.product_prices, 'product_prices');
        return payload;
    }
}

export class ShopProductPagination extends BaseModel {
    data: ShopProduct[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ShopProductVariant {
    id: number;
    name: string;
    price: string;
    dealer_price: string;
    our_price: string;
    ean: string;
    pan: string;
    model: string;
    vat_type: string;
    description: any;
    product_prices: ShopProductPrice[];
}

export class ShopProductFile extends BaseModel {
    file: File;

    get postPayloadProductFile() {
        const payload = new FormData();
        this.addToPayload(payload, this.file, 'file');
        return payload;
    }
}

export class ShopProductPrice {
    id: number;
    dealer_id: number;
    product_id: number;
    amount: string;
    is_active: number;
    company_name: string;
}
