import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegraService {

  constructor(
    private request: RequestService, 
    public alertService: AlertService
  ) { }

  public getIntegraList(params?): Observable<any> {
    return this.request.get('v3/integra/list', params);
  }
  public getIntegraPractice(id) {
    return this.request.get('v3/integra/list', {id: id},  {responseType: 'blob'})
   }
 
  public putAIntegraList(id, body: {}) {
    return this.request.put('v3/integra/update/' + id, body);
  }

}
