<div class="card">
    <div class="card-header">
        <div class="card-title">
            Stai visualizzando l'anno:&nbsp;
            <a class=" click" (click)="openYearPopover(yearPopover)"
               #yearPopover="ngbPopover" autoClose="outside" triggers="manual"
               [ngbPopover]="yearPopContent" popoverTitle="Seleziona anno">{{ selectedYear }}</a>
            &nbsp;in modalità
            <a class=" click" (click)="openModePopover(modePopover)"
               #modePopover="ngbPopover" autoClose="outside" triggers="manual"
               [ngbPopover]="modePopContent" popoverTitle="Seleziona la modalità">{{ modes[selectedMode] }}</a>
        </div>
        <div class="card-tools">
            <button class="btn btn-sm btn-primary mr-2" (click)="toggleCompare()">
                {{ compareMode ? 'Normale' : 'Compara'}}
            </button>
            <button class="btn btn-sm btn-secondary" [routerLink]="['/business-plan/business-units']">Torna indietro</button>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <div class="card-title text-bold" *ngIf="!loading && businessPlan">Utile</div>
        <div class="card-title" *ngIf="loading && !businessPlan">Caricamento...</div>
    </div>
    <div class="card-body p-0">
        <app-spinner *ngIf="loading" status='loading'></app-spinner>
        <table class="table table-sm table-bordered" *ngIf="!loading && businessPlan">
            <thead>
            <tr>
                <th></th>
                <th class="text-center">Gen</th>
                <th class="text-center">Feb</th>
                <th class="text-center">Mar</th>
                <th class="text-center">Apr</th>
                <th class="text-center">Mag</th>
                <th class="text-center">Giu</th>
                <th class="text-center">Lug</th>
                <th class="text-center">Ago</th>
                <th class="text-center">Set</th>
                <th class="text-center">Ott</th>
                <th class="text-center">Nov</th>
                <th class="text-center">Dic</th>
                <th class="text-center">{{ selectedYear }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="businessPlan.business_units.length === 0">
                <td colspan="14" class="text-center text-secondary" >
                    Non ci sono dati da visualizzare
                </td>
            </tr>
            <tr *ngFor="let bu of businessPlan.business_units; let i = index;">
                <td>{{ bu.name }}</td>
                <td *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13]" class="text-right" [ngClass]="{'text-bold': i === 13, 'text-success': bu.net[i] > 0, 'text-danger': bu.net[i] < 0}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: bu.compare_percentages.net[i], tooltip: bu.compare.net[i] }"></ng-container>
                    {{ bu.net[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </td>
            </tr>
            <tr>
                <td colspan="14">&nbsp;</td>
            </tr>
            <tr>
                <th>Totale</th>
                <th *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13]" class="text-right text-bold" [ngClass]="{'text-success': businessPlan.total.net[i] > 0, 'text-danger': businessPlan.total.net[i] < 0}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: businessPlan.total.compare_percentages.net[i], tooltip: businessPlan.total.compare.net[i] }"></ng-container>
                    {{ businessPlan.total.net[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </th>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="card-title text-bold" *ngIf="!loading && businessPlan">Fatturato</div>
        <div class="card-title" *ngIf="loading && !businessPlan">Caricamento...</div>
    </div>
    <div class="card-body p-0">
        <app-spinner *ngIf="loading" status='loading'></app-spinner>
        <table class="table table-sm table-bordered" *ngIf="!loading && businessPlan">
            <thead>
            <tr>
                <th></th>
                <th class="text-center">Gen</th>
                <th class="text-center">Feb</th>
                <th class="text-center">Mar</th>
                <th class="text-center">Apr</th>
                <th class="text-center">Mag</th>
                <th class="text-center">Giu</th>
                <th class="text-center">Lug</th>
                <th class="text-center">Ago</th>
                <th class="text-center">Set</th>
                <th class="text-center">Ott</th>
                <th class="text-center">Nov</th>
                <th class="text-center">Dic</th>
                <th class="text-center">{{ selectedYear }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="businessPlan.business_units.length === 0">
                <td colspan="14" class="text-center text-secondary" >
                    Non ci sono dati da visualizzare
                </td>
            </tr>
            <tr *ngFor="let bu of businessPlan.business_units; let i = index;">
                <td>{{ bu.name }}</td>
                <td *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13]" class="text-right" [ngClass]="{'text-bold': i === 13, 'text-success': bu.revenue[i] > 0}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: bu.compare_percentages.revenue[i], tooltip: bu.compare.revenue[i] }"></ng-container>
                    {{ bu.revenue[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </td>
            </tr>
            <tr>
                <td colspan="14">&nbsp;</td>
            </tr>
            <tr>
                <th>Totale</th>
                <th *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13]" class="text-right text-bold" [ngClass]="{'text-success': businessPlan.total.revenue[i] > 0, 'text-danger': businessPlan.total.revenue[i] < 0}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: businessPlan.total.compare_percentages.revenue[i], tooltip: businessPlan.total.compare.revenue[i] }"></ng-container>
                    {{ businessPlan.total.revenue[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </th>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="card-title text-bold" *ngIf="!loading && businessPlan">Costi</div>
        <div class="card-title" *ngIf="loading && !businessPlan">Caricamento...</div>
    </div>
    <div class="card-body p-0">
        <app-spinner *ngIf="loading" status='loading'></app-spinner>
        <table class="table table-sm table-bordered" *ngIf="!loading && businessPlan">
            <thead>
            <tr>
                <th></th>
                <th class="text-center">Gen</th>
                <th class="text-center">Feb</th>
                <th class="text-center">Mar</th>
                <th class="text-center">Apr</th>
                <th class="text-center">Mag</th>
                <th class="text-center">Giu</th>
                <th class="text-center">Lug</th>
                <th class="text-center">Ago</th>
                <th class="text-center">Set</th>
                <th class="text-center">Ott</th>
                <th class="text-center">Nov</th>
                <th class="text-center">Dic</th>
                <th class="text-center">{{ selectedYear }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="businessPlan.business_units.length === 0">
                <td colspan="14" class="text-center text-secondary" >
                    Non ci sono dati da visualizzare
                </td>
            </tr>
            <tr *ngFor="let bu of businessPlan.business_units; let i = index;">
                <td>{{ bu.name }}</td>
                <td *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13]" class="text-right" [ngClass]="{'text-bold': i === 13, 'text-danger': bu.cost[i] > 0}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: bu.compare_percentages.cost[i], tooltip: bu.compare.cost[i] }"></ng-container>
                    {{ bu.cost[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </td>
            </tr>
            <tr>
                <td colspan="14">&nbsp;</td>
            </tr>
            <tr>
                <th>Totale</th>
                <th *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13]" class="text-right text-bold" [ngClass]="{'text-danger': businessPlan.total.cost[i] > 0}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: businessPlan.total.compare_percentages.cost[i], tooltip: businessPlan.total.compare.cost[i] }"></ng-container>
                    {{ businessPlan.total.cost[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </th>
            </tr>
            </tbody>
        </table>
    </div>
</div>


<ng-template #yearPopContent>
    <app-edit-popup [config]="yearPopupConfig" (onSave)="changeYear($event)"></app-edit-popup>
</ng-template>

<ng-template #modePopContent>
    <app-edit-popup [config]="modePopupConfig" (onSave)="changeMode($event)"></app-edit-popup>
</ng-template>

<ng-template #trend let-value let-tooltip="tooltip">
    <span [ngbTooltip]="tooltip | currency: '€ ': 'symbol' : '1.0-0'" class="text-sm float-left pt-1" *ngIf="compareMode && value != 0" [ngClass]="{'text-danger': value < 0, 'text-success': value > 0}">
        {{ value }}%
        <i class="fa text-sm" [ngClass]="{'fa-angle-double-up': value > 0, 'fa-angle-double-down': value < 0}"></i>
    </span>
</ng-template>