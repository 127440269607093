import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { BusinessPlanService } from '@services/business-plan.service';
import {
    BusinessPlan,
    BusinessPlanProvider,
    BusinessUnit,
    BusinessUnitRow,
    BusinessUnitRowType,
} from '@models/business-plan';
import { AlertService } from '@services/alert.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { VideoCategory } from '@models/video';

@Component({
    selector: 'app-business-unit-viewer',
    templateUrl: './business-unit-viewer.component.html',
    styleUrls: ['./business-unit-viewer.component.css'],
})
export class BusinessUnitViewerComponent implements OnInit {
    modes = {
        'forecast': 'Previsionale',
        'actual': 'Reale',
    }

    compareMode = false;

    selectedYear: number;
    selectedMode: string = 'actual';
    popupConfig: any;
    availableYears = [];

    loading = false;
    businessPlan: BusinessPlanProvider;

    yearPopupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };

    modePopupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _service: BusinessPlanService,
        private _alertService: AlertService,
    ) {
        this._activatedRoute.params.subscribe(params => {
            this._activatedRoute.queryParams.subscribe(params => {
                if(params.hasOwnProperty('year')) {
                    this.selectedYear = parseInt(params['year']);
                }
                else {
                    this.selectedYear = new Date().getFullYear();
                }
                if(params.hasOwnProperty('mode')) {
                    this.selectedMode = params['mode'];
                }
                else {
                    this.selectedMode = 'actual';
                }


                if(params.hasOwnProperty('compare')) {
                    this.compareMode = params['compare'] === 'true';
                }
                else {
                    this.compareMode = false;
                }



                for (let i = 2024; i <= new Date().getFullYear() + 5; i++) {
                    this.availableYears.push({
                        label: i,
                        key: i
                    });
                }

                this.loadBusinessPlan();
            });
        });
    }

    ngOnInit(): void {
    }

    openYearPopover(popOver: NgbPopover) {
        this.yearPopupConfig.inputValue = this.selectedYear;
        this.yearPopupConfig.id = this.selectedYear;
        this.yearPopupConfig.key = 'selectedYear';
        this.yearPopupConfig.popover = popOver;
        this.yearPopupConfig.isSelect = true;
        this.yearPopupConfig.items = this.availableYears;
        popOver.open();
    }

    openModePopover(popOver: NgbPopover) {
        this.modePopupConfig.inputValue = this.selectedMode;
        this.modePopupConfig.id = this.selectedMode;
        this.modePopupConfig.key = 'selectedMode';
        this.modePopupConfig.popover = popOver;
        this.modePopupConfig.isSelect = true;
        this.modePopupConfig.items = [
            { label: 'Previsionale', key: 'forecast' },
            { label: 'Reale', key: 'actual' }
        ];
        popOver.open();
    }

    changeYear(year: number) {
        this.selectedYear = year;
        this._router.navigate(['/business-plan/business-units/view'], { queryParams: { year: year, mode: this.selectedMode }});
    }

    changeMode(mode: any) {
        this.selectedMode = mode;
        this._router.navigate(['/business-plan/business-units/view'], { queryParams: { year: this.selectedYear, mode: mode }});
    }

    private loadBusinessPlan() {
        this.loading = true;
        this._service.getBusinessPlan(this.selectedYear, this.selectedMode, this.compareMode).subscribe((response: BusinessPlanProvider) => {
            this.businessPlan = response;
            this.loading = false;
        }, () => {
            this.loading = false;
            this._alertService.show('Errore' , 'Errore durante il caricamento del Business Plan', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    toggleCompare() {
        this._router.navigate(['/business-plan/business-units/view'], { queryParams: { year: this.selectedYear, mode: this.selectedMode, compare: !this.compareMode }});
    }
}
