<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadList()">
    <div class="form-row">
      <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="companyName">Company name</label>
          <app-retailer-typeahead #retailerTypeahead (onChange)="filter.id_dealer = $event"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="key_account">Key Account</label>
          <app-agent-select #agentSelect (onChange)="filter.id_ka = $event"></app-agent-select>
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="area_manager">Area Manager</label>
          <app-master-agent-select #masterAgentSelect (onChange)="filter.id_am = $event"></app-master-agent-select>
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label>Data di scadenza</label>
          <input
            id="documentDate"
            type="text"
            daterangepicker
            autocomplete="off"
            [options]="pickerOptions"
            [(ngModel)]="filter.date"
            name="date"
            class="form-control"
            (selected)="generateDateRangeString($event.start, $event.end)"
          />
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label for="paymentType">Pagamento</label>
          <ng-select
            [clearable]="true"
            [(ngModel)]="filter.payment_type"
            [items]="paymentTypesItems"
            bindLabel="label"
            bindValue="value"
            [searchable]="true"
            name="paymentType"
          ></ng-select>
        </div>
      </div>
      <div *ngIf="activeTab === 'expiration'"   class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label>Rinnovato</label>
          <ng-select [clearable]="true" [(ngModel)]="filter.rinnovato" [items]="renewalItems" bindLabel="label" bindValue="value" [searchable]="true" name="renewed"></ng-select>
        </div>
      </div>
      <div *ngIf="activeTab === 'expired'"  class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label>Rinnovato</label>
          <ng-select [clearable]="true" [(ngModel)]="filter.renewed" [items]="renewItems" bindLabel="label" bindValue="value" [searchable]="true" name="renewed"></ng-select>
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <button class="btn btn-info btn-block" translate type="submit">Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>

<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link text-uppercase" [class.active]="activeTab === 'expiration'" (click)="selectTab('expiration')" data-toggle="tab" href="#expiration"
      >Abbonamenti in scadenza</a
    >
  </li>
  <li class="nav-item">
    <a class="nav-link text-uppercase" [class.active]="activeTab === 'expired'" (click)="selectTab('expired')" data-toggle="tab" href="#expired">Abbonamenti scaduti</a>
  </li>
</ul>

<div class="tab-content mt-2">
  <!-- Tab Expiration -->
  <div id="expiration" *ngIf="activeTab == 'expiration'" class="tab-pane fade show active">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0" translate>Abbonamenti in scadenza</h4>
      </div>
      <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-orders" [fullScreen]="false">
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th translate>Company name</th>
              <th translate>Area Manager</th>
              <th translate>Key Account</th>
              <th translate>Servizio</th>
              <th translate>Scadenza</th>
              <th translate>Periodo</th>
              <th translate>Pagamento</th>
              <th translate>Rinnovato</th>
            </tr>
          </thead>
          <tbody class="bg-gradiend-white">
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders" [fullScreen]="false">
              <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngIf="expirationList.length == 0">
              <td translate colspan="9" class="text-center">List is empty</td>
            </tr>
            <tr *ngFor="let item of expirationList">
              <td>
                <a [routerLink]="['/dealer', item.id_dealer]" target="_blank">{{ item.company_name }}</a>
              </td>
              <td>{{ item.area_manager_ragsociale !== null ? item.area_manager_ragsociale : '-' }}</td>
              <td>{{ item.key_account_ragsociale != null ? item.key_account_ragsociale : '-' }}</td>
              <td>
                {{ item?.serviceTranslate }}
              </td>
              <td>
                {{ item?.to | amDateFormat : 'DD/MM/YYYY' }}
              </td>
              <td>{{ item?.from | amDateFormat : 'MM/YYYY' }} - {{ item?.to | amDateFormat : 'MM/YYYY' }}</td>
              <td>
                {{ item?.paymentTranslate }}
              </td>
              <td class="text-center">
                <i *ngIf="item.rinnovato == '2'" class="fa fa-exclamation-triangle mr-2 text-warning"></i>
                <i *ngIf="item.rinnovato == '1'" class="fa fa-check-circle mr-2 text-success"></i>
                <i *ngIf="item.rinnovato == '0'" class="fa fa-times-circle mr-2 text-danger"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer" *ngIf="expirationList?.length > 0">
        <span
          ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b> {{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }} .
          <a class="link" (click)="exportExpiration()">Esporta la lista completa</a>
        </span>
        <ngb-pagination
          *ngIf="ordersPagination.pageCount > 1"
          size="sm"
          [pageSize]="ordersPagination.perPage"
          [maxSize]="10"
          [(page)]="ordersPagination.currentPage"
          [collectionSize]="ordersPagination.totalCount"
          (pageChange)="loadList()"
          [boundaryLinks]="true"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
  <!-- Tab Expired -->
  <div id="expired" *ngIf="activeTab == 'expired'" class="tab-pane fade show active">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0" translate>Abbonamenti Scaduti</h4>
      </div>
      <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-orders" [fullScreen]="false">
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th translate>Company name</th>
              <th translate>Area Manager</th>
              <th translate>Key Account</th>
              <th translate>Servizio</th>
              <th translate>Scadenza</th>
              <th translate>Periodo</th>
              <th translate>Pagamento</th>
              <th>Rinnovato</th>
            </tr>
          </thead>
          <tbody class="bg-gradiend-white">
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders" [fullScreen]="false">
              <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngIf="expirationList.length == 0">
              <td translate colspan="9" class="text-center">List is empty</td>
            </tr>
            <tr *ngFor="let item of expirationList">
              <td>
                <a [routerLink]="['/dealer', item.id_dealer]" target="_blank">{{ item.company_name }}</a>
              </td>
              <td>{{ item.area_manager_ragsociale !== null ? item.area_manager_ragsociale : '-' }}</td>
              <td>{{ item.key_account_ragsociale != null ? item.key_account_ragsociale : '-' }}</td>
              <td>
                {{ item?.serviceTranslate }}
              </td>
              <td>
                {{ item?.to | amDateFormat : 'DD/MM/YYYY' }}
              </td>
              <td>{{ item?.from | amDateFormat : 'MM/YYYY' }} - {{ item?.to | amDateFormat : 'MM/YYYY' }}</td>
              <td>
                {{ item?.paymentTranslate }}
              </td>
              <td class="text-center">
                <i *ngIf="item.renewed == '1'" class="fa fa-check-circle mr-2 text-success"></i>
                <i *ngIf="item.renewed == '0'" class="fa fa-times-circle mr-2 text-danger"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer" *ngIf="expirationList?.length > 0">
        <span
          ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b> {{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }} .
          <a class="link" (click)="exportExpired()">Esporta la lista completa</a>
        </span>
        <ngb-pagination
          *ngIf="ordersPagination.pageCount > 1"
          size="sm"
          [pageSize]="ordersPagination.perPage"
          [maxSize]="10"
          [(page)]="ordersPagination.currentPage"
          [collectionSize]="ordersPagination.totalCount"
          (pageChange)="loadList()"
          [boundaryLinks]="true"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
