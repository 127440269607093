<div class="modal-header">
  <h4 class="modal-title clamp-title">{{ selectedCategory ? 'Modifica Categoria' : 'Aggiungi Categoria' }}</h4>
  <button (click)="dismissModal()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [ngBusy]="busy">
  <form [formGroup]="categoryForm" (ngSubmit)="saveCategory()">
    <div class="form-row">
      <div class="col-6 form-group">
        <label for="name" translate>Name</label>
        <input type="text" class="form-control" id="name" formControlName="name" />
        <val-errors controlName="name">
          <ng-template valError="required">
            <span translate>Name is required</span>
          </ng-template>
        </val-errors>
      </div>
      <div class="col-6 form-group">
        <label for="featured" translate>Consigliata</label>
        <ng-select id="featured" formControlName="featured" [items]="featuredItems" bindValue="value" bindLabel="key"></ng-select>
        <val-errors controlName="featured">
          <ng-template valError="required">
            <span translate>Featured is required</span>
          </ng-template>
        </val-errors>
      </div>
      <div class="col-12 col-lg-6 form-group">
        <label for="productImage" translate>Image</label>
        <div class="custom-file" *ngIf="!imageName">
          <input id="productImage" accept="image/*" type="file" class="custom-file-input" (change)="handleFileInput($event?.target?.files[0])" />
          <label class="custom-file-label" for="productImage" translate> Upload image... </label>
        </div>
        <div *ngIf="imageName">
          <span class="text-break">{{ imageName }}</span>
          <button class="btn btn-xs btn-warning float-right" type="button" (click)="handleFileRemoveModal()">
            <i class="fa fa-times m-0 px-1"></i>
          </button>
        </div>
        <val-errors controlName="image_name">
          <ng-template valError="required">
            <span translate>Image is required</span>
          </ng-template>
        </val-errors>
      </div>
      <div class="col-12 col-lg-6 form-group">
        <label for="categoryId" translate>Parent Category</label>
        <ng-select
          id="categoryId"
          formControlName="category_id"
          [items]="parentCategories"
          bindValue="id"
          bindLabel="nome"
          (search)="loadParentCategories($event?.term)"
          [loading]="categoriesLoading"
          >>
        </ng-select>
      </div>
      <div class="col-12 col-lg-6 form-group">
        <label for="brandId" translate>Brand</label>
        <ng-select id="brandId" formControlName="brand_id" [items]="brands" bindValue="id" bindLabel="nome" (search)="loadBrands($event?.term)" [loading]="brandsLoading"
          >>
        </ng-select>
      </div>
      <div class="col-12 col-lg-6 form-group">
        <label for="operatorId" translate>Operator</label>
        <ng-select
          id="operatorId"
          formControlName="operator_id"
          [items]="operators"
          bindValue="id"
          bindLabel="name"
          (search)="loadOperators($event?.term)"
          [loading]="operatorsLoading"
        >
        </ng-select>
      </div>
      <div class="col-12 form-group">
        <label for="productDescription" translate>Description</label>
        <editor [init]="{ base_url: '/tinymce', suffix: '.min' }" formControlName="description"></editor>
      </div>
      <div class="col-12 mt-2">
        <button type="submit" class="btn btn-success btn-block" [disabled]="categoryForm.invalid"><i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
      </div>
    </div>
  </form>
</div>
