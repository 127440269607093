import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

const v3Version = 'v3';

@Injectable({
  providedIn: 'root'
})
export class OrdersControlService {

  constructor(private request: RequestService, public alertService: AlertService) { }

  public getReportOrdersControl(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list', params );
  }

  // ----> chiamate per Bonifico

  public getReportOrdersControlBonifico(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list-bonifico', params );
  }

  public getReportOrdersSuggestedBonifico(orderId?, transactionId?): Observable<any> {
    return this.request.get('v3/orders-control/suggested-bonifico', { order_id: orderId });
  }

  // ----> end

  // ----> chiamate per contrassegno

  public getReportOrdersControlContrassegno(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list-contrassegno', params );
  }

  public getReportOrdersSuggestedContrassegno(orderId?, transactionId?): Observable<any> {
    return this.request.get('v3/orders-control/suggested-contrassegno', { order_id: orderId });
  }

  // ----> end

  // ----> chiamate per carta di credito

  public getReportOrdersControlCarta(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list-carta',params );
  }
  
  public getReportOrdersSuggestedCdc(orderId?, transactionId?): Observable<any> {
    return this.request.get('v3/orders-control/suggested-cdc', { order_id: orderId });
  }

  public postCdcMatch(data: any): Observable<any> {
    return this.request.post('v3/orders-control/save-cdc-transaction', data);
  }

  public cdcUploadFile(formData) {
    return this.request.post('v3/orders-control/file-cdc', formData);
  }

  // ----> end

    // public getReportOrdersSuggestedCdc(): Observable<any> {
    //  return this.request.get('v3/orders-control/test');
    // }

  public getReportOrdersControlCompensazione(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list-compensazione',params );
  }

  public getReportOrdersControlRimessaAgente(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list-rimessa-agente', params );
  }

  public getReportOrdersControlAssegnoCorriere(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list-assegno-vista-corriere', params );
  }

  public getReportOrdersControlOmaggio(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list-omaggio', params );
  }

  public getReportOrdersControlCompensazioneAnticipata(params?): Observable<any> {
    return this.request.get('v3/orders-control/report-list-compensazione-anticipata', params );
  }

  public putReportStatus(id, body: {}) {
    return this.request.put('v3/orders-control/update/' + id, body);
  }


  public successAlert(text) {
    this.alertService.show('', 'Successfully ' + text, {
      classname: 'bg-success text-light',
      delay: 4000,
    });
  }

  public errorAlert(message?) {
    this.alertService.show('An error occurred!', message ? message : 'Try again!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }

}
