import {Component, Input, OnInit} from '@angular/core';
import {RequestService} from '@services/request.service';

@Component({
    selector: 'app-autoload-target',
    templateUrl: './autoload-target.component.html',
    styleUrls: ['./autoload-target.component.css']
})
export class AutoloadTargetComponent implements OnInit {
    data: any;
    status = 'hidden';
    @Input() brand: string;

    constructor(
        private requestService: RequestService,
    ) {
    }

    _urlTarget: string;

    @Input() set urlTarget(value: string) {
        this._urlTarget = value;

        if (this._urlTarget !== undefined) {
            this.loadTarget();
        }
    }

    ngOnInit() {

    }

    private loadTarget() {
        this.status = 'loading';
        this.requestService.get(this.brand + this._urlTarget)
            .subscribe((data) => {
                this.data = data.result;
                this.status = 'hidden';
            }, (error) => {
                this.status = 'error';
            });
    }

}
