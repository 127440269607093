import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { Mail, MailSubscription } from '@models/mail-generator';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '@services/request.service';
import { AuthenticationService } from '@services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MailGeneratorService } from '@services/mail-generator.service';
import { User } from '@models/user';
import { ConfirmOptionsService } from '@services/confirm-options.service';

@Component({
    selector: 'app-mail-detail',
    templateUrl: './mail-detail.component.html',
    styleUrls: ['./mail-detail.component.css'],
})
export class MailDetailComponent implements OnInit {
    @ViewChild('assegnaMailTemplate') private assegnaMailTemplate;
    deleteSubscriptionOptions: ConfirmOptionsService;
    ordersPagination: MetaPagination;
    mailListDetail: any;
    isDealer = false;
    isEdit = true;
    mailId: number;
    assegnaMailForm: FormGroup;
    subMasterGroup: any;
    masterAgentGroup: any;
    agentGroup: any;
    filter = {
        agent_id: '',
        master_agent_id: '',
    };

    selectAllSubMaster: boolean = false;
    selectAllMasterAgent: boolean = false;
    selectAllAgent: boolean = false;
    selected: false;
    selectedItems: { id: number; email: string }[] = [];
    enableSaveButton: boolean = false;

    popupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };

    role: { am: boolean; ka: boolean; dc: boolean };

    selectedOperator: number;
    operator = [
        { id: 16, name: 'A2A' },
        { id: 6, name: 'KENA' },
        { id: 11, name: 'TIM' },
    ];

    constructor(
        private route: ActivatedRoute,
        public request: RequestService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        public formBuilder: FormBuilder,
        public mailService: MailGeneratorService,
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.isDealer = user && user.profile.role === 'dealer';
        });
        this.deleteSubscriptionOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla',
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.mailId = params['id'];
            this.role = {
                am: params['am'] == 'true' ? true : false,
                ka: params['ka'] == 'true' ? true : false,
                dc: params['dc'] == 'true' ? true : false,
            };
            this.loadMailListDetail(this.mailId);
        });

        this.assegnaMailForm = this.formBuilder.group({});
    }

    loadMailListDetail(mailId: any) {
        this.spinner.show('table-orders');

        const params = {
            mailId: mailId,
        };

        if (this.filter.agent_id) {
            params['agentId'] = this.filter.agent_id;
        }

        if (this.filter.master_agent_id) {
            params['masterAgentId'] = this.filter.master_agent_id;
        }

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        this.mailService.getMailSubscription(params).subscribe(
            (response: any) => {
                this.mailListDetail = response;
                this.mailListDetail = new MailSubscription().fromArray(response.data);
                this.ordersPagination = response._meta;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    showModal() {
        this.modalService.open(this.assegnaMailTemplate, { size: 'xl', scrollable: true });
    }

    dismissModal() {
        this.modalService.dismissAll();
        this.loadMailListDetail(this.mailId);
        this.selectedItems = [];
    }

    onAgentChange(agentId: any): void {
        this.filter.agent_id = agentId;
        this.loadMailListDetail(this.mailId);
        this.loadKaSelect();
    }

    onMasterChange(masterAgentId: any): void {
        this.filter.master_agent_id = masterAgentId;
        this.loadMailListDetail(this.mailId);
        this.loadAmSelect();
    }

    onOperatorChange(event: any): void {
        if (event) {
            this.selectedOperator = event.id;
        }
        this.loadAmSelect();
        this.loadKaSelect();
        this.loadDcSelect();
    }

    loadAmSelect() {
        this.spinner.show('table-orders');

        const params = {
            mailId: this.mailId,
        };

        if (this.filter.master_agent_id) {
            params['masterAgentId'] = this.filter.master_agent_id;
        }

        if (this.selectedOperator) {
            params['operatorId'] = this.selectedOperator;
        }

        this.mailService.getAmSelect(params).subscribe(
            (response: any) => {
                this.masterAgentGroup = response;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    loadKaSelect() {
        this.spinner.show('table-orders');

        const params = {
            mailId: this.mailId,
        };

        if (this.filter.agent_id) {
            params['agentId'] = this.filter.agent_id;
        }

        if (this.selectedOperator) {
            params['operatorId'] = this.selectedOperator;
        }

        this.mailService.getkaSelect(params).subscribe(
            (response: any) => {
                this.agentGroup = response;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    loadDcSelect() {
        this.spinner.show('table-orders');


        this.mailService.getDcSelect().subscribe(
            (response: any) => {
                this.subMasterGroup = response;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    selectAllMasterAgents(event: any): void {
        const isChecked = event.target.checked;
        this.selectAllMasterAgent = isChecked;

        this.masterAgentGroup.forEach((agent) => {
            agent.am_checked = isChecked;
            this.updateSelectedItems(agent.id, agent.email, isChecked, 'master');
        });

        this.enableSaveButton = this.selectedItems.length > 0;
    }

    selectAllAgents(event: any): void {
        const isChecked = event.target.checked;
        this.selectAllAgent = isChecked;

        this.agentGroup.forEach((agent) => {
            agent.ka_checked = isChecked;
            this.updateSelectedItems(agent.id, agent.email, isChecked, 'agent');
        });

        this.enableSaveButton = this.selectedItems.length > 0;
    }

    updateSelectedItems(id: number, email: string, isSelected: boolean, type: 'master' | 'agent' | 'sub'): void {
        const index = this.selectedItems.findIndex((item) => item.id === id);

        if (isSelected) {
            if (index === -1) {
                this.selectedItems.push({ id: id, email: email });
            }
        } else {
            if (index !== -1) {
                this.selectedItems.splice(index, 1);
            }
        }
        this.enableSaveButton = this.selectedItems.length > 0;
    }

    toggleSelection(target: any, id: number, email: string, type: 'master' | 'agent' | 'sub'): void {
        const isChecked = target.checked;
        this.updateSelectedItems(id, email, isChecked, type);

        if (type === 'master') {
            const agent = this.masterAgentGroup.find((agent) => agent.id === id);
            if (agent) {
                agent.am_checked = isChecked;
            }
        } else if (type === 'agent') {
            const agent = this.agentGroup.find((agent) => agent.id === id);
            if (agent) {
                agent.ka_checked = isChecked;
            }
        } else if (type === 'sub') {
            const sub = this.subMasterGroup.find((sub) => sub.id === id);
            if (sub) {
                sub.dc_checked = isChecked;
            }
        }

        this.enableSaveButton = this.selectedItems.length > 0;
    }

    save() {
        const payload = {
            mailId: this.mailId,
            dealers: this.selectedItems.map((item) => ({ id: item.id, email: item.email })),
        };

        this.mailService.postMailAssigned(payload).subscribe(
            (response) => {
                this.mailService.successAlert('Assegnato correttamente');
                this.dismissModal();
            },
            (err) => {
                this.mailService.errorAlert(err.message);
                this.dismissModal();
            },
        );
    }

    openPopover(popOver: any, itemId, item, key, selectItems = []) {
        this.popupConfig.inputValue = item;
        this.popupConfig.id = itemId;
        this.popupConfig.key = key;
        this.popupConfig.popover = popOver;
        this.popupConfig.isSelect = selectItems?.length > 0;
        this.popupConfig.items = selectItems;
        popOver.open();
    }

    updateEmail(value) {
        const body = {};
        body[this.popupConfig['key']] = value;
        this.spinner.show('popupSpinner');
        return this.changeEmail(this.popupConfig.id, body);
    }

    changeEmail(itemId, body) {
        this.mailService.putEmail(itemId, body).subscribe(
            (response) => {
                this.loadMailListDetail(this.mailId);
                this.spinner.hide('popupSpinner');
            },
            (err) => {
                this.loadMailListDetail(this.mailId);
                this.modalService.dismissAll();
                this.spinner.hide('popupSpinner');
            },
        );
    }

    deleteMailAssigned(id: number) {
        this.mailService.deleteMailAssigned(id).subscribe(
            () => {
                const index = this.mailListDetail.findIndex((dealer) => dealer.id === id);
                if (index > -1) {
                    this.mailService.successAlert('Deleted Service!');
                    this.loadMailListDetail(this.mailId);
                }
            },
            (err) => {
                this.mailService.errorAlert(err.message);
            },
        );
    }
}
