import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';
import {DealerView} from '@models/dealer-view';


export class Sdd extends BaseModel {
    id: number;
    company_name: string;
    key_account: string;
    dealer_id: number;
    created_by: string;
    update_by: string;
    iban: string;
    iban_type: string;
    payments_type: string;
    type: string;
    signature_date: string;
    created_at: string;
    updated_at: string;
    from: string;
    to: string;
    file_path: string;
    status: string;

    get typeLabel() {
        return Sdd.sddTypesPair[this.type];
    }

    get typePaymentsLabel(){
        return Sdd.sddPaymentsTypesPair[this.payments_type];
    }

    
    get typeIbanLabel(){
        return Sdd.sddInbanPair[this.iban_type];
    }
    
    get sddStatusLabel(){
        return Sdd.sddStausPair[this.status];
    }
    
    public static sddStatus = [
        {
            label: 'Non agganciato',
            key: '0'
        },
        {
            label: 'Agganciato',
            key: '1'
        }
    ]

    public static sddStausPair = {
        '0': 'Non agganciato',
        '1': 'Agganciato ',
    };

    public static sddTypes = [
        {
            label: 'Tim',
            key: 'tim'
        },
        {
            label: 'Kdcube',
            key: 'kdcube'
        },
        {
            label: 'Shop',
            key: 'shop'
        }
    ];
    public static sddTypesPair = {
        'tim': 'Modulo TIM',
        'kdcube': 'Modulo KDCube',
        'shop': 'Modulo KDShop',
    };

    public static ibanTypes = [
        {
            label: 'B2B',
            key: 'btob'
        },
        {
            label: 'B2C',
            key: 'btoc'
        }
    ];

    public static sddInbanPair = {
        'btob': 'B2B',
        'btoc': 'B2C',
    };

    public static paymentsTypes = [
        {
            label: 'First',
            key: 'first'
        },
        {
            label: 'Recurrent',
            key: 'recurrent'
        }
    ];
    
    public static sddPaymentsTypesPair = {
        'first': 'First',
        'recurrent': 'Recurrent'
    };


    fromArray(result: []): Sdd[] {
        const array: Sdd[] = [];
        for (const item of result) {
            array.push(new Sdd(item));
        }
        return array;
    }
}

export class SddProvider extends BaseModel {
    data: Sdd[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class DocumentSdd extends BaseModel {
    id: number;
    // nome: string;
    // descrizione: string;
    file_path: string;
    created: string;
    file: File;

    fromArray(result: []) {
        const array: DocumentSdd[] = [];
        for (const item of result) {
            array.push(new DocumentSdd(item));
        }
        return array;
    }
}