import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '@environments/environment';

@Pipe({
  name: 'assets'
})
export class AssetsPipe implements PipeTransform {


  transform(value: string, args: any[] = []): string {
    if (!value) { return null; }
    return environment.assetsUrl + value;
  }

}
