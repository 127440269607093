<div class="card bg-gradient-light">
    <div class="card-header">
        <h1 class="card-title">Filter</h1>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Ragione Sociale</label>
                <input
                    type="text"
                    [(ngModel)]="filter.name"
                    (keyup.enter)="refreshList()"
                    class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Nome Rappresentante</label>
                <input
                    type="text"
                    [(ngModel)]="filter.owner_name"
                    (keyup.enter)="refreshList()"
                    class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Cognome Rappresentante</label>
                <input
                    type="text"
                    [(ngModel)]="filter.owner_surname"
                    (keyup.enter)="refreshList()"
                    class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Partita Iva</label>
                <input
                    type="text"
                    [(ngModel)]="filter.vat"
                    (keyup.enter)="refreshList()"
                    class="form-control form-control-sm"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Regione</label>
                <app-state-select (onChange)="this.filter.state = $event" [small]="true"></app-state-select>
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Provincia</label>
                <app-province-select
                    [state]="this.filter.state"
                    [onDemand]="true"
                    [small]="true"
                    (onChange)="this.filter.province = $event"
                ></app-province-select>
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Comune</label>
                <app-city-select
                    [province]="this.filter.province"
                    [onDemand]="true"
                    [small]="true"
                    (onChange)="this.filter.city = $event"
                ></app-city-select>
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Data Creazione</label>
                <input
                    class="form-control form-control-sm"
                    #availabilityRangePicker
                    type="text"
                    [(ngModel)]="filter.created_range"
                    daterangepicker
                    [options]="{}"
                    (selected)="setRangeCreated($event.start, $event.end)"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Telefono</label>
                <input
                    type="text"
                    [(ngModel)]="filter.phone"
                    (keyup.enter)="refreshList()"
                    class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Cellulare</label>
                <input
                    type="text"
                    [(ngModel)]="filter.mobile"
                    (keyup.enter)="refreshList()"
                    class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Codice</label>
                <input
                    type="text"
                    [(ngModel)]="filter.code"
                    (keyup.enter)="refreshList()"
                    class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Operatori</label>
                <app-vendor-select
                    [small]="true"
                    [multiple]="true"
                    (onChange)="this.filter.vendors = $event"
                ></app-vendor-select>
            </div>

            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Stato</label>
                <select class="form-control form-control-sm" [(ngModel)]="filter.deleted">
                    <option [value]="0">Attivi</option>
                    <option [value]="1">Disattivi</option>
                </select>
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label translate>Roles</label>
                <ng-select class="form-control-sm" [(ngModel)]="filter.roles" [clearable]="false">
                    <ng-option [value]="dealerRole" *show="['master', 'master-agent', 'agent', 'operator']" translate>
                        Retailer
                    </ng-option>
                    <ng-option [value]="agentRole" *show="['master', 'operator']">Key Account</ng-option>
                    <ng-option [value]="masterAgentRole" *show="['master', 'operator']">Area Manager</ng-option>
                    <ng-option [value]="operatorRole" *show="['master', 'operator']">Staff</ng-option>
                    <ng-option [value]="submasterRole" *show="['master', 'operator']">Sub Master</ng-option>
                    <ng-option [value]="retailerRole" *show="['master', 'operator']">Retailer</ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <button class="btn btn-dark btn-sm mr-2" (click)="resetFilters()">Reset</button>
        <button class="btn btn-primary btn-sm" type="submit" (click)="refreshList(true)">Filtra</button>
    </div>
</div>
<div class="card bg-gradient-light">
    <div class="card-header">
        <div class="d-inline-block">
            <div ngbDropdown [autoClose]="false">
                <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Mostra più dati</button>
                <div ngbDropdownMenu>
                    <ul style="max-height: 300px; overflow-y: scroll; padding: 0">
                        <li ngbDropdownItem *ngFor="let item of this.fields">
                            <label style="min-width: 100%" class="mb-0">
                                <input type="checkbox" [(ngModel)]="item.checked" />
                                {{ item.label }}
                            </label>
                        </li>
                        <li ngbDropdownItem *ngFor="let item of this.expand">
                            <label style="min-width: 100%" class="mb-0">
                                <input type="checkbox" [(ngModel)]="item.checked" />
                                {{ item.label }}
                            </label>
                        </li>
                    </ul>
                    <div class="px-4 mt-2">
                        <button class="btn btn-primary btn-sm btn-block" (click)="refreshList()">Aggiorna</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-tools">
            <span *ngIf="isDealersList">
                <button
                    *show="['master', 'operator']"
                    class="btn btn-secondary btn-sm mr-2"
                    translate
                    (click)="showCreateCampaignModal()"
                    [disabled]="selectedDealersForCampaign?.length <= 0"
                >
                    Create Campaign
                </button>
            </span>
            <ng-container *show="['master', 'operator', 'agent']">
                <button class="btn btn-success btn-sm" *isVendorDisabled="'Eolo'" (click)="showCreateDealerModal()">
                    Nuovo Negozio
                </button>
            </ng-container>
        </div>
    </div>

    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th *ngIf="isDealersList">
                        <div class="custom-control custom-checkbox">
                            <input
                                class="custom-control-input"
                                type="checkbox"
                                id="select-all"
                                [(ngModel)]="selectAll"
                                (change)="selectDealer($event)"
                            />
                            <label for="select-all" class="custom-control-label" translate></label>
                        </div>
                    </th>
                    <th *show="['operator', 'master']"></th>
                    <th *show="['agent']"></th>
                    <ng-container *isVendorDisabled="'Eolo'"><th *ngIf="this.activeFields['id']">ID</th></ng-container>
                    <th *ngIf="this.activeFields['username']" translate>Username</th>
                    <th *ngIf="this.activeFields['company_name']">Rag. Sociale</th>
                    <th *ngIf="this.activeFields['piva']">P. IVA</th>
                    <th *ngIf="this.activeFields['codfiscale']">Cod. Fiscale</th>
                    <th *ngIf="this.activeFields['company_owner']">Responsabile</th>
                    <th *ngIf="this.activeFields['cellulare']">Cellulare</th>
                    <th *ngIf="this.activeFields['telefono']">Telefono</th>
                    <th *ngIf="this.activeFields['email']">E-Mail</th>
                    <th *ngIf="this.activeFields['pec']">PEC</th>
                    <th *ngIf="this.activeFields['indirizzo']">Indirizzo</th>
                    <th *ngIf="this.activeFields['civico']">Civico</th>
                    <th *ngIf="this.activeFields['lat']">Latitudine</th>
                    <th *ngIf="this.activeFields['lon']">Longitudine</th>
                    <th *ngIf="this.activeFields['cap']">CAP</th>
                    <th *ngIf="this.activeExpand['city']">Comune</th>
                    <th *ngIf="this.activeExpand['province']">Provincia</th>
                    <th *ngIf="this.activeExpand['state']">Regione</th>
                    <th *ngIf="this.activeExpand['dealer_type']">Tipologia Dealer</th>
                    <th *ngIf="this.activeExpand['spid_ir_enabled']">Spid Ir Abilitato</th>
                    <th *ngIf="this.activeExpand['spid_codice_sede']">Spid Codice Sede</th>
                    <th *ngIf="this.activeExpand['spid_user']">Spid User</th>
                    <th *ngIf="this.activeExpand['spid_progressivo']">Spid Progressivo</th>
                    <th *ngIf="this.activeExpand['visibile']">Visibile</th>
                    <th *ngIf="this.activeExpand['inpost']">InPost</th>
                    <th *ngIf="this.activeExpand['enabledVendors']" class="text-center">Operatori</th>
                    <ng-container *ngIf="this.activeExpand['vendors']">
                        <th *ngFor="let vendor of enabledVendors">Codice {{ vendor.name }}</th>
                    </ng-container>
                    <ng-container *ngIf="this.activeExpand['agents']">
                        <th *ngFor="let vendor of enabledVendors">Key Account {{ vendor.name }}</th>
                    </ng-container>
                    <th *ngIf="this.activeFields['iban']">IBAN</th>
                    <th *ngIf="this.activeFields['created']">Inserito il</th>
                </tr>
            </thead>
            <tbody class="bg-gradient-white">
                <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-dealers"
                    [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <tr *ngFor="let dealer of dealers; index as i">
                    <td *ngIf="isDealersList">
                        <div class="custom-control custom-checkbox">
                            <input
                                class="custom-control-input"
                                type="checkbox"
                                [id]="i"
                                [(ngModel)]="dealer.isSelected"
                                (change)="selectDealer($event, dealer)"
                            />
                            <label [for]="i" class="custom-control-label"></label>
                        </div>
                    </td>
                    <td *show="['agent']">
                        <i
                            class="fab fa-facebook link text-danger mr-2"
                            style="cursor: pointer"
                            (click)="showFacebookModal(dealer)"
                            *ngIf="dealer.facebook_page === null"
                        ></i>
                        <i
                            class="fab fa-facebook text-secondary mr-2"
                            style="cursor: pointer"
                            *ngIf="dealer.facebook_page !== null && dealer.kdsocial === 0"
                        ></i>
                        <i
                            class="fab fa-facebook text-primary mr-2"
                            *ngIf="dealer.facebook_page !== null && dealer.kdsocial === 1"
                        ></i>

                        <i
                            class="fas fa-info-circle link text-danger mr-2"
                            style="cursor: pointer"
                            (click)="showDealerTypeModal(dealer)"
                            *ngIf="dealer.dealer_type === null"
                        ></i>
                        <i
                            class="fas fa-info-circle text-primary mr-2"
                            style="cursor: pointer"
                            *ngIf="dealer.dealer_type !== null"
                            (click)="showDealerTypeModal(dealer)"
                        ></i>
                    </td>

                    <td *show="['operator', 'master']">
                        <i class="fas fa-pencil-alt link text-primary mr-2" (click)="showUpdateDealerModal(dealer)"></i>
                        <i
                            class="fas fa-times-circle link text-danger"
                            [swal]="dealerConfirmDeleteOptions"
                            (confirm)="deleteDealer(dealer)"
                            *ngIf="dealer.deleted === 0"
                        ></i>
                        <i
                            class="fas fa-check-circle link text-success"
                            [swal]="dealerConfirmRestoreOptions"
                            (confirm)="restoreDealer(dealer)"
                            *ngIf="dealer.deleted === 1"
                        ></i>
                        <i
                            placement="top"
                            ngbTooltip="Reset password link"
                            class="fas fa-link link text-secondary mr-2 ml-2"
                            (click)="showResetPasswordModal(dealer.id)"
                        ></i>
                        <i
                            *ngIf="isDealersList"
                            placement="top"
                            ngbTooltip="Reset administrative password"
                            class="fas fa-lock link text-secondary mr-2 ml-1"
                            [swal]="showResetAdminPasswordModal(dealer.id)"
                            (confirm)="confirmAdminResetPassword(dealer.id)"
                        ></i>
                    </td>
                    <ng-container *isVendorDisabled="'Eolo'"
                        ><td *ngIf="this.activeFields['id']">
                            {{ dealer.id }}
                        </td></ng-container
                    >
                    <td *ngIf="this.activeFields['username']">
                        {{ dealer.username }}
                    </td>
                    <td *ngIf="this.activeFields['company_name']">
                        <a [routerLink]="['/dealer', dealer.id]" target="_blank">{{ dealer.company_name }}</a>
                    </td>
                    <td *ngIf="this.activeFields['piva']">{{ dealer.piva }}</td>
                    <td *ngIf="this.activeFields['codfiscale']">
                        {{ dealer.codfiscale }}
                    </td>
                    <td *ngIf="this.activeFields['company_owner']">
                        {{ dealer.company_owner }}
                    </td>
                    <td *ngIf="this.activeFields['cellulare']">
                        {{ dealer.cellulare }}
                    </td>
                    <td *ngIf="this.activeFields['telefono']">
                        {{ dealer.telefono }}
                    </td>
                    <td *ngIf="this.activeFields['email']">
                        {{ dealer.email }}
                    </td>
                    <td *ngIf="this.activeFields['pec']">{{ dealer.pec }}</td>
                    <td *ngIf="this.activeFields['indirizzo']">
                        {{ dealer.indirizzo }}
                    </td>
                    <td *ngIf="this.activeFields['civico']">
                        {{ dealer.civico }}
                    </td>
                    <td *ngIf="this.activeFields['cap']">{{ dealer.cap }}</td>
                    <td *ngIf="this.activeFields['lat']">{{ dealer.lat }}</td>
                    <td *ngIf="this.activeFields['lon']">{{ dealer.lon }}</td>
                    <td *ngIf="this.activeExpand['city']">{{ dealer.city }}</td>
                    <td *ngIf="this.activeExpand['province']">
                        {{ dealer.province }}
                    </td>
                    <td *ngIf="this.activeExpand['state']">
                        {{ dealer.state }}
                    </td>
                    <td *ngIf="this.activeExpand['dealer_type']">
                        {{ dealer.dealer_type }}
                    </td>
                    <td *ngIf="this.activeExpand['spid_ir_enabled']">
                        {{ dealer.spid_ir_enabled ? 'Si' : 'No' }}
                    </td>
                    <td *ngIf="this.activeExpand['spid_codice_sede']">
                        {{ dealer.spid_codice_sede }}
                    </td>
                    <td *ngIf="this.activeExpand['spid_user']">
                        {{ dealer.spid_user }}
                    </td>
                    <td *ngIf="this.activeExpand['spid_progressivo']">
                        {{ dealer.spid_progressivo }}
                    </td>

                    <td *ngIf="this.activeExpand['visibile']">
                        {{ dealer.visibile == 0 ? 'No' : 'Si ' }}
                    </td>
                    <td *ngIf="this.activeExpand['inpost']">
                        {{ dealer.inpost }}
                    </td>

                    <td *ngIf="this.activeExpand['enabledVendors']" class="text-center">
                        <img
                            *ngFor="let vendor of dealer.enabledVendors"
                            [src]="vendor.smallImage"
                            class="mr-1"
                            height="18px"
                        />
                    </td>

                    <ng-container *ngIf="this.activeExpand['vendors']">
                        <td *ngFor="let vendor of enabledVendors">
                            {{ getCodeFromRetailer(dealer, vendor) }}
                        </td>
                    </ng-container>
                    <ng-container *ngIf="this.activeExpand['agents']">
                        <td *ngFor="let vendor of enabledVendors">
                            {{ getAgentFromRetailer(dealer, vendor) }}
                        </td>
                    </ng-container>
                    <td *ngIf="this.activeFields['iban']">{{ dealer.iban }}</td>
                    <td *ngIf="this.activeFields['created']">
                        {{ dealer.created | amDateFormat : 'DD/MM/YYYY' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
        <span
            >Ci sono <b>{{ this.pagination.totalCount }}</b> dealers.
            <a class="link" (click)="exportDealers()">Esporta la lista completa</a></span
        >
        <ngb-pagination
            size="sm"
            [pageSize]="pagination.pageSize"
            [maxSize]="10"
            [(page)]="pagination.page"
            [collectionSize]="pagination.totalCount"
            (pageChange)="refreshList()"
            [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #dealerFormTemplate let-modal>
    <div class="modal-header" [ngBusy]="busy">
        <h4 class="modal-title clamp-title">
            {{ dealerModalTitle }}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="dealerForm">
            <div class="row">
                <div class="col-12 col-lg-4" *ngIf="selectedDealer.isNew">
                    <div class="form-group">
                        <label>Username</label>
                        <input
                            [(ngModel)]="selectedDealer.username"
                            formControlName="username"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="username">
                            <ng-template valError="required"> L'username è obbligatorio </ng-template>
                            <ng-template valError="invalidAsync"> L'username è già esistente </ng-template>
                            <ng-template valError="pattern">
                                <span translate>Whitespace not allowed</span>
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!selectedDealer.isNew">
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right">
                        <a href="javascript:void(0)" class="text-small" (click)="showAllDealerModalCollapse()"
                            >mostra tutto</a
                        >
                        /
                        <a href="javascript:void(0)" class="text-small" (click)="hideAllDealerModalCollapse()"
                            >nascondi tutto</a
                        >
                    </div>
                </div>
            </ng-container>
            <a href="javascript:void(0)" class="display-6" (click)="collapse.azienda = !collapse.azienda">
                Dati Azienda
                <i class="fas fa-exclamation-circle text-danger" *ngIf="this.dealerFormSectionErrors.azienda"></i>
                <i
                    class="fas float-right link-black pt-2"
                    [ngClass]="{
                        'fa-angle-down': collapse.azienda,
                        'fa-angle-up': !collapse.azienda
                    }"
                ></i>
            </a>
            <hr />
            <div class="row" [ngbCollapse]="collapse.azienda">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Ragione Sociale</label>
                        <input
                            [(ngModel)]="selectedDealer.ragsociale"
                            formControlName="ragsociale"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="ragsociale">
                            <ng-template valError="required"> La ragione sociale è obbligatoria </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Partita IVA</label>
                        <input
                            [(ngModel)]="selectedDealer.piva"
                            formControlName="piva"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="piva">
                            <ng-template valError="required"> La partita iva è obbligatoria </ng-template>
                            <ng-template valError="invalidAsync">
                                La partita iva è già presente. Chiedere al backoffice di riabilitare il negozio.
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="!selectedDealer.isNew">
                    <div class="form-group">
                        <label translate>Role</label>
                        <ng-select
                            class="form-control-sm"
                            [(ngModel)]="selectedDealer.role"
                            [clearable]="false"
                            formControlName="role"
                        >
                            <ng-option
                                [value]="dealerRole"
                                *show="['master', 'master-agent', 'agent', 'operator']"
                                translate
                                >Retailer
                            </ng-option>
                            <ng-option [value]="agentRole" *show="['master', 'operator']">Key Account</ng-option>
                            <ng-option [value]="masterAgentRole" *show="['master', 'operator']">Area Manager</ng-option>
                            <ng-option [value]="operatorRole" *show="['master', 'operator']">Staff</ng-option>
                            <ng-option [value]="masterRole" *show="['master']">Master</ng-option>
                            <ng-option [value]="submasterRole" *show="['master']">Sub Master</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Codice SDI</label>
                        <input
                            [(ngModel)]="selectedDealer.codice_sdi"
                            formControlName="codice_sdi"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="codice_sdi">
                            <ng-template valError="required"> Il codice SDI è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="form-group">
                        <label>IBAN</label>
                        <input
                            [(ngModel)]="selectedDealer.iban"
                            formControlName="iban"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="iban">
                            <ng-template valError="required"> L'iban è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
            </div>
            <a href="javascript:void(0)" class="display-6" (click)="collapse.rappresentante = !collapse.rappresentante">
                Rappresentante dell'azienda
                <i
                    class="fas fa-exclamation-circle text-danger"
                    *ngIf="this.dealerFormSectionErrors.rappresentante"
                ></i>
                <i
                    class="fas float-right link-black pt-2"
                    [ngClass]="{
                        'fa-angle-down': collapse.rappresentante,
                        'fa-angle-up': !collapse.rappresentante
                    }"
                ></i>
            </a>
            <hr />
            <div class="row" [ngbCollapse]="collapse.rappresentante">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Nome</label>
                        <input
                            [(ngModel)]="selectedDealer.nome"
                            formControlName="nome"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="nome">
                            <ng-template valError="required"> Il nome è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Cognome</label>
                        <input
                            [(ngModel)]="selectedDealer.cognome"
                            formControlName="cognome"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="cognome">
                            <ng-template valError="required"> Il cognome è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Codice Fiscale</label>
                        <input
                            [(ngModel)]="selectedDealer.codfiscale"
                            formControlName="codfiscale"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="codfiscale">
                            <ng-template valError="required"> Il codice fiscale è obbligatorio </ng-template>
                            <ng-template valError="pattern"> Il codice fiscale non è del formato corretto </ng-template>
                        </val-errors>
                    </div>
                </div>
            </div>
            <a href="javascript:void(0)" class="display-6" (click)="collapse.sede = !collapse.sede">
                Sede
                <i class="fas fa-exclamation-circle text-danger" *ngIf="this.dealerFormSectionErrors.sede"></i>
                <i
                    class="fas float-right link-black pt-2"
                    [ngClass]="{
                        'fa-angle-down': collapse.sede,
                        'fa-angle-up': !collapse.sede
                    }"
                ></i>
            </a>
            <hr />
            <div class="row" [ngbCollapse]="collapse.sede">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Provincia</label>
                        <app-province-select
                            [small]="true"
                            [province]="selectedDealer.provincia"
                            (onChange)="
                                this.dealerForm.patchValue({
                                    provincia: $event
                                })
                            "
                        ></app-province-select>
                        <val-errors controlName="provincia">
                            <ng-template valError="required"> La provincia è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Comune</label>
                        <app-city-select
                            [province]="this.dealerForm.get('provincia').value"
                            [onDemand]="true"
                            [small]="true"
                            [city]="selectedDealer.localita"
                            (onChange)="this.dealerForm.patchValue({ localita: $event })"
                        ></app-city-select>
                        <val-errors controlName="localita">
                            <ng-template valError="required"> Il comune è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Indirizzo</label>
                        <input
                            [(ngModel)]="selectedDealer.indirizzo"
                            formControlName="indirizzo"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="indirizzo">
                            <ng-template valError="required"> L'indirizzo è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Civico</label>
                        <input
                            [(ngModel)]="selectedDealer.civico"
                            formControlName="civico"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="civico">
                            <ng-template valError="required"> Il civico è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>CAP</label>
                        <input
                            [(ngModel)]="selectedDealer.cap"
                            formControlName="cap"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="cap">
                            <ng-template valError="required"> Il cap è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="selectedDealer.role == agentRole">
                    <div class="form-group">
                        <label translate>Car code</label>
                        <input
                            [(ngModel)]="selectedDealer.car_code"
                            formControlName="car_code"
                            type="text"
                            class="form-control form-control-sm"
                        />
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!selectedDealer.isNew" formArrayName="vendors">
                <ng-container *ngFor="let vendor of selectedDealer.vendors; let i = index">
                    <a
                        href="javascript:void(0)"
                        class="display-6"
                        (click)="collapse.operators[i] = !collapse.operators[i]"
                        >{{ vendor.vendor_name }}</a
                    >
                    <hr />
                    <div class="row" [formGroupName]="i" [ngbCollapse]="collapse.operators[i]">
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label>Abilitato</label>
                                <select
                                    formControlName="id_dealer"
                                    [(ngModel)]="selectedDealer.vendors[i].id_dealer"
                                    class="form-control form-control-sm"
                                >
                                    <option [value]="null">No</option>
                                    <option [value]="selectedDealer.id">Si</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label>Key Account</label>
                                <app-agent-select
                                    [small]="true"
                                    [disabled]="!selectedDealer.vendors[i].id_dealer"
                                    [agent]="selectedDealer.vendors[i].id_agente"
                                    [vendors]="[selectedDealer.vendors[i].id_operator]"
                                    (onChange)="setVendorAgentOnFormArray(i, $event)"
                                >
                                </app-agent-select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label>Codice {{ vendor.vendor_name }}</label>
                                <input
                                    [(ngModel)]="selectedDealer.vendors[i].code"
                                    [disabled]="!selectedDealer.vendors[i].id_dealer"
                                    formControlName="code"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <a href="javascript:void(0)" class="display-6" (click)="collapse.altro = !collapse.altro">
                Altro
                <i class="fas fa-exclamation-circle text-danger" *ngIf="this.dealerFormSectionErrors.altro"></i>
                <i
                    class="fas float-right link-black pt-2"
                    [ngClass]="{
                        'fa-angle-down': collapse.altro,
                        'fa-angle-up': !collapse.altro
                    }"
                ></i>
            </a>
            <hr />
            <div class="row" [ngbCollapse]="collapse.altro">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>E-Mail</label>
                        <input
                            [(ngModel)]="selectedDealer.email"
                            formControlName="email"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="email">
                            <ng-template valError="required"> L'email è obbligatoria </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Telefono</label>
                        <input
                            [(ngModel)]="selectedDealer.telefono"
                            formControlName="telefono"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="telefono">
                            <ng-template valError="required"> Il telefono è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Cellulare</label>
                        <input
                            [(ngModel)]="selectedDealer.cellulare"
                            formControlName="cellulare"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <val-errors controlName="cellulare">
                            <ng-template valError="required"> Il cellulare è obbligatorio </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Whatsapp</label>
                        <input
                            [(ngModel)]="selectedDealer.whatsapp"
                            formControlName="whatsapp"
                            type="text"
                            class="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label translate>Autenticazione a 2 fattori</label>
                        <ng-select [(ngModel)]="selectedDealer.is_2fa" formControlName="is_2fa" class="form-control-sm">
                            <ng-option [value]="1">Abilita</ng-option>
                            <ng-option [value]="0">Disabilita</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Codice InPost</label>
                        <input
                            [(ngModel)]="selectedDealer.inpost"
                            formControlName="inpost"
                            type="text"
                            class="form-control form-control-sm"
                        />
                        <!-- <val-errors controlName="Codice InPost">
                            <ng-template valError="required">
                                L'email è obbligatoria
                            </ng-template>
                        </val-errors> -->
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label translate>Blacklist</label>
                        <ng-select
                            [(ngModel)]="selectedDealer.cattivo_pagatore"
                            formControlName="cattivo_pagatore"
                            class="form-control-sm"
                        >
                            <ng-option [value]="1">Si</ng-option>
                            <ng-option [value]="0">No</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="!selectedDealer.isNew">
                    <div class="form-group">
                        <label>Pagina Facebook</label>
                        <input
                            [(ngModel)]="selectedDealer.facebook_page"
                            formControlName="facebook_page"
                            type="text"
                            class="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="!selectedDealer.isNew">
                    <div class="form-group">
                        <label translate>KDSocial</label>
                        <ng-select
                            [(ngModel)]="selectedDealer.kdsocial"
                            formControlName="kdsocial"
                            class="form-control-sm"
                        >
                            <ng-option [value]="1">Si</ng-option>
                            <ng-option [value]="0">No</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="!selectedDealer.isNew && selectedDealer.role == agentRole">
                    <div class="form-group">
                        <label translate>Tipologia</label>
                        <ng-select
                            [(ngModel)]="selectedDealer.position"
                            formControlName="position"
                            class="form-control-sm"
                        >
                            <ng-option value="keyaccount">Key Account</ng-option>
                            <ng-option value="agent">Agente</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="selectedDealer.role == agentRole">
                    <div class="form-group">
                        <label>Area Manager</label>
                        <app-master-agent-select
                            [small]="true"
                            [masterAgent]="selectedDealer.id_masteragente"
                            (onChange)="setMasterAgent($event)"
                        >
                        </app-master-agent-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="!selectedDealer.isNew">
                    <div class="form-group">
                        <label translate>Priority</label>
                        <ng-select
                            [(ngModel)]="selectedDealer.priority"
                            formControlName="priority"
                            [multiple]="true"
                            [clearable]="true"
                            class="form-control-sm"
                        >
                            <ng-option value="Satispay">Satispay</ng-option>
                            <ng-option value="Amex">Amex</ng-option>
                            <ng-option value="Indabox">Indabox</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="!selectedDealer.isNew">
                    <div class="form-group">
                        <label translate>Tipologia Dealer</label>
                        <ng-select
                            [(ngModel)]="selectedDealer.dealer_type"
                            formControlName="dealer_type"
                            [clearable]="true"
                            class="form-control-sm"
                        >
                            <ng-option value="ETNICO">Etnico</ng-option>
                            <ng-option value="SPECIALISTICO">Specialistico</ng-option>
                            <ng-option value="GENERICO">Generico</ng-option>
                            <ng-option value="GRANDE_DISTRIBUZIONE">Grande Distribuzione</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="!selectedDealer.isNew">
                    <div class="form-group">
                        <label translate>Mostra Nei Report</label>
                        <ng-select
                            [(ngModel)]="selectedDealer.visibile"
                            [items]="visibile_types"
                            bindLabel="label"
                            bindValue="value"
                            formControlName="visibile"
                            [clearable]="true"
                            class="form-control-sm"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!selectedDealer.isNew">
                <a href="javascript:void(0)" class="display-6" (click)="collapse.fatturazione = !collapse.fatturazione">
                    Dati Fatturazione
                    <i
                        class="fas fa-exclamation-circle text-danger"
                        *ngIf="this.dealerFormSectionErrors.fatturazione"
                    ></i>
                    <i
                        class="fas float-right link-black pt-2"
                        [ngClass]="{
                            'fa-angle-down': collapse.fatturazione,
                            'fa-angle-up': !collapse.fatturazione
                        }"
                    ></i>
                </a>
                <hr />
                <div class="row" [ngbCollapse]="collapse.fatturazione">
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Stato Freeinvoice</label>
                            <select
                                formControlName="stato_freeinvoice"
                                [(ngModel)]="selectedDealer.stato_freeinvoice"
                                class="form-control form-control-sm"
                            >
                                <option value="0">Profilo Incompleto</option>
                                <option value="1">E-Mail Errata</option>
                                <option value="2">C.F./P.IVA/PEC errata</option>
                                <option value="3">Conferma PEC</option>
                                <option value="4">Procedura Completata</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Tipo Ritenuta</label>
                            <ng-select
                                [items]="options.tipoRitenuta"
                                formControlName="tipo_ritenuta"
                                [(ngModel)]="selectedDealer.tipo_ritenuta"
                                class="form-control-sm"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Causale Ritenuta</label>
                            <ng-select
                                [items]="options.causaleRitenuta"
                                formControlName="causale_ritenuta"
                                [(ngModel)]="selectedDealer.causale_ritenuta"
                                class="form-control-sm"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Regime Fiscale</label>
                            <ng-select
                                [items]="options.regimeFiscale"
                                formControlName="regime_fiscale"
                                [(ngModel)]="selectedDealer.regime_fiscale"
                                class="form-control-sm"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Tipologia Società</label>
                            <ng-select
                                [items]="options.tipoSocieta"
                                formControlName="tipologia_societa"
                                [(ngModel)]="selectedDealer.tipologia_societa"
                                class="form-control-sm"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>PEC</label>
                            <input
                                [(ngModel)]="selectedDealer.pec"
                                formControlName="pec"
                                type="text"
                                class="form-control form-control-sm"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="lead font-weight-bold">SEDE LEGALE</p>
                        <hr class="mt-1 mb-2" />
                    </div>

                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Provincia</label>
                            <app-province-select
                                [small]="true"
                                [province]="selectedDealer.provincia_legale"
                                (onChange)="
                                    this.dealerForm.patchValue({
                                        provincia_legale: $event
                                    })
                                "
                            ></app-province-select>
                        </div>
                    </div>

                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Comune</label>
                            <app-city-select
                                [province]="this.dealerForm.get('provincia_legale').value"
                                [onDemand]="true"
                                [small]="true"
                                [city]="selectedDealer.comune_legale"
                                (onChange)="
                                    this.dealerForm.patchValue({
                                        comune_legale: $event
                                    })
                                "
                            ></app-city-select>
                            <val-errors controlName="comune_legale">
                                <ng-template valError="required"> Il comune è obbligatorio </ng-template>
                            </val-errors>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Cap</label>
                            <input
                                [(ngModel)]="selectedDealer.cap_legale"
                                formControlName="cap_legale"
                                type="text"
                                class="form-control form-control-sm"
                            />
                            <val-errors controlName="cap_legale">
                                <ng-template valError="required"> Il cap è obbligatorio </ng-template>
                            </val-errors>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Indirizzo</label>
                            <input
                                [(ngModel)]="selectedDealer.indirizzo_legale"
                                formControlName="indirizzo_legale"
                                type="text"
                                class="form-control form-control-sm"
                            />
                            <val-errors controlName="indirizzo_legale">
                                <ng-template valError="required"> L'indirizzo legale è obbligatorio </ng-template>
                            </val-errors>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Ragione Sociale Fatturazione</label>
                            <input
                                [(ngModel)]="selectedDealer.ragsociale_fatt"
                                formControlName="ragsociale_fatt"
                                type="text"
                                class="form-control form-control-sm"
                            />
                            <val-errors controlName="ragsociale_fatt">
                                <ng-template valError="required">
                                    La ragione sociale è obbligatoria per la fatturazione
                                </ng-template>
                            </val-errors>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Codice SDI</label>
                            <input
                                    [(ngModel)]="selectedDealer.codice_sdi"
                                    formControlName="codice_sdi"
                                    type="text"
                                    class="form-control form-control-sm"
                            />
                            <val-errors controlName="codice_sdi">
                                <ng-template valError="required">
                                    Il codice SDI è obbligatorio
                                </ng-template>
                            </val-errors>
                        </div>
                    </div> -->
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Api Key Free Invoice</label>
                            <input
                                [(ngModel)]="selectedDealer.fi_api_key"
                                formControlName="fi_api_key"
                                type="text"
                                class="form-control form-control-sm"
                            />
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="mode === 'retailer' && false">
                    <a
                        href="javascript:void(0)"
                        class="display-6"
                        (click)="collapse.kdricariche = !collapse.kdricariche"
                    >
                        KDRicariche
                        <i
                            class="fas float-right link-black pt-2"
                            [ngClass]="{
                                'fa-angle-down': collapse.kdricariche,
                                'fa-angle-up': !collapse.kdricariche
                            }"
                        ></i>
                    </a>
                    <hr />
                    <div [ngbCollapse]="collapse.kdricariche">
                        <div class="row" formGroupName="shopAccess">
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label>Può accedere dal</label>
                                    <input
                                        [(ngModel)]="shopAccess.from"
                                        placeholder="gg-mm-aaaa"
                                        ngbDatepicker
                                        formControlName="from"
                                        #from="ngbDatepicker"
                                        (click)="from.toggle()"
                                        type="text"
                                        class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label>Al</label>
                                    <input
                                        [(ngModel)]="shopAccess.to"
                                        type="text"
                                        placeholder="gg-mm-aaaa"
                                        ngbDatepicker
                                        formControlName="to"
                                        #to="ngbDatepicker"
                                        (click)="to.toggle()"
                                        class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <label>&nbsp;</label>
                                <button
                                    class="btn btn-success btn-block btn-sm"
                                    [disabled]="!this.shopAccess.from || !this.shopAccess.to"
                                    (click)="saveShopAccess()"
                                >
                                    Salva
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngFor="let access of this.selectedDealer.shopAccesses">
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label>Può accedere dal</label>
                                    <input
                                        [value]="access.from | amDateFormat : 'DD-MM-YYYY'"
                                        type="text"
                                        [attr.disabled]="true"
                                        class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label>Al</label>
                                    <input
                                        [value]="access.to | amDateFormat : 'DD-MM-YYYY'"
                                        type="text"
                                        class="form-control form-control-sm"
                                        [attr.disabled]="true"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <label>&nbsp;</label>
                                <button class="btn btn-danger btn-block btn-sm" (click)="deleteShopAccess(access.id)">
                                    Cancella
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="mode === 'retailer'">
                    <a href="javascript:void(0)" class="display-6" (click)="collapse.spidIr = !collapse.spidIr">
                        Spid IR
                        <i
                            class="fas float-right link-black pt-2"
                            [ngClass]="{
                                'fa-angle-down': collapse.spidIr,
                                'fa-angle-up': !collapse.spidIr
                            }"
                        ></i>
                    </a>
                    <hr />
                    <div class="row" [ngbCollapse]="collapse.spidIr">
                        <div class="col-3">
                            <div class="form-group">
                                <label>Abilitato</label>
                                <ng-select formControlName="spid_ir_enabled" class="form-control-sm">
                                    <ng-option [value]="true">Si</ng-option>
                                    <ng-option [value]="false">No</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>Codice Sede</label>
                                <input
                                    [disableControl]="this.dealerForm.get('spid_ir_enabled').value == false"
                                    formControlName="spid_codice_sede"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>User</label>
                                <input
                                    formControlName="spid_user"
                                    [disableControl]="this.dealerForm.get('spid_ir_enabled').value == false"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>Progressivo</label>
                                <input
                                    formControlName="spid_progressivo"
                                    type="text"
                                    [disableControl]="this.dealerForm.get('spid_ir_enabled').value == false"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="mode === 'retailer'">
                    <a href="javascript:void(0)" class="display-6" (click)="collapse.kdpay = !collapse.kdpay">
                        KDPay
                        <i
                            class="fas float-right link-black pt-2"
                            [ngClass]="{
                                'fa-angle-down': collapse.kdpay,
                                'fa-angle-up': !collapse.kdpay
                            }"
                        ></i>
                    </a>
                    <hr />
                    <div class="row" [ngbCollapse]="collapse.kdpay">
                        <!--                        <div class="col-3">-->
                        <!--                            <div class="form-group">-->
                        <!--                                <label>ID Terminale</label>-->
                        <!--                                <input-->
                        <!--                                        [(ngModel)]="selectedDealer.pay_terminal_id"-->
                        <!--                                        formControlName="pay_terminal_id"-->
                        <!--                                        type="text"-->
                        <!--                                        class="form-control form-control-sm"-->
                        <!--                                />-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="col-3">
                            <div class="form-group">
                                <label>Username</label>
                                <input
                                    [(ngModel)]="selectedDealer.pay_username"
                                    formControlName="pay_username"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>Password</label>
                                <input
                                    [(ngModel)]="selectedDealer.pay_password"
                                    formControlName="pay_password"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>VAS ID</label>
                                <input
                                    [(ngModel)]="selectedDealer.pay_vas_id"
                                    formControlName="pay_vas_id"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>VAS Password</label>
                                <input
                                    [(ngModel)]="selectedDealer.pay_vas_password"
                                    formControlName="pay_vas_password"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div> </ng-container
                ><ng-container *ngIf="mode === 'retailer'">
                    <a href="javascript:void(0)" class="display-6" (click)="collapse.sdd = !collapse.sdd">
                        SDD
                        <i
                            class="fas float-right link-black pt-2"
                            [ngClass]="{
                                'fa-angle-down': collapse.sdd,
                                'fa-angle-up': !collapse.sdd
                            }"
                        ></i>
                    </a>
                    <hr />
                    <div class="row" [ngbCollapse]="collapse.sdd">
                        <div class="col-4">
                            <div class="form-group">
                                <label>Abilitato</label>
                                <select
                                    formControlName="sdd_enabled"
                                    [(ngModel)]="selectedDealer.sdd_enabled"
                                    class="form-control form-control-sm"
                                >
                                    <option [value]="0">No</option>
                                    <option [value]="1">Si</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4" *ngIf="selectedDealer.sdd_enabled.toString() === '1'">
                            <div class="form-group">
                                <label>IBAN Addebito</label>
                                <input
                                    [(ngModel)]="selectedDealer.sdd_iban"
                                    formControlName="sdd_iban"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>

                        <div class="col-4" *ngIf="selectedDealer.sdd_enabled.toString() === '1'">
                            <div class="form-group">
                                <label>Data Firma</label>
                                <input
                                    [(ngModel)]="selectedDealer.sdd_signature_date"
                                    placeholder="gg-mm-aaaa"
                                    ngbDatepicker
                                    formControlName="sdd_signature_date"
                                    #sdd="ngbDatepicker"
                                    (click)="sdd.toggle()"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                            </div>
                        </div>

                        <div class="col-4" *ngIf="selectedDealer.sdd_enabled.toString() === '1'">
                            <div class="form-group">
                                <label>Tipo SDD</label>
                                <ng-select
                                    [items]="sdd_types"
                                    [multiple]="true"
                                    bindValue="value"
                                    bindLabel="label"
                                    formControlName="sdd_type"
                                    [(ngModel)]="selectedDealer.sdd_type"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Note</label>
                        <textarea
                            [(ngModel)]="selectedDealer.note"
                            formControlName="note"
                            class="form-control form-control-sm"
                            rows="5"
                        ></textarea>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button
                        class="btn btn-success btn-block"
                        [disabled]="!this.dealerForm.valid"
                        (click)="saveDealer(selectedDealer)"
                    >
                        <i class="fa fa-check-circle mr-2"></i> Salva
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #campaignFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Create Campaign</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-pbx-campaign-modal
        [dealerIds]="selectedDealersForCampaign"
        (createdCampaign)="handleSavedCampaign($event)"
        [filters]="selectedFilters"
    >
    </app-pbx-campaign-modal>
</ng-template>

<ng-template #passwordResetModal let-modal>
    <div class="modal-header">
        <h3 class="modal-title clamp-title" translate>Reset Password Link</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body mb-4" *ngIf="link">
        <h5 class="mb-4">
            Invia questo link al partner per poter effettuare il reset
            {{ dealerName }}
        </h5>
        <div class="row">
            <p class="col-11">{{ link }}</p>
            <button
                placement="top"
                ngbTooltip="Copia"
                [value]="link"
                id="clipboardPassword"
                (click)="copyToClipboard()"
                class="col-1 btn bg-transparent p-1"
            >
                <i class="fa fa-copy text-secondary fa-lg"></i>
            </button>
        </div>
    </div>
    <div class="modal-body mb-4" *ngIf="!link">
        <h5 class="mb-2 mt-2 text-danger">Error generating link!</h5>
    </div>
</ng-template>

<ng-template #socialActivation let-modal>
    <div class="modal-header">
        <h3 class="modal-title clamp-title">KDSocial</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body mb-4">
        <h5 class="mb-4">Inserisci la pagina facebook di {{ selectedDealer.name }}</h5>
        <div class="form-group">
            <label>Link Pagina Facebook</label>
            <input
                type="text"
                class="form-control"
                [disabled]="loadingFacebook"
                [(ngModel)]="selectedDealer.facebook_page"
            />
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="saveFacebookPage()" [ladda]="this.loadingFacebook">Salva</button>
    </div>
</ng-template>

<ng-template #dealerTypeEdit let-modal>
    <div class="modal-header">
        <h3 class="modal-title clamp-title">Tipologia Dealer</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body mb-4">
        <h5 class="mb-4">Modifica la tipologia del Dealer {{ selectedDealer.name }}</h5>
        <div class="form-group">
            <label translate>Tipologia Dealer</label>
            <ng-select [(ngModel)]="selectedDealer.dealer_type" [clearable]="true" [disabled]="loadingDealerType">
                <ng-option value="ETNICO">Etnico</ng-option>
                <ng-option value="SPECIALISTICO">Specialistico</ng-option>
                <ng-option value="GENERICO">Generico</ng-option>
                <ng-option value="GRANDE_DISTRIBUZIONE">Grande Distribuzione</ng-option>
            </ng-select>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="saveDealerType()" [ladda]="this.loadingDealerType">Salva</button>
    </div>
</ng-template>
