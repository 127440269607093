import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { EPayService } from '@services/e-pay.service';
import { ShopBrand } from '@models/e-pay/shop-brand';
import { AuthenticationService } from '@services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { User } from '@models/user';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { KdOrderService } from '@services/kd-order.service';
import { Amex } from '@app/models/kd-orders';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-amex',
  templateUrl: './amex.component.html',
  styleUrls: ['./amex.component.css'],
})
export class AmexComponent implements OnInit {
  amexOrders: Amex[] = [];
  ordersPagination: MetaPagination = new MetaPagination();
  insertData: Amex;
  busy: any;
  loadingImport = false;
  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };
  filter = {
    id: '',
    company_name: '',
    dealer_id: '',
    vat: '',
    phone: '',
    email: '',
    status: '',
    created_at: '',
    date: '',
    opportunity_name: '',
    lead_status: '',
    lead_sub_status: '',
    stage: '',
  };
  apiDate = {
    from: '',
    to: '',
  };
  statuses = Amex.orderStatus;
  selectedContentId = null;
  selectedOrder: Amex;
  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };
  brandsLoading = false;
  brands: ShopBrand[] = [];
  isDealer = false;

  @ViewChild('amexFormTemplate') private amexFormTemplate;
  @ViewChild('popContent') private popContent;
  @ViewChild('receiptFormTemplate') private receiptFormTemplate;

  showLegend: boolean = false;

  stages = [
    { name: 'Stage 1 - Prospect Evaluation', description: 'Valutazione del Prospect senza che ci sia stato un primo contatto.' },
    { name: 'Stage 2 - First Contact', description: 'Primo contatto con il cliente senza offerta commerciale.' },
    {
      name: 'Stage 3 - Needs Assessment + Stage 4 - Formal Presentation',
      description: 'Proposta di offerta commerciale descrivendo il prodotto e scegliendo quale sia più adatto alle esigenze del cliente.',
    },
    { name: 'Stage 5 - Decision Pending', description: "Il cliente sta valutando l'offerta commerciale." },
    { name: 'Stage 6 - Verbal Win/Contract Pending', description: "Il cliente ha dato l'OK alla firma del contratto, aspettiamo documentazione." },
    { name: 'Stage 7 - Contract Fully Executed', description: 'Il contratto è firmato ed è in lavorazione nel sistema di Amex.' },
    { name: 'Closed-Won', description: 'La carta è stata emessa e spedita al cliente.' },
    {
      name: 'Closed-Miss',
      description:
        'Il cliente non ha mai risposto dopo reiterate telefonate. Dopo la 4/5 chiamate e varie email di sollecito lo chiudiamo "Miss" o la carta - dopo lavorazione da parte del sistema (ex Stage 7) - viene Declinata.',
    },
    {
      name: 'Closed-Duplicate',
      description:
        "Il cliente lo chiudiamo come Duplicato quando ci accorgiamo che ha già una carta Business solo dopo averlo convertito da Lead ad Opportunità, oppure c'è una doppia segnalazione (ne teniamo una e chiudiamo come Duplicato la seconda).",
    },
  ];

  constructor(
    private ePayService: EPayService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    private dateRangePickerOptions: DaterangepickerConfig,
    private AmexService: KdOrderService,
    public route: ActivatedRoute,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadOrders();
    setTimeout(() => {});
  }
  isAuthorized(): boolean {
    const currentUser = this.authenticationService.currentUserValue;
    return currentUser && ['master', 'operator', 'submaster'].includes(currentUser.profile.role);
  }

  toggleLegend() {
    this.showLegend = !this.showLegend;
  }

  loadOrders(hideSpinner = false) {
    this.spinner.show('table-orders');
    const params = {};
    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.vat) {
      params['vat'] = this.filter.vat;
    }
    if (this.filter.phone) {
      params['phone'] = this.filter.phone;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.opportunity_name) {
      params['opportunity_name'] = this.filter.opportunity_name;
    }
    if (this.filter.lead_status) {
      params['lead_status'] = this.filter.lead_status;
    }
    if (this.filter.lead_sub_status) {
      params['lead_sub_status'] = this.filter.lead_sub_status;
    }
    if (this.filter.stage) {
      params['stage'] = this.filter.stage;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.AmexService.getAmexOrders(params).subscribe(
      (response: any) => {
        this.amexOrders = new Amex().fromArray(response.data);
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  showModal(data?: Amex) {
    this.insertData = data;
    this.modalService.open(this.amexFormTemplate, { size: data ? 'md' : 'lg' });
  }

  save(value) {
    const body = {};
    body[this.popupConfig['key']] = value;
    this.spinner.show('popupSpinner');
    return this.changeOrder(this.popupConfig.id, body);
  }

  changeOrder(orderId, body) {
    this.AmexService.putAmexOrder(orderId, body).subscribe(
      (response) => {
        this.loadOrders(true);
        this.spinner.hide('popupSpinner');
      },
      (err) => {
        this.loadOrders(true);
        this.modalService.dismissAll();
        this.spinner.hide('popupSpinner');
      },
    );
  }

  openPopover(popOver: any, orderId, item, key, selectItems = []) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }

  exportAmex() {
    const params = {
      'per-page': 0,
    };

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.vat) {
      params['vat'] = this.filter.vat;
    }
    if (this.filter.email) {
      params['email'] = this.filter.email;
    }
    if (this.filter.phone) {
      params['phone'] = this.filter.phone;
    }
    if (this.filter.opportunity_name) {
      params['opportunity_name'] = this.filter.opportunity_name;
    }
    if (this.filter.lead_status) {
      params['lead_status'] = this.filter.lead_status;
    }
    if (this.filter.lead_sub_status) {
      params['lead_sub_status'] = this.filter.lead_sub_status;
    }
    if (this.filter.stage) {
      params['stage'] = this.filter.stage;
    }

    this.AmexService.getAmexOrders(params).subscribe((data) => {
      let rows = data.data;

      let output = rows.map((row) => {
        return {
          Dealer: row.dealer,
          Contatto: row.company_name,
          'Dealer ID': row.dealer_id,
          'Partita IVA': row.vat,
          Email: row.email,
          Telefono: row.phone,
          'Nome referral': row.referral_name,
          'Cognome referral': row.referral_surname,
          'Email referral': row.referral_email,
          'Opportunity name': row.opportunity_name,
          'Lead status': row.lead_status,
          'Lead sub status': row.lead_sub_status,
          Stage: row.stage,
          'Sub-Product': row.card_type,
          'Opportunity Date': row.opportunity_date,
          Stato: row.status,
          'Data Inserimento': row.created_at,
          'Data Gestione': row.updated_at,
        };
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-amex.xlsx');
    });
  }
}
