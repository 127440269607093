import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CuService} from '@services/cu.service';
import { Cu } from '@app/models/cu';
import * as moment from 'moment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-cu-modal',
  templateUrl: './cu-modal.component.html',
  styleUrls: ['./cu-modal.component.css']
})
export class CuModalComponent implements OnInit {

  @Input() data: Cu;
  @Output() refresh = new EventEmitter<boolean>();

  cuForm: FormGroup;
  busy: any;
  file: File;
  busyDocumentFormModal: Subscription;
  documentFormModal: NgbModalRef;
  @ViewChild('documentFormTemplate') private documentFormTemplate;

  constructor(
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    private cuService: CuService
  ) { }

  ngOnInit(): void {
    this.cuForm = this.formBuilder.group({
      dealer_id: [null, [Validators.required]],
      year: [null, [Validators.required]],
      file_name: [null, [Validators.required]]
    });   
  }

  onSubmit(){
    const form = this.cuForm.value;

    // form.created = moment(form.created, 'DD-MM-YYYY').format('YYYY-MM-DD');

    const payload = new FormData();
    payload.append('dealer_id', form['dealer_id']);
    payload.append('year', form['year']);
    payload.append('file', this.file);
  
    return this.addSubscription(payload);
  }

  setDealerId(dealerId: any) {
    this.cuForm.get('dealer_id').setValue(dealerId);
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  handleFileInput(files: FileList) {
    if (files && files.item(0)) {
        const document = files.item(0);
        this.file = document;
        if (document) {
          this.cuForm.get('file_name').setValue(document.name);
        }
    }
  }

  addSubscription(selectedSubscription: FormData) {
    this.busy = this.cuService.addCuSubscription(selectedSubscription).subscribe((response: Cu) => {
        this.cuService.successAlert('CU aggiunto correttamente!');
        this.refresh.emit(true);
        this.dismissModal();
    }, err => {
        this.cuService.errorAlert(err.message);
        this.refresh.emit(true);
        this.dismissModal();
    });
  }
}
