<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadOrders()">
    <div class="form-row">
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label for="companyName" translate>Company name</label>
          <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="companyName"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Data</label>
          <input
            (selected)="generateDateRangeString($event.start, $event.end)"
            [(ngModel)]="filter.date"
            [options]="pickerOptions"
            class="form-control"
            daterangepicker
            id="documentDate"
            name="date"
            type="text"
          />
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <button class="btn btn-info btn-block" translate type="submit">Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card">
  <div class="card-header">
    <h4 class="mb-0">Amazon Counter</h4>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner
      [fullScreen]="false"
      bdColor="rgba(0, 0, 0, 0.8)"
      color="#fff"
      name="table-orders"
      size="default"
      type="square-jelly-box"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th *show="['master', 'operator']">Rag. Sociale</th>
          <th>Data</th>
          <th>Stato</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="bg-gradient-white">
        <ngx-spinner
          [fullScreen]="false"
          bdColor="rgba(0, 0, 0, 0.8)"
          color="#fff"
          name="orders"
          size="default"
          type="square-jelly-box"
        >
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngFor="let order of orders">
          <td *show="['master', 'operator']">
            <a [routerLink]="['/dealer', order.dealer_id]" target="_blank"> {{ order.company_name }}</a>
          </td>
          <td>
            {{ order?.created_at | date : 'dd/MM/yyyy HH:MM:ss' }}
          </td>
          <td>
            <a
              href="javascript:void(0)"
              (click)="openPopover(statusPopover, order?.id, order?.status, 'status', statuses)"
              #statusPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContent"
              popoverTitle="Cambia valore"
            >
              <span *ngIf="order.status == 'accepted'">Accettata</span>
              <span *ngIf="order.status == 'canceled'">Annullata</span>
              <span *ngIf="order.status == 'pending'">In revisione</span>
            </a>
          </td>
          <td class="text-right">
            <button (click)="showDetailModal(order)" class="btn btn-link">
              <i class="fa fa-eye text-info"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="orders?.length <= 0">
          <td class="text-muted" colspan="10" translate>List is empty</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="orders?.length > 0" class="card-footer">
    <span
      ><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
      {{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.
      <a class="link" (click)="exportAmazonCounter()">Esporta la lista completa</a>
    </span>
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      (pageChange)="loadOrders()"
      [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
</div>

<ng-template #detailTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">Candidatura di {{ selectedData?.company_name }}</h4>
    <button (click)="modalService.dismissAll()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-bordered">
      <tr>
        <th>Negozio</th>
        <td>{{ selectedData?.company_name }}</td>
      </tr>
      <tr>
        <th>Partita IVA</th>
        <td>{{ selectedData?.vat }}</td>
      </tr>
      <tr>
        <th>Nome</th>
        <td>{{ selectedData?.first_name }}</td>
      </tr>
      <tr>
        <th>Cognome</th>
        <td>{{ selectedData?.last_name }}</td>
      </tr>
      <tr>
        <th>Indirizzo</th>
        <td>{{ selectedData?.address }}</td>
      </tr>
      <tr>
        <th>Città</th>
        <td>{{ selectedData?.city }}</td>
      </tr>
      <tr>
        <th>Provincia</th>
        <td>{{ selectedData?.province }}</td>
      </tr>
      <tr>
        <th>Latitudine e Longitudine</th>
        <td>{{ selectedData?.lat }} - {{ selectedData?.lng }}</td>
      </tr>
      <tr>
        <th>E-Mail</th>
        <td>{{ selectedData?.email }}</td>
      </tr>
      <tr>
        <th>Cellulare</th>
        <td>{{ selectedData?.mobile }}</td>
      </tr>
      <tr>
        <th>Telefono</th>
        <td>{{ selectedData?.phone }}</td>
      </tr>
      <tr>
        <th>Orari di apertura</th>
        <td>
          <li *ngFor="let openingHour of selectedData?.opening_hours">
            {{ openingHour.start }}
            <span *ngFor="let range of openingHour.shiftSchedules">{{ range.start }} - {{ range.end }}</span>
          </li>
        </td>
      </tr>
    </table>
  </div>
</ng-template>
<ng-template #popContent>
  <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
