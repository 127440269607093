import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";
import {RequestService} from '@services/request.service';

@Component({
  selector: 'app-autoload-fatturato',
  templateUrl: './autoload-fatturato.component.html',
  styleUrls: ['./autoload-fatturato.component.css']
})
export class AutoloadFatturatoComponent implements OnInit {
    _urlFatturato: string;
    data: any;

    @Input() brand: string
    @Input() set urlFatturato(value: string) {
        this._urlFatturato = value;

        if (this._urlFatturato !== undefined) {
            this.loadFatturato();
        }
    }

    constructor(
        private requestService: RequestService,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {

    }

    private loadFatturato() {
        this.spinner.show('loader-fatturato');
        this.requestService.get(this.brand + this._urlFatturato)
            .subscribe((data) => {
                this.data = data.result;
                this.spinner.hide('loader-fatturato');
            });
    }

}
