import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PbxCall} from '@models/pbx/pbx-call';
import {MetaPagination} from '@models/meta-pagination';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PbxLead} from '@models/pbx/pbx-lead';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {PbxCampaignQuestion} from '@models/pbx/pbx-question';
import {NgxSpinnerService} from 'ngx-spinner';
import {ExcelService} from '@services/excel.service';

@Component({
    selector: 'app-pbx-calls',
    templateUrl: './pbx-calls.component.html',
    styleUrls: ['./pbx-calls.component.css']
})
export class PbxCallsComponent implements OnInit {
    @Input() calls: PbxCall[] = [];
    @Input() callsPagination: MetaPagination;
    @Output() changePage = new EventEmitter<any>();
    localFormat = PbxCampaignService.datetimeLocalFormat;
    @ViewChild('answersFormTemplate') private answersFormTemplate;
    @ViewChild('callFormTemplate') private callFormTemplate;
    deleteCallOptions: ConfirmOptionsService;
    answersModal: NgbModalRef;
    questionAnswers: PbxCampaignQuestion[] = [];
    callModal: NgbModalRef;
    selectedEditCall: PbxCall;
    selectedCall: PbxCall;
    selectedCampaign;
    filter = {
        province_id: '',
        region_id: '',
        city_id: '',
        company_name: ''
    };

    constructor(
        public modalService: NgbModal,
        private pbxCampaignService: PbxCampaignService,
        private spinner: NgxSpinnerService,
        private excelService: ExcelService) {
    }

    ngOnInit(): void {
        this.deleteCallOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
        this.pbxCampaignService.changedCampaign.subscribe(data => {
            this.selectedCampaign = data?.id;
            if (this.callsPagination && data) {
                this.changePage.emit(1);
            }
        });
    }

    loadCalls() {
        this.changePage.emit(this.filter);
    }

    showAnswersModal(call: PbxCall) {
        this.questionAnswers = [];
        this.selectedCall = call;
        this.loadQuestionsAndAnswers(call);
        this.answersModal = this.modalService.open(this.answersFormTemplate, {size: 'lg'});
        this.answersModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showCallModal(call: PbxCall) {
        this.selectedEditCall = call;
        this.callModal = this.modalService.open(this.callFormTemplate, {size: 'xl'});
        this.callModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    callStatus(key: string) {
        return key ? PbxCampaignService.callStatuses.find(status => status.key === key) : '';
    }

    feedbackStatus(key: string) {
        return key ? PbxCampaignService.feedbackStatuses.find(status => status.key === key) : '';
    }

    deleteCall(id: number) {
        this.pbxCampaignService.deleteCall(id).subscribe((response) => {
            const index = this.calls.findIndex(call => call.id === id);
            if (index > -1) {
                this.calls.splice(index, 1);
            }
        });
    }

    loadQuestionsAndAnswers(call: PbxCall) {
        this.spinner.show('table-questions');
        this.pbxCampaignService.getQuestionAnswers(call.id, call.lead.campaign_id).subscribe((response: PbxCampaignQuestion[]) => {
            this.questionAnswers = response;
            this.spinner.hide('table-questions');
        }, err => {
            this.spinner.hide('table-questions');
        });
    }

    exportList(): void {
        this.spinner.show('table-calls');
        this.pbxCampaignService.getCalls({
            campaign_id: this.selectedCampaign,
            exportExcel: true
        }).subscribe((responseCalls) => {
            // let questions = [];
            //
            // responseCalls.data.forEach((item) => {
            //    item.pbxQuestionAnswers.forEach((answer) => {
            //       if(!questions.includes(answer.question)) {
            //           questions.push(answer.question);
            //       }
            //    });
            // });
            //
            // console.log(questions);
            //
            // const array = responseCalls.data.map((item) => {
            //     let row = {
            //         'ID Dealer': item.lead.dealer_id,
            //         'Operatore': item.caller_name,
            //         'Dealer': item.lead.company_name,
            //         'Durata': item.duration,
            //         'Numero': item.destination,
            //         'Note': item.response.replace(/(<([^>]+)>)/gi, ""),
            //         'Stato Feedback': item.feedback_status,
            //         'Stato Chiamata': item.call_status,
            //     };
            //
            //     questions.forEach(question => {
            //         const answer = item.pbxQuestionAnswers.find(answ => answ.question === question);
            //
            //         row[question] = '';
            //         if(answer) {
            //             row[question] = answer.text.replace(/(<([^>]+)>)/gi, "");
            //         }
            //     });
            //
            //     return row;
            // });
            //
            // this.excelService.exportAsExcelFile(array, 'calls');
            this.spinner.hide('table-calls');
        }, err => {
            this.spinner.hide('table-calls');
        });
    }
}
