import {Injectable} from '@angular/core';
import {Affiliation, AffiliationList, RequiredDocument} from '@app/models/affiliation';
import {Company} from '@app/models/company';
import {Dealer} from '@app/models/dealer';
import {Person} from '@app/models/user';
import {RequestService} from '@services/request.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DealerService} from './dealer.service';
import {AlertService} from '@services/alert.service';
import { AuthenticationService } from './authentication.service';

const affiliations = 'affiliations';
@Injectable({
    providedIn: 'root'
})
export class AffiliationService {

    constructor(
        public request: RequestService,
        private dealers: DealerService,
        private authService: AuthenticationService,
        private alertService: AlertService
    ) {
    }

    list(params?): Observable<AffiliationList> {
        return this.request.get(affiliations, params).pipe(
            map((response: any) => {
                return new AffiliationList(response);
            })
        );
    }

    updateAffiliation(id: number, status: number):Observable<any>{   
        const payload = { status: status };
        return this.request.post('affiliations/' + id, payload);
    }

    getDealerList(): Observable<Dealer[]> {
        return this.dealers.getList().pipe(
            map((response: any) => {
                return new Dealer().fromArray(response);
            })
        );
    }

    getOperatorList(dealerId: number = null): Observable<Company[]> {
        return this.dealers.getDealer(`${this.authService.currentUserValue.profile.id}`, {
            expand: 'enabledVendors'
        }).pipe(
            map((response: any) => {
                return new Company().fromArray(response.enabledVendors);
            })
        );
    }

    getRequiredDocumentsList(operatorId: number = null): Observable<RequiredDocument[]> {
        return this.request.get(`affiliations/documents/${operatorId}`).pipe(
            map((response: any) => {
                return new RequiredDocument().fromArray(response);
            })
        );
    }

    saveAffiliation(url, model: Affiliation, alertText): Observable<Affiliation> {
        return this.request.post(url, model.postPayload).pipe(
            map((response: any) => {
                this.successAlert(alertText);
                return new Affiliation(response);
            })
        );
    }

    changeStatusOfAffiliation(url, model, alertText): Observable<Affiliation> {
        return this.request.post(url, model).pipe(
            map((response: any) => {
                this.successAlert(alertText);
                return new Affiliation(response);
            })
        );
    }

    getAffiliationJson(id, alertText) {
        return this.request.get(`affiliations/file/${id}`).pipe(
            map((response: any) => {
                this.successAlert(alertText);
                return response;
            })
        );
    }

    getAffiliationJsonA2a(id, alertText) {
        return this.request.get(`affiliations/file-a2a/${id}`).pipe(
            map((response: any) => {
                this.successAlert(alertText);
                return response;
            })
        );
    }

    deleteAffiliation(url, alertText): Observable<Affiliation> {
        return this.request.delete(url).pipe(
            map((response: any) => {
                this.successAlert(alertText);
                return new Affiliation(response);
            })
        );
    }

    successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }

    getAffiliationsParam(affiliations, form, date) {
        const param = this.getFiltersParam(form, date);
        return {
            'in_revision-page': affiliations.in_revision._meta.currentPage,
            'in_revision-per-page': affiliations.in_revision._meta.perPage,
            'archived-page': affiliations.archived._meta.currentPage,
            'archived-per-page': affiliations.archived._meta.perPage,
            ...param
        };
    }

    setPaginationParam(objName, affiliations, currentPage, perPage, form, date) {
        const filtersParam = this.getFiltersParam(form, date);
        const params = {};
        params[objName + '-page'] = currentPage;
        params[objName + '-per-page'] = perPage;
        return {...params, ...filtersParam};
    }

    getFiltersParam(form, date) {
        const param = {};
        if (form.dealer) {
            param['dealer_id'] = form.dealer;
        }
        if (form.operator) {
            param['operator_id'] = form.operator;
        }
        if (form.date) {
            param['from_date'] = date.from_date;
            param['to_date'] = date.to_date;
        }
        if (form.state) {
            param['status'] = form.state;
        }
        return param;
    }

    getStatusList() {
        return [{
            value: 'Approved',
            id: 1
        },
            {
                value: 'Rejected',
                id: 2
            }
        ];
    }

    removeItemFromList(affiliation, affiliationItems) {
        for (let i = 0; i < affiliationItems.length; i++) {
            if (affiliationItems[i].id === affiliation.id) {
                affiliationItems.splice(i, 1);
            }
        }
        return affiliationItems;
    }
}
