<div class="card bg-gradient-light">
    <div class="card-header" *ngIf="!hideHeader">
        <div class="row">
            <div class="col-lg-auto col-12">
                <h1 class="display-6 mb-0 mb-2" translate>
                    Timeline
                </h1>
            </div>
            <div class="col-lg-3 col-12">
                <input (selected)="loadNewDate($event?.start, $event?.end)" [(ngModel)]="selectedDate" class="form-control form-control-sm" daterangepicker
                       id="date" type="text"/>
            </div>
            <div class="col-auto">
                <button (click)="showAll()" class="btn btn-primary btn-sm">Mostra Tutti</button>
            </div>
            <div *show="['operator', 'agent']" class="col-auto justify-content-lg-end">
                <button (click)="showReportModal()" class="btn btn-success btn-sm float-right" translate
                        type="button">
                    Add Report
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <ngx-spinner
                [fullScreen]="false"
                bdColor="rgba(0, 0, 0, 0.8)"
                color="#fff"
                name='timeline'
                size="default"
                type="square-jelly-box"
        >
        </ngx-spinner>
        <span *ngIf="reports?.length<=0" class="text-muted">No reports</span>
        <div *ngIf="reports?.length>0" class="timeline">
            <ng-container *ngFor="let date of dates">
                <div class="time-label lead"><span
                        class="bg-gradient-success">{{date|amDateFormat:localDateFormat}}</span>
                </div>
                <div *ngFor="let report of reports" [ngClass]="{'d-none':report.date != date}">
                    <i *ngIf="report.date == date"
                       [ngClass]="{
                       'fa-comment bg-gradient-info': report.type === 'report',
                       'fa-map bg-gradient-success': report.type === 'visit',
                        'fa-phone bg-gradient-warning': report.type === 'call'
                        }" class="fas"></i>
                    <div *ngIf="report.date == date" class="elevation-1 timeline-item">
                        <h3 class="timeline-header pb-4 pb-lg-0">
                            <strong><em>{{report?.from}}</em></strong>
                            <span class="float-right">{{report?.category}}</span>
                        </h3>
                        <div class="timeline-body">
                            <span class="text-muted">
                               <app-rich-text-truncate [charLimit]="10000"
                                                       [text]="report?.text"></app-rich-text-truncate>
                            </span>

                            <ng-container *ngIf="report.type === 'visit'">
                                <div *ngFor="let item of report.json_data | keyvalue"
                                     class="bg-gradient-white rounded p-2 mb-2 border">
                                    <div class="text-md font-weight-bold">{{item.key}}</div>
                                    <div>{{item.value}}</div>
                                </div>

                                <div *ngIf="report.json_data && report.json_data.length === 0" class="text-muted font-italic">
                                    Non ci sono note
                                </div>

                                <p *ngIf="report.affiliato === '1'" class="mt-2"><i
                                        class="fas fa-check-circle text-success"></i>
                                    Affiliato</p>
                                <p *ngIf="report.attivita_chiusa === '1'" class="mt-2"><i
                                        class="fas fa-check-circle text-danger"></i>
                                    Attivita chiusa</p>
                            </ng-container>
                        </div>
                        <div class="timeline-footer border-top">
                            <div class="row align-items-center justify-content-between">
                                <div class="col">
                                    <p class="mb-0 text-muted mr-2" style="font-size: 14px;">
                                        <i class="far fa-clock mr-2 text-info"></i>
                                        <span>{{report?.datetime|amDateFormat: 'HH:mm DD/MM/YYYY' }}</span></p>
                                </div>
                                <ng-container *ngIf="report.type === 'report'">
                                    <div *show="['operator', 'agent']" class="col text-right">
                                        <button (click)="showReportModal(report)" *ngIf="currentUser == report?.user_id"
                                                class="btn btn-link btn-sm"
                                        >
                                            <i class=" fa fa-edit text-muted"></i>
                                        </button>
                                        <button (confirm)="deleteReport(report.id)" *ngIf="currentUser == report?.user_id"
                                                [swal]="deleteDocumentOptions"
                                                class="btn btn-link btn-sm">
                                            <i class="fa fa-trash text-danger"></i>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #reportFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add Report</h4>
        <button (click)="modal.close('Ok click')"
                aria-label="Close"
                class="close"
                type="button"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div [ngBusy]="busy" class="modal-body">
        <form (ngSubmit)="saveReport()" [formGroup]="reportForm">
            <div class="form-row">
                <div class="col-12">
                    <label for="category" translate>Category</label>
                </div>
                <div class="col-12 col-lg form-group">
                    <ng-select (search)="loadCategories($event?.term)" [items]="categories" [loading]="categoriesLoading" [searchable]="true"
                               bindLabel="name" bindValue="id" formControlName="category_id"
                               id="category"></ng-select>
                    <val-errors controlName="category_id">
                        <ng-template valError="required">
                            <span translate>Category is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg form-group">
                    <button (click)="showCategoryModal()" class="btn btn-secondary btn-block" translate type="button">
                        Add New Category
                    </button>
                </div>
                <div class="col-12 form-group">
                    <label for="note" translate>Note</label>
                    <editor [init]="{base_url: '/tinymce', suffix: '.min'}" formControlName="note"
                            id="note"></editor>
                    <val-errors controlName="note">
                        <ng-template valError="required">
                            <span translate>Note is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12">
                    <button [disabled]="reportForm.invalid" class="btn btn-success btn-block" type="submit">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #categoryFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add Category</h4>
        <button (click)="modal.close('Ok click')"
                aria-label="Close"
                class="close"
                type="button"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div [ngBusy]="busyCategory" class="modal-body">
        <form (ngSubmit)="saveReportCategory()" [formGroup]="categoryForm">
            <div class="form-row">
                <div class="col-12  form-group">
                    <label for="name" translate>Name</label>
                    <input class="form-control" formControlName="name" id="name" type="text">
                    <val-errors controlName="name">
                        <ng-template valError="required">
                            <span translate>Name is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12">
                    <button [disabled]="categoryForm.invalid" class="btn btn-success btn-block" type="submit">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
