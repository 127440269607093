import {Component, OnInit} from '@angular/core';
import {Ticket} from '@models/ticket';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@app/services/authentication.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit {
  public replyForm: FormGroup;
  public documentForm: FormGroup;
  id: string;
  ticket: Ticket;
  busy: Subscription;

  constructor(
    private route: ActivatedRoute,
    private autenthicationService: AuthenticationService,
    public modalService: NgbModal,
    private dataService: DataService) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

    this.replyForm = new FormGroup({
      id_ticket: new FormControl(this.route.snapshot.paramMap.get('id'), [Validators.required]),
      risposta: new FormControl(null, [Validators.required]),
    });

    this.documentForm = new FormGroup({
      document: new FormControl(null, [Validators.required]),
    });

    this.refreshTicket();
  }

  get r() { return this.replyForm.controls; }

  refreshTicket() {
    this.busy = this.dataService
      .get(`/get-dettaglio-ticket?id=${this.id}`)
      .subscribe((data: any) => {
        this.ticket = data.result;
      });
  }

  markAsClosed() {
    this.busy = this.dataService.get(`/set-stato-ticket?id=${this.id}&stato=2`).subscribe((data) => {
      this.refreshTicket();
    });
  }

  markAsOpen() {
    this.busy = this.dataService.get(`/set-stato-ticket?id=${this.id}&stato=1`).subscribe((data) => {
      this.refreshTicket();
    });
  }

  addComment() {
    if (this.replyForm.invalid) {
      return;
    }

    this.busy = this.dataService.post('/inserisci-risposta-ticket', this.replyForm.value).subscribe((data) => {
      this.refreshTicket();
    });
  }
}
