import { Component, OnInit } from '@angular/core';
import { GiftCardService } from '@app/services/gift-card.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MetaPagination } from '@models/meta-pagination';
import { GiftCard } from '@app/models/gift-card';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication.service';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { User } from '@models/user';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.css'],
})
export class GiftCardComponent implements OnInit {
  giftCardList: GiftCard[] = [];
  ordersPagination: MetaPagination = new MetaPagination();
  isDealer = false;

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  filter = {
    id: '',
    company_name: '',
    dealer_id: '',
    created_at: '',
    date: '',
  };
  apiDate = {
    from: '',
    to: '',
  };

  constructor(
    private GiftCardService: GiftCardService,
    private authenticationService: AuthenticationService,
    private dateRangePickerOptions: DaterangepickerConfig,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadList();
    setTimeout(() => {});
  }

  loadList(hideSpinner = false) {
    this.spinner.show('table-orders');
    const params = {};

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.GiftCardService.getGiftCardList(params).subscribe(
      (response: any) => {
        this.giftCardList = new GiftCard().fromArray(response.data);
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  exsportGiftCardList() {
    const params = {
      'per-page': 0,
    };

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.GiftCardService.getGiftCardList(params).subscribe((data) => {
      let rows = data.data;

      let output = rows.map((row) => {
        return {
          'Rag. Sociale': row.company_name,
          'Dealer ID': row.dealer_id,
          'ID Transazione': row.transaction_id,
          Brand: row.name,
          'Prezzo Dealer': row.dealer_price,
          'Prezzo Cliente': row.our_price,
          'Data Creazione': row.created_at,
          Stato: row.status,
        };
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-Gift-Card-list.xlsx');
    });
  }
}
