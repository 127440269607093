import { BaseModel } from "./base-model";

export const KENA_MOBILE = 'Kena Mobile';
export const KENA_MOBILE_STANDS = 'Kena Mobile Stands';
export const TISCALI_MOBILE = 'Tiscali Mobile';
export const ENI_GAS_E_LUCE = 'Eni gas e luce';
export const SKY = 'Sky';
export const TIM = 'Tim';
export const A2A = 'A2A';

export class Vendor extends BaseModel {
  id: number;
  name: string;

  get smallImage() {
    return `assets/img/small_brands/${this.name.toLowerCase()}.png`;
  }

  get image() {
    return `assets/img/brands/${this.name.toLowerCase()}.png`;
  }

  fromArray(result: []) {
    const array: Vendor[] = [];
    for (const item of result) {
      array.push(new Vendor(item));
    }
    return array;
  }
}
