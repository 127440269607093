import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import * as moment from 'moment';
import {EPayService} from '@services/e-pay.service';
import {ShopBrand} from '@models/e-pay/shop-brand';
import {PrintingList, PrintingListProvider, Status, Tickets} from '@models/kd-orders';
import {AuthenticationService} from '@services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ActivatedRoute} from '@angular/router';
import {KdOrderService} from '@services/kd-order.service';
import {User} from '@models/user';
import {Moment} from 'moment';

@Component({
    selector: 'app-printing-list',
    templateUrl: './printing-list.component.html',
    styleUrls: ['./printing-list.component.css']
})
export class PrintingListComponent implements OnInit {

    orders: PrintingList[] = [];
    ordersPagination: MetaPagination;

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        }
    };

    filter = {
        dealer_id: '',
        date: '',
        status: '',
        email: '',
        api_id: '',
        type: '',
        reference: ''
    };
    apiDate = {
        from: '',
        to: ''
    };
    localFormat = 'dd/MM/yyyy HH:MM';
    statuses = Status.list;
    currency = EPayService.currency;
    selectedData: PrintingList | any;

    @ViewChild('detailTemplate') private detailTemplate;

    constructor(
        private ePayService: EPayService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        private dateRangePickerOptions: DaterangepickerConfig,
        private printingListService: KdOrderService,
        public route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.loadOrders();
    }

    loadOrders() {
        this.spinner.show('table-orders');
        const params = {};
        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.date) {
            params['from'] = this.apiDate.from;
            params['to'] = this.apiDate.to;
        }
        if (this.filter.email) {
            params['email'] = this.filter.email;
        }
        if (this.filter.api_id) {
            params['api_id'] = this.filter.api_id;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        if (this.filter.reference) {
            params['reference'] = this.filter.reference;
        }

        this.printingListService.getPrint(params).subscribe((response: PrintingListProvider) => {
            this.orders = response.data;
            this.ordersPagination = response._meta;
            this.spinner.hide('table-orders');
        }, err => {
            this.spinner.hide('table-orders');
        });
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.date = startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }

    showDetailModal(data?: PrintingList) {
        this.selectedData = data;
        this.modalService.open(this.detailTemplate, {size: 'lg', scrollable: true});
    }
}
