import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';

const version = 'v3';

@Injectable({
  providedIn: 'root'
})
export class FlixbusService {

  constructor(private request: RequestService, public alertService: AlertService) { }

  public getFlixbusList(params?): Observable <any> {
    return this.request.get(version + '/flixbus/list', params);
  }
}
