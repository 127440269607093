import {Component, OnInit} from '@angular/core';
import {DealerService} from '@services/dealer.service';
import {ToastrService} from 'ngx-toastr';
import Handsontable from 'handsontable';

@Component({
    selector: 'app-bulk-assign-dealer',
    templateUrl: './bulk-assign-dealer.component.html',
    styleUrls: ['./bulk-assign-dealer.component.css']
})
export class BulkAssignDealerComponent implements OnInit {

    vendor = null;
    agent = null;

    rows = [
        {
            id: null,
            ragsociale: null,
            old_keyaccount: null,
            new_keyaccount: null
        }
    ];
    cols: [];

    settings: Handsontable.GridSettings = {
        afterChange: (changes) => this.update(changes)
    };

    previewLoaded = false;
    previewLoading = false;
    saveLoading = false;

    constructor(
        private dealerService: DealerService,
        private toastService: ToastrService
    ) {
    }

    get valid() {
        return this.vendor && this.agent;
    }

    ngOnInit(): void {
        this.resetRows();
    }

    resetRows() {
        this.rows = this.rows.map((row) => {
            row.ragsociale = null;
            row.old_keyaccount = null;
            row.new_keyaccount = null;
            return row;
        })
        this.previewLoaded = false;
    }

    setVendor($event: any) {
        this.vendor = $event;
        this.resetRows();
    }

    setAgent($event: any) {
        this.agent = $event;
        this.resetRows();
    }

    update(changes: Handsontable.CellChange[]) {
        console.log(changes);
        this.previewLoaded = false;
    }

    previewResult() {
        const invalidRows = this.rows.filter(row => {
            return isNaN(row.id) || row.id === null;
        });
        if(invalidRows.length > 0) {
            this.toastService.error('In lista c\'è qualche id vuoto', 'Errore');
            return false;
        }

        const payload = new FormData();
        payload.set('rows', JSON.stringify(this.rows.map(row => row.id)));
        payload.set('agent', this.agent);
        payload.set('vendor', this.vendor);

        this.previewLoading = true;

        this.dealerService.bulkAssignPreview(payload)
            .subscribe((data) => {
                this.rows = data;
                setTimeout(() => {
                    this.previewLoaded = true;
                    this.previewLoading = false;
                }, 2000);

                this.toastService.warning('Controlla bene', 'Controlla bene FR');
            }, (error) => {
                this.toastService.error(error, 'Errore Anteprima');
                this.previewLoading = false;
            });
    }

    saveResult() {
        const payload = new FormData();
        payload.set('rows', JSON.stringify(this.rows.map(row => row.id)));
        payload.set('agent', this.agent);
        payload.set('vendor', this.vendor);
        payload.set('save', 'true');

        this.saveLoading = true;

        this.dealerService.bulkAssignPreview(payload)
            .subscribe((data) => {
                this.rows = data;
                this.previewLoaded = true;
                this.saveLoading = false;
                this.toastService.success('Assegnazione massiva completata', 'Grande fr');
            }, (error) => {
                this.toastService.error(error, 'Errore Anteprima');
                this.saveLoading = false;
            });
    }
}
