<ng-select
  [ngClass]="{'form-control-sm': small}"
  [items]="data"
  bindValue="key"
  bindLabel="value"
  [(ngModel)]="model"
  (ngModelChange)="onChange.emit($event)"
  [loading]="loading"
  loadingText="Caricamento..."
></ng-select>
