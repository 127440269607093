import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@services/authentication.service';

@Component({
    selector: 'app-documents-modal',
    templateUrl: './documents-modal.component.html',
    styleUrls: ['./documents-modal.component.scss'],
})
export class DocumentsModalComponent implements OnInit {
    @Input()
    image = false;
    document: string;
    @ViewChild('documentModal') private documentModal;

    constructor(private modalService: NgbModal, private authService: AuthenticationService) {}

    ngOnInit() {}

    public showDocument(id: string, requireAuth = false) {
        // this.document = `${this.globals.apiUrl}/files/${id}`;

        if (requireAuth) {
            id = id + '&access_token=' + this.authService.currentUserToken;
        }

        this.document = id;
        this.modalService.open(this.documentModal, { size: 'lg' }).result.then((result) => {});
    }
}
