import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MetaPagination} from '@models/meta-pagination';
import {ShopBrand, ShopBrandPagination} from '@models/e-pay/shop-brand';
import {NgxSpinnerService} from 'ngx-spinner';
import {EPayService} from '@services/e-pay.service';
import {ConfirmOptionsService} from '@services/confirm-options.service';

@Component({
    selector: 'app-brands',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {
    brandModal: NgbModalRef;
    @ViewChild('brandFormTemplate') private brandFormTemplate;
    brands: ShopBrand[] = [];
    brandsPagination: MetaPagination;
    selectedBrand: ShopBrand;
    deleteBrandOptions: ConfirmOptionsService;
    filter = {
        name: ''
    };

    constructor(
        public modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private ePayService: EPayService) {
        this.deleteBrandOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }


    ngOnInit(): void {
        this.loadBrands();
    }

    showModal(brand?: ShopBrand) {
        this.selectedBrand = brand;
        this.brandModal = this.modalService.open(this.brandFormTemplate, {size: 'lg'});
        this.brandModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    loadBrands() {
        this.spinner.show('table-brands');
        const params = {};
        if (this.brandsPagination?.currentPage) {
            params['page'] = this.brandsPagination?.currentPage;
        }
        if (this.filter.name) {
            params['name'] = this.filter.name;
        }
        this.ePayService.getBrands(params).subscribe((response: ShopBrandPagination) => {
            this.brands = response.data;
            this.brandsPagination = response._meta;
            this.spinner.hide('table-brands');
        }, err => {
            this.spinner.hide('table-brands');
        });
    }

    changeStatus(brand: ShopBrand) {
        brand.is_active = brand.is_active ? '1' : '0';
        this.ePayService.changeBrandStatus(brand.id, brand).subscribe((response: ShopBrand) => {
            brand.is_active = Number(response.is_active);
            this.ePayService.successAlert('Changed Status!');
        }, err => {
            this.ePayService.errorAlert(err.message);
        });
    }

    deleteBrand(id: number) {
        this.ePayService.deleteBrand(id).subscribe(() => {
            const index = this.brands.findIndex(brand => brand.id === id);
            if (index > -1) {
                this.brands.splice(index, 1);
                this.ePayService.successAlert('Deleted Brand!');
            }
        }, (err) => {
            this.ePayService.errorAlert(err.message);
        });
    }
}
