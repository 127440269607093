import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KdTaxService {
  constructor(private request: RequestService, public alertService: AlertService) {}

  public getAdvices(params?,): Observable<any> {
    return this.request.get('v3/application/list', params);
  }

  public successAlert(text) {
    this.alertService.show('', 'Successfully ' + text, {
      classname: 'bg-success text-light',
      delay: 4000,
    });
  }

  public errorAlert(message?) {
    this.alertService.show('An error occurred!', message ? message : 'Try again!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }
}
