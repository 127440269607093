<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadOrders()">
        <div class="form-row">
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                    id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Dealer ID</label>
                    <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Fiscal code</label>
                    <input [(ngModel)]="filter.fiscal_code" name="fiscal_code" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Token</label>
                    <input [(ngModel)]="filter.token" name="token" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date Range</label>
                    <input id="documentDate" type="text" daterangepicker [options]="pickerOptions"
                        [(ngModel)]="filter.date" name="date" class="form-control"
                        (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Tabella -->

<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>SPID IR</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
        <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th translate>Company name</th>
                    <th translate>Dealer ID</th>
                    <th translate>Fiscal code</th>
                    <th translate>Token</th>
                    <th translate>Booked Url</th>
                    <th translate>Date</th>
                </tr>
            </thead>
            <tbody class="bg-gradiend-white">
            <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="orders"
                [fullScreen]="false"
            >
            <p style="color: white">Caricamento...</p>
            </ngx-spinner>
                <tr *ngIf="bookingOrders.length==0">
                    <td translate colspan="6" class="text-center">List is empty</td>
                </tr>
                <tr *ngFor="let bookingOrder of bookingOrders">
                    <td>
                        <a [routerLink]="['/dealer', bookingOrder.dealer_id]" target="_blank">{{bookingOrder.company_name}}</a>
                    </td>
                    <td>
                      {{bookingOrder?.dealer_id}}
                    </td>
                    <td>
                      {{bookingOrder?.fiscal_code}}
                    </td>
                    <td>
                      {{bookingOrder?.token}}
                    </td>
                    <td>
                       <a href="{{bookingOrder?.booked_url}}" target="_blank">link infocert</a>
                    </td>
                    <td>
                        {{bookingOrder?.created_date | amFromUnix | amDateFormat: 'HH:mm DD/MM/YYYY' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="bookingOrders?.length>0">
        <span><span translate>There are</span><b>{{ this.ordersPagination.totalCount }}</b> {{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}. <a class="link">Esporta la lista completa</a>
    </span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadOrders()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>