<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadOrders()">
    <div class="form-row">
      <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="companyName">Company name</label>
          <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="companyName"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Dealer ID</label>
          <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control" />
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Status</label>
          <ng-select
            [clearable]="true"
            class="disableClear"
            [(ngModel)]="filter.status"
            [items]="statuses"
            bindLabel="label"
            name="subscribed"
            id="subscribed"
            bindValue="key"
            [searchable]="true"
          ></ng-select>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Date</label>
          <input
            type="text"
            daterangepicker
            [options]="pickerOptions"
            [(ngModel)]="filter.date"
            name="date"
            class="form-control"
            autocomplete="off"
            (selected)="generateDateRangeString($event.start, $event.end)"
          />
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <button class="btn btn-info btn-block" translate type="submit">Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="card">
  <div class="card-header">
    <h4 class="mb-0" translate>OLIVETTI</h4>
  </div>
  <div class="card-body p-0 rounted-bottom table-responsive">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-orders" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th translate>Espandi</th>
          <th translate>Dealer Id</th>
          <th translate>Dealer</th>
          <th translate>Modello Richiesto</th>
          <th translate>Interesse</th>
          <th translate>Note</th>
          <th translate>Date</th>
          <th translate class="text-center">Status</th>
        </tr>
      </thead>
      <tbody class="bg-gradiend-white">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders" [fullScreen]="false">
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngIf="olivettiOrders.length == 0">
          <td translate colspan="8" class="text-center">List is empty</td>
        </tr>
        <ng-container *ngFor="let item of olivettiOrders">
          <tr>
            <td>
              <button class="btn btn-outline-info btn-sm text-center" type="button" (click)="item.show = !item.show" role="button" aria-expanded="false" translate>
                <i class="fas fa-plus mr-0" *ngIf="!item.show"></i>
                <i class="fas fa-minus mr-0" *ngIf="item.show"></i>
              </button>
            </td>
            <td>
              {{ item?.dealer_id }}
            </td>
            <td>
              <a [routerLink]="['/dealer', item.dealer_id]" target="_blank">{{ item.dealer }}</a>
            </td>
            <td>
              {{ item.printer_model }}
            </td>
            <td>
              {{ item.interest }}
            </td>
            <td *ngIf="item?.notes">
              <button
                href="javascript:void(0)"
                class="btn px-0 bg-transparent text-left"
                (click)="openPopover(statusPopover, item?.id, item?.notes, 'notes')"
                #statusPopover="ngbPopover"
                autoClose="outside"
                triggers="manual"
                [ngbPopover]="popContentNotes"
                popoverTitle="Modifica nota"
              >
                <span class="d-inline-block text-truncate" style="width: 200px">{{ item?.notes }}</span>
              </button>
            </td>
            <td *ngIf="item?.notes == '' || item?.notes == null">
              <button
                href="javascript:void(0)"
                class="btn text-primary px-0 bg-transparent"
                (click)="openPopover(statusPopover, item?.id, item?.notes, 'notes')"
                #statusPopover="ngbPopover"
                autoClose="outside"
                triggers="manual"
                [ngbPopover]="popContentNotes"
                popoverTitle="Scrivi una nota"
              >
                Aggiungi una nota >
              </button>
            </td>
            <td>
              {{ item?.created_at | amDateFormat : 'HH:mm - DD/MM/YYYY' }}
            </td>

            <td>
              <button
                href="javascript:void(0)"
                class="btn btn-sm text-uppercase btn-block"
                (click)="openPopover(plafondStatusPopover, item?.id, item?.status, 'status', statuses)"
                #plafondStatusPopover="ngbPopover"
                autoClose="outside"
                triggers="manual"
                [ngbPopover]="popContent"
                popoverTitle="Cambia valore"
                [ngClass]="{
                  'btn-info': item.status === 'pending',
                  'btn-success': item.status === 'confirmed',
                  'btn-warning': item.status === 'canceled',
                  'btn-danger': item.status === 'ko',
                  'btn-primary': item.status === 'handled'
                }"
              >
                {{ item?.orderStatus }}
              </button>
            </td>
          </tr>

          <!-- Se è show -->
          <ng-container *ngIf="item.show && item.formType === 'azienda'">
            <tr>
              <td colspan="12" class="p-0">
                <table class="table mb-0">
                  <thead class="">
                    <!-- header -->
                    <td colspan="12" class="bg-dark">
                      <p class="text-center text-sm text-uppercase">Dati Cliente (Azienda)</p>
                    </td>
                    <tr class="bg-white">
                      <th scope="col">Ragione Sociale</th>
                      <th scope="col">Partita iva</th>
                      <th scope="col">Nome Referente</th>
                      <th scope="col">Cognome Referente</th>
                      <th scope="col">Comune</th>
                      <th scope="col">Provincia</th>
                      <th scope="col">Email</th>
                      <th scope="col">Telefono</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white">
                      <td>
                        <p>{{ item.company_name }}</p>
                      </td>
                      <td>
                        <p>{{ item.vat }}</p>
                      </td>
                      <td>
                        <p>{{ item.name }}</p>
                      </td>
                      <td>
                        <p>{{ item.surname }}</p>
                      </td>
                      <td>
                        <p>{{ item.city }}</p>
                      </td>
                      <td>
                        <p>{{ item.province }}</p>
                      </td>
                      <td>
                        <p>{{ item.email }}</p>
                      </td>
                      <td>
                        <p>{{ item.phone }}</p>
                      </td>
                    </tr>
                  </tbody>
                  <!-- header -->
                </table>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="item.show && item.formType === 'privato'">
            <tr>
              <td colspan="12" class="p-0">
                <table class="table mb-0">
                  <thead class="">
                    <!-- header -->
                    <td colspan="12" class="bg-dark">
                      <p class="text-center text-sm text-uppercase">Dati Cliente (Privato)</p>
                    </td>
                    <tr class="bg-white">
                      <th scope="col">Nome</th>
                      <th scope="col">Cognome</th>
                      <th scope="col">Comune</th>
                      <th scope="col">Provincia</th>
                      <th scope="col">Email</th>
                      <th scope="col">Telefono</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white">
                      <td>
                        <p>{{ item.name }}</p>
                      </td>
                      <td>
                        <p>{{ item.surname }}</p>
                      </td>
                      <td>
                        <p>{{ item.city }}</p>
                      </td>
                      <td>
                        <p>{{ item.province }}</p>
                      </td>
                      <td>
                        <p>{{ item.email }}</p>
                      </td>
                      <td>
                        <p>{{ item.phone }}</p>
                      </td>
                    </tr>
                  </tbody>
                  <!-- header -->
                </table>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <span
      ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b
      >{{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.<a class="link" (click)="exportOlivetti()"> Esporta la lista completa</a></span
    >
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      (pageChange)="loadOrders()"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>

<ng-template #popContent>
  <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
<ng-template #popContentNotes>
  <app-text-popup [config]="popupConfig" (onSave)="save($event)"></app-text-popup>
</ng-template>
