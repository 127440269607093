<div class="card bg-gradient-dark">
  <div class="card-body">
    <div class="d-flex align-items-lg-center">
      <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
           width="160px"
           src="assets/img/flats/shopping-cart.png">
      <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Lista Negozi</h1>
        <hr>
        <div class="row " *show="['operator','master', 'masteragent', 'submaster' ]">
          <div class="col-3">
            <div class="form-group">
              <label translate>Liste Partner</label>
              <ng-select
                [clearable]="true"
                class="disableClear"
                name="subscribed"
                id="subscribed"
                [(ngModel)]="selectedListaItem"
                [searchable]="true"
                placeholder="Filtra per lista..."
                (change)="filterList()"
              >
                <ng-option *ngFor="let item of shopSelectItem" [value]="item.id">
                  {{ item.label }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-3">
            <!--                        <div *ngIf="showKeyAccountSelect">-->
            <!--                          <label>Key Account</label>-->
            <!--                          <app-agent-select-->
            <!--                            (onChange)="onAgentChange($event)"-->
            <!--                            id="agent"-->
            <!--                          ></app-agent-select>-->
            <!--                        </div>-->
          </div>
          <div class="col-6 text-right mt-auto form-group" *show="['operator']">
            <button class="btn btn-info" (click)="showModal()">
              Importa Lista
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs" (navChange)="toggleKeyAccountSelect($event)">

  <li ngbNavItem>
    <a ngbNavLink translate>{{ 'Negozi' }}</a>
    <ng-template ngbNavContent>
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0" translate>Liste Negozi</h4>
        </div>
        <div class="card-body p-0 rounded-bottom table-responsive">
          <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
          >
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th style="width: 5%;" translate>ID</th>
              <th style="width: 20%;" translate>Nome Lista</th>
              <th style="width: 10%;" translate>Totale Contatti</th>
              <th style="width: 10%;" translate>Programmati</th>
              <th style="width: 10%;" translate>Visitati</th>
              <th style="width: 10%;" translate>Affiliati</th>
              <th style="width: 15%;" translate>Date</th>
              <th style="width: 15%;" class="text-center">Lista</th>
              <th *show="['operator', 'master']" class="text-center">Esporta</th>
              <th *show="['operator', 'master']" class="text-center">Elimina</th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
              bdColor="rgba(0, 0, 0, 0.8)"
              size="default"
              color="#fff"
              type="square-jelly-box"
              name="orders"
              [fullScreen]="false"
            >
              <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let list of shopList">
              <td>
                {{ list.id }}
              </td>
              <td>
                {{ list.name_list }}
              </td>
              <td>
                {{ list.count_list }}
              </td>
              <td>
                {{ list.visit_schedule }}
              </td>
              <td>
                {{ list.visited }}
              </td>
              <td>
                {{ list.affiliated }}
              </td>
              <td>
                {{ list?.created_at | date:'dd/MM/yyyy HH:MM:ss' }}
              </td>
              <td class="text-center">
                <button class="btn btn-link"
                        (click)="watchDetail(list);"
                >
                  <i class=" fa fa-eye text-info"></i>
                </button>
              </td>
              <td class="text-center" *show="['operator', 'master']">
                <button class="btn btn-link"
                        (click)="exportReportList(list.id);"
                >
                  <i class="fa fa-download text-primary"></i>
                </button>
              </td>
              <td class="text-center" *show="['operator', 'master']">
                <button type="button" class="btn bg-transparent"
                        [swal]="deleteSubscriptionOptions" *ngIf="isEdit"
                        (confirm)="deleteList(list.id)">
                  <i class="fas fa-times mr-0 text-danger"></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="shopList?.length<=0">
              <td class="text-muted text-center" colspan="9" translate>List is empty</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer" *ngIf="shopList?.length>0">
            <span><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
              {{ this.ordersPagination.totalCount <= 1 ? 'Lista' : 'Liste'|translate }}.
                <a *show="['master', 'operator']" class="link" (click)="exportReportList()"
                >Esporta la lista completa</a
                >
            </span>
          <ngb-pagination *ngIf="ordersPagination.pageCount>1"
                          size="sm"
                          [pageSize]="ordersPagination.perPage"
                          [maxSize]="10"
                          [(page)]="ordersPagination.currentPage"
                          [collectionSize]="ordersPagination.totalCount"
                          (pageChange)="loadList()"
                          [boundaryLinks]="true"
          ></ngb-pagination>
        </div>
      </div>
    </ng-template>
  </li>

  <li ngbNavItem *show="['operator','master','submaster']">
    <a ngbNavLink translate>{{ 'Area Manager' }}</a>
    <ng-template ngbNavContent>
      <div class="card" *ngIf="classificaAm">
        <div class="card-header">
          <h4 class="mb-0" translate>{{ selectedListaItem ? selectedListaItem.label : 'Nome Lista' }}</h4>
        </div>
        <div class="card-body p-0 rounded-bottom table-responsive">
          <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
          >
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th style="width: 10%;" translate>N°</th>
              <th style="width: 30%;" translate>Area Manager</th>
              <th style="width: 15%; text-align: right">
                <span translate>Tot. Assegnati</span>
                <i class="fa cursor-hand ml-2"
                   [class.fa-sort]="sortColumn !== 'totale_assegnati'"
                   [class.fa-sort-amount-up]="sortColumn === 'totale_assegnati' && sortDirection === 'asc'"
                   [class.fa-sort-amount-down]="sortColumn === 'totale_assegnati' && sortDirection === 'desc'"
                   [class.text-success]="sortColumn === 'totale_assegnati' && sortDirection === 'asc'"
                   [class.text-danger]="sortColumn === 'totale_assegnati' && sortDirection === 'desc'"
                   (click)="sortClassificaAm('totale_assegnati')">
                </i>
              </th>

              <th style="width: 15%; text-align: right">
                <span translate>Tot. Programmati</span>
                <i class="fa cursor-hand ml-2"
                   [class.fa-sort]="sortColumn !== 'visite_programmate_totali'"
                   [class.fa-sort-amount-up]="sortColumn === 'visite_programmate_totali' && sortDirection === 'asc'"
                   [class.fa-sort-amount-down]="sortColumn === 'visite_programmate_totali' && sortDirection === 'desc'"
                   [class.text-success]="sortColumn === 'visite_programmate_totali' && sortDirection === 'asc'"
                   [class.text-danger]="sortColumn === 'visite_programmate_totali' && sortDirection === 'desc'"
                   (click)="sortClassificaAm('visite_programmate_totali')">
                </i>
              </th>

              <th style="width: 15%; text-align: right">
                <span translate>Tot. Visitati</span>
                <i class="fa cursor-hand ml-2"
                   [class.fa-sort]="sortColumn !== 'visite_totali'"
                   [class.fa-sort-amount-up]="sortColumn === 'visite_totali' && sortDirection === 'asc'"
                   [class.fa-sort-amount-down]="sortColumn === 'visite_totali' && sortDirection === 'desc'"
                   [class.text-success]="sortColumn === 'visite_totali' && sortDirection === 'asc'"
                   [class.text-danger]="sortColumn === 'visite_totali' && sortDirection === 'desc'"
                   (click)="sortClassificaAm('visite_totali')">
                </i>
              </th>

              <th style="width: 15%; text-align: right">
                <span translate>Tot. Affiliati</span>
                <i class="fa cursor-hand ml-2"
                   [class.fa-sort]="sortColumn !== 'affiliati_totali'"
                   [class.fa-sort-amount-up]="sortColumn === 'affiliati_totali' && sortDirection === 'asc'"
                   [class.fa-sort-amount-down]="sortColumn === 'affiliati_totali' && sortDirection === 'desc'"
                   [class.text-success]="sortColumn === 'affiliati_totali' && sortDirection === 'asc'"
                   [class.text-danger]="sortColumn === 'affiliati_totali' && sortDirection === 'desc'"
                   (click)="sortClassificaAm('affiliati_totali')">
                </i>
              </th>

            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
              bdColor="rgba(0, 0, 0, 0.8)"
              size="default"
              color="#fff"
              type="square-jelly-box"
              name="orders"
              [fullScreen]="false"
            >
              <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let master of classificaAm.result  let i = index">
              <td>
                {{ i + 1 }}
              </td>
              <td>
                {{ master.areamanager }}
              </td>
              <td class="text-right">
                {{ master.totale_assegnati }}
              </td>
              <td class="text-right">
                {{ master.visite_programmate_totali }}
              </td>
              <td class="text-right">
                {{ master.visite_totali }}
              </td>
              <td class="text-right">
                {{ master.affiliati_totali }}
              </td>
            </tr>
            <tr *ngIf="classificaAm?.result?.length<=0">
              <td class="text-muted text-center py-5" colspan="6" translate>Per <strong>visualizzare i dati</strong>
                seleziona prima una lista
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer" *ngIf="classificaAm?.result?.length>0">
          <p class="text-muted">Info aggiuntive</p>
        </div>
      </div>
    </ng-template>
  </li>


  <li ngbNavItem id="key-account-tab" *show="['operator','masteragent','master','submaster']">
    <a ngbNavLink translate>{{ 'Key Account' }}</a>
    <ng-template ngbNavContent>
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0" translate>{{ classificaKa?.name_list }}</h4>
        </div>
        <div class="card-body p-0 rounded-bottom table-responsive">
          <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
          >
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th style="width: 10%;" translate>ID</th>
              <th style="width: 30%;" translate>Key Account</th>
              <th style="width: 15%; text-align: right">
                <span translate>Tot. Assegnati</span>
                <i class="fa cursor-hand ml-2"
                   [class.fa-sort]="sortColumn !== 'totale_assegnati'"
                   [class.fa-sort-amount-up]="sortColumn === 'totale_assegnati' && sortDirection === 'asc'"
                   [class.fa-sort-amount-down]="sortColumn === 'totale_assegnati' && sortDirection === 'desc'"
                   [class.text-success]="sortColumn === 'totale_assegnati' && sortDirection === 'asc'"
                   [class.text-danger]="sortColumn === 'totale_assegnati' && sortDirection === 'desc'"
                   (click)="sortClassificaKa('totale_assegnati')">
                </i>
              </th>

              <th style="width: 15%; text-align: right">
                <span translate>Tot. Programmati</span>
                <i class="fa cursor-hand ml-2"
                   [class.fa-sort]="sortColumn !== 'visite_programmate_totali'"
                   [class.fa-sort-amount-up]="sortColumn === 'visite_programmate_totali' && sortDirection === 'asc'"
                   [class.fa-sort-amount-down]="sortColumn === 'visite_programmate_totali' && sortDirection === 'desc'"
                   [class.text-success]="sortColumn === 'visite_programmate_totali' && sortDirection === 'asc'"
                   [class.text-danger]="sortColumn === 'visite_programmate_totali' && sortDirection === 'desc'"
                   (click)="sortClassificaKa('visite_programmate_totali')">
                </i>
              </th>

              <th style="width: 15%; text-align: right">
                <span translate>Tot. Visitati</span>
                <i class="fa cursor-hand ml-2"
                   [class.fa-sort]="sortColumn !== 'visite_totali'"
                   [class.fa-sort-amount-up]="sortColumn === 'visite_totali' && sortDirection === 'asc'"
                   [class.fa-sort-amount-down]="sortColumn === 'visite_totali' && sortDirection === 'desc'"
                   [class.text-success]="sortColumn === 'visite_totali' && sortDirection === 'asc'"
                   [class.text-danger]="sortColumn === 'visite_totali' && sortDirection === 'desc'"
                   (click)="sortClassificaKa('visite_totali')">
                </i>
              </th>

              <th style="width: 15%; text-align: right">
                <span translate>Tot. Affiliati</span>
                <i class="fa cursor-hand ml-2"
                   [class.fa-sort]="sortColumn !== 'affiliati_totali'"
                   [class.fa-sort-amount-up]="sortColumn === 'affiliati_totali' && sortDirection === 'asc'"
                   [class.fa-sort-amount-down]="sortColumn === 'affiliati_totali' && sortDirection === 'desc'"
                   [class.text-success]="sortColumn === 'affiliati_totali' && sortDirection === 'asc'"
                   [class.text-danger]="sortColumn === 'affiliati_totali' && sortDirection === 'desc'"
                   (click)="sortClassificaKa('affiliati_totali')">
                </i>
              </th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
              bdColor="rgba(0, 0, 0, 0.8)"
              size="default"
              color="#fff"
              type="square-jelly-box"
              name="orders"
              [fullScreen]="false"
            >
              <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let agent of classificaKa.result  let i = index">
              <td>
                {{ i + 1 }}
              </td>
              <td>
                {{ agent.keyaccount }}
              </td>
              <td class="text-right">
                {{ agent.totale_assegnati }}
              </td>
              <td class="text-right">
                {{ agent.visite_programmate_totali }}
              </td>
              <td class="text-right">
                {{ agent.visite_totali }}
              </td>
              <td class="text-right">
                {{ agent.affiliati_totali }}
              </td>
            </tr>
            <tr *ngIf="classificaKa?.result?.length<=0">
              <td class="text-muted text-center py-5" colspan="6" translate>Per <strong>visualizzare i dati</strong>
                seleziona prima una lista
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer" *ngIf="classificaKa?.result?.length>0">
          <p class="text-muted">*info aggiuntive</p>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>


<!-- MODALE -->

<ng-template #shopListUploadTemplate let-modal>
  <div class="modal-header bg-gradient-dark">
    <h4 class="modal-title clamp-title" translate>{{ 'Importa Lista' }}</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="save()" [formGroup]="shopListUpload">
      <div class="mt-2 mb-0">
        <div class="form-group">
          <label class="lead mb-1">Nome lista</label>
          <input
            formControlName="name_list"
            id="nameList"
            type="text"
            class="form-control"
          >
          <val-errors controlName="name_list">
            <ng-template valError="required">
              Il nome della lista è obbligatorio
            </ng-template>
          </val-errors>
        </div>

        <div class="form-group">
          <label class="lead mb-1">Descrizione</label>
          <textarea
            formControlName="description"
            id="description"
            class="form-control"
          ></textarea>
        </div>
        <div class="form-group">
          <div class="custom-file">
            <input
              (change)="onFileSelected($event)"
              class="custom-file-input cursor-hand"
              formControlName="fileInput"
              id="validatedCustomFile"
              type="file"
              class="custom-file-input" id="validatedCustomFile"
            >
            <label
              *ngIf="file"
              class="custom-file-label text-green"
            >
              {{ file.name }}
            </label>
            <label
              *ngIf="!file"
              class="custom-file-label"
              for="validatedCustomFile"
            >Scegli il file
            </label>
          </div>
          <val-errors controlName="file">
            <ng-template valError="required">
              Il file è obbligatorio
            </ng-template>
          </val-errors>
        </div>
        <button [disabled]="loadingFile === true" class="btn btn-secondary btn-block mb-2" type="submit">
          <i class="fa fa-check-circle mr-2"></i> <span translate>Importa</span>
        </button>
        <div class="alert alert-info">
          <h2 class="lead mb-1">Linee Guida</h2>
          <p>Prima di importare il file, assicurati di che ci siano le seguenti colonne obbligatorie:</p>
          <ul>
            <li>company: Ragione Sociale</li>
            <li>city: Città</li>
            <li>province: Provincia</li>
            <li>region: Regione</li>
            <li>address: Indirizzo</li>
          </ul>

          <p>Inoltre, puoi aggiungere le seguenti colonne opzionali:</p>
          <ul>
            <li>name: Nome</li>
            <li>surname: Cognome</li>
            <li>fiscal_code: Codice Fiscale</li>
            <li>vat_number: Partita IVA</li>
            <li>zip: CAP</li>
            <li>email: Email</li>
            <li>phone: Telefono</li>
            <li>agent_id: ID Agente</li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!-- FINE MODALE -->
