<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadList()">
        <div class="form-row">
            <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"></app-retailer-typeahead>
                </div>
            </div>

            <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">Status</label>
                    <ng-select
                        [clearable]="true"
                        class="disableClear"
                        [(ngModel)]="filter.status"
                        [items]="kdAssicuraStatuses"
                        bindLabel="label"
                        name="status"
                        id="status"
                        bindValue="key"
                        [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date</label>
                    <input
                        id="documentDate"
                        autocomplete="off"
                        type="text"
                        daterangepicker
                        [options]="pickerOptions"
                        [(ngModel)]="filter.created_at"
                        name="date"
                        class="form-control"
                        (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Tabella -->

<div class="card">
    <div class="card-header">
        <h4 class="mb-0">KD ASSICURA</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th>Espandi</th>
                    <th class="text-center">#</th>
                    <th>Dealer</th>
                    <th>Tipo Polizza</th>
                    <th>Status</th>
                    <th class="text-center">Data Creazione</th>
                </tr>
            </thead>
            <tbody class="bg-gradient-white">
                <tr *ngIf="list?.length == 0">
                    <td translate colspan="9" class="text-center">List is empty</td>
                </tr>
                <ng-container *ngFor="let item of list">
                    <tr>
                        <td>
                            <button
                                class="btn btn-outline-info btn-sm text-center"
                                type="button"
                                (click)="item.show = !item.show"
                                role="button"
                                aria-expanded="false"
                                translate
                            >
                                <i class="fas fa-plus mr-0" *ngIf="!item.show"></i>
                                <i class="fas fa-minus mr-0" *ngIf="item.show"></i>
                            </button>
                        </td>
                        <td class="text-center">{{ item.id }}</td>
                        <td>
                            <a [routerLink]="['/dealer', item.dealer_id]" target="_blank">{{ item.ragsociale }}</a>
                        </td>
                        <td>
                            <div class="d-flex justify-content-start align-items-center w-100 h-100">
                                <i [class]="getIcon(item.data.type)" style="margin-right: 5px"></i>
                                <p>{{ getType(item.data.type) }}</p>
                            </div>
                        </td>
                        <td>
                            <button
                                href="javascript:void(0)"
                                class="btn btn-sm text-uppercase btn-block"
                                (click)="
                                    openPopover(assicuraPopover, item?.id, item?.status, 'status', kdAssicuraStatuses)
                                "
                                #assicuraPopover="ngbPopover"
                                autoClose="outside"
                                triggers="manual"
                                [ngbPopover]="popContent"
                                popoverTitle="Cambia valore"
                                [ngClass]="{
                                    'btn-warning': item.status === 'pending',
                                    'btn-success': item.status === 'completed',
                                    'btn-info': item.status === 'sent',
                                    'btn-danger': item.status === 'failed'
                                }"
                            >
                                {{ getStatus(item?.status) }}
                            </button>
                        </td>
                        <td class="text-center">
                            {{ item?.created_at | amDateFormat : 'DD/MM/YYYY' }}
                        </td>
                    </tr>
                    <tr *ngIf="item.show">
                        <ng-container *ngIf="item.data.type == 'pets'">
                            <td colspan="12" class="p-0 border border-secondary">
                                <table class="table mb-0">
                                    <thead>
                                        <td colspan="12" class="bg-secondary">
                                            <p class="text-center text-sm text-uppercase">Dettagli</p>
                                        </td>
                                        <tr style="background: #eee">
                                            <th scope="col">Nome Animale</th>
                                            <th scope="col">Data di Nascita</th>
                                            <th scope="col">Microchip</th>
                                            <th scope="col">Comune</th>
                                            <th scope="col">Cap</th>
                                            <th scope="col">Telefono Proprietario</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white">
                                            <td>
                                                <p>{{ item.data.name | uppercase }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.birth_date }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.microchip }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.comune }} - ({{ item.data.provincia }})</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.cap }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.phone }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="item.data.type == 'car' || item.data.type == 'motorcycle'">
                            <td colspan="12" class="p-0 border border-secondary">
                                <table class="table mb-0">
                                    <thead>
                                        <td colspan="12" class="bg-secondary">
                                            <p class="text-center text-sm text-uppercase">Dettagli</p>
                                        </td>
                                        <tr style="background: #eee">
                                            <th scope="col">Targa</th>
                                            <th scope="col">Data di Nascita</th>
                                            <th scope="col">Comune</th>
                                            <th scope="col">Cap</th>
                                            <th scope="col">Telefono</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white">
                                            <td>
                                                <p>{{ item.data.registration_plate | uppercase }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.birth_date }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.comune }} - ({{ item.data.provincia }})</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.cap }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.phone }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="item.data.type == 'buildings'">
                            <td colspan="12" class="p-0 border border-secondary">
                                <table class="table mb-0">
                                    <thead>
                                        <td colspan="12" class="bg-secondary">
                                            <p class="text-center text-sm text-uppercase">Dettagli</p>
                                        </td>
                                        <tr style="background: #eee">
                                            <th scope="col">Nome e Cognome</th>
                                            <th scope="col">Proprietario</th>
                                            <th scope="col">Utilizzo</th>
                                            <th scope="col">Indirizzo</th>
                                            <th scope="col">Comune</th>
                                            <th scope="col">Cap</th>
                                            <th scope="col">Nazione</th>
                                            <th scope="col">Telefono</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white">
                                            <td>
                                                <p>{{ item.data.name_surname }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.owner | uppercase }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.usage }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.address }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.comune }} - ({{ item.data.provincia }})</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.cap }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.nation | uppercase }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.phone }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="item.data.type == 'injuries'">
                            <td colspan="12" class="p-0 border border-secondary">
                                <table class="table mb-0">
                                    <thead>
                                        <td colspan="12" class="bg-secondary">
                                            <p class="text-center text-sm text-uppercase">Dettagli</p>
                                        </td>
                                        <tr style="background: #eee">
                                            <th scope="col">Nome e Cognome</th>
                                            <th scope="col">Professione</th>
                                            <th scope="col">Data di Nascita</th>
                                            <th scope="col">Comune</th>
                                            <th scope="col">Cap</th>
                                            <th scope="col">Telefono</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white">
                                            <td>
                                                <p>{{ item.data.name_surname }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.profession }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.birth_date }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.comune }} - ({{ item.data.provincia }})</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.cap }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.phone }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="item.data.type == 'bills'">
                            <td colspan="12" class="p-0 border border-secondary">
                                <table class="table mb-0">
                                    <thead>
                                        <td colspan="12" class="bg-secondary">
                                            <p class="text-center text-sm text-uppercase">Dettagli</p>
                                        </td>
                                        <tr style="background: #eee">
                                            <th scope="col">Nome e Cognome</th>
                                            <th scope="col">Telefono</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white">
                                            <td>
                                                <p>{{ item.data.name_surname }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.data.phone }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
        <span
            ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b
            >{{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.<a
                class="link"
                (click)="exportList()"
            >
                Esporta la lista completa</a
            ></span
        >
        <ngb-pagination
            *ngIf="ordersPagination.pageCount > 1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadList()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>

<ng-template #popContent>
    <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
