import {VisiteService} from '@services/visite.service';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {CalendarOptions} from '@fullcalendar/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '@services/authentication.service';
import {AgentVisitsService} from '@services/agent-visits.service';
import {environment} from '@environments/environment';
import {ViewportScroller} from '@angular/common';
import {MapTypeStyle} from '@agm/core/services/google-maps-types';
import {DataBuilderModalService} from '@services/data-builder-modal.service';
import {RequestService} from '@services/request.service';

@Component({
    selector: 'app-master-visite-detail',
    templateUrl: './master-visite-detail.component.html',
    styleUrls: ['./master-visite-detail.component.scss'],
})
export class MasterVisiteDetailComponent implements OnInit {
    busy: Subscription;
    id: any;
    visit_id = null;

    selectedDateRange = {
        from: '',
        to: '',
    };

    filterForm = {
        dateRange: '',
    };

    sales_agent = 'Caricamento...';
    global: any;
    kpi: any;
    provinces = [];
    cities_with_most_visits = [];
    timeline = [];
    retailersNeverVisited = [];
    resultsDetail = [];
    currentEvent;

    public baseAwsUrl = environment.baseUrl;

    defaultView = true;

    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        events: [],
        locale: 'it',
    };

    @ViewChild('timelineDiv') public timelineElement: ElementRef;

    defaultStyles: MapTypeStyle[] = [
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
                {visibility: 'off'}
            ]
        }
    ];

    mapPinOptions = {
        url: 'assets/img/flats/house_2.png',
        scaledSize: {
            width: 30,
            height: 30
        }
    };

    mapAgentPinOptions = {
        url: 'assets/img/flats/agent.png',
        scaledSize: {
            width: 30,
            height: 30
        }
    };
    laddaExcel = false;

    constructor(
        private auth: AuthenticationService,
        private service: VisiteService,
        public modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig,
        private dashboardService: DashboardService,
        private requestService: RequestService,
        private agentVisitsService: AgentVisitsService,
        public dbm: DataBuilderModalService,
        public route: ActivatedRoute,
        private scroller: ViewportScroller
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
        const visit_id = this.route.snapshot.paramMap.get('visit_id');

        if (visit_id) {
            this.visit_id = visit_id;
        }

        if (!this.id) {
            this.id = this.auth.currentUserValue.profile.id;
        }

        this.dateRangePickerOptions.settings.locale.format = 'DD/MM/YYYY';
        this.setDefaultRange();
    }

    ngOnInit() {
    }

    refresh() {
        this.busy = this.service
            .salesAgentOverview(
                this.selectedDateRange.from,
                this.selectedDateRange.to,
                this.id
            )
            .subscribe((data) => {
                this.global = data.overview;
                this.provinces = data.provinces;
                this.cities_with_most_visits = data.cities_with_most_visits;
                this.timeline = data.timeline;
                this.kpi = data.kpi;
                this.sales_agent = "Dettaglio Visite di " + data.sales_agent;

                if (this.visit_id) {
                    setTimeout(() => {
                        console.log(document.getElementById(this.visit_id));
                        document.getElementById(this.visit_id).scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'nearest'
                        });
                    }, 1000);

                }
            });

        this.spinner.show('calendar');
        this.service.salesAgentCalendar('2021-01', this.id).subscribe((data) => {
            this.calendarOptions.events = data;
            this.spinner.hide('calendar');
        });
    }

    setDefaultRange() {
        this.setSelectedDateRange(moment().startOf('month'), moment());
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        if (from.isSame(moment().startOf('month')) && to.isSame(moment())) {
            this.defaultView = true;
        } else {
            this.defaultView = false;
        }

        const selectedDateRange = this.getDateString(from, to);
        this.filterForm.dateRange = selectedDateRange;
        this.refresh();
    }

    showRetailersNeverVisited() {
        this.dbm.show('visites/retailers-never-visited/' + this.id, 'Negozi mai visitati');
    }

    showResultsDetail(modal, event) {
        this.busy = this.service
            .resultsDetail(
                this.selectedDateRange.from,
                this.selectedDateRange.to,
                this.id,
                event
            )
            .subscribe((data) => {
                this.currentEvent = event;
                this.resultsDetail = data;
                this.modalService.open(modal, {size: 'lg'});
            });
    }

    sendReply(item: any) {
        this.busy = this.agentVisitsService
            .postResponse({
                id_visita: item.id,
                testo: item.reply,
            })
            .subscribe((data) => {
                this.refresh();
            });
    }

    downloadExcel() {
        this.laddaExcel = true;
        this.service
            .salesAgentOverview(
                this.selectedDateRange.from,
                this.selectedDateRange.to,
                this.id, true
            )
            .subscribe((data) => {
                    this.requestService.downloadFile(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    this.laddaExcel = false;
                },
                (error) => {
                    this.laddaExcel = false;
                });
    }

    private getDateString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format('YYYY-MM-DD');
        this.selectedDateRange.to = end.format('YYYY-MM-DD');
        return (
            start.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            end.format(localDateFormat)
        );
    }
}
