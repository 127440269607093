import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';

export class Ria extends BaseModel{
    id: number;
    dealer_id: number;
    company_name: string;

    created_at: string;
    created_by: string;
    update_at: string;
    update_by: string;
    notes: string;
    status: string;
    is_deleted: number;
    file_certificate_path: string;
    file_document_back_path: string;
    file_document_front_path: string;
    file_module_path: string;
    file_photo_shop_back_path: string;
    file_photo_shop_front_path: string;
    file_privacy_path: string;

    public static riaTypes = [
        {
            label: 'In attesa',
            key: 'pending'
        },
        {
            label: 'Respinto',
            key: 'cancelled'
        },
        {
            label: 'Confermato',
            key: 'confirmed'
        }
    ];

    get riaStatusLabel(){
        return Ria.riaStatusPair[this.status];
    }

    public static riaStatusPair = {
        'confirmed': 'Confermato',
        'cancelled': 'Respinto',
        'pending' : 'In attesa'
    };

    fromArray(result: []): Ria[] {
        const array: Ria[] = [];
        for (const item of result) {
            array.push(new Ria(item));
        }
        return array;
    };
}

export class RiaProvider extends BaseModel {
    data: Ria[];
    _meta: MetaPagination;
    _links: Array<any>;
}


