import { BaseModel } from './base-model';
import { MetaPagination } from './meta-pagination';

export class Whatsapp extends BaseModel {
    id: number;
    nome: string;
    tipo_destinatario: string;
    tipo_invio: string;
    operatori: string;
    slug: string;
    created_at: string;
    created_by: string;
    update_at: string;
    update_by: string;
    is_deleted: number;
    loghi_operatori: any;
    destinatari: any;
    destinazione: string;
    role: any;

    constructor(data: any = {}) {
        super();
        this.id = data.id || 0;
        this.nome = data.nome || '';
        this.tipo_destinatario = data.tipo_destinatario || '';
        this.tipo_invio = this.translateTipoInvio(data.tipo_invio || '');
        this.operatori = data.operatori || '';
        this.slug = data.slug || '';
        this.created_at = data.created_at || '';
        this.created_by = data.created_by || '';
        this.update_at = data.update_at || '';
        this.update_by = data.update_by || '';
        this.is_deleted = data.is_deleted || 0;
        this.loghi_operatori = data.loghi_operatori || null;
        this.destinatari = data.destinatari || null;
        this.destinazione = data.destinazione || null;
        this.role = data.role || '';
    }

    private translateTipoInvio(tipo_invio: string): string {
        switch (tipo_invio) {
            case 'group':
                return 'Gruppo';
            case 'number':
                return 'Numero';
            case 'multiple':
                return 'Multi';
            default:
                return tipo_invio;
        }
    }

    fromArray(result: []): Whatsapp[] {
        const array: Whatsapp[] = [];
        for (const item of result) {
            array.push(new Whatsapp(item));
        }
        return array;
    }
}

export class WhatsappProvider extends BaseModel {
    data: Whatsapp[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class WhatsappSubscription extends BaseModel {
    id: number;
    wa_id: number;
    nome: string;
    dealer_id: number;
    number: string;
    role: any;
    created_at: string;
    created_by: string;
    update_at: string;
    update_by: string;
    is_deleted: number;

    fromArray(result: []): WhatsappSubscription[] {
        const array: WhatsappSubscription[] = [];
        for (const item of result) {
            array.push(new WhatsappSubscription(item));
        }
        return array;
    }
}

export class WhatsappSubscriptionProvider extends BaseModel {
    data: WhatsappSubscription[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class WhatsappLog extends BaseModel {
    id: number;
    wa_id: number;
    dealer_id: number;
    nome: string;
    html: string;
    created_at: string;
    created_by: string;
    update_at: string;
    update_by: string;
    is_deleted: number;

    fromArray(result: []): WhatsappLog[] {
        const array: WhatsappLog[] = [];
        for (const item of result) {
            array.push(new WhatsappLog(item));
        }
        return array;
    }
}

export class WhatsappLogProvider extends BaseModel {
    data: WhatsappLog[];
    _meta: MetaPagination;
    _links: Array<any>;
}
