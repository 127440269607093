import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { OrdersControl } from '@app/models/orders-control';
import { OrdersControlService } from '@app/services/orders-control.service';
import { AuthenticationService } from '@services/authentication.service';
import { RequestService } from '@app/services/request.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ActivatedRoute } from '@angular/router';
import { User } from '@models/user';
import { OrdersControlBonificoComponent } from './orders-control-bonifico/orders-control-bonifico.component';
import { OrdersControlCartaDiCreditoComponent } from './orders-control-carta-di-credito/orders-control-carta-di-credito.component';
import { OrdersControlCompensazioneComponent } from './orders-control-compensazione/orders-control-compensazione.component';
import { OrdersControlContrassegnoComponent } from './orders-control-contrassegno/orders-control-contrassegno.component';
import { OrdersControlRimessaAgenteComponent } from './orders-control-rimessa-agente/orders-control-rimessa-agente.component';
import { OrdersControlOmaggioComponent } from './orders-control-omaggio/orders-control-omaggio.component';
import { OrdersControlCompensazioneAnticipataComponent } from './orders-control-compensazione-anticipata/orders-control-compensazione-anticipata.component';
import { OrdersControlAssegnoVistaCorriereComponent } from './orders-control-assegno-vista-corriere/orders-control-assegno-vista-corriere.component';

@Component({
  selector: 'app-orders-control',
  templateUrl: './orders-control.component.html',
  styleUrls: ['./orders-control.component.css'],
})
export class OrdersControlComponent implements OnInit {
  @ViewChild('listaBonifici') listaBonifici: OrdersControlBonificoComponent;
  @ViewChild('listaContrassegno') listaContrassegno: OrdersControlContrassegnoComponent;
  @ViewChild('listaCartaDiCredito') listaCartaDiCredito: OrdersControlCartaDiCreditoComponent;
  @ViewChild('listaPerCompensazione') listaPerCompensazione: OrdersControlCompensazioneComponent;
  @ViewChild('listaAssegnoVistaCorriere') listaAssegnoVistaCorriere: OrdersControlAssegnoVistaCorriereComponent;
  @ViewChild('listaRimessaAgente') listaRimessaAgente: OrdersControlRimessaAgenteComponent;
  @ViewChild('listaOmaggio') listaOmaggio: OrdersControlOmaggioComponent;
  @ViewChild('listaCompensazioneAnticipata') listaCompensazioneAnticipata: OrdersControlCompensazioneAnticipataComponent;

  filterParams: any;
  reportControlOrders: OrdersControl;
  selectedTab: string = '';
  isDealer = false;
  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
    // Imposta la data massima su oggi
    maxDate: moment(),
    // Imposta la data minima su un anno fa dalla data attuale
    // minDate: moment().subtract(1, 'year'),
  };

  saldato = [
    {
      label: 'Da Saldare',
      key: '0',
    },
    {
      label: 'Saldato',
      key: '1',
    },
  ];

  apiDate = {
    from: '',
    to: '',
  };

  filter = {
    dealer_id: '',
    company_name: '',
    created: '',
    date: '',
    saldato: '',
    pagamento: '',
  };

  constructor(
    private ordersService: OrdersControlService,
    public request: RequestService,
    private authenticationService: AuthenticationService,
    private dateRangePickerOptions: DaterangepickerConfig,
    public route: ActivatedRoute,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.selectedTab = '0';

    this.defaultDate();

    this.loadOrders();
  }

  loadOrders(hideSpinner = false) {
    const params = {};

    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    if (this.filter.saldato) {
      params['saldato'] = this.filter.saldato;
    }
    if (this.selectTab !== undefined) {
      params['pagamento'] = this.selectedTab;
    }

    this.ordersService.getReportOrdersControl(params).subscribe(
      (response: any) => {
        this.reportControlOrders = response;
      },
      (err) => {
        console.log('errore');
      },
    );
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;

    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  // public generateDateRangeString(startDate: Moment, endDate: Moment) {
  //   const localDateFormat = this.dateRangePickerOptions.settings.locale.format;

  //   // Calcola la data massima consentita, che è 1 anno dopo la data di inizio
  //   const maxDate = startDate.clone().add(1, 'year');

  //   // Imposta la data massima per il datepicker
  //   this.pickerOptions.maxDate = maxDate;

  //   this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
  //   this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
  //   this.filter.date = startDate.format(localDateFormat)
  //     + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  // }

  public defaultDate() {
    // Imposta il filtro data di default come mese corrente
    const startDate = moment().startOf('month');
    const endDate = moment().endOf('month');

    this.generateDateRangeString(startDate, endDate);

    // Imposta il valore predefinito per il campo filter.date
    this.filter.date = startDate.format('DD/MM/YYYY') + ' - ' + endDate.format('DD/MM/YYYY');
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  refreshData() {
    this.loadOrders();

    if (this.selectedTab === '0' && this.listaBonifici) {
      this.listaBonifici.loadOrders();
    } else if (this.selectedTab === '1' && this.listaContrassegno) {
      this.listaContrassegno.loadOrders();
    } else if (this.selectedTab === '2' && this.listaCartaDiCredito) {
      this.listaCartaDiCredito.loadOrders();
    } else if (this.selectedTab === '3' && this.listaPerCompensazione) {
      this.listaPerCompensazione.loadOrders();
    } else if (this.selectedTab === '4' && this.listaRimessaAgente) {
      this.listaRimessaAgente.loadOrders();
    } else if (this.selectedTab === '5' && this.listaAssegnoVistaCorriere) {
      this.listaAssegnoVistaCorriere.loadOrders();
    } else if (this.selectedTab === '6' && this.listaOmaggio) {
      this.listaOmaggio.loadOrders();
    } else if (this.selectedTab === '7' && this.listaCompensazioneAnticipata) {
      this.listaCompensazioneAnticipata.loadOrders();
    }
  }
}
