import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';


export class RagSociale extends BaseModel {
  id: number;
  id_dealer: number;
  ragsociale: string;
  ragione_sociale: string;
  piva: string;
  codfiscale: string;
  provider: number;
  created: string;

  fromArray(result: []): RagSociale[] {
    const array: RagSociale[] = [];
    for (const item of result) {
      array.push(new RagSociale(item));
    }
    return array;
  }
}

export class RagSocialeProvider extends BaseModel {
  data: RagSociale[];
  _meta: MetaPagination;
  _links: Array<any>;
}