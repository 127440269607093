<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadOrders()">
        <div class="form-row">
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                                            id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Data</label>
                    <input id="documentDate" type="text" daterangepicker [options]="pickerOptions"
                           [(ngModel)]="filter.date" name="date" class="form-control"
                           (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label>Stato</label>
                    <ng-select [clearable]="true" class="disableClear" [(ngModel)]="filter.status"
                               [items]="statuses" bindLabel="label"
                               name="subscribed" id="subscribed"
                               bindValue="key" [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>ID Raccomandata</label>
                    <input [(ngModel)]="filter.shipment_id" name="type" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label>Servizio</label>
                    <input [(ngModel)]="filter.service" name="type" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>Raccomandate</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="table-orders"
                [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th *show="['master','operator']">Rag. Sociale</th>
                <th>ID Raccomandata</th>
                <th>Prezzo Dealer</th>
                <th>Data</th>
                <th>Servizio</th>
                <th>Stampa</th>
                <th>Colore</th>
                <th>Fogli</th>
                <th>Stato</th>
<!--                <th></th>-->
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let order of orders">
                <td *show="['master','operator']">
                    <a [routerLink]="['/dealer', order.dealer_id]" target="_blank">
                        {{order.company_name}}</a>
                </td>
                <td>
                    {{order?.shipment_id}}
                </td>
                <td>
                    {{order?.total_dealer_price|currency:currency}}
                </td>
                <td>
                    {{order?.created_at | date:'dd/MM/yyyy HH:MM:ss'}}
                </td>
                <td>
                    {{order?.shipment_type}}
                </td>
                <td>
                    {{order?.print_type}}
                </td>
                <td>
                    {{order?.color}}
                </td>
                <td>
                    {{order?.sheets}}
                </td>
                <td>
                    {{order?.status}}
                </td>
<!--                <td class="text-right">-->
<!--                    <button class="btn btn-link" (click)="showDetailModal(order)">-->
<!--                        <i class=" fa fa-eye text-info"></i>-->
<!--                    </button>-->
<!--                </td>-->
            </tr>
            <tr *ngIf="orders?.length<=0">
                <td class="text-muted" colspan="10" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="orders?.length>0">
    <span><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
        {{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}.
        <a class="link"
        >Esporta la lista completa</a
        >
    </span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
                        size="sm"
                        [pageSize]="ordersPagination.perPage"
                        [maxSize]="10"
                        [(page)]="ordersPagination.currentPage"
                        [collectionSize]="ordersPagination.totalCount"
                        (pageChange)="loadOrders()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #detailTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedData?.company_name}}
            - {{selectedData?.transaction_id}}</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-group list-group-flush">
            <li class="list-group-item px-0">
                <strong translate>Sender</strong>
                <ul class="ml-2 pl-4">
                    <li>First Name: {{selectedData?.item?.sender.first_name}}</li>
                    <li>Last Name: {{selectedData?.item?.sender.last_name}}</li>
                    <li>Email: {{selectedData?.item?.sender.email}}</li>
                    <li>City: {{selectedData?.item?.sender.city}}</li>
                    <li>Province: {{selectedData?.item?.sender.province}}</li>
                    <li>Address: {{selectedData?.item?.sender.address}}</li>
                    <li>Street Number: {{selectedData?.item?.sender.street_number}}</li>
                    <li>ZIP: {{selectedData?.item?.sender.zip}}</li>
                    <li>DUG: {{selectedData?.item?.sender.dug}}</li>
                </ul></li>
            <li class="list-group-item px-0">
                <strong translate>Destinations</strong>
                <div class="row">
                    <ul class="col-4 pl-5" *ngFor="let destination of selectedData?.item?.destinations">
                        <li>First Name: {{destination?.first_name}}</li>
                        <li>Last Name: {{destination?.last_name}}</li>
                        <li>Email: {{destination?.email}}</li>
                        <li>City: {{destination?.city}}</li>
                        <li>Province: {{destination?.province}}</li>
                        <li>Address: {{destination?.address}}</li>
                        <li>Street Number: {{destination?.street_number}}</li>
                        <li>ZIP: {{destination?.zip}}</li>
                        <li>DUG: {{destination?.dug}}</li>
                        <br>
                    </ul>
                </div>
            </li>
            <li class="list-group-item px-0">
                <strong translate>Options</strong>
                <div class="row">
                    <ul class="col-4 pl-5" *ngIf = "selectedData?.item?.options?.ar_c">
                        <li>Company Name: {{selectedData?.item?.options?.ar_c?.company_name}}</li>
                        <li>First Name: {{selectedData?.item?.options?.ar_c?.first_name}}</li>
                        <li>Last Name: {{selectedData?.item?.options?.ar_c?.last_name}}</li>
                        <li>Email: {{selectedData?.item?.options?.ar_c?.email}}</li>
                        <li>City: {{selectedData?.item?.options?.ar_c?.city}}</li>
                        <li>Province :{{selectedData?.item?.options?.ar_c?.province}}</li>
                        <li>Address: {{selectedData?.item?.options?.ar_c?.address}}</li>
                        <li>ZIP: {{selectedData?.item?.options?.ar_c?.zip}}</li>
                    </ul>
                </div>
            </li>
            <li class="list-group-item px-0">
                <strong translate>Documents</strong>
                <ul class="ml-2 pl-4">
                    <li>PDF: <a [href]="selectedData?.item?.documents?.pdf"
                            target="_blank">{{selectedData?.item?.documents.pdf}}</a></li>
                    <li>Content: {{selectedData?.item?.documents.content}}</li>
                </ul></li>
            <li class="list-group-item px-0">
                <strong translate>Prices</strong>
                <div class="row">
                    <ul class="col-6 pl-5" *ngFor="let price of selectedData?.item?.prices">
                        <li>Service Code: {{price?.service_code}}</li>
                        <li>Service Description{{price?.service_description}}</li>
                        <li>Quantity: {{price?.quantity}}</li>
                        <li>Total Unit Amount: {{price?.total_unit_amount}}</li>
                        <br>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</ng-template>
