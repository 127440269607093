<div class="card bg-gradient-light">
    <div class="card-header">
        <div class="card-title">Assegna massivamente dealer a Key Account</div>
    </div>
    <div class="card-body">
        <div class="row align-items-end">
            <div class="col-12 col-md-4">
                <label>Scegli operatore</label>
                <app-vendor-select (onChange)="setVendor($event)"></app-vendor-select>
            </div>
            <div class="col-12 col-md-4">
                <label>Scegli a chi assegnare</label>
                <app-agent-select  (onChange)="setAgent($event)"></app-agent-select>
            </div>
            <div class="col-12 col-md-4 pt-3">
                <button *ngIf="!previewLoaded" class="btn btn-block btn-success" [disabled]="!valid" [ladda]="previewLoading" (click)="previewResult()">Anteprima Risultato</button>
                <button *ngIf="previewLoaded"  class="btn btn-block btn-danger" [disabled]="!valid" [ladda]="saveLoading" (click)="saveResult()">Salva</button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <app-master-card
                title="Dealers"
                bodyClass="p-0"
                image="assets/img/flats/employee.png"
        >
            <hot-table
                    hotId="hot"
                    [data]="rows"
                    licenseKey="non-commercial-and-evaluation"
                    stretchH="all"
                    [rowHeaders]="true"
                    [colHeaders]="true"
                    [settings]="settings"
                    [height]="500"
                    >
                <hot-column data="id" title="ID" type="numeric"></hot-column>
                <hot-column data="ragsociale" [readOnly]="true" title="Ragione Sociale"></hot-column>
                <hot-column data="old_keyaccount" [readOnly]="true" title="Vecchio Key Account"></hot-column>
                <hot-column data="new_keyaccount" [readOnly]="true" title="Nuovo Key Account"></hot-column>
            </hot-table>
        </app-master-card>
    </div>
</div>
