import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '@services/dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {ShopAccess} from '@app/pages/shop-access/shop-access.type';
import {DealerService} from '@services/dealer.service';
import {Subscription} from 'rxjs';
import {environment} from '@environments/environment';
// import {DaterangepickerConfig} from 'ng2-daterangepicker';
// import { Moment } from 'moment';


@Component({
    selector: 'app-master-dealer-dettaglio',
    templateUrl: './master-dealer-dettaglio.component.html',
    styleUrls: ['./master-dealer-dettaglio.component.scss']
})
export class MasterDealerDettaglioComponent implements OnInit {
    busy: Subscription;
    id: string;
    data: any;
    
    // filterDate = '';
    // apiDate = {
    //     from: null,
    //     to: null
    // };

    edit = {
        responsabile: false,
        telefono: false,
        cellulare: false,
        email: false,
        formazione: false,
        padre_figlio: false,
        negozio: false,
        funzionale: false,
        sede: false,
    };
    loading = {
        responsabile: false,
        telefono: false,
        cellulare: false,
        email: false,
        formazione: false,
        padre_figlio: false,
        negozio: false,
        funzionale: false,
        sede: false,
    };

    riepilogoPeriod = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-DD'),
    };

    kdServicePeriod = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-DD'),
    };

    timeline = [];
    paymentTypesPair = ShopAccess.paymentTypesPair;

    baseAwsUrl = environment.baseAwsUrl;
    dealerChooses = [];
    loadingDealerNumbers = false;

    constructor(
        public route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private dashboardService: DashboardService,
        private dealerService: DealerService,
        private router: Router,
        // private dateRangePickerOptions: DaterangepickerConfig,
    ) {
        this.route.params.subscribe(params => {
            if(params.hasOwnProperty('id')) {
                this.id = params['id'];
                this.reload(); // reset and set base on new parameter this time
            }
            if(params.hasOwnProperty('number')) {
                this.loadingDealerNumbers = true;
                this.dealerService.getDealersByNumber(params['number']).subscribe((data) => {
                    if(data.length === 1) {
                        this.id = data[0].id;
                        this.router.navigate(['/dealer/' + this.id]);
                    }
                    else {
                        this.dealerChooses = data;
                    }
                    this.loadingDealerNumbers = false;
                },
                (error) => {
                    this.loadingDealerNumbers = false;
                });
            }
        });
    }

    get dealer() {
        if (this.data) {
            return this.data.dealer;
        }

        return {
            ragsociale: ''
        };
    }


    get info() {
        if (this.data) {
            return this.data.info;
        }

        return {
            is_shop: '1',
            functional: '0'
        };
    }

    get location() {
        if (this.data) {
            return this.data.location;
        }

        return {
            address: '',
            address_number: '',
            city: '',
            region: '',
            province: '',
            zip_code: '',
        };
    }

    get chartPeriod() {
        if (this.data) {
            return this.data.chartPeriod;
        }

        return {
            from: moment().subtract(12, 'months').format('YYYY-MM-01'),
            to: moment().format('YYYY-MM-DD'),
        };
    }

    // get kdCubeReportDateRange() {
    //     if (this.data) {
    //         return this.data.kdCubeReportDateRange;
    //     }

    //     return {
    //         from: moment().subtract(12, 'months').format('YYYY-MM-01'),
    //         to: moment().format('YYYY-MM-DD'),
    //     };
    // }

    affiliazione(id) {
        if (this.data) {
            if (id in this.data.affiliazioni) {
                return this.data.affiliazioni[id];
            }
        }

        return null;
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
    }

    reload() {
        this.spinner.show();

        this.busy = this.dashboardService.get(`/negozio-dettaglio?id=${this.id}`)
            .subscribe(data => {
                this.data = data.result;
                this.spinner.hide();
            }, (error) => {
               this.router.navigate(['/retailers/list']);
            });
    }

    sendReply(item: any) {
        this.spinner.show();

        this.dashboardService.post(`/visite-risposta`, {
            id_visita: item.id,
            testo: item.reply
        })
            .subscribe((data) => {
                this.dashboardService.get(`-detail/negozio-visite?id=${this.id}`)
                    .subscribe(data => {
                        this.timeline = data.result;

                        this.spinner.hide();
                    });
            });
    }

    saveDealerInfo(params: {}, key: string) {

        this.loading[key] = true;
        this.dealerService.updateDealer(this.id, params)
            .subscribe((data) => {
                this.loading[key] = false;
                this.edit[key] = false;
                this.reload();
            }, (error) => {
                this.loading[key] = false;
                this.edit[key] = false;
                this.reload();
            });
    }

    setAddress($event: any) {
        this.location.address = $event.address;
        this.location.address_number = $event.streetNumber === null ? 'snc' : $event.streetNumber;
        this.location.city = $event.city;
        this.location.zip_code = $event.postalCode;
    }

    // public generateDateRangeString(startDate: Moment, endDate: Moment) {
    //     const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    //     this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    //     this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    //     this.filterDate = startDate.format(localDateFormat)
    //         + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    //     return this.reload();
    //   }
    
    //   clearDateFiler() {
    //     this.filterDate = null;
    //     this.apiDate.from = null;
    //     this.apiDate.to = null;
    //     return this.reload();
    //   }

    chooseDealer(id) {
        this.router.navigate(['/dealer/' + id]);
    }
}
