import { 
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  ViewChild
} from '@angular/core';
import { PbxLead } from '@app/models/pbx/pbx-lead';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {PbxCampaignService} from '@services/pbx-campaign.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-pbx-modal-leads',
  templateUrl: './pbx-modal-leads.component.html',
  styleUrls: ['./pbx-modal-leads.component.css']
})
export class PbxModalLeadsComponent implements OnInit {

  @Input() campaignId;
  @Input() data: PbxLead;
  @Output() refresh = new EventEmitter<boolean>();

  pbxInputForm: FormGroup;
  file: File | null = null;

  busyDocumentFormModal: Subscription;
  documentFormModal: NgbModalRef;

  constructor(
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    private service: PbxCampaignService
  ) { }

  ngOnInit(): void {
    this.pbxInputForm = this.formBuilder.group({
      fileInput: [null, [Validators.required]],
    });
  }

  onFileSelected(event: any){
    this.file = event.target.files[0];
  }

  save(){
    if (this.file) {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("campaignId", this.campaignId);
      this.service.pbxUploadFile(formData).subscribe(
        (response: PbxLead) => {
          this.service.successAlert("importato correttamente");
          this.refresh.emit(true);
          this.dismissModal();
        },
        (err) => {
          this.service.errorAlert(err.message);
          this.refresh.emit(true);
          this.dismissModal();
        }
      );
    }

  }

  dismissModal() {
    this.modalService.dismissAll();
  }

}
