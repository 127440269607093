import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Kdcube } from '@app/models/kdcube';
import { MetaPagination } from '@app/models/meta-pagination';
import { User } from '@app/models/user';
import { AuthenticationService } from '@app/services/authentication.service';
import { KdcubeService } from '@app/services/kdcube.service';
import { RequestService } from '@app/services/request.service';
import { AgentSelectComponent } from '@app/widgets/agent-select/agent-select.component';
import { MasterAgentSelectComponent } from '@app/widgets/master-agent-select/master-agent-select.component';
import { RetailerTypeaheadComponent } from '@app/widgets/retailer-typeahead/retailer-typeahead.component';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-kdcube',
  templateUrl: './kdcube.component.html',
  styleUrls: ['./kdcube.component.css'],
})
export class KdcubeComponent implements OnInit {
  @ViewChild('retailerTypeahead') retailerTypeahead: RetailerTypeaheadComponent;
  @ViewChild('agentSelect') agentSelect: AgentSelectComponent;
  @ViewChild('masterAgentSelect') masterAgentSelect: MasterAgentSelectComponent;
  ordersPagination: MetaPagination = new MetaPagination();
  expirationList: Kdcube[] = [];
  isDealer = false;
  activeTab: string = 'expiration';
  kdcubeServiceType = Kdcube.serviceTranslate;
  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  filter = {
    id: null,
    id_ka: null,
    id_am: null,
    id_dealer: null,
    created_at: null,
    date: null,
    id_operator: null,
    id_invoice: null,
    id_stripe: null,
    from: null,
    vardate: null,
    to: null,
    service: null,
    time_range: null,
    payment_type: null,
    price: null,
    renewed: null,
    rinnovato: null,
  };

  apiDate = {
    from: '',
    to: '',
  };

  renewalItems: { label: string; value: string }[] = [
    {
      label: 'In attesa',
      value: '2',
    },
    {
      label: 'Rinnovato',
      value: '1',
    },
    {
      label: 'Annullato',
      value: '0',
    },
  ];

  renewItems: { label: string; value: string }[] = [
    {
      label: 'SI',
      value: '1',
    },
    {
      label: 'NO',
      value: '0',
    },
  ];

  paymentTypesItems: { label: string; value: string }[] = [
    {
      label: 'Nessuno',
      value: 'none',
    },
    {
      label: 'Carta di Credito',
      value: 'MNP',
    },
    {
      label: 'Bonifico',
      value: 'sdd-transfer',
    },
    {
      label: 'Stripe',
      value: 'stripe',
    },
    {
      label: 'Omaggio',
      value: 'free',
    },
    {
      label: 'KD Shop',
      value: 'shop',
    },
  ];

  constructor(
    public request: RequestService,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private dateRangePickerOptions: DaterangepickerConfig,
    public route: ActivatedRoute,
    private kdcubeService: KdcubeService,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    if (this.activeTab == 'expiration') {
      this.loadExpirationList();
    } else if (this.activeTab == 'expired') {
      this.loadExpiredList();
    }
  }

  selectTab(tabName: string) {
    this.activeTab = tabName;
    this.resetFilters();
    this.loadList();
  }

  resetFilters() {
    for (const key in this.filter) {
      if (this.filter.hasOwnProperty(key)) {
        this.filter[key] = null;
      }
    }
    if (this.retailerTypeahead) {
      this.retailerTypeahead.reset();
    }
    if (this.agentSelect) {
      this.agentSelect.reset();
    }
    if (this.masterAgentSelect) {
      this.masterAgentSelect.reset();
    }
  }

  loadExpirationList() {
    this.spinner.show('table-orders');
    const params = {};

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }

    if (this.filter.id_dealer) {
      params['id_dealer'] = this.filter.id_dealer;
    }

    if (this.filter.id_ka) {
      params['id_ka'] = this.filter.id_ka;
    }
    if (this.filter.id_am) {
      params['id_am'] = this.filter.id_am;
    }

    if (this.filter.payment_type) {
      params['payment_type'] = this.filter.payment_type;
    }

    if (this.filter.rinnovato) {
      params['rinnovato'] = this.filter.rinnovato;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.kdcubeService.getExpirationList(params).subscribe(
      (response: any) => {
        this.expirationList = new Kdcube().fromArray(response.data);
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  loadExpiredList() {
    this.spinner.show('table-orders');
    const params = {};

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }

    if (this.filter.id_dealer) {
      params['id_dealer'] = this.filter.id_dealer;
    }

    if (this.filter.id_ka) {
      params['id_ka'] = this.filter.id_ka;
    }
    if (this.filter.id_am) {
      params['id_am'] = this.filter.id_am;
    }

    if (this.filter.payment_type) {
      params['payment_type'] = this.filter.payment_type;
    }

    if (this.filter.renewed) {
      params['renewed'] = this.filter.renewed;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.kdcubeService.getExpiredList(params).subscribe(
      (response: any) => {
        this.expirationList = new Kdcube().fromArray(response.data);
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  exportExpiration() {
    const params = {
      'per-page': 0,
    };

    if (this.filter.id_dealer) {
      params['id_dealer'] = this.filter.id_dealer;
    }

    if (this.filter.id_ka) {
      params['id_ka'] = this.filter.id_ka;
    }
    if (this.filter.id_am) {
      params['id_am'] = this.filter.id_am;
    }

    if (this.filter.payment_type) {
      params['payment_type'] = this.filter.payment_type;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.spinner.show('table-orders');
    this.kdcubeService.getExpirationList(params).subscribe(
      (data) => {
        let rows = data.data;

        let output = rows.map((row) => {
          let paymentTypeItem = this.paymentTypesItems.find((item) => item.value === row.payment_type);
          let paymentTypeLabel = paymentTypeItem ? paymentTypeItem.label : row.payment_type;

          return {
            'ID': row.id,
            'ID Dealer': row.id_dealer,
            Dealer: row.company_name,
            'P.Iva': row.piva,
            Da: row.from,
            A: row.to,
            Service: row.service === 'all' ? 'Tutti i servizi' : row.service,
            'Tipo di pagamento': paymentTypeLabel,
            'Area Manager': row.area_manager_ragsociale,
            'Key Account': row.key_account_ragsociale,
          };
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'report-KdCube-in-scadenza.xlsx');
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  exportExpired() {
    const params = {
      'per-page': 0,
    };

    if (this.filter.id_dealer) {
      params['id_dealer'] = this.filter.id_dealer;
    }

    if (this.filter.id_ka) {
      params['id_ka'] = this.filter.id_ka;
    }
    if (this.filter.id_am) {
      params['id_am'] = this.filter.id_am;
    }

    if (this.filter.payment_type) {
      params['payment_type'] = this.filter.payment_type;
    }

    if (this.filter.renewed) {
      params['renewed'] = this.filter.renewed;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.spinner.show('table-orders');

    this.kdcubeService.getExpiredList(params).subscribe(
      (data) => {
        let rows = data.data;
        let output = rows.map((row) => {
          let paymentTypeItem = this.paymentTypesItems.find((item) => item.value === row.payment_type);
          let paymentTypeLabel = paymentTypeItem ? paymentTypeItem.label : row.payment_type;

          let renewedTypeItem = this.renewItems.find((item) => item.value === row.renewed);
          let renewedTypeLabel = renewedTypeItem ? renewedTypeItem.label : row.renewed;

          return {
            'ID': row.id,
            'ID Dealer': row.id_dealer,
            'Ragione Sociale': row.company_name,
            'P.Iva': row.piva,
            Da: row.from,
            A: row.to,
            Service: row.service === 'all' ? 'Tutti i servizi' : row.service,
            'Tipo di pagamento': paymentTypeLabel,
            'Area Manager': row.area_manager_ragsociale,
            'Key Account': row.key_account_ragsociale,
            Rinnovato: renewedTypeLabel,
          };
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'report-KdCube-scaduti.xlsx');

        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }
}
