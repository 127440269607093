<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadList()">
        <div class="form-row">
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                    id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">Dealer ID</label>
                    <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control">
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date</label>
                    <input id="documentDate" type="text" daterangepicker [options]="pickerOptions"
                        [(ngModel)]="filter.date" name="date" class="form-control" autocomplete="off"
                        (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- tabella -->

<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>INTEGRA</h4>
    </div>
    <div class="card-body p-0 rounted-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
        <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th>Espandi</th>
                    <th translate>Company name</th>
                    <th translate>Name</th>
                    <th translate>Surname</th>
                    <th translate>Email</th>
                    <th translate>Date</th>
                    <th translate class="text-center">Status</th>
                    <th translate class="text-center">Download</th>
                </tr>
            </thead>
            <tbody class="bg-gradiend-white">
            <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="orders"
                [fullScreen]="false"
            >
            <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngIf="integraList.length==0">
                <td translate colspan="8" class="text-center">List is empty</td>
            </tr>
            <ng-container *ngFor="let item of integraList">
                <tr>
                    <td>
                        <button 
                        class="btn btn-outline-info btn-sm text-center" type="button" (click)="item.show = !item.show" role="button" aria-expanded="false" translate>
                        <i class="fas fa-plus mr-0" *ngIf="!item.show"></i>
                        <i class="fas fa-minus mr-0" *ngIf="item.show"></i>
                        </button>
                    </td>
                    <td>
                         <a [routerLink]="['/dealer', item.dealer_id]" target="_blank">{{item.company_name}}</a>
                    </td>
                    <td>
                        {{item?.firstname}}
                    </td>
                    <td>
                        {{item?.lastname}}
                    </td>
                    <td>
                        {{item?.reporter_email}}
                    </td>
                    <td>        
                        {{item?.created_at | amDateFormat: 'HH:mm - DD/MM/YYYY' }}
                    </td>
                    <td class="text-center">Status</td>
                    <!-- <td> 
                        <button href="javascript:void(0)" class="btn btn-sm text-uppercase btn-block"
                        (click)="openPopover(plafondStatusPopover,a2aOrder?.id,a2aOrder?.status,'status',statuses)"
                        #plafondStatusPopover="ngbPopover" autoClose="outside" triggers="manual"
                        [ngbPopover]="popContent" popoverTitle="Cambia valore"
                        [ngClass]="{
                           'btn-info' : a2aOrder.status === 'pending', 
                           'btn-success': a2aOrder.status === 'confirmed',
                           'btn-danger': a2aOrder.status === 'canceled'
                        }"
                        >
                        {{a2aOrder?.orderStatus}}
                        </button>
                    </td> -->
                    
                    <td class="text-center">
                        <i class="fas fa-download text-primary cursor-hand" (click)="downloadZip(item.id)"></i>
                    </td>
                </tr>

                <!-- Se è show -->
                <tr *ngIf="item.show">
                    <td colspan="12" class="p-0">
                        <table class="table mb-0">
                            <thead class="">
                            <!-- header -->
                            <td colspan="12" class="bg-dark">
                                <p class="text-center text-sm text-uppercase">Dati cliente</p>
                            </td>
                              <tr class="bg-white">
                                <th scope="col">Nome del cliente</th>
                                <th scope="col">Data di nascita</th>
                                <th scope="col">Comune</th>
                                <th scope="col">Email</th>
                                <th scope="col">Telefono</th>
                                <th scope="col" class="text-right">Importo</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="bg-white">
                                <td>
                                    <p>{{item.name}}</p>
                                </td>
                                <td>
                                    <p>{{item.birthdate}}</p>
                                </td>
                                <td>
                                    <p>{{item.city}}</p>
                                </td>
                                <td>
                                    <p>{{item.customer_email}}</p>
                                </td>
                                <td>
                                    <p>{{item.phone}}</p>
                                </td>
                                <td class="text-right">
                                    <p>{{item.loan_amount_requested | currency: "€"}}</p>
                                </td>
                              </tr>
                            </tbody>
                            <!-- header -->
                            <td colspan="12" class="bg-dark">
                                <p class="text-center text-sm text-uppercase">Dati Pratica</p>
                            </td>
                            <thead class="">
                                <tr class="bg-white">
                                  <th scope="col">Finalità</th>
                                  <th scope="col">Impiego</th>
                                  <th scope="col">Tipo d'impiego</th>
                                  <th scope="col">Reddito netto mensile</th>
                                  <th scope="col">Cessione del 5° in corso</th>
                                  <th scope="col" class="text-right">Impegni in corso</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="bg-white">
                                  <td>
                                      <p>{{item.purpose}}</p>
                                  </td>
                                  <td>
                                    <p>{{item.job}}</p>
                                  </td>
                                  <td>
                                      <p>{{item.permanent_occupation}}</p>
                                  </td>
                                  <td>
                                      <p>{{item.monthly_income | currency: "€"}}</p>
                                  </td>
                                  <td>
                                      <p>{{item.on_going_loans}}</p>
                                  </td>
                                  <td class="text-right">
                                      <p>{{item.on_going_commitments | currency: "€"}}</p>
                                  </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
     
            </ng-container>
            </tbody>  
        </table> 
    </div>
    <div class="card-footer">
        <span><span translate>There are </span><b>{{ this.ordersPagination.totalCount }} </b>{{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}. <a class="link" > Esporta la lista completa</a></span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadList()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>