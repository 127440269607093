import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';
import { AffiliationListPagination } from '@models/affiliation';

export class BusinessPlan extends BaseModel {
    id: number;
    name: string;
    revenue: number[];
    cost: number[];
    net: number[];
    compare_percentages: {
        revenue: number[],
        cost: number[],
        net: number[],
    };
    compare: {
        revenue: number[],
        cost: number[],
        net: number[],
    };
}

export class BusinessPlanProvider extends BaseModel {
    business_units: BusinessPlan[];
    total: {
        revenue: number[],
        cost: number[],
        net: number[],
        compare_percentages: {
            revenue: number[],
            cost: number[],
            net: number[],
        },
        compare: {
            revenue: number[],
            cost: number[],
            net: number[],
        }
    };
}

export class BusinessUnit extends BaseModel {
    id: number;
    name: string;
    description: string;
    rows_type: BusinessUnitRowType[];
    total_cost_row: number[];
    total_revenue_row: number[];
    total_margin_row: number[];

    compare_total_cost_row: number[];
    compare_total_revenue_row: number[];
    compare_total_margin_row: number[];

    compare_total_cost_row_percentage: any[] = [];
    compare_total_revenue_row_percentage: any[] = [];
    compare_total_margin_row_percentage: any[] = [];

    get childModels() {
        return {
            rows_type: BusinessUnitRowType,
        };
    }

    init(year: number) {
        for (let i = 0; i < this.rows_type.length; i++) {
            const rowType = this.rows_type[i];
            const rows = rowType.rows.slice();
            const compare_rows = rowType.compare_rows.slice();
            if (rowType.rows.length < 12) {
                const iRows = [];
                for (let j = 0; j < 12; j++) {
                    //reindex rows by month
                    const exist = rows.find((row) => row.month === j+1 && row.year === year);

                    if (!exist) {
                        this.rows_type[i].rows[j] = new BusinessUnitRow({
                            month: j+1,
                            year: year,
                            value: null,
                            type_id: this.rows_type[i].id,
                        });
                    }
                    else {
                        this.rows_type[i].rows[j] = exist;
                    }
                }
            }
            if (rowType.compare_rows.length < 12) {
                const iRows = [];
                for (let j = 0; j < 12; j++) {
                    //reindex rows by month
                    const exist = compare_rows.find((row) => row.month === j+1 && row.year === year);

                    if (!exist) {
                        this.rows_type[i].compare_rows[j] = new BusinessUnitRow({
                            month: j+1,
                            year: year,
                            value: null,
                            type_id: this.rows_type[i].id,
                        });
                    }
                    else {
                        this.rows_type[i].compare_rows[j] = exist;
                    }
                }
            }
            this.rows_type[i].compare_percentage = [];
            for (let j = 0; j < 12; j++) {
                this.rows_type[i].compare_percentage[j] = this.rows_type[i].compare_rows[j].value ? ((this.rows_type[i].rows[j].value - this.rows_type[i].compare_rows[j].value) / this.rows_type[i].compare_rows[j].value * 100).toFixed(0) : null;
            }

            this.rows_type[i].compare_total = this.rows_type[i].compare_rows.reduce((acc, row) => acc + (row.value !== null ? parseFloat(row.value.toString()) : 0), 0);
            this.rows_type[i].compare_total_percentage = this.rows_type[i].compare_total ? ((this.rows_type[i].total - this.rows_type[i].compare_total) / this.rows_type[i].compare_total * 100).toFixed(0) : null;
        }

        for (let j = 0; j <= 12; j++) {
            this.compare_total_cost_row_percentage[j] = this.compare_total_cost_row[j] ? ((this.total_cost_row[j] - this.compare_total_cost_row[j]) / this.compare_total_cost_row[j] * 100).toFixed(0) : null;
            this.compare_total_revenue_row_percentage[j] = this.compare_total_revenue_row[j] ? ((this.total_revenue_row[j] - this.compare_total_revenue_row[j]) / this.compare_total_revenue_row[j] * 100).toFixed(0) : null;
            this.compare_total_margin_row_percentage[j] = this.compare_total_margin_row[j] ? ((this.total_margin_row[j] - this.compare_total_margin_row[j]) / this.compare_total_margin_row[j] * 100).toFixed(0) : null;
        }
    }

    fromArray(result: any[]): BusinessUnit[] {
        const array: BusinessUnit[] = [];
        for (const item of result) {
            array.push(new BusinessUnit(item));
        }
        return array;
    }
}

export class BusinessUnitRowType extends BaseModel {
    id: number;
    name: string;
    bu_id: number;
    type: string;
    format: string;
    order: number;
    rows: BusinessUnitRow[];
    compare_rows: BusinessUnitRow[];
    compare_percentage: any[];
    total: number;
    compare_total: number;
    compare_total_percentage: any;

    get childModels() {
        return {
            rows: BusinessUnitRow,
            compare_rows: BusinessUnitRow,
        };
    }

    fromArray(result: any[]): BusinessUnitRowType[] {
        const array: BusinessUnitRowType[] = [];
        for (const item of result) {
            array.push(new BusinessUnitRowType(item));
        }
        return array;
    }

}


export class BusinessUnitRow extends BaseModel {
    id: number;
    type_id: number;
    month: number;
    year: number;
    value: number;
    type: string;

    fromArray(result: any[]): BusinessUnitRow[] {
        const array: BusinessUnitRow[] = [];
        for (const item of result) {
            array.push(new BusinessUnitRow(item));
        }
        return array;
    }

}

export class BusinessUnitProvider extends BaseModel {
    data: BusinessUnit[];
    _meta: MetaPagination;
    _links: Array<any>;
}