import { Component, OnInit, ViewChild } from '@angular/core';
import { BusinessPlanService } from '@services/business-plan.service';
import { BusinessUnit, BusinessUnitProvider } from '@models/business-plan';
import { Pagination } from '@models/pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@services/alert.service';
import { ConfirmOptionsService } from '@services/confirm-options.service';

@Component({
    selector: 'app-business-units',
    templateUrl: './business-units.component.html',
    styleUrls: ['./business-units.component.css'],
})
export class BusinessUnitsComponent implements OnInit {

    @ViewChild('formTemplate') private formTemplate;

    form: FormGroup;
    provider: BusinessUnitProvider;
    loading = false;

    get confirmDeleteOptions() {
        return new ConfirmOptionsService({
            title: "Elimina Business Unit",
            text: "Sei sicuro di voler rimuovere questa riga?",
        });
    }

    constructor(private _service: BusinessPlanService,
                public modalService: NgbModal,
                private alertService: AlertService,
                ) {
    }

    ngOnInit(): void {
        this.getBusinessUnits();

        this.form = new FormGroup({
            id: new FormControl(''),
            name: new FormControl('', [Validators.required]),
            description: new FormControl('')
        });
    }

    getBusinessUnits(pagination: Pagination = null) {
        const params = {};

        if(pagination) {
            params['page'] = pagination.page;
        }

        this.loading = true;
        this._service.getBusinessUnits(params).subscribe((response: BusinessUnitProvider) => {
            this.provider = response;
            this.provider.data = (new BusinessUnit()).fromArray(this.provider.data);
            this.loading = false;
        },
        () => {
            this.loading = false;
            this.alertService.show('Errore', 'Errore durante il caricamento delle Business Unit', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    save($event: any) {
        if(this.form.invalid) {
            return;
        }

        const payload = this.form.value;

        if(payload.id) {
            return this.update(payload);
        }

        this.loading = true;
        this._service.saveBusinessUnit(payload).subscribe((response: BusinessUnit) => {
            this.form.reset();
            this.getBusinessUnits();
            this.modalService.dismissAll();
            this.loading = false;

            this.alertService.show('Ottimo', 'Business Unit create con successo', {
                classname: 'bg-success text-light',
                delay: 3000,
            });
        }, () => {
            this.loading = false;
            this.alertService.show('Errore', 'Errore durante il salvataggio della Business Unit', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    showModal() {
        this.modalService.open(this.formTemplate, { size: 'lg' });
    }

    edit(row: BusinessUnit) {
        this.form.patchValue(row);
        this.modalService.open(this.formTemplate, { size: 'lg' });
    }

    delete(row: BusinessUnit) {
        this.loading = true;
        this._service.deleteBusinessUnit(row.id).subscribe((response: BusinessUnit) => {
            this.getBusinessUnits();
            this.loading = false;

            this.alertService.show('Ottimo', 'Business Unit rimossa con successo', {
                classname: 'bg-success text-light',
                delay: 3000,
            });
        }, () => {
            this.loading = false;
            this.alertService.show('Errore', 'Errore durante la rimozione della Business Unit', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }

    update(payload: any) {
        this.loading = true;
        this._service.updateBusinessUnit(payload).subscribe((response: BusinessUnit) => {
            this.form.reset();
            this.getBusinessUnits();
            this.modalService.dismissAll();
            this.loading = false;

            this.alertService.show('Ottimo', 'Business Unit aggiornata con successo', {
                classname: 'bg-success text-light',
                delay: 3000,
            });
        }, () => {
            this.loading = false;
            this.alertService.show('Errore', 'Errore durante l\'aggiornamento della Business Unit', {
                classname: 'bg-danger text-light',
                delay: 3000,
            });
        });
    }
}
