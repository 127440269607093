import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

const version = 'v3';

@Injectable({
  providedIn: 'root',
})
export class A2aService {
  constructor(private request: RequestService, public alertService: AlertService) {}

  public getA2aOrders(params?): Observable<any> {
    return this.request.get(version + '/application/orders', params);
  }

  public putA2aOrder(id, body: {}) {
    return this.request.put(version + '/application/update/' + id, body);
  }

  public putDealerIdA2aOrder(id, body: {}) {
    return this.request.put('a2a/update/' + id, body);
  }

  public getA2aList(params?): Observable<any> {
    return this.request.get('a2a/list', params);
  }
}
