import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';
import {PbxLead} from '@models/pbx/pbx-lead';
import {PbxQuestionAnswer} from '@models/pbx/pbx-question';

export class PbxCall extends BaseModel {
    id: number;
    lead_id: number;
    caller_id: number;
    caller_name: string;
    lead?: PbxLead;
    destination: string;
    start_time: string;
    duration: string;
    scheduled_at: string;
    response: string;
    feedback_status: string;
    call_status: string;
    answers?: PbxQuestionAnswer[];
    pbxQuestionAnswers?: PbxQuestionAnswer[];
}

export class PbxStatus {
    name: string;
    key: string;
    is_closed?: boolean;
    color?: string;
}

export class PbxCallListPagination extends BaseModel {
    data: PbxCall[];
    _meta: MetaPagination;
    _links: Array<any>;
}
