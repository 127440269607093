import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { AuthenticationService } from '@services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { User } from '@models/user';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx'
import { Cu } from '@app/models/cu';
import { CuService } from '@app/services/cu.service';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { RequestService } from '@app/services/request.service';

@Component({
  selector: 'app-cu',
  templateUrl: './cu.component.html',
  styleUrls: ['./cu.component.css']
})
export class CuComponent implements OnInit {

  cuOrders: Cu[] = [];
  ordersPagination: MetaPagination;
  @ViewChild('cuFormTemplate') private cuFormTemplate;
  @ViewChild('cuMassiveTemplateUpdate') private cuMassiveTemplateUpdate;
  deleteSubscriptionOptions: ConfirmOptionsService;
  isDealer = false;
  apiDate = '';
  isEdit = true;
  insertData: Cu;
  busy: any;
  rangeOptions = {
    singleDatePicker: true,
    ranges: []
  };

  filter = {
    id: '',
    dealer_id: '',
    company_name: '',
    created: '',
    date: '',
    year: '',
    deleted: undefined
  }

  selectItems = [
    { label: 'Attivo', key: 0 },
    { label: 'Disattivo', key: 1 }
  ]

  constructor(
    public request: RequestService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    private dateRangePickerOptions: DaterangepickerConfig,
    private CuService: CuService,
    public route: ActivatedRoute
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });

    this.deleteSubscriptionOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla'
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadOrders();
  }

  onItemSelected(selectedItem: any) {
    this.filter.deleted = selectedItem.key;
  }

  loadOrders(hideSpinner: boolean = false) {
    this.spinner.show('table-orders');
    const params = {};

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.year) {
      params['year'] = this.filter.year;
    }
    if (this.filter.deleted !== undefined) {
      params['deleted'] = this.filter.deleted;
    }
    if (this.filter.date) {
      params['created'] = this.apiDate;
    }

    this.CuService.getCuOrders(params).subscribe((response: any) => {
      this.cuOrders = new Cu().fromArray(response.data);
      this.ordersPagination = response._meta;
      this.spinner.hide('table-orders');
    }, err => {
      this.spinner.hide('table-orders');
    });
  }

  deleteCuSubscription(id: number) {
    this.CuService.deleteCuSubscription(id).subscribe(() => {
      const index = this.cuOrders.findIndex(dealer => dealer.id === id);
      if (index > -1) {
        this.CuService.successAlert('Deleted Service!');
        this.loadOrders();
      }
    }, (err) => {
      this.CuService.errorAlert(err.message);
    });
  }

  showModal(data?: Cu) {
    this.insertData = data;
    this.modalService.open(this.cuFormTemplate, { size: data ? 'md' : 'lg', centered: true });
  }

  showMassiveUploadModal() {
    this.modalService.open(this.cuMassiveTemplateUpdate, { size: 'md', centered: true });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  selectedDate(date: Moment) {
    this.filter.date = date.format(this.dateRangePickerOptions.settings.locale.format);
    this.apiDate = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
  }

  exportSdd() {
    this.spinner.show('table-dealers');
    const params = {
      'per-page': 0
    };

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.deleted) {
      params['deleted'] = this.filter.deleted;
    }
    if (this.filter.year) {
      params['year'] = this.filter.year;
    }
    if (this.filter.date) {
      params['created'] = this.apiDate;
    }

    this.CuService
      .getCuOrders(params)
      .subscribe((data) => {
        this.spinner.hide('table-dealers');

        let rows = data.data;

        let output = rows.map((row) => {
          return {
            'Ragione Sociale': row.company_name,
            'Dealer Id': row.dealer_id,
            'Anno': row.year,
            'Data di creazione': row.created
          };
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'export-cu.xlsx');
      });
  }

}
