import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrdersControl, suggestedTransaction } from '@app/models/orders-control';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrdersControlService } from '@app/services/orders-control.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cdc-modal',
  templateUrl: './cdc-modal.component.html',
  styleUrls: ['./cdc-modal.component.css'],
})
export class CdcModalComponent implements OnInit {
  @Input() orderId: any;
  @Output() refresh = new EventEmitter<boolean>();

  transactionSuggested: suggestedTransaction[] = [];
  showSpinner = false;

  constructor(public modalService: NgbModal, private service: OrdersControlService) {}

  ngOnInit(): void {
    this.loadOptions();
  }

  loadOptions() {
    this.service.getReportOrdersSuggestedCdc(this.orderId).subscribe(
      (response: any) => {
        this.transactionSuggested = response.map((item) => ({ ...item, selected: item.is_paid }));
        // .map(item => ({ ...item, selected: false }));
      },
      (error) => {
        console.error('Errore nel recupero dei dati:', error);
      },
    );
  }

  toggleSelection(item: suggestedTransaction) {
    item.selected = !item.selected;
  }

  sendData() {
    this.showSpinner = true;
    const selectedItems = this.transactionSuggested.filter((item) => item.selected);

    // Assicurati che ci siano dati selezionati da salvare
    //if (selectedItems.length > 0) {
    const dataToSave = {
      orderId: this.orderId,
      id_transaction: selectedItems.map((transaction) => transaction.id),
    };

    this.service.postCdcMatch(dataToSave).subscribe(
      (response) => {
        // this.showSpinner = false;
        setTimeout(() => {
          this.showSpinner = false; // Nascondi lo spinner dopo 2 secondi
        }, 3000);
        this.service.successAlert('saved');
        this.refresh.emit(true);
        this.dismissModal(); // Chiudi la modale
      },
      (error) => {
        this.showSpinner = false;
        console.error('Errore durante il salvataggio dei dati:', error);
        this.service.errorAlert('Failed to save data');
        this.refresh.emit(true);
        this.dismissModal(); // Chiudi la modale
      },
    );
    //}
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}
