import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShopOrdini } from '@app/models/orders-control';
import { OrdersControlService } from '@app/services/orders-control.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cdc-modal-import',
  templateUrl: './cdc-modal-import.component.html',
  styleUrls: ['./cdc-modal-import.component.css']
})
export class CdcModalImportComponent implements OnInit {
  
  @Input() data: ShopOrdini;
  @Output() refresh = new EventEmitter<boolean>();
  @ViewChild('documentFormTemplate') private documentFormTemplate;

  cdcImport: FormGroup;
  file: File | null = null;
  loadingExport = false;
  showSpinner = false;

  constructor(
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    private service: OrdersControlService
  ) { }

  ngOnInit(): void {
      this.cdcImport = this.formBuilder.group({
        fileInput: [null, [Validators.required]],
    });
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  save() {
    if (this.file) {
      this.showSpinner = true; 
        const formData = new FormData();
        formData.append('file', this.file);


        this.loadingExport = true;
        this.service.cdcUploadFile(formData).subscribe(
            (response: ShopOrdini) => {
                this.service.successAlert('importato correttamente');
                this.refresh.emit(true);
                this.dismissModal();
                this.loadingExport = null;
            },
            (err) => {
                this.service.errorAlert(err.message);
                this.refresh.emit(true);
                this.dismissModal();
                this.loadingExport = null;
            }
        );
    }
    // return this.addSubscription(formData);
  }

  dismissModal() {
      this.modalService.dismissAll();
  }

}
