<div class="card bg-gradient-info">
  <div class="card-body">
    <div class="d-flex align-items-center">
      <img src="" alt="" class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1" src="assets/img/flats/target.png" width="160" />
      <div class="flex-1 px-4">
        <h1 class="display-4">KPI Recap</h1>
      </div>
    </div>
  </div>
</div>

<ul class="nav nav-tabs">
  <li class="nav-item" *show="['operator', 'master', 'submaster']">
    <a class="nav-link text-uppercase" [class.active]="selectedTab === 'areaManager'" (click)="selectTab('areaManager')" data-toggle="tab" href="#areaManager">Area Manager</a>
  </li>
  <li class="nav-item" *show="['operator', 'master', 'masteragent', 'submaster']">
    <a class="nav-link text-uppercase" [class.active]="selectedTab === 'keyAccount'" (click)="selectTab('keyAccount')" data-toggle="tab" href="#keyAccount">Key Account</a>
  </li>
</ul>

<div class="tab-content">
  <!-- Tab: Area Manager -->
  <ng-container *show="['operator', 'master', 'submaster']">
    <div id="areaManager" *ngIf="selectedTab === 'areaManager'" class="tab-pane fade show active">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-6 col-12">
              <label>Periodo</label>
              <app-month-range-picker (selected)="setSelectedDate($event)" [singleMonthPicker]="true"></app-month-range-picker>
            </div>
            <div class="col-lg-6 col-12">
              <label>Tipo Kpi</label>
              <ng-select (change)="onKpiTypeChange($event)">
                <ng-option *ngFor="let kpiType of kpiTypeList" [value]="kpiType?.id">{{ kpiType?.title }}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="areaManagerTemplate" *ngIf="!loadingKpi && selectedKpiType !== null; else noKpiTypeSelected"></ng-container>
      <app-spinner *ngIf="loadingKpi" status="loading"></app-spinner>
    </div>
  </ng-container>

  <!-- Tab: Key Account -->
  <ng-container *show="['operator', 'master', 'submaster', 'masteragent']">
    <div id="keyAccount" *ngIf="selectedTab === 'keyAccount'" class="tab-pane fade show active">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-4 col-12">
              <label>Periodo</label>
              <app-month-range-picker (selected)="setSelectedDate($event)" [singleMonthPicker]="true"></app-month-range-picker>
            </div>
            <div class="col-lg-4 col-12">
              <label>Tipo Kpi</label>
              <ng-select (change)="onKpiTypeChange($event)">
                <ng-option *ngFor="let kpiType of kpiTypeList" [value]="kpiType.id">{{ kpiTypeList !== null ? kpiType?.title : '' }}</ng-option>
              </ng-select>
            </div>
            <div class="col-lg-4 col-12" *show="['operator', 'master', 'submaster']">
              <label>Area Manager</label>
              <app-master-agent-select (onChange)="onMasterAgentChange($event)"></app-master-agent-select>
            </div>
          </div>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="keyAccountTemplate" *ngIf="!loadingKpi && selectedKpiType != null; else noKpiTypeSelected"></ng-container>
      <app-spinner *ngIf="loadingKpi" status="loading"></app-spinner>
    </div>
  </ng-container>
</div>

<ng-template #keyAccountTemplate>
  <div class="card table-responsive">
    <div class="card-header bg-gradient-secondary">
      <p class="display-5">
        KPI Selezionato: <span class="font-weight-bold">{{ selectedKpiType?.title }}</span>
      </p>
      <div [innerHTML]="selectedKpiType?.description" class="display-10"></div>
    </div>
    <div class="card-body p-0">
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr>
            <th>Key Account</th>
            <th class="text-left">Area Manager</th>
            <th class="text-center">Avanzamento</th>
            <th class="text-center">Target</th>
            <th class="text-center">Media da fare</th>
            <th class="text-center"><span aria-hidden="true">&#x25B2;</span> al Tgt</th>
            <th class="text-center">PAF</th>
            <th class="text-center">PAF %</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let kpi of kpis">
            <td class="text-left">
              {{ kpi.ragsociale }}
            </td>
            <td class="text-left">
              {{ kpi.am_ragsociale }}
            </td>
            <td class="text-center font-weight-bold">
              {{ kpi.value }}
            </td>
            <td class="text-center text-primary font-weight-bold">{{ kpi.target }}</td>
            <td class="text-center">{{ kpi.average }}</td>
            <td class="text-center">{{ kpi.delta_to_tgt }}</td>
            <td class="text-center font-weight-bold" [ngClass]="{ 'text-success': kpi.paf >= kpi.target, 'text-danger': kpi.paf < kpi.target }">{{ kpi.paf }}</td>
            <td class="text-center font-weight-bold" [ngClass]="{ 'text-success': kpi.paf_perc >= 100, 'text-danger': kpi.paf_perc < 100 }">{{ kpi.paf_perc }}%</td>
          </tr>
        </tbody>
        <tfoot class="w-100">
          <tr>
            <td colspan="7" class="p-3">
              <p class="text-success cursor-hand" (click)="export()"><i class="fas fa-table"></i> Esporta la lista in excel</p>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #areaManagerTemplate>
  <div class="card table-responsive">
    <div class="card-header bg-gradient-secondary">
      <p class="display-5">
        KPI Selezionato: <span class="font-weight-bold">{{ selectedKpiType?.title }}</span>
      </p>
      <div [innerHTML]="selectedKpiType?.description" class="display-10"></div>
    </div>
    <div class="card-body p-0">
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr>
            <th class="text-left">Area Manager</th>
            <th class="text-center">Avanzamento</th>
            <th class="text-center">Target</th>
            <th class="text-center">Media da fare</th>
            <th class="text-center"><span aria-hidden="true">&#x25B2;</span> al Tgt</th>
            <th class="text-center">PAF</th>
            <th class="text-center">PAF %</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let kpi of kpis">
            <td class="text-left">
              {{ kpi.ragsociale }}
            </td>
            <td class="text-center">
              {{ kpi.value }}
            </td>
            <td class="text-center text-primary">{{ kpi.target }}</td>
            <td class="text-center">{{ kpi.average }}</td>
            <td class="text-center">{{ kpi.delta_to_tgt }}</td>
            <td class="text-center">{{ kpi.paf }}</td>
            <td class="text-center font-weight-bold" [ngClass]="{ 'text-success': kpi.paf_perc >= 100, 'text-danger': kpi.paf_perc < 100 }">{{ kpi.paf_perc }}%</td>
          </tr>
        </tbody>
        <tfoot class="w-100">
          <tr>
            <td colspan="7" class="p-3">
              <p class="text-success cursor-hand" (click)="export()"><i class="fas fa-table"></i> Esporta la lista in excel</p>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #noKpiTypeSelected>
  <p class="text-center">Seleziona un Tipo Kpi per visualizzare i dati</p>
</ng-template>
