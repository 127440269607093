import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const agent = 'agent';
const visits = 'visites';
const schedule = 'schedule';
const response = 'response';

@Injectable({
    providedIn: 'root'
})
export class AgentVisitsService {

    constructor(
        public request: RequestService,
        private alertService: AlertService) {
    }

    postVisit(body) {
        return this.request.post(visits, body);
    }

    putVisit(id, body) {
        return this.request.put(visits + '/' + id, body);
    }

    deleteVisit(id) {
        return this.request.delete(visits + '/' + id);
    }

    postSchedule(body) {
        return this.request.post(visits + '/' + schedule, body);
    }

    putSchedule(id, body) {
        return this.request.put(visits + '/' + schedule + '/' + id, body);
    }

    deleteSchedule(id) {
        return this.request.delete(visits + '/' + schedule + '/' + id);
    }

    postResponse(body) {
        return this.request.post(visits + '/' + response, body);
    }

    putResponse(id, body) {
        return this.request.put(visits + '/' + response + '/' + id, body);
    }

    deleteResponse(id) {
        return this.request.delete(visits + '/' + response + '/' + id);
    }

    getRoutes(param?): Observable<any> {
        return this.request.get(agent + '/routes', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getDashboardReport(param?): Observable<any> {
        return this.request.get(agent + '/report', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    getAgentStops(param?): Observable<any> {
        return this.request.get(agent + '/stops', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    getAgentVisits(param?): Observable<any> {
        return this.request.get(agent + '/visits', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getVisitFormStructure(param?): Observable<any> {
        return this.request.get(visits + '/form-structure', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getShops(param?): Observable<any> {
        return this.request.get(agent + '/shops', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
