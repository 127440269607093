import {Component, OnInit, ViewChild} from '@angular/core';
import {EPayService} from '@services/e-pay.service';
import {ShopOrder, ShopOrderPagination} from '@models/e-pay/shop-order';
import {MetaPagination} from '@models/meta-pagination';
import {User} from '@models/user';
import {AuthenticationService} from '@services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopBrand, ShopBrandPagination} from '@models/e-pay/shop-brand';
import * as XLSX from 'xlsx';
import {ActivatedRoute} from '@angular/router';

// noinspection DuplicatedCode
@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
    orders: ShopOrder[] = [];
    selectedOrder: ShopOrder;
    ordersPagination: MetaPagination;
    options = {
        singleDatePicker: true,
        ranges: []
    };
    filter = {
        dealer_id: '',
        order_id: '',
        product: '',
        brand: '',
        type: '',
        phone_number: '',
        status: '',
    };
    types = EPayService.productTypes;
    currency = EPayService.currency;
    statuses = EPayService.orderStatuses;
    @ViewChild('receiptFormTemplate') private receiptFormTemplate;
    brandsLoading = false;
    brands: ShopBrand[] = [];
    isDealer = false;

    constructor(
        private ePayService: EPayService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        public route: ActivatedRoute) {
        this.authenticationService.currentUser.subscribe((user: User) => {
                this.isDealer = user && user.profile.role === 'dealer';
            }
        );
    }

    ngOnInit(): void {
        this.filter.order_id = this.route.snapshot.paramMap.get('orderId');
        this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
        this.loadOrders();
        setTimeout(() => {
            this.loadBrands();
        });
    }

    loadOrders() {
        this.spinner.show('table-orders');
        const params = {};
        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }
        if (this.filter.phone_number) {
            params['phone_number'] = this.filter.phone_number;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.order_id) {
            params['id'] = this.filter.order_id;
        }
        if (this.filter.product) {
            params['product'] = this.filter.product;
        }
        if (this.filter.brand) {
            params['brand'] = this.filter.brand;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        this.ePayService.getOrders(params).subscribe((response: ShopOrderPagination) => {
            this.orders = response.data;
            this.ordersPagination = response._meta;
            this.spinner.hide('table-orders');
        }, err => {
            this.spinner.hide('table-orders');
        });
    }

    loadBrands(term?) {
        this.brandsLoading = true;
        const param = {};
        if (term) {
            param['name'] = term;
        }
        this.ePayService.getBrands(param).subscribe((response: ShopBrandPagination) => {
            this.brands = response.data;
            this.brandsLoading = false;
        }, err => {
            this.brandsLoading = false;
        });
    }

    showReceiptModal(order: ShopOrder) {
        this.selectedOrder = order;
        this.modalService.open(this.receiptFormTemplate, {size: 'md'});
    }

    exportList() {
        this.spinner.show('table-orders');
        const params = {};
        if (this.filter.phone_number) {
            params['phone_number'] = this.filter.phone_number;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.order_id) {
            params['id'] = this.filter.order_id;
        }
        if (this.filter.product) {
            params['product'] = this.filter.product;
        }
        if (this.filter.brand) {
            params['brand'] = this.filter.brand;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        params['per-page'] = 0;

        this.ePayService.getOrders(params).subscribe((response: ShopOrderPagination) => {
            this.spinner.hide('table-orders');

            const rows = response.data.map(order => {

                return {
                    'Prodotto': order.order_items.length > 0 ? order.order_items[0].product_name : null,
                    'Brand': order.order_items.length > 0 ? order.order_items[0].brand_name : null,
                    'Numero di telefono': order.phone_number,
                    'Stato': order.status,
                    'Inserito il': order.created_at,
                };
            });

            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'export.xlsx');
        }, err => {
            this.spinner.hide('table-orders');
        });
    }
}
