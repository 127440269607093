import { Component, OnInit, ViewChild } from '@angular/core';
import { Partnership } from '@app/models/partnership';
import { PartnershipService } from '@app/services/partnership.service';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { RequestService } from '@app/services/request.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/models/user';


@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.css']
})
export class PartnershipComponent implements OnInit {
  isDealer = false;
  mode: any; // Dichiarazione della variabile mode
  @ViewChild('partnershipFormTemplate') private partnershipFormTemplate;

  constructor(
    public request: RequestService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    private sddService: PartnershipService,
    public route: ActivatedRoute

  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
  });
  }

  ngOnInit(): void {
  }

  showModal(mode: any) {
    this.mode = mode;
    this.modalService.open(this.partnershipFormTemplate, {size:'md'});
  }

  dismissModal() {
    this.modalService.dismissAll();
  }


}
