<div class="card">
    <div class="card-header">
        <div class="card-title">Carica invito a fatturare</div>
        <div class="card-tools">
            <button (click)="downloadTemplate()" class="btn btn-sm btn-secondary">Scarica Template</button>
        </div>
    </div>
    <div class="card-body">
        <div class="w-100 text-center" *ngIf="loading">
            <img src="assets/img/svgs/loading_gears.svg" />
        </div>
        <ng-container *ngIf="!loading">
            <div class="row">
                <div class="col-12 col-md-6">
                    <label>Descrizione Invito</label>
                    <input type="text" [(ngModel)]="description" class="form-control" />
                </div>
                <div class="col-12 col-md-6">
                    <label>Carica il template</label>
                    <div class="custom-file">
                        <input (change)="loadTemplate($event?.target?.files[0])" class="custom-file-input"
                               type="file">
                        <label class="custom-file-label" for="file">
                            <ng-container *ngIf="this.file">{{this.file.name}}</ng-container>
                            <ng-container *ngIf="!this.file">Scegli il file...</ng-container>
                        </label>
                    </div>
                </div>
            </div>


            <div *ngIf="launchKey" class="alert alert-success mt-4 mb-0">
                Il tuo invito è stato caricato correttamente. Il gruppo di riferimento è: <u><b>{{launchKey}}</b></u>
            </div>
        </ng-container>
    </div>
    <div class="card-footer" *ngIf="!loading">
        <button class="btn btn-success" [disabled]="!description || description.length < 5 || !this.file" (click)="sendInvito()">Carica Invito</button>
    </div>
</div>

<h3 class="display-6">Inviti Caricati</h3>

<div class="card">
    <div class="card-body p-0">
        <div class="w-100 text-center" *ngIf="loadingGroups">
            <img src="assets/img/svgs/loading_gears.svg" />
        </div>
        <ng-container *ngIf="!loadingGroups">
            <table class="table table-sm">
                <tr>
                    <th>Invito</th>
                    <th>Creato da</th>
                    <th class="text-right">Fatture Totali</th>
                    <th class="text-right">Fatture Sbloccate</th>
                    <th class="text-right">Fatture Bloccate</th>
                    <th class="text-right"></th>
                </tr>
                <tr *ngFor="let row of groups">
                    <td>{{row.name}}</td>
                    <td>{{row.owner}}</td>
                    <td class="text-right">{{row.proFormas.length}}</td>
                    <td class="text-right">{{row.unlockedProFormas.length}}</td>
                    <td class="text-right">{{row.lockedProFormas.length}}</td>
                    <td class="text-right">
                        <button class="btn btn-sm btn-success" *ngIf="row.unlockedProFormas.length !== row.proFormas.length" (click)="unlock(row.id)">Sblocca Invito</button>
                        <button class="btn btn-sm btn-warning ml-2"  *ngIf="row.lockedProFormas.length !== row.proFormas.length" (click)="block(row.id)">Blocca Invito</button>
                    </td>
                </tr>
            </table>
        </ng-container>
    </div>
</div>