import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {PbxCampaignQuestion} from '@models/pbx/pbx-question';
import {PbxCall} from '@models/pbx/pbx-call';

@Component({
  selector: 'app-pbx-answers-modal',
  templateUrl: './pbx-answers-modal.component.html',
  styleUrls: ['./pbx-answers-modal.component.css']
})
export class PbxAnswersModalComponent implements OnInit {
  @Input() questionAnswers: PbxCampaignQuestion[] = [];
  @Input() call: PbxCall;
  questionTypes = PbxCampaignService.questionTypes;

  constructor(public modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}
