import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {map} from 'rxjs/operators';
import {PbxCampaign, PbxCampaignOperatorPagination, PbxCampaignReport} from '@models/pbx/pbx-campaign';
import {
    PbxCampaignQuestionListPagination,
    PbxQuestion,
    PbxQuestionCategory,
    PbxQuestionListPagination,
    PbxQuestionType
} from '@models/pbx/pbx-question';
import {PbxLeadListPagination} from '@models/pbx/pbx-lead';
import {PbxCall, PbxCallListPagination, PbxStatus} from '@models/pbx/pbx-call';
import {BehaviorSubject} from 'rxjs';

const pbxCampaigns = 'pbx-campaigns';
const pbxQuestions = 'pbx-questions';
const pbxCalls = 'pbx-calls';
const leads = 'leads';
const questions = 'questions';

@Injectable({
    providedIn: 'root'
})
export class PbxCampaignService {
    constructor(private request: RequestService, public alertService: AlertService) {
    }

    static datetimeApiFormat = 'YYYY-MM-DD HH:mm:ss';
    static datetimeLocalFormat = 'DD/MM/YYYY HH:MM';
    static callStatuses: PbxStatus[] = [
        {
            name: 'Positive',
            key: 'positive',
            color: 'success',
            is_closed: true
        },
        {
            name: 'Negative',
            key: 'negative',
            color: 'danger',
            is_closed: true
        },
        {
            name: 'Without Answer',
            key: 'without-answer',
            color: 'warning',
            is_closed: false
        },
        {
            name: 'Owner Absent',
            key: 'owner-absent',
            color: 'primary',
            is_closed: false
        },
        {
            name: 'Call Back',
            key: 'call-back',
            color: 'info',
            is_closed: false
        },
    ];

    static feedbackStatuses: PbxStatus[] = [
        {
            name: 'Positive',
            key: 'positive',
            color: 'success'
        },
        {
            name: 'Negative',
            key: 'negative',
            color: 'danger'
        }
    ];
    static questionTypes: PbxQuestionType[] = [
        {
            name: 'Yes/No',
            key: 'yes/no',
            answers: [{
                name: 'Yes',
                key: 'yes'
            }, {
                name: 'No',
                key: 'no'
            }]
        },
        {
            name: 'Text',
            key: 'text'
        }
    ];
    static questionCategories: PbxQuestionCategory[] = [
        {name: 'Orders', key: 'orders'},
        {name: 'Training', key: 'training'},
        {name: 'Critical Issues', key: 'critical-issues'},
        {name: 'Managers', key: 'managers'},
        {name: 'Productivity', key: 'productivity'}
    ];
    static activeCampaign = 0;
    static expiredCampaign = 1;
    static onHoldCampaign = 2;

    campaign: PbxCampaign;
    public changedCampaign = new BehaviorSubject(this.campaign);

    public getCampaigns(params?) {
        return this.request.get(pbxCampaigns, params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public postCampaign(body: PbxCampaign, params?) {
        return this.request.post(pbxCampaigns, body, params).pipe(
            map((response: any) => {
                return new PbxCampaign(response);
            })
        );
    }

    public putCampaign(id: number, body: PbxCampaign) {
        return this.request.put(pbxCampaigns + '/' + id, body).pipe(
            map((response: any) => {
                return new PbxCampaign(response);
            })
        );
    }

    public postQuestion(body: PbxQuestion) {
        return this.request.post(pbxQuestions, body).pipe(
            map((response: any) => {
                return new PbxQuestion(response);
            })
        );
    }

    public pbxUploadFile(formData){
        return this.request.post('pbx-campaign/file',  formData)
    }

    public getQuestions(param?) {
        return this.request.get(pbxQuestions, param).pipe(
            map((response: any) => {
                return new PbxQuestionListPagination(response);
            })
        );
    }

    public getLeads(campaignId: number, params?) {
        return this.request.get(pbxCampaigns + '/' + campaignId + '/' + leads, params).pipe(
            map((response: any) => {
                return new PbxLeadListPagination(response);
            })
        );
    }

    public getCampaignQuestions(campaignId: number, params?) {
        return this.request.get(pbxCampaigns + '/' + campaignId + '/' + questions, params).pipe(
            map((response: any) => {
                return new PbxCampaignQuestionListPagination(response);
            })
        );
    }

    public postCall(body: PbxCall) {
        return this.request.post(pbxCalls, body).pipe(
            map((response: any) => {
                return new PbxCall(response);
            })
        );
    }

    public getCalls(params?) {
        return this.request.get(pbxCalls, params).pipe(
            map((response: any) => {
                return new PbxCallListPagination(response);
            })
        );
    }

    public getQuestionAnswers(callId: number, campaignId: number) {
        const params = {campaign_id: campaignId};
        return this.request.get(pbxCalls + '/' + callId + '/question-answers', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public putCall(id: number, body: PbxCall) {
        return this.request.put(pbxCalls + '/' + id, body).pipe(
            map((response: any) => {
                return new PbxCall(response);
            })
        );
    }

    public deleteCall(id: number) {
        return this.request.delete(pbxCalls + '/' + id);
    }

    public deleteCampaign(id: number) {
        return this.request.delete(pbxCampaigns + '/' + id);
    }

    public getCampaignReport(id: number) {
        return this.request.get(pbxCampaigns + '/' + id + '/report').pipe(
            map((response: any) => {
                return new PbxCampaignReport(response);
            })
        );
    }

    public getQuestionsReport(id: number) {
        return this.request.get(pbxCampaigns + '/' + id + '/questions-report').pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getOperatorsReport(id: number, params?) {
        return this.request.get(pbxCampaigns + '/' + id + '/operators', params).pipe(
            map((response: any) => {
                return new PbxCampaignOperatorPagination(response);
            })
        );
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'REPORT ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'ERRORE ',
            message ? message : 'Riprova di nuovo!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
