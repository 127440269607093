<ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs">
    <li ngbNavItem *show="['master', 'masteragent', 'operator', 'submaster']">
        <a ngbNavLink>Key Account</a>
        <ng-template ngbNavContent>
            <ng-container *ngFor="let card of cards">
                <div class="card cursor-hand">
                    <div class="card-header bg-gradient-light" (click)="card.show = !card.show">
                        <div class="card-title display-6 mb-0">{{card.title}}</div>
                        <div class="card-tools pt-1 pr-2">
                            <i class="fas text-xl" [ngClass]="{'fa-plus-circle': !card.show, 'fa-minus-circle': card.show}"></i>
                        </div>
                    </div>
                    <div class="card-body p-0" *ngIf="card.show">
                        <data-builder-table *ngIf="card.show" [url]="card.url"></data-builder-table>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'operator', 'submaster']">
        <a ngbNavLink>Area Manager</a>
        <ng-template ngbNavContent>
            <ng-container *ngFor="let card of cardsAm">
                <div class="card cursor-hand" (click)="card.show = !card.show">
                    <div class="card-header bg-gradient-light">
                        <div class="card-title display-6 mb-0">{{card.title}}</div>
                        <div class="card-tools pt-1 pr-2">
                            <i class="fas text-xl" [ngClass]="{'fa-plus-circle': !card.show, 'fa-minus-circle': card.show}"></i>
                        </div>
                    </div>
                    <div class="card-body p-0" *ngIf="card.show">
                        <data-builder-table *ngIf="card.show" [url]="card.url"></data-builder-table>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>