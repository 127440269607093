<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/payment.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Shop credit</h1>
                <hr>
                <div class="form-row">
                    <div class="col-12 col-lg-6">
                        <app-retailer-typeahead (onChange)="loadData($event)" *ngIf="!dealerId"
                                                id="dealer"></app-retailer-typeahead>
                        <input
                                type="text" daterangepicker [(ngModel)]="filterDate" *ngIf="dealerId"
                                name="vat_date" class="form-control" placeholder="Select date"
                                (cancelDaterangepicker)="clearDateFiler()"
                                (selected)="generateDateRangeString($event.start, $event.end)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card mt-3 bg-gradient-light">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="report"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body" style="min-height: 100px;">
        <div class="row d-flex align-items-center">
            <div class="col-lg col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img class="d-inline-flex" width="70px"
                         src="assets/img/flats/euro.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Current credit</p>
                        <h1 class="display-6">{{report?.total_amount|currency:currency}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-lg col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img class="d-inline-flex" width="70px"
                         src="assets/img/flats/money-sign.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Total credit</p>
                        <h1 class="display-6">{{report?.credit|currency:currency}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-lg col-12 dashboard-card">
                <div class="d-flex align-items-center"><img
                        class="d-inline-flex" width="70px"
                        src="assets/img/flats/money-loss.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Total debit</p>
                        <h1 class="display-6">{{report?.debit|currency:currency}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-lg col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img class="d-inline-flex" width="70px"
                         src="assets/img/flats/file.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Total transaction</p>
                        <h1 class="display-6">{{report?.total_transaction}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-10 col-12">
                <h4 class="mb-0" translate>{{dealerId ? 'Transactions' : 'Dealers'}}</h4>
            </div>
            <div class="col-lg-2 col-12" *ngIf="dealerId">
                <button *show="['master']" class="btn btn-success btn-sm float-right" type="button" translate
                        (click)="showModal()">
                    Add Transaction
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="table-credit"
                [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped" *ngIf="!dealerId">
            <thead>
            <tr>
                <th translate>Company name</th>
                <th translate>Company owner</th>
                <th translate class="text-right">Amount</th>
                <th class="text-right"></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <tr *ngFor="let dealer of dealers">
                <td>
                    <a [routerLink]="['/dealer', dealer.id]" target="_blank">
                        {{dealer.company_name}}
                    </a>
                </td>
                <td>
                    {{dealer.company_owner}}
                </td>
                <td class="text-right">
                    {{dealer.total_amount|currency:currency}}
                </td>
                <td class="text-right">
                    <button class="btn btn-link py-0 my-0" routerLink="/gestione-crediti/shop-credit/{{dealer?.id}}">
                        <i class="fa fa-eye text-info"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="dealers.length<=0">
                <td translate colspan="10" class="text-muted">List is empty</td>
            </tr>
            </tbody>
        </table>
        <table class="table table-sm table-striped" *ngIf="dealerId">
            <thead>
            <tr>
                <th>Date</th>
                <th>Description</th>
                <th translate>Service</th>
                <th translate>Operator</th>
                <th class="text-right">Credit</th>
                <th class="text-right">Debit</th>
                <th class="text-right">Total</th>
                <th class="text-right" *show="['master']"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let credit of credits">
                <td>{{credit.created_at|amDateFormat:localFormat}}</td>
                <td>{{credit?.note}}</td>
                <td>
                    <a *ngIf="credit?.serviceType"
                       [routerLink]="['/'+credit?.serviceType?.path+'/orders',{orderId:credit?.order_id, dealerId:credit?.dealer_id}]">
                        {{credit?.serviceType?.label}}
                    </a>
                </td>
                <td>{{credit?.operator}}</td>
                <td class="text-right">
                    <span *ngIf="credit?.is_credit">{{credit.amount|currency:currency}}</span></td>
                <td class="text-right">
                    <span *ngIf="!credit?.is_credit">{{credit.amount|currency:currency}}</span></td>
                <td class="text-right">
                    <strong>{{credit.current_sum|currency:currency}}</strong></td>
                <td class="text-right" *show="['master']">
                    <button class="btn btn-link py-0 my-0" (click)="showModal(credit)">
                        <i class=" fa fa-edit text-muted"></i>
                    </button>
                    <button class="btn btn-link py-0 my-0" [swal]="deleteCreditOptions"
                            (confirm)="removeTransaction(credit.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="credits.length<=0">
                <td class="text-muted" colspan="10" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="dealers.length>0 && pagination">
    <span><span translate>There are</span><b> {{ pagination.totalCount }}</b>
        {{pagination.totalCount <= 1 ? 'dealer' : 'dealers'}}.</span>
        <ngb-pagination *ngIf="pagination.pageCount>1"
                        size="sm"
                        [pageSize]="pagination.perPage"
                        [maxSize]="10"
                        [(page)]="pagination.currentPage"
                        [collectionSize]="pagination.totalCount"
                        (pageChange)="loadDealers()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
    <div class="card-footer" *ngIf="credits.length>0 && pagination">
    <span><span translate>There are</span><b> {{ pagination.totalCount }}</b>
        {{pagination.totalCount <= 1 ? 'transaction' : 'transactions'|translate}}.</span>
        <ngb-pagination *ngIf="pagination.pageCount>1"
                        size="sm"
                        [pageSize]="pagination.perPage"
                        [maxSize]="10"
                        [(page)]="pagination.currentPage"
                        [collectionSize]="pagination.totalCount"
                        (pageChange)="loadTransactions()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #transactionTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Transaction</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="transactionBusy">
        <form [formGroup]="transactionForm" (ngSubmit)="saveTransaction()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="price" translate>Amount</label>
                    <div class="input-group">
                        <input type="number" class="form-control" id="price" aria-describedby="basic-addon"
                               formControlName="amount">
                        <div class="input-group-append">
                            <div class="input-group-text bg-white" id="basic-addon"><strong>€</strong></div>
                        </div>
                    </div>
                    <val-errors controlName="price">
                        <ng-template valError="required">
                            <span translate>Amount is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 form-group">
                    <label for="note" translate>Note</label>
                    <textarea class="form-control" id="note" rows="5" formControlName="note"></textarea>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #detailTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedCredit?.service}}</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-group list-group-flush">
            <li class="list-group-item px-0" *ngFor="let item of selectedCredit?.order?.order_items">
                <strong translate>{{item?.product_name}}</strong>
                <span class="float-right">{{item?.price|currency:currency}}</span></li>
        </ul>
    </div>
</ng-template>
