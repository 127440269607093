<div class="card bg-gradient-dark">
    <div class="card-body">
        <div class="d-flex align-items-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1" src="assets/img/flats/target.png" width="160" />
            <div class="flex-1 px-4">
                <h1 class="display-4">KPI &amp; Targets</h1>
                <hr />

                <div class="row">
                    <div class="col-12 d-md-flex justify-content-md-start align-items-md-center">
                        <div class="col-12 col-md-auto p-md-1 p-0 mb-2 mb-md-0">
                            <button class="btn btn-block btn-warning mr-2" *ngIf="selectedTab === 'generale'" [disabled]="reloadingKpis" (click)="processKpis()">Ricalcola Avanzamento</button>
                        </div>
                        <div class="col-12 col-md-auto p-md-1 p-0 mb-2 mb-md-0">
                            <button class="btn btn-block btn-light" (click)="showExcelModal()" *show="['operator', 'master']">Scarica in Excel</button>
                        </div>
                        <div class="col-12 col-md-auto p-md-1 p-0 mb-2 mb-md-0">
                            <button class="btn btn-block btn-info" (click)="showUploadExcelModal()" *show="['operator', 'master']">Carica Excel</button>
                        </div>
                        <div class="col-12 col-md p-md-1 p-0"></div>
                        <div class="col-12 col-md-auto p-md-1 p-0 mb-2 mb-md-0 mr-md-1">
                            <button class="btn btn-block btn-success float-md-right" [disabled]="downloadingConsuntivo" (click)="downloadConsuntivo()" *show="['operator', 'master']">
                                Scarica Consuntivo
                            </button>
                        </div>
                        <div class="col-12 col-md-auto p-md-1 p-0">
                            <button
                                class="btn btn-block btn-light float-md-right mr-2"
                                [disabled]="downloadingConsuntivoDetail"
                                (click)="downloadConsuntivoDetail()"
                                *show="['operator', 'master', 'submaster', 'masteragent']"
                            >
                                Scarica Dettaglio
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ul class="nav nav-tabs">
    <li class="nav-item" *show="['operator','master']">
        <a class="nav-link text-uppercase" [class.active]="selectedTab === 'direttoreCommerciale'" (click)="selectTab('direttoreCommerciale')" data-toggle="tab" href="#direttoreCommerciale"
            >Direttore Commerciale</a
        >
    </li>
    <li class="nav-item" *show="['operator', 'master', 'submaster']">
        <a class="nav-link text-uppercase" [class.active]="selectedTab === 'areaManager'" (click)="selectTab('areaManager')" data-toggle="tab" href="#areaManager">Area Manager</a>
    </li>
    <li class="nav-item" *show="['operator', 'master', 'masteragent', 'submaster']">
        <a class="nav-link text-uppercase" [class.active]="selectedTab === 'keyAccount'" (click)="selectTab('keyAccount')" data-toggle="tab" href="#keyAccount">Key Account</a>
    </li>
    <li class="nav-item" *show="['operator', 'master', 'masteragent', 'submaster', 'agent']">
        <a class="nav-link text-uppercase" [class.active]="selectedTab === 'dealers'" (click)="selectTab('dealers')" data-toggle="tab" href="#dealers">Dealers</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-uppercase" [class.active]="selectedTab === 'generale'" (click)="selectTab('generale')" data-toggle="tab" href="#generale">Generale</a>
    </li>
</ul>

<div class="tab-content">
    <!-- Tab: Direttore Commerciale -->
    <div id="direttoreCommerciale" *ngIf="selectedTab === 'direttoreCommerciale'" class="tab-pane fade show active">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-lg-4 col-md-12 mb-3 mb-md-0">
                                <label>Periodo</label>
                                <app-month-range-picker (selected)="setSelectedDate($event)" [singleMonthPicker]="true"></app-month-range-picker>
                            </div>
                            <div class="col-lg-4 col-md-12 mb-3 mb-md-0">
                                <label translate>Direttore Commerciale</label>
                                <!-- <ng-select [items]="dcList" bindValue="id" bindLabel="ragsociale" (change)="setScopeId($event?.id)"></ng-select> -->
                                <app-submaster-select (onChange)="setScopeId($event)"></app-submaster-select>
                            </div>
                            <div class="col-lg-4 col-md-12 d-md-flex justify-content-md-end">
                                <button (click)="loadTargetList()" class="btn btn-info btn-block mt-auto" translate>Filtra</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3 mt-md-0">
                        <div class="col-12 h-100 w-100 d-flex flex-column align-items-md-end justify-content-md-end p-0">
                            <div class="text-md-right text-center mb-2" translate *ngIf="kpiPayload.dealer_id == null">
                                Seleziona un <strong>Direttore Commerciale</strong> per assegnargli un target
                            </div>
                            <div class="text-center">
                                <button class="btn btn-primary ml-md-auto" (click)="showTargetModal(selectedData)" [disabled]="kpiPayload.dealer_id == null">Assegna Target</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="kpiTemplate" *ngIf="!loadingKpi"></ng-container>
        <app-spinner *ngIf="loadingKpi" status="loading"></app-spinner>
    </div>
    <!-- Tab: Area Manager -->
    <div id="areaManager" *ngIf="selectedTab === 'areaManager'" class="tab-pane fade show active">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-lg-4 col-md-12 mb-3 mb-md-0">
                                <label translate>Periodo</label>
                                <app-month-range-picker (selected)="setSelectedDate($event)" [singleMonthPicker]="true"></app-month-range-picker>
                            </div>
                            <div class="col-lg-4 col-md-12 mb-3 mb-md-0" *show="['operator', 'master', 'masteragent', 'submaster']">
                                <label translate>Area Manager</label>
                                <app-master-agent-select (onChange)="setScopeId($event)"></app-master-agent-select>
                            </div>
                            <div class="col-lg-4 col-md-12 d-md-flex justify-content-md-end">
                                <button (click)="loadTargetList()" class="btn btn-info btn-block mt-auto" translate>Filtra</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3 mt-md-0">
                        <div class="col-12 h-100 w-100 d-flex flex-column align-items-md-end justify-content-md-end p-0">
                            <div class="text-md-right text-center mb-2" translate *ngIf="kpiPayload.dealer_id == null">Seleziona un <strong>Area Manager</strong> per assegnargli un target</div>
                            <div class="text-center">
                                <button class="btn btn-primary ml-md-auto" (click)="showTargetModal(selectedData)" [disabled]="kpiPayload.dealer_id == null">Assegna Target</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="kpiTemplate" *ngIf="!loadingKpi"></ng-container>
        <app-spinner *ngIf="loadingKpi" status="loading"></app-spinner>
    </div>

    <!-- Tab: Key Account -->
    <div id="keyAccount" *ngIf="selectedTab === 'keyAccount'" class="tab-pane fade show active">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-md-8">
                        <div class="row">
                            <div class="col-lg-4 col-md-12 mb-3 mb-md-0">
                                <label translate>Periodo</label>
                                <app-month-range-picker (selected)="setSelectedDate($event)" [singleMonthPicker]="true"></app-month-range-picker>
                            </div>
                            <div class="col-lg-4 col-md-12 mb-3 mb-md-0" *show="['operator', 'master', 'masteragent', 'submaster']">
                                <label translate>Agent</label>
                                <app-agent-select (onChange)="setScopeId($event)"></app-agent-select>
                            </div>
                            <div class="col-lg-4 col-md-12 d-md-flex justify-content-md-end">
                                <button (click)="loadTargetList()" class="btn btn-info btn-block mt-auto" translate>Filtra</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3 mt-md-0">
                        <div class="col-12 h-100 w-100 d-flex flex-column align-items-md-end justify-content-md-end p-0">
                            <div translate class="text-md-right text-center mb-2" *ngIf="kpiPayload.dealer_id == null">Seleziona un <strong>Key Account</strong> per assegnargli un target</div>
                            <div class="text-center">
                                <button class="btn btn-primary ml-md-auto" (click)="showTargetModal(selectedData)" [disabled]="kpiPayload.dealer_id == null">Assegna Target</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container [ngTemplateOutlet]="kpiTemplate" *ngIf="!loadingKpi"></ng-container>
        <app-spinner *ngIf="loadingKpi" status="loading"></app-spinner>
    </div>

    <!-- Tab: Dealers -->
    <div id="dealers" *ngIf="selectedTab === 'dealers'" class="tab-pane fade show active">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-md-9">
                        <div class="row">
                            <div class="col-lg-2 col-12 mt-auto">
                                <label translate>Periodo</label>
                                <app-month-range-picker (selected)="setSelectedDateDealersKpi($event)" [singleMonthPicker]="true"></app-month-range-picker>
                            </div>
                            <div class="col-lg-3 col-12 mt-md-0 mt-2 mt-md-auto" *show="['operator', 'master', 'masteragent', 'submaster']">
                                <label translate>Agent</label>
                                <app-agent-select (onChange)="setScopeKaKpiId($event)"></app-agent-select>
                            </div>
                            <div class="col-lg-3 col-12 mt-md-0 mt-2 mt-md-auto">
                                <label translate>
                                    Dealer
                                    <span class="text-muted" *show="['operator', 'master', 'masteragent', 'submaster']" style="font-size: smaller">(Selezionare prima un Key Account)</span>
                                </label>
                                <ng-container *show="['operator', 'master', 'masteragent', 'submaster']">
                                    <app-retailer-typeahead
                                        [style.pointer-events]="dealersKpiPayload.ka_id === null ? 'none' : 'auto'"
                                        (onChange)="dealersKpiPayload.dealer_id = $event"
                                        id="companyName"
                                    ></app-retailer-typeahead>
                                </ng-container>
                                <ng-container *show="['agent']">
                                    <app-retailer-typeahead (onChange)="dealersKpiPayload.dealer_id = $event" id="companyName"></app-retailer-typeahead>
                                </ng-container>
                            </div>
                            <div class="col-lg-2 col-12 mt-md-0 mt-2 mt-md-auto" *show="['operator', 'master', 'masteragent', 'submaster', 'agent']">
                                <label>Target Raggiunto</label>
                                <ng-select [(ngModel)]="dealersKpiPayload.is_achieved">
                                    <ng-option [value]="0">Tutti</ng-option>
                                    <ng-option [value]="1">Sì</ng-option>
                                    <ng-option [value]="2">No</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-lg-2 col-12 mt-md-0 mt-2 mt-md-auto">
                                <label>Tipo KPI</label>
                                <ng-select [(ngModel)]="dealersKpiPayload.kpi_type_id">
                                    <ng-option *ngFor="let type of kpiTypeFilters" [value]="type.type_id">{{ type.descrizione }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 mt-3 mt-md-0">
                        <div class="col-12 h-100 d-flex justify-content-md-end p-0">
                            <button (click)="loadDealersKpiList()" class="btn btn-info btn-block mt-auto" [disabled]="!dealersKpiPayload.ka_id" translate>Filtra</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container [ngTemplateOutlet]="dealersKpiTemplate" *ngIf="!loadingKpi"></ng-container>
        <app-spinner *ngIf="loadingKpi" status="loading"></app-spinner>
    </div>

    <!-- Tab: Generale -->
    <div id="generale" *ngIf="selectedTab === 'generale'" class="tab-pane fade show active">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12 col-md-8">
                        <div class="row">
                            <div class="col-lg-4 col-md-12 mb-3 mb-md-0">
                                <label translate>Periodo</label>
                                <app-month-range-picker (selected)="setSelectedDate($event)" [singleMonthPicker]="true"></app-month-range-picker>
                            </div>
                            <div class="col-lg-4 col-md-12 d-md-flex justify-content-md-end">
                                <button (click)="loadTargetList()" class="btn btn-info btn-block mt-auto" translate>Filtra</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3 mt-md-0">
                        <div class="col-12 h-100 w-100 d-flex flex-column align-items-md-end justify-content-md-end p-0" *show="['operator', 'master']">
                            <div translate colspan="6" class="text-md-right text-center mb-2">Clicca qui per creare un target</div>

                            <div class="text-center">
                                <button class="btn btn-primary ml-md-auto" (click)="showTargetModal(selectedData)">Assegna Target</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="kpiTemplate" *ngIf="!loadingKpi"></ng-container>
        <app-spinner *ngIf="loadingKpi" status="loading"></app-spinner>
    </div>
</div>

<ng-template #kpiTemplate>
    <div class="card mt-3 bg-gradient-light">
        <div class="card-body" style="min-height: 100px">
            <div class="row d-flex align-items-center">
                <div class="col-lg col-12 dashboard-card">
                    <div class="d-flex align-items-center">
                        <img class="d-inline-flex" src="assets/img/flats/euro.png" width="70px" />
                        <div class="d-block w-100 pl-3">
                            <p class="lead" translate>Premi raggiunti</p>
                            <h1 class="display-6">{{ targetList.achieved_revenue | currency : '€ ' }}</h1>
                        </div>
                    </div>
                </div>
                <div class="col-lg col-12 dashboard-card">
                    <div class="d-flex align-items-center">
                        <img class="d-inline-flex" src="assets/img/flats/money-sign.png" width="70px" />
                        <div class="d-block w-100 pl-3">
                            <p class="lead" translate>Premi raggiunti in proiezione</p>
                            <h1 class="display-6">{{ targetList.paf_revenue | currency : '€ ' }}</h1>
                        </div>
                    </div>
                </div>
                <div class="col-lg col-12 dashboard-card">
                    <div class="d-flex align-items-center">
                        <img class="d-inline-flex" src="assets/img/flats/money-loss.png" width="70px" />
                        <div class="d-block w-100 pl-3">
                            <p class="lead" translate>Totale premi disponibili</p>
                            <h1 class="display-6">{{ targetList.total_revenue | currency : '€ ' }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4" *ngFor="let kpi of targetList?.kpis">
        <div class="card-header bg-light d-flex align-items-center">
            <h4 class="mb-0">
                <strong>{{ kpi.category }}</strong>
                <small *ngIf="kpi.info" class="ml-2 text-italic text-sm">{{ kpi.info }}</small>
            </h4>
        </div>
        <div class="card-body p-0">
            <table *ngFor="let item of kpi.items" class="table">
                <tr>
                    <th width="40%">
                        <img style="height: 15px" [src]="item.type.image" alt="" />
                    </th>
                    <th width="20%" class="text-center" *ngIf="item.revenue_type === 'volume'">Obiettivo al {{ item.to | amDateFormat : 'DD/MM' }}</th>
                    <th width="20%" class="text-center" *ngIf="item.revenue_type === 'quantita'">Totale</th>
                    <th width="20%" class="text-center" *ngIf="item.revenue_type === 'volume-quantita'">Raggiunti i</th>
                    <th width="20%" class="text-center" *ngIf="item.revenue_type === 'volume'">
                        <button
                            type="button"
                            class="border-0 bg-none text-bold btn-link p-0"
                            *show="['master', 'operator']"
                            (click)="openPopover(statusPopover, item?.id, item?.revenue_type, 'revenue_type', ['volume', 'quantita'])"
                            [ngbPopover]="popContentRevenueType"
                            popoverTitle="Cambia valore"
                            autofocus
                            autoClose="outside"
                            triggers="manual"
                            #statusPopover="ngbPopover"
                        >
                            Premio
                        </button>
                        <span class="text-lg" *show="['masteragent', 'submaster', 'agent']">Premio</span>
                    </th>
                    <th width="10%" class="text-center" *ngIf="item.revenue_type === 'quantita' || item.revenue_type === 'volume-quantita'">
                        <button
                            type="button"
                            class="border-0 bg-none text-bold btn-link p-0"
                            *show="['master', 'operator']"
                            (click)="openPopover(statusPopover, item?.id, item?.revenue_type, 'revenue_type', ['volume', 'quantita', 'volume-quantita'])"
                            [ngbPopover]="popContentRevenueType"
                            popoverTitle="Cambia valore"
                            autofocus
                            autoClose="outside"
                            triggers="manual"
                            #statusPopover="ngbPopover"
                        >
                            Ogni pezzo
                        </button>
                        <span class="text-lg" *show="['masteragent', 'submaster', 'agent']">Ogni pezzo</span>
                    </th>
                    <th width="10%" class="text-center" *ngIf="item.revenue_type === 'quantita' || item.revenue_type === 'volume-quantita'">Totale</th>
                    <th width="20%" class="text-center">Raggiunto</th>
                </tr>
                <tr>
                    <td>
                        <div class="text-lg text-bold">
                            {{ item.type.title }} <span *ngIf="item.type.notes">*</span
                            ><span class="float-right" *show="['operator']"
                                ><i [swal]="deleteTargetAssociato" (confirm)="deleteTarget(item.id)" class="fas fa-times text-danger text-sm link"></i
                            ></span>
                        </div>
                        <div *ngIf="item.type.description" [innerHTML]="item.type.description"></div>
                    </td>
                    <td class="text-center" *ngIf="item.revenue_type === 'volume'">
                        <span class="text-lg"> {{ item.value }}</span> su
                        <button
                            type="button"
                            class="border-0 bg-none text-lg btn-link p-0"
                            *show="['master', 'operator']"
                            (click)="openPopover(statusPopover, item?.id, item?.target, 'target')"
                            [ngbPopover]="popContentTarget"
                            popoverTitle="Cambia valore"
                            autofocus
                            autoClose="outside"
                            triggers="manual"
                            #statusPopover="ngbPopover"
                        >
                            {{ item.target }}
                        </button>
                        <span class="text-lg" *show="['masteragent', 'submaster', 'agent']">{{ item.target }}</span>
                    </td>
                    <td class="text-center" *ngIf="item.revenue_type === 'quantita'">
                        <span class="text-lg">{{ item.value }}</span>
                    </td>
                    <td class="text-center" *ngIf="item.revenue_type === 'volume-quantita'">
                        <span class="text-lg">{{ item.target }}</span>
                    </td>
                    <td class="text-center">
                        <button
                            type="button"
                            class="border-0 bg-none text-lg btn-link p-0"
                            *show="['master', 'operator']"
                            (click)="openPopover(statusPopover, item?.id, item?.revenue, 'revenue')"
                            [ngbPopover]="popContentRevenueValue"
                            popoverTitle="Cambia valore"
                            autofocus
                            autoClose="outside"
                            triggers="manual"
                            #statusPopover="ngbPopover"
                        >
                            {{ item.revenue | currency : '€ ' }}
                        </button>
                        <span class="text-lg" *show="['masteragent', 'submaster', 'agent']">{{ item.revenue | currency : '€ ' }}</span
                        ><span class="text-sm ml-2" *ngIf="item.revenue_type === 'quantita' || item.revenue_type === 'volume-quantita'">x {{ item.value }}</span>
                    </td>
                    <td class="text-center" *ngIf="item.revenue_type === 'quantita' || item.revenue_type === 'volume-quantita'">
                        <b class="text-lg">{{ item.revenue_value | currency : '€ ' }}</b>
                    </td>
                    <td class="text-center">
                        <span class="text-success" *ngIf="item.achieved">
                            <i class="fas fa-check"></i>
                        </span>
                        <span class="text-danger" *ngIf="!item.achieved">
                            <i class="fas fa-times"></i>
                        </span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="card-footer" style="padding: 10px">
            <ul class="list-group list-group-flush">
                <ng-container *ngFor="let item of kpi.items">
                    <li class="list-group-item bg-none" style="padding-left: 0px" *ngIf="item.type.notes" [innerHTML]="item.type.notes"></li>
                </ng-container>
            </ul>
        </div>
    </div>

    <ng-container *ngIf="targetList.notes && targetList.notes.length > 0">
        <div class="card" *ngFor="let note of targetList.notes">
            <div class="card-header bg-gradient-dark text-white">
                <h2 Class="lead">Regole di Consuntivazione</h2>
            </div>
            <div class="card-body">
                <div [innerHTML]="note.description"></div>
            </div>
            <div class="card-footer">
                <p>
                    Note aggiornate al <strong>{{ note.created_at | date : 'dd/MM/yyyy - HH:mm:ss' }}</strong>
                </p>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dealersKpiTemplate>
    <div class="card" *ngIf="kpisList">
        <div class="card-body table-responsive p-0">
            <table class="table table-sm table-striped">
                <thead>
                    <tr>
                        <th>Dealer</th>
                        <ng-container *ngFor="let kpi of kpisList.kpi">
                            <th class="text-center bg-secondary border border-2" style="color: white">{{ kpi.descrizione }}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let dealer of kpisList.dealers">
                        <tr class="border border-2">
                            <td class="border border-2">
                                <a [routerLink]="['/dealer', dealer.id]" target="_blank">
                                    <span>{{ dealer.ragsociale }}</span>
                                </a>
                            </td>
                            <ng-container *ngFor="let kpi of kpisList.kpi">
                                <td class="font-weight-bold text-center text-lg border border-2">
                                    <ng-container *ngIf="hasKpi(dealer, kpi)">
                                        <ng-container *ngIf="getKpiTarget(dealer, kpi) > 1">
                                            <div>
                                                <span
                                                    [ngClass]="{
                                                        'text-danger': getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi) <= 0.4,
                                                        'text-warning': getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi) > 0.4 && getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi) < 1,
                                                        'text-success': getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi) >= 1
                                                    }"
                                                >
                                                    {{ getKpiValue(dealer, kpi) }}</span
                                                >
                                                <span class="text-sm font-weight-normal text-dark"> su </span>
                                                <span class="text-secondary">{{ getKpiTarget(dealer, kpi) }}</span>
                                            </div>
                                            <div class="progress progress-xs rounded-lg">
                                                <div
                                                    class="progress-bar rounded-lg"
                                                    role="progressbar"
                                                    [style.width.%]="(getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi)) * 100"
                                                    [attr.aria-valuenow]="getKpiValue(dealer, kpi)"
                                                    [attr.aria-valuemin]="0"
                                                    [attr.aria-valuemax]="getKpiTarget(dealer, kpi)"
                                                    [ngClass]="{
                                                        'bg-danger': getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi) <= 0.4,
                                                        'bg-warning': getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi) > 0.4 && getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi) < 1,
                                                        'bg-success': getKpiValue(dealer, kpi) / getKpiTarget(dealer, kpi) >= 1,
                                                        'fill-animation': getKpiValue(dealer, kpi) > 0
                                                    }"
                                                ></div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="getKpiTarget(dealer, kpi) == 1">
                                            <span *ngIf="getKpiValue(dealer, kpi) < getKpiTarget(dealer, kpi)" class="text-danger">
                                                <i class="fa fa-times m-0 px-1"></i>
                                            </span>
                                            <span *ngIf="getKpiValue(dealer, kpi) === getKpiTarget(dealer, kpi)" class="text-success">
                                                <i class="fa fa-check m-0 px-1"></i>
                                            </span>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!hasKpi(dealer, kpi)">
                                        <span class="text-sm text-secondary font-weight-normal">-</span>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
            <span
                ><span>Ci sono </span><b>{{ this.kpiPagination.totalCount }} </b>{{ this.kpiPagination.totalCount <= 1 ? 'target' : 'targets' }}.<a class="link" (click)="exportDealersTarget()">
                    Esporta la lista completa</a
                ></span
            >
            <!-- <ngb-pagination
        *ngIf="kpiPagination.pageCount > 20"
        size="sm"
        [pageSize]="kpiPagination.perPage"
        [maxSize]="15"
        [(page)]="kpiPagination.currentPage"
        [collectionSize]="kpiPagination.totalCount"
        (pageChange)="loadDealersKpiList()"
        [boundaryLinks]="true"
      >
      </ngb-pagination> -->
        </div>
    </div>
    <ng-container *show="['operator', 'master', 'masteragent', 'submaster']">
        <div *ngIf="!kpisList">
            <p class="text-center">Selezionare un Key Account per visualizzare i dati</p>
        </div>
    </ng-container>
    <ng-container *show="['agent']">
        <div *ngIf="!kpisList">
            <p class="text-center">Nessun dato disponibile</p>
        </div>
    </ng-container>
</ng-template>

<ng-template #assegnaTargetModal let-modal>
    <div class="modal-header">
        <h4>ASSEGNA UN TARGET</h4>
        <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="form-group">
                <label translate>Seleziona un KPI</label>
                <app-kpi-select (onChange)="setScopeIdKpi($event)"></app-kpi-select>
            </div>
            <div class="form-group">
                <label for="target">Inserisci un Target</label>
                <input type="number" class="form-control" [(ngModel)]="kpiPayload.target" />
            </div>
            <div class="form-group">
                <label for="from">Da</label>
                <input type="date" class="form-control" [(ngModel)]="kpiPayload.from" />
            </div>
            <div class="form-group">
                <label for="to">A</label>
                <input type="date" class="form-control" [(ngModel)]="kpiPayload.to" />
            </div>
            <div class="form-group">
                <label for="revenue">Guadagno</label>
                <input type="number" class="form-control" [(ngModel)]="kpiPayload.revenue" />
            </div>
            <div class="form-group">
                <label for="revenue_type">Tipo Guadagno</label>
                <select class="form-control" [(ngModel)]="kpiPayload.revenue_type">
                    <option value="volume">Volume</option>
                    <option value="quantita">Quantita</option>
                    <option value="volume-quantita">Quantità a target</option>
                </select>
            </div>
            <button
                (click)="assegnaTarget()"
                class="btn btn-primary"
                [disabled]="!kpiPayload.target || !kpiPayload.type_id || !kpiPayload.from || !kpiPayload.to || !kpiPayload.revenue_type || isLoading"
            >
                <span *ngIf="isLoading">
                    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple"></ngx-spinner>
                </span>
                Assegna Target
            </button>
        </div>
    </div>
</ng-template>

<ng-template #excelModal let-modal>
    <div class="modal-header">
        <h4>Scarica Excel</h4>
        <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label>Tipo</label>
                    <select class="form-control" [(ngModel)]="excelType">
                        <option value="keyaccount">Key Account</option>
                        <option value="areamanager">Area Manager</option>
                        <option value="dealer">Tutti i dealers</option>
                        <option value="dealer_kena">Dealers Kena</option>
                        <option value="dealer_a2a">Dealers A2A</option>
                        <option value="dealer_tim">Dealers Tim</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p class="text-bold">
                    KPI <span class="text-sm link" (click)="selectAllKpiTypes()">Seleziona tutto</span> o <span class="text-sm link" (click)="deselectAllKpiTypes()">Deseleziona tutto</span>
                </p>
            </div>
            <div class="col-3">
                <p class="text-bold">Premio</p>
            </div>
            <div class="col-3">
                <p class="text-bold">Tipo</p>
            </div>
        </div>
        <ng-container *ngFor="let group of kpiTypes | keys">
            <p class="display-6">{{ group }}</p>
            <div class="row my-2" *ngFor="let type of kpiTypes[group]">
                <div class="col-6">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" [id]="type.slug" [(ngModel)]="type.checked" />
                        <label class="custom-control-label" [for]="type.slug">{{ type.title }}</label>
                    </div>
                </div>
                <div class="col-3">
                    <input class="form-control form-control-sm" [(ngModel)]="type.revenue" [disabled]="!type.checked" />
                </div>
                <div class="col-3">
                    <select class="form-control form-control-sm" [(ngModel)]="type.revenue_type" [disabled]="!type.checked">
                        <option value="volume">Volume</option>
                        <option value="quantita">Quantita</option>
                        <option value="volume-quantita">Quantita a target</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" [disabled]="downloadingExcel" (click)="downloadExcel()">Scarica</button>
    </div>
</ng-template>

<ng-template #uploadExcelModal let-modal>
    <div class="modal-header">
        <h4>Carica Target con Excel</h4>
        <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group" *ngIf="!uploadingExcel && uploadResult.length === 0">
            <div class="col-lg-4 pl-0 col-12">
                <label translate>Periodo</label>
                <app-month-range-picker (selected)="setSelectedUploadDate($event)" [singleMonthPicker]="true"></app-month-range-picker>
            </div>

            <label for="uploadExcel" translate>Excel</label>
            <div class="custom-file">
                <input id="uploadExcel" type="file" class="custom-file-input" (change)="handleFileInput($event?.target?.files[0])" />
                <label class="custom-file-label" for="uploadExcel" translate> Scegli file... </label>
            </div>
        </div>
        <div class="form-group" *ngIf="!uploadingExcel && uploadResult.length > 0">
            <div class="alert alert-danger" *ngFor="let error of uploadResult">
                <p>Ci sono errori per l'id {{ error.id }}:</p>
                <ul>
                    <li *ngFor="let message of error.errors">
                        {{ message }}
                    </li>
                    <li>
                        {{ error.kpi | json }}
                    </li>
                </ul>
            </div>
        </div>
        <app-spinner *ngIf="uploadingExcel" status="loading"></app-spinner>
    </div>
</ng-template>

<ng-template #popContentTarget>
    <app-edit-popup [config]="popupConfig" (onSave)="saveTarget($event)"></app-edit-popup>
</ng-template>

<ng-template #popContentRevenueType>
    <app-edit-popup [config]="popupConfig" (onSave)="saveRevenueType($event)"></app-edit-popup>
</ng-template>

<ng-template #popContentRevenueValue>
    <app-edit-popup [config]="popupConfig" (onSave)="saveRevenueValue($event)"></app-edit-popup>
</ng-template>
