import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class VisureService {
  
    constructor(
        private request: RequestService, 
        public alertService: AlertService
    ){}

    public getVisureCameraliList(params?): Observable<any> {
        return this.request.get('v3/visure-camerali/list', params);
    }

    public getVisureCatastaliList(params?): Observable<any> {
        return this.request.get('v3/visure-catastali/list', params);
    }

}  