import { Component, OnInit, ViewChild } from '@angular/core';
import {KdOrderService} from '@services/kd-order.service';
import { 
  DigitalSigniture,
  SpidCredit,
  SpidCreditPagination,
  SpidCreditReport
} from '@app/models/kd-orders';
import { MetaPagination } from '@models/meta-pagination';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-spid-credit',
  templateUrl: './spid-credit.component.html',
  styleUrls: ['./spid-credit.component.css']
})
export class SpidCreditComponent implements OnInit {

  dealers: SpidCredit[] = [];
  credits: SpidCredit[] = [];
  report: SpidCreditReport;
  pagination: MetaPagination;
  // localFormat = KdOrderService.datetimeLocalFormat;
  deleteCreditOptions: ConfirmOptionsService;
  selectedCredit: SpidCredit;
  transactionModal: NgbModalRef;
  transactionBusy: any;
  @ViewChild('transactionTemplate') private transactionTemplate;
  @ViewChild('detailTemplate') private detailTemplate;
  transactionForm = new FormGroup({
    amount: new FormControl('', Validators.required),
    note: new FormControl('')
  });
  
  dealerId = null;
  filterDate = '';
  apiDate = {
      from: null,
      to: null
  };


  constructor(
    private spidCreditService : KdOrderService,
    private spinner: NgxSpinnerService,
    private dateRangePickerOptions: DaterangepickerConfig,
    public route: ActivatedRoute,
    public modalService: NgbModal,
  ) {
    this.dealerId = this.route.snapshot.paramMap.get('id');
    this.deleteCreditOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro!',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla'
    });
  }

  ngOnInit(): void {
    this.loadReport(this.dealerId);
    if (this.dealerId) {
        this.loadTransactions();
    } else {
        this.loadCredit();
      }
  }

  showModal(credit?: SpidCredit) {
    this.selectedCredit = credit;
    this.transactionForm.setValue({
        amount: credit ? Number(credit?.amount) : '',
        note: ''
    });
    this.transactionModal = this.modalService.open(this.transactionTemplate, {size: 'md'});
    this.transactionModal.result.then((res) => {
    }).catch((res) => {
    });
  }

  showDetailModal(credit?: SpidCredit) {
    this.selectedCredit = credit;
    this.modalService.open(this.detailTemplate, {size: 'md'});
  }

  loadCredit(dealerId?){
    this.spinner.show('table-credit');
    const params = {};
    if (this.pagination?.currentPage) {
        params['page'] = this.pagination?.currentPage;
    }
    if (dealerId) {
        params['dealer_id'] = dealerId;
    }
    this.spidCreditService.getSpidCredit(params).subscribe((response: SpidCreditPagination) => {
        this.dealers = response.data;
        this.pagination = response._meta;
        this.spinner.hide('table-credit');
    }, err => {
        this.spinner.hide('table-credit');
    });

  }

  loadReport(dealerId?) {
    this.spinner.show('report');
    const params = {};
    if (this.pagination?.currentPage) {
        params['page'] = this.pagination?.currentPage;
    }
    if (dealerId) {
        params['dealer_id'] = dealerId;
    }
    this.spidCreditService.getCreditReport(params).subscribe((response: SpidCreditReport) => {
        this.report = response;
        this.spinner.hide('report');
    }, err => {
        this.spinner.hide('report');
    });
  }

  loadData(dealerId?) {
    this.loadReport(dealerId);
    setTimeout(() => {
        this.loadCredit(dealerId);
    });
  }


  loadTransactions() {
    this.spinner.show('table-credit');
    const params = {
        dealer_id: this.dealerId
    };
    if (this.pagination?.currentPage) {
        params['page'] = this.pagination?.currentPage;
    }
    if (this.apiDate?.from && this.apiDate?.to) {
        params['from'] = this.apiDate?.from;
        params['to'] = this.apiDate?.to;
    }
    this.spidCreditService.getCredits(params).subscribe((response: SpidCreditPagination) => {
        this.credits = response.data;
        this.pagination = response._meta;
        this.spinner.hide('table-credit');
    }, error => {
        this.spinner.hide('table-credit');
    });
  }

  removeTransaction(id: number) {
    this.spidCreditService.deleteCredit(id).subscribe(() => {
        this.loadTransactions();
        this.spidCreditService.successAlert('Removed Transaction!');
    }, (err) => {
        this.spidCreditService.errorAlert(err);
    });
}

  saveTransaction() {
    const credit = new SpidCredit();
    credit.amount = this.transactionForm.value.amount;
    credit.dealer_id = this.dealerId;
    credit.note = this.transactionForm.value.note;
    this.transactionBusy = this.changeTransaction(credit).subscribe((response: SpidCredit) => {
        this.loadTransactions();
        this.transactionForm.reset();
        this.spidCreditService.successAlert('Changed Transaction!');
        this.modalService.dismissAll();
    }, err => {
        this.transactionForm.reset();
        this.spidCreditService.errorAlert(err);
        this.modalService.dismissAll();
    });
  }

  changeTransaction(credit: SpidCredit) {
    return this.selectedCredit ? this.spidCreditService.putDealerCredits(this.selectedCredit?.id, credit)
        : this.spidCreditService.postDealerCredits(credit);
}

  

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filterDate = startDate.format(localDateFormat)
        + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    return this.loadTransactions();
  }

  clearDateFiler() {
    this.filterDate = null;
    this.apiDate.from = null;
    this.apiDate.to = null;
    return this.loadTransactions();
  }





}

  



