import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Dealer } from '@app/models/dealer';
import { RequestService } from '@services/request.service';

@Component({
    selector: 'app-negozio-select',
    templateUrl: './negozio-select.component.html',
    styleUrls: ['./negozio-select.component.css'],
})
export class NegozioSelectComponent implements OnInit {
    data = [];

    @Input()
    model: any;
    @Input()
    small = false;

    @Input()
    bindLabel = 'company_name';

    @Input()
    disabled = false;

    @Output() isValid = new EventEmitter();
    @Output() onChange = new EventEmitter();

    form: FormGroup;

    loading = false;

    constructor(private requestService: RequestService) {}

    ngOnInit(): void {
        this.loading = true;
        this.requestService.get('v3/dealers/select-list').subscribe((data) => {
            this.data = new Dealer().fromArray(data);
            this.loading = false;
        });

        // this.isValid.emit();
    }

    public reset() {
        this.model = null;
    }
}
