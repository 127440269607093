import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { A2aContratti } from '@app/models/a2a';
import { MetaPagination } from '@app/models/meta-pagination';
import { User } from '@app/models/user';
import { A2aService } from '@app/services/a2a.service';
import { AlertService } from '@app/services/alert.service';
import { AuthenticationService } from '@app/services/authentication.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-a2a-contratti',
  templateUrl: './a2a-contratti.component.html',
  styleUrls: ['./a2a-contratti.component.css'],
})
export class A2aContrattiComponent implements OnInit {
  ordersPagination: MetaPagination = new MetaPagination();

  a2aList: A2aContratti[] = [];
  isDealer = false;

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  apiDate = {
    from: '',
    to: '',
  };

  filter = {
    company_name: '',
    dealer_id: '',
    product: '',
    nome_opportunita: '',
    date: '',
    id_agente: '',
    id_masteragente: '',
    fase: '',
    data_sottoscrizione_ordine: '',
    stato: '',
  };

  newDealerId = null;

  constructor(
    private spinner: NgxSpinnerService,
    private A2aService: A2aService,
    private authenticationService: AuthenticationService,
    private dateRangePickerOptions: DaterangepickerConfig,
    public route: ActivatedRoute,
    private alertService: AlertService,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.generateDateRangeString(moment().startOf('month'), moment());
    this.loadList();
  }

  loadList(hideSpinner = false) {
    this.spinner.show('table-orders');
    const params = {};

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.id_agente) {
      params['id_agente'] = this.filter.id_agente;
    }
    if (this.filter.id_masteragente) {
      params['id_masteragente'] = this.filter.id_masteragente;
    }
    if (this.filter.stato) {
      params['stato'] = this.filter.stato;
    }
    if (this.filter.fase) {
      params['fase'] = this.filter.fase;
    }
    if (this.filter.product) {
      params['product'] = this.filter.product;
    }
    if (this.filter.nome_opportunita) {
      params['nome_opportunita'] = this.filter.nome_opportunita;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }
    if (this.ordersPagination.currentPage) {
      params['page'] = this.ordersPagination.currentPage;
    }

    this.A2aService.getA2aList(params).subscribe(
      (response: any) => {
        this.a2aList = response.data.map((item) => new A2aContratti(item));
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        console.error(err);
        this.spinner.hide('table-orders');
      },
    );
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  clearFase() {
    this.filter.fase = null;
  }

  updateDealer(orderId) {
    const body = {};
    body['dealer_id'] = this.newDealerId;
    this.spinner.show('updateSpinner');
    this.A2aService.putDealerIdA2aOrder(orderId, body).subscribe(
      (response) => {
        this.spinner.hide('updateSpinner');

        this.alertService.show('Operazione completata', 'Ordine aggiornato con successo!', { classname: 'bg-success text-light', delay: 2000 });
        this.loadList();
      },
      (err) => {
        this.spinner.hide('updateSpinner');
        this.alertService.show('Errore', `Non è stato possibile aggiornare l\'ordine, a causa del seguente errore: ${err}`, { classname: 'bg-danger text-light', delay: 2000 });
        this.loadList();
      },
    );
  }

  isNewDealerIdVisible(id_operatore: string): boolean {
    return !id_operatore.startsWith('MG');
  }

  exportA2aList() {
    this.spinner.show('table-orders');
    const params = {
      'per-page': 0,
    };

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }

    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }

    if (this.filter.id_agente) {
      params['id_agente'] = this.filter.id_agente;
    }

    if (this.filter.id_masteragente) {
      params['id_masteragente'] = this.filter.id_masteragente;
    }

    if (this.filter.stato) {
      params['stato'] = this.filter.stato;
    }

    if (this.filter.product) {
      params['product'] = this.filter.product;
    }

    if (this.filter.nome_opportunita) {
      params['nome_opportunita'] = this.filter.nome_opportunita;
    }

    if (this.filter.fase) {
      params['fase'] = this.filter.fase;
    }

    if (this.filter.data_sottoscrizione_ordine) {
      params['data_sottoscrizione_ordine'] = this.filter.data_sottoscrizione_ordine;
    }

    if (this.filter.stato) {
      params['stato'] = this.filter.stato;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.A2aService.getA2aList(params).subscribe((data) => {
      let rows = data.data;

      let output = rows.map((row) => {
        return {
          id: row.id,
          company_name: row.company_name,
          dealer_id: row.dealer_id,
          id_agente: row.id_agente,
          ragsociale_agente: row.ragsociale_agente,
          id_masteragente: row.id_masteragente,
          ragsociale_masteragente: row.ragsociale_masteragente,
          product: row.product,
          sepa:row.sepa,
          nome_opportunita: row.nome_opportunita,
          account_personale_provincia: row.account_personale_provincia,
          codice_mgm_univoco: row.codice_mgm_univoco,
          mercato_di_provenienza: row.mercato_di_provenienza,
          commodity: row.commodity,
          sottocanale: row.sottocanale,
          causale_annullamento: row.causale_annullamento,
          causale_annullamento_estesa: row.causale_annullamento_estesa,
          data_annullamento: row.data_annullamento,
          canale_vendita: row.canale_vendita,
          codice_pdv: row.codice_pdv,
          fase: row.fase,
          esito_ammissibilita_richiesta: row.esito_ammissibilita_richiesta,
          data_sottoscrizione_ordine: row.data_sottoscrizione_ordine,
          modalita_vendita: row.modalita_vendita,
          nome_account: row.nome_account,
          operatore: row.operatore,
          pod_pdr: row.pod_pdr,
          processo: row.processo,
          stato: row.stato,
          stato_blacklist: row.stato_blacklist,
          stato_credit_check: row.stato_credit_check,
          stato_validazione: row.stato_validazione,
          validity_start_date: row.validity_start_date,
          activation_date:row.activation_date,
          tipo_account: row.tipo_account,
        };
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-a2a-contratti.xlsx');
      this.spinner.hide('table-orders');
    });
  }
}
