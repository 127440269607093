<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadList()">
        <div class="form-row">
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                                            id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Richiedente</label>
                    <input [(ngModel)]="filter.denominazione" name="denominazione" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Id transazione</label>
                    <input [(ngModel)]="filter.transaction_id" name="transaction_id" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date Range</label>
                    <input id="documentDate" type="text" daterangepicker autocomplete="off"  [options]="pickerOptions"
                    [(ngModel)]="filter.date" name="date" class="form-control"
                    (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Tipo visura</label>
                    <ng-select [clearable]="true" class="disableClear" [(ngModel)]="filter.tipo_visura"
                               [items]="tipoVisuraLabel" bindLabel="lable"
                               name="subscribed" id="subscribed"
                               bindValue="key" [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block form-control" translate type="submit">Filter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>VISURE CATASTALI</h4>
    </div>
    <div class="card-body p-0 rounted-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
        <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th translate>Company name</th>
                    <th translate>Data</th>
                    <th translate>Richiedente</th>
                    <th translate>Tipo di visura</th>
                    <th translate>Id transazione</th>
                    <th translate class="text-center">Status</th>
                </tr>
            </thead>
            <tbody class="bg-gradiend-white">
            <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="orders"
                [fullScreen]="false"
            >
            <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngIf="orders.length==0">
                <td translate colspan="6" class="text-center">List is empty</td>
            <tr *ngFor="let item of orders">
                <td>
                    <a [routerLink]="['/dealer', item.dealer_id]" target="_blank">{{item.company_name}}</a>
                </td>
                <td>        
                    {{item?.created_at | amDateFormat: 'DD/MM/YYYY - HH:mm' }}
                </td>
                <td>
                    {{item?.denominazione}}
                </td>
                <td>
                    {{item?.tipoVisura}}
                </td>
                <td>
                    {{item?.transaction_id}}
                </td>
                <td class="text-center">

                    <span 
                        class="badge py-1 px-2 badge-info"
                        *ngIf="item?.status==statuses[0].key" translate>{{statuses[0].label}}
                    </span>
                    <span 
                        class="badge py-1 px-2 text-white badge-warning" 
                        *ngIf="item?.status==statuses[1].key"translate>{{statuses[1].label}}
                    </span>
                    <span 
                        class="badge py-1 px-2 badge-success" 
                        *ngIf="item?.status==statuses[2].key" translate>{{statuses[2].label}}
                    </span>
                </td>
            </tr>
            </tbody>  
        </table> 
    </div>
    <div class="card-footer">
        <span><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b>{{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}.<a class="link" (click)="exportVisureCamerali()"> Esporta la lista completa</a></span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadList()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>