import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
    @Input()
    status: string = 'hidden';
    @Input()
    message: string = "Riprova, se il problema persiste contatta l'assistenza";

    @Input()
    dark = false;

    constructor() {

    }

    ngOnInit() {

    }
}
