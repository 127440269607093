<div class="card bg-gradient-dark">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img
                class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1"
                width="160px"
                src="assets/img/flats/gear.png"
            />
            <div class="d-block w-100 pl-lg-3">
                <h1 class="display-4" translate>Gestione Mail Automatiche</h1>
                <hr />
                <form (ngSubmit)="loadMailList()">
                    <div class="form-row">
                        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
                            <div class="form-group">
                                <label>Nome Mail</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="filters.nome"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
                            <div class="form-group">
                                <label>Slug</label>
                                <ng-select
                                    class="form-control-md"
                                    [(ngModel)]="filters.slug"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                    <ng-option *ngFor="let slug of slugList" [value]="slug">{{ slug }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
                            <div class="form-group">
                                <label translate>Tipo Destinatario</label>
                                <ng-select
                                    [items]="destinatario"
                                    name="subscribed"
                                    bindLabel="name"
                                    bindValue="key"
                                    [(ngModel)]="selectedDest"
                                    [searchable]="true"
                                    (change)="onTypeDestChange($event)"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
                            <div class="form-group">
                                <label class="lead mb-1">Operatori</label>
                                <app-vendor-select (onChange)="onOperatorChange($event)"></app-vendor-select>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
                            <div class="form-group">
                                <label>Data</label>
                                <input
                                    id="documentDate"
                                    type="text"
                                    daterangepicker
                                    autocomplete="off"
                                    [options]="pickerOptions"
                                    [(ngModel)]="filters.date"
                                    name="date"
                                    class="form-control"
                                    (selected)="generateDateRangeString($event.start, $event.end)"
                                />
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12">
                            <label></label>
                            <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <h4 class="mb-0" translate>Liste Mail</h4>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-success" (click)="showModal()"><i class="fa fa-plus mr-2"></i>Crea Mail</button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th style="width: 5%" translate>ID</th>
                    <th style="width: 15%" translate>Nome Mail</th>
                    <th style="width: 15%" translate>Slug</th>
                    <th style="width: 20%" translate>Tipo Destinatario</th>
                    <th style="width: 15%" translate>Operatori</th>
                    <th style="width: 15%" translate>Date</th>
                    <th class="text-center" style="width: 5%">Log</th>
                    <th style="width: 5%">Dettagli</th>
                    <th style="width: 5%"></th>
                </tr>
            </thead>
            <tbody class="bg-gradient-white">
                <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <tr *ngFor="let item of mailList">
                    <td>
                        {{ item.id }}
                    </td>
                    <td>
                        {{ item.nome }}
                    </td>
                    <td>
                        {{ item.slug }}
                    </td>
                    <td>
                        <span
                            class="badge badge-pill mr-1"
                            *ngFor="let dest of item?.destinatari"
                            [ngClass]="{
                                'badge-info': dest.ruolo === 'masterAgent',
                                'badge-primary': dest.ruolo === 'keyAccount',
                                'badge-warning': dest.ruolo === 'subMaster',
                                'badge-success': dest.ruolo === 'dealer'
                            }"
                        >
                            {{ dizionarioRuoli[dest.ruolo] }}
                        </span>
                    </td>
                    <td>
                        <img
                            *ngFor="let img of item?.loghi_operatori"
                            src="assets/img/small_brands/{{ img.nome }}.png"
                            class="mr-1"
                            height="18px"
                        />
                    </td>
                    <td>
                        {{ item?.created_at | date : 'dd/MM/yyyy HH:MM:ss' }}
                    </td>
                    <td class="text-center">
                        <button class="btn btn-link" (click)="watchLog(item)">
                            <i class="fa fa-info-circle text-secondary"></i>
                        </button>
                    </td>
                    <td class="text-center">
                        <button class="btn btn-link" (click)="watchDetail(item)">
                            <i class="fa fa-eye text-info"></i>
                        </button>
                    </td>
                    <td>
                        <button
                            type="button"
                            class="close text-danger"
                            aria-label="Close"
                            [swal]="deleteSubscriptionOptions"
                            *ngIf="isEdit"
                            (confirm)="deleteMail(item.id)"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="mailList?.length <= 0">
                    <td class="text-muted text-center" colspan="9" translate>List is empty</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="mailList?.length > 0">
        <span
            ><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
            {{ this.ordersPagination.totalCount <= 1 ? 'Lista' : ('Liste' | translate) }}.
            <a class="link">Esporta la lista completa</a>
        </span>
        <ngb-pagination
            *ngIf="ordersPagination.pageCount > 1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadMailList()"
            [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<!-- MODALE -->

<ng-template #mailModalTemplate let-modal>
    <div class="modal-header bg-gradient-dark">
        <h4 class="modal-title clamp-title" translate>{{ 'Crea una Mail' }}</h4>
        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form (ngSubmit)="save()" [formGroup]="salvaMail">
            <div class="mt-2 mb-0 row">
                <div class="form-group col-md-6">
                    <label class="lead mb-1">Nome Mail</label>
                    <input formControlName="nome" id="nomeMail" type="text" class="form-control" />
                    <val-errors controlName="nomeMail">
                        <ng-template valError="required"> Il nome della mail è obbligatorio </ng-template>
                    </val-errors>
                </div>

                <div class="form-group col-md-6">
                    <label class="lead mb-1">Slug</label>
                    <input formControlName="slug" id="slug" class="form-control" />
                    <val-errors controlName="slug">
                        <ng-template valError="required"> Lo slug della mail è obbligatorio </ng-template>
                    </val-errors>
                </div>

                <div class="form-group col-md-6">
                    <label class="lead mb-1">Destinatari</label>
                    <ng-select
                        [clearable]="true"
                        [multiple]="true"
                        class="disableClear"
                        name="subscribed"
                        id="subscribed"
                        [searchable]="true"
                        formControlName="tipo_destinatario"
                        (change)="setDestinatari()"
                    >
                        <ng-option value="masterAgent">Area Manager</ng-option>
                        <ng-option value="keyAccount">Key Account</ng-option>
                        <ng-option value="subMaster">Direttore Commerciale</ng-option>
                        <ng-option value="dealer">Dealer</ng-option>
                    </ng-select>
                    <val-errors controlName="tipo_destinatario">
                        <ng-template valError="required"> Destinatari richiesti </ng-template>
                    </val-errors>
                </div>

                <div class="form-group col-md-6">
                    <label class="lead mb-1">Operatori</label>
                    <app-vendor-select (onChange)="setOperatori($event)" [multiple]="true"></app-vendor-select>
                    <val-errors>
                        <ng-template valError="required">
                            <span translate>Operator is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="form-group col-12 mb-0">
                    <button class="btn btn-secondary btn-block my-2" type="submit" [disabled]="!salvaMail.valid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
