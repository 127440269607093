import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AuthenticationService} from '@app/services/authentication.service';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {DashboardService} from '@services/dashboard.service';
import {environment} from '@environments/environment';

import * as $ from 'jquery';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
    public searchForm: FormGroup;
    dealer: any;
    agente: any;
    searching = false;
    searchFailed = false;
    whatsAppUrl = environment.whatsAppUrl;
    telegramUrl = environment.telegramUrl;

    constructor(
        public router: Router,
        public authenticationService: AuthenticationService,
        public dashboardService: DashboardService,
    ) {
    }

    ngOnInit() {
        this.searchForm = new FormGroup({
            search: new FormControl(null)
        });
    }

    formatter = (x: any) => {
        this.router.navigate(['dealer', x.id]);
        return x.ragsociale;
    };

    formatterAgente = (x: any) => {
        this.router.navigate(['agente', x.id]);
        return x.ragsociale;
    };

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
                this.dashboardService.get(`/cerca-negozio?q=${term}`).pipe(
                    tap(() => this.searchFailed = false),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    }))
            ),
            tap(() => this.searching = false)
        );

    searchAgente = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
                this.dashboardService.get(`/cerca-agente?q=${term}`).pipe(
                    tap(() => this.searchFailed = false),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    }))
            ),
            tap(() => this.searching = false)
        );

    toggle(): void {
        const portalBody = $('#portalBody');
        if (portalBody.hasClass('sidebar-collapse')) {
            portalBody.removeClass('sidebar-collapse');
            portalBody.addClass('sidebar-open');
        } else {
            portalBody.addClass('sidebar-collapse');
        }
    }
}
