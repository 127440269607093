import {Component, OnInit} from '@angular/core';
import {InvoiceService} from '@services/invoice.service';
import * as fileSaver from 'file-saver';
import {AlertService} from '@services/alert.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-invoice-upload',
    templateUrl: './invoice-upload.component.html',
    styleUrls: ['./invoice-upload.component.css']
})
export class InvoiceUploadComponent implements OnInit {
    loading = false;
    launchKey = null;

    description: string = null;
    loadingGroups = false;
    groups = [];
    file = null;

    constructor(private invoiceService: InvoiceService,
                private toastrService: ToastrService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    downloadTemplate() {
        this.invoiceService.getTemplateProForma()
            .subscribe((buffer: any) => {
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                fileSaver.saveAs(data, 'Template_Fatture_' + new Date().getTime() + '.xlsx');
            });
    }

    loadTemplate(file: any) {
        this.file = file;
    }

    sendInvito() {
        const formData = new FormData();
        formData.append('name', this.description);
        formData.append('template', this.file);

        this.loading = true;
        this.invoiceService
            .postPreviewTemplate(formData)
            .subscribe((data: any) => {
                this.launchKey = data;
                this.loading = false;
                this.refresh();
            }, (error) => {
                this.loading = false;
                this.toastrService.error(error, 'Errore nell\'upload');
            });
    }

    refresh() {
        this.loadingGroups = true;
        this.invoiceService.listInvoiceGroups()
            .subscribe((data) => {
                this.groups = data;
                this.loadingGroups = false;
            });
    }

    unlock(id) {
        this.loadingGroups = true;
        this.invoiceService.unlockGroup(id)
            .subscribe((data) => {
                this.refresh();
            });
    }

    block(id) {
        this.loadingGroups = true;
        this.invoiceService.blockGroup(id)
            .subscribe((data) => {
                this.refresh();
            });
    }
}
