<h4>Filtri</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4" [ngBusy]="busy">
    <div class="row">
        <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <label for="dealer" class="" translate>Dealer</label>
                <app-retailer-typeahead (onChange)="handleDealerSelectedModal($event)"></app-retailer-typeahead>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <label for="operator" class="" translate>Operatore</label>
                <ng-select [items]="filterOperators" bindValue="id"
                           bindLabel="company_name" [(ngModel)]="filterForm.operator"></ng-select>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <label translate>Date</label>
                <input class="form-control" #availabilityRangePicker type="text" [(ngModel)]="filterForm.date"
                       daterangepicker [options]="filterDaterangePickerOptions"
                       (selected)="setSelectedDateRange($event.start, $event.end)"/>
            </div>
        </div>
        <div class="col-xl-2 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <label for="operator" class="" translate>Stato</label>
                <ng-select [items]="selectToAffilation" bindValue="key" bindLabel="label"
                           [(ngModel)]="filterForm.state"></ng-select>
            </div>
        </div>
        <div class="col-xl-1 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <button class="btn btn-info btn-block" (click)="filterAffiliations()" translate>Filter
                </button>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info" role="alert" *ngIf="showAlertMessage(affiliationItems)">
    {{emptyAffiliationsListMessage}}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="row pt-4 position-relative" *ngIf="affiliationItems?.in_revision?.data?.length>0">
    <ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, .7)" color="#333333" [name]="inRevisionSpinnerName"
                 class="h-100 w-100"
                 type="ball-clip-rotate-multiple"
                 size="medium">
    </ngx-spinner>
    <div class="col-12 mb-3">
        <h4 translate>Lista affiliazioni in revisione</h4>
    </div>
    <div class="col-md-12"
         *ngFor="let affiliationInRevision of affiliationItems?.in_revision?.data; index as i">
        <div class="box box-widget">
            <div class="box-header with-border d-lg-flex align-items-center">
                <div class="user-block">
                    <img class="img-circle" src="https://picsum.photos/200/300" alt="User Image">
                    <span class="username"><a href="#"
                                              class="text-capitalize">{{affiliationInRevision?.agent?.first_name}}
                        {{affiliationInRevision?.agent?.last_name}}</a></span>
                    <span class="description"><span
                            translate>Creato il</span> {{affiliationInRevision?.created_at | amDateFormat:createDateFormat}}</span>
                </div>
                <div class="d-flex mr-5">
                    <button class="btn btn-xs btn-success mr-2"
                            *show="['operator']"
                            [swal]="approveAffiliationOptions"
                            (confirm)="approveAffiliation(affiliationInRevision)" translate>
                        Approva
                    </button>
                    <button class="btn btn-xs btn-warning mr-2" [swal]="rejectAffiliationOptions"
                            (confirm)="rejectAffiliation(affiliationInRevision, $event)"
                            *show="['operator']" translate>
                        Rifiuta
                    </button>
<!--                    <button class="btn btn-info btn-xs btn-block mx-2" [swal]="rejectAffiliationOptions"-->
<!--                            (confirm)="rejectAffiliation(affiliationInRevision, $event)"-->
<!--                            *show="['operator']" translate>-->
<!--                        Nota-->
<!--                    </button>-->
                    <button class="btn btn-xs btn-light mr-2" data-widget=""
                            *show="['agent']" (click)="selectAffilation(affiliationInRevision)" translate>
                        Aggiorna
                    </button>
                </div>
                <div class="box-tools my-auto">
                    <button *ngIf="!show[i]" class="btn btn-link ml-3 pt-1 px-0" (click)="toggleShow(!show[i], i)">
                        <i class="fa fa-plus text-muted"></i>
                    </button>
                    <button *ngIf="show[i]" class="btn btn-link ml-3 pt-1 px-0" (click)="toggleShow(!show[i], i)">
                        <i class="fa fa-minus text-muted"></i>
                    </button>
                </div>
            </div>
            <div class="box-body bg-light">
                <div class="row">
                    <div class="col-6 col-lg-4">
                        <span> <span class="pr-1"
                                     translate>Operatore:</span> <b>{{affiliationInRevision?.operator?.company_name}}</b></span>
                    </div>
                    <div class="col-6 col-lg-4">
                        <span><span class="pr-1"
                                    translate>Dealer:</span> <a
                                [routerLink]="['/dealer', affiliationInRevision?.dealer?.id]"
                                target="_blank"><b>{{affiliationInRevision?.dealer?.company_name}}</b></a></span>
                    </div>
                    <div class="col-12 col-lg-4">
                        <small class="d-block text-lg-right text-muted pr-1"><i
                                class="fa fa-download mr-2"></i>{{affiliationInRevision?.submitted_documents?.length}}
                            {{affiliationInRevision?.submitted_documents?.length > 1 ? 'files' : 'file'}}
                            <span translate>inviati</span></small>
                    </div>
                </div>
            </div>
            <div class="box-footer box-comments"
                 *ngIf="show[i]">
                <div class="box-footer box-comments p-0">
                    <div class="box-comment mb-2 w-100"
                         *ngFor="let submittedDocument of affiliationInRevision?.submitted_documents">
                        <div class="comment-text d-flex justify-content-between align-items-center">
                            <div>
                                   <span class="username d-block text-capitalize">{{showRequiredDocument(submittedDocument?.requirement_id, affiliationInRevision)?.name}}
                                        </span>
                                <span class="text-muted">{{showRequiredDocument(submittedDocument?.requirement_id, affiliationInRevision)?.description}}
                                        </span>
                            </div>
                            <div>
                                <a [href]="submittedDocument?.link">{{submittedDocument?.file_name ? submittedDocument?.file_name : submittedDocument?.link}}</a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="affiliationInRevision?.submitted_documents?.length<=0">
                        <p class="text-muted" translate>No submitted files</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col justify-content-end d-flex">
        <ngb-pagination
                *ngIf="affiliationItems?.in_revision?._meta"
                [(page)]="affiliationItems?.in_revision._meta.currentPage"
                [pageSize]="affiliationItems?.in_revision._meta.perPage"
                [collectionSize]="affiliationItems?.in_revision._meta.perPage*affiliationItems?.in_revision._meta.pageCount"
                [maxSize]="5"
                [boundaryLinks]="true" size="sm"
                (pageChange)="setAffiliationsInRevisionPaginationPage($event,affiliationItems?.in_revision._meta.perPage)"
                [ellipses]="false" [hidden]="affiliationItems?.in_revision._meta.pageCount<=1">
            <ng-template ngbPaginationFirst>««
            </ng-template>
            <ng-template ngbPaginationPrevious>«
            </ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}
            </ng-template>
            <ng-template ngbPaginationNext>»
            </ng-template>
            <ng-template ngbPaginationLast>»»
            </ng-template>
        </ngb-pagination>
    </div>
</div>

<div class="row mt-4 position-relative">
    <div class="col-12 mb-3">
        <h4 class="d-inline" translate *ngIf="affiliationItems?.archived.data?.length>0">
            Lista delle affiliazioni
        </h4>
        <div class="float-right" *show="['agent']">
            <button (click)="selectAffilation()" class="btn btn-sm btn-success"><i
                    class="fa fa-plus"></i> <span translate>Inserisci Affiliazione</span>
            </button>
        </div>
    </div>
    <ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, .7)" color="#333333" [name]="archivedSpinnerName"
                 class="h-100 w-100"
                 type="ball-clip-rotate-multiple"
                 size="medium">
    </ngx-spinner>

    <!-- da lavorare  -->
    <div class="col-md-12 position-relative"
         *ngFor="let affiliation of affiliationItems?.archived?.data; index as i">
        <div class="box box-widget">
            <div class="box-header with-border d-lg-flex align-items-center">
                <div class="user-block">
                    <img class="img-circle" src="https://picsum.photos/200/300" alt="User Image">
                    <span class="username"><a href="#" class="text-capitalize">{{affiliation?.agent?.first_name}}
                        {{affiliation?.agent?.last_name}}</a></span>
                    <span class="description"><span
                            translate>Creato il</span> {{affiliation?.created_at | amDateFormat:'H:mm DD/MM/YYYY'}}</span>
                </div>
                <div class="d-flex mr-5">
                    <button href="javascript:void(0)" class="btn btn-xs mr-4"
                            [ngClass]="{'btn-warning': affiliation.status !== 1, 'btn-success': affiliation.status === 1}"
                            (click)="openPopover(plafondStatusPopover,affiliation?.id,affiliation?.status,'status',selectToAffilation)"
                            #plafondStatusPopover="ngbPopover" autoClose="outside" triggers="manual"
                            [ngbPopover]="popContent" popoverTitle="Cambia valore"

                    >
                        {{affiliation?.affiliationStatusLabel}}
                    </button>


                    <span *ngIf="affiliation.status==2">
                    <button class="btn btn-xs btn-light mr-2" data-widget=""
                            *show="['agent']" (click)="selectAffilation(affiliation)" translate>
                        Modifica
                    </button>
                 </span>

<!--                    <button class="btn btn-info btn-xs btn-block mx-2" [swal]="rejectAffiliationOptions"-->
<!--                            (confirm)="rejectAffiliation(affiliationInRevision, $event)"-->
<!--                            *show="['operator']" translate>-->
<!--                        Nota-->
<!--                    </button>-->
                    <button class="btn btn-xs btn-danger mr-2"
                            *show="['agent', 'operator']"
                            [swal]="deleteAffiliationOptions"
                            (confirm)="deleteAffiliation(affiliation)" translate>
                        Cancella
                    </button>
                </div>
                <div class="box-tools my-auto">
                    <button *ngIf="!show[i+affiliationItems?.in_revision?.data?.length]"
                            class="btn btn-link ml-3 pt-1 px-0">
                        <i class="fa fa-plus text-muted"
                           (click)="toggleShow(!show[i+affiliationItems?.in_revision?.data?.length], i+affiliationItems?.in_revision?.data?.length)"></i>
                    </button>
                    <button *ngIf="show[i+affiliationItems?.in_revision?.data?.length]"
                            class="btn btn-link ml-3 pt-1 px-0">
                        <i class="fa fa-minus text-muted"
                           (click)="toggleShow(!show[i+affiliationItems?.in_revision?.data?.length], i+affiliationItems?.in_revision?.data?.length)"></i>
                    </button>
                </div>
            </div>
            <div class="box-body bg-light">
                <div class="row">
                    <div class="col-6 col-lg-4">
                        <span><span class="pr-1"
                                    translate>Operatore:</span> <b>{{affiliation?.operator?.company_name}}</b></span>
                    </div>
                    <div class="col-6 col-lg-4">
                        <span class="d-flex">
                            <span class="pr-1" translate>Dealer:</span>
                            <a [routerLink]="['/dealer', affiliation?.dealer?.id]"
                               target="_blank"><b>{{affiliation?.dealer?.company_name}}</b></a>
                                <p *show="['operator']">
                                    <i 
                                        *ngIf="affiliation?.operator?.id === 16" 
                                        class="fas fa-download ml-2 cursor-hand"
                                        (click)="downloadFileA2a(affiliation?.dealer?.id)">
                                    </i>
                                    <i 
                                        *ngIf="affiliation?.operator?.id !== 16" 
                                        class="fas fa-download ml-2 cursor-hand"
                                        (click)="downloadFile(affiliation?.dealer?.id)">
                                    </i>
                                </p>
                        </span>
                    </div>
                    <div class="col-6 col-lg-2">
                        <span>
                            <span translate>Stato:</span>
                            <span class="badge ml-2"
                                  [ngClass]="{'badge-warning': affiliation.status !== 1, 'badge-success': affiliation.status === 1}"
                                  translate>{{affiliation?.affiliationStatusLabel}}</span>
                        </span>
                    </div>
                    <div class="col-6 col-lg-2">
                        <small class="d-block text-right text-muted pr-1">
                            <i class="fa fa-download mr-2"></i>
                            {{affiliation?.submitted_documents?.length}}
                            {{affiliation?.submitted_documents?.length > 1 ? 'files' : 'file'}}
                            <span translate>inviati</span>
                        </small>
                    </div>
                </div>
            </div>
            <div class="box-body"
                 *ngIf="affiliation?.status == 2 && show[i+affiliationItems?.in_revision?.data?.length]">
                <span translate>Note rifiuto:</span>
                <p class="d-block text-muted">
                    {{affiliation?.note}}
                </p>
            </div>
            <div class="box-footer box-comments" *ngIf="show[i+affiliationItems?.in_revision?.data?.length]">
                <div class="box-footer box-comments p-0">
                    <div class="box-comment" *ngFor="let submittedDocument of affiliation?.submitted_documents">
                        <div class="comment-text mb-2 d-flex justify-content-between align-items-center">
                            <div>
                                                           <span class="username d-block text-capitalize">{{showRequiredDocument(submittedDocument?.requirement_id, affiliation)?.name}}
                            </span>
                                <span class="text-muted">{{showRequiredDocument(submittedDocument?.requirement_id, affiliation)?.description}}</span>
                            </div>
                            <div>
                                <a [href]="submittedDocument?.link">{{submittedDocument?.file_name}}</a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="affiliation?.submitted_documents?.length<=0">
                        <p class="text-muted" translate>No submitted files</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 justify-content-end d-flex">
        <ngb-pagination [class.ngb-pagination-disabled]="isLoading"
                        *ngIf="affiliationItems?.archived?._meta"
                        [(page)]="affiliationItems?.archived._meta.currentPage"
                        [pageSize]="affiliationItems?.archived._meta.perPage"
                        [boundaryLinks]="true" size="sm"
                        [collectionSize]="affiliationItems?.archived._meta.perPage*affiliationItems?.archived._meta.pageCount"
                        [maxSize]="5"
                        (pageChange)="setAffiliationsArchivedPaginationPage($event,affiliationItems?.in_revision._meta.perPage)"
                        [ellipses]="false" [hidden]="affiliationItems?.archived._meta.pageCount<=1">
            <ng-template ngbPaginationFirst>««</ng-template>
            <ng-template ngbPaginationPrevious>«</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            <ng-template ngbPaginationNext>»</ng-template>
            <ng-template ngbPaginationLast>»»</ng-template>
        </ngb-pagination>
    </div>
</div>

<ng-template #affilationsTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title"
            translate>{{selectedAffiliation ? 'Modifica Affiliazione' : 'Nuova Affiliazione'}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-affilation-modal [affiliationValues]="selectedAffiliation"
                          [affiliationItems]="affiliationItems"></app-affilation-modal>
</ng-template>
<ng-template #popContent>
    <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
