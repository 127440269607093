import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import * as moment from 'moment';
import {EPayService} from '@services/e-pay.service';
import {ShopBrand} from '@models/e-pay/shop-brand';
import {Status, Tickets, TicketsProvider} from '@models/kd-orders';
import {AuthenticationService} from '@services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ActivatedRoute} from '@angular/router';
import {KdOrderService} from '@services/kd-order.service';
import {User} from '@models/user';
import {Moment} from 'moment';
import {Document} from '@models/invoice/document';

@Component({
    selector: 'app-tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

    orders: Tickets[] = [];
    ordersPagination: MetaPagination;

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        }
    };

    filter = {
        dealer_id: '',
        date: '',
        status: '',
        job_id: '',
        email: ''
    };

    apiDate = {
        from: '',
        to: ''
    };
    localFormat = 'dd/MM/yyyy HH:MM';
    dateFormat = 'dd/MM/yyyy';
    currency = EPayService.currency;
    statuses = Status.list;
    selectedData: Tickets;
    @ViewChild('detailTemplate') private detailTemplate;

    constructor(
        private ePayService: EPayService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        private dateRangePickerOptions: DaterangepickerConfig,
        private TicketsService: KdOrderService,
        public route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.loadOrders();
    }

    loadOrders() {
        this.spinner.show('table-orders');
        const params = {};
        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.date) {
            params['from_date'] = this.apiDate.from;
            params['to_date'] = this.apiDate.to;
        }
        if (this.filter.job_id) {
            params['job_id'] = this.filter.job_id;
        }
        if (this.filter.email) {
            params['email'] = this.filter.email;
        }

        this.TicketsService.getTickets(params).subscribe((response: TicketsProvider) => {
            this.orders = response.data;
            this.ordersPagination = response._meta;
            this.spinner.hide('table-orders');
        }, err => {
            this.spinner.hide('table-orders');
        });
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.date = startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }

    showDetailModal(data?: Tickets) {
        this.selectedData = data;
        this.modalService.open(this.detailTemplate, {size: 'lg', scrollable: true});
    }
}
