import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { KpiService } from '@app/services/kpi.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { MetaPagination } from '@app/models/meta-pagination';
import { RequestService } from '@services/request.service';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AuthenticationService } from '@app/services/authentication.service';
import { User } from '@app/models/user';

@Component({
    selector: 'app-kpi-analisi',
    templateUrl: './kpi-analisi.component.html',
    styleUrls: ['./kpi-analisi.component.css'],
})
export class KpiAnalisiComponent implements OnInit {
    @ViewChild('assegnaTargetModal') private assegnaTargetModal;
    @ViewChild('excelModal') private excelModal;
    @ViewChild('uploadExcelModal') private uploadExcelModal;
    @ViewChild('selectRef') selectRef: any;
    @ViewChild('popContent') private popContent;
    @ViewChild('retailerTypeahead') retailerTypeahead: ElementRef;

    targetsPagination: MetaPagination = new MetaPagination();
    kpiPagination: MetaPagination = new MetaPagination();

    dcList: any;
    popupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };

    selectedData: any;
    targetList: any;
    isLoading = false;
    isEdit = true;
    selectedTab: string = 'generale';

    filter = {
        dealer_id: '',
    };

    kpiPayload = {
        month: '',
        year: '',
        from: this.getFirstDayOfMonth(),
        to: this.getLastDayOfMonth(),
        dealer_id: '',
        type_id: '',
        target: 0,
        revenue: 0,
        revenue_type: '',
    };

    dealersKpiPayload = {
        month: '',
        year: '',
        from: this.getFirstDayOfMonth(),
        to: this.getLastDayOfMonth(),
        dealer_id: '',
        ka_id: '',
        is_achieved: 0,
        kpi_type_id: '',
    };

    loadingKpi = false;
    kpiTypes = [];
    excelType = 'keyaccount';
    downloadingConsuntivo = false;
    downloadingExcel = false;
    uploadingExcel = false;
    uploadResult = [];
    uploadPayload = {
        month: moment().format('MM'),
        year: moment().format('YYYY'),
    };
    reloadingKpis = false;
    downloadingConsuntivoDetail = false;

    kpisList: any = null;
    typeList: any = null;
    dealersKpi: any[] = [];
    kpiTypeFilters: any[] = [];
    dealerKpiValues = {};

    private user: User;
    get deleteTargetAssociato() {
        return new ConfirmOptionsService({
            title: 'Elimina Record',
            text: 'Sei sicuro di voler rimuovere questo record?',
        });
    }

    constructor(private kpiService: KpiService, public requestService: RequestService, public modalService: NgbModal, private spinner: NgxSpinnerService, private authService: AuthenticationService) {
        this.authService.currentUser.subscribe((user) => (this.user = user));
    }

    ngOnInit(): void {
        this.loadKpiTypes();
        if (this.user.profile.role == 'operator') {
            this.kpiService.getDcSelect().subscribe((res) => {
                this.dcList = res;
            });
        }
    }

    loadTargetList(hideSpinner = false) {
        if (this.selectedTab !== 'generale' && !this.kpiPayload.dealer_id) {
            this.targetList = [];
            this.loadingKpi = false;
            return;
        }

        this.loadingKpi = true;
        const params = {};

        if (this.targetsPagination?.currentPage) {
            params['page'] = this.targetsPagination?.currentPage;
        }

        if (this.kpiPayload.dealer_id) {
            params['dealer_id'] = this.kpiPayload.dealer_id;
        }

        if (this.kpiPayload.month) {
            params['month'] = this.kpiPayload.month;
        }

        if (this.kpiPayload.year) {
            params['year'] = this.kpiPayload.year;
        }

        if (this.selectedTab === 'generale') {
            params['general'] = true;
        }

        this.kpiService.getAssociaTarget(params).subscribe(
            (response: any) => {
                this.targetList = response;
                this.loadingKpi = false;
            },
            (err) => {
                console.error('Errore durante il caricamento dei target:', err);
                this.loadingKpi = false;
            },
        );
    }

    loadDealersKpiList() {
        this.loadingKpi = true;
        const params = {};

        if (this.kpiPagination?.currentPage) {
            params['page'] = this.kpiPagination?.currentPage;
        }

        if (this.dealersKpiPayload.ka_id) {
            params['ka_id'] = this.dealersKpiPayload.ka_id;
        }

        if (this.dealersKpiPayload.dealer_id) {
            params['dealer_id'] = this.dealersKpiPayload.dealer_id;
        }

        if (this.dealersKpiPayload.month) {
            params['month'] = this.dealersKpiPayload.month;
        }

        if (this.dealersKpiPayload.year) {
            params['year'] = this.dealersKpiPayload.year;
        }

        if (this.dealersKpiPayload.is_achieved) {
            params['is_achieved'] = this.dealersKpiPayload.is_achieved;
        }

        if (this.dealersKpiPayload.kpi_type_id) {
            params['kpi_type_id'] = this.dealersKpiPayload.kpi_type_id;
        }

        this.kpiService.getDealersKpiList(params).subscribe(
            (response: any) => {
                this.kpisList = response.data;
                if (!this.dealersKpiPayload.kpi_type_id) {
                    this.kpiTypeFilters = this.kpisList.kpi;
                }

                this.kpiPagination = response._meta;

                this.loadingKpi = false;
            },
            (err) => {
                this.loadingKpi = false;
            },
        );
    }

    hasKpi(dealer: any, kpi: any): boolean {
        return dealer.kpi && Array.isArray(dealer.kpi) && dealer.kpi.some((item: any) => item.type_id === kpi.type_id);
    }

    getKpiValue(dealer: any, kpi: any): number {
        const targetKpi = dealer.kpi.find((item: any) => item.type_id === kpi.type_id);
        return targetKpi.value ? targetKpi.value : 0;
    }

    getKpiTarget(dealer: any, kpi: any): number {
        const targetKpi = dealer.kpi.find((item: any) => item.type_id === kpi.type_id);
        return targetKpi.target ? targetKpi.target : 0;
    }

    exportDealersTarget() {
        let rows = this.kpisList.dealers;

        let data: any[][] = [];

        data.push(['Ragione Sociale', 'KPI', 'Target', 'Avanzamento', 'Target Raggiunto']);

        rows.forEach((dealer) => {
            dealer.kpi.forEach((kpi) => {
                data.push([dealer.ragsociale, kpi.descrizione, kpi.target, kpi.value, kpi.value >= kpi.target ? 'SI' : 'NO']);
            });
        });

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Dealers KPIs');

        XLSX.writeFile(wb, 'dealers_kpis.xlsx');
    }

    assegnaTarget() {
        if (this.kpiPayload) {
            this.isLoading = true;
            this.kpiService
                .postAssociaTarget(this.kpiPayload)
                .subscribe(
                    (response) => {
                        this.isLoading = false;
                        this.kpiService.successAlertCreate('correttamente!');
                        this.modalService.dismissAll();
                        this.resetKpiPayload();
                        this.loadTargetList();
                    },
                    (error) => {
                        console.error("Errore durante l'invio dei dati", error);
                    },
                )
                .add(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 5000);
                });
        }
    }

    resetKpiPayload() {
        this.kpiPayload.revenue = 0;
        this.kpiPayload.revenue_type = '';
        this.kpiPayload.target = 0;
        this.kpiPayload.type_id = '';
    }

    setScopeId(event) {
        this.kpiPayload.dealer_id = event;
        this.loadTargetList();
    }

    setScopeDealerKpiId(event) {
        this.dealersKpiPayload.dealer_id = event;
        if (event !== null) {
            this.loadDealersKpiList();
        } else {
            this.kpisList = null;
        }
    }

    setScopeKaKpiId(event) {
        this.dealersKpiPayload.ka_id = event;
        if (event !== null) {
            this.loadDealersKpiList();
        } else {
            this.kpisList = null;
        }
    }

    setScopeIdKpi(event) {
        this.kpiPayload.type_id = event;
    }

    setSelectedDate($event: any) {
        this.kpiPayload.month = moment($event).format('MM');
        this.kpiPayload.year = moment($event).format('YYYY');
        this.loadTargetList();
    }

    setSelectedDateDealersKpi($event: any) {
        this.dealersKpiPayload.month = moment($event).format('MM');
        this.dealersKpiPayload.year = moment($event).format('YYYY');
    }

    showTargetModal(data: any) {
        this.selectedData = data;
        this.modalService.open(this.assegnaTargetModal, { size: 'md' });
    }

    showExcelModal() {
        this.modalService.open(this.excelModal, { size: 'lg' });
    }

    showUploadExcelModal() {
        this.uploadResult = [];
        this.modalService.open(this.uploadExcelModal, { size: 'lg' });
    }

    openPopover(popOver: any, orderId, item, key, selectItems = []) {
        this.popupConfig.inputValue = item;
        this.popupConfig.id = orderId;
        this.popupConfig.key = key;
        this.popupConfig.popover = popOver;
        this.popupConfig.isSelect = selectItems?.length > 0;
        this.popupConfig.items = selectItems;
        popOver.open();
    }

    saveTarget(value) {
        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
            console.error('Il valore non è un numero valido.');
            return;
        }

        const body = {};
        body[this.popupConfig['key']] = numericValue;
        this.spinner.show('popupSpinner');
        return this.changeOrder(this.popupConfig.id, body);
    }

    saveRevenueType(value) {
        const body = {};
        body[this.popupConfig['key']] = value;
        this.spinner.show('popupSpinner');
        return this.changeOrder(this.popupConfig.id, body);
    }

    saveRevenueValue(value) {
        const body = {};
        body[this.popupConfig['key']] = value;
        this.spinner.show('popupSpinner');
        return this.changeOrder(this.popupConfig.id, body);
    }

    changeOrder(orderId, body) {
        this.kpiService.putTargetAssociato(orderId, body).subscribe(
            (response) => {
                this.kpiService.successAlertCreate('correttamente!');
                this.loadTargetList(true);
                this.spinner.hide('popupSpinner');
            },
            (err) => {
                console.error('Errore durante la richiesta HTTP:', err);

                this.loadTargetList(true);
                this.spinner.hide('popupSpinner');
            },
        );
    }

    deleteTarget(id: number) {
        this.kpiService.deleteTargetAssegnato(id).subscribe(
            () => {
                this.kpiService.successAlertTarget('correttamente!');
                this.loadTargetList();
            },
            (err) => {
                this.kpiService.errorAlert(err.message);
            },
        );
    }

    selectTab(tabName: string) {
        this.targetList = [];
        this.selectedTab = tabName;
        this.kpiPayload.dealer_id = null;
        this.dealersKpiPayload.dealer_id = null;
        this.dealersKpiPayload.ka_id = null;
        this.setParametroInBaseAllaScheda();
        if (tabName == 'dealers') {
            if (this.user.profile.role == 'agent') {
                this.loadDealersKpiList();
            } else if (this.user.profile.role !== 'agent') {
                return;
            }
        } else {
            this.loadTargetList();
        }
    }

    setParametroInBaseAllaScheda() {
        switch (this.selectedTab) {
            case 'direttoreCommerciale':
                break;
            case 'areaManager':
                break;
            case 'keyAccount':
                break;
            case 'dealers':
                this.dealersKpiPayload.dealer_id = null;
                this.dealersKpiPayload.ka_id = null;
                break;
            case 'generale':
                this.kpiPayload.dealer_id = null;
                this.kpiPayload.type_id = null;
                break;
        }
    }

    getFirstDayOfMonth(): string {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        return this.formatDate(firstDay);
    }

    getLastDayOfMonth(): string {
        const today = new Date();
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        return this.formatDate(lastDay);
    }

    formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    private loadKpiTypes() {
        this.kpiService.getKpiTypeList({ 'per-page': 0 }).subscribe(
            (response: any) => {
                this.kpiTypes = response.data.map((item) => {
                    item['checked'] = false;
                    item['revenue'] = 0;
                    item['revenue_type'] = 'volume';
                    return item;
                });

                this.kpiTypes = this.kpiTypes.reduce((acc, item) => {
                    if (!acc[item.category]) {
                        acc[item.category] = [];
                    }
                    acc[item.category].push(item);
                    return acc;
                }, {});
            },
            (error) => {},
        );
    }

    downloadExcel() {
        //unpack kpitypes from keys
        const types = Object.keys(this.kpiTypes)
            .map((key) => this.kpiTypes[key])
            .flat();

        const payload = types
            .filter((item) => item.checked)
            .map((item) => {
                return {
                    id: item.id,
                    revenue: item.revenue,
                    revenue_type: item.revenue_type,
                };
            });

        this.downloadingExcel = true;
        this.requestService.post(`v3/kpi/get-template?type=${this.excelType}`, payload, { responseType: 'blob' }).subscribe(
            (response: any) => {
                const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fileSaver.saveAs(blob, 'template.xlsx');
                this.downloadingExcel = false;
            },
            (error) => {
                this.downloadingExcel = false;
            },
        );
    }

    downloadConsuntivo() {
        this.downloadingConsuntivo = true;
        this.requestService.post(`v3/kpi/get-consuntivo?month=${this.kpiPayload.month}&year=${this.kpiPayload.year}`, {}, { responseType: 'blob' }).subscribe(
            (response: any) => {
                const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fileSaver.saveAs(blob, 'consuntivo.xlsx');
                this.downloadingConsuntivo = false;
            },
            (error) => {
                this.downloadingConsuntivo = false;
            },
        );
    }

    handleFileInput(file: any) {
        const formData = new FormData();
        formData.append('file', file);
        this.uploadingExcel = true;
        this.uploadResult = [];
        this.requestService.post(`v3/kpi/upload-template?month=${this.uploadPayload.month}&year=${this.uploadPayload.year}`, formData).subscribe(
            (response: any) => {
                this.loadTargetList();
                this.uploadResult = response;
                this.uploadingExcel = false;
            },
            (error) => {
                this.kpiService.errorAlert(error.message);
                this.uploadingExcel = false;
            },
        );
    }

    setSelectedUploadDate($event: any) {
        this.uploadPayload.month = moment($event).format('MM');
        this.uploadPayload.year = moment($event).format('YYYY');
    }

    selectAllKpiTypes() {
        //iterate through all kpi types inside keys and set checked to true
        Object.keys(this.kpiTypes).forEach((key) => {
            this.kpiTypes[key].forEach((item) => {
                item.checked = true;
            });
        });
    }

    deselectAllKpiTypes() {
        Object.keys(this.kpiTypes).forEach((key) => {
            this.kpiTypes[key].forEach((item) => {
                item.checked = false;
            });
        });
    }

    processKpis() {
        this.reloadingKpis = true;
        this.requestService
            .get('v3/kpi/process-kpi', {
                month: this.kpiPayload.month,
                year: this.kpiPayload.year,
            })
            .subscribe(
                (response: any) => {
                    this.reloadingKpis = false;
                    this.loadTargetList();
                    this.kpiService.reloadAlert(`Ricalcolati ${response.success} KPI`);
                },
                (error) => {
                    this.kpiService.errorAlert(error.message);
                    this.reloadingKpis = false;
                },
            );
    }

    downloadConsuntivoDetail() {
        this.downloadingConsuntivoDetail = true;
        this.requestService.post(`v3/kpi/get-consuntivo-detail?month=${this.kpiPayload.month}&year=${this.kpiPayload.year}`, {}, { responseType: 'blob' }).subscribe(
            (response: any) => {
                const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fileSaver.saveAs(blob, 'consuntivo_dettaglio.xlsx');
                this.downloadingConsuntivoDetail = false;
            },
            (error) => {
                this.downloadingConsuntivoDetail = false;
            },
        );
    }
}
