import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';


export class Mail extends BaseModel {
  id: number;
  nome: string;
  tipo_destinatario: string;
  operatori: string;
  slug: string;
  created_at: string;
  created_by: string;
  update_at: string;
  update_by: string;
  is_deleted: number;
  loghi_operatori: any;
  destinatari: any;

  fromArray(result: []): Mail[] {
    const array: Mail[] = [];
    for (const item of result) {
      array.push(new Mail(item));
    }
    return array;
  }
}

export class MailProvider extends BaseModel {
  data: Mail[];
  _meta: MetaPagination;
  _links: Array<any>;
}


export class MailSubscription extends BaseModel {
  id: number;
  mail_id: number;
  nome: string;
  dealer_id: number;
  email: string;
  role: any;
  created_at: string;
  created_by: string;
  update_at: string;
  update_by: string;
  is_deleted: number;

  fromArray(result: []): MailSubscription[] {
    const array: MailSubscription[] = [];
    for (const item of result) {
      array.push(new MailSubscription(item));
    }
    return array;
  }
}

export class MailSubscriptionProvider extends BaseModel {
  data: MailSubscription[];
  _meta: MetaPagination;
  _links: Array<any>;
}


export class MailLog extends BaseModel {
  id: number;
  mail_id: number;
  dealer_id: number;
  nome: string;
  html: string;
  created_at: string;
  created_by: string;
  update_at: string;
  update_by: string;
  is_deleted: number;

  fromArray(result: []): MailLog[] {
    const array: MailLog[] = [];
    for (const item of result) {
      array.push(new MailLog(item));
    }
    return array;
  }
}

export class MailLogProvider extends BaseModel {
  data: MailLog[];
  _meta: MetaPagination;
  _links: Array<any>;
}