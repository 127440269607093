import { Component, OnInit } from '@angular/core';
import { Flixbus } from '@app/models/flixbus';
import { MetaPagination } from '@models/meta-pagination';
import { FlixbusService } from '@app/services/flixbus.service';
import { AuthenticationService } from '@services/authentication.service';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { User } from '@models/user';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-flixbus',
  templateUrl: './flixbus.component.html',
  styleUrls: ['./flixbus.component.css'],
})
export class FlixbusComponent implements OnInit {
  flixbusList: Flixbus[] = [];
  statusCustom = Flixbus.listStatus;
  ordersPagination: MetaPagination = new MetaPagination();
  isDealer = false;

  filter = {
    id: '',
    company_name: '',
    dealer_id: '',
    created_at: '',
    status: '',
    date: '',
    transaction_id: '',
  };

  apiDate = {
    from: '',
    to: '',
  };

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  constructor(
    private FlixbusService: FlixbusService,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private dateRangePickerOptions: DaterangepickerConfig,
    public route: ActivatedRoute,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadList();
    setTimeout(() => {});
  }

  loadList(hideSpinner = false) {
    this.spinner.show('table-orders');
    const params = {};

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.transaction_id) {
      params['transaction_id'] = this.filter.transaction_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.FlixbusService.getFlixbusList(params).subscribe(
      (response: any) => {
        this.flixbusList = new Flixbus().fromArray(response.data);
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  exportFlixbusList() {
    this.spinner.show('table-dealers');
    const params = {
      'per-page': 0,
    };

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.transaction_id) {
      params['transaction_id'] = this.filter.transaction_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.FlixbusService.getFlixbusList(params).subscribe((data: any) => {
      this.spinner.hide('table-dealers');

      this.spinner.hide('table-dealers');

      let rows = new Flixbus().fromArray(data.data);

      let output = rows.map((row) => {
        return {
          'Ragione Sociale': row.company_name,
          'Dealer ID': row.dealer_id,
          'Data Inserimento': row.created_at,
          'ID transazione': row.transaction_id,
          Stato: row.status,
        };
      });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-flixbus.xlsx');
    });
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }
}
