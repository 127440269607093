<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadOrders()">
    <div class="form-row">
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="companyName">Company name</label>
          <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="companyName"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Dealer ID</label>
          <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control" />
        </div>
      </div>
      <!-- <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">VAT</label>
                    <input [(ngModel)]="filter.vat" name="vat" class="form-control">
                </div>
            </div> -->
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Date</label>
          <input
            id="documentDate"
            type="text"
            daterangepicker
            [options]="pickerOptions"
            [(ngModel)]="filter.date"
            name="date"
            class="form-control"
            autocomplete="off"
            (selected)="generateDateRangeString($event.start, $event.end)"
          />
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Status</label>
          <ng-select
            [clearable]="true"
            class="disableClear"
            [(ngModel)]="filter.status"
            [items]="statuses"
            bindLabel="label"
            name="subscribed"
            id="subscribed"
            bindValue="key"
            [searchable]="true"
          ></ng-select>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <button class="btn btn-info btn-block" translate type="submit">Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Tabella -->

<div class="card">
  <div class="card-header">
    <h4 class="mb-0" translate>A2A</h4>
  </div>
  <div class="card-body p-0 rounted-bottom table-responsive">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-orders" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th></th>
          <th translate>Company name</th>
          <th translate>VAT</th>
          <th translate>Name</th>
          <th translate>Surname</th>
          <th translate>Email</th>
          <th translate>Phone</th>
          <th translate>City</th>
          <th translate>Address</th>
          <th translate>Note</th>
          <th translate>Date</th>
          <th translate>Status</th>
        </tr>
      </thead>
      <tbody class="bg-gradiend-white">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders" [fullScreen]="false">
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngIf="a2aOrders.length == 0">
          <td translate colspan="12" class="text-center">List is empty</td>
        </tr>
        <ng-container *ngFor="let a2aOrder of a2aOrders">
          <tr>
            <td>
              <button class="btn btn-outline-info btn-sm text-center" type="button" (click)="a2aOrder.show = !a2aOrder.show" role="button" aria-expanded="false" translate>
                <i class="fas fa-plus mr-0" *ngIf="!a2aOrder.show"></i>
                <i class="fas fa-minus mr-0" *ngIf="a2aOrder.show"></i>
              </button>
            </td>
            <td>
              {{ a2aOrder.company_name }}
              <!-- <a [routerLink]="['/dealer', a2aOrder.dealer_id]" target="_blank">{{a2aOrder.company_name}}</a> -->
            </td>
            <td>
              {{ a2aOrder?.vat }}
            </td>
            <td>
              {{ a2aOrder?.name }}
            </td>
            <td>
              {{ a2aOrder?.surname }}
            </td>
            <td>
              {{ a2aOrder?.email }}
            </td>
            <td>
              {{ a2aOrder?.phone }}
            </td>
            <td>
              {{ a2aOrder?.city }}
            </td>
            <td>
              {{ a2aOrder?.address }}
            </td>
            <td *ngIf="a2aOrder?.notes">
              <button
                href="javascript:void(0)"
                class="btn px-0 bg-transparent text-left"
                (click)="openPopover(statusPopover, a2aOrder?.id, a2aOrder?.notes, 'notes')"
                #statusPopover="ngbPopover"
                autoClose="outside"
                triggers="manual"
                [ngbPopover]="popContentNotes"
                popoverTitle="Modifica nota"
              >
                <span class="d-inline-block text-truncate" style="width: 200px">{{ a2aOrder?.notes }}</span>
              </button>
            </td>
            <td *ngIf="a2aOrder?.notes == '' || a2aOrder?.notes == null">
              <button
                href="javascript:void(0)"
                class="btn text-primary px-0 bg-transparent"
                (click)="openPopover(statusPopover, a2aOrder?.id, a2aOrder?.notes, 'notes')"
                #statusPopover="ngbPopover"
                autoClose="outside"
                triggers="manual"
                [ngbPopover]="popContentNotes"
                popoverTitle="Scrivi una nota"
              >
                Aggiungi una nota >
              </button>
            </td>
            <td>
              {{ a2aOrder?.created_at | amDateFormat : 'HH:mm - DD/MM/YYYY' }}
            </td>
            <td>
              <button
                href="javascript:void(0)"
                class="btn btn-sm text-uppercase btn-block"
                (click)="openPopover(plafondStatusPopover, a2aOrder?.id, a2aOrder?.status, 'status', statuses)"
                #plafondStatusPopover="ngbPopover"
                autoClose="outside"
                triggers="manual"
                [ngbPopover]="popContent"
                popoverTitle="Cambia valore"
                [ngClass]="{
                  'btn-info': a2aOrder.status === 'pending',
                  'btn-success': a2aOrder.status === 'confirmed',
                  'btn-danger': a2aOrder.status === 'canceled'
                }"
              >
                {{ a2aOrder?.orderStatus }}
              </button>
            </td>
          </tr>
          <tr *ngIf="a2aOrder.show" class="">
            <td colspan="12">
              <div class="row">
                <div class="container col-12 py-2">
                  <strong class="py-3 mt-3">
                    <p>Da quanto tempo il vostro negozio è attivo?</p>
                  </strong>
                  <p>{{ a2aOrder.negozio_attivo_da }}</p>
                  <strong>
                    <p class="mt-3">Perché siete interessati a diventare un punto vendita dedicato ad A2A?</p>
                  </strong>
                  <p>{{ a2aOrder.interesse_a2a }}</p>
                  <p>{{ a2aOrder.specificare_altro === null ? '-' : a2aOrder.specificare_altro }}</p>
                  <strong>
                    <p class="mt-3">Lavori già nel mondo dell’energia e del gas? Con quale operatore?</p>
                  </strong>
                  <p>{{ a2aOrder.sei_gia_rivenditore }}</p>
                  <strong>
                    <p class="mt-3">Se hai risposto alla domanda precedente si, quanti contratti fai in media al mese?</p>
                  </strong>
                  <p>{{ a2aOrder.contatti_mensili }}</p>
                  <strong>
                    <p class="mt-3">Se non lavori già con un operatore di energia e gas, quanti contratti ti impegni a fare nel mese?</p>
                  </strong>
                  <p>{{ a2aOrder.stima_contatti_mensili }}</p>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <span
      ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b
      >{{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.<a class="link" (click)="exportA2a()"> Esporta la lista completa</a></span
    >
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      (pageChange)="loadOrders()"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>
<ng-template #popContent>
  <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
<ng-template #popContentNotes>
  <app-text-popup [config]="popupConfig" (onSave)="save($event)"></app-text-popup>
</ng-template>
