import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';
import { GroupProvider } from '@models/group';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  private endpoint = 'groups';

  constructor(private request: RequestService, public alertService: AlertService) {
  }

  public getGroup(params?): Observable<GroupProvider> {
    return this.request.get(`${this.endpoint}`, params);
  }

  addGroup(data) {
    return this.request.post(`${this.endpoint}`, data);
  }

  updateGroup(id, data) {
    return this.request.put(`${this.endpoint}/${id}`, data);
  }

  public deleteGroup(id: number) {
    return this.request.delete(`${this.endpoint}/${id}`);
  }

  public getTypes() {
    return this.request.get(`${this.endpoint}/type`);
  }

  public getGroupDealers(id: number) {
    return this.request.get(`${this.endpoint}/${id}`);
  }

  public deleteGroupDealers(id: number) {
    return this.request.delete(`${this.endpoint}/dealers/${id}`);
  }

  public addGroupDealers(data) {
    return this.request.post(`${this.endpoint}/dealers`, data);
  }

  public successAlert(text) {
    this.alertService.show('', 'Successfully ' + text, { classname: 'bg-success text-light', delay: 3000 });
  }

  public errorAlert(message?) {
    this.alertService.show('An error occurred!', message ? message : 'Try again!', {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  public uploadCsv(file: FormData): Observable<any> {
    return this.request.post(`${this.endpoint}/upload-csv`, file);
  }
}
