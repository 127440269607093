<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 src="assets/img/flats/placeholder.png"
                 width="160px">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Statistiche su mappa</h1>
                <hr>
                <form (ngSubmit)="load()">
                    <div class="form-row align-items-end">
                        <div class="col-lg-3 col-12"><label translate>Operatore</label>
                            <app-vendor-select [outputLabel]="true" [pickFirst]="true" (onChange)="setVendor($event)"></app-vendor-select>
                        </div>
                        <div class="col-lg-3 col-12" *ngIf="currentTab == 2"><label translate>Master Agent</label>
                            <app-master-agent-select (onChange)="filter.masteragent_id = $event"></app-master-agent-select>
                        </div>
                        <div class="col-lg-3 col-12" *ngIf="currentTab == 2"><label translate>Agent</label>
                            <app-agent-select (onChange)="filter.agent_id = $event"></app-agent-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="region" translate>Region</label>
                            <app-state-select (onChange)="changeRegion($event)"
                                              id="region"
                            ></app-state-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="province" translate>Province</label>
                            <app-province-select (onChange)="changeProvince($event)"
                                                 [onDemand]="true"
                                                 [state]="this.filter.region_id"
                                                 id="province"
                            ></app-province-select>
                        </div>
                        <div class="col-lg-3 col-12" *ngIf="currentTab == 2"><label for="city" translate>Canale</label>
                            <app-channel-select
                                    (onChange)="this.filter.channel = $event"
                            ></app-channel-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="city" translate>Cluster Comune</label>
                            <app-cluster-select
                                    (onChange)="this.filter.cluster = $event"
                            ></app-cluster-select>
                        </div>
                        <div class="col-lg-3 col-12 mt-2 mt-lg-auto">
                            <button class="btn btn-light btn-block"
                                    translate
                                    type="submit">Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs" [(activeId)]="currentTab">
    <li [ngbNavItem]="2">
        <a ngbNavLink translate>Negozi</a>
        <ng-template ngbNavContent>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <h4 class="mb-0" translate>Map</h4>
                                </div>
                                <div class="col-lg-6 col-12 d-flex justify-content-end">
                                    <div class="form-check form-check-inline">
                                        <input (change)="mode=0" checked class="form-check-input" id="inlineRadio1"
                                               name="inlineRadioOptions" type="radio" value="option1">
                                        <label class="form-check-label" for="inlineRadio1" translate>Tutti i partner</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input (change)="mode=1" class="form-check-input" id="inlineRadio2"
                                               name="inlineRadioOptions"
                                               type="radio" value="option2">
                                        <label class="form-check-label" for="inlineRadio2" translate>Produttivi</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input (change)="mode=2" class="form-check-input" id="inlineRadio3"
                                               name="inlineRadioOptions"
                                               type="radio" value="option3">
                                        <label class="form-check-label" for="inlineRadio3" translate>Improduttivi</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <ngx-spinner
                                    [fullScreen]="false"
                                    bdColor="rgba(0, 0, 0, 0.8)"
                                    color="#fff"
                                    name="map"
                                    size="default"
                                    type="square-jelly-box"
                            >
                                <p style="color: white">Caricamento...</p>
                            </ngx-spinner>
                            <agm-map #gm
                                     [clickableIcons]="false"
                                     [latitude]='42.2894929'
                                     [longitude]='11.7101332'
                                     [showDefaultInfoWindow]="false"
                                     [styles]="defaultStyles"
                                     [zoom]="6"
                                     mapTypeId="roadmap">
                                <ng-container *ngIf="visitsData && visitsData.length > 0">
                                    <ng-container *ngIf="mode === 0 || mode === 1">
                                        <ng-container
                                                *ngFor="let visit of visitsData">
                                            <agm-marker
                                                    *ngIf="visit.default_lat && visit.default_lon && visit.current_month_sales > 0"

                                                    iconUrl="http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_green.png"
                                                    [latitude]="visit.default_lat"
                                                    [longitude]="visit.default_lon"
                                            >
                                                <agm-info-window>
                                                    <p>Partner</p>
                                                    <b>{{visit.default_ragsociale}}</b>
                                                    <p>Ordini</p>
                                                    <b>{{visit.current_month_sales}}</b>
                                                </agm-info-window>
                                            </agm-marker>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="mode === 0 || mode === 2">
                                        <ng-container
                                                *ngFor="let visit of visitsData">
                                            <agm-marker
                                                    *ngIf="visit.default_lat && visit.default_lon && visit.current_month_sales === 0"

                                                    iconUrl="http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_red.png"
                                                    [latitude]="visit.default_lat"
                                                    [longitude]="visit.default_lon"
                                            >
                                                <agm-info-window>
                                                    <p>Partner</p>
                                                    <b>{{visit.default_ragsociale}}</b>
                                                    <p>Ordini</p>
                                                    <b>{{visit.current_month_sales}}</b>
                                                </agm-info-window>
                                            </agm-marker>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </agm-map>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <app-master-card
                            bodyClass="p-0"
                            image="assets/img/flats/new.png"
                            title="Negozi"
                    >
                        <data-builder-table (onRowsChange)="setMapPins($event)" *ngIf="tableUrl"
                                            [url]="tableUrl"></data-builder-table>
                    </app-master-card>
                </div>
            </div>
        </ng-template>
    </li>
    <li [ngbNavItem]="1">
        <a ngbNavLink translate>Comuni</a>
        <ng-template ngbNavContent>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <h4 class="mb-0" translate>Map</h4>
                                </div>
                                <div class="col-lg-6 col-12 d-flex justify-content-end">
                                    <div class="form-check form-check-inline">
                                        <input (change)="mode=0" checked class="form-check-input" id="inlineRadio1"
                                               name="inlineRadioOptions" type="radio" value="option1">
                                        <label class="form-check-label" for="inlineRadio1" translate>Tutti i comuni</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input (change)="mode=1" class="form-check-input" id="inlineRadio2"
                                               name="inlineRadioOptions"
                                               type="radio" value="option2">
                                        <label class="form-check-label" for="inlineRadio2" translate>Con partner</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input (change)="mode=2" class="form-check-input" id="inlineRadio3"
                                               name="inlineRadioOptions"
                                               type="radio" value="option3">
                                        <label class="form-check-label" for="inlineRadio3" translate>Senza partner</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <ngx-spinner
                                    [fullScreen]="false"
                                    bdColor="rgba(0, 0, 0, 0.8)"
                                    color="#fff"
                                    name="map"
                                    size="default"
                                    type="square-jelly-box"
                            >
                                <p style="color: white">Caricamento...</p>
                            </ngx-spinner>
                            <agm-map #gm
                                     [clickableIcons]="false"
                                     [latitude]='42.2894929'
                                     [longitude]='11.7101332'
                                     [showDefaultInfoWindow]="false"
                                     [styles]="defaultStyles"
                                     [zoom]="6"
                                     mapTypeId="roadmap">
                                <ng-container *ngIf="cityData && cityData.length > 0">
                                    <ng-container *ngIf="mode === 0 || mode === 1">
                                        <ng-container
                                                *ngFor="let visit of cityData">
                                            <agm-marker
                                                    *ngIf="visit.default_lat && visit.default_lon && visit.default_dealers > 0"
                                                    [iconUrl]="getCityIconUrl(visit)"
                                                    [latitude]="visit.default_lat"
                                                    [longitude]="visit.default_lon"
                                            >
                                                <agm-info-window>
                                                    <p>Comune</p>
                                                    <b>{{visit.default_comune}}</b>
                                                    <p>Partners</p>
                                                    <b>{{visit.default_dealers}}</b>
                                                </agm-info-window>
                                            </agm-marker>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="mode === 0 || mode === 2">
                                        <ng-container
                                                *ngFor="let visit of cityData">
                                            <agm-marker
                                                    *ngIf="visit.default_lat && visit.default_lon && visit.default_dealers === 0"

                                                    [iconUrl]="getCityIconUrl(visit)"
                                                    [latitude]="visit.default_lat"
                                                    [longitude]="visit.default_lon"
                                            >
                                                <agm-info-window>
                                                    <p>Comune</p>
                                                    <b>{{visit.default_comune}}</b>
                                                    <p>Partners</p>
                                                    <b>{{visit.default_dealers}}</b>
                                                </agm-info-window>
                                            </agm-marker>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </agm-map>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <app-master-card
                            bodyClass="p-0"
                            image="assets/img/flats/new.png"
                            title="Comuni"
                    >
                        <data-builder-table (onRowsChange)="setCityMapPins($event)" *ngIf="tableCityUrl"
                                            [url]="tableCityUrl"></data-builder-table>
                    </app-master-card>
                </div>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>


