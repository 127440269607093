<div class="card bg-gradient-light">
  <ngx-spinner
    bdColor="rgba(51,51,51,0.76)"
    size="medium"
    color="#fff"
    type="timer"
    [fullScreen]="false"
  >
    <p style="color: white"> Caricamento... </p>
  </ngx-spinner>
  <div class="card-header">
    <h4>
      {{title}}
      <img [src]="logo" height="30px">
    </h4>
    <div class="card-tools">
    </div>
  </div>
  <!-- /.card-header -->
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div class="card mb-0" [ngClass]="bgClass">
          <div class="card-body">
            <div style="height: 250px;">
<!--              <ngx-charts-line-chart-->
<!--                class="mb-2"-->
<!--                legendTitle="Contratti"-->
<!--                [showRefLabels]="true"-->
<!--                [roundDomains]="true"-->
<!--                [xAxis]="true"-->
<!--                [yAxis]="true"-->
<!--                [showGridLines]="false"-->
<!--                xAxisLabel="Periodo"-->
<!--                yAxisLabel="Contratti"-->
<!--                [legend]="false"-->
<!--                [results]="chartData"-->
<!--                [showXAxisLabel]="false"-->
<!--                [showYAxisLabel]="true"-->
<!--              >-->
<!--              </ngx-charts-line-chart>-->
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4">
        <p class="summary-title">{{ summaryTitle }}</p>
        <div class="summary" *ngFor="let summary of summaries">
          <p class="title">{{summary.title}} <span>{{summary.label}}</span></p>
          <ngb-progressbar type="success" height="10px" [value]="summary.percentage"></ngb-progressbar>
        </div>
      </div>
    </div>
  </div>
  <!-- ./card-body -->
  <div class="card-footer">
    <div class="row icon-box-list">
      <div class="col-lg-3 col-6" *ngFor="let item of footerIcons">
        <div class="icon-box">
          <div class="icon bg-gradient-danger elevation-2 rounded-circle">
            <i [class]="item.icon"></i>
          </div>
          <div class="content">
            <p class="title">{{item.title}}</p>
            <p class="description">{{item.value}} {{item.suffix}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.card-footer -->
</div>
