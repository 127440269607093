import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from '@services/request.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Video, VideoCategory } from '@models/video';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { User } from '@app/models/user';
import { DaterangepickerConfig } from 'ng2-daterangepicker';

@Component({
    selector: 'app-video-index',
    templateUrl: './video-index.component.html',
    styleUrls: ['./video-index.component.scss'],
})
export class VideoIndexComponent implements OnInit {
    @ViewChild('videoListTemplate') private videoListTemplate;
    @ViewChild('videoFormTemplate') private videoFormTemplate;
    @ViewChild('categoryFormTemplate') private categoryFormTemplate;

    private videoListModal: NgbModalRef;
    private videoFormModal: NgbModalRef;
    private categoryFormModal: NgbModalRef;
    private user: User;

    categories: VideoCategory[] = [];
    busy: Subscription;
    busyVideoListModal: Subscription;
    busyVideoFormModal: Subscription;
    busyCategoryFormModal: Subscription;
    selectedCategory: VideoCategory;
    fileName: string;
    roles: string[] = ['operator', 'master'];
    showActionsButtons: boolean;
    disabledLogoFileInput: boolean;
    disabledLogoInput: boolean;
    selectedVideo: Video;
    featuredVideo: Video;
    videoForm: FormGroup;
    videoCategoryForm: FormGroup;
    videoModalTitle: string;
    categoryModalTitle: string;

    constructor(
        private dataService: RequestService,
        public formBuilder: FormBuilder,
        public modalService: NgbModal,
        private toastr: ToastrService,
        private authService: AuthenticationService,
        private dateRangePickerOptions: DaterangepickerConfig
    ) {
        this.logoFieldsValidator = this.logoFieldsValidator.bind(this);
        this.authService.currentUser.subscribe(user => this.user = user);
    }

    get videoConfirmDeleteOptions() {
        return new ConfirmOptionsService({
            title: 'Elimina video',
            text: 'Sei sicuro di voler rimuovere questo video?'
        });
    }

    get videoCategoryConfirmDeleteOptions() {
        return new ConfirmOptionsService({
            title: 'Elimina video categoria',
            text: 'Sei sicuro di voler rimuovere questo video categoria?',
        });
    }

    ngOnInit() {
        this.disabledLogoFileInput = false;
        this.disabledLogoInput = false;

        this.busy = this.dataService.get('videos/latest')
            .subscribe((data: any) => {
                this.featuredVideo = new Video(data);
            });

        this.busy = this.dataService.get('videos/category')
            .subscribe((data: any) => {
                this.categories = new VideoCategory().fromArray(data);
            });

        this.videoForm = this.formBuilder.group({
            title: [null, [Validators.required]],
            description: [null, [Validators.required]],
            video_path: [null, [Validators.required]],
            availability_range: [null, [Validators.required]],
        });

        this.videoCategoryForm = this.formBuilder.group({
            title: [null, [Validators.required]],
            description: [null, [Validators.required]],
            logo: [{ value: null, disabled: this.disabledLogoInput }],
            logo_file: [{ value: null, disabled: this.disabledLogoFileInput }],
        }, { validator: this.logoFieldsValidator });

        this.fileName = "Choose file ...";

        if (this.roles.indexOf(this.user.profile.role) > -1) {
            this.showActionsButtons = true;
        } else {
            this.showActionsButtons = false;
        }
    }


    logoFieldsValidator(group: FormGroup): { [s: string]: boolean } {
        if (group) {
            let logoVal = group.controls['logo'].value;
            let logoFileVal = group.controls['logo_file'].value;

            if (!logoVal && !logoFileVal) {
                this.disabledLogoInput = false;
                this.disabledLogoFileInput = false;
            } else if (logoVal && !logoFileVal) {
                this.disabledLogoInput = false;
                this.disabledLogoFileInput = true;
            }
            else if (!logoVal && logoFileVal) {
                this.disabledLogoInput = true;
                this.disabledLogoFileInput = false;
            }
            if (group.controls['logo'].value || group.controls['logo_file'].value) {
                return null;
            }
        }
        return { 'error': true };
    }

    onDragChange(data: VideoCategory[] = []) {
        const payload = data.map((category, index) => {
            return {
                id: category.id,
                order: index
            }
        });
        this.dataService.put(`video/reorder-categories`, payload)
            .subscribe((data: any) => {
                this.toastr.success("Ordine delle categorie aggiornato.", "Notifica");
            }, (err: any) => {
                this.toastr.error("Aggiorna errore di ordine delle categorie.", "Errore")
            })
    }

    showList(category: VideoCategory) {
        this.selectedCategory = category;
        this.categoryModalTitle = `Video nella categoria ${category.title}`;

        if (this.selectedCategory.video_count && this.selectedCategory.videos.length != this.selectedCategory.video_count) {
            this.busy = this.dataService.get(`videos/category/${this.selectedCategory.id}`, { expand: 'created_by' })
                .subscribe((data: any) => {
                    this.selectedCategory.videos = new Video().fromArray(data);
                    this.videoListModal = this.modalService.open(this.videoListTemplate, { size: 'xl' });
                    this.videoListModal.result.then((res) => { }).catch((res) => { });
                });
        } else {
            this.videoListModal = this.modalService.open(this.videoListTemplate, { size: 'xl' });
            this.videoListModal.result.then((res) => { }).catch((res) => { });
        }
    }

    showCreateVideoModal(category: VideoCategory) {
        this.selectedVideo = new Video({ id_category: category.id });
        this.videoModalTitle = `Aggiungi video alla categoria ${category.title}`;
        this.videoFormModal = this.modalService.open(this.videoFormTemplate, { size: 'lg' });
        this.videoFormModal.result.then((res) => { }).catch((res) => { });
    }

    showUpdateCategoryModal(category: VideoCategory) {
        this.selectedCategory = new VideoCategory(category);
        this.categoryModalTitle = `Aggiungi video alla categoria ${category.title}`;
        this.categoryFormModal = this.modalService.open(this.categoryFormTemplate, { size: 'lg' });
        this.categoryFormModal.result.then((res) => { }).catch((res) => { });
    }

    showCreateCategoryModal() {
        this.selectedCategory = new VideoCategory();
        this.categoryModalTitle = "Aggiungi video alla categoria";
        this.categoryFormModal = this.modalService.open(this.categoryFormTemplate, { size: 'lg' });
        this.categoryFormModal.result.then((res) => { }).catch((res) => { });
    }

    showUpdateVideoModal(video: Video) {
        this.selectedVideo = new Video(video);
        this.videoModalTitle = `Aggiorna il video ${video.title}`;
        this.selectedVideo.availability_range = this.getDateRangeString(this.selectedVideo.available_from, this.selectedVideo.available_to);
        this.videoFormModal = this.modalService.open(this.videoFormTemplate, { size: 'lg' });
        this.videoFormModal.result.then((res) => { }).catch((res) => { });
    }

    setSelectedVideoAvailability(from: Moment, to: Moment) {
        let selectedDateRange = this.getDateRangeString(from, to);

        this.selectedVideo.available_from = from.format('YYYY-MM-DD');
        this.selectedVideo.available_to = to.format('YYYY-MM-DD');
        this.selectedVideo.availability_range = selectedDateRange;
        this.videoForm.get('availability_range').setValue(selectedDateRange);
    }

    deleteCategory(category: VideoCategory) {
        let request = this.dataService.delete(`videos/category/${category.id}`);

        this.busyCategoryFormModal = request.subscribe((data: any) => {
            if (data === null) {
                this.deleteLocalStateCategory(category);
                this.toastr.success("Categoria eliminata con successo", "Notifica");
            }
        }, (err: any) => this.toastr.error("Si è verificato un errore durante l'eliminazione della categoria", "Errore"));
    }

    deleteVideo(video: Video) {
        let request = this.dataService.delete(`videos/${video.id}`);

        this.busyCategoryFormModal = request.subscribe((data: any) => {
            if (data === null) {
                this.deleteLocalStateVideo(video);
            }
        });
    }

    saveCategory(category: VideoCategory) {
        const formData = new FormData();
        const properties = ['id', 'title', 'description', 'logo', 'logo_file'];
        properties.forEach(prop => {
            if (category && category[prop]) {
                formData.append(prop, category[prop])
            }
        });

        if (category && !category.id) {
            formData.append("order", this.categories.length.toString());
        }

        let isNew = category.id ? false : true;
        let request = isNew ? this.dataService.post(`videos/category?expand=created_by`, formData) : this.dataService.post(`videos/category/${category.id}?expand=created_by`, formData);

        this.busyCategoryFormModal = request.subscribe((data: any) => {
            let savedCategory = new VideoCategory(data);
            savedCategory.isNew = isNew;

            this.updateLocalStateCategory(savedCategory);
            this.categoryFormModal.close();
            this.toastr.success(`Categoria ${isNew ? 'creata' : 'aggiornata'} con successo`, "Notifica");
        }, (err: any) => this.toastr.error(`Si è verificato un errore durante la ${isNew ? 'creazione' : 'aggiornamento'} della categoria`, "Errore"));
    }

    saveVideo(video: Video) {
        let payload = {
            ...(video.id_category ? { id_category: video.id_category } : {}),
            ...(video.video_path ? { video_path: video.video_path } : {}),
            ...(video.title ? { title: video.title } : {}),
            ...(video.description ? { description: video.description } : {}),
            ...(video.available_from ? { available_from: video.available_from } : {}),
            ...(video.available_to ? { available_to: video.available_to } : {}),
            ...(video.duration ? { duration: video.duration } : {})
        };

        let isNew = video.id ? false : true;
        let request = isNew ? this.dataService.post(`videos?expand=created_by`, payload) : this.dataService.put(`videos/${video.id}?expand=created_by`, payload);

        this.busyVideoFormModal = request.subscribe((data: any) => {
            let savedVideo = new Video(data);
            savedVideo.isNew = isNew;

            this.updateLocalStateWithVideo(savedVideo);
            this.videoFormModal.close();
        });
    }

    updateLocalStateCategory(category: VideoCategory) {
        for (let i in this.categories) {
            if (this.categories[i].id == category.id) {
                this.categories[i] = category;
                return;
            }
        }
        let newCategory = this.categories.find(c => {
            return c.id !== category.id;
        })
        if (newCategory || this.categories.length === 0) {
            this.categories.push(category);
        }
    }

    deleteLocalStateCategory(category: VideoCategory) {
        let index = this.categories.findIndex(function (o) {
            return o.id === category.id;
        })
        if (index !== -1) {
            this.categories.splice(index, 1);
        }
    }

    deleteLocalStateVideo(video: Video) {
        let categoryIndex = this.categories.findIndex(function (o) {
            return o.id === video.id_category;
        });

        if (categoryIndex !== -1) {
            let videoIndex = this.categories[categoryIndex].videos.findIndex(function (o) {
                return o.id === video.id;
            });

            if (videoIndex !== -1) {
                this.categories[categoryIndex].video_count--;
                this.categories[categoryIndex].videos.splice(videoIndex, 1);
            }
        }
    }

    updateLocalStateWithVideo(video: Video) {
        for (let i in this.categories) {
            if (this.categories[i].id == video.id_category) {
                if (video.isNew) {
                    let hadNoVideos = this.categories[i].video_count == 0;
                    let hadLoadedVideos = (this.categories[i].video_count > 0 && this.categories[i].videos.length > 0);
                    if (hadNoVideos || hadLoadedVideos) {
                        this.categories[i].videos.unshift(video);
                    }
                    this.categories[i].video_count++;
                } else {
                    for (let j in this.categories[i].videos) {
                        if (this.categories[i].videos[j].id == video.id) {
                            this.categories[i].videos[j] = video;
                            return;
                        }
                    }
                }
                return;
            }
        }
    }

    handleFileInput(files: FileList) {
        if (files && files.item(0)) {
            const logoFile = files.item(0);
            const isImage = logoFile.type.includes('image') ? true : false;
            if (!isImage) {
                this.toastr.error("tipo di file non supportato", "Errore");
                return;
            } else {
                this.fileName = logoFile.name;
                this.selectedCategory.logo_file = logoFile;
            }
        }
    }

    private getDateRangeString(startDate: any, endDate: any) {
        let localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        let start = moment(startDate);
        let end = moment(endDate);

        return start.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + end.format(localDateFormat);
    }

}

