import {KenaService} from '@services/kena.service';
import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-kena-recap',
    templateUrl: './kena-recap.component.html',
    styleUrls: ['./kena-recap.component.css'],
})
export class KenaRecapComponent implements OnInit {
    show = {
        sales: false,
        productivesMnp: false,
        productives5Mnp: false,
        mnp: false,
        soccer: false,
        openings: false,
        smartphoneSales: false
    };

    cards = [
        { title: 'Analisi Produttivi con 5 MNP', url: 'sales/recap-productives-5-mnp', show: false },
        { title: 'Analisi Produttivi MNP', url: 'sales/recap-productives-mnp', show: false },
        { title: 'Analisi Produzione Sim', url: 'sales/recap-production-sales', show: false },
        { title: 'Analisi Produzione MNP', url: 'sales/recap-production-mnp', show: false },
        { title: 'Analisi Produzione ILIAD', url: 'sales/recap-production-iliad', show: false },
        { title: 'Analisi Produttivi Calcio', url: 'sales/recap-production-soccer', show: false },
        { title: 'Analisi Affiliazioni', url: 'affiliations/recap-openings', show: false },
        { title: 'Analisi Vendite Smartphone', url: 'shop-orders/recap-smartphones?type=smartphones', show: false },
        { title: 'Analisi Vendite Accessori', url: 'shop-orders/recap-smartphones?type=accessories', show: false },
        { title: 'Analisi KDCube', url: 'sales/recap-kd', show: false },
        { title: 'Avanzamento Target ad Oggi', url: 'sales/recap-target', show: false },
        { title: 'Avanzamento Target in Proiezione', url: 'sales/recap-target-projection', show: false },
    ]

    cardsAm = [
        { title: 'Analisi Produttivi con 5 MNP', url: 'sales/recap-productives-5-mnp?areamanager=true', show: false },
        { title: 'Analisi Produttivi MNP', url: 'sales/recap-productives-mnp?areamanager=true', show: false },
        { title: 'Analisi Produzione Sim', url: 'sales/recap-production-sales?areamanager=true', show: false },
        { title: 'Analisi Produzione MNP', url: 'sales/recap-production-mnp?areamanager=true', show: false },
        { title: 'Analisi Produzione ILIAD', url: 'sales/recap-production-iliad?areamanager=true', show: false },
        { title: 'Analisi Produttivi Calcio', url: 'sales/recap-production-soccer?areamanager=true', show: false },
        { title: 'Analisi Affiliazioni', url: 'affiliations/recap-openings?areamanager=true', show: false },
        { title: 'Analisi Vendite Smartphone', url: 'shop-orders/recap-smartphones?areamanager=true&type=smartphones', show: false },
        { title: 'Analisi Vendite Accessori', url: 'shop-orders/recap-smartphones?areamanager=true&type=accessories', show: false },
        { title: 'Analisi KDCube', url: 'sales/recap-kd?areamanager=true', show: false },
        { title: 'Avanzamento Target ad Oggi', url: 'sales/recap-target?areamanager=true', show: false },
        { title: 'Avanzamento Target in Proiezione', url: 'sales/recap-target-projection?areamanager=true', show: false },
    ]

    constructor() {
    }

    ngOnInit() {
    }
}
