import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import * as moment from 'moment';
import {EPayService} from '@services/e-pay.service';
import {ShopBrand} from '@models/e-pay/shop-brand';
import {AuthenticationService} from '@services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ActivatedRoute} from '@angular/router';
import {KdOrderService} from '@services/kd-order.service';
import {User} from '@models/user';
import {Moment} from 'moment';
import {Certificates, CertificatesProvider, Status, Tickets} from '@models/kd-orders';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.css']
})
export class CertificatesComponent implements OnInit {

  orders: Certificates[] = [];
  //selectedOrder: ShopOrder;
  ordersPagination: MetaPagination;

  selectedData: Certificates;

  @ViewChild('detailTemplate') private detailTemplate;

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    }
  };

  filter = {
    dealer_id: '',
    date: "",
    status: '',
    transaction_id: "",
    total_price: "",
    service: ""
  };

  localFormat = 'dd/MM/yyyy HH:MM';
  apiDates = {
    date: {
      from: '',
      to: ''
    }
  };

  apiDate = {
    from: '',
    to: ''
  };

  status = [
    {
      lable: "Completed",
      key: "completed"
    },
    {
      lable: "Failed",
      key: "failed"
    },
    {
      lable: "Processing",
      key: "processing"
    }
  ]

  types = EPayService.productTypes;
  currency = EPayService.currency;
  statuses = Status.list;
  @ViewChild('receiptFormTemplate') private receiptFormTemplate;
  brandsLoading = false;
  brands: ShopBrand[] = [];
  isDealer = false;


  constructor(private ePayService: EPayService,
              private authenticationService: AuthenticationService,
              private spinner: NgxSpinnerService,
              public modalService: NgbModal,
              private dateRangePickerOptions: DaterangepickerConfig,
              private CertificatesService: KdOrderService,
              public route: ActivatedRoute) {
        this.authenticationService.currentUser.subscribe((user: User) => {
        this.isDealer = user && user.profile.role === 'dealer';
      }
  );}

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadOrders();
    setTimeout(() => {
    });
  }

  loadOrders() {
    this.spinner.show('table-orders');
    const params = {};
    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }
    if(this.filter.transaction_id) {
      params['transaction_id'] = this.filter.transaction_id;
    }
    if(this.filter.total_price) {
      params['total_price'] = this.filter.total_price;
    }
    if(this.filter.service) {
      params['service'] = this.filter.service;
    }

    this.CertificatesService.getCertificates(params).subscribe((response: CertificatesProvider) => {
      this.orders = response.data;
      this.ordersPagination = response._meta;
      this.spinner.hide('table-orders');
    }, err => {
      this.spinner.hide('table-orders');
    });
  }


  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat)
        + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  showDetailModal(data?: Certificates) {
    this.selectedData = data;
    this.modalService.open(this.detailTemplate, {size: 'lg', scrollable: true});
  }

}
