<app-master-header
  cardClass="bg-gradient-1"
  title="Controllo ordini"
  [image]="'flats/payment.png' | assets"
 
>
  <p class="lead">
    Da qui puoi effettuare dei controlli del saldo di ogni dealer
  </p>
</app-master-header>


<!-- tab -->

<ul ngbNav #nav="ngbNav" class="mt-2 mb-3 mx-auto">
    <li ngbNavItem>
        <a class="btn btn-light px-2" ngbNavLink (click)="selectTab('0'); refreshData()" translate>Bonifico</a>
        <ng-template ngbNavContent>
            <app-orders-control-bonifico #listaBonifici [currentDate]="apiDate" [filterParams]="filter" (updateCompleted)="loadOrders()"></app-orders-control-bonifico>
        </ng-template>
    </li>
    <li ngbNavItem>
      <a class="btn btn-light px-2"  ngbNavLink (click)="selectTab('1'); refreshData()" translate>Contrassegno</a>
      <ng-template ngbNavContent>
         <app-orders-control-contrassegno #listaContrassegno [currentDate]="apiDate" [filterParams]="filter" (updateCompleted)="loadOrders()"></app-orders-control-contrassegno>
      </ng-template>
  </li>
    <li ngbNavItem>
        <a class="btn btn-light px-2"  ngbNavLink (click)="selectTab('2'); refreshData()" translate>Carta di credito</a>
        <ng-template ngbNavContent>
           <app-orders-control-carta-di-credito #listaCartaDiCredito [currentDate]="apiDate" [filterParams]="filter" (updateCompleted)="loadOrders()"></app-orders-control-carta-di-credito>
        </ng-template>
    </li>
    <!-- <li ngbNavItem>
        <a class="btn btn-light px-2" ngbNavLink (click)="selectTab('3'); refreshData()" translate>Compensazione</a>
        <ng-template ngbNavContent>
           <app-orders-control-compensazione #listaPerCompensazione [currentDate]="apiDate" [filterParams]="filter" (updateCompleted)="loadOrders()"></app-orders-control-compensazione>
        </ng-template>
    </li>
    <li ngbNavItem>
      <a class="btn btn-light px-2"  ngbNavLink (click)="selectTab('4'); refreshData()" translate>Rimessa Agente</a>
      <ng-template ngbNavContent>
         <app-orders-control-rimessa-agente #listaRimessaAgente [currentDate]="apiDate" [filterParams]="filter" (updateCompleted)="loadOrders()"></app-orders-control-rimessa-agente>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a class="btn btn-light px-2" ngbNavLink (click)="selectTab('5'); refreshData()" translate>Assegno Corriere</a>
      <ng-template ngbNavContent>
         <app-orders-control-assegno-vista-corriere #listaAssegnoVistaCorriere [currentDate]="apiDate" [filterParams]="filter" (updateCompleted)="loadOrders()"></app-orders-control-assegno-vista-corriere>
      </ng-template>
    </li> -->
    <li ngbNavItem>
      <a class="btn btn-light px-2"  ngbNavLink (click)="selectTab('6'); refreshData()" translate>Omaggio</a>
      <ng-template ngbNavContent>
        <app-orders-control-omaggio #listaOmaggio [currentDate]="apiDate" [filterParams]="filter" (updateCompleted)="loadOrders()"></app-orders-control-omaggio>
      </ng-template>
    </li>
    <!-- <li ngbNavItem>
      <a class="btn btn-light px-2"  ngbNavLink (click)="selectTab('7'); refreshData()" translate>Compensazione anticipata</a>
      <ng-template ngbNavContent>
         <app-orders-control-compensazione-anticipata #listaCompensazioneAnticipata [currentDate]="apiDate" [filterParams]="filter" (updateCompleted)="loadOrders()"></app-orders-control-compensazione-anticipata>
      </ng-template>
  </li> -->
</ul>

<!-- filter -->
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="refreshData()">
        <div class="form-row">
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="dealer_id">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                    id="dealer_id"></app-retailer-typeahead>
                </div>   
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">Status</label>
                   <!--STATUS -->
                   <ng-select [clearable]="true" class="disableClear" [(ngModel)]="filter.saldato"
                   [items]="saldato" bindLabel="label"
                   name="saldato" id="saldato"
                   bindValue="key" [searchable]="true"
                   ></ng-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date</label>
                    <input id="documentDate" autocomplete="off" type="text" daterangepicker [options]="pickerOptions"
                    [(ngModel)]="filter.date" name="date" class="form-control"
                    (selected)="generateDateRangeString($event.start, $event.end)"
                />
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- totale -->
<div class="row" *ngIf="reportControlOrders">
  <div class="col-md-4 col-sm-4 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-info"
          ><i class="fa fa-euro-sign"></i
        ></span>
  
        <div class="info-box-content">
          <span class="info-box-text">Totale ordini</span>
          <span class="info-box-number">{{reportControlOrders.totale_ordini_effettuati
          }}</span>
        
        </div>
      </div>

  </div>

  <div class="col-md-4 col-sm-4 col-12">
      <div class="info-box elevation-1">
          <span class="info-box-icon bg-success"
          ><i class="fa fa-cart-plus"></i
          ></span>
          <div class="info-box-content">
          <span class="info-box-text">Totale saldato</span>
          <span class="info-box-number">{{reportControlOrders.totale_saldato  === null ?  '-' : reportControlOrders.totale_saldato  | currency:'€' }}</span>
        </div>
      </div>
  </div>

  <div class="col-md-4 col-sm-4 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-warning"
          ><i class="far fa-copy"></i
        ></span>
  
        <div class="info-box-content">
          <span class="info-box-text">Totale da saldare</span>
          <span class="info-box-number">{{reportControlOrders.totale_da_saldare  === null ?  '-' : reportControlOrders.totale_da_saldare  | currency:'€'}}</span>
        </div>
      </div>
  </div>
</div>

<div [ngbNavOutlet]="nav"></div>