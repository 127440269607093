import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Kpi } from '@app/models/kpi';
import { KpiService } from '@app/services/kpi.service';

@Component({
  selector: 'app-kpi-select',
  templateUrl: './kpi-select.component.html',
  styleUrls: ['./kpi-select.component.css']
})
export class KpiSelectComponent implements OnInit {

  @Input()
  small = false;
  @Input()
  disabled = false;

  @Input()
  kpiValue: any;

  @Input()
  vendors: [];


  data: Kpi[] = [];

  @Output() onChange = new EventEmitter();

  loading = false;


  constructor(
    private service: KpiService
  ) { }

  ngOnInit(): void {
    if (this.kpiValue) {
      this.kpiValue = this.kpiValue.toString();
    }

    this.loading = true;
    this.service.selectKpiOptions().subscribe((data) => {
        this.data = new Kpi().fromArray(data);
        this.loading = false;
    });
  }

  public reset() {
    this.kpiValue = null;
  }
  
}


