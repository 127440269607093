<div class="modal-header">
  <h4 class="modal-title">Conferma modifica</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Data incasso:</p>
  <input type="date" [(ngModel)]="data" class="form-control" />
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="confirm()">Conferma</button>
  <button type="button" class="btn btn-danger" (click)="close()">Esci</button>
</div>
