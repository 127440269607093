<div class="container">
    <table class="table">
        <thead>
          <tr>
            <th style="width: 5%;">#</th>
            <th style="width: 15%;">Data accredito</th>
            <th style="width: 30%;">Descrizione</th>
            <th class="text-right" style="width: 20%;">Totale</th>
            <th class="text-right" style="width: 15%;">Saldato</th>
            <th class="text-right" style="width: 15%;">Da saldare</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let item of transactionSuggested">
            <th>
                <div class="input-group-prepend m-0">
                    <div class="input-group-text p-0">
                    <input type="checkbox" 
                    [ngModel]="item.selected" (change)="toggleSelection(item)"
                    aria-label="Checkbox for following text input">
                    </div>
                </div>
            </th>
            <td>{{item.execution_date}}</td>
            <td>{{item.description}}</td>
            <td class="text-right"><b>{{item.amount}} €</b></td>
            <td class="text-success text-right">{{item.total_paid | currency:'€' }}</td>
            <td class="text-danger text-right">{{item.total_to_be_paid | currency:'€' }}</td>
          </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="6" class="text-right px-0 pb-0">
                    <button class="btn btn-primary text-uppercase px-4 py-1"
                    (click)="sendData()"
                    >Salva 
                    <span *ngIf="showSpinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                </td>
            </tr>
        </tfoot>
    </table>
</div>