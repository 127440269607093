<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadList()">
    <div class="form-row">
      <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="companyName">Company name</label>
          <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="companyName"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-xxl-6 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Dealer ID</label>
          <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control" />
        </div>
      </div>
      <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Date</label>
          <input
            id="documentDate"
            type="text"
            daterangepicker
            autocomplete="off"
            [options]="pickerOptions"
            [(ngModel)]="filter.date"
            name="date"
            class="form-control"
            (selected)="generateDateRangeString($event.start, $event.end)"
          />
        </div>
      </div>
      <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <button class="btn btn-info btn-block" translate type="submit">Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Tabella -->

<div class="card">
  <div class="card-header">
    <h4 class="mb-0 lead" translate>KASKOPOINT</h4>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-orders" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped">
      <thead class="border-bottom border-info">
        <tr>
          <th>Espandi</th>
          <th translate>Company name</th>
          <th translate>ID Transazione</th>
          <th translate>Prezzo Dealer</th>
          <th translate>Prezzo Cliente</th>
          <th translate>Date</th>
          <th translate class="text-center">Status</th>
          <th translate class="text-center">Contratto</th>
        </tr>
      </thead>
      <tbody class="bg-gradiend-white">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders" [fullScreen]="false">
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngIf="kaskoList.length == 0">
          <td translate colspan="9" class="text-center">List is empty</td>
        </tr>
        <ng-container *ngFor="let item of kaskoList">
          <tr>
            <td>
              <button class="btn btn-outline-info btn-sm text-center" type="button" (click)="item.show = !item.show" role="button" aria-expanded="false" translate>
                <i class="fas fa-plus mr-0" *ngIf="!item.show"></i>
                <i class="fas fa-minus mr-0" *ngIf="item.show"></i>
              </button>
            </td>
            <td>
              <a [routerLink]="['/dealer', item.dealer_id]" target="_blank">{{ item.company_name }}</a>
            </td>
            <td>
              {{ item?.transaction_id }}
            </td>
            <td>
              {{ item?.dealer_price | currency : '€' }}
            </td>
            <td>
              {{ item?.customer_price | currency : '€' }}
            </td>
            <td>
              {{ item?.created_at | amDateFormat : 'DD/MM/YYYY - HH:mm' }}
            </td>
            <td class="text-center">Status</td>
            <!-- <td> 
                        <button href="javascript:void(0)" class="btn btn-sm text-uppercase btn-block"
                        (click)="openPopover(plafondStatusPopover,a2aOrder?.id,a2aOrder?.status,'status',statuses)"
                        #plafondStatusPopover="ngbPopover" autoClose="outside" triggers="manual"
                        [ngbPopover]="popContent" popoverTitle="Cambia valore"
                        [ngClass]="{
                           'btn-info' : a2aOrder.status === 'pending', 
                           'btn-success': a2aOrder.status === 'confirmed',
                           'btn-danger': a2aOrder.status === 'canceled'
                        }"
                        >
                        {{a2aOrder?.orderStatus}}
                        </button>
                    </td> -->
            <td class="text-center">
              <i class="fas fa-download text-primary cursor-hand" (click)="downloadZip(item.id)"></i>
            </td>
          </tr>

          <!-- Se è show -->
          <tr *ngIf="item.show">
            <td colspan="12" class="p-0 border border-secondary">
              <table class="table mb-0">
                <thead class="">
                  <!-- header -->
                  <td colspan="12" class="bg-secondary">
                    <p class="text-center text-sm text-uppercase">Dati cliente</p>
                  </td>
                  <tr style="background: #eee">
                    <th scope="col">Nome</th>
                    <th scope="col">Cognome</th>
                    <th scope="col">Cod. Fiscale</th>
                    <th scope="col">Email</th>
                    <th scope="col">Telofono</th>
                    <th scope="col">Totale pagato</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white">
                    <td>
                      <p>{{ item.objectPassed.name === null ? '-' : item.objectPassed.name }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.surname === null ? '-' : item.objectPassed.surname }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.fiscalCode === null ? '-' : item.objectPassed.fiscalCode }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.mail === null ? '-' : item.objectPassed.mail }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.phone === null ? '-' : item.objectPassed.phone }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.totalAmount | currency : '€' }}</p>
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr style="background: #eee">
                    <th scope="col">Regione</th>
                    <th scope="col">Comune</th>
                    <th scope="col">Provincia</th>
                    <th scope="col">CAP</th>
                    <th scope="col">Indirizzo</th>
                    <th scope="col">Civico</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white">
                    <td>
                      <p>{{ item.objectPassed.region === null ? '-' : item.objectPassed.regionName }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.city === null ? '-' : item.objectPassed.cityName }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.province === null ? '-' : item.objectPassed.provinceName }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.zipCode === null ? '-' : item.objectPassed.zip_code }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.address === null ? '-' : item.objectPassed.address }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.civicNumber === null ? '-' : item.objectPassed.civicNumber }}</p>
                    </td>
                  </tr>
                </tbody>
                <!-- header -->
                <td colspan="12" class="bg-secondary">
                  <p class="text-center text-sm text-uppercase">Dati Polizza</p>
                </td>
                <thead class="">
                  <tr style="background: #eee">
                    <th scope="col">Polizza Acquistata</th>
                    <th scope="col">Marca Dispositivo</th>
                    <th scope="col">Modello</th>
                    <th scope="col">IMEI</th>
                    <th scope="col">Memoria</th>
                    <th scope="col">Sku</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white">
                    <td>
                      <p>{{ item.objectPassed.productName === null ? '-' : item.objectPassed.productName }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.brandName === null ? '-' : item.objectPassed.brandName }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.deviceName === null ? '-' : item.objectPassed.deviceName }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.imei === null ? '-' : item.objectPassed.imei }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.memory === null ? '-' : item.objectPassed.memory }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.sku === null ? '-' : item.objectPassed.sku }}</p>
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col">Prezzo polizza</th>
                    <th scope="col">Durata polizza</th>
                    <th scope="col">Data ordine polizza</th>
                    <th scope="col">Data acquisto polizza</th>
                    <th scope="col" colspan="2">Note</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white">
                    <td>
                      <p>{{ item.objectPassed.totalAmount | currency : '€' }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.months === null ? '-' : item.objectPassed.months }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.orderDate | amDateFormat : 'DD/MM/YYYY - HH:mm' }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.purchaseDate | amDateFormat : 'DD/MM/YYYY - HH:mm' }}</p>
                    </td>
                    <td>
                      <p>{{ item.objectPassed.note === 'none' ? 'Nessuna nota' : item.objectPassed.note }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <span
      ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b
      >{{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.<a class="link" (click)="exportList()"> Esporta la lista completa</a></span
    >
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      (pageChange)="loadList()"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>
