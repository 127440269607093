import { Component, OnInit } from "@angular/core";
import { KenaService } from "@services/kena.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-kena-visite",
  templateUrl: "./kena-visite.component.html",
  styleUrls: ["./kena-visite.component.css"],
})
export class KenaVisiteComponent implements OnInit {
  retailers = [];
  days = [];
  visits_by_day = [];
  total;
  date_info;
  date: {
    full_month: string;
    month: string;
    year: string;
    prev_month: string;
    prev_year: string;
  };

  defaultMonth;
  period;
  apiFormat = "YYYY-MM";

  agent: number = null;

  constructor(
    private service: KenaService,
    private spinner: NgxSpinnerService
  ) {
    this.defaultMonth = [moment().startOf("month")];
  }

  ngOnInit() {}

  setSelectedDateRange(date: any) {
    this.period = moment(date).format(this.apiFormat);
    this.date = {
      full_month: moment(date).format("MMMM"),
      month: moment(date).format("MM"),
      year: moment(date).format("YYYY"),
      prev_month: moment(date).subtract(1, "months").format("MM"),
      prev_year: moment(date).subtract(1, "months").format("YYYY"),
    };
    this.refresh();
  }

  setAgent($event) {
    this.agent = $event;
    this.refresh();
  }

  refresh() {
    if (this.period) {
      this.spinner.show();
      this.service.visits(this.period, this.agent).subscribe((data) => {
        this.total = data.agent;
        this.days = data.date.days;
        this.retailers = data.retailers;
        this.date_info = data.date;
        this.visits_by_day = data.visits_by_day;
        this.spinner.hide();
      });
    }
  }

  isVisitedInDay(day, pdv) {
    return (
      pdv.visits.length > 0 && pdv.visits.find((item) => item.date === day)
    );
  }

  visitWithResults(day, pdv) {
    if (pdv.visits.length > 0) {
      let visit = pdv.visits.find((item) => item.date === day);

      return visit && visit.results.length > 0;
    }

    return false;
  }

  showVisitPopover(popover, pdv, day) {
    let visit = pdv.visits.find((item) => item.date === day);

    if (visit.results.length > 0) {
      if (popover.isOpen()) {
        popover.close();
      } else {
        popover.open({ visit });
      }
    }
  }
}
