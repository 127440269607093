import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';
import {DealerView} from '@models/dealer-view';

export class DigitalSigniture {
    public static orderStatuses = [
        {
            label: 'Processed',
            key: 'processed'
        },
        {
            label: 'Canceled',
            key: 'canceled'
        },
        {
            label: 'Processing',
            key: 'processing'
        },
    ];
    company_name: string;
    dealer_id: number;
    status: string;
    created_at: string;
    total_price: string;
    form: {
        email: string;
    };
    type: string;
    signature_id: string;

}

export class DigitalSignitureProvider extends BaseModel {
    data: DigitalSigniture[];
    _meta: MetaPagination;
    _links: Array<any>;
}

//new
export class SpidCredit extends BaseModel {
    
    id: number;
    dealer_id: number;
    order_id: number;
    amount: number;
    created_at: string;
    created_by: number;
    company_name: string;
    company_owner: string;
    is_credit: boolean;
    current_sum: string;
    total_amount: string;
    note: string;
    total_transaction: string;
    debit: string;
    creator: string;

    fromArray(result: []): SpidCredit[] {
        const array: SpidCredit[] = [];
        for (const item of result) {
            array.push(new SpidCredit(item));
        }
        return array;
    }

}
export class SpidCreditReport extends BaseModel {
    credit: string;
    debit: string;
    total_amount: string;
    total_transaction: string;
}

export class SpidCreditPagination extends BaseModel {
    data: SpidCredit[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class SpidCreditProvider extends BaseModel {
    data: DigitalSigniture[];
    _meta: MetaPagination;
    _links: Array<any>;
}

//end

export class SpidIr extends BaseModel{
    
    company_name: string;
    dealer_id: number;
    fiscal_code: string;
    booked_url: string;
    token: string;
    created_at: string;
    created_date: number;

    fromArray(result: []): SpidIr[] {
        const array: SpidIr[] = [];
        for (const item of result) {
            array.push(new SpidIr(item));
        }
        return array;
    }
}

export class SpidIrProvider extends BaseModel {
    data: SpidIr[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class Amex extends BaseModel{

    public static orderStatus= [
        {
            label: 'In attesa',
            key: 'pending'
        },
        {
            label: 'Confermato',
            key: 'confirmed'
        },
        {
            label: 'Cancellato',
            key: 'canceled'
        },
    ];
    static orderStatusType = {
        'pending': 'In attesa',
        'confirmed': 'Confermato',
        'canceled': 'Cancellato'
    };
    
    id: number;
    company_name: string;
    dealer_id: number;
    vat: string;
    phone: string;
    email: string;
    created_at: string;
    created_date: number;
    status: string;
    show = false;
    file_path: string;
    
    //dati azienda 
    company_type:string;
    name:string;
    surname:string;
    city: string;
    address: string;
    province: string;
    zip_code: string;
    sales:string;
    sector:string
    
    //dati referral
    referral_name:string;
    referral_surname:string;
    referral_email:string;
    dealer: string;

    //dati avanzamento
    opportunity_name : string;
    lead_status : string;
    lead_sub_status : string; 
    stage : string;

    get orderStatus(){
        return Amex.orderStatusType[this.status];
    }

    fromArray(result: []): Amex[] {
        const array: Amex[] = [];
        for (const item of result) {
            array.push(new Amex(item));
        }
        return array;
    }
}

export class AmexProvider extends BaseModel {
    data: Amex[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class PostalSlip extends BaseModel {
    public static orderPayments = [
        {
            label: 'Contanti',
            key: 'CASH'
        },
        {
            label: 'Carta di credito',
            key: 'MN_POS'
        },
    ];
    public static orderTypes = [
        {
            label: 'Bollettino',
            key: 'ps'
        },
        {
            label: 'PagoPA',
            key: 'pa'
        },
        {
            label: 'MAV',
            key: 'mr'
        },
    ];
    public static bolletinStatus = [
        {
            label: 'Aperto',
            key: 0
        },
        {
            label: 'Chiuso',
            key: 1
        },
    ];
    static paymentType = {
        'CASH': 'Contanti',
        'MN_POS': 'Carta di credito'
    };
    static slipType = {
        'PS': 'Bollettino',
        'PA': 'PagoPA',
        'MR': 'MAV/RAV'
    };
    static slipStatus = {
        'EXECUTED': 'Eseguito',
    };
    company_name: string;
    dealer_id: number;
    tracking_code: string;
    total_price: string;
    commission: string;
    payment: string;
    closed: number;
    status: string;
    created_at: string;
    type: string;

    get paymentType(){
        return PostalSlip.paymentType[this.payment];
    }

    get slipType(){
        return PostalSlip.slipType[this.type];
    }

    get  slipStatus(){
        return PostalSlip.slipStatus[this.status];
    }


    fromArray(result: []): PostalSlip[] {
        const array: PostalSlip[] = [];
        for (const item of result) {
            array.push(new PostalSlip(item));
        }
        return array;
    }
}

export class PostalSlipProvider extends BaseModel {
    data: PostalSlip[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class Certificates {
    company_name: string;
    dealer_id: number;
    dealer: object;
    status: string;
    created_at: string;
    total_price: string;
    transaction_id: string;
    service: string;
    items: {
        name: string;
    };

}

export class CertificatesProvider extends BaseModel {
    data: Certificates[];
    _meta: MetaPagination;
    _links: Array<any>;
}


export class Pec {
    public static pecStatuses = [
        {
            label: 'Registered',
            key: 'registered'
        },
        {
            label: 'In Activation',
            key: 'in activation'
        },
        {
            label: 'Activating',
            key: 'activating'
        },
        {
            label: 'Activated',
            key: 'activated'
        },
        {
            label: 'Revoked',
            key: 'revoked'
        },
        {
            label: 'Closed',
            key: 'closed'
        },
    ];
    dealer_id: number;
    company_name: string;
    status: string;
    created_at: string;
    email: string;
    email_type: string;
    email_id: string;
    activation_date: string;
    expiration_date: string;
}

export class PecProvider extends BaseModel {
    data: Pec[];
    _meta: MetaPagination;
    _links: Array<any>;
}


export class Packages {
    company_name: string;
    dealer_id: number;
    status: string;
    created_at: string;
    shipping_code: string;
    pickup_date: string;
    dealer_price: string;
    courier: string;
    reference: string;
    items: {
        sender: string;
        pickup: string;
        recipient: string;
        boxes: string;
    };
    dealer: DealerView;
}

export class PackagesProvider extends BaseModel {
    data: Packages[];
    _meta: MetaPagination;
    _links: Array<any>;
}


export class PrintingList {
    company_name: string;
    dealer: object;
    dealer_id: number;
    status: string;
    created_at: string;
    total_price: string;
    email: string;
    api_id: string;
    type: string;
    shipping_price: string;
    reference: string;
    note: string;
    items: {
        reference: string;
        name: string;
        price: string;
        quantity: number;
    }[];
}

export class PrintingListProvider extends BaseModel {
    data: PrintingList[];
    _meta: MetaPagination;
    _links: Array<any>;
}


export class Shipping {
    company_name: string;
    dealer_id: number;
    status: string;
    created_at: string;
    total_price: string;
    transaction_id: string;
    service: string;
    api_status: string;
    item: {
        sender: {
            address: string;
            city: string;
            dug: string;
            email: string;
            first_name: string;
            last_name: string;
            province: string;
            street_number: string;
            zip: string;
        };
        destinations: [
            {
                address: string;
                city: string;
                dug: string;
                email: string;
                first_name: string;
                last_name: string;
                province: string;
                street_number: string;
                zip: string;
            }
        ];
        options: {
            ar_c: {
                address: string;
                city: string;
                company_name: string;
                email: string;
                first_name: string;
                last_name: string;
                province: string;
                zip: string;
            };
        };
        prices: [
            {
                service_description: string;
                service_code: string;
                quantity: number;
                total_unit_amount: number;
            }
        ];

        documents: {
            pdf: string;
            content: string;
        };
    };
}

export class ReadyMail {
    color: string;
    created_at: string;
    dealer_id: number;
    envelopes: number;
    id: number;
    print_type: string;
    sheets: number;
    shipment_id: number;
    shipment_type: number;
    status: string;
    company_name: string;
    total_dealer_price: number;
}

export class ShippingProvider extends BaseModel {
    data: Shipping[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ReadyMailProvider extends BaseModel {
    data: ReadyMail[];
    _meta: MetaPagination;
    _links: Array<any>;
}


export class Tickets {
    dealer: DealerView;
    dealer_id: number;
    status: string;
    created_at: string;
    total_price: string;
    job_id: string;
    email: string;
    item: {
        offer_id: string;
        dealer_price: string;
        name: string;
        expiration_date: string;
    };
}

export class AmazonCounter {
    id:number;
    dealer_id: number;
    company_name: string;
    status: string;
    created_at: string;
    vat: number;
    first_name: string;
    last_name: string;
    address: any;
    city: string;
    province: string;
    lat: number;
    lng: number;
    email: any;
    note: string;
    mobile: number;
    phone:number;
    opening_hours: any;

}

export class TicketsProvider extends BaseModel {
    data: Tickets[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class AmazonCounterProvider extends BaseModel {
    data: AmazonCounter[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class Status {
    public static list = [
        {
            label: 'Completed',
            key: 'completed'
        },
        {
            label: 'Failed',
            key: 'failed'
        },
        {
            label: 'Pending',
            key: 'pending'
        },
    ];
}
