<div class="card mt-3">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-12 col-12 d-flex mb-2">
                <h4 class="mb-0">Leads</h4>
                <button class="btn btn-primary px-3 ml-auto"
                type="button"  (click)="showModal()"
                >Carica il nuovo report
                </button>
            </div>
            <hr>
            <div class="col-lg-12 col-12 mb-2">
                <form (ngSubmit)="loadLeadsData()">
                    <div class="form-row">
                        <div class="col-lg col-6">
                            <label>Regione</label>
                            <app-state-select
                                    (onChange)="this.filter.region_id = $event"
                                    [small]="true"
                            ></app-state-select>
                        </div>
                        <div class="col-lg col-6">
                            <label>Provincia</label>
                            <app-province-select
                                    [state]="this.filter.region_id"
                                    [onDemand]="true"
                                    [small]="true"
                                    (onChange)="this.filter.province_id = $event"
                            ></app-province-select>
                        </div>
                        <div class="col-lg col-6">
                            <label>Comune</label>
                            <app-city-select
                                    [province]="this.filter.province_id"
                                    [onDemand]="true"
                                    [small]="true"
                                    (onChange)="this.filter.city_id = $event"
                            ></app-city-select>
                        </div>
                        <div class="col-lg col-6">
                            <label>Ragione Sociale</label>
                            <input
                                    type="text" name = "company_name"
                                    [(ngModel)]="filter.company_name"
                                    class="form-control form-control-sm"
                            />
                        </div>
                        <div class="col-lg-1 col-6 d-flex align-items-end">
                            <button class="btn btn-info btn-block btn-sm" type="submit" translate>Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Company name</th>
                <th translate>Company owner</th>
                <th translate>E-Mail</th>
                <th translate>Region</th>
                <th translate>Province</th>
                <th translate>City</th>
                <th translate>Scheduled For</th>
                <th translate class="text-center">Call</th>
                <th translate class="text-center">Schedule</th>
                <th translate>Status</th>
                <!-- <th translate>Feedback</th> -->
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-leads"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let lead of leads;index as i">
                <td>
                    <a [routerLink]="['/dealer', lead.dealer_id]" target="_blank">{{
                        lead.company_name
                        }}</a>
                </td>
                <td>
                    {{ lead.company_owner }}
                </td>
                <td>{{ lead?.email }}</td>
                <td>{{ lead?.region }}</td>
                <td>{{ lead?.province }}</td>
                <td>{{ lead?.city }}</td>
                <td>
                    <span *ngIf="!isActiveCampaign">{{lead?.scheduled_at|amDateFormat:localFormat}}</span>
                    <button class="btn btn link m-0 p-0" *ngIf="isActiveCampaign"
                            [disabled]="!!lead.scheduled_call_status || callStatus(lead.call_status)?.is_closed"
                            (click)="showCallModal(lead, lead?.scheduled_destination, true)">{{lead?.scheduled_at|amDateFormat:localFormat}}</button>
                </td>
                <td class="text-center position-relative">
                    <div ngbDropdown container="body">
                        <button type="button" class="btn btn-link text-success" id="dropdownConfig" ngbDropdownToggle
                                [disabled]="!isActiveCampaign || callStatus(lead.call_status)?.is_closed"><i
                                class="fas fa-phone fa-lg"></i>
                            <span class="badge badge-pill badge-primary align-bottom position-absolute"
                                  *ngIf="lead?.number_of_calls>0">{{lead?.number_of_calls}}</span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                            <button type="button" ngbDropdownItem class="btn btn-link"
                                    (click)="showCallModal(lead, lead?.cell_phone, false)">
                                <span translate>Cell phone</span>: {{ lead?.cell_phone }}
                            </button>
                            <button type="button" ngbDropdownItem class="btn btn-link"
                                    (click)="showCallModal(lead, lead?.phone)">
                                <span translate>Phone</span>: {{ lead?.phone }}
                            </button>
                        </div>
                    </div>
                </td>
                <td class="text-center">
                    <button type="button" class="btn btn-link text-info" (click)="showScheduleCallModal(lead)"
                            [disabled]="!isActiveCampaign || callStatus(lead.call_status)?.is_closed"><i
                            class="fas fa-calendar-day fa-lg"></i>
                    </button>
                </td>
                <td>
                    <span class="text-{{callStatus(lead.call_status)?.color}}"
                          translate>{{callStatus(lead.call_status)?.name}}</span>
                </td>
                <!-- <td>
                    <span class="text-{{feedbackStatus(lead.feedback_status)?.color}}"
                          translate>{{feedbackStatus(lead.feedback_status)?.name}}</span>
                </td> -->
            </tr>
            <tr *ngIf="leads?.length<=0">
                <td class="text-muted" colspan="10" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="leads?.length>0">
    <span><span translate>There are</span><b> {{ this.leadsPagination.totalCount }}</b>
        {{this.leadsPagination.totalCount <= 1 ? 'dealer' : 'dealers'}}.</span>
        <ngb-pagination *ngIf="leadsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="leadsPagination.perPage"
                        [maxSize]="5"
                        [(page)]="leadsPagination.currentPage"
                        [collectionSize]="leadsPagination.totalCount"
                        (pageChange)="loadLeadsData()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
<ng-template #scheduleFormTemplate let-modal>
    <app-pbx-schedule-call-modal [lead]="selectedLead" [operator]="operator"></app-pbx-schedule-call-modal>
</ng-template>

<ng-template #callFormTemplate let-modal>
    <app-pbx-call-modal [lead]="selectedLead" [phoneNumber]="selectedPhoneNumber" [isScheduleCall]="isScheduleCall"
                        [operator]="operator"></app-pbx-call-modal>
</ng-template>
<ng-template #reportFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{insertData ? 'Modifica report' : 'AGGIUNGI REPORT'}}</h4>
        <button type="button" class="close px-3" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-pbx-modal-leads
         [data]="insertData" (refresh)="loadLeadsData()" [campaignId]="campaignId"></app-pbx-modal-leads>
</ng-template> 
