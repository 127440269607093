import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class Popup extends BaseModel {
    id: number;
    title: string;
    id_kdricarica_brand: number;
    description: string;
    type: string;
    start_date: string;
    end_date: string;
    status: string;
    debug: number;
    is_pro: number;
    brand_name: string;
}

export class PopupPagination extends BaseModel {
    data: Popup[];
    _meta: MetaPagination;
    _links: Array<any>;
}
