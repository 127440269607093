import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import * as moment from 'moment';
import {EPayService} from '@services/e-pay.service';
import {ShopBrand} from '@models/e-pay/shop-brand';
import {Pec, PecProvider, Status, Tickets} from '@models/kd-orders';
import {AuthenticationService} from '@services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ActivatedRoute} from '@angular/router';
import {KdOrderService} from '@services/kd-order.service';
import {User} from '@models/user';
import {Moment} from 'moment';


@Component({
  selector: 'app-pec',
  templateUrl: './pec.component.html',
  styleUrls: ['./pec.component.css']
})
export class PecComponent implements OnInit {

  orders: Pec[] = [];
  //selectedOrder: ShopOrder;
  ordersPagination: MetaPagination;

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    }
  };
  pickerOptions2 = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    }
  };
  pickerOptions3 = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    }
  };

  filter = {
    dealer_id: '',
    date: "",
    status: '',
    email: '',
    email_id: "",
    email_type: "",
    activation_date: "",
    expiration_date: ""
  };

  apiDate = {
    from: '',
    to: ''
  };

  apiDate2 = {
    from_activation: '',
    to_activation: ''
  };

  apiDate3 = {
    from_expiration: '',
    to_expiration: ''
  };

  localFormat = 'dd/MM/yyyy HH:MM';

  statuses = Pec.pecStatuses;

  types = EPayService.productTypes;
  currency = EPayService.currency;

  @ViewChild('receiptFormTemplate') private receiptFormTemplate;
  brandsLoading = false;
  brands: ShopBrand[] = [];
  isDealer = false;

  constructor(
      private ePayService: EPayService,
      private authenticationService: AuthenticationService,
      private spinner: NgxSpinnerService,
      public modalService: NgbModal,
      private dateRangePickerOptions: DaterangepickerConfig,
      private PecService: KdOrderService,
      public route: ActivatedRoute
  ) {
      this.authenticationService.currentUser.subscribe((user: User) => {
          this.isDealer = user && user.profile.role === 'dealer';
        }
    );
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadOrders();
    setTimeout(() => {
    });
  }

  loadOrders() {
    this.spinner.show('table-orders');
    const params = {};
    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }
    if (this.filter.email) {
      params['email'] = this.filter.email;
    }
    if (this.filter.email_id) {
      params['email_id'] = this.filter.email_id;
    }
    if (this.filter.email_type) {
      params['email_type'] = this.filter.email_type;
    }
    if (this.filter.activation_date) {
      this.apiDate2.from_activation = this.apiDate2.from_activation.replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, "$3/$2/$1");
      this.apiDate2.to_activation = this.apiDate2.to_activation.replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, "$3/$2/$1");

      params['from_activation_date'] = this.apiDate2.from_activation;
      params['to_activation_date'] = this.apiDate2.to_activation;
    }
    if (this.filter.expiration_date) {
      this.apiDate3.from_expiration = this.apiDate3.from_expiration.replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, "$3/$2/$1");
      this.apiDate3.to_expiration = this.apiDate3.to_expiration.replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, "$3/$2/$1");

      params['from_expiration_date'] = this.apiDate3.from_expiration;
      params['to_expiration_date'] = this.apiDate3.to_expiration;
    }
    this.PecService.getPec(params).subscribe((response: PecProvider) => {
      this.orders = response.data;
      this.ordersPagination = response._meta;
      this.spinner.hide('table-orders');
    }, err => {
      this.spinner.hide('table-orders');
    });
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat)
        + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }
  public generateDateRangeString2(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate2.from_activation = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate2.to_activation = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.activation_date = startDate.format(localDateFormat)
        + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }
  public generateDateRangeString3(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate3.from_expiration = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate3.to_expiration = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.expiration_date = startDate.format(localDateFormat)
        + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

}
