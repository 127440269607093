<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadOrders()">
    <div class="form-row">
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="companyName">Company name</label>
          <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="companyName"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Dealer ID</label>
          <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control" />
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">VAT</label>
          <input [(ngModel)]="filter.vat" name="vat" class="form-control" />
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Phone</label>
          <input [(ngModel)]="filter.phone" name="phone" class="form-control" />
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Date</label>
          <input
            id="documentDate"
            type="text"
            daterangepicker
            [options]="pickerOptions"
            [(ngModel)]="filter.date"
            name="date"
            class="form-control"
            (selected)="generateDateRangeString($event.start, $event.end)"
          />
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate>Status</label>
          <ng-select
            [clearable]="true"
            class="disableClear"
            [(ngModel)]="filter.status"
            [items]="statuses"
            bindLabel="label"
            name="subscribed"
            id="subscribed"
            bindValue="key"
            [searchable]="true"
          ></ng-select>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Opportunity name</label>
          <input [(ngModel)]="filter.opportunity_name" name="opportunity_name" class="form-control" />
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Lead status</label>
          <input [(ngModel)]="filter.lead_status" name="lead_status" class="form-control" />
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Lead sub status</label>
          <input [(ngModel)]="filter.lead_sub_status" name="lead_sub_status" class="form-control" />
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="">Stage</label>
          <input [(ngModel)]="filter.stage" name="stage" class="form-control" />
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <button class="btn btn-info btn-block" translate type="submit">Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="card" *show="['master', 'operator', 'submaster']">
  <div class="card-header d-flex py-3">
    <h4 class="mb-0" translate>Carica il report di AMEX</h4>
    <button class="btn btn-primary btn-sm ml-auto" type="button" translate (click)="showModal()">AGGIUNGI REPORT</button>
  </div>
</div>

<div class="card mt-4 w-full h-auto">
  <div class="w-full d-flex flex-row px-3 py-3 justify-content-start items-center">
    <h4 class="mb-0 mr-4" translate>Legenda</h4>
    <button class="btn btn-primary btn-sm display-8" type="button" (click)="toggleLegend()">
      <strong>{{ showLegend ? '-' : '+' }}</strong>
    </button>
  </div>
  <div *ngIf="showLegend" class="mt-3 px-4 py-4">
    <div class="legend-item px-1 py-1" *ngFor="let stage of stages">
      <p>
        <strong>{{ stage.name }}</strong
        >: {{ stage.description }}
      </p>
    </div>
  </div>
</div>

<!-- Tabella -->

<div class="card">
  <div class="card-header">
    <h4 class="mb-0" translate>AMEX</h4>
  </div>
  <div class="card-body p-0 rounted-bottom table-responsive">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-orders" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th></th>
          <th translate>Dealer</th>
          <th translate>Company name</th>
          <th translate>Dealer Id</th>
          <th translate>VAT</th>
          <th translate>Phone</th>
          <th translate>Email</th>
          <th translate>Date</th>
          <th translate>Status</th>
        </tr>
      </thead>
      <tbody class="bg-gradiend-white">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders" [fullScreen]="false">
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngIf="amexOrders.length == 0">
          <td translate colspan="9" class="text-center">List is empty</td>
        </tr>
        <ng-container *ngFor="let amexOrder of amexOrders">
          <tr>
            <td>
              <button class="btn btn-outline-info btn-sm text-center" type="button" (click)="amexOrder.show = !amexOrder.show" role="button" aria-expanded="false" translate>
                <i class="fas fa-plus mr-0" *ngIf="!amexOrder.show"></i>
                <i class="fas fa-minus mr-0" *ngIf="amexOrder.show"></i>
              </button>
            </td>
            <td>
              <a [routerLink]="['/dealer', amexOrder.dealer_id]" target="_blank">{{ amexOrder.dealer }}</a>
            </td>
            <td>
              {{ amexOrder.company_name }}
            </td>
            <td>
              {{ amexOrder?.dealer_id }}
            </td>
            <td>
              {{ amexOrder?.vat }}
            </td>
            <td>
              {{ amexOrder?.phone }}
            </td>
            <td>
              {{ amexOrder?.email }}
            </td>
            <td>
              {{ amexOrder?.created_at | amDateFormat : 'HH:mm DD/MM/YYYY' }}
            </td>
            <td *ngIf="isAuthorized()">
              <button
                href="javascript:void(0)"
                class="btn btn-sm text-uppercase btn-block"
                (click)="openPopover(plafondStatusPopover, amexOrder?.id, amexOrder?.status, 'status', statuses)"
                #plafondStatusPopover="ngbPopover"
                autoClose="outside"
                triggers="manual"
                [ngbPopover]="popContent"
                popoverTitle="Cambia valore"
                [ngClass]="{
                  'btn-info': amexOrder.status === 'pending',
                  'btn-success': amexOrder.status === 'confirmed',
                  'btn-danger': amexOrder.status === 'canceled'
                }"
              >
                {{ amexOrder?.orderStatus }}
              </button>
            </td>
            <td *ngIf="!isAuthorized()">
              <span
                class="py-1 px-2 rounded"
                [ngClass]="{
                  'bg-info': amexOrder.status === 'pending',
                  'bg-success': amexOrder.status === 'confirmed',
                  'bg-danger': amexOrder.status === 'canceled'
                }"
              >
                {{ amexOrder?.orderStatus }}
              </span>
            </td>
          </tr>
          <tr *ngIf="amexOrder.show">
            <td colspan="9">
              <div class="">
                <h4 class="mt-4" translate>Dati cliente</h4>
                <div class="row">
                  <div class="col-4">
                    <div class="my-3">
                      <p><b translate>Company name</b></p>
                      <p>{{ amexOrder.company_name === null ? '-' : amexOrder.company_name }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Name</b></p>
                      <p>{{ amexOrder.name === null ? '-' : amexOrder.name }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Phone</b></p>
                      <p>{{ amexOrder.phone === '' ? '-' : amexOrder.phone }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Zip code</b></p>
                      <p>{{ amexOrder.zip_code === null ? '-' : amexOrder.zip_code }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Sales</b></p>
                      <p>{{ amexOrder.sales === null ? '-' : amexOrder.sales }}</p>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="my-3">
                      <p><b translate>Company type</b></p>
                      <p>{{ amexOrder.company_type === null ? '-' : amexOrder.company_type }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Surname</b></p>
                      <p>{{ amexOrder.surname === null ? '-' : amexOrder.surname }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>City</b></p>
                      <p>{{ amexOrder.city === null ? '-' : amexOrder.city }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Address</b></p>
                      <p>{{ amexOrder.address === null ? '-' : amexOrder.address }}</p>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="my-3">
                      <p><b translate>VAT</b></p>
                      <p>{{ amexOrder.vat === null ? '-' : amexOrder.vat }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Email</b></p>
                      <p>{{ amexOrder.email === null ? '-' : amexOrder.email }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Province</b></p>
                      <p>{{ amexOrder.province === null ? '-' : amexOrder.province }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Sector</b></p>
                      <p>{{ amexOrder.sector === null ? '-' : amexOrder.sector }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="mb-2">
                <h4 class="mt-4" translate>Stato di avanzamento</h4>
                <div class="row">
                  <div class="col-10 d-flex justify-content-between">
                    <div class="my-3">
                      <p><b translate>Opportunity name</b></p>
                      <p>{{ amexOrder.opportunity_name === null ? '-' : amexOrder.opportunity_name }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Lead status</b></p>
                      <p>{{ amexOrder.lead_status === null ? '-' : amexOrder.lead_status }}</p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Lead sub status</b></p>
                      <p>{{ amexOrder.lead_sub_status === null ? '-' : amexOrder.lead_sub_status }}</p>
                    </div>
                  </div>
                  <div class="col-10 mt-2">
                    <p><b translate>Stage</b></p>
                    <p>{{ amexOrder.stage === null ? '-' : amexOrder.stage }}</p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="mb-1">
                <h4 class="mt-4" translate>Dati referral</h4>
                <div class="row">
                  <div class="col-10 d-flex justify-content-between">
                    <div class="my-3">
                      <p><b translate>Nome Referente</b></p>
                      <p>
                        {{ amexOrder.referral_name }} <span>{{ amexOrder.referral_surname }} </span><span>({{ amexOrder.id }})</span>
                      </p>
                    </div>
                    <div class="my-3">
                      <p><b translate>Email</b></p>
                      <p>{{ amexOrder.referral_email }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <span
      ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b
      >{{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.<a class="link" (click)="exportAmex()"> Esporta la lista completa</a></span
    >
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      (pageChange)="loadOrders()"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>
<ng-template #amexFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{ insertData ? 'Modifica report' : 'AGGIUNGI REPORT' }}</h4>
    <button type="button" class="close px-3" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-amex-modal> [data]="insertData" (refresh)="loadOrders()" </app-amex-modal>
</ng-template>

<ng-template #popContent>
  <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
