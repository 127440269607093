import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';

export class Kasko extends BaseModel {
    
    // id: number;
    // transaction_id: number;
    // invoice_id: string;
    // dealer_price: string;
    // our_price: string;
    // customer_price: string;
    // status: string;
    // created_at: string;
    // created_by: number;

    dealer_id: number;
    company_name: string;
    show = false;

    id: string;
    invoice_id: string;
    transaction_id: string;
    service: string;
    customer_price: string;
    dealer_price: string;
    status: string;
    created_at: string;
    savedOrder: string;
    savedOrderDetail: string;
    idSavedOrder: string;
    savedPoint: string;
    savedPointData: string;
    log: string;
    objectPassed: {
      name: string;
      surname: string;
      orderDate: string;
      purchaseDate: string;
      fiscalCode: string;
      address: string;
      civicNumber: string;
      region: string;
      regionName: string;
      province: string;
      provinceName: string;
      city: string;
      cityName: string;
      zipCode: string;
      zip_code: string;
      phone: string;
      mail: string;
      note: string;
      productID: string;
      productName: string;
      brandID: string;
      brandName: string;
      deviceID: string;
      deviceName: string;
      months: string;
      totalAmount: string;
      sku: string;
      imei: string;
      memory: string;
    };
  
    fromArray(result: []): Kasko[] {
        const array: Kasko[] = [];
        for (const item of result) {
            array.push(new Kasko(item));
        }
        return array;
    }

}

export class KaskoPagination extends BaseModel {
    data: Kasko[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class KaskoProvider extends BaseModel {
    data: Kasko[];
    _meta: MetaPagination;
    _links: Array<any>;
}