import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {DashboardService} from '@services/dashboard.service';
import {ShopAccess} from '@app/pages/shop-access/shop-access.type';

@Component({
    selector: 'app-dealer-detail',
    templateUrl: './dealer-detail.component.html',
    styleUrls: ['./dealer-detail.component.css']
})
export class DealerDetailComponent implements OnInit {
    @Input() id: number | string;
    data: any;

    paymentTypesPair = ShopAccess.paymentTypesPair;

    constructor(
        private spinner: NgxSpinnerService,
        private dashboardService: DashboardService
    ) {

    }

    get dealer() {
        if (this.data) {
            return this.data.dealer;
        }

        return {
            ragsociale: ''
        };
    }

    ngOnInit(): void {
        this.reload();
    }

    affiliazione(id) {
        if (this.data) {
            if (id in this.data.affiliazioni) {
                return this.data.affiliazioni[id];
            }
        }

        return null;
    }

    reload() {
        this.spinner.show();

        this.dashboardService.get(`/negozio-dettaglio?id=${this.id}`)
            .subscribe(data => {
                this.data = data.result;
                this.spinner.hide();
            });
    }
}
