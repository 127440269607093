<!--HEADER-->
<div class="card bg-gradient-1">
  <div class="card-body">
    <div class="d-flex align-items-lg-center">
      <img
        class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
        src="assets/img/flats/employee.png"
        width="160px"
      />
      <div class="d-block w-100 pl-lg-3">
        <h1 class="display-4" translate>Gruppi di negozi</h1>
        <hr />
        <form (ngSubmit)="loadGroup()">
          <div class="row align-items-end">
            <div class="col-lg-3 col-12">
              <label translate>Dealer</label>
              <app-retailer-typeahead (onChange)="filter.dealer = $event"></app-retailer-typeahead>
            </div>

            <div class="col-lg-3 col-12">
              <label translate>Type</label>
              <ng-select
                [(ngModel)]="filter.type"
                [clearable]="true"
                [items]="itemTypes"
                [ngModelOptions]="{ standalone: true }"
                [searchable]="true"
                bindLabel="type"
                bindValue="type"
                id="type"
                name="type"
                placeholder="Select type"
              ></ng-select>
            </div>

            <div class="col-lg-3 col-12">
              <label translate>Name</label>
              <input [(ngModel)]="filter.name" class="form-control" name="name" type="text" />
            </div>

            <div class="col-lg-3 col-12">
              <button class="btn btn-info btn-block" translate type="submit">Filter</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <ngx-spinner
    [fullScreen]="false"
    bdColor="rgba(0, 0, 0, 0.8)"
    color="#fff"
    name="dashboard"
    size="default"
    type="square-jelly-box"
  >
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
</div>

<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-10 col-12 d-flex justify-content-start align-items-center">
        <h4 *ngIf="isEdit" class="mb-0" translate>Active</h4>
        <h4 *ngIf="!isEdit" class="mb-0" translate>Deleted</h4>
      </div>
      <div class="col-lg-2 col-12 d-flex justify-content-md-around justify-content-end">
        <button (click)="showCsvModal()" class="btn btn-info btn-sm float-right mr-2 mr-md-0" type="button"
        >
          Upload CSV
        </button>
        <button (click)="showModal()" class="btn btn-success btn-sm float-right" type="button">
          Crea Gruppo
        </button>
      </div>
    </div>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <table class="table table-sm table-striped">
      <thead>
      <tr>
        <th translate>Nome Gruppo</th>
        <th translate>Negozio Padre</th>
        <th translate>Tipo di gruppo</th>
        <th class="text-right" translate>Num. negozi</th>
        <th class="text-right" translate>Data Creazione</th>
        <th class="text-right"></th>
      </tr>
      </thead>
      <tbody class="bg-gradient-white">
      <ngx-spinner
        [fullScreen]="false"
        bdColor="rgba(0, 0, 0, 0.8)"
        color="#fff"
        name="table-documents"
        size="default"
        type="square-jelly-box"
      >
        <p style="color: white">Caricamento...</p>
      </ngx-spinner>

      <tr *ngFor="let group of groups">
        <td translate>{{ group.name }}</td>
        <td translate="">
          <a [routerLink]="['/dealer', group.dealer_id]">{{ group.company_name }}</a>
        </td>
        <td class="pl-0 pr-5" translate>{{ group.type }}</td>
        <td class="text-right" translate>{{ group.dealer_count }}</td>
        <td class="text-right">{{ group.created_at | date : localFormat }}</td>
        <td class="text-right">
          <button (click)="showModal(group)" *ngIf="isEdit" class="btn btn-link">
            <i class="fa fa-edit text-muted"></i>
          </button>

          <button
            (confirm)="deleteGroup(group.id)"
            *ngIf="isEdit"
            [swal]="deleteGroupOptions"
            class="btn btn-link"
          >
            <i class="fa fa-trash text-danger"></i>
          </button>
        </td>
      </tr>
      <tr *ngIf="groups?.length <= 0">
        <td class="text-muted" colspan="10" translate>List is empty</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="groups?.length > 0" class="card-footer">
        <span
        ><span translate>There are </span><b> {{ this.groupPagination?.totalCount }}</b>
          {{ this.groupPagination?.totalCount <= 1 ? 'gruppo' : 'gruppi' }}.</span
        >
    <ngb-pagination
      (pageChange)="loadGroup()"
      *ngIf="groupPagination?.pageCount > 1"
      [(page)]="groupPagination.currentPage"
      [boundaryLinks]="true"
      [collectionSize]="groupPagination.totalCount"
      [maxSize]="10"
      [pageSize]="groupPagination.perPage"
      size="sm"
    ></ngb-pagination>
  </div>
</div>

<ng-template #groupFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{ selectedData ? 'Modifica Gruppo' : 'Crea Gruppo' }}</h4>
    <button (click)="dismissModal()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-group-modal (refresh)="loadGroup()" [data]="selectedData"></app-group-modal>
</ng-template>

<ng-template #csvTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Carica CSV</h4>
    <button (click)="dismissModal()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-spinner
      [fullScreen]="false"
      bdColor="rgba(0, 0, 0, 0.8)"
      color="#fff"
      name="csvUpload"
      size="default"
      type="square-jelly-box"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <p class="link mb-2"><a (click)="downloadCsvTemplate()">Scarica il modello</a></p>
    <input type="file" (change)="handleSelectedFile($event.target.files)" accept=".csv" />
  </div>
  <div class="modal-footer">
    <button (click)="uploadCsv()" class="btn btn-info btn-success float-right" [disabled]="!selectedFile">Upload
    </button>
  </div>
</ng-template>