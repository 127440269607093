<div [ngBusy]="busy">
  <div class="row">
    <div class="col-12">
      <div class="card card-main-header bg-gradient-info">
        <div class="card-body">
          <img class="bg-gradient-light" src="/assets/img/flats/help.png" />
          <div class="card-content row align-items-center">
            <div class="col">
              <h1>Domande più richieste</h1>
              <hr />
              <h2 class="mb-0">
                Qui puoi trovare la risposta alle domande più richieste dai
                partner.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #faqModal let-modal>
    <div class="modal-body faq-modal">
      <h1>
        <ngb-highlight
          [result]="selectedFaq.title"
          [term]="filter"
        ></ngb-highlight>
      </h1>
      <hr />
      <p>
        <ngb-highlight
          [result]="selectedFaq.risposta"
          [term]="filter"
        ></ngb-highlight>
      </p>
    </div>
  </ng-template>

  <div class="row">
    <div class="col-12 col-lg-3">
      <div class="card mg-theme full">
        <div class="card-header">
          <h1>Categorie</h1>
          <img class="float-right" src="/assets/img/flats/folder.png" />
        </div>
        <div class="card-body p-0">
          <div class="form-group px-3 my-3">
            <input
              type="text"
              class="form-control"
              placeholder="Cerca tra le domande"
              [(ngModel)]="this.filter"
            />
          </div>

          <ul class="nav flex-column menu">
            <li
              class="nav-item"
              [ngClass]="{ active: this.categoryFilter === null }"
              (click)="this.categoryFilter = null"
            >
              <a class="nav-link"
                ><i class="fa fa-caret-right"></i> Tutte le faq
                <span class="float-right">{{ originFaqs.length }}</span></a
              >
            </li>
            <li
              class="nav-item"
              *ngFor="let category of categories"
              [ngClass]="{
                active:
                  this.categoryFilter &&
                  categoryFilter.id.toString() === category.id.toString()
              }"
            >
              <a class="nav-link" (click)="this.categoryFilter = category">
                <i class="fa fa-caret-right"></i> {{ category.title }}
                <div class="float-right">
                  <ng-container *show="['master', 'operator']">
                    <button
                      class="btn btn-circle bg-gradient-success btn-circle-xs mr-2"
                      (click)="showCreateFaqModal(category)"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                    <button
                      class="btn btn-circle bg-gradient-info btn-circle-xs mr-2"
                      (click)="
                        showUpdateCategoryModal(category);
                        $event.stopPropagation()
                      "
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                    <button
                      class="btn btn-circle bg-gradient-danger btn-circle-xs mr-2"
                      [swal]="categoryConfirmDeleteOptions"
                      (confirm)="deleteCategory(category)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </ng-container>

                  <span>{{ category.faq_count }}</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="card-footer" *show="['master', 'operator']">
          <button
            class="btn btn-block btn-small bg-gradient-success"
            (click)="showCreateCategoryModal()"
          >
            Aggiungi Categoria
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-9">
      <div
        class="callout callout-info"
        *ngIf="this.filter || this.categoryFilter"
      >
        <h5>Filtri attivi</h5>
        <p *ngIf="this.filter">
          Stai visualizzando faq che contengono nel titolo o nella risposta
          <strong>{{ this.filter }}</strong
          >.
        </p>
        <p *ngIf="this.categoryFilter">
          Stai visualizzando faq nella categoria
          <strong>{{ this.categoryFilter.title }}</strong
          >.
        </p>
      </div>

      <div
        class="card bg-gradient-light cursor-hand"
        *ngFor="
          let faq of faqs
            | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
        "
        (click)="show[faq.id]=!show[faq.id]"
      >
        <div class="card-header">
          <div class="user-block">
            <img class="img-circle" [src]="'flats/question.png' | assets">
            <span class="username"><b>{{faq.title}}</b></span>
            <span class="description">
              Inserita il:  <b>{{faq.created | amDateFormat: 'DD/MM/YYYY'}}</b>
            </span>
          </div>
          <div class="card-tools">
            <i class="fas" [ngClass]="{'fa-plus': !show[faq.id], 'fa-minus': show[faq.id]}"></i>
          </div>
        </div>
        <ng-container *ngIf="show[faq.id]">
          <div class="card-body bg-white border-bottom py-2" [innerHTML]="faq.risposta">
          </div>
        </ng-container>
        <div class="card-body py-2 rounded-bottom" *ngIf="show[faq.id]" [ngClass]="{'bg-white': !show[faq.id], 'bg-light': show[faq.id]}">
          <span class="text-muted text-sm">Categoria: </span> <b class="text-sm">{{faq.category}}</b>

          <div *show="['master', 'operator']" class="float-right">
            <button
              class="btn btn-xs bg-gradient-info mr-2"
              (click)="showUpdateFaqModal(faq)"
            >
              Modifica
            </button>
            <button
              class="btn btn-xs bg-gradient-danger"
              [swal]="faqConfirmDeleteOptions"
              (confirm)="deleteFaq(faq)"
            >
              Elimina
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="false">
        <div
          class="col-12 col-md-6 col-lg-4"
          *ngFor="
            let faq of faqs
              | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
          "
        >
          <div
            class="card card-outline card-tabs card-dark bg-gradient-light zoom faq"
            (click)="showFaq(faq)"
          >
            <div class="card-body">
              <h1>
                <ngb-highlight
                  [result]="faq.risposta"
                  [term]="filter"
                ></ngb-highlight>
              </h1>
            </div>
            <div class="card-footer">
              <span class="float-right link">Leggi la risposta</span>
            </div>
          </div>
        </div>
      </div>
      <ngb-pagination
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="faqs.length"
      ></ngb-pagination>
    </div>
  </div>
</div>

<ng-template #categoryFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">{{ categoryModalTitle }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [ngBusy]="busyCategoryFormModal">
    <form [formGroup]="categoryForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="lead">Nome</label>
            <input
              [(ngModel)]="selectedCategory.title"
              formControlName="title"
              type="text"
              class="form-control"
            />
            <val-errors controlName="title">
              <ng-template valError="required">
                Il nome della categoria è obbligatorio
              </ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="lead"
              >Visibile solo se abilitato con questo brand</label
            >
            <input
              [(ngModel)]="selectedCategory.id_vendor"
              formControlName="id_vendor"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <button
            class="btn btn-success btn-block"
            [disabled]="!this.categoryForm.valid"
            (click)="saveCategory(selectedCategory)"
          >
            <i class="fa fa-check-circle mr-2"></i>Salva
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #faqFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">{{ faqModalTitle }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [ngBusy]="busyFaqFormModal">
    <form [formGroup]="faqForm" *ngIf="selectedFaq">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="lead">Titolo</label>
            <input
              [(ngModel)]="selectedFaq.title"
              formControlName="title"
              type="text"
              class="form-control"
            />
            <val-errors controlName="title">
              <ng-template valError="required">
                Il titolo è obbligatorio
              </ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="lead">Risposta</label>
              <editor
                      id="note" [init]="tinyMceConfig" [(ngModel)]="selectedFaq.risposta"
                      formControlName="risposta"
                      name="transactionNote"></editor>
            <val-errors controlName="risposta">
              <ng-template valError="required">
                La risposta è obbligatoria
              </ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12 mt-3">
          <button
            class="btn btn-success btn-block"
            [disabled]="!this.faqForm.valid"
            (click)="saveFaq(selectedFaq)"
          >
            <i class="fa fa-check-circle mr-2"></i> Salva
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
