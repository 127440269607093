import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class DatepickerAdapter extends NgbDateAdapter<string> {
  constructor() {
    super();
  }

  fromModel(value: any): NgbDateStruct {
    value = moment(value, 'DD-MM-YYYY');
    return value && moment.isMoment(value)
      ? {
        day: value.date(),
        month: value.month() + 1,
        year: value.year()
      }
      : null;
  }

  toModel(date: NgbDateStruct): string {
    return date
      ? moment({
        year: date.year,
        month: date.month - 1,
        date: date.day,
        hour: 12
      }).format('DD-MM-YYYY')
      : null;
  }
}
