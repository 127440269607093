import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import {AlertService} from '@services/alert.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RiaService {

  constructor(
    private request: RequestService,
    public alertService: AlertService) { 

  }

  public getRiaOrders(params?): Observable<any> {
    return this.request.get('v2/ria/orders', params);
  }

  public getRiaZip(id) {
   return this.request.get('v2/ria/zip', {id: id},  {responseType: 'blob'})
  }

  public putRiaOrder(id, body: {}){
    return this.request.put( 'v2/ria/update/' + id, body);
  }
  
  deleteRiaSubscription(id :number) {
    return this.request.delete(`v2/ria/delete/${id}`);
  }

  public successAlert(text) {
    this.alertService.show(
        '',
        'Successfully ' + text,
        {classname: 'bg-success text-light', delay: 4000}
    );
  }

  public errorAlert(message?) {
    this.alertService.show(
        'An error occurred!',
        message ? message : 'Try again!',
        {classname: 'bg-danger text-light', delay: 2000}
    );
  }
  
}

