import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MetaPagination } from '@app/models/meta-pagination';
import { User } from '@app/models/user';
import { WhatsappLog } from '@app/models/whatsapp-generator';
import { AuthenticationService } from '@app/services/authentication.service';
import { RequestService } from '@app/services/request.service';
import { WhatsappGeneratorService } from '@app/services/whatsapp-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-whatsapp-log',
    templateUrl: './whatsapp-log.component.html',
})
export class WhatsappLogComponent implements OnInit {

    @ViewChild('waTemplate') private waTemplate;
    ordersPagination: MetaPagination;
    whatsappLog: WhatsappLog[];
    isDealer = false;
    isEdit = true;
    waId: number;
    htmlContent: string;
    subMasterList: [] = [];
    selectedSubmaster: any;
    filter = {
        name: '',
        date: '',
    };

    apiDate = {
        from: '',
        to: '',
    };

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        },
    };
    
    constructor(
        private route: ActivatedRoute,
        public request: RequestService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        public formBuilder: FormBuilder,
        public service: WhatsappGeneratorService,
        private dateRangePickerOptions: DaterangepickerConfig,
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.isDealer = user && user.profile.role === 'dealer';
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.waId = params['id'];
        });
        this.loadWaLog();
        this.loadDcSelect();
    }

    loadWaLog() {
        const params = {
            id: this.waId,
        };

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        if (this.filter.name) {
            params['name'] = this.filter.name;
        }

        if (this.filter.date) {
            params['from'] = this.apiDate?.from;
            params['to'] = this.apiDate?.to;
        }

        this.service.getWaLogById(params).subscribe(
            (response: any) => {
                this.whatsappLog = response;
                this.whatsappLog = new WhatsappLog().fromArray(response.data);
                this.ordersPagination = response._meta;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    loadDcSelect() {
        this.spinner.show('table-orders');

        this.service.getDcSelect().subscribe(
            (response: any) => {
                this.subMasterList = response;
                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    showModal(item: any) {
        this.modalService.open(this.waTemplate, { size: 'xl', scrollable: true });
        this.htmlContent = item.html;
    }

    dismissModal() {
        this.modalService.dismissAll();
        this.loadWaLog();
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.date =
            startDate.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            endDate.format(localDateFormat);
    }
}
