import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PbxLead} from '@models/pbx/pbx-lead';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PbxCall} from '@models/pbx/pbx-call';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {User} from '@models/user';
import * as moment from 'moment';

@Component({
    selector: 'app-pbx-schedule-call-modal',
    templateUrl: './pbx-schedule-call-modal.component.html',
    styleUrls: ['./pbx-schedule-call-modal.component.css']
})
export class PbxScheduleCallModalComponent implements OnInit {
    @Input() lead: PbxLead;
    @Input() operator: User;
    modalBusy: any;
    scheduleCallForm = new FormGroup({
        scheduled_at: new FormControl('', Validators.required),
        destination: new FormControl('', Validators.required)
    });
    public options: any = {
        singleDatePicker: true,
        ranges: [],
        locale: {format: 'YYYY-MM-DD'}
    };
    currentDatetime = moment().format('YYYY-MM-DDTHH:mm');


    constructor(public modalService: NgbModal,
                private pbxCampaignService: PbxCampaignService) {
    }

    ngOnInit(): void {
        this.scheduleCallForm.get('scheduled_at').setValue(this.currentDatetime);
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    save() {
        if (this.scheduleCallForm.invalid) {
            return;
        }
        const call = new PbxCall();
        call.lead_id = this.lead.id;
        call.caller_id = this.operator.profile.id;
        call.scheduled_at = moment(this.scheduleCallForm.value.scheduled_at).format(PbxCampaignService.datetimeApiFormat);
        call.destination = this.scheduleCallForm.value.destination;
        this.modalBusy = this.pbxCampaignService.postCall(call).subscribe((response: PbxCall) => {
            this.lead.scheduled_at = response.scheduled_at;
            this.lead.scheduled_destination = response.destination;
            this.lead.scheduled_call_id = response.id;
            this.lead.scheduled_call_status = response.call_status;
            this.pbxCampaignService.successAlert('Added Schedule!');
            this.dismissModal();
        }, err => {
            this.pbxCampaignService.errorAlert(err.message);
            this.dismissModal();
        });
    }
}
