import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Amex} from '@app/models/kd-orders';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {KdOrderService} from '@services/kd-order.service';

@Component({
    selector: 'app-amex-modal',
    templateUrl: './amex-modal.component.html',
    styleUrls: ['./amex-modal.component.css'],
})
export class AmexModalComponent implements OnInit {
    @Input() data: Amex;
    @Output() refresh = new EventEmitter<boolean>();

    amexForm: FormGroup;
    file: File | null = null;

    loadingExport = false;

    @ViewChild('documentFormTemplate') private documentFormTemplate;

    constructor(
        public modalService: NgbModal,
        public formBuilder: FormBuilder,
        private service: KdOrderService
    ) {
    }

    ngOnInit(): void {
        this.amexForm = this.formBuilder.group({
            fileInput: [null, [Validators.required]],
        });
    }

    onFileSelected(event: any) {
        this.file = event.target.files[0];
    }

    save() {
        if (this.file) {
            const formData = new FormData();
            formData.append('file', this.file);


            this.loadingExport = true;
            this.service.amexUploadFile(formData).subscribe(
                (response: Amex) => {
                    this.service.successAlert('importato correttamente');
                    this.refresh.emit(true);
                    this.dismissModal();
                    this.loadingExport = null;
                },
                (err) => {
                    this.service.errorAlert(err.message);
                    this.refresh.emit(true);
                    this.dismissModal();
                    this.loadingExport = null;
                }
            );
        }
        // return this.addSubscription(formData);
    }

    dismissModal() {
        this.modalService.dismissAll();
    }
}
