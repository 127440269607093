<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadOrders()">
        <div class="form-row">
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead
                        (onChange)="filter.dealer_id = $event"
                        id="companyName"
                    ></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">Dealer ID</label>
                    <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control" />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">Iban</label>
                    <input [(ngModel)]="filter.iban" name="iban" class="form-control" />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Iban type</label>
                    <ng-select
                        [clearable]="true"
                        class="disableClear"
                        [(ngModel)]="filter.iban_type"
                        [items]="sddIbanTypeStatus"
                        bindLabel="label"
                        name="iban_type"
                        id="iban_type"
                        bindValue="key"
                        [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Payment type</label>
                    <ng-select
                        [clearable]="true"
                        class="disableClear"
                        [(ngModel)]="filter.payments_type"
                        [items]="sddPaymentsTypeStatus"
                        bindLabel="label"
                        name="payments_type"
                        id="payments_type"
                        bindValue="key"
                        [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Status</label>
                    <ng-select
                        [clearable]="true"
                        class="disableClear"
                        [(ngModel)]="filter.status"
                        [items]="sddStausPair"
                        bindLabel="label"
                        name="status"
                        id="status"
                        bindValue="key"
                        [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Type</label>
                    <ng-select
                        [clearable]="true"
                        class="disableClear"
                        [(ngModel)]="filter.type"
                        [items]="sddTypesStatus"
                        bindLabel="label"
                        name="type"
                        id="type"
                        bindValue="key"
                        [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Signature date</label>
                    <input
                        id="documentDate"
                        autocomplete="off"
                        type="text"
                        daterangepicker
                        [options]="rangeOptions"
                        [(ngModel)]="filter.date"
                        name="date"
                        class="form-control"
                        (selected)="selectedDate($event.start)"
                    />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Tabella -->

<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                <h4 class="mb-0">SDD</h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                <div
                    class="d-flex h-100 w-100 justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-between align-items-center"
                >
                    <button class="btn btn-info btn-sm" type="button" translate (click)="showContractModal()">
                        <i class="fa fa-download"></i> Genera Precompilato
                    </button>
                    <button
                        class="btn btn-success btn-sm ml-xl-2 ml-lg-2 ml-md-2 ml-0"
                        type="button"
                        translate
                        (click)="showModal()"
                    >
                        <i class="fa fa-plus"></i>Aggiungi SDD
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-md table-striped">
            <thead>
                <tr>
                    <th translate>Company name</th>
                    <th translate>Key Account</th>
                    <th translate>Iban</th>
                    <th translate>Iban type</th>
                    <th translate>Type</th>
                    <th translate>Signature date</th>
                    <th translate>Creation date</th>
                    <th translate>Payment type</th>
                    <th class="text-center" translate>Status</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <tr *ngIf="sddOrders.length == 0">
                    <td translate colspan="10" class="text-center">List is empty</td>
                </tr>

                <tr *ngFor="let sddOrder of sddOrders">
                    <td class="pl-4">
                        <a [routerLink]="['/dealer', sddOrder.dealer_id]" target="_blank">{{
                            sddOrder.company_name
                        }}</a>
                    </td>
                    <td>{{ sddOrder?.key_account !== null ? sddOrder?.key_account : '-' }}</td>
                    <td>
                        {{ sddOrder?.iban }}
                    </td>
                    <td>
                        <button
                            href="javascript:void(0)"
                            class="btn btn-sm px-3 btn-block"
                            (click)="
                                openPopover(
                                    typePopover,
                                    sddOrder?.id,
                                    sddOrder?.iban_type,
                                    'iban_type',
                                    sddIbanTypeStatus
                                )
                            "
                            #typePopover="ngbPopover"
                            autoClose="outside"
                            triggers="manual"
                            [ngbPopover]="popContentIban"
                            popoverTitle="Cambia valore"
                            [ngClass]="{
                                'btn-info': sddOrder.iban_type === 'btoc',
                                'btn-success': sddOrder.iban_type === 'btob'
                            }"
                        >
                            {{ sddOrder?.typeIbanLabel }}
                        </button>
                    </td>
                    <td>
                        {{ sddOrder?.typeLabel }}
                    </td>
                    <td>
                        {{ sddOrder?.signature_date | amDateFormat : 'DD/MM/YYYY' }}
                    </td>
                    <td>
                        {{ sddOrder?.created_at | amDateFormat : 'DD/MM/YYYY HH:mm' }}
                    </td>
                    <td>
                        <button
                            href="javascript:void(0)"
                            class="btn btn-sm px-3 btn-block"
                            (click)="
                                openPopover(
                                    paymentPopover,
                                    sddOrder?.id,
                                    sddOrder?.payments_type,
                                    'payments_type',
                                    sddPaymentsTypeStatus
                                )
                            "
                            #paymentPopover="ngbPopover"
                            autoClose="outside"
                            triggers="manual"
                            [ngbPopover]="popContent"
                            popoverTitle="Cambia valore"
                            [ngClass]="{
                                'btn-info': sddOrder.payments_type === 'first',
                                'btn-success': sddOrder.payments_type === 'recurrent'
                            }"
                        >
                            {{ sddOrder?.typePaymentsLabel }}
                        </button>
                    </td>
                    <td>
                        <button
                            href="javascript:void(0)"
                            class="btn btn-sm px-3 text-small btn-block"
                            (click)="openPopover(statusPopover, sddOrder?.id, sddOrder?.status, 'status', sddStausPair)"
                            #statusPopover="ngbPopover"
                            autoClose="outside"
                            triggers="manual"
                            [ngbPopover]="popContentStatus"
                            popoverTitle="Cambia stato"
                            [ngClass]="{
                                'btn-danger': sddOrder.status == '0',
                                'btn-success': sddOrder.status == '1'
                            }"
                        >
                            {{ sddOrder?.sddStatusLabel }}
                        </button>
                    </td>
                    <td class="pr-4">
                        <button
                            type="button"
                            class="close text-danger"
                            aria-label="Close"
                            [swal]="deleteSubscriptionOptions"
                            *ngIf="isEdit"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Elimina"
                            (confirm)="deleteSddSubscription(sddOrder.id)"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </td>
                    <td class="pr-4">
                        <a
                            href="{{ sddOrder?.file_path }}"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="scarica documento"
                            ><i class="fas fa-download text-primary"></i
                        ></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="sddOrders?.length > 0">
        <span
            ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b>
            {{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.
            <a class="link" (click)="exportSdd()">Esporta la lista completa</a>
        </span>
        <ngb-pagination
            *ngIf="ordersPagination.pageCount > 1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadOrders()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>

<ng-template #sddFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{ insertData ? 'Modifica SDD' : 'Aggiungi SDD' }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-sdd-modal [data]="insertData" (refresh)="loadOrders()"></app-sdd-modal>
</ng-template>
<ng-template #popContentIban>
    <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
<ng-template #popContentStatus>
    <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
<ng-template #popContent>
    <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>

<ng-template #generateContractTemplate let-modal>
    <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        size="default"
        color="#fff"
        type="square-jelly-box"
        name="modal"
        [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="modal-header">
        <h4 class="modal-title clamp-title">Generazione Precompilato</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 mt-auto mb-0">
                <label>Seleziona un Dealer</label>
                <app-retailer-typeahead (onChange)="setDealerForContract($event)"></app-retailer-typeahead>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12 mt-auto mb-0">
                <button
                    class="btn btn-info float-right"
                    type="button"
                    [disabled]="this.selectedDealerForContract == null"
                    (click)="generateContract()"
                >
                    Genera Contratto
                </button>
            </div>
        </div>
    </div>
</ng-template>
