import { BaseModel } from './base-model';
import { MetaPagination } from './meta-pagination';

export class KdAssicura extends BaseModel {
    show: boolean = false;
    data: any;
    dealer_id: number;
    id: number;
    ragsociale: string;
    service: string;
    status: string;
    created_at: string;

    constructor(attributes?: any) {
        super(attributes);
    }

    public static kdAssicuraStatuses = [
        {
            label: 'In attesa',
            key: 'pending',
        },
        {
            label: 'Inviato',
            key: 'sent',
        },
        {
            label: 'Completato',
            key: 'completed',
        },
        {
            label: 'Fallito',
            key: 'failed',
        },
    ];

    public static kdAssicuraTypes: KdAssicuraType[] = [
        {
            key: 'car',
            icon: 'fa fa-car',
            value: 'Auto',
        },
        {
            key: 'motorcycle',
            icon: 'fa fa-motorcycle',
            value: 'Moto',
        },
        {
            key: 'injuries',
            icon: 'fa fa-medkit',
            value: 'Infortuni',
        },
        {
            key: 'buildings',
            icon: 'fa fa-building',
            value: 'Immobili',
        },
        {
            key: 'pets',
            icon: 'fa fa-paw',
            value: 'Animali',
        },
        {
            key: 'bills',
            icon: 'fa fa-file',
            value: 'Bollette',
        },
    ];

    get kdAssicuraStatusLabel() {
        return KdAssicura.kdAssicuraStatusPair[this.status];
    }

    public static kdAssicuraStatusPair = {
        confirmed: 'Confermato',
        cancelled: 'Respinto',
        pending: 'In attesa',
    };

    fromArray(result: []): KdAssicura[] {
        const array: KdAssicura[] = [];
        for (const item of result) {
            array.push(new KdAssicura(item));
        }
        return array;
    }
}

export class KdAssicuraProvider extends BaseModel {
    data: KdAssicura[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export interface KdAssicuraType {
    key: string;
    icon: string;
    value: string;
}
