import { Component, OnInit, ViewChild } from '@angular/core';
import { DealerInvoice, DealerInvoiceLine, DealerInvoiceLineDetail } from '@models/dealer-invoice';
import { Pagination } from '@models/pagination';
import { InvoiceService } from '@services/invoice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import * as moment from 'moment';

@Component({
    selector: 'app-invoice-management',
    templateUrl: './invoice-management.component.html',
    styleUrls: ['./invoice-management.component.scss'],
})
export class InvoiceManagementComponent implements OnInit {
    @ViewChild('sendInvoiceModalTemplate') private sendInvoiceModalTemplate;
    @ViewChild('invoice') invoiceModal;
    @ViewChild('destinatario') destinatarioModal;

    deleteSubscriptionOptions: ConfirmOptionsService;
    pagination = new Pagination({ page: 1, pageSize: 20 });
    invoices: DealerInvoice[];
    currentInvoice: DealerInvoice;
    selectedInvoices = [];
    firstInvoiceSelected: DealerInvoice;

    newDestinatario: number = null;

    oldDestinatario: {
        invoice_id: '';
        destinatario_id: '';
        subject: '';
    };

    groupOptions = [];

    totals = {
        ready_to_be_sent: null,
        ready_to_be_updated: null,
        discarded: null,
    };

    isLoading: boolean = false;
    invoiceId: any;
    invoiceNumber: string = '';
    invoiceDate: string = '';
    isModalOpen: boolean = false;
    showNewDetailRow: boolean = false;
    selectedLineItemId: any;

    newDetail = {
        offerta: '',
        info: '',
        tipo: '',
        compenso: '',
        iva: '',
        ritenuta: ',',
    };

    yesNoOptions = [
        { value: 0, label: 'Sì' },
        { value: 1, label: 'No' },
    ];

    loadingSDI: {};

    sdi_statuses = [
        { value: 0, label: 'Da inviare' },
        { value: 4, label: 'Scartate' },
        { value: 5, label: 'Elaborazione' },
        { value: 6, label: 'Non inviate' },
        { value: 7, label: 'Inviate' },
        { value: 8, label: 'Consegnate' },
        { value: 9, label: 'Non Consegnate' },
    ];

    document_types = [
        { value: 0, label: 'Fatture' },
        { value: 1, label: 'Documenti a 0€' },
        { value: 2, label: 'Note credito' },
    ];

    filter = {
        id: '',
        numero_fattura: '',
        fatturante: '',
        merged: '0',
        is_old: '',
        stato: '',
        stato_invio_sdi: [0, 4, 5, 6, 7, 8, 9],
        stato_freeinvoice: '',
        id_vendor: '',
        document: [0, 1, 2],
        page: 1,
        sort: '-id',
        group_id: '',
    };

    constructor(
        private service: InvoiceService,
        private toastr: ToastrService,
        public modal: NgbModal,
        private spinner: NgxSpinnerService,
        private _route: ActivatedRoute,
        private _router: Router,
    ) {
        // this.resetFilters();
        this._route.queryParams.subscribe((params: any) => {
            Object.keys(this.filter).forEach((key) => {
                if (params[key]) {
                    this.filter[key] = params[key];
                }
            });
            if (!Array.isArray(this.filter.stato_invio_sdi)) {
                this.filter.stato_invio_sdi = [this.filter.stato_invio_sdi];
            }
            this.filter.stato_invio_sdi = this.filter.stato_invio_sdi.map(Number);

            if (!Array.isArray(this.filter.document)) {
                this.filter.document = [this.filter.document];
            }
            this.filter.document = this.filter.document.map(Number);
        });
        this.deleteSubscriptionOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla',
        });
    }

    get invoiceTypeOptions() {
        return {
            title: 'Cambia il tipo della fattura',
            input: 'select',
            inputOptions: {
                0: 'Nuova',
                1: 'Vecchia',
            },
            inputPlaceholder: 'Seleziona il tipo di fattura',
            showCancelButton: true,
            confirmButtonText: 'Sì',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    get invoiceRedoOptions() {
        return {
            title: 'Storna questa fattura e ricreala corretta',
            input: 'text',
            inputLabel: 'Seleziona la voce da specificare nella nota credito',
            inputPlaceholder: 'Es: Nota di credito per ritenuta non applicata...',
            showCancelButton: true,
            confirmButtonText: 'Vai frrr',
            cancelButtonText: 'Annulla',
            footer: 'Grazie Lucio per semplificarci sempre la vita <3',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    get invoiceMergeOptions() {
        return {
            title: 'Unisci i documenti',
            text: 'Sei sicuro di voler unire i documenti?',
            showCancelButton: true,
            confirmButtonText: 'Sicuro al 100%',
            cancelButtonText: 'Annulla',
            footer: 'Grazie Lucio per semplificarci sempre la vita <3',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    get invoiceStatusOptions() {
        return {
            title: 'Cambia lo stato della fattura',
            input: 'select',
            inputOptions: {
                '-1': 'Nascosta',
                0: 'Da approvare',
                1: 'Approvata',
                2: 'Rifiutata',
            },
            inputPlaceholder: 'Seleziona lo stato della fattura',
            showCancelButton: true,
            confirmButtonText: 'Sì',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    ngOnInit() {
        this.service.getGroupList().subscribe((data) => {
            this.groupOptions = data;
        });
        this.refresh();
    }

    refresh() {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: this.filter,
            // queryParamsHandling: 'merge',
            skipLocationChange: false,
        });

        this.spinner.show('table-invoices');
        this.service
            .list({
                expand: 'subject,send,update,resend,transactions_count,sendProblem,profile_status,vendors,periods',
                page: this.filter.page,
                id: this.filter.id,
                number: this.filter.numero_fattura,
                subject: this.filter.fatturante,
                type: this.filter.is_old,
                status: this.filter.stato,
                merged: this.filter.merged,
                sdi_status: this.filter.stato_invio_sdi.join(','),
                document: this.filter.document.join(','),
                fi_status: this.filter.stato_freeinvoice,
                sort: this.filter.sort,
                group_id: this.filter.group_id,
            })
            .subscribe((data) => {
                this.invoices = new DealerInvoice().fromArray(data.rows);
                this.loadingSDI = this.invoices.reduce(function (map, obj) {
                    map[obj.id] = false;
                    return map;
                }, {});

                this.pagination = new Pagination(data.pagination);
                this.filter.page = this.pagination.page;
                this.spinner.hide('table-invoices');
            });

        this.totals = {
            ready_to_be_sent: null,
            ready_to_be_updated: null,
            discarded: null,
        };

        this.service.overview().subscribe((data) => {
            this.totals = data;
        });
    }

    resetFilters() {
        this.filter = {
            id: '',
            numero_fattura: '',
            fatturante: '',
            merged: '0',
            is_old: '',
            stato: '',
            stato_invio_sdi: [0, 4, 5, 6, 7, 8, 9],
            stato_freeinvoice: '',
            id_vendor: '',
            document: [0, 1, 2],
            page: 1,
            sort: '-id',
            group_id: '',
        };
    }

    showInvoicesReadyToBeSent() {
        this.filter.is_old = '0';
        this.filter.stato = '1';
        this.filter.stato_freeinvoice = '0';
        this.filter.stato_invio_sdi = [0];
        this.filter.document = [0];
        this.filter.sort = 'fatturante,-totale';
        this.refresh();
    }

    showInvoicesToBeUpdated() {
        this.filter.is_old = '0';
        this.filter.stato = '1';
        this.filter.stato_freeinvoice = '1';
        this.filter.stato_invio_sdi = [5, 7];
        this.filter.document = [0];
        this.refresh();
    }

    showInvoicesRejected() {
        this.filter.is_old = '0';
        this.filter.stato = '1';
        this.filter.stato_freeinvoice = '1';
        this.filter.stato_invio_sdi = [4];
        this.filter.document = [0];
        this.refresh();
    }
    showCreateRow(item: any) {
        this.selectedLineItemId = item.id;
        this.showNewDetailRow = !this.showNewDetailRow;
    }

    createInvoiceDetail() {
        this.isLoading = true;
        const payload = {
            id_voce: this.selectedLineItemId,
            offerta: this.newDetail.offerta,
            info: this.newDetail.info,
            tipo: this.newDetail.tipo,
            compenso: this.newDetail.compenso,
            iva: this.newDetail.iva,
            ritenuta: this.newDetail.ritenuta,
        };

        setTimeout(() => {
            this.service.createInvoiceDetail(payload).subscribe(
                (response) => {
                    this.isLoading = false;
                    this.showNewDetailRow = false;
                    this.service.successAlert('creata correttamente');
                    this.showInvoice(this.currentInvoice);
                    this.resetForm();
                },
                (error) => {
                    this.isLoading = false;
                    this.service.errorAlert(error.message);
                    this.dismissModal();
                },
            );
        }, 1500);
    }

    resetForm() {
        this.newDetail.offerta = '';
        this.newDetail.info = '';
        this.newDetail.tipo = '';
        this.newDetail.compenso = '';
        this.newDetail.iva = '';
        this.newDetail.ritenuta = '';
    }

    showInvoice(invoice: DealerInvoice) {
        this.spinner.show('invoice-detail');

        if (this.currentInvoice && this.currentInvoice.id !== invoice.id) {
            this.currentInvoice = null;
        }

        this.modal.open(this.invoiceModal, { size: 'xl' });

        this.service.get(invoice.id).subscribe((data) => {
            this.currentInvoice = new DealerInvoice(data);
            this.spinner.hide('invoice-detail');
        });
    }

    sendInvoiceModal(invoice: any) {
        this.modal.open(this.sendInvoiceModalTemplate, { size: 'md' });
        this.invoiceId = invoice.id;
        this.isModalOpen = true;
    }

    setSelectedDate($event: any) {
        const date = moment($event);
        //1 month is added to the date
        date.subtract(1, 'M');
        this.invoiceDate = date.format('DD-MM-YYYY');
    }

    sendInvoiceData() {
        this.isLoading = true;

        const payload = {
            invoiceId: this.invoiceId,
            invoiceNumber: this.invoiceNumber,
            invoiceDate: this.invoiceDate,
        };

        this.service.updateInvoiceTransaction(payload).subscribe(
            (response) => {
                this.service.successAlert('inviata correttamente');
                this.dismissModal();
            },
            (error) => {
                this.isLoading = false;
                this.service.errorAlert(error.message);
            },
        );
    }

    addBollo(invoiceId: any) {
        this.isLoading = true;

        this.service.addBollo(invoiceId).subscribe(
            (response) => {
                this.service.successAlert('aggiunto correttamente');
                this.isLoading = false;
                this.refresh();
            },
            (error) => {
                this.service.errorAlert(error.message);
                this.isLoading = false;
                this.refresh();
            },
        );
    }

    dismissModal() {
        this.modal.dismissAll();
        this.isLoading = false;
        this.invoiceId = '';
        this.invoiceNumber = '';
        this.invoiceDate = '';
        this.refresh();
    }

    truncateText(text: string, maxLength: number): string {
        if (text.length > maxLength) {
            return text.substring(0, maxLength);
        } else {
            return text;
        }
    }

    regenInvoice(invoice: DealerInvoice) {
        this.spinner.show('table-invoices');
        this.service.regenInvoice(invoice.id).subscribe((data) => {
            this.refresh();
        });
    }

    toggleTooltip(tooltip: NgbTooltip) {
        if (tooltip.isOpen()) {
            tooltip.close();
        } else {
            tooltip.open();
        }
    }

    sendInvoice(invoice: DealerInvoice) {
        this.loadingSDI[invoice.id] = true;
        this.service.sendToSDI(invoice.id).subscribe((response: any) => {
            this.loadingSDI[invoice.id] = false;

            if (response.hasOwnProperty('data') && response.data.length === 1) {
                let result = response.data[0];

                if (result.invoiceStatus === 5) {
                    this.toastr.success(
                        `L'esito dell'invio è: ${result.invoiceStatusName} con numero ${result.invoiceBody.number} e data: ${result.invoiceBody.date}`,
                        'Fattura inviata',
                    );
                }

                this.refresh();
            }

            if (response.hasOwnProperty('errors') && response.errors.length === 1) {
                let result = response.errors[0];

                this.toastr.error(`${result.message}`, 'Errore');
            }
        });
    }

    updateInvoice(invoice: DealerInvoice) {
        this.loadingSDI[invoice.id] = true;
        this.service.updateSdiStatus(invoice.id).subscribe((response: any) => {
            this.loadingSDI[invoice.id] = false;

            if (response.hasOwnProperty('data')) {
                let result = response.data;

                if (result.invoiceStatus === 4) {
                    this.toastr.error(`La fattura è stata scartata`, 'Aggiornamento');
                }

                if (result.invoiceStatus === 8) {
                    this.toastr.success(`La fattura è stata consegnata allo SDI correttamente.`, 'Ottimo');
                }

                if (result.invoiceStatus === 7 || result.invoiceStatus === 5) {
                    this.toastr.info(`La fattura è ancora in attesa di essere consegnata.`, 'Attendi');
                }

                this.refresh();
            }
        });
    }

    changeInvoiceType($event: any, invoice: DealerInvoice) {
        if ($event.toString() === invoice.is_old.toString()) {
            this.toastr.info(`La fattura è già di tipo ${invoice.type}.`, 'Attenzione');
        } else {
            this.spinner.show('table-invoices');
            this.service
                .updateInvoice(invoice.id, {
                    is_old: parseInt($event),
                })
                .subscribe(
                    (data: any) => {
                        invoice.is_old = parseInt(data.is_old);
                        this.toastr.success(`La fattura è cambiata in ${invoice.type}.`, 'Ottimo');
                        this.spinner.hide('table-invoices');
                    },
                    (err) => {
                        this.toastr.error(`C'è stato un'errore col cambio di tipo.`, 'Errore');
                        this.spinner.hide('table-invoices');
                    },
                );
        }
    }

    changeInvoiceStatus($event: any, invoice: DealerInvoice) {
        if ($event.toString() === invoice.stato.toString()) {
            this.toastr.info(`La fattura è già ${invoice.status}.`, 'Attenzione');
        } else {
            this.spinner.show('table-invoices');
            this.service
                .updateInvoice(invoice.id, {
                    stato: parseInt($event),
                })
                .subscribe(
                    (data: any) => {
                        invoice.stato = parseInt(data.stato);
                        this.toastr.success(`La fattura è cambiata in ${invoice.status}.`, 'Ottimo');
                        this.spinner.hide('table-invoices');
                    },
                    (err) => {
                        this.toastr.error(`C'è stato un'errore col cambio di stato.`, 'Errore');
                        this.spinner.hide('table-invoices');
                    },
                );
        }
    }

    setSubject($event: any) {
        this.filter.fatturante = $event ? $event : '';
        this.refresh();
    }

    saveInvoiceLine(lineItem: DealerInvoiceLine) {
        this.spinner.show('invoice-detail');
        this.service.updateInvoiceLine(lineItem.id, lineItem).subscribe((data) => {
            this.spinner.hide('invoice-detail');
            lineItem.editMode = false;
        });
    }

    saveInvoiceDetailLine(dettaglio: DealerInvoiceLineDetail) {
        this.spinner.show('invoice-detail');
        this.service.updateInvoiceDetailLine(dettaglio.id, dettaglio).subscribe((data) => {
            dettaglio.editMode = false;
            this.modal.dismissAll();
            this.showInvoice(this.currentInvoice);
        });
        this.isModalOpen = false;
    }

    redoInvoiceType($event: any, invoice: DealerInvoice) {
        this.spinner.show('table-invoices');
        this.service.redoInvoice(invoice.id, $event).subscribe((data) => {
            this.refresh();
        });
    }

    updateRitenuta(lineItem: DealerInvoiceLine, newValue: number) {
        this.spinner.show('invoice-detail');

        const updateRequests = lineItem.sublines.map((dettaglio) => {
            dettaglio.soggetto_a_ritenuta = newValue;
            return this.service.updateInvoiceDetailLine(dettaglio.id, dettaglio);
        });

        forkJoin(updateRequests).subscribe(
            (results) => {
                this.toastr.success('Tutti i dettagli sono stati aggiornati con successo.', 'Successo');
                this.spinner.hide('invoice-detail');
                this.showInvoice(this.currentInvoice);
            },
            (error) => {
                this.toastr.error("Errore durante l'aggiornamento dei dettagli.", 'Errore');
                this.spinner.hide('invoice-detail');
            },
        );
    }

    selectInvoice(event: any, invoice: DealerInvoice) {
        if (invoice?.id) {
            if (event.currentTarget.checked) {
                invoice.checked = true;

                if (this.selectedInvoices.length === 0) {
                    this.firstInvoiceSelected = invoice;
                }

                this.selectedInvoices.push(invoice.id);
            } else {
                const index = this.selectedInvoices.findIndex((data) => data === invoice.id);
                if (index > -1) {
                    invoice.checked = false;
                    this.selectedInvoices.splice(index, 1);
                }

                if (this.selectedInvoices.length === 0) {
                    this.firstInvoiceSelected = null;
                }
            }
        }
    }

    mergeDocuments(selectedInvoices: any[]) {
        this.spinner.show('table-invoices');
        this.service.mergeDocuments(selectedInvoices).subscribe((data) => {
            this.firstInvoiceSelected = null;
            this.selectedInvoices = [];
            this.refresh();
        });
    }

    toggleSort(key: string) {
        if (this.filter.sort === key) {
            this.filter.sort = `-${key}`;
        } else {
            this.filter.sort = key;
        }
        this.refresh();
    }

    deleteInvoiceDetail(id: number) {
        this.service.deleteInvoiceDetail(id).subscribe(
            () => {
                const index = this.invoices.findIndex((dealer) => dealer.id === id);
                if (index > -1) {
                    this.service.successAlert('Deleted Service!');
                    this.showInvoice(this.currentInvoice);
                }
            },
            (err) => {
                this.service.errorAlert(err.message);
            },
        );
    }

    updateDestinatario() {
        this.spinner.show('destinatario');

        const params = {
            invoice_id: this.oldDestinatario.invoice_id,
            dealer_id: this.newDestinatario,
        };

        this.service.updateDestinatario(params).subscribe(
            (res) => {
                this.spinner.hide('destinatario');
                this.modal.dismissAll();
                this.newDestinatario = null;
                this.refresh();
            },
            (err) => {
                console.log('Errore: ', err);
                this.spinner.hide('destinatario');
                this.modal.dismissAll();
                this.newDestinatario = null;
            },
        );
    }

    openModal(invoiceId, destinatario, ragsociale) {
        this.oldDestinatario = {
            invoice_id: invoiceId,
            destinatario_id: destinatario,
            subject: ragsociale,
        };
        this.modal.open(this.destinatarioModal, { size: 'md' });
    }
}
