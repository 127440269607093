<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add Product</h4>
    <button (click)="dismissModal()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <diV class="row">
        <div class="col-12 mb-2">
            <label>Choose a product type</label>
            <ng-select [(ngModel)]="selectedProductType" [disabled]="!!selectedProduct">
                <ng-option *ngFor="let productType of productTypes" [value]="productType.key"
                           translate>{{productType.name}}</ng-option>
            </ng-select>
        </div>
    </diV>
    <form [formGroup]="standardProductForm" (ngSubmit)="saveStandardProduct()"
          *ngIf="selectedProductType==productTypes[0].key || selectedProductType==productTypes[1].key">
        <div class="form-row">
            <div class="col-12 col-lg-6 form-group">
                <label for="nameProduct" translate>Name</label>
                <input type="text" class="form-control" formControlName="name">
                <val-errors controlName="name">
                    <ng-template valError="required">
                        <span translate>Name is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="category" translate>Category</label>
                <ng-select id="category" formControlName="category_id"
                           [items]="categories"
                           bindValue="id" bindLabel="nome" (search)="loadCategories($event?.term)"
                           [loading]="categoriesLoading"></ng-select>
                <val-errors controlName="category_id">
                    <ng-template valError="required">
                        <span translate>Category is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="nameProduct" translate>Serial</label>
                <input type="text" class="form-control" formControlName="serial">
                <val-errors controlName="serial">
                    <ng-template valError="required">
                        <span translate>Serial is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="nameProduct" translate>SKU</label>
                <input type="text" class="form-control" formControlName="sku">
                <val-errors controlName="sku">
                    <ng-template valError="required">
                        <span translate>Sku is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="brandProduct" translate>Brand</label>
                <ng-select id="brandProduct" formControlName="brand_id"
                           [items]="brands"
                           bindValue="id" bindLabel="nome" (search)="loadBrands($event?.term)"
                           [loading]="brandsLoading"></ng-select>
                <val-errors controlName="category_id">
                    <ng-template valError="required">
                        <span translate>Brand is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="productImage" translate>Image</label>
                <div class="custom-file" *ngIf="!imageName">
                    <input id="productImage" accept="image/*" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0])">
                    <label class="custom-file-label" for="productImage" translate>
                        Upload image...
                    </label>
                </div>
                <div *ngIf="imageName">
                    <span class="text-break">{{imageName}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button" (click)="handleFileRemoveModal()">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
                <val-errors controlName="image_name">
                    <ng-template valError="required">
                        <span translate>Image is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label translate>Operator</label>
                <ng-select formControlName="operator_id" [items]="operators" bindLabel="name" bindValue="key">>
                </ng-select>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label translate>Serial type</label>
                <ng-select formControlName="serial_type_id"
                           [items]="serialTypes"
                           bindValue="id" bindLabel="nome" (search)="loadSerialTypes($event?.term)"
                           [loading]="serialTypesLoading">
                </ng-select>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label translate>Product type for account
                    statement</label>
                <ng-select [items]="statementTypes" bindLabel="name" bindValue="key"
                           formControlName="statement_type"></ng-select>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="standardPrice" translate>Nostro Prezzo</label>
                <input type="text" class="form-control" id="standardPrice" formControlName="our_price">
                <val-errors controlName="our_price">
                    <ng-template valError="required">
                        <span translate>Our price is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="standardPrice" translate>Prezzo Dealer</label>
                <input type="text" class="form-control" id="standardPrice" formControlName="dealer_price">
                <val-errors controlName="dealer_price">
                    <ng-template valError="required">
                        <span translate>Dealer price is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="creditCardPrice" translate>% Fee Carta di credito</label>
                <input type="text" class="form-control" id="creditCardPrice" formControlName="credit_card_percentage">
                <val-errors controlName="credit_card_percentage">
                    <ng-template valError="required">
                        <span translate>Fee of credit card is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Fornitore</label>
                <ng-select formControlName="supplier"
                           [items]="suppliers"
                           bindValue="key" bindLabel="name" 
                           [loading]="suppliersLoading"
                            >
                </ng-select>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="creditCardPrice" translate>% Sconto Dealer Pro</label>
                <input type="text" class="form-control" id="creditCardPrice" formControlName="dealer_pro_discount">
                <val-errors controlName="dealer_pro_discount">
                    <ng-template valError="required">
                        <span translate>Discount dealer pro is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-3 form-group">
                <label for="dealer" translate>Dealer compensation per unit</label>
                <input type="text" class="form-control" id="dealer" formControlName="compensation">
            </div>
            <div class="col-12 col-lg-3 form-group">
                <label for="purchase" translate>Free shipping with minimum purchase</label>
                <input type="number" class="form-control" id="purchase" formControlName="min_quantity_free_sped"
                       min="1">
            </div>
            <div class="col-12 col-lg-3 form-group">
                <label for="quantityPerUnit" translate>Quantity per unit</label>
                <input type="number" class="form-control" id="quantityPerUnit" formControlName="quantity_per_unit"
                       min="1">
                <val-errors controlName="quantity_per_unit">
                    <ng-template valError="required">
                        <span translate>Quantity is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-3 form-group">
                <label for="quantityInStock" translate>Quantity in stock</label>
                <input type="number" class="form-control" id="quantityInStock" formControlName="quantity_in_stock">
                <val-errors controlName="quantity_in_stock">
                    <ng-template valError="required">
                        <span translate>Quantity is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox"
                           value="1" formControlName="vat">
                    <label class="form-check-label" translate>
                        Product subject to VAT 22%
                    </label>
                </div>
            </div>
            <div class="col-12 col-lg form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox"
                           value="1" formControlName="virtual">
                    <label class="form-check-label" translate>
                        Virtual product
                    </label>
                </div>
            </div>
            <div class="col-12 col-lg form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox"
                           value="1" formControlName="shipment">
                    <label class="form-check-label" translate>
                        Product subject to shipment
                    </label>
                </div>
            </div>
            <div class="col-12 col-lg form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox"
                           value="1" formControlName="visible">
                    <label class="form-check-label" translate>
                        Visible
                    </label>
                </div>
            </div>
            <div class="col-12">
                <h5 translate>Payments:</h5>
                <hr>
            </div>
            <div class="col-12 col-lg form-group d-flex align-items-center"
                 *ngFor="let paymentType of paymentsArray.controls; let i=index;">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [value]="paymentTypes[i]?.key"
                           [formControl]="paymentType">
                    <label class="form-check-label pt-0 mt-o" translate>
                        {{paymentTypes[i]?.name}}
                    </label>
                </div>
            </div>
            <div class="col-12 form-group">
                <label for="productDescription" translate>Description</label>
                <editor [init]="{base_url: '/tinymce', suffix: '.min'}" id="productDescription" formControlName="description"></editor>
                <val-errors controlName="description">
                    <ng-template valError="required">
                        <span translate>Description is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 form-group">
                <label for="productDescription" translate>Descrizione Breve</label>
                <input type="text" class="form-control" id="nameProduct" formControlName="short_description">
                <val-errors controlName="short_description">
                    <ng-template valError="required">
                        <span translate>Description is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-12 form-group">
                <label translate>Sub-products</label>
                <ng-select multiple="true" [items]="subProducts" bindLabel="nome"
                           [searchable]="true" (search)="loadSubProducts($event?.term)" formControlName="sub_products"
                           [hideSelected]="true"
                           [loading]="subProductsLoading"
                           (remove)="removeSelectedSubProduct($event)"
                           [clearable]="false"></ng-select>
            </div>
            <div class="col-12">
                <h5 translate>Chosen sub-products:</h5>
                <div class="sub-product-container">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0" *ngFor="let sub_product of selectedSubProducts">
                            {{sub_product.nome}}
                            <button type="button" class="float-right btn btn-link text-danger m-0 p-0"
                                    (click)="removeSelectedSubProduct(sub_product.id)"><i
                                    class="fas fa-times-circle"></i></button>
                        </li>
                        <li class="list-group-item px-0 text-muted" *ngIf="selectedSubProducts?.length<=0" translate>
                            List
                            is empty
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-9 form-group" *ngIf="selectedProductType==productTypes[1].key">
                <h5 translate>Variants:</h5>
            </div>
            <div class="col-12 col-lg-3 form-group d-flex align-items-end"
                 *ngIf="selectedProductType==productTypes[1].key">
                <button type="button" class="btn btn-secondary btn-block" translate (click)="showVariantModal()">
                    Add Variant
                </button>
            </div>
            <div class="col-12 table-responsive sub-product-container" *ngIf="selectedProductType==productTypes[1].key">
                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th translate>Quantity</th>
                        <th class="text-right" translate>Unit price</th>
                        <th class="text-right" translate>VAT price</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let variant of variants;index as i">
                        <th>{{variant?.quantity}}</th>
                        <td class="text-right">{{variant?.price|currency:currency}}</td>
                        <td class="text-right">{{variant?.vat_price|currency:currency}}</td>
                        <td class="text-right">
                            <button type="button" class="float-right btn btn-link text-danger m-0 p-0"
                                    (click)="removeVariant(i)">
                                <i class="fas fa-times-circle"></i></button>
                        </td>
                    </tr>
                    <tr *ngIf="variants?.length<=0">
                        <td translate class="text-muted" colspan="5">List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 mt-2">
                <button type="submit" class="btn btn-success btn-block" [disabled]="standardProductForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
            </div>
        </div>
    </form>
    <form [formGroup]="splitProductForm" *ngIf="selectedProductType==productTypes[2].key"
          (ngSubmit)="saveSplitProduct()">
        <div class="form-row">
            <div class="col-12 col-lg-6 form-group">
                <label for="nameProduct" translate>Name</label>
                <input type="text" class="form-control" id="nameProduct" formControlName="name">
                <val-errors controlName="name">
                    <ng-template valError="required">
                        <span translate>Name is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="categoryProduct" translate>Category</label>
                <ng-select id="categoryProduct" formControlName="category_id"
                           [items]="categories"
                           bindValue="id" bindLabel="nome" (search)="loadCategories($event?.term)"
                           [loading]="categoriesLoading"></ng-select>
                <val-errors controlName="category_id">
                    <ng-template valError="required">
                        <span translate>Category is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="brand" translate>Brand</label>
                <ng-select id="brand" formControlName="brand_id"
                           [items]="brands"
                           bindValue="id" bindLabel="nome" (search)="loadBrands($event?.term)"
                           [loading]="brandsLoading"></ng-select>
                <val-errors controlName="category_id">
                    <ng-template valError="required">
                        <span translate>Brand is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="image" translate>Image</label>
                <div class="custom-file" *ngIf="!imageNameProduct">
                    <input id="image" accept="image/*" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0])">
                    <label class="custom-file-label" for="productImage" translate>
                        Upload image...
                    </label>
                </div>
                <div *ngIf="imageNameProduct">
                    <span class="text-break">{{imageNameProduct}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button" (click)="handleFileRemoveModal()">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
                <val-errors controlName="image_name">
                    <ng-template valError="required">
                        <span translate>Image is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="stPrice" translate>Standard price</label>
                <input type="text" class="form-control" id="stPrice" formControlName="standard_price">
                <val-errors controlName="standard_price">
                    <ng-template valError="required">
                        <span translate>Standard price is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="creditPrice" translate>Price with credit card</label>
                <input type="text" class="form-control" id="creditPrice" formControlName="credit_card_price">
                <val-errors controlName="credit_card_price">
                    <ng-template valError="required">
                        <span translate>Price with credit card is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="purchaseProduct" translate>Free shipping with minimum purchase</label>
                <input type="text" class="form-control" id="purchaseProduct" formControlName="min_quantity_free_sped">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="quantity" translate>Quantity in stock</label>
                <input type="text" class="form-control" id="quantity" formControlName="quantity_in_stock">
                <val-errors controlName="quantity_in_stock">
                    <ng-template valError="required">
                        <span translate>Quantity is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="countProduct" translate>Quantity per unit</label>
                <input type="text" class="form-control" id="countProduct" formControlName="quantity_per_unit">
                <val-errors controlName="quantity_per_unit">
                    <ng-template valError="required">
                        <span translate>Quantity is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" formControlName="vat"
                           value="1">
                    <label class="form-check-label" translate>
                        Product subject to VAT 22%
                    </label>
                </div>
            </div>
            <div class="col-12 col-lg form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" formControlName="virtual"
                           value="1">
                    <label class="form-check-label" translate>
                        Virtual product
                    </label>
                </div>
            </div>
            <div class="col-12 col-lg form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" formControlName="shipment"
                           value="1">
                    <label class="form-check-label" translate>
                        Product subject to shipment
                    </label>
                </div>
            </div>
            <div class="col-12 col-lg form-group">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox"
                           value="1" formControlName="visible">
                    <label class="form-check-label" translate>
                        Visible
                    </label>
                </div>
            </div>
            <div class="col-12">
                <h5 translate>Payments:</h5>
                <hr>
            </div>
            <div class="col-12 col-lg form-group d-flex align-items-center"
                 *ngFor="let paymentType of splitProductForm?.get('payment_types'); let i=index;">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [value]="paymentTypes[i]?.key"
                           [formControl]="paymentType">
                    <label class="form-check-label" translate>
                        {{paymentTypes[i]?.name}}
                    </label>
                </div>
            </div>
            <div class="col-12 form-group">
                <label for="description" translate>Description</label>
                <editor [init]="{base_url: '/tinymce', suffix: '.min'}" id="description" [init]="defaultConfig" formControlName="description"></editor>
            </div>
        </div>
        <div class="form-row" *ngIf="!selectedProduct">
            <div class="col-12">
                <h5 translate>Installment data</h5>
            </div>
            <div class="col-12 col-lg-12 form-group">
                <label translate>IMEI warehouse</label>
                <ng-select [items]="subProducts" bindLabel="nome" bindValue="id"
                           [searchable]="true" (search)="loadSubProducts($event?.term)" formControlName="imei"
                           [loading]="subProductsLoading"></ng-select>
            </div>
            <div class="col-12">
                <p translate>Installment tim</p>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Payable in installments in (N ° Installments)</label>
                <input type="text" class="form-control" formControlName="installment_payable">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Installment Cost / Month</label>
                <input type="text" class="form-control" formControlName="installment_cost">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Dealer profit on installments</label>
                <input type="text" class="form-control" formControlName="installment_profit">
            </div>
            <div class="col-12">
                <p translate>Credit card financing</p>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Payable in installments in (N ° Installments)</label>
                <input type="text" class="form-control" formControlName="credit_card_payable">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Installment Cost / Month</label>
                <input type="text" class="form-control" formControlName="credit_card_cost">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Dealer profit on installments</label>
                <input type="text" class="form-control" formControlName="credit_card_profit">
            </div>
            <div class="col-12">
                <p translate>SDD bank loan</p>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Payable in installments in (N ° Installments)</label>
                <input type="text" class="form-control" formControlName="sdd_bank_payable">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Installment Cost / Month</label>
                <input type="text" class="form-control" formControlName="sdd_bank_cost">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label translate>Dealer profit on installments</label>
                <input type="text" class="form-control" formControlName="sdd_bank_profit">
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-2">
                <button type="submit" class="btn btn-success btn-block" [disabled]="splitProductForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
            </div>
        </div>
    </form>
</div>


<ng-template #variationFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add Variant</h4>
        <button
                type="button"
                class="close" (click)="variationModal.dismiss()"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="variantProductForm" (ngSubmit)="saveVariation()">
            <div class="form-row">
                <div class="col-12 col-lg-6 form-group">
                    <label for="variationName" translate>Name</label>
                    <input type="text" class="form-control" id="variationName" formControlName="name">
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="quantityVariant" translate>Quantity</label>
                    <input type="number" class="form-control" id="quantityVariant" formControlName="quantity" min="1">
                    <val-errors controlName="quantity">
                        <ng-template valError="required">
                            <span translate>Quantity is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="unitPrice" translate>Price</label>
                    <input type="text" class="form-control" id="unitPrice" formControlName="price"
                           (blur)="calculateVatPrice()">
                    <val-errors controlName="price">
                        <ng-template valError="required">
                            <span translate>Price is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="vatPrice" translate>VAT price</label>
                    <input type="text" class="form-control" id="vatPrice" formControlName="vat_price">
                    <val-errors controlName="vat_price">
                        <ng-template valError="required">
                            <span translate>VAT price is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 form-group">
                    <label for="descriptionVariant" translate>Description</label>
                    <editor [init]="{base_url: '/tinymce', suffix: '.min'}" id="descriptionVariant" formControlName="description"></editor>
                </div>
                <div class="col-12 mt-2">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="variantProductForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
