/* tslint:disable:object-literal-key-quotes */
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrap from '@fullcalendar/bootstrap';
import {Calendar} from '@fullcalendar/core';
import {DataService} from '@services/data.service';
import {Employee} from '@models/employee';
import {environment} from '@environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '@services/authentication.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {CalendarOptions} from '@fullcalendar/angular';
import {Profile} from '@app/models/user';
import {PopupService} from '@services/popup.service';
import {Popup} from '@models/popup';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('modal') modal;
    @ViewChild('popupNewModal') popupNewModal;
    calendarPlugins = [dayGridPlugin, bootstrap];
    events: { start: string; end: string; title: string }[];
    employees: Employee[];
    direction: Employee[];

    classifica: [
        {
            id: string;
            presidio: string;
            venditori: number;
            totale_contratti: number;
            mnp: number;
            al: number;
        }
    ];

    me: Profile = null;
    data = [];
    environment = environment;

    userSubscription: Subscription;

    onStand = false;
    idStand = '0';
    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        // dateClick: this.handleDateClick.bind(this), // bind is important!
        events: [
            // { title: 'event 1', date: '2019-04-01' },
            // { title: 'event 2', date: '2019-04-02' }
        ]
    };
    popupNews: Popup;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private dataService: DataService,
        public modalService: NgbModal,
        private popupService: PopupService
    ) {
        const name = Calendar.name;

        if (this.authenticationService.currentUserValue.profile.role === 'stand') {
            this.onStand = true;
            this.idStand = this.authenticationService.currentUserValue.profile.id;
            console.log('id_stand: ' + this.idStand);
        }
    }

    ngAfterViewInit() {
        this.userSubscription = this.authenticationService
            .currentUser
            .subscribe((user) => {
                this.me = user.profile;
                if (['master', 'agent', 'masteragent', 'operator', 'submaster'].includes(user.profile.role)) {
                    this.router.navigate(['/dashboard']);
                } else if (user.loggedIn && user.profile?.is_psw_expired) {
                    this.router.navigate(['/change-password']);
                }

                if (user.profile.role === 'dealer') {
                    let valid = user.profile.iban;
                    valid = valid && user.profile.codfiscale;
                    valid = valid && user.profile.pec;
                    valid = valid && user.profile.codice_sdi;
                    valid = valid && user.profile.causale_ritenuta;
                    valid = valid && user.profile.regime_fiscale;
                    valid = valid && user.profile.tipo_ritenuta;
                    valid = valid && user.profile.tipologia_societa;

                    if (!valid) {
                        this.modalService.dismissAll();
                        this.modalService.open(this.modal, {size: 'lg'});
                    }
                }
            });
    }

    ngOnInit() {
        this.dataService.get('/get-backoffice')
            .subscribe((response: any) => {
                this.employees = response.result.backoffice;
                this.direction = response.result.direction;
            });
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll();
        this.userSubscription.unsubscribe();
    }
}
