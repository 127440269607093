<app-spinner [status]="status"></app-spinner>

<ng-container *ngIf="status === 'hidden'">
    <div class="d-flex align-items-center justify-content-center flex-wrap" *ngIf="this.data && this.data.length === 0"
         style="height: 250px;">
        <div class="text-center">
            <img src="/assets/img/flats/settings_2.png" height="64px"/>
            <p class="lead mt-2">Non ci sono dati con questi filtri. Prova a cambiarli.</p>
        </div>
    </div>

    <div class="row align-items-center mb-2" *ngFor="let row of data">
        <div class="col mr-2">
            <ng-container *ngIf="false">
                <div class="p-2" [ngClass]="{'box-hover-dark rounded': row.hasOwnProperty('url')}"
                     (click)="openAutoloadTable(autoloadTable, row)">
                    <p class="lead mb-0">{{row.title}}<span
                            class="float-right"><strong>{{row.value}}</strong></span></p>
                    <div class="progress rounded mt-1">
                        <div class="progress-bar bg-dark" aria-valuenow="43" aria-valuemin="0"
                             aria-valuemax="100" [style.width.%]="row.perc">{{row.perc}}%
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="p-2">
                <p class="lead mb-0">{{row.title}}<span
                        class="float-right"><strong>{{row.value}}</strong></span></p>
                <div class="progress rounded mt-1">
                    <div class="progress-bar bg-dark" aria-valuenow="43" aria-valuemin="0"
                         aria-valuemax="100" [style.width.%]="row.perc">{{row.perc}}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<ng-template #autoloadTable let-modal size="xl">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Dettaglio</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <app-autoload-table [url]="autoloadTableUrl"></app-autoload-table>
    </div>
</ng-template>
