<div class="card">
    <div class="card-header">
        <div class="card-title">
            Stai visualizzando l'anno:&nbsp;
            <a class=" click" (click)="openYearPopover(yearPopover)"
               #yearPopover="ngbPopover" autoClose="outside" triggers="manual"
               [ngbPopover]="yearPopContent" popoverTitle="Seleziona anno">{{ selectedYear }}</a>
            &nbsp;in modalità
            <a class=" click" (click)="openModePopover(modePopover)"
               #modePopover="ngbPopover" autoClose="outside" triggers="manual"
               [ngbPopover]="modePopContent" popoverTitle="Seleziona la modalità">{{ modes[selectedMode] }}</a>
        </div>
        <div class="card-tools">
            <button class="btn btn-sm btn-primary mr-2" (click)="toggleCompare()">
                {{ compareMode ? 'Normale' : 'Compara'}}
            </button>
            <button class="btn btn-sm btn-success mr-2" (click)="toggleEditMode()">
                {{ editMode ? 'Sola Lettura' : 'Modifica'}}
            </button>
            <button class="btn btn-sm btn-secondary" [routerLink]="['/business-plan/business-units']">Torna indietro</button>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <div class="card-title" *ngIf="!loading && businessUnit">Business Unit <b>{{ businessUnit.name }}</b></div>
        <div class="card-title" *ngIf="loading && !businessUnit">Caricamento...</div>
    </div>
    <div class="card-body p-0">
        <app-spinner *ngIf="loading" status='loading'></app-spinner>
        <table class="table table-sm table-bordered" *ngIf="!loading && businessUnit">
            <thead>
            <tr>
                <th>
                    <a class="link" (click)="showModal()">
                        <i class="fa fa-plus primary"></i>
                    </a>
                </th>
                <th class="text-center">Gen</th>
                <th class="text-center">Feb</th>
                <th class="text-center">Mar</th>
                <th class="text-center">Apr</th>
                <th class="text-center">Mag</th>
                <th class="text-center">Giu</th>
                <th class="text-center">Lug</th>
                <th class="text-center">Ago</th>
                <th class="text-center">Set</th>
                <th class="text-center">Ott</th>
                <th class="text-center">Nov</th>
                <th class="text-center">Dic</th>
                <th class="text-center">{{ selectedYear }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="businessUnit.rows_type.length === 0">
                <td colspan="14" class="text-center text-secondary" >
                    Non ci sono dati da visualizzare
                </td>
            </tr>
            <tr *ngFor="let type of businessUnit.rows_type; let i = index;">
                <td>
                    <span *ngIf="type.type !== 'separator'">{{type.name}}</span>

                    <div class="float-right" *ngIf="editMode">
                        <i class="fa fa-arrow-up text-secondary link mr-2" *ngIf="type.order > 1" (click)="moveUp(type)"></i>
                        <i class="fa fa-arrow-down text-secondary link mr-2" *ngIf="i +1 < businessUnit.rows_type.length" (click)="moveDown(type)"></i>

                        <a (click)="edit(type)" class="link"><i class="fas fa-edit text-primary mr-2"></i></a>
                        <a [swal]="confirmDeleteOptions"
                           (confirm)="delete(type)" class="link"><i class="fa fa-times text-danger"></i></a>
                    </div>
                </td>
                <ng-container *ngIf="type.type !== 'separator'">
                    <td *ngFor="let row of type.rows; let r = index;" class="text-right">
                        <ng-container *ngTemplateOutlet="trend; context:{$implicit: type.compare_percentage[r], tooltip: type.compare_rows[r].value }"></ng-container>
                        <ng-container *ngIf="!editMode">
                            <span *ngIf="type.format === 'number'">{{ row.value | number}}</span>
                            <span *ngIf="type.format === 'currency'">{{ row.value | currency: '€ ' : 'symbol' : '1.0-0'}}</span>
                        </ng-container>
                        <ng-container *ngIf="editMode">
                            <a *ngIf="row.value === null"  class="link"
                               (click)="openRowPopover(rowPopover, row)"
                               #rowPopover="ngbPopover" autoClose="outside" triggers="manual"
                               [ngbPopover]="rowPopContent" popoverTitle="Imposta valore"
                            >-</a>
                            <a *ngIf="row.value !== null" class="link"
                               (click)="openRowPopover(rowPopover, row)"
                               #rowPopover="ngbPopover" autoClose="outside" triggers="manual"
                               [ngbPopover]="rowPopContent" popoverTitle="Imposta valore"
                            >
                                <span *ngIf="type.format === 'number'">{{ row.value | number}}</span>
                                <span *ngIf="type.format === 'currency'">{{ row.value | currency: '€ ': 'symbol' : '1.0-0'}}</span>
                            </a>
                        </ng-container>
                    </td>
                    <td class="text-right text-bold" [ngClass]="{'text-success': type.type === 'revenue', 'text-danger': type.type === 'cost'}">
                        <ng-container *ngTemplateOutlet="trend; context:{$implicit: type.compare_total_percentage, tooltip: type.compare_total }"></ng-container>
                        <span *ngIf="type.format === 'number'">{{ type.total | number}}</span>
                        <span *ngIf="type.format === 'currency'">{{ type.total | currency: '€ ': 'symbol' : '1.0-0'}}</span>
                    </td>
                </ng-container>
                <td *ngIf="type.type === 'separator'" colspan="13">&nbsp;</td>
            </tr>

            <tr><td colspan="14">&nbsp;</td></tr>


            <tr>
                <td class="text-bold">Totale Fatturato</td>
                <td *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11,12]" class="text-right text-success" [ngClass]="{'text-bold': i === 12}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: businessUnit.compare_total_revenue_row_percentage[i], tooltip: businessUnit.compare_total_revenue_row[i] }"></ng-container>
                    {{ businessUnit.total_revenue_row[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </td>
            </tr>
            <tr>
                <td class="text-bold">Totale Costi</td>
                <td *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11,12]" class="text-right text-danger" [ngClass]="{'text-bold': i === 12}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: businessUnit.compare_total_cost_row_percentage[i], tooltip: businessUnit.compare_total_cost_row[i] }"></ng-container>
                    {{ businessUnit.total_cost_row[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </td>
            </tr>
            <tr><td colspan="14">&nbsp;</td></tr>

            <tr>
                <td class="text-bold">Margine</td>
                <td *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11,12]" class="text-right text-bold" [ngClass]="{'text-success': businessUnit.total_margin_row[i] > 0, 'text-danger': businessUnit.total_margin_row[i] < 0}">
                    <ng-container *ngTemplateOutlet="trend; context:{$implicit: businessUnit.compare_total_margin_row_percentage[i], tooltip: businessUnit.compare_total_margin_row[i] }"></ng-container>
                    {{ businessUnit.total_margin_row[i] | currency: '€ ' : 'symbol' : '1.0-0'}}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>


<ng-template #yearPopContent>
    <app-edit-popup [config]="yearPopupConfig" (onSave)="changeYear($event)"></app-edit-popup>
</ng-template>

<ng-template #modePopContent>
    <app-edit-popup [config]="modePopupConfig" (onSave)="changeMode($event)"></app-edit-popup>
</ng-template>

<ng-template #rowPopContent>
    <app-edit-popup outputType="all" [config]="rowPopupConfig" (onSave)="changeRow($event)"></app-edit-popup>
</ng-template>

<ng-template #formTemplate let-modal>
    <div class="modal-header">
        <h4>
            <span *ngIf="form.get('id').value">Aggiorna </span>
            <span *ngIf="!form.get('id').value">Crea </span>
            Riga
        </h4>
        <button (click)="_modalService.dismissAll()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form" (ngSubmit)="save($event)">
            <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" formControlName="name" placeholder="" />
            </div>
            <div class="form-group">
                <label>Tipo</label>
                <select class="form-control" formControlName="type">
                    <option value="">Seleziona un tipo</option>
                    <option value="data">Dati</option>
                    <option value="revenue">Ricavo</option>
                    <option value="cost">Costo</option>
                    <option value="separator">Separatore</option>
                </select>
            </div>
            <div class="form-group">
                <label>Formato</label>
                <select class="form-control" formControlName="format">
                    <option value="">Seleziona un formato</option>
                    <option value="number">Numero</option>
                    <option value="currency">Valuta</option>
                    <option value="none">Nessuno</option>
                </select>
            </div>

            <div class="form-group">
                <label>Nascosto</label>
                <select class="form-control" formControlName="hidden">
                    <option value="">Seleziona un valore</option>
                    <option value="1">Nascosto</option>
                    <option value="0">Visibile</option>
                </select>
            </div>

            <button type="submit" class="btn btn-primary" [disabled]="!this.form.valid || this.loading">
                <span *ngIf="form.get('id').value">Aggiorna</span>
                <span *ngIf="!form.get('id').value">Crea</span>
            </button>
        </form>
    </div>
</ng-template>

<ng-template #trend let-value let-tooltip="tooltip">
    <span [ngbTooltip]="tooltip | currency: '€ ': 'symbol' : '1.0-0'" class="text-sm float-left pt-1" *ngIf="compareMode && value != 0 && value != null" [ngClass]="{'text-danger': value < 0, 'text-success': value > 0}">
        {{ value }}%
        <i class="fa text-sm" [ngClass]="{'fa-angle-double-up': value > 0, 'fa-angle-double-down': value < 0}"></i>
    </span>
</ng-template>