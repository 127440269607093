<div>
    <div *ngIf="isLoading" class="text-center text-lg">
        <svg class="my-4" height="100" preserveAspectRatio="xMidYMid"
             style="margin: 1rem auto 1rem auto; display: block;" viewBox="0 0 100 100" width="100" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(50 50)">
                <g transform="translate(-17 -17) scale(0.5)">
                    <g>
                        <animateTransform attributeName="transform" begin="0s" dur="0.2s" keyTimes="0;1"
                                          repeatCount="indefinite" type="rotate" values="0;45"></animateTransform>
                        <path d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.359972760794346 21.46047782418268 L31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                              fill="#F6B303"></path>
                    </g>
                </g>
                <g transform="translate(0 22) scale(0.4)">
                    <g>
                        <animateTransform attributeName="transform" begin="-0.1s" dur="0.2s" keyTimes="0;1"
                                          repeatCount="indefinite" type="rotate" values="45;0"></animateTransform>
                        <path d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.359972760794346 21.46047782418268 L31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                              fill="#656564"></path>
                    </g>
                </g>
                <g transform="translate(28 4) scale(0.3)">
                    <g>
                        <animateTransform attributeName="transform" begin="-0.1s" dur="0.2s" keyTimes="0;1"
                                          repeatCount="indefinite" type="rotate" values="0;45"></animateTransform>
                        <path d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.359972760794346 21.46047782418268 L31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                              fill="#FF9824"></path>
                    </g>
                </g>
            </g>
        </svg>
        <p>Caricamento...</p>
    </div>

    <!--  <ngx-spinner-->
    <!--    bdColor="rgba(0, 0, 0, 0.8)"-->
    <!--    size="medium"-->
    <!--    color="#fff"-->
    <!--    type="ball-spin-fade"-->
    <!--    [fullScreen]="false"-->
    <!--  ></ngx-spinner>-->

    <div *ngIf="somethingChanged && !isLoading" class="p-3">Alcuni parametri sono cambiati, vuoi aggiornare i dati? <button (click)="refresh()" class="btn btn-sm btn-primary">Aggiorna Dati</button></div>

    <table *ngIf="!isLoading" [ngClass]="table.classes" class="table">
        <thead>
        <tr>
            <th
                    *ngFor="let col of table.columnSet; let i = index"
                    [attr.colSpan]="col.span"
                    [ngClass]="col.classes"
                    class="{{ i > 0 ? 'border-left' : ''}}"
            >
                <i
                        (selected)="setDateRange(col, $event.start, $event.end)"
                        *ngIf="col.columnType === 1"
                        [(ngModel)]="table.dateRanges[col.key]"
                        [name]="col.key"
                        class="far fa-calendar-alt float-left link mt-1 mr-2"
                        daterangepicker
                        ngDefaultControl
                ></i>
                {{ col.label }}
                <div
                        *ngIf="col.key === 'default' && table.dataProvider.length > 0"
                        class="d-inline-block float-right"
                        ngbDropdown [autoClose]="false"
                >
                    <i
                            class="fas fa-plus-circle link text-success"
                            ngbDropdownToggle
                    ></i>
                    <ul ngbDropdownMenu style="max-height: 300px; overflow-y: scroll; padding: 0;">
                        <ng-container *ngFor="let item of table.dataProvider">
                            <li
                                    (confirm)="saveAction($event, item)"
                                    *ngIf="!item.hasOwnProperty('calendar') || item.calendar; else noCalendarItem"
                                    [swal]="dataProviderActions(item)"
                                    ngbDropdownItem
                            >
                                <label class="mb-0" style="min-width: 100%">
                                    <i [ngClass]="item.icon"></i>
                                    {{ item.label }}
                                </label>
                            </li>
                            <ng-template
                                #noCalendarItem>
                                <li
                                        (click)="saveActionNoCalendar(item)"
                                        ngbDropdownItem
                                >
                                    <label class="mb-0" style="min-width: 100%">
                                        <i [ngClass]="item.icon"></i>
                                        {{ item.label }}
                                    </label>
                                </li>
                            </ng-template>

                        </ng-container>
                    </ul>
                </div>
                <div
                        *ngIf="col.subset.length > 0"
                        class="d-inline-block mr-2 float-left"
                        ngbDropdown [autoClose]="false"
                >
                    <i class="fas fa-list link text-info" ngbDropdownToggle></i>
                    <ul ngbDropdownMenu  style="max-height: 300px; overflow-y: scroll; padding: 0;">
                        <li *ngFor="let subsetItem of col.subset" ngbDropdownItem>
                            <label class="mb-0" style="min-width: 100%">
                                <input
                                        [(ngModel)]="subsetItem.checked"
                                        (ngModelChange)="updateSubset(col, subsetItem)"
                                        type="checkbox"
                                />
                                {{ subsetItem.label }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div
                        *ngIf="col.percentageSubset.length > 0"
                        class="d-inline-block mr-2 float-left"
                        ngbDropdown [autoClose]="false"
                >
                    <i class="fas fa-percentage link text-dark" ngbDropdownToggle></i>
                    <ul ngbDropdownMenu style="max-height: 300px; overflow-y: scroll; padding: 0;">
                        <li
                                *ngFor="let subsetItem of col.percentageSubset"
                                ngbDropdownItem
                        >
                            <label class="mb-0" style="min-width: 100%">
                                <input
                                        [(ngModel)]="subsetItem.checked"
                                        (ngModelChange)="updateSubset(col, subsetItem)"
                                        type="checkbox"
                                />
                                {{ subsetItem.label }}
                            </label>
                        </li>
                    </ul>
                </div>
                <ng-container *ngIf="table.pbxCampaignAvailable && i === 0">
                    <i (click)="showPbxCampaign()" *show="['operator']" class="fa fa-headphones link ml-2"></i>
                </ng-container>
            </th>
        </tr>
        <tr>
            <th *ngFor="let col of table.columns" [ngClass]="col.classes">
                <i
                        (selected)="setDateRange(col, $event.start, $event.end)"
                        *ngIf="col.columnType === 1"
                        [(ngModel)]="table.dateRanges[col.key]"
                        [name]="col.key"
                        class="far fa-calendar-alt float-left link mt-1"
                        daterangepicker
                        ngDefaultControl
                ></i>
                <a (click)="toggleSort(col)" class="cursor-hand" translate>
                    {{ col.label }}
                </a>
                <span *ngIf="table.sort && !table.sort[col.key]" class="float-left mr-2">
            <i class="fas fa-sort"></i>
          </span>
                <span
                        *ngIf="table.sort &&
              table.sort[col.key] &&
              (table.sort[col.key] === 3 || table.sort[col.key] === '3')
            "
                        class="float-left mr-2"
                >
            <i class="fas fa-sort-amount-down text-success"></i>
          </span>
                <span
                        *ngIf=" table.sort &&
              table.sort[col.key] &&
              (table.sort[col.key] === 4 || table.sort[col.key] === '4')
            "
                        class="float-left mr-2"
                >
            <i class="fas fa-sort-amount-up text-success"></i>
          </span>
            </th>
        </tr>
        <tr *ngIf="table.showFilters">
            <th *ngFor="let col of table.columns" [ngClass]="col.classes">
                <ng-container [ngSwitch]="col.filter">
                    <ng-select
                            (change)="setFilters()"
                            *ngSwitchCase="2"
                            [(ngModel)]="this.table.filters[col.key]"
                            [items]="table.filterValues[col.key]"
                            [multiple]="true"
                            class="form-control-sm"
                    ></ng-select>
                </ng-container>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr
                *ngFor="
          let row of table.rows | slice: (page - 1) * pageSize:page * pageSize
        "
        >
            <td *ngFor="let col of table.columns" [ngClass]="col.classes">
                <ng-container [ngSwitch]="col.type">
                    <span *ngSwitchCase="0">{{ row[col.key] }}</span>
                    <span *ngSwitchCase="1">{{ row[col.key] | percent }}</span>
                    <span *ngSwitchCase="2">
              <i *ngIf="row[col.key] === '1' || row[col.key] === true; else elseBool;" class="fas fa-check-circle text-success"></i>
              <ng-template #elseBool><i class="fas fa-times-circle text-danger"></i></ng-template>
            </span>
                    <span *ngSwitchCase="3">{{ row[col.key] | currency: '€ ' }}</span>
                    <span *ngSwitchCase="4">{{ row[col.key] | amDateFormat: 'DD-MM-YYYY' }}</span>
                    <span *ngSwitchCase="5"><a [routerLink]="convertUrlParams(col.extras.url, row)">{{ row[col.key] }}</a></span>
                </ng-container>
            </td>
        </tr>
        <tr *ngIf="table.totalRow" class="bg-gradient-dark">
            <ng-container *ngFor="let col of table.columns; let i = index">
                <th [ngClass]="col.classes">
                    <ng-container [ngSwitch]="col.type">
                        <span *ngSwitchCase="0">{{ table.totalRow[col.key] }}</span>
                        <span *ngSwitchCase="1">{{
                            table.totalRow[col.key] | percent
                            }}</span>
                        <span *ngSwitchCase="3">{{ table.totalRow[col.key] | currency: '€ ' }}</span>
                        <span *ngSwitchCase="4"></span>
                    </ng-container>
                </th>
            </ng-container>
        </tr>
        </tbody>
    </table>

    <div class="px-4 pb-4">
        <a
                (click)="exportTable()"
                *ngIf="!this.isLoading && table && table.export"
                class="text-success cursor-hand"
        ><i class="fas fa-table"></i> Esporta lista in excel <span class="text-sm">({{table.originalRows.length}} righe)</span></a
        >

        <ngb-pagination
                *ngIf="!this.isLoading && this.table"
                [(page)]="page"
                [collectionSize]="table.rows.length"
                [maxSize]="10"
                [pageSize]="pageSize"
                class="float-right"
                size="sm"
        ></ngb-pagination>
    </div>
</div>

<ng-template #campaignFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate> Create Campaign</h4>
        <button
                (click)="modal.dismiss('Cross click')"
                aria-label="Close"
                class="close"
                type="button"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-pbx-campaign-modal (createdCampaign)="handleSavedCampaign($event)" [dealerIds]="table.ids"
                            [tableUrl]="completeUrl">
    </app-pbx-campaign-modal>
</ng-template>
