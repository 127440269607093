import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment/moment';
import {RequestService} from '@services/request.service';

@Component({
    selector: 'app-master-nuove-affiliazioni',
    templateUrl: './master-nuove-affiliazioni.component.html',
    styleUrls: ['./master-nuove-affiliazioni.component.css']
})
export class MasterNuoveAffiliazioniComponent implements OnInit {
    busy: Subscription;
    laddaTrendDaily = null;
    laddaTrendMonthly = null;

    urlSubheader: string;
    urlAgenti: string;
    urlRegioni: string;
    urlAgentiTabella: string;
    urlImproduttiviTabella: string;
    urlNegoziTabella: string;
    urlAffiliazioniTabella: string;
    urlTrend: string;
    chartPeriod = {
        from: moment().subtract(12, 'month').startOf('month'),
        to: moment().endOf('month'),
    };

    operatore = null;

    period = {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD'),
    };

    constructor(
        public requestService: RequestService
    ) {
    }

    ngOnInit() {
    }

    updateUrls(operatore, periodo) {
        this.operatore = operatore;
        const periodMoment = moment(periodo);
        let period = {
            start: periodMoment.startOf('month').format('YYYY-MM-DD'),
            end: periodMoment.endOf('month').format('YYYY-MM-DD'),
        };

        if (moment(periodo).format('YYYY-MM') === moment().format('YYYY-MM')) {
            period.end = moment().format('YYYY-MM-DD');
        }

        this.period = period;

        this.urlSubheader = `v2/openings/overview-header?vendor=${operatore}&period=${periodo}`;
        this.urlAgenti = `v2/openings/new-list-by-agent?vendor=${operatore}&date[current_month]=${period.start};${period.end}`;
        this.urlTrend = `/openings/trend?vendor=${operatore}&from=${period.start}&to=${period.end}`;
        this.urlRegioni = `v2/openings/new-list-by-am?vendor=${operatore}&date[current_month]=${period.start};${period.end}`;
        this.urlAffiliazioniTabella = `v2/openings/request-list?vendor=${operatore}&date[current_month]=${period.start};${period.end}`;
        this.urlNegoziTabella = `v2/openings/new-list?vendor=${operatore}&date[current_month]=${period.start};${period.end}`;
        this.urlAgentiTabella = `/nuove-affiliazioni-dettaglio?operatore=${operatore}&periodo=${periodo}`;
        this.urlImproduttiviTabella = `/nuove-affiliazioni-improduttivi?operatore=${operatore}&periodo=${periodo}`;
    }

    downloadDailyTrend() {
        this.laddaTrendDaily = this.requestService
            .get('v2/openings/trend?vendor=' + this.operatore + '&from=' + this.period.start + '&to=' + this.period.end + '&download=1', {}, {responseType: 'blob'})
            .subscribe((res: any) => {
                this.requestService.downloadFile(res, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                this.laddaTrendDaily = null;
            });
    }

    downloadMonthlyTrend() {
        this.laddaTrendMonthly = this.requestService
            .get('v2/openings/trend-range?vendor=' + this.operatore + '&from=' + this.chartPeriod.from + '&to=' + this.chartPeriod.to + '&download=1', {}, {responseType: 'blob'})
            .subscribe((res: any) => {
                this.requestService.downloadFile(res, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                this.laddaTrendMonthly = null;
            });
    }

    updateChart($event: any) {
        this.chartPeriod = {
            from: $event.start,
            to: $event.end,
        };
    }
}
