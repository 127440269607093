import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from '@services/store.service';
import { StoreCategory, StoreCategoryPagination } from '@models/store/store-category';
import { StoreOperator, StoreOperatorPagination } from '@models/store/store-operator';
import { StoreBrand, StoreBrandPagination } from '@models/store/store-product';
import { TinyMCE } from '@models/tinyMCE';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.css'],
})
export class CategoryModalComponent implements OnInit {
  @Input() categories: StoreCategory[] = [];
  @Input() selectedCategory: StoreCategory;
  busy: any;
  categoryForm = new FormGroup({
    name: new FormControl('', Validators.required),
    image_name: new FormControl('', Validators.required),
    category_id: new FormControl(''),
    operator_id: new FormControl(''),
    brand_id: new FormControl(''),
    description: new FormControl(''),
    featured: new FormControl('', Validators.required),
  });
  submittedFile: File;
  defaultConfig = new TinyMCE();
  categoriesLoading = false;
  operatorsLoading = false;
  brandsLoading = false;
  parentCategories: StoreCategory[] = [];
  operators: StoreOperator[] = [];
  brands: StoreBrand[] = [];

  featuredItems = [
    {
      key: 'Si',
      value: '1',
    },
    {
      key: 'No',
      value: '0',
    },
  ];

  constructor(public modalService: NgbModal, private storeService: StoreService) {}

  ngOnInit(): void {
    this.selectedCategory.featured = this.selectedCategory.featured.toString();
    this.loadParentCategories();
    setTimeout(() => {
      this.loadOperators();
      this.loadBrands();
      this.loadData();
    });
  }

  saveCategory() {
    const category = new StoreCategory();
    category.nome = this.categoryForm.value.name;
    category.id_padre = this.categoryForm.value.category_id;
    category.id_gestore = this.categoryForm.value.operator_id;
    category.immagine_path = this.categoryForm.value.image_name;
    category.descrizione = this.categoryForm.value.description;
    category.featured = this.categoryForm.value.featured;
    category.id_brand_telefonia = this.categoryForm.value.brand_id;
    category.file = this.submittedFile;
    if (this.selectedCategory) {
      return this.changeCategory(category);
    }
    return this.addCategory(category);
  }

  changeCategory(category: StoreCategory) {
    this.busy = this.storeService.putCategory(this.selectedCategory.id, category).subscribe(
      (response: StoreCategory) => {
        const index = this.categories.findIndex((data) => data.id === response.id);
        if (index >= 0) {
          this.categories[index] = response;
        }
        this.storeService.successAlert('Changed Category!');
        this.dismissModal();
      },
      (err) => {
        this.storeService.errorAlert(err.message);
        this.dismissModal();
      },
    );
  }

  addCategory(category: StoreCategory) {
    this.busy = this.storeService.postCategory(category).subscribe(
      (response: StoreCategory) => {
        this.categories.unshift(response);
        this.storeService.successAlert('Created Category!');
        this.dismissModal();
      },
      (err) => {
        this.storeService.errorAlert(err.message);
        this.dismissModal();
      },
    );
  }

  handleFileInput(file: File) {
    this.submittedFile = file;
    this.categoryForm.get('image_name').setValue(file.name);
  }

  handleFileRemoveModal() {
    this.categoryForm.get('image_name').setValue('');
    this.submittedFile = null;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  loadParentCategories(term?) {
    this.categoriesLoading = true;
    const params = {};
    if (term) {
      params['nome'] = term;
    } else if (this.selectedCategory && this.selectedCategory?.id_padre) {
      params['id'] = this.selectedCategory.id_padre;
    }
    this.storeService.getCategories(params).subscribe(
      (response: StoreCategoryPagination) => {
        this.parentCategories = response.data;
        this.categoriesLoading = false;
      },
      (err) => {
        this.categoriesLoading = false;
      },
    );
  }

  loadOperators(term?) {
    this.operatorsLoading = true;
    const params = {};
    if (term) {
      params['name'] = term;
    } else if (this.selectedCategory && this.selectedCategory?.id_gestore) {
      params['id'] = this.selectedCategory.id_gestore;
    }
    this.storeService.getOperators(params).subscribe(
      (response: StoreOperatorPagination) => {
        this.operators = response.data;
        this.operatorsLoading = false;
      },
      (err) => {
        this.operatorsLoading = false;
      },
    );
  }

  loadBrands(term?) {
    this.brandsLoading = true;
    const params = {};
    if (term) {
      params['name'] = term;
    } else if (this.selectedCategory && this.selectedCategory?.id_brand_telefonia) {
      params['id'] = this.selectedCategory.id_brand_telefonia;
    }
    this.storeService.getBrands(params).subscribe(
      (response: StoreBrandPagination) => {
        this.brands = response.data;
        this.brandsLoading = false;
      },
      (err) => {
        this.brandsLoading = false;
      },
    );
  }

  loadData() {
    if (this.selectedCategory) {
      this.categoryForm.setValue({
        name: this.selectedCategory?.nome ? this.selectedCategory.nome : '',
        image_name: this.selectedCategory?.immagine_path ? this.selectedCategory.immagine_path : '',
        description: this.selectedCategory?.descrizione ? this.selectedCategory.descrizione : '',
        category_id: this.selectedCategory?.id_padre ? Number(this.selectedCategory.id_padre) : '',
        operator_id: this.selectedCategory?.id_gestore ? Number(this.selectedCategory.id_gestore) : '',
        featured: this.selectedCategory?.featured,
        brand_id: this.selectedCategory?.id_brand_telefonia ? Number(this.selectedCategory.id_brand_telefonia) : '',
      });
    }
  }

  get imageName() {
    return this.categoryForm.get('image_name').value;
  }
}
