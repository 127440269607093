<app-spinner *ngIf="loading" status="loading"></app-spinner>

<div class="row" *ngIf="data && !loading">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label>Mostra dati nel periodo</label>
                            <app-datepicker-range-popup
                                    (onSelect)="setSelectedDateRange($event.from, $event.to)"
                                    [from]="filterForm.from"
                                    [to]="filterForm.to"
                            ></app-datepicker-range-popup>
                        </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-4"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/kd_cube_blue.svg" />
                    KDCube
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table" *ngIf="data.pro_subscription.enabled">
                    <tr>
                        <th>Abbonamento Pro</th>
                        <th>Scade il</th>
                        <th>Modalità di pagamento</th>
                        <th>Credito Attuale</th>
                    </tr>
                    <tr>
                        <td><span class="badge badge-success">Si</span></td>
                        <td>{{ data.pro_subscription.to }}</td>
                        <td>{{ data.pro_subscription.payment }}</td>
                        <td>{{ data.kd_credit | currency: '€ ' }}</td>
                    </tr>
                </table>
                <table class="table" *ngIf="!data.pro_subscription.enabled">
                    <tr>
                        <th>Abbonamento Pro</th>
                        <th>Mai stato pro?</th>
                        <th *ngIf="data.pro_subscription.been_pro">Ultima volta pro</th>
                        <th>Credito Attuale</th>
                    </tr>
                    <tr>
                        <td><span class="badge badge-danger">No</span></td>
                        <td ><span class="badge" [ngClass]="{'badge-success': data.pro_subscription.been_pro, 'badge-danger': !data.pro_subscription.been_pro}">{{ data.pro_subscription.been_pro ? 'Si' : 'No' }}</span></td>
                        <td *ngIf="data.pro_subscription.been_pro">{{ data.pro_subscription.last_time }}</td>
                        <td>{{ data.kd_credit | currency: '€ ' }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img width="40px" class="mr-2" src="https://dealer.kdistribution.it/assets/images/logo/logo-text.svg" />
                    Kena Mobile
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Abilitato</th>
                        <th>Plafond Attuale</th>
                        <th>Media Fatturato</th>
                        <th width="30%" *ngIf="data.kena.sales.length > 0">Vendite</th>
                        <th *ngIf="data.kena.sales.length === 0">Ultima Vendita</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.kena.enabled, 'badge-danger': !data.kena.enabled}">{{ data.kena.enabled ? 'Si' : 'No' }}</span></td>
                        <td>{{ data.kena.plafond == null ? 'Non aggiornato' : data.kena.plafond }}</td>
                        <td>{{ data.kena.average_invoice | currency: '€ '}}</td>
                        <td *ngIf="data.kena.sales.length > 0">
                            <table class="table-borderless table-sm w-100">
                                <tr *ngFor="let period of data.kena.sales">
                                    <td>{{period.month}}</td>
                                    <td class="text-right">{{period.sales}}</td>
                                </tr>
                            </table>
                        </td>
                        <td *ngIf="data.kena.sales.length === 0">
                            {{ data.kena.last_sale }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img width="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/a2a/logo-form.svg" />
                    A2A
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Abilitato</th>
                        <th>Fatturato Medio</th>
                        <th width="30%" *ngIf="data.a2a.sales.length > 0">Vendite</th>
                        <th *ngIf="data.a2a.sales.length === 0">Ultima Vendita</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.a2a.enabled, 'badge-danger': !data.a2a.enabled}">{{ data.a2a.enabled ? 'Si' : 'No' }}</span></td>
                        <td>{{ data.a2a.average_invoice | currency: '€ '}}</td>
                        <td *ngIf="data.a2a.sales.length > 0">
                            <table class="table-borderless table-sm w-100">
                                <tr *ngFor="let period of data.a2a.sales">
                                    <td>{{period.month}}</td>
                                    <td class="text-right">{{period.sales}}</td>
                                </tr>
                            </table>
                        </td>
                        <td *ngIf="data.a2a.sales.length === 0">
                            {{ data.a2a.last_sale }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img width="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/tim.svg" />
                    TIM
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Abilitato</th>
                        <th>Fatturato Medio</th>
                        <th width="30%" *ngIf="data.tim.sales.length > 0">Vendite</th>
                        <th *ngIf="data.tim.sales.length === 0">Ultima Vendita</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.tim.enabled, 'badge-danger': !data.tim.enabled}">{{ data.tim.enabled ? 'Si' : 'No' }}</span></td>
                        <td>{{ data.tim.average_invoice | currency: '€ '}}</td>
                        <td *ngIf="data.tim.sales.length > 0">
                            <table class="table-borderless table-sm w-100">
                                <tr *ngFor="let period of data.tim.sales">
                                    <td>{{period.month}}</td>
                                    <td class="text-right">{{period.sales}}</td>
                                </tr>
                            </table>
                        </td>
                        <td *ngIf="data.tim.sales.length === 0">
                            {{ data.tim.last_sale }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/mastersms.svg" />
                    Tickets
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Totali</th>
                        <th>Ancora aperti</th>
                        <th>Feedback Medio</th>
                    </tr>
                    <tr>
                        <td>{{ data.tickets.total }}</td>
                        <td>{{ data.tickets.open }}</td>
                        <td>{{ data.tickets.average_feedback === 0 ? 'Mai dato' : data.tickets.average_feedback }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/tech.svg" />
                    Shop
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Totale Prodotti Acquistati</th>
                        <th>Credito da compensare</th>
                    </tr>
                    <tr>
                        <td>{{ data.shop_products | currency: '€ ' }}</td>
                        <td>{{ data.credit | currency: '€ ' }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/services/kd_finance.svg" />
                    Visite
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Visite nel periodo selezionato</th>
                        <th>Ultima visita</th>
                    </tr>
                    <tr>
                        <td>{{ data.visits.total }}</td>
                        <td>{{ data.visits.last }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/visure.svg" />
                    Etichette
                </h4>
            </div>
            <div class="card-body p-0">
                <ul class="list-group list-group-flush">
                    <li *ngFor="let label of data.labels" class="list-group-item">
                      <div class="badge badge-info">{{ label }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/amex/logo_amex_full.svg" />
                    American Express
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Segnalazione per se stesso</th>
                        <th>Segnalazioni inserite</th>
                        <th>Carte attivate</th>
                        <th>Priorità</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.amex.self, 'badge-danger': !data.amex.self}">{{ data.amex.self ? 'Si' : 'No' }}</span></td>
                        <td>{{ data.amex.notifications }}</td>
                        <td>{{ data.amex.activated }}</td>
                        <td><span class="badge" [ngClass]="{'badge-success': data.amex.priority, 'badge-danger': !data.amex.priority}">{{ data.amex.priority ? 'In priorità' : 'Non in priorità' }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/services/logo_spid.svg" />
                    SPID
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>SPID in magazzino</th>
                        <th>SPID da IR venduti</th>
                        <th>SPID con webcam venduti</th>
                    </tr>
                    <tr>
                        <td>{{ data.spid.credit }}</td>
                        <td>{{ data.spid.activated }}</td>
                        <td>{{ data.spid.sold }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/pay.svg" />
                    KDPay
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Servizio Attivo</th>
                        <th>Attivo dal</th>
                        <th>Bollettini venduti</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.kd_pay.active, 'badge-danger': !data.kd_pay.active}">{{ data.kd_pay.active ? 'Si' : 'No' }}</span></td>
                        <td>{{ data.kd_pay.active_since }}</td>
                        <td>{{ data.kd_pay.pay_slips }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/ricariche.svg" />
                    Ricariche & Gift Card
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Fatturato Ricariche & Gift Card</th>
                        <th>Licenze Bitdefender</th>
                        <th>Licenze Garanzia3</th>
                    </tr>
                    <tr>
                        <td>{{ data.recharges | currency: '€ ' }}</td>
                        <td>{{ data.bitdefender }}</td>
                        <td>{{ data.garanzia_tre }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img width="40px"  class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/satispay/satispay.svg" />
                    Satispay
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Attivazione</th>
                        <th>Stato</th>
                        <th>Priorità</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.satispay.enabled, 'badge-danger': !data.satispay.enabled}">{{ data.satispay.enabled ? 'Si' : 'No' }}</span></td>
                        <td>{{ data.satispay.status }}</td>
                        <td><span class="badge" [ngClass]="{'badge-success': data.satispay.priority, 'badge-danger': !data.satispay.priority}">{{ data.satispay.priority ? 'In priorità' : 'Non in priorità' }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img width="40px"  class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/misc/logo-sisal.png" />
                    Sisal
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Disponibile</th>
                        <th>Link Tracciante</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.sisal.enabled, 'badge-danger': !data.sisal.enabled}">{{ data.sisal.enabled ? 'Si' : 'No' }}</span></td>
                        <td>{{ data.sisal.link }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img width="40px"  class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/misc/ups-logo.svg" />
                    UPS
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Disponibile</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.ups.enabled, 'badge-danger': !data.ups.enabled}">{{ data.ups.enabled ? 'Si' : 'No' }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img width="40px"  class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/pudo/Frame233.png" />
                    Indabox
                </h4>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <tr>
                        <th>Disponibile</th>
                    </tr>
                    <tr>
                        <td><span class="badge" [ngClass]="{'badge-success': data.indabox.enabled, 'badge-danger': !data.indabox.enabled}">{{ data.indabox.enabled ? 'Si' : 'No' }}</span></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <img height="40px" class="mr-2" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/visure.svg" />
                    Altri Servizi
                </h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-4 text-center">
                        <img height="40px" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/servizi_postali.svg" />
                        <div class="text-lg">{{ data.other_services.postal }}</div>
                        <div class="text-sm">raccomandate</div>
                    </div>
                    <div class="col-4 text-center">
                        <img height="40px" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/speedy.svg" />
                        <div class="text-lg">{{ data.other_services.packages }}</div>
                        <div class="text-sm">pacchi</div>
                    </div>
                    <div class="col-4 text-center">
                        <div style="height: 40px">
                            <img style="width: 70px;" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/flixbus.svg" />
                        </div>
                        <div class="text-lg">{{ data.other_services.flixbus }}</div>
                        <div class="text-sm">flixbus</div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-4 text-center">
                        <img height="40px" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/visure.svg" />
                        <div class="text-lg">{{ data.other_services.certificates }}</div>
                        <div class="text-sm">visure</div>
                    </div>
                    <div class="col-4 text-center">
                        <img height="40px" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/print.svg" />
                        <div class="text-lg">{{ data.other_services.printing }}</div>
                        <div class="text-sm">stampe</div>
                    </div>
                    <div class="col-4 text-center">
                        <img height="40px" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/services/kd_pec.svg" />
                        <div class="text-lg">{{ data.other_services.pec }}</div>
                        <div class="text-sm">pec</div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-4 text-center">
                        <img height="40px" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/assicura.svg" />
                        <div class="text-lg">{{ data.other_services.insurance }}</div>
                        <div class="text-sm">assicurazioni segnalate</div>
                    </div>
                    <div class="col-4 text-center">
                        <img height="40px" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/logo/ticket.svg" />
                        <div class="text-lg">{{ data.other_services.tickets }}</div>
                        <div class="text-sm">tickets</div>
                    </div>
                    <div class="col-4 text-center">
                        <div style="height: 40px">
                            <img style="width: 70px;" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/integra/logo.svg" />
                        </div>
                        <div class="text-lg">{{ data.other_services.integra }}</div>
                        <div class="text-sm">integra</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>