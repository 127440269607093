/* tslint:disable:variable-name */
import {BaseModel} from './base-model';

export const ROLE_MASTERAGENT = 'masteragent';
export const ROLE_SUBMASTER = 'submaster';
export const ROLE_MASTER = 'master';
export const ROLE_STAND = 'stand';
export const ROLE_DEALER = 'dealer';
export const ROLE_OPERATOR = 'operator';
export const ROLE_VENDITORE = 'venditore';
export const ROLE_AGENT = 'agent';
export const ROLE_DEMO = 'demo';

export class User {
    token: string;
    loggedIn = false;
    profile: Profile = new Profile();

    deserialize(input: any): this {
        if (input) {
            this.profile = new Profile().deserialize(input);
        }
        return this;
    }
}

export class Profile {
    id: any;
    operatori: [string];
    nome: string;
    cognome: string;
    username: string;
    ragione_sociale: string;
    inside_pic: string;
    inside_pic_2: string;
    outside_pic: string;
    position: string;
    partita_iva: string;
    email: string;
    telefono: string;
    cellulare: string;
    iban: string;
    codice_sdi: string;
    pec: string;
    causale_ritenuta: string;
    regime_fiscale: string;
    tipo_ritenuta: string;
    tipologia_societa: string;
    consenso_fatture: string;
    codice_kena: string;
    codice_tiscali: string;
    codice_eni: string;
    codice_sky: string;
    codice_tim: string;
    plafond_tiscali: number;
    plafond_kena: number;
    sim_kena: number;
    sim_tiscali: number;
    credito: number;
    e_shop_credit: number;
    punti: number;
    sede_comune: string;
    sede_provincia: string;
    sede_indirizzo: string;
    sede_cap: string;
    sede_civico: string;
    id_indirizzo_spedizione: number = null;
    spedizione_comune: string = null;
    spedizione_provincia: string = null;
    spedizione_indirizzo: string = null;
    spedizione_cap: string = null;
    spedizione_civico: string = null;
    codfiscale: string;
    role: string;
    abilitato_sms: boolean;
    sms_disponibili: number;
    stato_fi: number;
    plafond_tim: number;
    is_psw_expired: boolean;
    company_prefix: string;

    get pic() {
        if (!this.inside_pic) {
            return '/assets/img/flats/online-store.png';
        }

        return this.inside_pic;
    }

    get isStand(): boolean {
        return this.role === ROLE_STAND;
    }

    get isDealer(): boolean {
        return this.role === ROLE_DEALER;
    }

    get isVenditore(): boolean {
        return this.role === ROLE_VENDITORE;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export class Person extends BaseModel {
    id: string;
    fist_name: string;
    last_name: string;
    username?: string;
    email?: string;
}
