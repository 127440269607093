<div class="modal-body">
    <form (ngSubmit)="save()" [formGroup]="amexForm">
        <div class="col-md-12 col-sm-12 mt-2 mb-0">
            <div class="form-group mb-0">
                <label class="lead">Report AMEX</label>
                <div class="custom-file">
                    <input
                            (change)="onFileSelected($event)"
                            class="custom-file-input cursor-hand"
                            formControlName="fileInput"
                            id="validatedCustomFile"
                            type="file"
                    />
                    <label *ngIf="file" class="custom-file-label text-green">{{ file.name }}</label>
                    <label
                            *ngIf="!file"
                            class="custom-file-label"
                            for="validatedCustomFile"
                    >Scegli il file</label
                    >
                </div>
                <val-errors controlName="file">
                    <ng-template valError="required">
                        Il file è obbligatorio
                    </ng-template>
                </val-errors>
            </div>

            <div class="alert alert-info mt-2">
                <p>Verificare che le colonne siano nominate come segue:</p>
                <small>usate per fare il match delle righe</small>
                <ul>
                    <li>Referral Email</li>
                    <li>Unique Company ID</li>
                </ul>
                <small>usate per aggiornare le righe</small>
                <ul>
                    <li>Lead Status</li>
                    <li>Lead Sub-Status</li>
                    <li>Opportunity Name</li>
                    <li>Stage</li>
                    <li>Sub-Product</li>
                </ul>
            </div>

            <button [disabled]="loadingExport === true" class="btn btn-info btn-block mb-2" type="submit">
                <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
            </button>
        </div>
    </form>
</div>
