<app-master-header
    [ngBusy]="busy"
    cardClass="bg-gradient-info"
    image="assets/img/flats/destination.png"
    title="Gestione Visite"
>
    <div class="row align-items-end">
        <div class="col-lg-4 col-12">
            <label translate>Seleziona periodo</label>
            <input
                (selected)="setSelectedDateRange($event.start, $event.end)"
                [(ngModel)]="filterForm.dateRange"
                class="form-control"
                daterangepicker
                type="text"
            />
        </div>
    </div>
</app-master-header>

<div class="card">
    <div class="card-body">
        <div *ngIf="global" class="row">
            <div *ngIf="defaultView" class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/online-store.png" />
                    <span class="title">{{ global.today_visits }}</span>
                    <span class="description"> Visite di oggi </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/calendar_2.png" />
                    <span class="title">{{ global.period_visits }}</span>
                    <span class="description"> Visite nel periodo </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/percentage.png" />
                    <span class="title">{{ global.average_period_visits }}</span>
                    <span class="description"> Media visite </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/settings_2.png" />
                    <span class="title">{{ global.period_our_retailers }}</span>
                    <span class="description"> Visite uniche</span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/new.png" />
                    <span class="title">{{ global.period_other_retailers }}</span>
                    <span class="description"> Visite uniche altri pdv </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/internet.png" />
                    <span class="title">{{ global.total_shops }}</span>
                    <span class="description" translate> Negozi totali </span>
                </div>
            </div>
        </div>

        <div *ngIf="global" class="row mt-4 d-lg-flex d-none">
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/map.png" />
                    <span class="title">{{ global.period_cities }}</span>
                    <span class="description"> Comuni visitati </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/placeholder.png" />
                    <span class="title">{{ global.cities_with_shops }}</span>
                    <span class="description" translate> Comuni con negozi</span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/global-network.png" />
                    <span class="title">{{ global.covered_cities }}</span>
                    <span class="description">Comuni totali</span>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #visitFormModal class="bg-white" let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Nuova Visita</h4>
        <button (click)="handleClose(modal)" aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-spinner *ngIf="!visitFormReady" status="loading"></app-spinner>
        <form *ngIf="visitFormReady" [formGroup]="visitForm">
            <div *ngIf="visitFormMode !== 'unknown'" class="form-group">
                <label>Attività chiusa</label>
                <ng-select class="form-control-sm" formControlName="closed_activity">
                    <ng-option value="No">No</ng-option>
                    <ng-option value="Sì">Sì</ng-option>
                </ng-select>
            </div>

            <ng-container *ngIf="visitFormDataReady">
                <ng-container *ngIf="visitFormMode !== 'unknown'">
                    <div
                        class="text-lg link cursor-hand"
                        (click)="dealerAdditionalInfoCollapse = !dealerAdditionalInfoCollapse"
                    >
                        Anagrafica Dealer
                        <i class="fas fa-exclamation-circle text-danger" *ngIf="!this.dealerAdditionError"></i>
                        <i
                            class="fas float-right link-black pt-2"
                            [ngClass]="{
                                'fa-angle-down': dealerAdditionalInfoCollapse,
                                'fa-angle-up': !dealerAdditionalInfoCollapse
                            }"
                        ></i>
                    </div>
                    <div class="bg-light border rounded-lg p-2" [ngbCollapse]="dealerAdditionalInfoCollapse">
                        <div class="row" formGroupName="dealerAdditional">
                            <div *ngIf="showControl" class="form-group col-12 col-md-6">
                                <label>Nome</label>
                                <input class="form-control form-control-sm" formControlName="name" type="text" />
                            </div>
                            <div *ngIf="showControl" class="form-group col-12 col-md-6">
                                <label>Cognome</label>
                                <input class="form-control form-control-sm" formControlName="surname" type="text" />
                            </div>
                            <div *ngIf="showControl" class="form-group col-12 col-md-4">
                                <label>Telefono</label>
                                <input class="form-control form-control-sm" formControlName="phone" type="text" />
                            </div>
                            <div *ngIf="showControl" class="form-group col-12 col-md-4">
                                <label>Cellulare</label>
                                <input class="form-control form-control-sm" formControlName="mobile" type="text" />
                            </div>
                            <div *ngIf="showControl" class="form-group col-12 col-md-4">
                                <label>Email</label>
                                <input class="form-control form-control-sm" formControlName="email" type="text" />
                            </div>
                            <div *ngIf="showControl" class="form-group col-12">
                                <label>Posizione</label>
                                <app-autocomplete-address
                                    (setAddress)="setAddress($event)"
                                    [small]="true"
                                ></app-autocomplete-address>
                                <div>
                                    <span class="text-sm text-muted mr-1">Indirizzo:</span>
                                    <span>{{ visitForm.controls.address.value }}</span>
                                    <span class="text-sm text-muted mx-1">Civico:</span>
                                    <span>{{ visitForm.controls.address_number.value }}</span>
                                    <span class="text-sm text-muted mx-1">Comune:</span>
                                    <span>{{ visitForm.controls.city_name.value }}</span>
                                    <span class="text-sm text-muted mx-1">CAP:</span>
                                    <span>{{ visitForm.controls.cap.value }}</span>
                                </div>
                            </div>
                            <div class="form-group col-12">
                                <label>Giorni di chiusura</label>
                                <ng-select [multiple]="true" class="form-control-sm" formControlName="closing_days">
                                    <ng-option value="Lunedì">Lunedì</ng-option>
                                    <ng-option value="Martedì">Martedì</ng-option>
                                    <ng-option value="Mercoledì">Mercoledì</ng-option>
                                    <ng-option value="Giovedì">Giovedì</ng-option>
                                    <ng-option value="Venerdì">Venerdì</ng-option>
                                    <ng-option value="Sabato">Sabato</ng-option>
                                    <ng-option value="Domenica">Domenica</ng-option>
                                    <ng-option value="No chiusura">No chiusura</ng-option>
                                </ng-select>
                                <val-errors controlName="closing_days"></val-errors>
                            </div>
                            <div class="form-group col-6">
                                <label>Operatori</label>
                                <ng-select
                                    [addTag]="true"
                                    [multiple]="true"
                                    addTagText="Aggiungi altro operatore"
                                    class="form-control-sm"
                                    formControlName="brands"
                                >
                                    <ng-option value="Vodafone">Vodafone</ng-option>
                                    <ng-option value="WindTre">WindTre</ng-option>
                                    <ng-option value="Tim">Tim</ng-option>
                                    <ng-option value="ILIAD">ILIAD</ng-option>
                                    <ng-option value="Tiscali">Tiscali</ng-option>
                                    <ng-option value="Fastweb">Fastweb</ng-option>
                                    <ng-option value="Linkem">Linkem</ng-option>
                                    <ng-option value="Sky WiFi">Sky WiFi</ng-option>
                                </ng-select>
                                <val-errors controlName="brands"></val-errors>
                            </div>
                            <div class="form-group col-6">
                                <label>Materiale esposto</label>
                                <ng-select [multiple]="true" class="form-control-sm" formControlName="advertising">
                                    <ng-option value="Espositore da banco">Espositore da banco</ng-option>
                                    <ng-option value="Bandiera a Goccia">Bandiera a Goccia</ng-option>
                                    <ng-option value="Vetrofania Porta">Vetrofania Porta</ng-option>
                                    <ng-option value="Locandina">Locandina</ng-option>
                                    <ng-option value="Planslip">Planslip</ng-option>
                                    <ng-option value="No Materiale">No Materiale</ng-option>
                                </ng-select>
                                <val-errors controlName="advertising"></val-errors>
                            </div>
                        </div>
                        <div class="row" formGroupName="dealerAdditionalJson" *ngIf="kenaShowControl">
                            <div class="col-12">
                                <div class="form-group">
                                    <label translate>Tipologia Dealer</label>
                                    <ng-select formControlName="dealer_type" [clearable]="true" class="form-control-sm">
                                        <ng-option value="ETNICO">Etnico</ng-option>
                                        <ng-option value="SPECIALISTICO">Specialistico</ng-option>
                                        <ng-option value="GENERICO">Generico</ng-option>
                                        <ng-option value="GRANDE_DISTRIBUZIONE">Grande Distribuzione</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <label>Ha satispay al negozio?</label>
                                <ng-select class="form-control-sm" formControlName="satispay">
                                    <ng-option value="Si">Si</ng-option>
                                    <ng-option value="No">No</ng-option>
                                </ng-select>
                                <val-errors controlName="satispay"></val-errors>
                            </div>
                            <div class="form-group col-6">
                                <label>Ha amex al negozio?</label>
                                <ng-select class="form-control-sm" formControlName="amex">
                                    <ng-option value="Si">Si</ng-option>
                                    <ng-option value="No">No</ng-option>
                                    <ng-option value="No">Si, già l'aveva</ng-option>
                                </ng-select>
                                <val-errors controlName="amex"></val-errors>
                            </div>
                            <div class="form-group col-6">
                                <label>Qual è il brand principale con cui lavora?</label>
                                <ng-select
                                    [addTag]="true"
                                    addTagText="Aggiungi altro operatore"
                                    class="form-control-sm"
                                    formControlName="main_brand"
                                >
                                    <ng-option value="Vodafone">Vodafone</ng-option>
                                    <ng-option value="WindTre">WindTre</ng-option>
                                    <ng-option value="Tim">Tim</ng-option>
                                    <ng-option value="ILIAD">ILIAD</ng-option>
                                    <ng-option value="Tiscali">Tiscali</ng-option>
                                    <ng-option value="Fastweb">Fastweb</ng-option>
                                    <ng-option value="Linkem">Linkem</ng-option>
                                    <ng-option value="Sky WiFi">Sky WiFi</ng-option>
                                    <ng-option value="very">Very</ng-option>
                                </ng-select>
                                <val-errors controlName="main_brand"></val-errors>
                            </div>
                            <div class="form-group col-6">
                                <label>Quante sim fa con il brand principale?</label>
                                <input
                                    type="number"
                                    formControlName="main_bran_qty"
                                    class="form-control form-control-sm w-100"
                                />
                                <val-errors controlName="main_bran_qty"></val-errors>
                            </div>
                            <div class="form-group col-6">
                                <label>Quanti telefoni vende al mese?</label>
                                <ng-select class="form-control-sm" formControlName="phone_qty">
                                    <ng-option value="0">0</ng-option>
                                    <ng-option value="< 20">< 20</ng-option>
                                    <ng-option value="20-30">20 - 30</ng-option>
                                    <ng-option value="> 30">> 30</ng-option>
                                </ng-select>
                                <val-errors controlName="phone_qty"></val-errors>
                            </div>
                            <div class="form-group col-6">
                                <label>Quanti telefoni rateizzati vende al mese?</label>
                                <ng-select class="form-control-sm" formControlName="phone_fnc_qty">
                                    <ng-option value="0>">0</ng-option>
                                    <ng-option value="5>">5</ng-option>
                                    <ng-option value="10">10</ng-option>
                                    <ng-option value="15">15</ng-option>
                                    <ng-option value="20">+20</ng-option>
                                </ng-select>
                                <val-errors controlName="phone_fnc_qty"></val-errors>
                            </div>
                            <div class="form-group col-12">
                                <label>Che tipologie di telefoni vende?</label>
                                <ng-select [multiple]="true" class="form-control-sm" formControlName="phone_types">
                                    <ng-option value="Nessuno">Nessuno</ng-option>
                                    <ng-option value="Rigenerati">Rigenerati</ng-option>
                                    <ng-option value="Nuovi">Nuovi</ng-option>
                                    <ng-option value="Vincolati">Vincolati</ng-option>
                                </ng-select>
                                <val-errors controlName="phone_types"></val-errors>
                            </div>
                            <div class="form-group col-12">
                                <label>Ha la polizza al negozio?</label>
                                <ng-select class="form-control-sm" formControlName="has_insurance">
                                    <ng-option value="Si">Si</ng-option>
                                    <ng-option value="No">No</ng-option>
                                    <ng-option value="No">Si, già l'aveva</ng-option>
                                </ng-select>
                                <val-errors controlName="has_insurance"></val-errors>
                            </div>
                            <div class="form-group col-12">
                                <label
                                    >Sai che da oggi può diventare segnalatore assicurativo (Zurich e Prima) ed ottenere
                                    un gettone ricorrente sui premi assicurativi?</label
                                >
                                <ng-select [multiple]="true" class="form-control-sm" formControlName="insurance_info">
                                    <ng-option value="Si">Si</ng-option>
                                    <ng-option value="No">No</ng-option>
                                </ng-select>
                                <val-errors controlName="insurance_info"></val-errors>
                            </div>
                            <div class="form-group col-12">
                                <label>Qual è la produzione business che fa in media?</label>
                                <input
                                    type="number"
                                    formControlName="business_qty"
                                    class="form-control form-control-sm w-100"
                                />
                                <val-errors controlName="business_qty"></val-errors>
                            </div>
                        </div>
                    </div>
                    <hr />
                </ng-container>
                <ng-container *ngIf="visitFormMode === 'unknown'">
                    <div class="text-lg">Negozio</div>
                    <div class="bg-light border rounded-lg p-2">
                        <div class="row">
                            <div class="form-group col-12 col-md-6">
                                <label>Ragione Sociale</label>
                                <input class="form-control form-control-sm" formControlName="shop" type="text" />
                            </div>
                            <div class="form-group col-12">
                                <label>Posizione</label>
                                <app-autocomplete-address
                                    (setAddress)="setAddress($event)"
                                    [small]="true"
                                ></app-autocomplete-address>
                                <div>
                                    <span class="text-sm text-muted mr-1">Indirizzo:</span>
                                    <span>{{ visitForm.controls.address.value }}</span>
                                    <span class="text-sm text-muted mx-1">Civico:</span>
                                    <span>{{ visitForm.controls.address_number.value }}</span>
                                    <span class="text-sm text-muted mx-1">Comune:</span>
                                    <span>{{ visitForm.controls.city_name.value }}</span>
                                    <span class="text-sm text-muted mx-1">CAP:</span>
                                    <span>{{ visitForm.controls.cap.value }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="priorities.length > 0">
                    <div class="text-lg mb-2">Priorità</div>
                    <div class="d-flex flex-row justify-content-between mb-2">
                        <div *ngIf="priorities.includes('Amex')">
                            <img
                                height="24px"
                                src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/amex/logo_header_amex.svg"
                                alt=""
                            />
                            Priorità AMEX
                        </div>
                        <div *ngIf="priorities.includes('Satispay')">
                            <img
                                height="24px"
                                src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/satispay/satispay.svg"
                                alt=""
                            />
                            Priorità Satispay
                        </div>
                        <div *ngIf="priorities.includes('Indabox')">
                            <img
                                height="24px"
                                src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/indabox/indabox.png"
                                alt=""
                            />
                            Priorità Indabox
                        </div>
                    </div>
                    <hr />
                </ng-container>

                <div formGroupName="additional">
                    <ng-container *ngIf="!visitFormInitialData.has_whatsapp">
                        <div role="alert" class="alert alert-danger d-flex justify-content-between align-items-center">
                            <div
                                class="rounded-circle bg-gradient-white d-flex justify-content-center items-center"
                                style="height: 35px; width: 35px"
                            >
                                <i
                                    class="fab fa-whatsapp text-success mb-0 font-weight-bold display-7 d-flex justify-content-center align-items-center"
                                ></i>
                            </div>
                            <p>Il dealer non ci ha ancora scritto su Whatsapp!</p>
                            <div
                                class="rounded-circle bg-gradient-white d-flex justify-content-center items-center"
                                style="height: 35px; width: 35px"
                            >
                                <i
                                    class="fab fa-whatsapp text-success mb-0 font-weight-bold display-7 d-flex justify-content-center align-items-center"
                                ></i>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let item of visitFormStructure">
                        <div class="form-group">
                            <label>{{ item.label }}</label>
                            <ng-container [ngSwitch]="item.type">
                                <ng-container *ngSwitchCase="'textarea'">
                                    <textarea
                                        [formControlName]="item.name"
                                        class="form-control w-100"
                                        rows="3"
                                    ></textarea>
                                    <val-errors [controlName]="item.name"></val-errors>
                                </ng-container>
                                <ng-container *ngSwitchCase="'text'">
                                    <input
                                        type="text"
                                        [formControlName]="item.name"
                                        class="form-control form-control-sm w-100"
                                    />
                                    <val-errors [controlName]="item.name"></val-errors>
                                </ng-container>
                                <ng-container *ngSwitchCase="'number'">
                                    <input
                                        type="number"
                                        [formControlName]="item.name"
                                        class="form-control form-control-sm w-100"
                                    />
                                    <val-errors [controlName]="item.name"></val-errors>
                                </ng-container>
                                <ng-container *ngSwitchCase="'select'">
                                    <ng-select [formControlName]="item.name" class="form-control-sm">
                                        <ng-option *ngFor="let option of item.options" [value]="option.value">{{
                                            option.label
                                        }}</ng-option>
                                    </ng-select>
                                </ng-container>
                                <ng-container *ngSwitchCase="'multiselect'">
                                    <ng-select [formControlName]="item.name" [multiple]="true" class="form-control-sm">
                                        <ng-option *ngFor="let option of item.options" [value]="option.value">{{
                                            option.label
                                        }}</ng-option>
                                    </ng-select>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <div class="text-lg">Foto</div>
                <div class="bg-light border rounded-lg p-2">
                    <div *ngFor="let document of this.visitDocuments; let i = index" class="mt-2">
                        <div [formGroup]="document">
                            <div class="form-group">
                                <label translate
                                    >File (<span
                                        (click)="removeDocument(visitForm, i)"
                                        class="cursor-hand text-sm text-danger link"
                                        >Rimuovi</span
                                    >)</label
                                >
                                <div *ngIf="!document?.get('file_name')?.value" class="custom-file">
                                    <input
                                        (change)="handleFileInput($event?.target?.files[0], document)"
                                        class="custom-file-input"
                                        type="file"
                                    />
                                    <label class="custom-file-label" for="file"> Carica file... </label>
                                </div>
                                <div *ngIf="document?.get('file_name').value">
                                    <span class="text-break">{{ document?.get('file_name')?.value }}</span>
                                    <button
                                        (click)="handleFileRemove(document)"
                                        class="btn btn-xs btn-warning float-right"
                                        type="button"
                                    >
                                        <i class="fa fa-times m-0 px-1"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button (click)="addDocument(visitForm)" class="btn btn-info btn-sm" type="button">
                        <i class="fa fa-plus"></i> Aggiungi
                    </button>
                </div>
            </ng-container>

            <div class="mt-3">
                <button
                    (click)="saveVisit()"
                    *ngIf="!saving"
                    [disabled]="!this.visitForm.valid"
                    class="btn btn-success btn-block"
                >
                    <i class="fa fa-check-circle mr-2"></i> Registra Visita
                </button>
                <button *ngIf="saving" [disabled]="true" class="btn btn-info btn-block">
                    Sto salvando la visita...
                </button>
            </div>
        </form>
    </div>
</ng-template>

<div class="row">
    <div class="col-12 col-xl-8">
        <div *ngIf="visiteProgrammate && visiteProgrammate.rows.length === 0" class="alert alert-info">
            <i class="fas fa-info-circle mr-2"></i>
            Non ci sono visite programmate per oggi. Se le vuoi aggiungere vai al
            <a [routerLink]="['/visite/calendario']">calendario</a>.
        </div>

        <div *ngIf="visiteProgrammate && visiteProgrammate.rows.length > 0" class="mb-3">
            <app-table
                (onTrigger)="triggerProgrammata($event)"
                [columns]="visiteProgrammate.columns"
                [hideFooter]="true"
                [rows]="visiteProgrammate.rows"
                [small]="true"
                title="Visite programmate per oggi"
            >
            </app-table>
        </div>

        <app-master-card bodyClass="p-0" image="assets/img/flats/map.png" title="Lista Dealer">
            <app-table
                (onTrigger)="trigger($event)"
                [columns]="table.columns"
                [hideFooterDescription]="true"
                [rows]="table.rows"
                [small]="true"
            >
                <p class="mt-3 ml-3">
                    Hai visitato un negozio non ancora affiliato?
                    <button (click)="triggerCustom()" class="btn btn-sm btn-success ml-3">Segna la visita qui</button>
                </p>
                <hr />
            </app-table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <h1 class="display-6 mb-0 mb-2">Timeline</h1>
        <div *ngIf="timeline.length === 0">
            <div class="alert alert-info">
                <i class="fas fa-info-circle mr-2"></i>
                Nessuna visita registrata, prova a cambiare periodo.
            </div>
        </div>
        <div class="timeline" style="max-height: 700px; overflow-y: scroll">
            <ng-container *ngFor="let visits of timeline">
                <div class="time-label lead">
                    <span class="bg-gradient-success">{{ visits.periodo }}</span>
                </div>
                <div *ngFor="let item of visits.visite">
                    <i class="fas fa-sim-card bg-gradient-info"></i>
                    <div class="timeline-item bg-gradient-light elevation-1">
                        <span class="time"><i class="far fa-clock mr-2"></i>{{ item.ora }}</span>
                        <h3 class="timeline-header">
                            <a *ngIf="item.id_dealer" [routerLink]="['/dealer', item.id_dealer]">{{ item.negozio }}</a>
                            <span *ngIf="!item.id_dealer">{{ item.negozio }}</span>
                        </h3>
                        <div class="timeline-body">
                            <p class="lead font-italic"></p>

                            <div [innerHTML]="item.note_visita | safeHtml"></div>

                            <div
                                *ngFor="let item of item.json_data | keyvalue"
                                class="bg-gradient-white rounded p-2 mb-2 border"
                            >
                                <div class="text-md font-weight-bold">{{ item.key }}</div>
                                <div>{{ item.value }}</div>
                            </div>

                            <div
                                *ngIf="item.json_data === null || (item.json_data && item.json_data.length === 0)"
                                class="text-muted font-italic"
                            >
                                Non ci sono note
                            </div>

                            <p *ngIf="item.affiliato === '1'" class="mt-2">
                                <i class="fas fa-check-circle text-success"></i> Affiliato
                            </p>
                            <p *ngIf="item.attivita_chiusa === '1'" class="mt-2">
                                <i class="fas fa-check-circle text-danger"></i> Attivita chiusa
                            </p>

                            <ng-container *ngIf="false">
                                <hr />
                                <ul>
                                    <li>Plafond segnalato: {{ item?.actual_plafond | currency : '€ ' }}</li>
                                    <li>Sim in magazzino: {{ item?.warehouse_sim }}</li>
                                </ul>
                            </ng-container>

                            <ng-container *ngIf="item?.documents.length > 0">
                                <hr />
                                <p class="text-italic" translate>Foto del negozio:</p>
                                <ul>
                                    <li *ngFor="let document of item?.documents; let i = index">
                                        <app-documents-modal #showModal [image]="true"></app-documents-modal>
                                        <div
                                            (click)="
                                                showModal.showDocument(
                                                    baseAwsUrl +
                                                        '/visite/get-picture?visit_id=' +
                                                        item.id +
                                                        '&id=' +
                                                        document.id,
                                                    true
                                                )
                                            "
                                            class="link"
                                        >
                                            Foto {{ i + 1 }}
                                        </div>
                                    </li>
                                </ul>
                            </ng-container>

                            <ng-container *ngFor="let reply of item.replies">
                                <hr />
                                <div [ngClass]="{ 'text-right': reply.direction === 'right' }">
                                    <p class="text-muted text-md font-italic">
                                        {{ reply.author_name
                                        }}<span class="float-right text-sm">{{
                                            reply.created_at | amFromUnix | amDateFormat : 'DD-MM-YYYY H:mm'
                                        }}</span>
                                    </p>
                                    <p>{{ reply.note }}</p>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="item.showReply">
                                <hr />
                                <textarea [(ngModel)]="item.reply" class="form-control"></textarea>
                                <button (click)="sendReply(item)" class="btn btn-sm btn-success mt-2">Invia</button>
                            </ng-container>
                        </div>
                        <div class="timeline-footer">
                            <em>{{ item.citta }}, {{ item.provincia }}</em>

                            <a (click)="item.showReply = !item.showReply" class="float-right font-italic link"
                                ><i class="fa fa-reply"></i> Rispondi</a
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
