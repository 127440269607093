<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadOrders()">
        <div class="form-row">
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                                            id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date Range</label>
                    <input id="documentDate" type="text" daterangepicker [options]="pickerOptions"
                           [(ngModel)]="filter.date" name="date" class="form-control"
                           (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Status</label>
                    <ng-select [clearable]="true" class="disableClear" [(ngModel)]="filter.status"
                               [items]="status" bindLabel="lable"
                               name="subscribed" id="subscribed"
                               bindValue="key" [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Transaction ID</label>
                    <input class="disableClear form-control" [(ngModel)]="filter.transaction_id"
                           name="subscribed"
                    />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Service</label>
                    <input class="disableClear form-control" [(ngModel)]="filter.service"
                           name="subscribed"
                    />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block form-control" translate type="submit">Filter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>Certificati</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="table-orders"
                [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate *show="['master','operator']">Company name</th>
                <th translate>Transaction ID</th>
                <th translate class="text-right">Total Price</th>
                <th translate>Date</th>
                <th translate>Service</th>
                <th translate class="text-right">Status</th>
                <th></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let order of orders">
                <td *show="['master','operator']">
                    <a [routerLink]="['/dealer', order.dealer_id]" target="_blank">
                        {{order.dealer['company_name']}}</a>
                </td>
                <td>
                    {{order.transaction_id}}
                </td>
                <td class="text-right">
                    {{order.total_price|currency:currency}}
                </td>
                <td>
                    {{order.created_at | date:'dd/MM/yyyy HH:MM:ss'}}
                </td>
                <td>
                    {{order.service}}
                </td>
                <td class="text-right p-2 pb-3 text-capitalize">
                       <span class="badge badge-success"
                             *ngIf="order.status==statuses[0].key" translate>{{statuses[0].label}}</span>
                    <span class="badge badge-danger" *ngIf="order.status==statuses[1].key"
                          translate>{{statuses[1].label}}</span>
                    <span class="badge badge-info" *ngIf="order.status==statuses[2].key"
                          translate>{{statuses[2].label}}</span>
                </td>
                <td class="text-right">
                    <button class="btn btn-link" (click)="showDetailModal(order)">
                        <i class=" fa fa-eye text-info"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="orders?.length<=0">
                <td class="text-muted" colspan="10" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="orders?.length>0">
    <span><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
        {{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}.
        <a class="link"
        >Esporta la lista completa</a
        >
    </span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
                        size="sm"
                        [pageSize]="ordersPagination.perPage"
                        [maxSize]="10"
                        [(page)]="ordersPagination.currentPage"
                        [collectionSize]="ordersPagination.totalCount"
                        (pageChange)="loadOrders()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #detailTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedData?.dealer['company_name']}}
            - {{selectedData?.transaction_id}}</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-group list-group-flush">
            <li class="list-group-item px-0">
                <strong translate>Name</strong>
                <span class="float-right">{{selectedData?.items[0]?.name}}</span></li>

        </ul>
    </div>
</ng-template>
