import { BaseModel } from "./base-model";
import { Company } from "./company";
import { Person } from "./user";
import {Dealer} from './dealer';
import {MetaPagination} from '@models/meta-pagination';

export class AffiliationList extends BaseModel {

    in_revision: AffiliationListPagination;
    archived: AffiliationListPagination;

    get childModels() {
        return {
            in_revision: AffiliationListPagination,
            archived: AffiliationListPagination,
        }
    }

    fromArray(result: []) {
        const array: AffiliationList[] = [];
        for (const item of result) {
            array.push(new AffiliationList(item));
        }
        return array;
    }
}

export class AffiliationListPagination extends BaseModel {
    dataModel: any;

    data: Array<any>;
    _links: Array<any>;
    _meta: MetaPagination;

    get childModels() {
        return {
            data: Affiliation
        }
    }

    fromArray(result: []) {
        const array: AffiliationListPagination[] = [];
        for (const item of result) {
            array.push(new AffiliationListPagination(item));
        }
        return array;
    }
}

export class Affiliation extends BaseModel {
    id: number;
    agent_id: number;
    dealer_id: number;
    operator_id: number;
    agent?: Person;
    dealer?: Dealer;
    operator?: Company;
    status: number;
    note: string;
    created_at: string;
    submitted_documents: SubmittedDocument[];
    required_documents: RequiredDocument[];

    public static affilationStatus = [
        {
            label: 'In attesa',
            key: 0
        },
        {
            label: 'Gestito',
            key: 1
        },
        {
            label: 'Cancellato',
            key: 2
        },
        {
            label: 'KO Geo',
            key: 3
        },
        {
            label: 'KO Ateco',
            key: 4
        },
        {
            label: 'KO Black list',
            key: 5
        },
        {
            label: 'KO Escluso',
            key: 6
        },
        {
            label: 'KO Ripensamento',
            key: 7
        },
        {
            label: 'Aggiornare documentazione',
            key: 8
        },
        {
            label: 'Affiliato',
            key: 9
        }
    ];

    static affiliationStatusLabel = {
        0 : 'In attesa',
        1 : 'Gestito',
        2 : 'Cancellato',
        3 : 'KO Geo',
        4 : 'KO Ateco',
        5 : 'KO Black list',
        6 : 'KO Escluso',
        7 : 'KO Ripensamento',
        8 : 'Aggiornare documentazione',
        9 : 'Affiliato'
    };

    get affiliationStatusLabel(){
        return  Affiliation.affiliationStatusLabel[this.status]
    };

    get childModels() {
        return {
            submitted_documents: SubmittedDocument,
            required_documents: RequiredDocument,
        }
    }

    fromArray(result: []) {
        const array: Affiliation[] = [];
        for (const item of result) {
            array.push(new Affiliation(item));
        }
        return array;
    }

    get postPayload() {

        let i = 0;
        let payload = new FormData();
        this.addToPayload(payload, this.dealer_id, 'dealer_id');
        this.addToPayload(payload, this.operator_id, 'operator_id');

        for (let document of this.submitted_documents) {
            this.addToPayload(payload, {
                requirement_id: document.requirement_id || null,
                uploaded_in_id: document.uploaded_in_id || null,
                file: document.file || null,
            }, `submitted_documents[${i++}]`);
        }

        return payload;
    }
}

export class SubmittedDocument extends BaseModel {
    id: number;
    link: string;
    file_name: string;
    requirement_id: number;
    uploaded_in_id: number;
    created_at: string;

    file: File;

    fromArray(result: []) {
        const array: SubmittedDocument[] = [];
        for (const item of result) {
            array.push(new SubmittedDocument(item));
        }
        return array;
    }
}

export class RequiredDocument extends BaseModel {
    id: number;
    name: string;
    description: string;
    link: string;
    created_at: string;

    file: File;
    fileName: string;
    uploadedInId: number;
    uploadedAsList: Array<any> = [];

    get hasFile() {
        return this.file || this.uploadedInId;
    }

    fromArray(result: []) {
        const array: RequiredDocument[] = [];
        for (const item of result) {
            array.push(new RequiredDocument(item));
        }
        return array;
    }
}

