<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadList()">
        <div class="form-row">
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                    id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">Dealer ID</label>
                    <input [(ngModel)]="filter.dealer_id"  name="dealer_id" class="form-control">
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date</label>
                    <input id="documentDate" autocomplete="off" type="text" daterangepicker [options]="pickerOptions"
                        [(ngModel)]="filter.date" name="date" class="form-control"
                        (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="">Transaction id</label>
                    <input [(ngModel)]="filter.transaction_id"  name="transaction_id" class="form-control">
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter</button>
                </div>
            </div>
        </div>
    </form>
</div>


<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>FLIXBUS</h4>
    </div>
    <div class="card-body p-0 rounted-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
        <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th translate>Company name</th>
                    <th translate>Transaction Id</th>
                    <th translate>Data</th>
                    <th translate>Status</th>
                </tr>
            </thead>
                <tbody class="bg-gradiend-white">
                <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
                >
                <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <tr *ngIf="flixbusList.length==0">
                    <td translate colspan="4" class="text-center">List is empty</td>
                </tr>
                <ng-container *ngFor="let result of flixbusList">
                    <tr>
                        <td>
                            {{result.company_name}}
                            <!-- <a [routerLink]="['/dealer', result.dealer_id]" target="_blank">{{result.company_name}}</a> -->
                        </td>
                        <td>
                            {{result?.transaction_id}}
                        </td>
                        <td>
                            {{result?.created_at | amDateFormat: 'DD/MM/YYYY - HH:mm' }}
                        </td>
                        <td>
                            <span class="badge badge-success "
                                  *ngIf="result.status==statusCustom[0].key" translate>
                                  <p>
                                    {{statusCustom[0].label}}
                                  </p>
                            </span>
                            <span class="badge badge-info " *ngIf="result.status==statusCustom[1].key"
                                translate>
                                <p>
                                    {{statusCustom[1].label}}
                                </p>
                            </span>
                      </td>
                    </tr>
                </ng-container>
            </tbody>    
        </table>
    </div>
    <div class="card-footer">
        <span><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b>{{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}.<a class="link" (click)="exportFlixbusList()"> Esporta la lista completa</a></span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadList()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>

