import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import * as moment from 'moment';
import { Moment } from 'moment';
import { EPayService } from '@services/e-pay.service';
import { AmazonCounter, AmazonCounterProvider, Shipping } from '@models/kd-orders';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { AmazonCounterService } from './amazon-counter.service';
import { DaySchedule } from './amazon-interfaces.type';

@Component({
  selector: 'app-amazon-counter',
  templateUrl: './amazon-counter.component.html',
})
export class AmazonCounterComponent implements OnInit {
  orders: AmazonCounter[] = [];
  //selectedOrder: ShopOrder;
  ordersPagination: MetaPagination;

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  statuses = [
    {
      label: 'Accettata',
      key: 'accepted',
    },
    {
      label: 'In revisione',
      key: 'pending',
    },
    {
      label: 'Annullato',
      key: 'canceled',
    },
  ];

  selectedData: AmazonCounter | any;
  filter = {
    dealer_id: '',
    date: '',
    status: '',
    company_name: '',
  };
  apiDate = {
    from: '',
    to: '',
  };
  localFormat = 'dd/MM/yyyy HH:MM';
  currency = EPayService.currency;
  @ViewChild('detailTemplate') detailTemplate;

  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };

  constructor(
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    private dateRangePickerOptions: DaterangepickerConfig,
    public route: ActivatedRoute,
    private amazonCounterService: AmazonCounterService,
  ) {}

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadOrders();
  }

  loadOrders() {
    this.spinner.show('table-orders');
    const params = {};
    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.amazonCounterService.getAmazonCounterOrders(params).subscribe(
      (response: AmazonCounterProvider) => {
        this.orders = response.data;
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  openPopover(popOver: any, orderId, item, key, selectItems = []) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  showDetailModal(data?) {
    this.selectedData = data;
    this.modalService.open(this.detailTemplate, { size: 'lg', scrollable: true });
  }

  save(value) {
    const body = {};
    body[this.popupConfig['key']] = value;

    this.spinner.show('popupSpinner');

    this.amazonCounterService.putAmazonCounterOrder(this.popupConfig['id'], body).subscribe((response: AmazonCounter) => {
      this.popupConfig.popover.close();
      this.spinner.hide('popupSpinner');
      this.loadOrders();
    });
  }

  exportAmazonCounter() {
    this.spinner.show('table-orders');

    const params = {
      'per-page': 0,
    };

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }

    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.amazonCounterService.getAmazonCounterOrders(params).subscribe((response: AmazonCounterProvider) => {
      this.spinner.hide('table-orders');

      let rows = response.data;

      let output = rows.map((row) => {
        return {
          'Dealer ID': row.dealer_id,
          Contatto: row.company_name,
          'Partita IVA': row.vat,
          Nome: row.first_name,
          Cognome: row.last_name,
          Email: row.email,
          Telefono: row.phone,
          Cellulare: row.mobile,
          Città: row.city,
          Indirizzo: row.address,
          'Data Inserimento': row.created_at,
          Latitudine: row.lat,
          Longitudine: row.lng,
          Provincia: row.province,
          'Orari di Apertura': this.concatenate(row.opening_hours),
          Note: row.note,
          Stato: row.status,
        };
      });

      const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
      XLSX.writeFile(workBook, 'export-amazon-locker.xlsx');
    });
  }

  concatenate(json) {
    if (json) {
      const data: DaySchedule[] = json;
      let concatenatedString = '';

      data.forEach((day) => {
        if (day.shiftSchedules) {
          day.shiftSchedules.forEach((shift) => {
            concatenatedString += `${day.start !== undefined ? day.start : '?'}: ${shift.start !== undefined ? shift.start : '?'}-${shift.end !== undefined ? shift.end : '?'} | `;
          });
        }
      });
      return concatenatedString;
    }
  }
}
