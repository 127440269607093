import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

const version = 'v3';

@Injectable({
  providedIn: 'root',
})

export class ShopAffiliantionService {
  constructor(private request: RequestService, public alertService: AlertService) {}

  public getShopAffiliationList(params?): Observable<any> {
      return this.request.get(version + '/shop-list/list', params);
  }

  public updateListName(id, body: {}){
    return this.request.put(version + '/shop-list/update/' + id, body);
  }

  public getClassificaAm(params?): Observable<any> {
    return this.request.get(version + '/shop-list/classifica-am', params);
  }
  public getClassificaKa(params?): Observable<any> {
    return this.request.get(version + '/shop-list/classifica-ka', params);
  }

  public getShopSelect(): Observable<any> {
    return this.request.get(version + '/shop-list/shop-select');
  }

  public uploadFile(formData: FormData) {

      return this.request.post('v3/shop-list/import-list', formData);
  }

  // public exportReport(listId : number): Observable<any> {
  //   return this.request.get(version + `/shop-list/export-list?listId=${listId}`);
  // }

  public exportReport(params): Observable<any> {
    return this.request.get(version + '/shop-list/export-list/', params);
  }

  public exportMassiveReport(params): Observable<any> {
    return this.request.get(version + '/shop-list/export-massive-list/', params);
  }

  deleteListSubscription(id :number) {
    return this.request.delete(version +`/shop-list/delete/${id}`);
  }

  public getShopAffiliationListDetail(params): Observable<any> {
    return this.request.get(version + '/shop-list-detail/list-detail',  params);
  }

  public putOperatorStatus(id, body: {}){
    return this.request.put(version + '/shop-list-detail/update/' + id, body);
  }

  public postAddDealer(payload): Observable<any> {
    return this.request.post(version + '/shop-list-detail/add-dealer',  payload);
  }

  public postShopAffiliationListDetail(payload): Observable<any> {
    return this.request.post(version + '/shop-list-detail/list-assigned',  payload);
  }

  public postMassiveAssigned(payload): Observable<any> {
    return this.request.post(version + '/shop-list-detail/all-checked',  payload);
  }

  public postScheduleVisit(payload): Observable<any> {
    return this.request.post(version + '/shop-list-detail/schedule-visit',  payload);
  }

  public putAgentFeedback(id, body): Observable<any> {
    return this.request.put(version + `/shop-list-detail/update/${id}`, body);
  }

  public successAlert(text) {
    this.alertService.show('', 'File ' + text, { classname: 'bg-success text-light', delay: 2000 });
  }

  public errorAlert(message?) {
    this.alertService.show('Errore nel caricamento del file ', message ? message : 'Riprova di nuovo!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }

  public successAssignedAlert(text) {
    this.alertService.show('', 'Lista ' + text, { classname: 'bg-success text-light', delay: 2000 });
  }

  public errorAssignedAlert(message?) {
    this.alertService.show('Errore assegnazione della lista ', message ? message : 'Riprova di nuovo!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }

  public successVisitAlert(text) {
    this.alertService.show('', 'Visita ' + text, { classname: 'bg-success text-light', delay: 2000 });
  }

  public errorVisitAlert(message?) {
    this.alertService.show('Errore ', message ? message : 'Riprova di nuovo!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }

  public successFeedbackAlert(text) {
    this.alertService.show('', 'FeedBack ' + text, { classname: 'bg-success text-light', delay: 2000 });
  }

  public errorFeedbackAlert(message?) {
    this.alertService.show('Errore ', message ? message : 'Riprova di nuovo!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }


}  