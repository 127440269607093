import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {RequestService} from '@services/request.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-autoload-chartjs',
    templateUrl: './autoload-chartjs.component.html',
    styleUrls: ['./autoload-chartjs.component.css']
})
export class AutoloadChartjsComponent implements OnInit {

    @Input() brand: string = null;
    data: any = null;
    @Input()
    type = null;

    status = 'hidden';
    plugins = [
        ChartDataLabels
        // {
        //     beforeInit: function(chart, options) {
        //         chart.legend.afterFit = function() {
        //             this.height += 50; // must use `function` and not => because of `this`
        //         };
        //     }
        // }
    ];

    constructor(private requestService: RequestService, private spinner: NgxSpinnerService) {
    }

    _url: string;

    get url() {
        return this._url;
    }

    @Input()
    set url(value: string) {
        this._url = value;

        if (this._url) {
            this.refresh();
        }
    }

    get options() {
        if (this.data) {
            return this.data.options;
        }

        return [];
    };

    get datasets() {
        if (this.data) {
            return this.data.datasets;
        }

        return [];
    };

    get labels() {
        if (this.data) {
            return this.data.labels;
        }

        return [];
    };

    get legend() {
        if (this.data) {
            return this.data.legend;
        }

        return [];
    };

    get colors() {
        if (this.data) {
            return this.data.colors;
        }

        return [];
    };

    ngOnInit() {

    }

    public setUrl(value) {
        this.url = value;
    }

    public setBrand(value) {
        this.brand = value;
    }

    refresh() {
        this.status = 'loading';

        let requestUrl = 'dashboard' + this.url;

        if (this.brand !== null) {
            requestUrl = this.brand + this.url;
        }

        this.requestService.get(requestUrl)
            .subscribe((data) => {
                this.data = data.result;
                this.status = 'hidden';
            }, (error) => {
                this.status = 'error';
            });
    }
}
