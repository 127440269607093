<ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, .7)"
             color="#333333" name="popupSpinner"
             class="h-100 w-100"
             type="ball-clip-rotate-multiple"
             size="small">
</ngx-spinner>
<form class="align-items-center my-2 w-100  popup-container" (ngSubmit)="save()" [formGroup]="orderForm">

    <textarea type="text" id="input" class="pl-2 form-control" formControlName="value" rows="4" cols="50">
    </textarea>

    <button class="btn btn-info mr-1 mt-2" type="submit" [disabled]="orderForm.invalid">
        <i class="fa fa-check m-0"></i></button>
    <button class="btn btn-light mt-2" type="button" (click)="closePopover()"><i class="fa fa-times m-0"></i>
    </button>
</form>