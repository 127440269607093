import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SddService } from '@services/sdd.service';
import * as moment from 'moment';
import { DocumentSdd, Sdd } from '@app/models/sdd';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-sdd-modal',
    templateUrl: './sdd-modal.component.html',
    styleUrls: ['./sdd-modal.component.css'],
})
export class SddModalComponent implements OnInit {
    @Input() data: Sdd;
    @Output() refresh = new EventEmitter<boolean>();

    sddForm: FormGroup;

    file: File;
    busyDocumentFormModal: Subscription;
    documentFormModal: NgbModalRef;
    @ViewChild('documentFormTemplate') private documentFormTemplate;

    rangeOptions = {
        singleDatePicker: true,
        ranges: [],
    };

    apiDate = '';
    filter = {
        date: '',
    };

    busy: any;
    sddTypesStatus = Sdd.sddTypes;
    sddIbanTypeStatus = Sdd.ibanTypes;
    sddPaymentsTypeStatus = Sdd.paymentsTypes;

    constructor(
        public modalService: NgbModal,
        public formBuilder: FormBuilder,
        private dateRangePickerOptions: DaterangepickerConfig,
        private sddService: SddService,
    ) {}

    ngOnInit(): void {
        this.sddForm = this.formBuilder.group({
            dealer_id: [null, [Validators.required]],
            // ka_id: [null, [Validators.required]],
            iban: [
                null,
                [
                    Validators.required,
                    Validators.pattern(
                        /^IT[a-zA-Z0-9]{2}\s?([a-zA-Z]{1})([0-9]{3}\s?)([0-9]{4}\s?){1}([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{3})\s?/,
                    ),
                ],
            ],
            iban_type: [null, [Validators.required]],
            payments_type: [null, [Validators.required]],
            type: [null, [Validators.required]],
            signature_date: [null, [Validators.required]],
        });
    }

    save() {
        const form = this.sddForm.value;

        form.signature_date = moment(form.signature_date, 'DD-MM-YYYY').format('YYYY-MM-DD');

        const payload = new FormData();
        payload.append('dealer_id', form['dealer_id']);
        // payload.append('ka_id', form['ka_id']);
        payload.append('type', form['type']);
        payload.append('iban', form['iban']);
        payload.append('iban_type', form['iban_type']);
        payload.append('payments_type', form['payments_type']);
        payload.append('signature_date', form['signature_date']);
        payload.append('file', this.file);

        return this.addSubscription(payload);
    }

    setDealerId(dealerId: any) {
        this.sddForm.get('dealer_id').setValue(dealerId);
    }

    // setKaId(kaId: any) {
    //     this.sddForm.get('ka_id').setValue(kaId);
    // }

    selectedDate(date) {
        this.filter.date = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.apiDate = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    addSubscription(selectedSubscription: FormData) {
        this.busy = this.sddService.addSddSubscription(selectedSubscription).subscribe(
            (response: Sdd) => {
                this.sddService.successAlert('Changed Document!');
                this.refresh.emit(true);
                this.dismissModal();
            },
            (err) => {
                this.sddService.errorAlert(err.message);
                this.refresh.emit(true);
                this.dismissModal();
            },
        );
    }

    handleFileInput(files: FileList) {
        if (files && files.item(0)) {
            const document = files.item(0);
            this.file = document;
            // }
        }
    }
}
