<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadList()">
        <div class="form-row">
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                    id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xxl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Dealer ID</label>
                    <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date Range</label>
                    <input id="documentDate" type="text" daterangepicker autocomplete="off" [options]="pickerOptions"
                        [(ngModel)]="filter.date" name="date" class="form-control"
                        (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Tabella -->

<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>SPID IR</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-orders"
            [fullScreen]="false"
        >
        <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th style="width: 20%;" translate>Company name</th>
                    <th style="width: 15%;" translate>ID Transazione</th>
                    <th style="width: 10%;" translate>Brand</th>
                    <th style="width: 15%;" translate>Prezzo Dealer</th>
                    <th style="width: 15%;" translate>Prezzo Cliente</th>
                    <th style="width: 10%;" translate>Date</th>
                    <th class="text-center" style="width: 15%;" translate>Status</th>
                </tr>
            </thead>
            <tbody class="bg-gradiend-white">
            <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="orders"
                [fullScreen]="false"
            >
            <p style="color: white">Caricamento...</p>
            </ngx-spinner>
                <tr *ngIf="giftCardList.length==0">
                    <td translate colspan="7" class="text-center">List is empty</td>
                </tr>
                <tr *ngFor="let item of giftCardList">
                    <td>
                        <a [routerLink]="['/dealer', item.dealer_id]" target="_blank">{{item.company_name}}</a>
                    </td>
                    <td>
                      {{item?.transaction_id}}
                    </td>
                    <td>
                      {{item?.name}}
                    </td>
                    <td >
                      {{item?.dealer_price | currency: "€"}}
                    </td>
                    <td >
                        {{item?.customer_price | currency: "€"}}
                    </td>
                    <td>
                        {{item?.created_at | amDateFormat: 'DD/MM/YYYY  -  HH:mm' }}
                    </td>
                    <td>
                        <p class="bg-success text-white rounded text-bold text-center">{{item?.status}}</p>
                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="giftCardList?.length>0">
        <span><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b> {{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}. <a class="link" (click)="exsportGiftCardList()">Esporta la lista completa</a>
    </span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadList()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>
</div>