<div class="modal-body" tabindex="-1" [ngBusy]="busy">
    <form [formGroup]="groupForm" (ngSubmit)="save()" class="form-height">
        <div class="form-row">
            <div class="col-12 col-lg-4 col-xl-12 form-group mb-xl-2" *ngIf="!data">
                <label translate>Company Name</label>
                <app-retailer-typeahead ngDefaultControl formControlName="dealer_id"
                                        (onChange)="setDealerId($event)"></app-retailer-typeahead>
                <val-errors controlName="dealer_id">
                    <ng-template valError="required">
                        <span translate>Company Name is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="col-12 col-lg-4 col-xl-12 form-group mb-xl-2">
                <label translate>Name</label>
                <input class="form-control" type="text" formControlName="name"/>
                <val-errors controlName="name">
                    <ng-template valError="required">
                        <span translate>Name is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="col-12 col-xl-12 form-group mb-xl-2">
                <label translate>Type</label>
                <input class="form-control" type="text" formControlName="type"/>
                <val-errors controlName="type">
                    <ng-template valError="required">
                        <span translate>Type is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="col-12 mt-4 mb-4 row mr-0 pr-0" *ngIf="data">
                <h4 translate class="col-sm-9">Group Dealers:</h4>
                <button  id="dealer" type="button" class="btn btn-secondary col-sm-3 mr-0"
                        data-toggle="modal" data-target="#delaresModal" data-mdb-dismiss="modal"
                        (click)="showModal()"
                >Add Dealer
                </button>
            </div>

            <div class="col-12 mb-4">
                <div class="variation-container">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0" *ngFor="let dealer of dealers">
                            {{dealer['company_name']}}
                            <span class="float-right">
                                    <button type="button" class="btn btn-link text-danger mr-1 m-0 p-0"
                                            [swal]="deleteGroupDealers"
                                            (confirm)="deleteGroupDealer(dealer.id)">
                                            <i class="fas fa-trash"></i>
                                    </button>

                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 mt-4">
                <button type="submit" class="btn btn-success btn-block">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
</div>


<ng-template #groupDealerTemplate let-modal let-d="dismiss">
    <div class="modal-header" tabindex="2000">
        <h4 class="modal-title clamp-title" translate>Aggiungi Dealer</h4>
        <button (click)="d('Cross click')"
                type="button"
                class="close"
                aria-label="Close"

        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-group-dealer-modal [groupId]="data.id" (addDealer)="addDealer($event)"
                            [modal]="dealerModal"></app-group-dealer-modal>
</ng-template>
