import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { Mail } from '@models/mail-generator';
import { Router } from '@angular/router';
import { RequestService } from '@services/request.service';
import { AuthenticationService } from '@services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MailGeneratorService } from '@services/mail-generator.service';
import { User } from '@models/user';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
@Component({
    selector: 'app-mail-generator',
    templateUrl: './mail-generator.component.html',
    styleUrls: ['./mail-generator.component.css'],
})
export class MailGeneratorComponent implements OnInit {
    @ViewChild('mailModalTemplate') private mailModalTemplate;
    deleteSubscriptionOptions: ConfirmOptionsService;
    ordersPagination: MetaPagination;
    mailList: Mail[];
    salvaMail: FormGroup;
    isDealer = false;
    isEdit = true;
    destinatariSelected: any;
    slugList: [] = [];

    roleSelected: { am: boolean; ka: boolean; dc: boolean };

    destinatario = [
        { key: 'masterAgent', name: 'Area Manager' },
        { key: 'keyAccount', name: 'Key Account' },
        { key: 'subMaster', name: 'Direttore Commerciale' },
        { key: 'dealer', name: 'Dealer' },
    ];

    selectedOperator: number;
    selectedDest: string;

    dizionarioRuoli: { [key: string]: string } = {
        masterAgent: 'Area Manager',
        keyAccount: 'Key Account',
        subMaster: 'Direttore Commerciale',
        dealer: 'Dealer',
    };

    filters = {
        nome: null,
        slug: null,
        date: null,
    };

    apiDate = {
        from: '',
        to: '',
    };

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        },
    };

    constructor(
        private router: Router,
        public request: RequestService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        public formBuilder: FormBuilder,
        public mailService: MailGeneratorService,
        private dateRangePickerOptions: DaterangepickerConfig,
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.isDealer = user && user.profile.role === 'dealer';
        });
        this.deleteSubscriptionOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla',
        });
    }

    ngOnInit(): void {
        this.salvaMail = this.formBuilder.group({
            nome: ['', Validators.required],
            operatori: ['', Validators.required],
            tipo_destinatario: ['', Validators.required],
            slug: ['', Validators.required],
        });
        this.loadMailList();
        this.mailService.getSlugList().subscribe((slugs) => {
            this.slugList = slugs;
        });
    }

    loadMailList() {
        this.spinner.show('table-orders');

        const params = {};

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        if (this.selectedOperator) {
            params['operatorId'] = this.selectedOperator;
        }

        if (this.selectedDest) {
            params['destinatario'] = this.selectedDest;
        }

        if (this.filters.nome) {
            params['nome'] = this.filters.nome;
        }

        if (this.filters.slug) {
            params['slug'] = this.filters.slug;
        }

        if (this.filters.date) {
            params['from'] = this.apiDate?.from;
            params['to'] = this.apiDate?.to;
        }

        this.mailService.getMailList(params).subscribe(
            (response: any) => {
                this.mailList = response;
                this.mailList = new Mail().fromArray(response.data);
                this.ordersPagination = response._meta;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    watchLog(item: any) {
        const mailId = item.id;
        this.router.navigate(['/log', mailId]);
    }

    watchDetail(item: any) {
        const mailId = item.id;

        this.roleSelected = item.role;

        this.router.navigate([
            '/subscription',
            mailId,
            {
                am: this.roleSelected.am,
                ka: this.roleSelected.ka,
                dc: this.roleSelected.dc,
            },
        ]);
    }

    save() {
        if (this.salvaMail.valid) {
            const operatoriArray = this.salvaMail.value.operatori;
            const operatoriObj = {};
            operatoriArray.forEach((value, index) => {
                operatoriObj[`operatore${index + 1}`] = value;
            });

            const payload = {
                nome: this.salvaMail.value.nome,
                slug: this.salvaMail.value.slug,
                tipo_destinatario: this.destinatariSelected,
                operatori: JSON.stringify(operatoriObj),
            };

            this.mailService.postMail(payload).subscribe(
                (response: Mail) => {
                    this.mailService.successAlert('Salvato con successo');
                    this.dismissModal();
                },
                (err) => {
                    this.mailService.errorAlert(err);
                    this.dismissModal();
                },
            );
        }
    }

    showModal(): void {
        this.modalService.open(this.mailModalTemplate, { size: 'md' });
    }

    dismissModal() {
        this.salvaMail.reset();
        this.modalService.dismissAll();
        this.loadMailList();
    }

    onOperatorChange(event: any): void {
        if (event) {
            this.selectedOperator = event.id;
        }
    }

    onTypeDestChange(event: any): void {
        if (event) {
            this.selectedDest = event.key;
        }
    }

    setDestinatari() {
        const destinatariSelezionati = this.salvaMail.get('tipo_destinatario').value;
        const destinatariObj = {};
        destinatariSelezionati.forEach((value, index) => {
            destinatariObj[`destinatario${index + 1}`] = value;
        });
        this.destinatariSelected = JSON.stringify(destinatariObj);
    }

    setOperatori(id: number) {
        if (id) {
            this.salvaMail.controls['operatori'].setValue(id);
        }
    }

    deleteMail(id: number) {
        this.mailService.deleteListSubscription(id).subscribe(
            () => {
                const index = this.mailList.findIndex((dealer) => dealer.id === id);
                if (index > -1) {
                    this.mailService.successAlert('Deleted Service!');
                    this.loadMailList();
                }
            },
            (err) => {
                this.mailService.errorAlert(err.message);
            },
        );
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filters.date =
            startDate.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            endDate.format(localDateFormat);
    }
}
