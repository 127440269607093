import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';


export class ShopAffiliationList extends BaseModel {
    id: number;
    name_list: string;
    count_list: string;
    visit_schedule: string;
    visited: string;
    affiliated: string;
    classifica_am: any[];
    classifica_ka: any[];
    created_at: string;
    created_by: string;
    update_at: string;
    update_by: string;
    is_deleted: number;  

    fromArray(result: []): ShopAffiliationList[] {
        const array: ShopAffiliationList[] = [];
        for (const item of result) {
            array.push(new ShopAffiliationList(item));
        }
        return array;
    }
}

export class ShopAffiliationListProvider extends BaseModel {
    data: ShopAffiliationList[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ShopAffiliationListDetail extends BaseModel {
    id: number;
    list_id: number;
    dealer_id: number;
    agent_id: number;
    visit_id: string;
    visit_id_schedule: string;
    rag_sociale: string;
    kompass_id: string;
    nome: string;
    cognome: string;
    cod_fisc: string;
    piva: string;
    city_id: number;
    list_name: string;
    city_name: string;
    province_name: string;
    region_name: string;
    cap: string;
    address: string;
    email: string;
    telephone: string;
    json_data: string;
    note: string;
    status: string;
    created_at: string; 
    updated_at: string;
    created_by: number;
    updated_by: number;
    is_deleted: number;
    agent_name: string;
    checked: boolean;
    selected: boolean = false;  
    scheduled: string;
    visit_data: any;
    expired: boolean;

    public static visitStatus= [
        {
            label: 'Da Programmare',
            key: 'da-programmare'
        },
        // {
        //     label: '-',
        //     key: 'assegnato'
        // },
        {
            label: 'Programmata',
            key: 'programmata'
        },
        {
            label: 'Visitato',
            key: 'visitato'
        },
        {
            label: 'Affiliato',
            key: 'affiliato'
        },
        {
            label: 'Già Nostro Affiliato',
            key: 'gia-nostro-affiliato'
        },
        {
            label: 'Attività Chiusa',
            key: 'chiuso'
        },
        {
            label: 'Non Interessato',
            key: 'non-interessato'
        },
        {
            label: 'Altro',
            key: 'altro'
        },
    ];

    static statusType = {
        'da-programmare': 'Da programmare',
        'assegnato': 'Assegnato',
        'programmata': 'Programmata',
        'visitato': 'Visitato',
        'affiliato': 'Affiliato',
        'gia-nostro-affiliato': 'Già Nostro Affiliato',
        'chiuso': 'Chiuso',
        'non-interessato': 'Non Interessato',
        'altro': 'Altro',
    };
    
    get visitStatus(){
        return ShopAffiliationListDetail.statusType[this.status];
    }
    
    fromArray(result: []): ShopAffiliationListDetail[] {
        const array: ShopAffiliationListDetail[] = [];
        for (const item of result) {
            array.push(new ShopAffiliationListDetail(item));
        }
        return array;
    }
}

export class ShopAffiliationListDetailProvider extends BaseModel {
    data: ShopAffiliationListDetail[];
    _meta: MetaPagination;
    _links: Array<any>;
}