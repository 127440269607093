import { Component, OnInit, ViewChild } from '@angular/core';
import { MetaPagination } from '@models/meta-pagination';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreService } from '@services/store.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoreCategory, StoreCategoryPagination } from '@models/store/store-category';
import { StoreOperator, StoreOperatorPagination } from '@models/store/store-operator';
import { StoreBrand, StoreBrandPagination } from '@models/store/store-product';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
})
export class CategoryComponent implements OnInit {
  filter = {
    name: '',
    category_id: '',
    operator_id: '',
    brand_id: '',
  };
  categories: StoreCategory[] = [];
  categoriesPagination: MetaPagination;
  selectedCategory: any;
  @ViewChild('categoryFormTemplate') private categoryFormTemplate;
  deleteCategoryOptions: ConfirmOptionsService;
  categoriesLoading = false;
  brandsLoading = false;
  parentCategories: StoreCategory[] = [];
  operators: StoreOperator[] = [];
  brands: StoreBrand[] = [];
  operatorsLoading = false;

  featuredItems: { key: string; value: string }[] = [
    {
      key: 'Si',
      value: '1',
    },
    {
      key: 'No',
      value: '0',
    },
  ];

  constructor(public modalService: NgbModal, private storeService: StoreService, private spinner: NgxSpinnerService) {
    this.deleteCategoryOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro!',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
    });
  }

  ngOnInit(): void {
    this.loadCategories();
    setTimeout(() => {
      this.loadOperators();
      this.loadFilterCategories();
      this.loadBrands();
    });
  }

  showModal(category?: any) {
    this.selectedCategory = {
      ...category,
      featured: category.featured,
    };
    this.modalService.open(this.categoryFormTemplate, { size: 'lg' });
  }

  deleteCategory(id: number) {
    this.storeService.deleteCategory(id).subscribe(
      () => {
        const index = this.categories.findIndex((category) => category.id === id);
        if (index > -1) {
          this.categories.splice(index, 1);
          this.storeService.successAlert('Deleted Category!');
        }
      },
      (err) => {
        this.storeService.errorAlert(err.message);
      },
    );
  }
  ƒ;
  loadCategories() {
    this.spinner.show('table-categories');
    const params = {};
    if (this.categoriesPagination?.currentPage) {
      params['page'] = this.categoriesPagination?.currentPage;
    }
    if (this.filter.name) {
      params['nome'] = this.filter.name;
    }
    if (this.filter.category_id) {
      params['id_padre'] = this.filter.category_id;
    }
    if (this.filter.operator_id) {
      params['id_gestore'] = this.filter.operator_id;
    }
    if (this.filter.brand_id) {
      params['id_brand_telefonia'] = this.filter.brand_id;
    }

    this.storeService.getCategories(params).subscribe(
      (response: StoreCategoryPagination) => {
        this.categories = response.data;
        this.categoriesPagination = response._meta;
        this.spinner.hide('table-categories');
      },
      (err) => {
        this.spinner.hide('table-categories');
      },
    );
  }

  loadFilterCategories(term?) {
    this.categoriesLoading = true;
    const params = {};
    if (term) {
      params['nome'] = term;
    }
    this.storeService.getCategories(params).subscribe(
      (response: StoreCategoryPagination) => {
        this.parentCategories = response.data;
        this.categoriesLoading = false;
      },
      (err) => {
        this.categoriesLoading = false;
      },
    );
  }

  loadOperators(term?) {
    this.operatorsLoading = true;
    const params = {};
    if (term) {
      params['name'] = term;
    }
    this.storeService.getOperators(params).subscribe(
      (response: StoreOperatorPagination) => {
        this.operators = response.data;
        this.operatorsLoading = false;
      },
      (err) => {
        this.operatorsLoading = false;
      },
    );
  }

  loadBrands(term?) {
    this.brandsLoading = true;
    const params = {};
    if (term) {
      params['nome'] = term;
    }
    this.storeService.getBrands(params).subscribe(
      (response: StoreBrandPagination) => {
        this.brands = response.data;
        this.brandsLoading = false;
      },
      (err) => {
        this.brandsLoading = false;
      },
    );
  }
}
