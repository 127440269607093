import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';


const version = 'v3';


@Injectable({
  providedIn: 'root',
})


export class MailGeneratorService {
  constructor(private request: RequestService, public alertService: AlertService) {}

  // Mail
  public getMailList(params?): Observable<any> {
    return this.request.get(version + '/mail/list', params);
  }

  public postMail(payload: any) {
    return this.request.post('v3/mail/create', payload);
  }

  public getSlugList(){
    return this.request.get('v3/mail/slug-list');
  }

  deleteListSubscription(id :number) {
    return this.request.delete(version +`/mail/delete/${id}`);
  }

  // Mail subscription
  public getMailSubscription(params?): Observable<any> {
    return this.request.get(version + '/mail-subscription/mail-detail', params);
  }

  public getDcSelect(): Observable<any> {
    return this.request.get(version + '/mail-subscription/dc-select');
  }

  public getAmSelect(params?): Observable<any> {
    return this.request.get(version + '/mail-subscription/am-select', params);
  }

  public getkaSelect(params?): Observable<any> {
    return this.request.get(version + '/mail-subscription/agent-select', params);
  }

  public postMailAssigned(payload): Observable<any> {
    return this.request.post(version + '/mail-subscription/mail-assigned',  payload);
  }

  public putEmail(id, body: {}){
    return this.request.put(version + '/mail-subscription/update/' + id, body);
  }

  deleteMailAssigned(id :number) {
    return this.request.delete(version +`/mail-subscription/delete/${id}`);
  }


  // Mail Log

  public getMailLogById(params?): Observable<any> {
    return this.request.get(version + '/mail-log/mail-log', params);
  }


  // Alert
  public successAlert(text) {
    this.alertService.show('', 'Servizio ' + text, { classname: 'bg-success text-light', delay: 2000 });
  }

  public errorAlert(message?) {
    this.alertService.show('Errore nel caricamento del file ', message ? message : 'Riprova di nuovo!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }

}
