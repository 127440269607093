<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadOrders()">
        <div class="form-row">
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                    id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Date Range</label>
                    <input id="documentDate" type="text" daterangepicker [options]="pickerOptions"
                           [(ngModel)]="filter.date" name="date" class="form-control"
                           (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Tracking code</label>
                    <input [(ngModel)]="filter.tracking_code" name="tracking_code" class="form-control"/>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Type</label>
                    <ng-select [clearable]="true" class="disableClear" [(ngModel)]="filter.type"
                    [items]="statuses" bindLabel="label"
                    name="subscribed" id="subscribed"
                    bindValue="key" [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Payment</label>
                    <ng-select [clearable]="true" class="disableClear" [(ngModel)]="filter.payment"
                    [items]="payments" bindLabel="label"
                    name="subscribed" id="subscribed"
                    bindValue="key" [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Open/Closed</label>
                    <ng-select [clearable]="true" class="disableClear" [(ngModel)]="filter.closed"
                    [items]="bollettinClosed" bindLabel="label"
                    name="subscribed" id="subscribed"
                    bindValue="key" [searchable]="true"
                    ></ng-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<!--- Tabella -->
<div class="card">
    <div class="card-header">
        <h4 class="mb-0 text-uppercase" translate>Bulletin</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="table-orders"
                [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Company name</th>
                <th translate>Tracking code</th>
                <th translate>Type</th>
                <th translate>Total price</th>
                <th translate>Commission</th>
                <th translate>Payment</th>
                <th translate>Open/Closed</th>
                <th translate>Date</th>
                <th translate>Status</th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let order of orders">
                <td>
                    <a [routerLink]="['/dealer', order.dealer_id]" target="_blank">
                        {{order?.company_name}}</a>
                </td>
                <td>
                    {{order?.tracking_code}}
                </td>
                <td class = "text-capitalize">
                    {{order?.slipType}}
                </td>
                <td class = "text-capitalize text-right">
                    {{order?.total_price | currency: 'EUR' }}
                </td>
                <td class="text-right">
                    {{order?.commission  | currency: 'EUR' }}
                </td>
                <td class="text-center">
                    {{order?.paymentType}}
                </td>
                <td>
                    <span class="badge badge-danger text-center"
                    *ngIf="order.closed==bollettinClosed[0].key" translate>{{bollettinClosed[0].label}}</span>
                    <span class="badge badge-success  text-center" 
                    *ngIf="order.closed==bollettinClosed[1].key" translate>{{bollettinClosed[1].label}}</span>
                </td>
                <td>
                    {{order.created_at | amFromUnix | amDateFormat: 'HH:mm DD/MM/YYYY' }}
                </td>
                <td>
                    <span class="badge badge-success">{{order?.slipStatus}}</span> 
                </td>
            </tr>
            <tr *ngIf="orders?.length<=0">
                <td class="text-muted" colspan="10" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="orders?.length>0">
    <span><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
        {{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}.
        <a class="link"
        >Esporta la lista completa</a
        >
    </span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
            size="sm"
            [pageSize]="ordersPagination.perPage"
            [maxSize]="10"
            [(page)]="ordersPagination.currentPage"
            [collectionSize]="ordersPagination.totalCount"
            (pageChange)="loadOrders()"
            [boundaryLinks]="true"
        >
        </ngb-pagination>
    </div>