<ng-template #documentModal let-modal size="xl">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Anteprima</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <ng-container *ngIf="image">
          <img [src]="document | safe" class="img-fluid" />
      </ng-container>
    <div class="embed-responsive embed-responsive-1by1" *ngIf="!image">
        <iframe [src]="document | safe" class="embed-responsive-item"></iframe>
    </div>
  </div>
</ng-template>
