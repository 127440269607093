import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import * as moment from 'moment';
import {Moment} from 'moment';
import {EPayService} from '@services/e-pay.service';
import {ShopBrand} from '@models/e-pay/shop-brand';
import {ReadyMail, ReadyMailProvider, Shipping, Status} from '@models/kd-orders';
import {AuthenticationService} from '@services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ActivatedRoute} from '@angular/router';
import {KdOrderService} from '@services/kd-order.service';
import {User} from '@models/user';

@Component({
    selector: 'app-ready-mail',
    templateUrl: './ready-mail.component.html',
    styleUrls: ['./ready-mail.component.css']
})
export class ReadyMailComponent implements OnInit {


    orders: ReadyMail[] = [];
    //selectedOrder: ShopOrder;
    ordersPagination: MetaPagination;

    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        }
    };

    statuses = [
        {
            label: 'Spedito',
            key: 'Spedito'
        },
        {
            label: 'Stampa in corso',
            key: 'Stampa in corso'
        },
        {
            label: 'Annullato',
            key: 'Annullato'
        },
    ];

    selectedData: Shipping;
    filter = {
        dealer_id: '',
        date: '',
        status: '',
        shipment_id: '',
        service: '',
    };
    apiDate = {
        from: '',
        to: ''
    };
    localFormat = 'dd/MM/yyyy HH:MM';
    types = EPayService.productTypes;
    currency = EPayService.currency;
    brandsLoading = false;
    brands: ShopBrand[] = [];
    isDealer = false;
    @ViewChild('detailTemplate') private detailTemplate;
    @ViewChild('receiptFormTemplate') private receiptFormTemplate;

    constructor(
        private ePayService: EPayService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        private dateRangePickerOptions: DaterangepickerConfig,
        private ShippingService: KdOrderService,
        public route: ActivatedRoute
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
                this.isDealer = user && user.profile.role === 'dealer';
            }
        );
    }

    ngOnInit(): void {
        this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
        this.loadOrders();
        setTimeout(() => {
        });
    }

    loadOrders() {
        this.spinner.show('table-orders');
        const params = {};
        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }
        if (this.filter.status) {
            params['status'] = this.filter.status;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.date) {
            params['from_date'] = this.apiDate.from;
            params['to_date'] = this.apiDate.to;
        }
        if (this.filter.shipment_id) {
            params['shipment_id'] = this.filter.shipment_id;
        }
        if (this.filter.service) {
            params['service'] = this.filter.service;
        }


        this.ShippingService.getReadyMail(params).subscribe((response: ReadyMailProvider) => {
            this.orders = response.data;
            this.ordersPagination = response._meta;
            this.spinner.hide('table-orders');
        }, err => {
            this.spinner.hide('table-orders');
        });
    }


    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.date = startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }

    showDetailModal(data?: Shipping) {
        this.selectedData = data;
        this.modalService.open(this.detailTemplate, {size: 'lg', scrollable: true});
    }

}
