import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@services/authentication.service';


@Component({
    selector: 'app-login-verification',
    templateUrl: './login-verification.component.html',
    styleUrls: ['./login-verification.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginVerificationComponent implements OnInit {

    @ViewChild('codeVerificationNgForm') codeVerificationNgForm: NgForm;

    codeVerification: FormGroup;

    sessionToken: string = null;

    errMessage: string;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthenticationService,
        private _formBuilder: FormBuilder,
        private _router: Router
    ) {
    }

    ngOnInit(): void {
        this.codeVerification = this._formBuilder.group({
            code: ['', [Validators.required]],
        });

        this.sessionToken = this._activatedRoute.snapshot.queryParamMap.get('token');
    }

    checkCode(): void {
        // Return if the form is invalid
        if (this.codeVerification.invalid) {
            return;
        }

        // Disable the form
        this.codeVerification.disable();


        // Check Code
        this._authService
            .twoFactorAuth(this.codeVerification.get('code').value, this.sessionToken)
            .subscribe({
                next: (response) => {
                    if (response.success) {
                        if(!response?.data.profile.is_psw_expire && response?.data.token) {
                            const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/';
                            // Navigate to the redirect url
                            this._router.navigateByUrl('/');
                            return;
                        }

                        if (response?.data.profile.is_psw_expire) {
                            this._router.navigateByUrl('/change-password?token=' + response?.data.token);
                            return;
                        }
                        if (response?.data.token) {
                            const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/';
                            // Navigate to the redirect url
                            this._router.navigateByUrl('/');
                            return;
                        }


                    } else {
                        this.codeVerification.enable();
                        if (response?.data?.attempt < 4) {
                            this.errMessage = response.data.message + " - Attempts so far: " + response.data.attempt ;
                        } else {
                            this._router.navigateByUrl('/login');
                        }
                    }
                },
                complete: () => {
                    // Re-enable the form
                    this.codeVerification.enable();

                    // Reset the form
                    this.codeVerificationNgForm.resetForm();


                },
            });

    }

}
