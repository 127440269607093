<app-master-header
        cardClass="bg-gradient-light"
        headerBackground="assets/img/italy.jpg"
        title="Vendite KD"
        [operatori]="true"
        (onChange)="this.updateUrls($event.operatore);"
>
</app-master-header>

<ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs">
    <li ngbNavItem>
        <a ngbNavLink>Negozi</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Vendite per Dealer"
                    image="assets/img/flats/ribbon.png"
            >
                <data-builder-table [url]="urlClassificaItalia"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'masteragent', 'operator', 'submaster']">
        <a ngbNavLink>Key Account</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Vendite per Key Account"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlClassificaAgenti"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'masteragent', 'operator', 'submaster']">
        <a ngbNavLink>Regioni</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Vendite per Regione"
                    image="assets/img/flats/global-network.png"
            >
                <data-builder-table [url]="urlClassificaRegioni"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
