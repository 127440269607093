import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '@services/authentication.service';
import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Avviso',
                        text: 'Sessione scaduta, eseguire nuovamente il login.',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                    }).then(() => {
                        this.authenticationService.logout();
                        location.reload();
                    });
                    // auto logout if 401 response returned from api
                }
                let error = err.error.message || err.statusText;
                if (err.error && err.error?.length > 0) {
                    error = err.error[0].message;
                }
                return throwError(error);
            })
        );
    }
}
