<body class="login-page">
<div class="login-box">
    <div class="card">
        <div class="card-body login-card-body rounded elevation-1">
            <img src="/assets/img/logo_full.png" width="100%" class="mb-5">

            <!--            <p class="login-box-msg">Inserisci i dati di accesso</p>-->

            <form  [formGroup]="codeVerification"
                   #codeVerificationNgForm="ngForm">
                <div class="row">
                    <div class="col-12 mb-3 pl-3 text-danger" *ngIf="codeVerification.get('code').hasError('required')" translate>
                        {{errMessage}}
                    </div>
                    <div class="col-12 mb-3 pl-3" translate>
                        Inserisci il codice ricevuto via mail. Hai 4 tentativi.
                    </div>

                    <div class="mb-4 col-12 w-75">

                        <input
                                id="code"
                                formControlName="code"
                                type="text"
                                class="form-control"
                                placeholder="Codice a 2 fattori"
                                [formControlName]="'code'">
                    </div>
                </div>

                <!-- Submit button -->
                <div class="row">
                    <div class="col-12">
                        <button
                                class="w-100 btn bg-gradient-yellow btn btn-block mb-2"

                                [disabled]="codeVerification.disabled"
                                (click)="checkCode()">
                    <span translate>
                       Accedi
                    </span>
                            <ngx-spinner
                                    *ngIf="codeVerification.disabled"
                            ></ngx-spinner>
                        </button>
                    </div>
                </div>

                <hr>
                <div class="row">
                    <div class="col-12">
                        <ul class="list-group list-group-horizontal no-bullet justify-content-center">
                            <li class="mr-2">
                                <a class="btn btn-circle btn-facebook" type="button"
                                   href="https://www.facebook.com/MasterGroupTLC" target="_blank"><i
                                        class="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li class="mr-2">
                                <a class="btn btn-circle btn-linkedin" type="button"
                                   href="https://www.linkedin.com/company/master-group-master-dealer-telefonia-/"
                                   target="_blank"><i class="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                            <li class="mr-2">
                                <a class="btn btn-circle btn-instagram" type="button"
                                   href="https://www.instagram.com/mastergroup_masterdealer/" target="_blank"><i
                                        class="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a class="btn btn-circle btn-info" type="button" href="https://master-dealer.it"
                                   target="_blank"><i class="fa fa-globe"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
        <!-- /.login-card-body -->
    </div>
</div>
</body>
