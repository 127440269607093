<div class="card" [ngClass]="cardClass">
    <div class="card-body" style="min-height: 100px;">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                     size="default"
                     color="#fff"
                     type="square-jelly-box"
                     name="loader-subheader"
                     [fullScreen]="false">
        </ngx-spinner>
        <div class="row" *ngIf="!error">
            <div *ngFor="let col of data; let i = index;" class="{{col.size}}"  [ngClass]="{'border-right': i < data.length-1}">
                <div class="d-flex align-items-center">
                    <img class="d-inline-flex"
                         [ngClass]="imgClass"
                         [src]="col.image"
                         width="70px">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>{{col.title}}</p>
                        <h1 class="display-6">{{col.subtitle}}</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-center flex-wrap"
             style="height: 140px; background-color: #000000d1;" *ngIf="error">
            <div class="text-center">
                <img src="/assets/img/flats/close.png" height="48px"/>
                <p class="lead text-white mt-2">C'è stato un errore nel caricamento...</p>
                <a (click)="load()" class="text-white mt-2 link"><i class="fas fa-redo-alt"></i></a>
            </div>
        </div>

    </div>
</div>
