import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { AuthenticationService } from '@app/services/authentication.service';
import { KpiService } from '@app/services/kpi.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-kpi-recap',
  templateUrl: './kpi-recap.component.html',
  styleUrls: ['./kpi-recap.component.css'],
})
export class KpiRecapComponent implements OnInit {
  selectedTab: string = '';
  loadingKpi: boolean = false;

  selectedKpiType: any = null;

  kpiTypeList: any;

  kpiPayload = {
    month: '',
    year: '',
    from: this.getFirstDayOfMonth(),
    to: this.getLastDayOfMonth(),
    type_id: '',
    id_am: '',
  };

  kpis: any;
  private user: User;

  constructor(private kpiService: KpiService, private authService: AuthenticationService) {
    this.authService.currentUser.subscribe((user) => (this.user = user));
  }

  ngOnInit(): void {
    this.selectedTab = this.user.profile.role == 'masteragent' ? 'keyAccount' : 'areaManager';
  }

  loadSelectKpi() {
    const params = {};

    if (this.kpiPayload.month) {
      params['month'] = this.kpiPayload.month;
    }

    if (this.kpiPayload.year) {
      params['year'] = this.kpiPayload.year;
    }

    this.kpiService.getKpiTypeListForRecap(params).subscribe((res) => {
      this.kpiTypeList = res.data;
      if (this.selectedKpiType && !this.kpiTypeList.some((kpiType) => kpiType.id === this.selectedKpiType.id)) {
        this.selectedKpiType = null;
      }
    });
  }

  selectTab(tabName: string) {
    this.kpiTypeList = null;
    this.selectedKpiType = null;
    this.kpis = null;
    this.selectedTab = tabName;
  }

  applyFilters() {
    const params = {};

    if (this.kpiPayload.month) {
      params['month'] = this.kpiPayload.month;
    }

    if (this.kpiPayload.year) {
      params['year'] = this.kpiPayload.year;
    }

    if (this.kpiPayload.id_am) {
      params['id_am'] = this.kpiPayload.id_am;
    }

    if (this.selectedKpiType.id) {
      params['id'] = this.selectedKpiType.id;
    }

    if (this.selectedTab == 'areaManager') {
      this.kpiService.getAreaManagerRanking(params).subscribe((res) => {
        this.kpis = res;
        this.loadingKpi = false;
      });
    } else if (this.selectedTab == 'keyAccount') {
      this.kpiService.getKeyAccountRanking(params).subscribe((res) => {
        this.kpis = res;
        this.loadingKpi = false;
      });
    }
  }

  onKpiTypeChange(event) {
    if (event != undefined) {
      this.loadingKpi = true;
      const selectedKpiTypeId = event;
      const selectedKpiType = this.kpiTypeList.find((kpiType) => kpiType.id === selectedKpiTypeId);
      this.selectedKpiType = selectedKpiType;
      this.applyFilters();
    } else if (event == undefined) {
      this.selectedKpiType = null;
      this.kpis = null;
    }
  }

  onMasterAgentChange(event) {
    this.loadingKpi = true;
    this.kpiPayload.id_am = event;
    this.applyFilters();
  }

  setSelectedDate($event: any) {
    this.kpiTypeList = null;
    this.selectedKpiType = null;
    this.kpis = null;
    this.kpiPayload.month = moment($event).format('MM');
    this.kpiPayload.year = moment($event).format('YYYY');
    this.loadSelectKpi();
  }

  getFirstDayOfMonth(): string {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    return this.formatDate(firstDay);
  }

  getLastDayOfMonth(): string {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return this.formatDate(lastDay);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  export() {
    let rows = this.kpis;
    let output = null;

    switch (this.selectedTab) {
      case 'areaManager':
        output = rows.map((row) => {
          return {
            'Area Manager': row.ragsociale,
            'Nome Target': this.selectedKpiType.title,
            Avanzamento: row.value,
            Target: row.target,
            'Media da fare': row.average,
            'Delta al tgt': row.delta_to_tgt,
            PAF: row.paf,
            'PAF %': row.paf_perc,
          };
        });
        break;

      case 'keyAccount':
        output = rows.map((row) => {
          return {
            'Key Account': row.ragsociale,
            'Area Manager': row.am_ragsociale,
            'Nome Target': this.selectedKpiType.title,
            Avanzamento: row.value,
            Target: row.target,
            'Media da fare': row.average,
            'Delta al tgt': row.delta_to_tgt,
            PAF: row.paf,
            'PAF %': row.paf_perc,
          };
        });
        break;

      default:
        output = [];
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `export-am-ranking-${this.kpiPayload.from}-${this.kpiPayload.to}.xlsx`);
  }
}
