import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dealer} from '@app/models/dealer';
import {AgenteService} from '@app/services/agente.service';

@Component({
    selector: 'app-agent-select',
    templateUrl: './agent-select.component.html',
    styleUrls: ['./agent-select.component.scss']
})
export class AgentSelectComponent implements OnInit {
    @Input()
    small = false;
    @Input()
    disabled = false;

    @Input()
    agent: any;

    @Input()
    vendors: [];

    data: Dealer[] = [];

    @Output() onChange = new EventEmitter();

    loading = false;

    constructor(
        private service: AgenteService
    ) {
    }

    ngOnInit(): void {
        // if (this.agent) {
        //     this.agent = this.agent;
        // }

        this.loading = true;
        this.service.selectOptions().subscribe((data) => {
            this.data = data;
            this.loading = false;
        });
    }


    public reset() {
        this.agent = null;
    }
}
