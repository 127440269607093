import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SurveyService} from '@app/services/survey.service';
import {Profile} from '@app/models/user';
import {AuthenticationService} from '@app/services/authentication.service';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

    @Input() dealerId;
    surveyForm: FormGroup;
    profile: Profile;
    surveyFormReady = false;
    surveyFormMode = 'new';
    surveyFormStructure: any = [];

    saving = false;

    constructor(
        private formBuilder: FormBuilder,
        private surveyService: SurveyService,
        private authService: AuthenticationService
    ) {
    }

    ngOnInit(): void {
        this.loadSurveyFormData();
    }

    buildForm(): void {
        const formGroup = {};
        this.surveyFormStructure.sections.forEach(section => {
            section.questions.forEach(control => {
                const validators = control.required ? [Validators.required] : [];
                formGroup[control.name] = [control.value, validators];
            });

        });
        this.surveyForm = this.formBuilder.group(formGroup);
        this.surveyForm.patchValue(this.surveyFormStructure.survey);
        this.surveyFormReady = true;
        this.saving = null;
    }

    loadSurveyFormData(): void {
        this.surveyService.getSurveyFormStructure(this.dealerId).subscribe(
            (data) => {
                this.surveyFormStructure = data;
                this.buildForm();
            },
            (error) => {
                console.error('Error loading survey form data:', error);
            }
        );
    }

    submit(): void {
        if (this.surveyForm.valid) {
            const payload = {
                dealer_id: this.dealerId,
                data: JSON.stringify(this.surveyForm.value),
            };
            this.saving = true;
            this.surveyService.postSurveyFormStructure(payload).subscribe(
                (response) => {

                    console.log('Dati salvati con successo:', response);
                    this.surveyService.successAlert('inviato correttamente');
                    this.saving = false;
                },
                (error) => {
                    console.error('Errore nel salvataggio dei dati:', error);
                    this.saving = false;
                    this.surveyService.errorAlert('nel invio del questionario. Riprova di nuovo.');
                }
            );
        } else {
            this.surveyService.errorAlert('Please complete all required fields before submitting the survey.');
        }
    }

}

