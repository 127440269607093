import {Component, OnInit, ViewChild} from '@angular/core';
import {AgentVisitsService} from '@services/agent-visits.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {MapTypeStyle} from '@agm/core/services/google-maps-types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '@environments/environment';

@Component({
    selector: 'app-agent-gps-tracking',
    templateUrl: './agent-gps-tracking.component.html',
    styleUrls: ['./agent-gps-tracking.component.css']
})
export class AgentGpsTrackingComponent implements OnInit {
    markers = [];
    shops = [];
    direction;
    filter = {
        date: '',
        agent_id: '',
        shop: '',
        province_id: '',
        region_id: '',
        city_id: '',
        channel: '',
        cluster: ''
    };
    rangeOptions = {
        singleDatePicker: true,
        ranges: []
    };
    apiDate = '';
    mapPinOptions = {
        url: 'assets/img/flats/pin.png',
        scaledSize: {
            width: 30,
            height: 45
        }
    };
    keys = [];
    defaultStyles: MapTypeStyle[] = [
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
                {visibility: 'off'}
            ]
        }
    ];
    public renderOptions = {
        suppressMarkers: true,
    };
    localFormat = 'DD/MM/YYYY HH:mm:ss';
    public markerOptions = {
        origin: {
            label: '',
            icon: ''
        },
        destination: {
            label: '',
            icon: ''
        },
        waypoints: []
    };
    localDatetimeFormat = 'DD/MM/YYYY HH:mm:ss';
    report;
    agentStops = [];
    agentStopsPagination;
    agentVisits = [];
    agentVisitsPagination;
    isSelectedAgent = false;
    lunchTime = 'lunch-time';
    nightTime = 'night-time';
    mode = 0;
    selectedVisit;
    baseAwsUrl = environment.baseAwsUrl;
    visitsData = [];
    tableUrl = null;
    mapAgentPinOptions = {
        url: 'assets/img/flats/pin-parking.png',
        scaledSize: {
            width: 40,
            height: 40
        }
    };
    @ViewChild('fileTemplate') private fileTemplate;

    constructor(
        private agentVisitsService: AgentVisitsService,
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig,
        public modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.selectedDate(moment());
        this.loadData();
    }

    loadData() {
        this.isSelectedAgent = !!this.filter.agent_id;
        // this.loadRoutes();
        setTimeout(() => {
            this.loadDashboard();
            // this.loadAgentStops();
            this.loadAgentVisits();
            this.loadShops();
        });
    }

    loadDashboard() {
        this.spinner.show('dashboard');
        const params = {};
        if (this.filter.date && this.apiDate) {
            params['date'] = this.apiDate;
        }
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        if (this.filter.channel) {
            params['channel'] = this.filter.channel;
        }
        if (this.filter.cluster) {
            params['cluster'] = this.filter.cluster;
        }
        this.agentVisitsService.getDashboardReport(params).subscribe((response) => {
            this.report = response;
            this.spinner.hide('dashboard');
        }, err => {
            this.spinner.hide('dashboard');
        });
    }

    loadAgentStops() {
        this.spinner.show('table-stops');
        const params = {};
        if (this.agentStopsPagination?.currentPage) {
            params['page'] = this.agentStopsPagination?.currentPage;
        }
        if (this.filter.date && this.apiDate) {
            params['date'] = this.apiDate;
        }
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        this.agentVisitsService.getAgentStops(params).subscribe((response) => {
            this.agentStops = response.data;
            this.agentStopsPagination = response._meta;
            this.spinner.hide('table-stops');
        }, err => {
            this.spinner.hide('table-stops');
        });
    }

    loadAgentVisits() {
        this.spinner.show('table-visits');

        const params = {};
        if (this.agentVisitsPagination?.currentPage) {
            params['page'] = this.agentVisitsPagination?.currentPage;
        }
        if (this.filter.date && this.apiDate) {
            params['date'] = this.apiDate;
        }
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        if (this.filter.shop) {
            params['shop'] = this.filter.shop;
        }
        if (this.filter.province_id) {
            params['province_id'] = this.filter.province_id;
        }
        if (this.filter.city_id) {
            params['city_id'] = this.filter.city_id;
        }

        let url = `agent/table?date[default]=${this.apiDate};${this.apiDate}`;

        if (this.filter.channel) {
            url += `&filter[default_dealer_channel]=${this.filter.channel}`;
        }
        if (this.filter.cluster) {
            url += `&filter[default_priority]=${this.filter.cluster}`;
        }
        if (this.filter.agent_id) {
            url += `&filter[default_agent_id]=${this.filter.agent_id}`;
        }
        if (this.filter.city_id) {
            url += `&filter[default_city_id]=${this.filter.city_id}`;
        }
        if (this.filter.province_id) {
            url += `&filter[default_province_id]=${this.filter.province_id}`;
        }

        this.visitsData = [];
        // this.tableUrl = `agent/table`;
        this.tableUrl = url;
    }

    loadRoutes() {
        this.spinner.show('map');
        const params = {};
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        if (this.filter.shop) {
            params['shop'] = this.filter.shop;
        }
        if (this.filter.province_id) {
            params['province_id'] = this.filter.province_id;
        }
        if (this.filter.city_id) {
            params['city_id'] = this.filter.city_id;
        }
        if (this.filter.date && this.apiDate) {
            params['date'] = this.apiDate;
        }
        this.agentVisitsService.getRoutes(params).subscribe((response) => {
            if (response?.data) {
                this.keys = Object.keys(response.data);
            }
            this.markers = response.data;
            this.direction = response.gps_data;
            if (this.direction && this.direction?.waypoints) {
                this.direction.waypoints.forEach((data) => {
                    data['stopover'] = false;
                    this.markerOptions.waypoints.push({
                        label: '',
                        icon:
                            {
                                url: 'assets/img/flats/pin-parking.png',
                                scaledSize: {
                                    height: 45,
                                    width: 45
                                }
                            },
                        infoWindow: ' <p class="w-100">\n' +
                            '                    <span class="pr-2" translate>Start date</span> <span\n' +
                            '                        class="float-right"><strong>' + moment(data.location.start_datetime).format(this.localDatetimeFormat)
                            + '</strong></span></p>\n' +
                            ' <p class="w-100">\n' +
                            '                    <span class="pr-2" translate>End date</span> <span\n' +
                            '                        class="float-right"><strong>' + moment(data.location.end_datetime).format(this.localDatetimeFormat) + '</strong></span></p>\n' +
                            ' <p class="w-100">\n' +
                            '                    <span class="pr-2" translate>Duration</span> <span\n' +
                            '                        class="float-right"><strong>' + data.location.duration + '</strong></span></p>\n' +
                            ' <p class="w-100">\n' +
                            '                    <span class="pr-2" translate>Latitude</span> <span\n' +
                            '                        class="float-right"><strong>' + data.location.lat + '</strong></span></p>\n' +
                            '                <p class="w-100">\n' +
                            '                    <span class="pr-2" translate>Longitude</span> <span\n' +
                            '                        class="float-right"><strong>' + data.location.lng + '</strong></span></p>\n'
                    });
                });
            }
            this.spinner.hide('map');
        }, err => {
            this.spinner.hide('map');
        });
    }

    showFileModal(visit) {
        this.selectedVisit = visit;
        this.modalService.open(this.fileTemplate, {size: 'md'});
    }

    selectedDate(date: Moment) {
        this.filter.date = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.apiDate = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    }

    changeRegion(event) {
        this.filter.region_id = event;
        if (!event) {
            this.filter.province_id = '';
        }
    }

    changeProvince(event) {
        this.filter.province_id = event;
        if (!event) {
            this.filter.region_id = '';
            this.filter.city_id = '';
        }
    }

    loadShops() {
        const params = {};
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        this.agentVisitsService.getShops(params).subscribe((response) => {
            this.shops = response;
        });
    }

    setMapPins(data: any) {
        this.visitsData = data;
    }
}
