/* tslint:disable:variable-name */
import { BaseModel } from '@models/base-model';
import { MetaPagination } from '@models/meta-pagination';
import { ShopOrderList } from '@models/shop-order';

export class SuretyOrdersList extends BaseModel {
  totale_ordine: string;
  dealer_id: string;
  id: string;
  order_id: string;
  ragsociale: string;
  stato_nome: string;
  type: string;
  welded: number;
  welding_date: string;
  created: string;
}

export class SuretyOrdersListPagination extends BaseModel {
  data: SuretyOrdersList[];
  _meta: MetaPagination;
  _links: Array<any>;
}

export class SuretyCreditReport extends BaseModel {
  credit: string;
  debit: string;
  total_amount: string;
  total_transaction: string;
}

export class DealerSuretyCredit extends BaseModel {
  id: number;
  total_amount: string;
  company_name: string;
  company_owner: string;
  total_transaction: number;
  credit: number;
  debit: number;
}

export class DealerSuretyCreditPagination extends BaseModel {
  data: DealerSuretyCredit[];
  _meta: MetaPagination;
  _links: Array<any>;
}

export class SuretyCredit extends BaseModel {
  id: number;
  dealer_id: number;
  order_id: number;
  amount: string;
  type: string;
  created_at: string;
  order: ShopOrderList;
  is_credit: boolean;
  operator: string;
  note: string;
  welding_date: string;
  welded: any;
  current_sum: number;

  get typePretty(): string {
    switch (this.type) {
      case 'plafond-kena':
        return 'Plafond Kena';
      case 'prodotti':
        return 'Prodotti';
      default:
        return 'Unknown';
    }
  }

  fromArray(data: SuretyCredit[]) {
    const array: SuretyCredit[] = [];
    for (const item of data) {
      array.push(new SuretyCredit(item));
    }
    return array;
  }
}

export class SuretyCreditPagination extends BaseModel {
  data: SuretyCredit[];
  _meta: MetaPagination;
  _links: Array<any>;
}
