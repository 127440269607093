<app-master-header
  cardClass="bg-gradient-1"
  title="Rendiconti di Addebito"
  [image]="'flats/payment.png' | assets"

>
  <p class="lead mt-2 mb-3 text-uppercase">
    Da qui puoi tenere traccia di tutti i rendiconti di addebito
  </p>
  <div>
    <form (ngSubmit)="loadList()">
        <div class="form-row">
          <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
              <label translate for="companyName">Company name</label>
              <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="companyName"></app-retailer-typeahead>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
              <label translate>Dealer ID</label>
              <input [(ngModel)]="filter.dealer_id" name="dealer_id" class="form-control" />
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-12 mt-auto mb-0">
            <div class="form-group">
              <label>Numero Documento</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="filter.number_doc"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
              <label translate>Date</label>
              <input
                id="documentDate"
                type="text"
                daterangepicker
                autocomplete="off"
                [options]="pickerOptions"
                [(ngModel)]="filter.date"
                name="date"
                class="form-control"
                (selected)="generateDateRangeString($event.start, $event.end)"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
              <button class="btn btn-secondary btn-block" translate type="submit">Filter</button>
            </div>
          </div>
        </div>
      </form>
  </div>
</app-master-header>

<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-6">
        <h4 class="mb-0" translate>Rendiconti di Addebito</h4>
      </div>
      <div class="col-6 text-right">
<!--          <p>ciao</p>-->
      </div>
    </div>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="default"
      color="#fff"
      type="square-jelly-box"
      name="table-orders"
      [fullScreen]="false"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped">
      <thead>
      <tr>
        <th translate>ID</th>
        <th  translate>Dealer ID</th>
        <th  translate>Rag Sociale</th>
        <th  translate>Anno</th>
        <th  translate>Periodo</th>
        <th  translate>Numero Doc.</th>
        <th  translate>Totale</th>
        <th  translate>Date</th>
        <th class="text-center">Visualizza</th>
      </tr>
      </thead>
      <tbody class="bg-gradient-white">
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        size="default"
        color="#fff"
        type="square-jelly-box"
        name="orders"
        [fullScreen]="false"
      >
        <p style="color: white">Caricamento...</p>
      </ngx-spinner>
      <tr *ngFor="let item of listItem">
        <td>
          {{ item.id }}
        </td>
        <td>
          {{ item.dealer_id }}
        </td>
        <td>
          {{ item.company_name }}
        </td>
        <td>
          {{ item.year }}
        </td>
        <td>
          {{ item.period }}
        </td>
        <td>
          {{ item.documentNumber }}
        </td>
        <td>
          {{ item?.date | date : 'dd/MM/yyyy HH:MM:ss' }}
        </td>
        <td>
          {{ item.total | currency: '€' }}
        </td>
        <td class="text-center">
          <button class="btn btn-link" (click)="watchFile(item.file)">
            <i class="fa fa-info-circle text-secondary"></i>
          </button>
        </td>
      </tr>
      <tr *ngIf="listItem?.length <= 0">
        <td class="text-muted text-center" colspan="9" translate>List is empty</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" *ngIf="listItem?.length > 0">
        <span
        ><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
          {{ this.ordersPagination.totalCount <= 1 ? 'Lista' : ('Liste' | translate) }}.
<!--            <a class="link">Esporta la lista completa</a>-->
        </span>
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      (pageChange)="loadList()"
      [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
</div>
