import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {User} from '@models/user';
import {AuthenticationService} from '@services/authentication.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '@services/data.service';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
    @ViewChild('fileUploadModal') fileUploadModal;

    busy: Subscription;

    urlClassificaItalia: string;
    urlClassificaAgenti: string;
    urlClassificaAreaManager: string;

    private user: User;
    stand = false;

    constructor(
        private modalService: NgbModal,
        private requestService: RequestService,
        private alertService: AlertService,
        private authService: AuthenticationService) {
        this.authService.currentUser.subscribe((user) => (this.user = user));

        this.stand = this.user && this.user.profile.operatori.includes('Kena Mobile Stands');
    }

    ngOnInit() {
    }

    updateUrls(operatore) {
        this.urlClassificaItalia = `dealers/table-list?vendor=${operatore}`;
        this.urlClassificaAgenti = `dealers/agent-table-list?vendor=${operatore}`;
        this.urlClassificaAreaManager = `dealers/master-agent-table-list?vendor=${operatore}`;
    }

    showFileUpload() {
        this.modalService.open(this.fileUploadModal, {size: 'sm'});
    }

    uploadFile($event: Event) {
        // @ts-ignore
        const file = $event.target.files.item(0);
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        this.busy = this.requestService.post(`eolo/partner/import`,
            formData
        ).subscribe((data: any) => {
            this.alertService.show('Operazione completata', 'File caricato con successo',   {classname: 'bg-success text-light', delay: 2000});
            this.modalService.dismissAll();
        }, err => {
            this.alertService.show('Errore', 'Errore nel caricamento del file',   {classname: 'bg-danger text-light', delay: 2000});
            this.modalService.dismissAll();
        });
    }
}
