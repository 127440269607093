import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '@app/models/user';
import * as moment from 'moment';
import { Calendar, CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { VisiteService } from '@app/services/visite.service';
import { AgentVisitsService } from '@app/services/agent-visits.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NegozioSelectComponent } from '@app/widgets/negozio-select/negozio-select.component';
import { AlertService } from '@app/services/alert.service';

@Component({
    selector: 'app-calendario-visite',
    templateUrl: './calendario-visite.component.html',
    styleUrls: ['./calendario-visite.component.scss'],
})
export class CalendarioVisiteComponent implements OnInit {
    @ViewChild('affiliatedNegozioSelect') affiliatedNegozioSelect: NegozioSelectComponent;
    private user: User;
    loadCalendar = false;
    currentDate = moment().format('YYYY-MM-DD');
    currentEvent: any;
    savingVisit = false;
    agentSelectId: any;
    @ViewChild('deleteSwal')
    public readonly deleteSwal!: SwalComponent;
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    events = [];
    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick.bind(this), // bind is important!
        eventClick: this.handleEventClick.bind(this),
        eventDrop: this.handleEventDrop.bind(this),
        events: this.events,
        locale: 'it',
        editable: true,
        eventDisplay: 'block',
        displayEventTime: false,
        customButtons: {
            next: {
                text: 'new',
                click: this.nextMonth.bind(this),
            },
            prev: {
                text: 'prev',
                click: this.prevMonth.bind(this),
            },
            today: {
                text: 'today',
                click: this.currentMonth.bind(this),
            },
        },
    };

    nameWithLocation: string = 'nameWithLocation';

    affiliatedForm: FormGroup;
    notAffiliatedForm: FormGroup;

    constructor(
        private spinner: NgxSpinnerService,
        public service: VisiteService,
        private agentVisitsService: AgentVisitsService,
        private toastr: ToastrService,
        private authService: AuthenticationService,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
    ) {
        this.authService.currentUser.subscribe((user) => {
            this.user = user;

            if (this.user.profile.role !== 'agent') {
                this.calendarOptions.editable = false;
                this.calendarOptions.eventClick = null;
                this.calendarOptions.eventDrop = null;
            }
        });
    }

    ngOnInit(): void {
        if (
            this.user &&
            (this.user.profile.role === 'operator' ||
                this.user.profile.role === 'master' ||
                this.user.profile.role === 'submaster' ||
                this.user.profile.role === 'masteragent')
        ) {
            this.loadCalendar = false;
        } else {
            this.loadCalendar = true;
            this.refresh();
        }

        this.affiliatedForm = this.formBuilder.group({
            id_dealer: [null, Validators.required],
            date: [null, Validators.required],
        });

        this.notAffiliatedForm = this.formBuilder.group({
            retailer: [null, Validators.required],
            retailer_kena: [null],
            date: [null, Validators.required],
        });
    }

    nextMonth(arg) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.next();
        this.currentEvent = arg.event;
        this.currentDate = moment(this.currentDate).add(1, 'M').format('YYYY-MM-DD');
        this.refresh();
    }

    prevMonth(arg) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.prev();
        this.currentEvent = arg.event;
        this.currentDate = moment(this.currentDate).add(-1, 'M').format('YYYY-MM-DD');
        this.refresh();
    }

    currentMonth(arg) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.today();
        this.currentEvent = arg.event;
        this.currentDate = moment().format('YYYY-MM-DD');
        this.refresh();
    }

    refresh() {
        this.spinner.show('calendar');
        const params = {};

        if (this.currentDate) {
            params['month'] = moment(this.currentDate).format('MM');
            params['year'] = moment(this.currentDate).format('YYYY');
        }
        this.service.salesAgentPlanning(this.agentSelectId, params).subscribe((data) => {
            this.events = data;
            this.calendarOptions.events = this.events;
            this.spinner.hide('calendar');
        });
    }

    handleEventDrop(arg) {
        this.spinner.show('calendar');
        const body = {
            datetime: moment(arg.event.start).format('YYYY-MM-DD'),
        };
        this.agentVisitsService.putSchedule(arg.event.id, body).subscribe(
            (data: any) => {
                this.spinner.hide('calendar');
                this.toastr.success(`Evento spostato con successo`, 'Notifica');
            },
            (err: any) => {
                this.refresh();
                this.toastr.error(`Si è verificato un'errore con lo spostamento`, 'Errore');
            },
        );
    }

    handleDateClick(arg) {
        console.log(arg);
    }

    handleEventClick(arg) {
        this.deleteSwal.fire();
        this.currentEvent = arg.event;
    }

    save(affiliated: boolean) {
        this.savingVisit = true;
        let payload = {};
        if (affiliated) {
            payload = {
                ...this.affiliatedForm.value,
            };
        } else if (!affiliated) {
            payload = {
                ...this.notAffiliatedForm.value,
            };
        }
        this.postSchedule(affiliated, payload);
    }

    postSchedule(affiliated: boolean, payload: {}) {
        this.agentVisitsService.postSchedule(payload).subscribe(
            (data) => {
                this.refresh();
                this.savingVisit = false;
                this.toastr.success('Visita salvata con successo!', 'Notifica');
            },
            (error) => {
                this.toastr.error(`Si è verificato un\'errore: ${error}`, 'Errore');
                this.savingVisit = false;
            },
        );
        this.negozioSelectReset(affiliated);
    }

    onAgentChange(agentId: any): void {
        this.agentSelectId = agentId;
        if (this.agentSelectId === null) {
            this.loadCalendar = false;
            this.refresh();
        } else if (!this.loadCalendar) {
            this.loadCalendar = true;
        }
        this.refresh();
    }

    onAffiliatedDealerChange(event: any) {
        this.affiliatedForm.controls['id_dealer'].setValue(event);
    }

    deleteEvent() {
        this.agentVisitsService.deleteSchedule(this.currentEvent.id).subscribe(
            (data: any) => {
                this.currentEvent.remove();
                this.toastr.success(`Evento eliminato con successo`, 'Notifica');
            },
            (err: any) => {
                this.toastr.error(`Si è verificato un'errore con l'eliminazione`, 'Errore');
            },
        );
    }

    negozioSelectReset(affiliated: boolean) {
        if (affiliated) {
            this.affiliatedForm.reset();
            this.affiliatedNegozioSelect.reset();
        } else {
            this.notAffiliatedForm.reset();
        }
    }
    delete() {}
}
