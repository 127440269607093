<div class="modal-body">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="spinner" [fullScreen]="false">
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-row">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label class="lead" translate>Anno</label>
          <input class="form-control" formControlName="year" />
          <val-errors controlName="year">
            <ng-template valError="required">
              <span translate>Year is required</span>
            </ng-template>
            <ng-template valError="pattern">
              <span translate>Year errors</span>
            </ng-template>
          </val-errors>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label class="lead">Scegli il file</label>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="validatedCustomFile" (change)="handleFileInput($event)" formControlName="file" accept=".zip" />
            <label class="custom-file-label" for="validatedCustomFile">{{ documentName !== '' ? documentName : 'Scegli il file' }}</label>
          </div>
          <val-errors controlName="file">
            <ng-template valError="required"> Il file è obbligatorio </ng-template>
          </val-errors>
        </div>
      </div>

      <div class="col-12 mt-auto">
        <label for=""></label>
        <button class="btn btn-info btn-block" type="submit" [disabled]="form.invalid"><i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span></button>
      </div>
    </div>
  </form>
</div>
