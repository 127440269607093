<div [ngBusy]="busy">
  <app-master-header [image]="'flats/settings.png' | assets" cardClass="bg-gradient-dark" title="Gestione Ticket">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per negozio</label>
          <app-retailer-typeahead
            (onChange)="this.filter.id_dealer = $event; this.refreshHandling()"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3" *show="['operator', 'master']">
        <div class="form-group">
          <label>Filtra per operatore</label>
          <app-staff-select (onChange)="this.filter.id_operatore = $event; this.refreshHandling()"
                            [model]="auth.currentUserValue.profile.id"></app-staff-select>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3" *show="['master']">
        <div class="form-group">
          <label>Filtra per categoria</label>
          <select (ngModelChange)="this.filter.subcategory = ''; this.refreshHandling()" [(ngModel)]="filter.category"
                  class="form-control">
            <option value="">Tutte le categorie</option>
            <option *ngFor="let cat of categories" [value]="cat.id">
              {{ cat.nome }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3" *show="['master']">
        <div class="form-group">
          <label>Filtra per sottocategoria</label>
          <select (ngModelChange)="this.refreshHandling()" [(ngModel)]="filter.subcategory"
                  [disabled]="this.filter.category === ''" class="form-control">
            <option value="">Tutte le sottocategorie</option>
            <ng-container *ngIf="this.filter.category !== ''">
              <option *ngFor="let cat of category(this.filter.category)" [value]="cat.id">
                {{ cat.nome }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per stato</label>
          <select (ngModelChange)="this.refreshHandling()" [(ngModel)]="filter.status" class="form-control">
            <option value="">Tutte gli stati</option>
            <option [value]="0">Aperto</option>
            <option [value]="1">Chiuso</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per contenuto</label>
          <input class="form-control" [(ngModel)]="filter.content" type="text" />
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per numero ticket</label>
          <input class="form-control" [(ngModel)]="filter.id" type="text" />
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>&nbsp;</label>
          <button class="btn btn-info" (click)="this.refreshHandling()">Filtra</button>
        </div>
      </div>
    </div>
  </app-master-header>

  <div class="row">
    <div *show="['master']" class="col-12 col-lg-3">
      <div class="card mg-theme full">
        <div class="card-header">
          <h1>Categorie</h1>
          <img [src]="'flats/folder.png' | assets" class="float-right" />
        </div>
        <div class="card-body p-0">
          <ul class="nav flex-column menu">
            <li *ngFor="let category of categories" class="nav-item">
              <a class="nav-link">
                <i class="fa fa-caret-right"></i> {{ category.nome }}
                <div class="float-right">
                  <ng-container *show="['master', 'operator']">
                    <button (click)="showCreateCategoryModal(category.id)"
                            class="btn btn-circle bg-gradient-success btn-circle-xs mr-2">
                      <i class="fas fa-plus"></i>
                    </button>
                    <button (click)="showUpdateCategoryModal(category); $event.stopPropagation()"
                            class="btn btn-circle bg-gradient-info btn-circle-xs mr-2">
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                    <button (confirm)="deleteCategory(category)" [swal]="categoryConfirmDeleteOptions"
                            class="btn btn-circle bg-gradient-danger btn-circle-xs mr-2">
                      <i class="fas fa-times"></i>
                    </button>
                  </ng-container>
                </div>
              </a>
              <ul *ngIf="category.subcategories.length > 0">
                <li *ngFor="let sub of category.subcategories" class="nav-item">
                  <a class="nav-link">
                    <i class="fa fa-caret-right"></i> {{ sub.nome }}
                    <div class="float-right">
                      <ng-container *show="['master', 'operator']">
                        <button (click)="showUpdateCategoryModal(sub); $event.stopPropagation()"
                                class="btn btn-circle bg-gradient-info btn-circle-xs mr-2">
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button (confirm)="deleteSubCategory(sub)" [swal]="categoryConfirmDeleteOptions"
                                class="btn btn-circle bg-gradient-danger btn-circle-xs mr-2">
                          <i class="fas fa-times"></i>
                        </button>
                      </ng-container>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div *show="['master', 'operator']" class="card-footer">
          <button (click)="showCreateCategoryModal()" class="btn btn-block btn-small bg-gradient-success">Aggiungi
            Categoria
          </button>
        </div>
      </div>
    </div>

    <div
      [ngClass]="{'col-md-12': this.auth.currentUserValue.profile.role !== 'master'}"
      class="col-12 col-md-9"
    >
      <div class="card bg-gradient-light">
        <div class="card-header">
          <h1 class="card-title">Ticket in attesa di gestione</h1>
        </div>
        <div class="card-body p-0">
          <ngx-spinner [fullScreen]="false" bdColor="rgba(0, 0, 0, 0.8)" color="#fff" name="table-to-handle"
                       size="default" type="square-jelly-box">
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th class="text-center"></th>
              <th class="text-center">#</th>
              <th>Dealer</th>
              <th class="text-center">Dealer pro</th>
              <th class="text-center">
                <img
                  src="assets/img/small_brands/{{toHandleList[0]?.retailer_info.media_kena.label}}.png"
                  class="mr-1"
                  height="20px"
                />
                </th>
              <th class="text-center">
                <img
                  src="assets/img/small_brands/{{toHandleList[0]?.retailer_info.media_a2a.label}}.png"
                  class="mr-1"
                  height="20px"
                />
              </th>
              <th>Categoria</th>
              <th>Subcategoria</th>
              <th class="text-center">Data</th>
              <th *show="['operator', 'master']" class="text-center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of toHandleList">
              <td class="text-center">
                <a (click)="showTicketModal(row)" class="link text-primary"><i class="fas fa-eye"></i></a>
              </td>
              <td class="text-center">{{ row.id }}</td>
              <td>{{ row.retailer }}</td>
              <td class="text-center">
                <ng-container *ngIf="row.retailer_info.is_dealer_pro == 1; else notPro">
                  <img class="bg-warning p-1 rounded-circle" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/pro/dealerpro_icon_white.svg" style="height: 20px;width: 20px;" alt="Dealer Pro Icon">
                </ng-container>
                <ng-template #notPro>
                  <p> - </p>
                </ng-template>
              </td>
              <td class="text-center">
               {{row.retailer_info.media_kena.value}}
              </td>
              <td class="text-center">
                {{row.retailer_info.media_a2a.value}}
              </td>
              <td>{{ row.category }}</td>
              <td>{{ row.subcategory }}</td>
              <td class="text-center">
                {{ row.created | amDateFormat : 'H:mm DD/MM/YYYY' }}
              </td>
              <td *show="['operator', 'master']" class="text-center">
                <button (click)="takeInHandle(row)" class="btn btn-primary btn-sm">Gestisci</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <span
          >Ci sono <b>{{ this.toHandlePagination.totalCount }}</b> ticket da gestire.</span
          >
          <ngb-pagination
            (pageChange)="this.toHandlePagination.page = $event; refreshToHandle()"
            [(page)]="toHandlePagination.page"
            [collectionSize]="toHandlePagination.totalCount"
            [maxSize]="10"
            [pageSize]="toHandlePagination.pageSize"
            size="sm"
          ></ngb-pagination>
        </div>
      </div>

      <div class="card bg-gradient-light">
        <div class="card-header">
          <h1 class="card-title">Ticket in gestione</h1>
        </div>
        <div class="card-body p-0">
          <ngx-spinner [fullScreen]="false" bdColor="rgba(0, 0, 0, 0.8)" color="#fff" name="table-handling"
                       size="default" type="square-jelly-box">
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th class="text-center"></th>
              <th class="text-center">#</th>
              <th>Dealer</th>
              <th class="text-center">Dealer pro</th>
              <th class="text-center">
                <img
                  src="assets/img/small_brands/{{handlingList[0]?.retailer_info.media_kena.label}}.png"
                  class="mr-1"
                  height="20px"
                />
              </th>
              <th class="text-center">
                <img
                  src="assets/img/small_brands/{{handlingList[0]?.retailer_info.media_a2a.label}}.png"
                  class="mr-1"
                  height="20px"
                />
              </th>
              <th>Operatore</th>
              <th>Categoria</th>
              <th>Subcategoria</th>
              <th class="text-center">Stato</th>
              <th class="text-center">Nuove Risposte</th>
              <th class="text-center">Data</th>
              <th class="text-center">Chiuso il</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of handlingList">
              <td class="text-center">
                <a (click)="showTicketModal(row)" class="link text-primary"><i class="fas fa-eye"></i></a>
              </td>
              <td class="text-center">{{ row.id }}</td>
              <td>{{ row.retailer }}</td>
              <td class="text-center">
                <ng-container *ngIf="row.retailer_info.is_dealer_pro == 1; else notPro">
                  <img class="bg-warning p-1 rounded-circle" src="https://kdistribution.s3.eu-central-1.amazonaws.com/svg/pro/dealerpro_icon_white.svg" style="height: 20px;width: 20px;" alt="Dealer Pro Icon">
                </ng-container>
                <ng-template #notPro>
                  <p> - </p>
                </ng-template>
              </td>
              <td class="text-center">
                {{row.retailer_info.media_kena.value}}
              </td>
              <td class="text-center">
                {{row.retailer_info.media_a2a.value}}
              </td>
              <td>{{ row.handler }}</td>
              <td>{{ row.category }}</td>
              <td>{{ row.subcategory }}</td>
              <td class="text-center">
                <span *ngIf="row.stato === 0" class="badge badge-success">Aperto</span>
                <span *ngIf="row.stato === 1" class="badge badge-danger">Chiuso</span>
              </td>
              <td class="text-center">
                <span *ngIf="row.new" class="badge badge-info">new</span>
              </td>
              <td class="text-center">
                {{ row.created | amDateFormat : 'HH:mm DD/MM/YYYY' }}
              </td>
              <td class="text-center">
                {{ row.handled_date | amDateFormat : 'HH:mm DD/MM/YYYY' }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer">
          <span
          >Ci sono <b>{{ this.handlingPagination.totalCount }}</b> ticket gestisti.</span
          >
          <ngb-pagination
            (pageChange)="this.handlingPagination.page = $event; refreshHandling()"
            [(page)]="handlingPagination.page"
            [collectionSize]="handlingPagination.totalCount"
            [maxSize]="10"
            [pageSize]="handlingPagination.pageSize"
            size="sm"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ticketTemplate let-modal>
  <div [ngBusy]="busy" class="modal-header">
    <h4 class="modal-title clamp-title">Ticket #{{ selectedTicket.id }}</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-spinner [fullScreen]="false" bdColor="rgba(0, 0, 0, 0.8)" color="#fff" name="ticket-modal" size="default"
                 type="square-jelly-box">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="row">
      <div class="col-4 border-right">
        <div class="box-profile">
          <div class="container">
            <div class="row">
              <div class="col-4">
                <div class="text-center">
                  <img alt="User profile picture" class="profile-user-img img-fluid img-circle"
                       src="/assets/img/flats/online-store.png" />
                </div>
              </div>
              <div class="col-8 d-flex flex-column">
                <h3 class="profile-username">
                  {{ selectedTicket.retailer }}
                </h3>
                <p class="text-muted">Ticket creato
                  il: {{ selectedTicket.created | amDateFormat : 'DD/MM/YYYY [alle] H:mm' }}</p>
              </div>
            </div>
          </div>
          <hr />
          <div class="container mt-2">
            <div class="row">
              <div class="col-12">
                <p class="text-muted mb-2">
                  Dealer Pro:
                  <i *ngIf="selectedTicket.retailer_info.is_dealer_pro == 1" class="fa fa-check-circle text-green"></i>
                  <i *ngIf="selectedTicket.retailer_info.is_dealer_pro == 0" class="fa fa-times-circle text-red"></i>
                </p>
              </div>
            </div>
          </div>
          <hr />
          <p class="mb-2">Dati vendite ultimi 3 mesi:</p>
          <div class="card">
            <div class="card-header bg-light text-left d-flex justify-content-start align-items-center">
              <div>
                <img src="assets/img/small_brands/kena.png" alt="Logo" style="max-height: 20px; margin-right: 0.5em" />
              </div>
              <div>
                <strong>MNP Kena Mobile</strong>
              </div>
            </div>
            <div class="card-body p-4 d-flex justify-content-start align-items-center">
              <ul *ngIf="selectedTicket.retailer_info.is_kena == 1" class="list-inline mb-0 text-muted">
                <li *ngFor="let item of kenaMonthData" class="list-inline-item text-muted">
                  <em>{{ item.month }}: </em> <strong>{{ item.value }}</strong>
                </li>
              </ul>
              <div *ngIf="selectedTicket.retailer_info.is_kena == 0">
                <p class="text-muted font-italic">Non Affiliato KENA</p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header bg-light text-left d-flex justify-content-start align-items-center">
              <div>
                <img src="assets/img/small_brands/a2a.png" alt="Logo" style="max-height: 20px; margin-right: 0.5em" />
              </div>
              <div>
                <strong>Contratti A2A </strong>
              </div>
            </div>
            <div class="card-body p-4 d-flex justify-content-start align-items-center">
              <ul *ngIf="selectedTicket.retailer_info.is_a2a == 1" class="list-inline text-muted mb-0">
                <li *ngFor="let item of a2aMonthData" class="list-inline-item text-muted">
                  <em>{{ item.month }}: </em> <strong>{{ item.value }}</strong>
                </li>
              </ul>
              <div *ngIf="selectedTicket.retailer_info.is_a2a == 0">
                <p class="text-muted font-italic">Non Affiliato A2A</p>
              </div>
            </div>
          </div>

          <p class="mt-1">
            <a (click)="goToDealerDetails(selectedTicket.id_dealer)" class="text-primary text-decoration-underline">Vedi
              più dettagli ></a>
          </p>

          <hr />
          <strong><i class="fas fa-box mr-1"></i> Categoria</strong>
          <p class="text-muted">
            {{ selectedTicket.category }}
          </p>
          <hr />
          <strong><i class="fas fa-boxes mr-1"></i> Subcategoria</strong>
          <p class="text-muted">
            {{ selectedTicket.subcategory }}
          </p>
          <hr />
          <strong><i class="fas fa-user mr-1"></i> Operatore</strong>
          <app-staff-select
            (onChange)="setStaffOperatorOnTicket(selectedTicket, $event)"
            [disabled]="!selectedTicket.id_operatore"
            [model]="selectedTicket.id_operatore"
            [small]="true"
          >
          </app-staff-select>
          <hr />
          <strong><i class="fas fa-user mr-1"></i> Stato</strong>
          <select (ngModelChange)="setStatusOnTicket(selectedTicket, $event)" [(ngModel)]="selectedTicket.stato"
                  class="form-control form-control-sm">
            <option value="0">Aperto</option>
            <option value="1">Chiuso</option>
          </select>
          <hr />
          <strong><i class="fas fa-folder-open mr-1"></i> Allegati</strong>
          <p *ngIf="selectedTicket.attachments.length === 0" class="text-muted">Non ci sono allegati.</p>
          <ng-container *ngFor="let attachment of selectedTicket.attachments">
            <p>
              <a [href]="attachment.doc_path"><i class="fa fa-file"> </i>{{ attachment.filename }}</a>
            </p>
            <hr />
          </ng-container>

          <form *ngIf="showUpload && selectedTicket.id_operatore" [formGroup]="attachmentForm">
            <div class="form-group">
              <label class="lead">Scegli l'allegato</label>
              <div class="custom-file">
                <input (change)="handleFileInput($event.target.files)" class="custom-file-input"
                       formControlName="ticketfile" id="validatedCustomFile" type="file" />
                <label class="custom-file-label" for="validatedCustomFile">{{ fileName }}</label>
              </div>
            </div>
          </form>

          <button (click)="newAttachment(selectedTicket)"
                  [disabled]="!selectedTicket.id_operatore || selectedTicket.stato === 1"
                  class="btn btn-primary btn-sm btn-block mt-2">
            Aggiungi Allegato
          </button>
        </div>
      </div>
      <div class="col-8">
        <div class="direct-chat direct-chat-primary">
          <div class="direct-chat-messages" style="height: auto">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-name float-left">{{ selectedTicket.retailer }}</span>
                <span
                  class="direct-chat-timestamp float-right">{{ selectedTicket.created | amDateFormat : 'H:mm DD/MM/YYYY' }}</span>
              </div>
              <!-- /.direct-chat-infos -->
              <img alt="message user image" class="direct-chat-img" src="/assets/img/flats/online-store.png" />
              <!-- /.direct-chat-img -->
              <div [innerHTML]="selectedTicket.testo | safeHtml" class="direct-chat-text"></div>
              <!-- /.direct-chat-text -->
            </div>
            <div
              *ngFor="let reply of selectedTicket.replies"
              [ngClass]="{
                right: reply.id_utente !== selectedTicket.id_dealer
              }"
              class="direct-chat-msg"
            >
              <div class="direct-chat-infos clearfix">
                <span
                  [ngClass]="{
                    'float-right': reply.id_utente !== selectedTicket.id_dealer,
                    'float-left': reply.id_utente === selectedTicket.id_dealer
                  }"
                  class="direct-chat-name"
                >{{ reply.creator }}</span
                >
                <span
                  [ngClass]="{
                    'float-left': reply.id_utente !== selectedTicket.id_dealer,
                    'float-right': reply.id_utente === selectedTicket.id_dealer
                  }"
                  class="direct-chat-timestamp"
                >{{ reply.created | amDateFormat : 'H:mm DD/MM/YYYY' }}</span
                >
              </div>
              <!-- /.direct-chat-infos -->
              <img *ngIf="reply.id_utente !== selectedTicket.id_dealer" alt="message user image" class="direct-chat-img"
                   src="/assets/img/flats/support.png" />
              <img *ngIf="reply.id_utente === selectedTicket.id_dealer" alt="message user image" class="direct-chat-img"
                   src="/assets/img/flats/online-store.png" />
              <!-- /.direct-chat-img -->
              <div [innerHTML]="reply.risposta | safeHtml" class="direct-chat-text"></div>
              <!-- /.direct-chat-text -->
            </div>
          </div>
        </div>
        <hr />
        <textarea
          [(ngModel)]="selectedReply.risposta"
          [disabled]="!selectedTicket.id_operatore || selectedTicket.stato === 1"
          class="form-control form-control-sm"
          placeholder="Scrivi qui la tua risposta"
          rows="5"
        ></textarea>
        <button (click)="saveReply(selectedReply)" [disabled]="!selectedReply.risposta"
                class="btn btn-sm float-right btn-primary mt-2">Invia risposta
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #categoryFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">{{ categoryModalTitle }}</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="categoryForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="lead">Nome</label>
            <input [(ngModel)]="selectedCategory.nome" class="form-control" formControlName="nome" type="text" />
            <val-errors controlName="nome">
              <ng-template valError="required"> Il nome della categoria è obbligatorio</ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="lead">Visibile solo se abilitato con questo brand</label>
            <input [(ngModel)]="selectedCategory.id_vendor" class="form-control" formControlName="id_vendor"
                   type="text" />
          </div>
        </div>
        <div class="col-12 mt-3">
          <button (click)="saveCategory(selectedCategory)" [disabled]="!this.categoryForm.valid"
                  class="btn btn-success btn-block">
            <i class="fa fa-check-circle mr-2"></i>Salva
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
