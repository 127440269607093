import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MetaPagination } from '@app/models/meta-pagination';
import { User } from '@app/models/user';
import { WhatsappSubscription } from '@app/models/whatsapp-generator';
import { AuthenticationService } from '@app/services/authentication.service';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { RequestService } from '@app/services/request.service';
import { WhatsappGeneratorService } from '@app/services/whatsapp-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-whatsapp-detail',
    templateUrl: './whatsapp-detail.component.html',
})
export class WhatsappDetailComponent implements OnInit {
    @ViewChild('assegnaWaTemplate') private assegnaWaTemplate;
    deleteSubscriptionOptions: ConfirmOptionsService;
    isDealer = false;
    waId: number;
    role: { am: boolean; ka: boolean; dc: boolean };
    form: FormGroup;
    filter = {
        agent_id: '',
        masteragent_id: '',
    };
    selectAllSubMaster: boolean = false;
    selectAllMasterAgent: boolean = false;
    selectAllAgent: boolean = false;
    selected: false;
    selectedItems: { id: number; number: string }[] = [];
    enableSaveButton: boolean = false;
    popupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null,
    };
    selectedOperator: number;
    operator = [
        { id: 16, name: 'A2A' },
        { id: 6, name: 'KENA' },
        { id: 11, name: 'TIM' },
    ];
    subMasterGroup: any;
    masterAgentGroup: any;
    agentGroup: any;
    listDetail: any;
    isEdit = true;
    ordersPagination: MetaPagination;
    sendType: string;

    constructor(
        private route: ActivatedRoute,
        public request: RequestService,
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        public formBuilder: FormBuilder,
        public service: WhatsappGeneratorService,
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.isDealer = user && user.profile.role === 'dealer';
        });
        this.deleteSubscriptionOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla',
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.waId = params['id'];
            this.sendType = params['sendType'];
            this.role = {
                am: params['am'] == 'true' ? true : false,
                ka: params['ka'] == 'true' ? true : false,
                dc: params['dc'] == 'true' ? true : false,
            };
            this.loadListDetail();
        });

        this.form = this.formBuilder.group({});
    }

    showModal() {
        this.modalService.open(this.assegnaWaTemplate, { size: 'xl', scrollable: true });
    }

    loadListDetail() {
        this.spinner.show('table-orders');

        const params = {
            waId: this.waId,
        };

        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        this.service.getWaSubscription(params).subscribe(
            (response: any) => {
                this.listDetail = new WhatsappSubscription().fromArray(response.data);
                this.ordersPagination = response._meta;
                this.spinner.hide('table-orders');
            },
            (err) => {
                console.log('Errore nel caricamento dei dati: ', err);
                this.spinner.hide('table-orders');
            },
        );
    }

    save() {
        const payload = {
            wa_id: this.waId,
            dealers: this.selectedItems.map((item) => ({ id: item.id, number: item.number })),
        };

        this.service.postWaAssigned(payload).subscribe(
            (response) => {
                this.service.successAlert('Assegnato correttamente');
                this.dismissModal();
            },
            (err) => {
                this.service.errorAlert(err.message);
                this.dismissModal();
            },
        );
        this.loadListDetail();
    }

    loadAmSelect() {
        this.spinner.show('table-orders');

        const params = {
            id: this.waId,
        };

        if (this.filter.masteragent_id) {
            params['masteragent_id'] = this.filter.masteragent_id;
        }

        if (this.selectedOperator) {
            params['operator_id'] = this.selectedOperator;
        }

        this.service.getAmSelect(params).subscribe(
            (response: any) => {
                this.masterAgentGroup = response;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    loadKaSelect() {
        this.spinner.show('table-orders');

        const params = {
            id: this.waId,
        };

        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }

        if (this.selectedOperator) {
            params['operator_id'] = this.selectedOperator;
        }

        this.service.getkaSelect(params).subscribe(
            (response: any) => {
                this.agentGroup = response;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    loadDcSelect() {
        this.spinner.show('table-orders');

        this.service.getDcSelect().subscribe(
            (response: any) => {
                this.subMasterGroup = response;

                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    delete(id: number) {
        this.service.deleteWaAssigned(id).subscribe(
            () => {
                const index = this.listDetail.findIndex((dealer) => dealer.id === id);
                if (index > -1) {
                    this.service.successAlert('Deleted Service!');
                    this.loadListDetail();
                }
            },
            (err) => {
                this.service.errorAlert(err.message);
            },
        );
    }

    openPopover(popOver: any, itemId, item, key, selectItems = []) {
        this.popupConfig.inputValue = item;
        this.popupConfig.id = itemId;
        this.popupConfig.key = key;
        this.popupConfig.popover = popOver;
        this.popupConfig.isSelect = selectItems?.length > 0;
        this.popupConfig.items = selectItems;
        popOver.open();
    }

    update(value) {
        const body = {};
        body[this.popupConfig['key']] = value;
        this.spinner.show('popupSpinner');
        return this.changeWa(this.popupConfig.id, body);
    }

    changeWa(itemId, body) {
        this.service.putWhatsapp(itemId, body).subscribe(
            (response) => {
                if (response) {
                    this.spinner.hide('popupSpinner');
                }
            },
            (err) => {
                console.log("Errore nell'update: ", err.message);
                this.modalService.dismissAll();
                this.spinner.hide('popupSpinner');
            },
        );
        this.loadListDetail();
    }

    dismissModal() {
        this.modalService.dismissAll();
        this.loadListDetail();
        this.selectedItems = [];
    }

    onOperatorChange(event: any): void {
        if (event) {
            this.selectedOperator = event.id;
        }
        this.loadAmSelect();
        this.loadKaSelect();
        this.loadDcSelect();
    }

    toggleSelection(target: any, id: number, number: string, type: 'master' | 'agent' | 'sub'): void {
        const isChecked = target.checked;
        this.updateSelectedItems(id, number, isChecked, type);

        if (type === 'master') {
            const agent = this.masterAgentGroup.find((agent) => agent.id === id);
            if (agent) {
                agent.am_checked = isChecked;
            }
        } else if (type === 'agent') {
            const agent = this.agentGroup.find((agent) => agent.id === id);
            if (agent) {
                agent.ka_checked = isChecked;
            }
        } else if (type === 'sub') {
            const sub = this.subMasterGroup.find((sub) => sub.id === id);
            if (sub) {
                sub.dc_checked = isChecked;
            }
        }

        this.enableSaveButton = this.selectedItems.length > 0;
    }

    updateSelectedItems(id: number, number: string, isSelected: boolean, type: 'master' | 'agent' | 'sub'): void {
        const index = this.selectedItems.findIndex((item) => item.id === id);

        if (isSelected) {
            if (index === -1) {
                this.selectedItems.push({ id: id, number: number });
            }
        } else {
            if (index !== -1) {
                this.selectedItems.splice(index, 1);
            }
        }
        this.enableSaveButton = this.selectedItems.length > 0;
    }

    onMasterChange(id: any): void {
        this.filter.masteragent_id = id;
        this.loadListDetail();
        this.loadAmSelect();
    }

    selectAllMasterAgents(event: any): void {
        const isChecked = event.target.checked;
        this.selectAllMasterAgent = isChecked;

        this.masterAgentGroup.forEach((agent) => {
            agent.am_checked = isChecked;
            this.updateSelectedItems(agent.id, agent.number, isChecked, 'master');
        });

        this.enableSaveButton = this.selectedItems.length > 0;
    }

    onAgentChange(agentId: any): void {
        this.filter.agent_id = agentId;
        this.loadListDetail();
        this.loadKaSelect();
    }

    selectAllAgents(event: any): void {
        const isChecked = event.target.checked;
        this.selectAllAgent = isChecked;

        this.agentGroup.forEach((agent) => {
            agent.ka_checked = isChecked;
            this.updateSelectedItems(agent.id, agent.number, isChecked, 'agent');
        });

        this.enableSaveButton = this.selectedItems.length > 0;
    }
}
