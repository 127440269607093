import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Kpi } from '@app/models/kpi';
import { MetaPagination } from '@app/models/meta-pagination';
import { AlertService } from '@app/services/alert.service';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';
import { KpiService } from '@app/services/kpi.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-kpi-type',
    templateUrl: './kpi-type.component.html',
    styleUrls: ['./kpi-type.component.css'],
})
export class KpiTypeComponent implements OnInit {
    isCollapsed: boolean = true;
    pickerOptions = {
        singleDatePicker: false,
        ranges: {
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 6 Months': [moment().subtract(6, 'month'), moment()],
            'Last Year': [moment().subtract(12, 'month'), moment()],
        },
    };

    slugList: [] = [];
    categoryList: [] = [];

    ordersPagination: MetaPagination = new MetaPagination();
    @ViewChild('detailTemplate') private detailTemplate;
    isImageSelected: boolean = false;
    selectedFileName: string = '';
    private imageFile: File;
    isLoading: boolean = false;
    kpiList: Kpi[] = [];
    selectedData: Kpi;
    datiForm: FormGroup;
    editForm: FormGroup;
    isEdit = true;
    tinyMceConfig = {
        base_url: '/tinymce',
        suffix: '.min',
        plugins: [
            'lists',
            'advlist',
            'autolink',
            'link',
            'image',
            'charmap',
            'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'paste',
            'code',
            'help',
            'wordcount',
        ],
        toolbar: 'undo redo | formatselect | ' + 'bold italic backcolor | alignleft aligncenter ' + 'alignright alignjustify | bullist numlist outdent indent | ' + 'removeformat | help',
    };

    get deleteSubscriptionOptions() {
        return new ConfirmOptionsService({
            title: 'Elimina KPI',
            text: 'Sei sicuro di voler rimuovere questo KPI ?',
        });
    }

    filters = {
        title: null,
        slug: null,
        date: null,
        category: null,
    };

    apiDate = {
        from: '',
        to: '',
    };

    constructor(
        private spinner: NgxSpinnerService,
        private kpiService: KpiService,
        public route: ActivatedRoute,
        public modalService: NgbModal,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private dateRangePickerOptions: DaterangepickerConfig,
    ) {
        this.datiForm = this.formBuilder.group({
            title: ['', Validators.required],
            description: [''],
            category: ['', Validators.required],
            notes: [''],
            image: [null],
            slug: ['', Validators.required],
        });
        this.editForm = this.formBuilder.group({
            editTitle: ['', Validators.required],
            editDescription: [''],
            editCategory: ['', Validators.required],
            editNotes: [''],
            editImage: [''],
            editSlug: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.loadKpiList();
        this.kpiService.getKpiSlugList().subscribe((res) => {
            this.slugList = res;
        });

        this.kpiService.getKpiCategoryList().subscribe((res) => {
            this.categoryList = res;
        });
    }

    salvaDati() {
        this.spinner.show('saveButtonSpinner');
        this.spinner.show();
        this.isLoading = true;

        const formData = new FormData();
        formData.append('title', this.datiForm.get('title').value);
        formData.append('description', this.datiForm.get('description').value);
        formData.append('category', this.datiForm.get('category').value);
        formData.append('notes', this.datiForm.get('notes').value);
        formData.append('slug', this.datiForm.get('slug').value);
        formData.append('file', this.imageFile);

        this.kpiService
            .postKpiTypeList(formData)
            .subscribe(
                (response: any) => {
                    this.loadKpiList();
                    this.kpiService.successKpiAlert('con successo!');
                    this.spinner.hide();
                    this.isLoading = false;
                    this.datiForm.reset();
                    this.datiForm.get('image').setValue(null);
                },
                (error) => {
                    this.alertService.show('Attenzione', error, { classname: 'bg-danger text-light', delay: 4000 });
                    // console.error('Errore durante il salvataggio dei dati:', error);
                    this.spinner.hide();
                    this.isLoading = false;
                },
            )
            .add(() => {
                this.spinner.hide('saveButtonSpinner');
            });
    }

    loadKpiList() {
        this.spinner.show('table-orders');

        const params = {};
        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        if (this.filters.title) {
            params['title'] = this.filters.title;
        }

        if (this.filters.slug) {
            params['slug'] = this.filters.slug;
        }

        if (this.filters.category) {
            params['category'] = this.filters.category;
        }

        if (this.filters.date) {
            params['from_date'] = this.apiDate.from;
            params['to_date'] = this.apiDate.to;
        }

        this.kpiService.getKpiTypeList(params).subscribe(
            (response: any) => {
                this.kpiList = new Kpi().fromArray(response.data);
                this.ordersPagination = response._meta;
                this.spinner.hide('table-orders');
            },
            (err) => {
                this.spinner.hide('table-orders');
            },
        );
    }

    deleteKpi(id: number) {
        this.kpiService.deleteKpiItem(id).subscribe(
            () => {
                const index = this.kpiList.findIndex((dealer) => dealer.id === id);
                if (index > -1) {
                    this.kpiService.successAlert('con successo!');
                    this.loadKpiList();
                }
            },
            (err) => {
                this.kpiService.errorAlert(err.message);
            },
        );
    }

    showDetailModal(data?: any) {
        this.editForm.patchValue({
            editTitle: data?.title || '',
            editDescription: data?.description || '',
            editNotes: data?.notes || '',
            editSlug: data?.slug || '',
            editCategory: data?.category || '',
            // editImage: data?.image || '',
        });

        this.selectedData = data;
        this.modalService.open(this.detailTemplate, { size: 'md', scrollable: true });
    }

    updateKpi(event: Event) {
        event.preventDefault();
        if (this.editForm.valid) {
            const formData = new FormData();
            formData.append('title', this.editForm.get('editTitle').value);
            formData.append('description', this.editForm.get('editDescription').value);
            formData.append('category', this.editForm.get('editCategory').value);
            formData.append('notes', this.editForm.get('editNotes').value);
            formData.append('slug', this.editForm.get('editSlug').value);
            formData.append('file', this.imageFile);

            this.kpiService.putKpiTypeList(this.selectedData.id, formData).subscribe(
                (response) => {
                    this.editForm.reset();
                    this.imageFile = null;
                    this.loadKpiList();
                    this.modalService.dismissAll();
                    this.kpiService.successKpiUpdateAlert('con successo!');
                },
                (error) => {
                    this.modalService.dismissAll();
                    this.kpiService.errorAlert();
                    console.error("Errore durante l'aggiornamento:", error);
                },
            );
        }
    }

    onFileChange(event: any) {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            this.selectedFileName = file.name;
            this.imageFile = file;
        } else {
            this.datiForm.get('image').setErrors(null);
        }
    }

    toggleCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filters.date = startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }
}
