import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrdersControl, ShopOrdini } from '@app/models/orders-control';
import { OrdersControlService } from '@app/services/orders-control.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-orders-control-omaggio',
  templateUrl: './orders-control-omaggio.component.html',
  styleUrls: ['./orders-control-omaggio.component.css']
})
export class OrdersControlOmaggioComponent implements OnInit {

  @Input() filterParams : any;
  @Input() currentDate : any;
  @Output() updateCompleted: EventEmitter<void> = new EventEmitter<void>();

  reportControlOrders: OrdersControl[]=[];
  
  status = [
    {
      label: 'Da Saldare',
      key: "0"
    },
    {
      label: 'Saldato',
      key: "1"
    }
  ] 

  selectedOrder: ShopOrdini;
  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null
  };


  constructor(
    private ordersService: OrdersControlService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.loadOrders()
    setTimeout(() => {
    });
  }

  public loadOrders(hideSpinner = false){
    this.spinner.show('table-orders');
    const params = {};

    if (this.filterParams.dealer_id) {
      params['dealer_id'] = this.filterParams.dealer_id;
    }
    if (this.filterParams.date) {
      params['from_date'] = this.currentDate.from;
      params['to_date'] = this.currentDate.to;
    }

    if (this.filterParams.saldato) {
      params['saldato'] = this.filterParams.saldato;
    }

    if (this.filterParams.pagamento !== undefined) {
      params['pagamento'] = this.filterParams.pagamento;
    }

  
    this.ordersService.getReportOrdersControlOmaggio(params).subscribe((response: any) => {
      this.reportControlOrders = response;
      this.spinner.hide('table-orders');
    }, err => {
      this.spinner.hide('table-orders');
      console.log("errore");
    }); 
  }

  openPopover(popOver: any, orderId, item, key, selectItems = []) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }

  save(value) {
    const body = {};
    body[this.popupConfig['key']] = value;
    this.spinner.show('popupSpinner');
    return this.changeOrder(this.popupConfig.id, body);
  }

  changeOrder(orderId, body) {
    this.ordersService.putReportStatus(orderId, body).subscribe((response) => {
        this.loadOrders(true);
        this.spinner.hide('popupSpinner');
        this.updateCompleted.emit();
    }, err => {
        this.loadOrders(true);
        this.modalService.dismissAll();
        this.spinner.hide('popupSpinner');
    });
  }
}
