import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

  export class KdcubeService {
  
    constructor(private request: RequestService, public alertService: AlertService) { }
  
    public getExpirationList(params?) {
        return this.request.get('/shop-access/expiration-list/', params );
    }

      
    public getExpiredList(params?) {
      return this.request.get('/shop-access/expired-list/', params );
    }

}  