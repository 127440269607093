import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalConfig, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EPayService} from '@services/e-pay.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ShopProduct, ShopProductFile, ShopProductPagination, ShopProductVariant} from '@models/e-pay/shop-product';
import {MetaPagination} from '@models/meta-pagination';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {ShopBrand, ShopBrandPagination} from '@models/e-pay/shop-brand';
import {ShopProductCategory, ShopProductCategoryPagination} from '@models/e-pay/shop-product-category';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BankTransactionFile} from '@models/invoice/bank-transaction';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
    productModal: NgbModalRef;
    @ViewChild('productFormTemplate') private productFormTemplate;
    productDetailModal: NgbModalRef;
    @ViewChild('productDetailTemplate') private productDetailTemplate;
    @ViewChild('fileFormTemplate') private fileFormTemplate;
    products: ShopProduct[] = [];
    productsPagination: MetaPagination;
    currency = EPayService.currency;
    selectedProduct: ShopProduct;
    deleteProductOptions: ConfirmOptionsService;
    filter = {
        brand: '',
        category: '',
        name: ''
    };
    fileForm = new FormGroup({
        file: new FormControl('', Validators.required)
    });
    fileBusy: any;
    submittedFile: File;
    categories: ShopProductCategory[] = [];
    brands: ShopBrand[] = [];
    brandsLoading = false;
    categoriesLoading = false;
    unmatchedProducts = [];
    isUploadedFile = false;

    constructor(
        public modalService: NgbModal,
        private ePayService: EPayService,
        private spinner: NgxSpinnerService,
        config: NgbModalConfig) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit(): void {
        this.loadProduct();
        this.loadBrands();
        this.loadCategories();
        this.deleteProductOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    showModal(product?: ShopProduct) {
        this.selectedProduct = product;
        this.productModal = this.modalService.open(this.productFormTemplate, {size: 'lg'});
        this.productModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showDetailModal(product?: ShopProduct) {
        this.selectedProduct = product;
        this.productDetailModal = this.modalService.open(this.productDetailTemplate, {size: 'lg'});
        this.productDetailModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    loadProduct() {
        this.spinner.show('table-products');
        const params = {};
        if (this.productsPagination?.currentPage) {
            params['page'] = this.productsPagination?.currentPage;
        }
        if (this.filter.brand) {
            params['brand_id'] = this.filter.brand;
        }
        if (this.filter.category) {
            params['category_id'] = this.filter.category;
        }
        if (this.filter.name) {
            params['name'] = this.filter.name;
        }
        params['expand'] = 'product_prices';
        this.ePayService.getProducts(params).subscribe((response: ShopProductPagination) => {
            this.products = response.data;
            this.productsPagination = response._meta;
            this.spinner.hide('table-products');
        }, err => {
            this.spinner.hide('table-products');
        });
    }

    deleteProduct(id: number) {
        this.ePayService.deleteProduct(id).subscribe(() => {
            const index = this.products.findIndex(brand => brand.id === id);
            if (index > -1) {
                this.products.splice(index, 1);
                this.ePayService.successAlert('Deleted Product!');
            }
        }, (err) => {
            this.ePayService.errorAlert(err.message);
        });
    }

    changeStatus(product: ShopProduct) {
        product.is_active = product.is_active ? '1' : '0';
        this.ePayService.changeProductStatus(product.id, product).subscribe((response: ShopProduct) => {
            product.is_active = Number(response.is_active);
            this.ePayService.successAlert('Changed Status!');
        }, err => {
            this.ePayService.errorAlert(err.message);
        });
    }

    loadBrands(term?) {
        this.brandsLoading = true;
        const param = {};
        if (term) {
            param['name'] = term;
        }
        this.ePayService.getBrands(param).subscribe((response: ShopBrandPagination) => {
            this.brands = response.data;
            this.brandsLoading = false;
        }, err => {
            this.brandsLoading = false;
        });
    }

    loadCategories(term?) {
        this.categoriesLoading = true;
        const param = {};
        if (term) {
            param['name'] = term;
        }
        this.ePayService.getProductCategories().subscribe((response: ShopProductCategoryPagination) => {
            this.categories = response.data;
            this.categoriesLoading = false;
        }, err => {
            this.categoriesLoading = false;
        });
    }

    showFileModal() {
        this.modalService.open(this.fileFormTemplate, {size: 'lg', scrollable: true});
    }

    handleFileInput(file: File) {
        this.submittedFile = file;
        this.fileForm.get('file').setValue(file.name);
    }

    handleFileRemoveModal() {
        this.fileForm.get('file').setValue('');
        this.submittedFile = null;
    }

    saveFile() {
        const shopProductFile = new ShopProductFile();
        shopProductFile.file = this.submittedFile;
        this.fileBusy = this.ePayService.postProductsFile(shopProductFile).subscribe((response) => {
            if (response && response?.length > 0) {
                this.unmatchedProducts = response;
            }
            this.isUploadedFile = true;
            this.ePayService.successAlert('Uploaded File!');
            this.loadProduct();
        }, err => {
            this.isUploadedFile = false;
            this.ePayService.errorAlert(err);
            this.dismissModal();
        });
    }

    refreshList(event) {
        return event ? this.loadProduct() : null;
    }

    dismissModal() {
        this.isUploadedFile = false;
        this.unmatchedProducts = [];
        this.handleFileRemoveModal();
        this.fileForm.get('file').setValue('');
        this.modalService.dismissAll();
    }

    get file() {
        return this.fileForm.get('file').value;
    }
}
