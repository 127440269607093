import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {PbxCampaign} from '@models/pbx/pbx-campaign';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PbxQuestion, PbxQuestionCategory, PbxQuestionListPagination, PbxQuestionType} from '@models/pbx/pbx-question';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-pbx-campaign-modal',
    templateUrl: './pbx-campaign-modal.component.html',
    styleUrls: ['./pbx-campaign-modal.component.css']
})
export class PbxCampaignModalComponent implements OnInit {
    @Input() dealerIds = [];
    @Input() filters;
    @Input() tableUrl;
    @Output() createdCampaign = new EventEmitter<boolean>();
    dealersCount: number;
    busy: any;
    questionBusy: any;
    questions: PbxQuestion[] = [];
    questionModal: NgbModalRef;
    questionTypes: PbxQuestionType[] = PbxCampaignService.questionTypes;
    questionCategories: PbxQuestionCategory[] = PbxCampaignService.questionCategories;
    @ViewChild('questionFormTemplate') private questionFormTemplate;
    campaignForm = new FormGroup({
        name: new FormControl('', Validators.required),
        description: new FormControl(''),
        date_range: new FormControl('', Validators.required),
        start_date: new FormControl('', Validators.required),
        end_date: new FormControl('', Validators.required),
        dealerIds: new FormControl('', Validators.required),
        questions: new FormControl([], Validators.required),
    });
    questionForm = new FormGroup({
        title: new FormControl('', Validators.required),
        category: new FormControl('', Validators.required),
        text: new FormControl('', Validators.required),
        question_type: new FormControl('', Validators.required)
    });
    defaultConfig = new TinyMCE();
    questionsLoading = false;
    constructor(
        private dateRangePickerConfig: DaterangepickerConfig,
        private pbxCampaignService: PbxCampaignService,
        public modalService: NgbModal) {
        this.loadQuestions();
    }

    ngOnInit(): void {
        this.campaignForm.get('dealerIds').setValue(this.dealerIds);
        this.dealersCount = this.dealerIds.length >= 0 && this.dealerIds[0] === 'all' ? this.filters.totalCount : this.dealerIds.length;
    }

    setDateRange(start: any, end: any) {
        const from = moment(start).format(this.dateRangePickerConfig.settings.locale.format);
        const to = moment(end).format(this.dateRangePickerConfig.settings.locale.format);
        const startDate = moment(start).format(this.dateRangePickerConfig.settings.locale.apiFormat);
        const endDate = moment(end).format(this.dateRangePickerConfig.settings.locale.apiFormat);
        const dateRange = from + this.dateRangePickerConfig.settings.locale.separator + to;
        this.campaignForm.get('date_range').setValue(dateRange);
        this.campaignForm.get('start_date').setValue(startDate);
        this.campaignForm.get('end_date').setValue(endDate);
    }

    createCampaign() {
        if (this.campaignForm.invalid) {
            return;
        }
        const campaign = new PbxCampaign();
        campaign.name = this.campaignForm.value.name;
        campaign.description = this.campaignForm.value.description;
        campaign.start_date = this.campaignForm.value.start_date;
        campaign.end_date = this.campaignForm.value.end_date;
        campaign.dealerIds = this.campaignForm.value.dealerIds;
        if(this.tableUrl) {
            campaign.table_url = this.tableUrl;
        }
        campaign.questionIds = [];
        this.selectedQuestions.forEach(data => {
            campaign.questionIds.push(data.id);
        });
        if(this.filters) {
            delete this.filters.totalCount;
        }
        this.busy = this.pbxCampaignService.postCampaign(campaign, this.filters).subscribe((response: PbxCampaign) => {
                this.createdCampaign.emit(true);
                this.pbxCampaignService.successAlert('Created Campaign!');
                this.modalService.dismissAll();
            },
            err => {
                this.pbxCampaignService.errorAlert(err.message);
                this.modalService.dismissAll();
            }
        );
    }

    createQuestion() {
        if (this.questionForm.invalid) {
            return;
        }
        const modal = new PbxQuestion();
        modal.title = this.questionForm.value.title;
        modal.category = this.questionForm.value.category;
        modal.question_type = this.questionForm.value.question_type;
        modal.text = this.questionForm.value.text;
        this.questionBusy = this.pbxCampaignService.postQuestion(modal).subscribe((response: PbxQuestion) => {
            this.questions.unshift(response);
            this.questions = this.questions.slice(0);
            const list = this.selectedQuestions;
            list.unshift(response);
            this.campaignForm.get('questions').setValue(list);
            this.pbxCampaignService.successAlert('Created Question!');
            this.emptyForm();
            this.questionModal.dismiss();
        }, err => {
            this.pbxCampaignService.errorAlert(err.message);
            this.emptyForm();
            this.questionModal.dismiss();
        });
    }

    emptyForm() {
        this.questionForm.setValue({
            title: '',
            category: '',
            text: '',
            question_type: ''
        });
    }

    showCreateQuestionModal() {
        this.questionModal = this.modalService.open(this.questionFormTemplate, {size: 'lg'});
        this.questionModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    loadQuestions(term?: string) {
        let param;
        if (term) {
            param = {title: term};
        }
        this.questionsLoading = true;
        this.pbxCampaignService.getQuestions(param).subscribe((response: PbxQuestionListPagination) => {
            this.questions = response.data;
            this.questionsLoading = false;
        }, err => {
            this.questionsLoading = false;
        });
    }

    removeSelectedQuestion(id: any) {
        if (id) {
            const index = this.selectedQuestions.findIndex(data => data.id === id);
            if (index >= 0) {
                this.selectedQuestions.splice(index, 1);
                this.campaignForm.get('questions').setValue(this.selectedQuestions);
            }
        }

    }

    dismissQuestionModal() {
        this.emptyForm();
        this.questionModal.dismiss();
    }

    get selectedQuestions() {
        return this.campaignForm.get('questions').value as PbxQuestion[];
    }
}
