import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class KdAssicuraService {
    constructor(private request: RequestService, public alertService: AlertService) {}

    public getList(params?): Observable<any> {
        return this.request.get('v3/kd-assicura/list', params);
    }

    public updateOrder(body: {}) {
        return this.request.put(`v3/kd-assicura/update`, body);
    }

    public successAlert(text) {
        this.alertService.show('', 'Successfully ' + text, {
            classname: 'bg-success text-light',
            delay: 4000,
        });
    }

    public errorAlert(message?) {
        this.alertService.show('An error occurred!', message ? message : 'Try again!', {
            classname: 'bg-danger text-light',
            delay: 2000,
        });
    }
}
