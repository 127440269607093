import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PbxLead} from '@models/pbx/pbx-lead';
import {AuthenticationService} from '@services/authentication.service';
import {User} from '@models/user';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PbxCall, PbxStatus} from '@models/pbx/pbx-call';
import * as moment from 'moment';
import {PbxCampaignQuestion, PbxCampaignQuestionListPagination, PbxQuestionType} from '@models/pbx/pbx-question';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-pbx-call-modal',
    templateUrl: './pbx-call-modal.component.html',
    styleUrls: ['./pbx-call-modal.component.css']
})
export class PbxCallModalComponent implements OnInit {
    @Input() lead?: PbxLead;
    @Input() phoneNumber?: string;
    @Input() operator?: User;
    @Input() call?: PbxCall;
    @Input() calls?: PbxCall[] = [];
    @Input() isEdit = false;
    @Input() isScheduleCall = false;
    modalBusy: any;
    defaultConfig = {base_url: '/tinymce', suffix: '.min'};
    answers: FormArray;
    callForm: FormGroup;
    format = 'YYYY-MM-DDTHH:mm';
    currentDatetime = moment().format(this.format);
    questions: PbxCampaignQuestion[] = [];
    callStatuses: PbxStatus[] = PbxCampaignService.callStatuses;
    feedbackStatuses: PbxStatus[] = PbxCampaignService.feedbackStatuses;
    questionTypes: PbxQuestionType[] = PbxCampaignService.questionTypes;
    apiFormat = PbxCampaignService.datetimeApiFormat;
    showTable = true;
    showReports = true;
    showKenaData = true;
    showInfo = true;

    riepilogoPeriod = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-DD'),
    };

    constructor(private pbxCampaignService: PbxCampaignService,
                public modalService: NgbModal,
                public authenticationService: AuthenticationService,
                private fb: FormBuilder) {
        this.callForm = new FormGroup({
            start_time: new FormControl('', Validators.required),
            duration: new FormControl(''),
            destination: new FormControl('', Validators.required),
            response: new FormControl(''),
            feedback_status: new FormControl('', Validators.required),
            call_status: new FormControl('', Validators.required),
            answers: new FormArray([])
        });
    }

    ngOnInit(): void {
        this.loadData();
        if (!this.isEdit) {
            this.loadQuestions();
        }
        this.callStatus.valueChanges.subscribe(status => {
            if (status === this.callStatuses[0].key) {
                this.callForm.controls['duration'].setValidators(Validators.required);
            } else {
                this.callForm.controls['duration'].clearValidators();
            }
            this.callForm.get('duration').updateValueAndValidity();
        });
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    save() {
        const call = new PbxCall();
        if (!this.isEdit) {
            call.lead_id = this.lead.id;
            call.caller_id = this.operator.profile.id;
            call.answers = this.getAnswersList();
        }
        call.start_time = moment(this.callForm.value.start_time).format(this.apiFormat);
        call.feedback_status = this.callForm.value.feedback_status;
        call.response = this.callForm.value.response;
        call.call_status = this.callForm.value.call_status;
        call.duration = this.callForm.value.duration;
        call.destination = this.callForm.value.destination;
        if (this.isEdit) {
            return this.putCall(call);
        }
        return this.postCall(call);
    }

    postCall(call: PbxCall) {
        if (this.isScheduleCall) {
            this.modalBusy = this.pbxCampaignService.putCall(this.lead.scheduled_call_id, call).subscribe((response: PbxCall) => {
                    this.refreshLeadsList(response);
                }, err => {
                    this.pbxCampaignService.errorAlert(err.message);
                    this.dismissModal();
                }
            );
        } else {
            this.modalBusy = this.pbxCampaignService.postCall(call).subscribe((response: PbxCall) => {
                    this.refreshLeadsList(response);
                }, err => {
                    this.pbxCampaignService.errorAlert(err.message);
                    this.dismissModal();
                }
            );
        }
    }

    refreshLeadsList(response) {
        this.lead.number_of_calls += 1;
        this.lead.feedback_status = response.feedback_status;
        this.lead.call_status = response.call_status;
        if (this.isScheduleCall) {
            this.lead.scheduled_call_status = response.call_status;
        }
        this.pbxCampaignService.successAlert('Added Call Report!');
        this.dismissModal();
    }

    putCall(call: PbxCall) {
        this.modalBusy = this.pbxCampaignService.putCall(this.call.id, call).subscribe((response: PbxCall) => {
            const index = this.calls.findIndex(data => data.id === this.call.id);
            if (index > -1) {
                this.calls[index] = response;
            }
            this.call = response;
            this.pbxCampaignService.successAlert('Changed Call Report!');
                this.dismissModal();
            }, err => {
                this.pbxCampaignService.errorAlert(err.message);
                this.dismissModal();
            }
        );
    }

    changePhoneNumber(event) {
        this.phoneNumber = event;
    }

    loadQuestions() {
        this.pbxCampaignService.getCampaignQuestions(this.lead.campaign_id).subscribe((response: PbxCampaignQuestionListPagination) => {
            this.questions = response.data;
            this.questions.forEach(data => {
                this.answersValue.push(this.fb.group({
                    id: [data.id],
                    ['text' + data.id]: ['']
                }));
            });
        }, err => {
        });
    }

    getAnswersList() {
        const list = [];
        this.callForm.value.answers.forEach(data => {
            if (data['text' + data.id] && data['text' + data.id] !== '') {
                list.push({campaign_question_id: data.id, text: data['text' + data.id]});
            }
        });
        return list;
    }

    loadData() {
        if (this.isEdit) {
            this.phoneNumber = this.call.destination;
            this.lead = this.call.lead;
            const startTime = moment(this.call?.start_time).format(this.format);
            this.callForm.get('start_time').setValue(startTime ? startTime : this.currentDatetime);
            this.callForm.get('duration').setValue(this.call.duration);
            this.callForm.get('destination').setValue(this.call.destination);
            this.callForm.get('feedback_status').setValue(this.call?.feedback_status ?
                this.call.feedback_status : this.feedbackStatuses[1]?.key);
            this.callForm.get('call_status').setValue(this.call?.call_status ? this.call.call_status : this.callStatuses[1]?.key);
            this.callForm.get('response').setValue(this.call.response);
        } else {
            const startTime = moment(this.isScheduleCall ? this.lead?.scheduled_at : this.currentDatetime).format(this.format);
            this.callForm.get('start_time').setValue(startTime);
            this.callForm.get('feedback_status').setValue(this.feedbackStatuses[1]?.key);
            this.callForm.get('call_status').setValue(this.callStatuses[1]?.key);
            this.callForm.get('destination').setValue(this.phoneNumber);
        }
    }

    get callStatus() {
        return this.callForm.get('call_status') as FormControl;
    }

    get answersValue(): FormArray {
        return this.callForm.get('answers') as FormArray;
    }
}
