import {Component, OnInit, ViewChild} from '@angular/core';
import {ShopOrder, ShopOrderPagination} from '@models/e-pay/shop-order';
import {MetaPagination} from '@models/meta-pagination';
import {EPayService} from '@services/e-pay.service';
import {ShopBrand, ShopBrandPagination} from '@models/e-pay/shop-brand';
import {AuthenticationService} from '@services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {User} from '@models/user';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import * as moment from 'moment';
import {KdOrderService} from '@services/kd-order.service';
import {SpidIr, SpidIrProvider} from '@models/kd-orders';


@Component({
  selector: 'app-spid-ir',
  templateUrl: './spid-ir.component.html',
  styleUrls: ['./spid-ir.component.css']
})
export class SpidIrComponent implements OnInit {

  bookingOrders: SpidIr[] = [];
  ordersPagination: MetaPagination;

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
        'Last Month': [moment().subtract(1, 'month'), moment()],
        'Last 6 Months': [moment().subtract(6, 'month'), moment()],
        'Last Year': [moment().subtract(12, 'month'), moment()],
    }
};
  
filter ={
  company_name: '',
  dealer_id: '',
  fiscal_code: '',
  booked_url: '',
  token: '',
  created_at: '',
  date: ''
}

apiDate = {
  from: '',
  to: ''
};

// types = EPayService.productTypes;
// statuses = PostalSlip.orderTypes;
// payments = PostalSlip.orderPayments; 
// bollettinClosed = PostalSlip.bolletinStatus;

@ViewChild('receiptFormTemplate') private receiptFormTemplate;
brandsLoading = false;
brands: ShopBrand[] = [];
isDealer = false;

  constructor(
    private ePayService: EPayService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    private dateRangePickerOptions: DaterangepickerConfig,
    private DigitalSignitureService: KdOrderService,
    public route: ActivatedRoute
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadOrders();
    setTimeout(() => {
    });
  }
  loadOrders() {
    this.spinner.show('table-orders');
    const params = {};
    if (this.ordersPagination?.currentPage) {
        params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
        params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
        params['dealer_id'] = this.filter.dealer_id;
      }
    if (this.filter.fiscal_code) {
        params['fiscal_code'] = this.filter.fiscal_code;
    }
    if (this.filter.date) {
        params['from_date'] = this.apiDate.from;
        params['to_date'] = this.apiDate.to;
    }
    if (this.filter.token) {
        params['token'] = this.filter.token;
    }

  this.DigitalSignitureService.getBookingOrdes(params).subscribe((response: any) => {
      this.bookingOrders = new SpidIr().fromArray(response.data);
      this.ordersPagination = response._meta;
      this.spinner.hide('table-orders');
    }, err => {
      this.spinner.hide('table-orders');
  }); 
}

public generateDateRangeString(startDate: Moment, endDate: Moment) {
  const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
  this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
  this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
  this.filter.date = startDate.format(localDateFormat)
      + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }
  
}

