<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadOrders()">
    <div class="form-row">
      <div class="col-xl-3 col-md-3 col-sm-6">
        <div class="form-group">
          <label translate for="companyName">Company name</label>
          <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="companyName"></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-6">
        <div class="form-group">
          <label for="deleted">Dealer Attivo/Disattivo</label>
          <ng-select
            [clearable]="true"
            name="deleted"
            bindLabel="label"
            bindValue="key"
            [items]="selectItems"
            [searchable]="false"
            [(ngModel)]="filter.deleted"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-6">
        <div class="form-group">
          <label translate>Periodo da:</label>
          <input
            id="documentDate"
            autocomplete="off"
            type="text"
            daterangepicker
            [options]="rangeOptions"
            [(ngModel)]="filter.date"
            name="date"
            class="form-control"
            (selected)="selectedDate($event.start)"
          />
        </div>
      </div>
      <div class="col-xl-3 col-md-3 col-sm-6">
        <div class="form-group">
          <button class="btn btn-info btn-block" translate type="submit" style="margin-top: 32px">Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal form -->

<div class="card">
  <div class="card-header d-flex py-3">
    <h4 class="mb-0" translate>Aggiungi un nuovo CU</h4>
    <div class="ml-auto">
      <button class="btn btn-primary px-3 btn-sm mr-2" type="button" translate (click)="showModal()">Aggiungi singolo CU</button>
      <button class="btn btn-primary px-3 btn-sm" type="button" translate (click)="showMassiveUploadModal()">Upload massivo CU</button>
    </div>
  </div>
</div>

<!-- Tabella -->

<div class="card">
  <div class="card-header">
    <h4 class="mb-0">CU</h4>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-orders" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-md table-striped">
      <thead>
        <tr>
          <th class="text-right">#</th>
          <th translate>Company name</th>
          <th translate>Attivo</th>
          <th translate>Anno</th>
          <th translate>Data di creazione</th>
          <th translate>Documento allegato</th>
        </tr>
      </thead>
      <tbody>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="orders" [fullScreen]="false">
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngIf="cuOrders.length == 0">
          <td translate colspan="5" class="text-center">List is empty</td>
        </tr>
      </tbody>
      <tr *ngFor="let cuOrder of cuOrders">
        <td class="">
          <button type="button" class="close text-danger" aria-label="Close" [swal]="deleteSubscriptionOptions" *ngIf="isEdit" (confirm)="deleteCuSubscription(cuOrder.id)">
            <span aria-hidden="true">&times;</span>
          </button>
        </td>
        <td class="w-50">
          <a [routerLink]="['/dealer', cuOrder.dealer_id]" target="_blank">{{ cuOrder.company_name }}</a>
        </td>
        <td>
          <span *ngIf="cuOrder.deleted == 1"> <i class="fa fa-times m-0 px-1 text-danger"></i> </span>
          <span *ngIf="cuOrder.deleted == 0"> <i class="fa fa-check m-0 px-1 text-success"></i> </span>
        </td>
        <td>
          {{ cuOrder?.year }}
        </td>
        <td>
          {{ cuOrder?.created | amDateFormat : 'DD/MM/YYYY HH:mm' }}
        </td>
        <td class="pr-4">
          <a href="{{ cuOrder.file_name }}">Scarica l'allegato<i class="fas fa-download text-primary ml-2"></i></a>
        </td>
      </tr>
    </table>
  </div>
  <div class="card-footer" *ngIf="cuOrders?.length > 0">
    <span
      ><span translate>There are </span><b>{{ this.ordersPagination.totalCount }}</b> {{ this.ordersPagination.totalCount <= 1 ? 'order' : ('orders' | translate) }}.
      <a class="link" (click)="exportSdd()">Esporta la lista completa</a>
    </span>
    <ngb-pagination
      *ngIf="ordersPagination.pageCount > 1"
      size="sm"
      [pageSize]="ordersPagination.perPage"
      [maxSize]="10"
      [(page)]="ordersPagination.currentPage"
      [collectionSize]="ordersPagination.totalCount"
      (pageChange)="loadOrders()"
      [boundaryLinks]="true"
    >
    </ngb-pagination>
  </div>
</div>

<ng-template #cuFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{ insertData ? 'Modifica Cu' : 'Aggiungi Cu' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-cu-modal [data]="insertData" (refresh)="loadOrders()"></app-cu-modal>
</ng-template>

<ng-template #cuMassiveTemplateUpdate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Upload Massivo Cu</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-cu-massive-update-modal (refresh)="loadOrders()"></app-cu-massive-update-modal>
</ng-template>
