<ng-container *ngIf="status === 'loading'">
    <div class="w-100 text-center">
        <img src="../../../assets/img/svgs/loading_gears.svg" [ngClass]="{'bg-white p-1 rounded': dark}" />
    </div>
</ng-container>
<ng-container *ngIf="status === 'error'">
    <div class="w-100 text-center">
        <img src="../../../assets/img/flats/reply.png" height="76" [ngClass]="{'bg-white p-1 img-circle': dark}"/>
        <p class="text-lg mt-3">{{message}}</p>
    </div>
</ng-container>