<div class="card bg-gradient-1">
  <div class="card-body">
    <div class="d-flex align-items-lg-center">
      <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1" src="assets/img/flats/payment.png" width="160px" />
      <div class="d-block w-100 pl-lg-3">
        <h1 class="display-4">Gestione Fido</h1>
        <hr />
        <div class="form-row">
          <div class="col-12 col-lg-4" *ngIf="!dealerId">
            <label>Tipologia</label>
            <ng-select [clearable]="true" [(ngModel)]="suretyTypeSelectedValue" (change)="onSuretyChange()">
              <ng-option *ngFor="let item of suretyTypes" [value]="item.value">{{ item.label }}</ng-option></ng-select
            >
          </div>
          <div class="col-12 col-lg-4" *ngIf="!dealerId">
            <label>Punto Vendita</label>
            <app-retailer-typeahead [class.disabled]="!suretyTypeSelectedValue" (onChange)="selectDealer($event)"></app-retailer-typeahead>
            <p style="font-size: x-small" *ngIf="!suretyTypeSelectedValue">Selezionare prima la tipologia</p>
          </div>
          <div class="col-12 col-lg-4" *ngIf="dealerId">
            <label>Data</label>
            <input
              type="text"
              daterangepicker
              [(ngModel)]="filterDate"
              name="vat_date"
              class="form-control"
              placeholder="Seleziona una data"
              (cancelDaterangepicker)="clearDateFilter()"
              (selected)="generateDateRangeString($event.start, $event.end)"
              [class.disabled]="!suretyTypeSelectedValue && !dealerId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p *ngIf="suretyTypeSelectedValue" class="mb-2">
  Tipologia selezionata: <span class="display-6" style="font-weight: bold">{{ suretyTypeSelectedLabel }}</span>
</p>
<div class="card mt-3 bg-gradient-light" *ngIf="!dealerId">
  <ngx-spinner [fullScreen]="false" bdColor="rgba(0, 0, 0, 0.8)" color="#fff" name="report" size="default" type="square-jelly-box">
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <div class="card-body" style="min-height: 100px">
    <div class="row d-flex align-items-center">
      <div class="col-lg col-12 dashboard-card">
        <div class="d-flex align-items-center">
          <img class="d-inline-flex" src="assets/img/flats/euro.png" width="70px" />
          <div class="d-block w-100 pl-3">
            <p class="lead" translate>Fido Disponibile</p>
            <h1 class="display-6">{{ report?.total_amount | currency : currency }}</h1>
          </div>
        </div>
      </div>
      <div class="col-lg col-12 dashboard-card">
        <div class="d-flex align-items-center">
          <img class="d-inline-flex" src="assets/img/flats/money-sign.png" width="70px" />
          <div class="d-block w-100 pl-3">
            <p class="lead" translate>Totale saldato</p>
            <h1 class="display-6">{{ report?.credit | currency : currency }}</h1>
          </div>
        </div>
      </div>
      <div class="col-lg col-12 dashboard-card">
        <div class="d-flex align-items-center">
          <img class="d-inline-flex" src="assets/img/flats/money-loss.png" width="70px" />
          <div class="d-block w-100 pl-3">
            <p class="lead" translate>Totale da saldare</p>
            <h1 class="display-6">{{ report?.debit | currency : currency }}</h1>
          </div>
        </div>
      </div>
      <div class="col-lg col-12 dashboard-card">
        <div class="d-flex align-items-center">
          <img class="d-inline-flex" src="assets/img/flats/file.png" width="70px" />
          <div class="d-block w-100 pl-3">
            <p class="lead" translate>Ordini con fido</p>
            <h1 class="display-6">{{ report?.total_transaction }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-10 col-12">
        <h4 class="mb-0" translate>{{ dealerId ? 'Storico' : 'Dealers' }}</h4>
      </div>
      <div class="col-lg-2 col-12" *ngIf="dealerId">
        <button class="btn btn-success btn-sm float-right" type="button" translate (click)="showModal()">Aggiungi Fido Disponibile</button>
      </div>
    </div>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-credit" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-sm table-striped" *ngIf="!dealerId">
      <thead>
        <tr>
          <th translate>Company name</th>
          <!--        <th translate>Company owner</th>-->
          <th class="text-right">Fido disponibile</th>
          <th class="text-right">Totale saldato</th>
          <th class="text-right">Totale da saldare</th>
          <th class="text-right">Ordini con fido</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody class="bg-gradient-white">
        <tr *ngFor="let dealer of dealers">
          <td>
            <a [routerLink]="['/dealer', dealer.id]" target="_blank">
              {{ dealer.company_name }}
            </a>
          </td>
          <!--        <td>-->
          <!--          {{ dealer.company_owner }}-->
          <!--        </td>-->
          <td class="text-right">
            {{ dealer.total_amount | currency : currency }}
          </td>
          <td class="text-right">
            {{ dealer.credit | currency : currency }}
          </td>
          <td class="text-right text-danger">
            {{ dealer.debit | currency : currency }}
          </td>
          <td class="text-right">
            {{ dealer.total_transaction }}
          </td>
          <td class="text-right">
            <button class="btn btn-link py-0 my-0" routerLink="/gestione-crediti/surety-credit/{{ dealer?.id }}/{{ suretyTypeSelectedValue }}">
              <i class="fa fa-eye text-info"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="dealers?.length <= 0">
          <td translate colspan="10" class="text-muted">List is empty</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-sm table-striped collapse-table" *ngIf="dealerId">
      <thead>
        <tr>
          <th>Data</th>
          <th>Tipo</th>
          <th translate>Operator</th>
          <th>Ordine</th>
          <th class="text-right">Credito</th>
          <th class="text-right">Debito</th>
          <th class="text-right">Totale</th>
          <th class="text-right">Saldato</th>
          <th class="text-right">Data Saldo</th>
          <th class="text-right" *ngIf="false"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let credit of credits">
          <td>{{ credit.created_at | amFromUnix | amDateFormat : 'DD/MM/YYYY HH:MM' }}</td>
          <td>{{ credit?.typePretty }}</td>
          <td>{{ credit?.operator }}</td>
          <td>{{ credit?.order_id }}</td>
          <td class="text-right">
            <span *ngIf="credit?.is_credit">{{ credit.amount | currency : currency }}</span>
          </td>
          <td class="text-right">
            <span *ngIf="!credit?.is_credit">{{ credit.amount | currency : currency }}</span>
          </td>
          <td class="text-right">
            <strong>{{ credit.current_sum | currency : currency }}</strong>
          </td>
          <td class="text-right">
            <a
              href="javascript:void(0)"
              (click)="openPopover(suretyPopover, credit)"
              #suretyPopover="ngbPopover"
              autoClose="outside"
              triggers="manual"
              [ngbPopover]="popContent"
              popoverTitle="Cambia valore"
            >
              <i class="text-danger fas fa-times" *ngIf="credit?.welded === 0 && credit.order_id"></i>
              <i class="text-success fas fa-check" *ngIf="credit?.welded === 1 && credit.order_id"></i>
            </a>
          </td>
          <td class="text-right">{{ credit.welding_date | amDateFormat : 'DD/MM/YYYY' }}</td>
          <td class="text-right" *ngIf="false">
            <!--                    <button class="btn btn-link py-0 my-0" (click)="showModal(credit)">-->
            <!--                        <i class=" fa fa-edit text-muted"></i>-->
            <!--                    </button>-->
            <button class="btn btn-link py-0 my-0" [swal]="deleteCreditOptions" (confirm)="removeTransaction(credit.id)">
              <i class="fa fa-trash text-danger"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="credits.length <= 0">
          <td class="text-muted" colspan="10" translate>List is empty</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" *ngIf="dealers?.length > 0 && pagination">
    <span
      ><span translate>There are</span><b> {{ pagination.totalCount }}</b> {{ pagination.totalCount <= 1 ? 'dealer' : 'dealers' }}.</span
    >
    <ngb-pagination
      *ngIf="pagination.pageCount > 1"
      size="sm"
      [pageSize]="pagination.perPage"
      [maxSize]="10"
      [(page)]="pagination.currentPage"
      [collectionSize]="pagination.totalCount"
      (pageChange)="loadDealers()"
      [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
  <div class="card-footer" *ngIf="credits.length > 0 && pagination">
    <span
      ><span translate>There are</span><b> {{ pagination.totalCount }}</b> {{ pagination.totalCount <= 1 ? 'transaction' : ('transactions' | translate) }}.</span
    >
    <ngb-pagination
      *ngIf="pagination.pageCount > 1"
      size="sm"
      [pageSize]="pagination.perPage"
      [maxSize]="10"
      [(page)]="pagination.currentPage"
      [collectionSize]="pagination.totalCount"
      (pageChange)="loadTransactions()"
      [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
</div>

<ng-template #popContent>
  <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>

<ng-template #transactionTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Fido</h4>
    <button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [ngBusy]="transactionBusy">
    <form [formGroup]="transactionForm" (ngSubmit)="saveTransaction()">
      <div class="form-row">
        <div class="col-12 form-group">
          <label for="price" translate>Fido Concesso</label>
          <div class="input-group">
            <input type="number" class="form-control" id="price" aria-describedby="basic-addon" formControlName="amount" />
            <div class="input-group-append">
              <div class="input-group-text bg-white" id="basic-addon"><strong>€</strong></div>
            </div>
          </div>
          <val-errors controlName="price">
            <ng-template valError="required">
              <span translate>Amount is required</span>
            </ng-template>
          </val-errors>
        </div>
        <div class="col-12 form-group">
          <label for="type" translate>Tipo Fido</label>
          <select class="form-control" id="type" formControlName="type">
            <option value="">-- Seleziona un opzione --</option>
            <option value="plafond-kena">Plafond Kena</option>
            <option value="prodotti">Prodotti</option>
          </select>
          <val-errors controlName="type">
            <ng-template valError="required">
              <span translate>Amount is required</span>
            </ng-template>
          </val-errors>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-success btn-block"><i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
