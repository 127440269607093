<div class="modal-body">
    <form (ngSubmit)="save()" [formGroup]="partnershipForm" >
        <div class="mt-2 mb-0">
            <div class="form-group">
                <label class="lead">Scegli il file</label>
                <div class="custom-file">
                    <input 
                        (change)="onFileSelected($event)"
                        class="custom-file-input cursor-hand"
                        formControlName="fileInput"
                        id="validatedCustomFile"
                        type="file"
                        class="custom-file-input" id="validatedCustomFile"
                        >
                        <label 
                            *ngIf="file" 
                            class="custom-file-label text-green"
                        >
                            {{ file.name }}
                        </label>
                        <label
                            *ngIf="!file"
                            class="custom-file-label"
                            for="validatedCustomFile"
                        >Scegli il file
                        </label>
                </div>
                <val-errors controlName="file">
                    <ng-template valError="required">
                        Il file è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
            <button [disabled]="loadingExport === true" class="btn btn-info btn-block mb-2" type="submit">
                <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
            </button>
        </div>
    </form>
</div>
 