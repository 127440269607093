import { MetaPagination } from '@models/meta-pagination';
import { BaseModel } from '@app/models/base-model';
import { DealerView } from '@models/dealer-view';

export class A2a extends BaseModel {
  public static orderStatus = [
    {
      label: 'In attesa',
      key: 'pending',
    },
    {
      label: 'Confermato',
      key: 'confirmed',
    },
    {
      label: 'Cancellato',
      key: 'canceled',
    },
  ];
  static orderStatusType = {
    pending: 'In attesa',
    confirmed: 'Confermato',
    canceled: 'Respinto',
  };

  constructor(attributes?: any) {
    super(attributes);

    if (this.data !== undefined) {
      const jsonData = JSON.parse(this.data);
      this.email = jsonData['email'];
      this.phone = jsonData['phone'];
      this.name = jsonData['name'];
      this.surname = jsonData['surname'];
      this.city = jsonData['city'];
      this.address = jsonData['address'];
      this.vat = jsonData['vat'];

      //sondaggio
      this.negozio_attivo_da = jsonData['negozio_attivo_da'];
      this.interesse_a2a = jsonData['interesse_a2a'];
      this.specificare_altro = jsonData['specificare_altro'];
      this.sei_gia_rivenditore = jsonData['sei_gia_rivenditore'];
      this.contatti_mensili = jsonData['contatti_mensili'];
      this.stima_contatti_mensili = jsonData['stima_contatti_mensili'];
    }
  }

  id: number;
  company_name: string;
  dealer_id: number;
  name: string;
  surname: string;
  city: string;
  address: string;
  notes: string;
  vat: string;
  phone: string;
  email: string;
  data: string;
  created_at: string;
  created_date: number;
  status: string;
  show = false;

  //sondaggio
  negozio_attivo_da: string;
  interesse_a2a: string;
  specificare_altro: string;
  sei_gia_rivenditore: string;
  contatti_mensili: string;
  stima_contatti_mensili: string;

  get orderStatus() {
    return A2a.orderStatusType[this.status];
  }

  fromArray(result: []): A2a[] {
    const array: A2a[] = [];
    for (const item of result) {
      array.push(new A2a(item));
    }
    return array;
  }
}

export class A2aProvider extends BaseModel {
  data: A2a[];
  _meta: MetaPagination;
  _links: Array<any>;
}

export class A2aContratti extends BaseModel {
  id: number;
  dealer_id: number;
  id_agente: number;
  ragsociale_agente: string;
  id_masteragente: number;
  ragsociale_masteragente: string;
  company_name: string;
  nome_opportunita: string;
  product: string;
  account_personale_provincia: string;
  codice_mgm_univoco: string;
  mercato_di_provenienza: string;
  commodity: string;
  sottocanale: string;
  causale_annullamento: string;
  causale_annullamento_estesa: string;
  data_annullamento: string;
  canale_vendita: string;
  codice_pdv: string;
  fase: string;
  esito_ammissibilita_richiesta: string;
  data_sottoscrizione_ordine: string;
  modalita_vendita: string;
  nome_account: string;
  operatore: string;
  pod_pdr: string;
  processo: string;
  stato: string;
  stato_blacklist: string;
  stato_credit_check: string;
  stato_validazione: string;
  tipo_account: string;
  sepa:string;
  validity_start_date: string;
  activation_date: string;

  show = false;

  fromArray(result: []): A2aContratti[] {
    const array: A2aContratti[] = [];
    for (const item of result) {
      array.push(new A2aContratti(item));
    }
    return array;
  }
}

export class A2aContrattiProvider extends BaseModel {
  data: A2aContratti[];
  _meta: MetaPagination;
  _links: Array<any>;
}
