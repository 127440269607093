<app-spinner [status]="status"></app-spinner>

<ng-container *ngIf="status === 'hidden'">
    <div style="height: 200px;">
        <canvas baseChart width="100"
                *ngIf="data"
                [colors]="colors"
                [legend]="legend"
                [plugins]="plugins"
                [options]="options"
                [datasets]="datasets"
                [labels]="labels"
                [chartType]="type"></canvas>
    </div>
</ng-container>

