<div class="row">
  <div class="col-12">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div *ngFor="let item of data; let i = index" class="card bg-gradient-light">
      <div class="card-header">
        <div class="user-block">
          <img class="img-circle" src="../../../assets/img/user2-160x160.jpg" />
          <a class="username" [routerLink]="['/gestione-crediti/gestione-credito/'+item.dealer_id]" target="_blank"
            ><b>{{ item.dealer_name }}</b></a
          >
          <div class="description d-flex align-items-center">
            <p class="mb-0">SDD:</p>
            <ng-container *ngIf="item.dealer_sdd_data.sdd === 1">
              <i class="fa fa-check-circle ml-2" style="color: green"></i>
            </ng-container>
            <ng-container *ngIf="item.dealer_sdd_data.sdd === 0">
              <i class="fa fa-times-circle ml-2" style="color: red"></i>
            </ng-container>
          </div>
          <div class="description d-flex align-items-center">
            <ng-container *ngIf="item.dealer_sdd_data.sdd === 1">
              <p class="mb-0">IBAN: {{ item.dealer_sdd_data.iban }}</p>
            </ng-container>
          </div>
        </div>
        <div class="card-tools">
          <span class="text-muted pr-2">Totale Dealer:</span>
          <span class="text-lg">{{ item.dealer_total }}€ </span>
        </div>
      </div>
      <div class="card-body p-0">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th class="text-left">
                <input type="checkbox" [(ngModel)]="item.isSelectedAll" (ngModelChange)="toggleSelectAllInvoices($event, item)" />
              </th>
              <th style="width: 10%">N. Documento</th>
              <th style="width: 20%">Data di creazione</th>
              <th style="width: 10%">Anno</th>
              <th style="width: 20%">Totale</th>
              <th style="width: 20%" class="text-center">Addebito</th>
              <th style="width: 20%" class="text-center">Stato</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of item.invoices; let i = index">
              <td class="text-left">
                <input type="checkbox" [(ngModel)]="invoice.isSelected" />
              </td>
              <td class="text-left">{{ invoice.sectional_number }}</td>
              <td class="text-left">{{ invoice.created_at | amFromUnix | amDateFormat : 'DD/MM/YY' }}</td>
              <td class="text-left">{{ invoice.year }}</td>
              <td class="text-left">
                <strong>{{ invoice.total }}€</strong>
              </td>
              <td class="text-center">
                <button class="btn btn-link" (click)="addCharges(invoice)" [disabled]="invoice.addebito == '1'">
                  <i *ngIf="invoice.addebito == '0'"  class="fa fa-plus text-info"></i>
                  <i *ngIf="invoice.addebito == '1'" class="fa fa-check-circle text-success"></i>
                </button>
              </td>
              <td class="text-left">
                <button
                  href="javascript:void(0)"
                  class="btn btn-sm btn-block px-3"
                  (click)="openPopover(typePopover, invoice?.id, invoice?.welding_status, 'welding_status', ecStatuses, item.dealer_id)"
                  #typePopover="ngbPopover"
                  autoClose="outside"
                  triggers="manual"
                  [ngbPopover]="popContentStatus"
                  popoverTitle="Cambia valore"
                  [ngClass]="{
                    'btn-info': invoice.welding_status === '1',
                    'btn-success': invoice.welding_status === '2',
                    'btn-danger': invoice.welding_status === '0'
                  }"
                >
                  {{ invoice.welding_status === '0' ? 'DA SALDARE' : invoice.welding_status === '1' ? 'IN CORSO' : invoice.welding_status === '2' ? 'SALDATO' : '' }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #popContentStatus>
  <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>

<ng-template #chargesModalTemplate let-modal>
  <div class="modal-header bg-gradient-dark">
    <h4 class="modal-title clamp-title" translate>{{ 'Inserisci i Dati' }}</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-row">
      <div class="col-12">
        <label for="invoiceNumber">Inserisci Numero Fattura</label>
        <input type="text" id="invoiceNumber" class="form-control" [(ngModel)]="invoiceNumber" />
      </div>
      <div class="col-12 mt-3">
        <label for="invoiceDate">Inserisci Data Fattura</label>
        <div class="input-group">
          <input
            id="invoiceDate"
            class="form-control"
            placeholder="gg-mm-aaaa"
            autocomplete="off"
            name="dp"
            [(ngModel)]="invoiceDate"
            ngbDatepicker
            #d="ngbDatepicker"
            (dateSelect)="setSelectedDate($event)"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-warning border-2 border-warning rounded p-3 mt-3">
      <p>Ricorda di inserire la data nel formato gg-mm-aaaa</p>
      <p class="">
        Stai effettuando una accredito della fattura, la transazione sarà tracciata utilizzando il tuo <strong>ID
        operatore</strong>.
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="sendChargesData()">
      <span>
        Invia
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
    </button>
  </div>
</ng-template>
