/* tslint:disable:variable-name */
import {BaseModel} from '@models/base-model';
import {DealerView} from '@models/dealer-view';
import {MetaPagination} from '@models/meta-pagination';
import {number} from 'ngx-custom-validators/src/app/number/validator';
import {SuretyCredit} from '@models/surety';
import { Vendor } from './vendor';

export class ShopOrder extends BaseModel {
    id: number;
    status_name: string;
    dealer: DealerView;
    company_name: string;
    payment: string;
    created: string;
    handled_date: string;
    order_total: number;
    shipping_total: number;
}

export class ShopOrderPagination extends BaseModel {
    data: ShopOrder[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ShopOrderReport extends BaseModel {
    sim: {
        handled_orders: number,
        sim_shipped: number,
        average_time: AverageTime,
    };
    plafond: {
        handled_orders: number,
        plafond_charged: number,
        average_time: AverageTime,
    };

    ranking: [
        {
            operatore: string,
            orders: string,
            orders_with_plafond: number,
            average_time: AverageTime,
            average_time_plafond: AverageTime
        }
    ];

    orders: [
        {
            dealer: string,
            operator: string,
            operator_plafond: string,
            sim: number,
            plafond: number,
            handling_time_interval: AverageTime,
            handling_time_plafond_interval: AverageTime,
            created: string
        }
    ];
}

export class AverageTime extends BaseModel {
    years: number;
    months: number;
    days: number;
    hours: number;
    minuts: number;
    seconds: number;
}

export class ShopOrderList extends BaseModel {
    id: number;
    fic_id: number;
    dealer: DealerView;
    agent: DealerView;
    created: string;
    shipping_total: number;
    status_name: string;
    status: number;
    plafond_status: any;
    payment: number;
    payment_name: string;
    amount: number;
    note: string;
    note_op: string;
    convalid: boolean;
    can_edit: boolean;
    is_sim: boolean;
    is_plafond: boolean;
    file_path: string;
    paid: number;
    is_compensation: number;
    order_subtotal: number;
    items: ShopOrderProductList[];
    taxes: ShopOrderTax[];
    packages: ShopOrderPackage[];
    detail: ShopOrderDetail;
    key_account_kena: string;
    key_account_tim: string;
    surety_total: number;
    surety: SuretyCredit;
    has_spid: boolean;
    has_wallet: boolean;
    wallet_assigned: boolean;
    spid_assigned: boolean;
    vendors: Vendor[];

}

export class ShopOrderProductList {
    id: number;
    created: string;
    order_id: number;
    parent_id: number;
    price: any;
    quantity: number;
    product_id: number;
    product: {
        name: string;
        description: string;
        quantity: number;
        variant_name: string;
        variant_description: string;
        thumb: boolean;
        sku: string;
        ean: any;
        dealer_pro_discount: number;
        sconto: number;
        image: string;
    };
    vat: any;
    total_price: any;
}

export class ShopOrderPackage {
    id: number;
    lotto: string;
    sim: any;
    data_assegnazione: string;
    numero: number;
}

export class ShopOrderTax {
    description: string;
    id: number;
    order_id: number;
    prettyDescription: string;
    total_price: string;
    type: string;
}

export class ShopOrderDetail {
    id: number;
    address: {
        comune: string;
        provincia: string;
        indirizzo: string;
        civico: string;
        cap: string;
        referente: string;
    };
    status_name: string;
    created: string;
    order_total: any;
    shipping_total: any;
    tax_total: any;
    handled_date: string;
    uploaded_file_date: string;
    payment: any;
    uploaded_file: string;
    tracking: string;
    operator?: string;
    operator_plafond?: string;
    operator_code: {
        kena: string;
        tiscali: string;
    };
    dealer_credit: any;
    file_path: string;
}

export class ShopOrderListPagination extends BaseModel {
    data: ShopOrderList[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ShopOrderFile extends BaseModel {
    file: File;

    get postPayload() {
        const payload = new FormData();
        this.addToPayload(payload, this.file, 'file');
        return payload;
    }
}
