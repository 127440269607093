<div class="row" *ngIf="reportControlOrders">
    <div class="col-12">
        <ngx-spinner
          bdColor="rgba(0, 0, 0, 0.8)"
          size="default"
          color="#fff"
          type="square-jelly-box"
          name="table-orders"
          [fullScreen]="false"
        >
        <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <div class="container" *ngIf="reportControlOrders.length==0">
          <div class="card-header" translate colspan="9" class="text-center">List is empty</div>
        </div>
          <div class="card bg-gradient-light" *ngFor="let item of reportControlOrders">
            <div class="card-header">
              <div class="row">
                <div class="col-8">
                  <div class="user-block">
                    <img class="img-circle" src="../../../assets/img/user2-160x160.jpg" />
                    <a class="username" [routerLink]="['/gestione-crediti/gestione-credito']" target="_blank"
                      ><b>{{item.dealer_name}}</b></a
                    >
                    <span class="description">
                      <p>Descrizione: Ordini effettuati con contrassegno</p>
                      <p>Totale ordini: <strong> {{item.dealer_total_order}}</strong></p>
                    </span>
                  </div>
                </div>
                <div class="col-4 d-flex">
                  <span style="border-right: 2px solid #666;" class="pr-3 py-1 ml-auto border-right-secondary">
                    <p class="text-uppercase text-right text-sm">Totale saldato</p>
                    <p class="lead text-lg text-right "><strong> {{item.dealer_total_order_amount | currency:'€' }}</strong></p>
                  </span>
                  <span class="pl-3  py-1 text-danger">
                    <p class="text-uppercase text-sm">Totale da saldare</p>
                    <p class="lead text-lg"><strong> {{item.dealer_total_order_weld | currency:'€' }}</strong></p>
                  </span>
                </div>
              </div>
            </div>
            <div class="card-body p-0">
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th style="width: 20%;" class="text-left">Data di creazione</th>
                    <th style="width: 15%;"class="text-left">Id ordine</th>
                    <th style="width: 10%;"class="text-left">Note</th>
                    <th style="width: 20%;"class="text-left">Importo</th>
                    <th style="width: 0%;"class="text-left"></th>
                    <th style="width: 15%;" class="text-center">Estratti</th>
                    <th style="width: 15%;" class="text-center">Stato</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let order of item.shop_ordini"> 
                    <td>
                      <p>{{order.created | amDateFormat: 'DD/MM/YYYY - HH:mm'}}</p>
                    </td>
                    <td >
                      <p>{{order.id}}</p>
                    </td>
                    <td *ngIf="order?.note_op">
                      <button href="javascript:void(0)" class="btn px-0 bg-transparent text-center" 
                      (click)="openPopover(notesPopover,order?.id,order?.note_op,'notes')"
                      #notesPopover="ngbPopover" autoClose="outside" triggers="manual"
                      [ngbPopover]="popContentNotes" popoverTitle="Modifica nota">
                      <i class="text-success fas fa-check ng-star-inserted"></i>
                  </button>
                  </td>
                    <td *ngIf="order?.note_op == '' ||  order?.note_op == null ">
                      <button href="javascript:void(0)" class="btn text-primary text-center px-0 bg-transparent"
                          (click)="openPopover(notesPopover,order?.id,order?.note_op,'note_op')"
                          #notesPopover="ngbPopover" autoClose="outside" triggers="manual"
                          [ngbPopover]="popContentNotes" popoverTitle="Scrivi una nota">
                          <i class="text-danger fas fa-times ng-star-inserted"></i>
                      </button>
                  </td>
                    <td>
                      <p><strong> {{order.totale_ordine | currency:'€' }}</strong></p>
                    </td>
                    <td>
                      <p></p>
                    </td>
                    <td class="text-center">
                      <button class="btn btn-primary btn-sm px-3 text-uppercase" type="button" translate
                      (click)="showModal(order)">{{ order.saldato === '1' ? "Vedi" : "Suggeriti"}}
                      </button>
                    </td>
                    <td class="text-center">
                      <button href="javascript:void(0)" class="btn btn-sm text-uppercase px-3"
                      (click)="statusClick(order.id, order.saldato === '0' ? '1' : '0')"
                      [ngClass]="{
                        'btn-success': order.saldato === '1',
                        'btn-danger': order.saldato === '0'
                      }">
                      {{ order.saldato === '0' ? 'Da saldare' : 'Saldato' }}
                    </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
  </div>
  <ng-template #popContent>
    <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
  </ng-template>
  <ng-template #popContentNotes>
    <app-text-popup  [config]="popupConfig"  (onSave)="save($event)"></app-text-popup>
  </ng-template>
  <ng-template #contrassegnoModalTemplate let-modal>
    <div class="modal-header my-auto modal-dialog-centered bg-dark text-white">
      <h4 class="modal-title clamp-title " translate>{{viewData ? 'Modifica transazioni suggerite' : 'Transazioni suggerite'}}</h4>
      <button type="button" class="close px-3 text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <app-contrassegno-modal 
        [orderId]="orderId" (refresh)="loadOrders()">
    </app-contrassegno-modal>
  </ng-template> 