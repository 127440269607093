<div class="card" [ngClass]="cardClass">
    <div class="card-body">
        <div class="d-flex align-items-center">
            <img *ngIf="image" class="d-none d-xl-inline-flex"
                 [src]="image"
                 [ngClass]="{'img-circle': rounded, 'bg-gradient-light p-1 elevation-1': background}"
                 width="160px">
            <div class="d-block w-100 pl-3">
                <h1 [class.display-6]="smallTitle" [class.display-4]="bigTitle">{{title | translate}}</h1>
                <hr>
                <ng-content></ng-content>

                <div class="row align-items-end">
                    <div class="col-12 col-xl-3" *ngIf="areamanager && agenti && !stand">
                        <label>Cosa vuoi vedere?</label>
                        <select class="form-control" [(ngModel)]="this.type">
                            <ng-container>
                                <option value="0">Master</option>
                                <option value="1">Area Manager</option>
                                <option value="2">Key Account</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-12 col-xl-3" *ngIf="areamanager && !stand && type == '1'">
                        <label>Seleziona un area manager</label>
                        <app-master-agent-select (onChange)="onMasterAgentSelect($event)"></app-master-agent-select>
                    </div>
                    <div class="col-12 col-xl-3" *ngIf="agenti && !stand && type == '2'">
                        <label>Seleziona un key account</label>
                        <app-agente-select (onChange)="onAgenteSelect($event)"></app-agente-select>
                    </div>
                    <div class="col-12 col-xl-3" *ngIf="agenti && stand">
                        <label>Seleziona un presidio</label>
                        <app-stand-select [master]="true" (onChange)="onPresidioSelect($event)"></app-stand-select>
                    </div>
                    <div class="col-12 col-xl-3" *ngIf="operatori">
                        <label>Seleziona un operatore</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="fas fa-search"></i>
                            <select class="form-control" [(ngModel)]="this.operatore">
                                <ng-container>
                                    <option *ngFor="let op of this.listaOperatori" [value]="op">{{op}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3" *ngIf="periodoFrom">
                        <label>Seleziona periodo di confronto</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.from">
                                <ng-container>
                                    <option *ngFor="let per of listaPeriodi" [value]="per.value">{{per.label}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3" *ngIf="periodoTo">
                        <label>Seleziona periodo di riferimento</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.to">
                                <ng-container>
                                    <option *ngFor="let per of listaPeriodi" [value]="per.value">{{per.label}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3" *ngIf="periodoSingle">
                        <label>Seleziona periodo</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.periodo">
                                <ng-container>
                                    <option *ngFor="let per of listaPeriodi" [value]="per.value">{{per.label}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
