import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';
import {Races} from '@models/races';

export class Group extends BaseModel {
    id: number;
    dealer_id: number;
    name: string;
    type: string;
    created_at: number;
    created_by: number;
    dealer_count:number;
    updated_at: number;
    updated_by: number;
    is_deleted: any;
    company_name: string;
    group_dealers: [];
}

export class GroupProvider {
    data: Group[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class GroupDealers extends BaseModel {
    id: number;
    dealer_id: number;
    group_id: number;
    created_at: number;
    created_by: number;
    updated_at: number;
    updated_by: number;
    is_deleted: any;
    company_name: string;
    group_dealers: [];
}

export class GroupDealersProvider {
    data: GroupDealers[];
    _meta: MetaPagination;
    _links: Array<any>;
}
