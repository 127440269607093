import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-month-range-picker',
    templateUrl: './month-range-picker.component.html',
    styleUrls: ['./month-range-picker.component.css'],
})
export class MonthRangePickerComponent implements OnInit, OnChanges {
    @Output() selected = new EventEmitter<any>();
    @Input() apiFormat = 'YYYY-MM';
    @Input() localFormat = 'MM/YYYY';
    @Input() ranges = {
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 12 Months': [
            moment().subtract(12, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
        ],
    };
    @Input() defaultMonthRange = [
        moment().subtract(3, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
    ];
    @Input() defaultSingleMonth = moment();
    @Input() maxSpan = 12;
    @Input() singleMonthPicker = false;
    @Input() separator = '-';
    @Input() startDate = environment.startDate;
    @Input() endDate;
    @Input() disabled = false;
    currentYearIndex: number;
    selectedYearIndex: number;
    selectedRange;
    dateRangeValue;
    years: Array<number>;
    months: Array<string>;
    monthsData: Array<{
        monthName: string;
        monthYear: number;
        dateFormat: string;
        isInRange: boolean;
        isLowerEdge: boolean;
        isUpperEdge: boolean;
        isSingle: boolean;
    }>;
    rangeIndexes: Array<number>;
    monthViewSlicesIndexes: Array<number>;
    monthDataSlice: Array<{
        monthName: string;
        monthYear: number;
        dateFormat: string;
        isInRange: boolean;
        isLowerEdge: boolean;
        isUpperEdge: boolean;
        isSingle: boolean;
    }>;
    globalIndexOffset: number;
    showPicker = false;
    dateRange;
    activeRange: number;
    selectedRangeIndexes;
    wasInside = false;
    emittedRange = null;

    constructor(private eRef: ElementRef) {}

    ngOnInit(): void {
        this.initYearLabels();
        this.initMonthLabels();
        this.initViewSlices();
        this.initMonthsData();
        this.initRangeIndexes();
        this.sliceDataIntoView();
        this.setDefaultValue();
        if (this.disabled) {
            this.setCurrentMonthAndYear();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.updateDateRangeValue();
        }
    }

    updateDateRangeValue(): void {
        if (this.disabled) {
            const currentDate = moment();
            this.dateRangeValue = currentDate.format('MM/YYYY');
        }
    }

    @HostListener('click')
    clickInside() {
        this.wasInside = true;
    }

    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.cancel();
        }
        this.wasInside = false;
    }

    getListOfRanges() {
        return Object.keys(this.ranges);
    }

    setDate(indexClicked) {
        if (this.disabled) return;
        if (this.rangeIndexes[0] === null) {
            this.rangeIndexes[0] = this.globalIndexOffset + indexClicked;
            if (this.singleMonthPicker) {
                this.setSingleMonthDate(indexClicked, this.rangeIndexes[0]);
            }
        } else if (!this.singleMonthPicker && this.rangeIndexes[1] === null) {
            this.rangeIndexes[1] = this.globalIndexOffset + indexClicked;
            this.rangeIndexes.sort((a, b) => a - b);
            this.setMonthConfiguration();
            this.setRangeDate();
        } else {
            this.initRangeIndexes();
            this.initMonthsData();
            this.setDate(indexClicked);
            this.sliceDataIntoView();
        }
    }

    setMonthConfiguration() {
        if (this.rangeIndexes?.length > 0) {
            this.monthsData.forEach((month, index) => {
                if (this.rangeIndexes[0] <= index && index <= this.rangeIndexes[1]) {
                    month.isInRange = true;
                }
                if (this.rangeIndexes[0] === index && this.rangeIndexes[1] === index) {
                    month.isSingle = true;
                } else {
                    if (this.rangeIndexes[0] === index) {
                        month.isLowerEdge = true;
                    }
                    if (this.rangeIndexes[1] === index) {
                        month.isUpperEdge = true;
                    }
                }
            });
        }
    }

    setRangeDate() {
        const fromMonthYear = this.monthsData[this.rangeIndexes[0]];
        const toMonthYear = this.monthsData[this.rangeIndexes[1]];
        const startDate = this.setDateFormat(fromMonthYear.monthName, fromMonthYear.monthYear, this.apiFormat);
        const endDate = this.setDateFormat(toMonthYear.monthName, toMonthYear.monthYear, this.apiFormat);
        const range = { start: startDate, end: endDate };
        this.dateRange = range;
        this.selectedRange =
            this.setDateFormat(fromMonthYear.monthName, fromMonthYear.monthYear, this.localFormat) +
            this.separator +
            this.setDateFormat(toMonthYear.monthName, toMonthYear.monthYear, this.localFormat);
    }

    setSingleMonthDate(indexClicked, rangeIndex) {
        this.monthsData.forEach((month, index) => {
            if (rangeIndex === index) {
                month.isSingle = true;
            }
        });
        const selectedMonth = this.monthsData[rangeIndex];
        const monthDate = this.setDateFormat(selectedMonth.monthName, selectedMonth.monthYear, this.apiFormat);
        this.dateRange = monthDate;
        this.selectedRange = this.setDateFormat(selectedMonth.monthName, selectedMonth.monthYear, this.localFormat);
    }

    setDateFormat(monthName, year, format) {
        const month = moment().month(monthName).format('MM');
        return moment(year + '-' + month + '-01T00:00:00.000Z').format(format);
    }

    emitData(range) {
        this.dateRangeValue = this.selectedRange;
        this.emittedRange = range;
        this.selected.emit(range);
    }

    sliceDataIntoView() {
        this.globalIndexOffset = this.monthViewSlicesIndexes[this.currentYearIndex];
        this.monthDataSlice = this.monthsData.slice(this.globalIndexOffset, this.globalIndexOffset + 24);
    }

    incrementYear() {
        if (this.currentYearIndex !== this.years.length - 1) {
            this.currentYearIndex++;
            this.sliceDataIntoView();
        }
    }

    decrementYear() {
        if (this.currentYearIndex !== 0) {
            this.currentYearIndex--;
            this.sliceDataIntoView();
        }
    }

    initRangeIndexes() {
        this.rangeIndexes = [null, null];
    }

    initMonthsData() {
        this.monthsData = new Array();
        this.years.forEach((year) => {
            this.months.forEach((month) => {
                this.monthsData.push({
                    monthName: month,
                    monthYear: year,
                    dateFormat: this.setDateFormat(month, year, this.apiFormat),
                    isInRange: false,
                    isLowerEdge: false,
                    isUpperEdge: false,
                    isSingle: false,
                });
            });
        });
    }

    initYearLabels() {
        const currentYear = moment().year();
        const range = (start, stop, step) =>
            Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
        this.years = range(currentYear - 100, currentYear + 100, 1);
        this.currentYearIndex = this.years.findIndex((year) => year === currentYear);
    }

    initMonthLabels() {
        this.months = moment.monthsShort();
    }

    initViewSlices() {
        this.monthViewSlicesIndexes = [];
        this.years.forEach((year, index) => {
            if (index === 0) {
                this.monthViewSlicesIndexes.push(0);
            } else if (index === 1) {
                this.monthViewSlicesIndexes.push(6);
            } else {
                this.monthViewSlicesIndexes.push(this.monthViewSlicesIndexes[index - 1] + 12);
            }
        });
    }

    cancel() {
        this.showPicker = false;
        this.selectedRange = this.dateRangeValue;
        this.rangeIndexes = this.selectedRangeIndexes;
        this.currentYearIndex = this.selectedYearIndex;
        this.initMonthsData();
        this.sliceDataIntoView();
        this.setMonthConfiguration();
    }

    apply() {
        this.showPicker = false;
        this.selectedRangeIndexes = this.rangeIndexes;
        this.selectedYearIndex = this.currentYearIndex;
        this.emitData(this.dateRange);
    }

    selectRange(range, index) {
        if (this.disabled) return;
        const dateRange = range ? this.ranges[range] : [];
        this.activeRange = index;
        this.setRange(dateRange);
    }

    setRange(range: any[] = []) {
        if (range.length > 0) {
            range.forEach((dateValue) => {
                this.setCurrentView(dateValue);
            });
            this.apply();
        }
    }

    setDefaultSingleMonth(date) {
        this.setCurrentView(date);
        this.apply();
    }

    setCurrentView(date) {
        const index = this.monthsData.findIndex(
            (item) =>
                item.monthName === moment(date).format('MMM') && item.monthYear === Number(moment(date).format('YYYY')),
        );
        this.currentYearIndex = this.years.findIndex((year) => year === this.monthsData[index].monthYear);
        this.sliceDataIntoView();
        const selectedIndex = this.monthDataSlice.findIndex(
            (item) =>
                item.monthName === moment(date).format('MMM') && item.monthYear === Number(moment(date).format('YYYY')),
        );
        this.setDate(selectedIndex);
    }

    setClassName(month, i) {
        return {
            isEdge:
                this.rangeIndexes[0] !== null &&
                (this.rangeIndexes[0] === this.globalIndexOffset + i ||
                    this.rangeIndexes[1] === this.globalIndexOffset + i),
            notCurrentYear: this.currentYearIndex === 0 ? i > 11 : i < 6 || i > 17,
            inRange: month.isInRange,
            isLowerEdge: month.isLowerEdge,
            isUpperEdge: month.isUpperEdge,
            isSingle: month.isSingle,
        };
    }

    disabledMonth(month) {
        if (
            (this.startDate && moment(this.startDate).diff(month.dateFormat, 'months') > 0) ||
            (this.endDate && moment(this.endDate).diff(month.dateFormat, 'months') < 0)
        ) {
            return true;
        }
        if (!this.singleMonthPicker && this.rangeIndexes[0] !== null && this.rangeIndexes[1] == null) {
            const selectedMonth = this.monthsData[this.rangeIndexes[0]].dateFormat;
            const maxSpan = moment(selectedMonth)
                .add(this.maxSpan - 1, 'M')
                .format(this.apiFormat);
            const minSpan = moment(selectedMonth)
                .add(-this.maxSpan - 1, 'M')
                .format(this.apiFormat);
            if (
                moment(maxSpan).diff(month.dateFormat, 'months') <= -1 ||
                moment(minSpan).diff(month.dateFormat, 'months') >= -1
            ) {
                return true;
            }
        }
        return false;
    }

    showMonthPicker() {
        this.showPicker = true;
    }

    setDefaultValue() {
        if (this.singleMonthPicker) {
            this.setDefaultSingleMonth(this.defaultSingleMonth);
        } else {
            this.setRange(this.defaultMonthRange ? this.defaultMonthRange : []);
        }
    }

    setCurrentMonthAndYear() {
        const currentDate = moment();
        const currentMonthName = currentDate.format('MMM');
        const currentYear = currentDate.year();
        const index = this.monthsData.findIndex(
            (item) => item.monthName === currentMonthName && item.monthYear === currentYear,
        );

        this.currentYearIndex = this.years.findIndex((year) => year === currentYear);
        this.sliceDataIntoView();

        if (index !== -1) {
            this.setDate(index - this.globalIndexOffset);
            this.dateRangeValue = `${currentMonthName} ${currentYear}`;
        }
    }
}
