import {Component, Input, OnInit} from '@angular/core';
import {DealerService} from '@services/dealer.service';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-dealer-overview',
    templateUrl: './dealer-overview.component.html',
    styleUrls: ['./dealer-overview.component.css']
})
export class DealerOverviewComponent implements OnInit {
    @Input() id: string;

    data: any = null;
    loading = false;
    defaultMonthRange: any[] = [];

    filterForm: any = {
        from: moment().startOf('year').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
    }

    constructor(
        private service: DealerService,
    ) {
    }

    ngOnInit(): void {
        this.defaultMonthRange = [moment(), moment()];
        if(this.id) {
            this.reload();
        }
    }

    setSelectedDateRange(from: any, to: any) {
        this.filterForm.from = from;
        this.filterForm.to = to;
        this.reload();
    }

    reload() {
        this.loading = true;
        this.service.getOverview(this.id, {
            from: moment(this.filterForm.from).startOf('month').format('YYYY-MM-DD'),
            to:  moment(this.filterForm.to).endOf('month').format('YYYY-MM-DD')
        }).subscribe(data => {
            this.data = data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    protected readonly self = self;
}
