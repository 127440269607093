import { Component, OnInit, ViewChild } from '@angular/core';
import { KpiDashboardData, NuoveVisiteItem } from '@app/models/kpi-dashboard-data';
import { DashboardService } from '@app/services/dashboard.service';
import { MonthRangePickerComponent } from '@app/widgets/month-range-picker/month-range-picker.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
    selector: 'app-kpi-dashboard',
    templateUrl: './kpi-dashboard.component.html',
    styleUrls: ['./kpi-dashboard.component.scss'],
})
export class KpiDashboardComponent implements OnInit {
    @ViewChild(MonthRangePickerComponent) monthRangePicker: MonthRangePickerComponent;

    defaultMonth;
    period;
    apiFormat = 'YYYY-MM-DD';
    date: {
        full_month: string;
        month: string;
        year: string;
        prev_month: string;
        prev_year: string;
        from_date: string;
        to_date: string;
    };

    isLoading: boolean = false;
    dashboardData: KpiDashboardData;

    pafToggle: boolean = false;

    constructor(
        private service: DashboardService,
        private spinner: NgxSpinnerService,
        public modalService: NgbModal,
        private router: Router,
    ) {
        this.defaultMonth = [moment().startOf('month')];
    }

    ngOnInit() {
        const currentDate = moment();
        this.setSelectedDateRange(currentDate);
    }

    loadData() {
        this.spinner.show('dashboard');
        const params = {
            from_date: this.date.from_date,
            to_date: this.date.to_date,
            paf: this.pafToggle,
        };

        this.service.getDashboardData(params).subscribe(
            (res: KpiDashboardData) => {
                this.dashboardData = res;
                this.spinner.hide('dashboard');
            },
            (err) => {
                console.log('Errore: ', err);
                this.spinner.hide('dashboard');
            },
        );
    }

    setSelectedDateRange(date: any) {
        const startDate = moment(date).startOf('month');
        const endDate = moment(date).endOf('month');

        this.date = {
            full_month: startDate.format('MMMM'),
            month: startDate.format('MM'),
            year: startDate.format('YYYY'),
            prev_month: moment(startDate).subtract(1, 'months').format('MM'),
            prev_year: moment(startDate).subtract(1, 'months').format('YYYY'),
            from_date: startDate.format(this.apiFormat),
            to_date: endDate.format(this.apiFormat),
        };

        this.period = startDate.format('YYYY-MM');
        this.loadData();
    }

    togglePaf() {
        this.pafToggle = !this.pafToggle;
        const date = new Date(this.defaultMonth);
        const monthNumber = date.getMonth() + 1;
        const startDate = moment(date).startOf('month');
        this.monthRangePicker.setDate(monthNumber + 5);
        this.setSelectedDateRange(startDate.format('YYYY-MM'));
    }

    openListDetail(id: number) {
        this.router.navigate(['shop-list-detail', id]);
    }
}
