<div class="card">
  <div class="card-body">
    <div class="d-flex align-items-lg-center">
      <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted" src="assets/img/flats/payment.png" width="160px" />
      <div class="d-block w-100 pl-lg-3">
        <h1 class="display-4">Ordini con Fido</h1>
        <hr />
        <div class="form-row">
          <div class="col-12 col-lg-3">
            <label>Tipologia</label>
            <ng-select [clearable]="true" [(ngModel)]="suretyTypeSelectedValue" (change)="onSuretyChange()">
              <ng-option *ngFor="let item of suretyTypes" [value]="item.value">{{ item.label }}</ng-option></ng-select
            >
          </div>
          <div class="col-12 col-lg-3">
            <label>Punto Vendita</label>
            <app-retailer-typeahead (onChange)="onDealerChange($event)"></app-retailer-typeahead>
          </div>
          <div class="col-12 col-lg-3">
            <div class="form-group">
              <label>Stato</label>
              <ng-select [clearable]="true" class="disableClear" [(ngModel)]="filter.stato" [items]="statuses" bindLabel="label" bindValue="value"> </ng-select>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="form-group">
              <label>Saldato</label>
              <ng-select [clearable]="true" class="disableClear" bindLabel="label" bindValue="value" class="disableClear" [items]="weldedStatuses" [(ngModel)]="filter.welded">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="form-row mt-2">
          <div class="col-12 col-lg-4">
            <label>Data di scadenza</label>
            <input
              type="text"
              daterangepicker
              [options]="pickerOptions"
              [(ngModel)]="filter.welding_date"
              name="date"
              class="form-control"
              placeholder="Seleziona una data"
              autocomplete="off"
              (selected)="generateDateRangeString($event.start, $event.end, true)"
            />
          </div>
          <div class="col-12 col-lg-4">
            <label>Data di creazione</label>
            <input
              type="text"
              daterangepicker
              [options]="pickerOptions"
              [(ngModel)]="filter.created"
              name="date"
              class="form-control"
              placeholder="Seleziona una data"
              autocomplete="off"
              (selected)="generateDateRangeString($event.start, $event.end, false)"
            />
          </div>
          <div class="col-12 col-lg-4 d-flex align-items-center justify-content-center mt-4">
            <button class="btn btn-info btn-block" translate (click)="loadOrders()">Filter</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body p-0 rounded-bottom table-responsive">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table" [fullScreen]="false">
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>

    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th>Dealer</th>
          <th>Tipo Fido</th>
          <th>Importo</th>
          <th>Data Creazione</th>
          <th>Data Scadenza</th>
          <th>Saldato</th>
          <th>Stato</th>
        </tr>
      </thead>
      <tbody class="bg-gradient-white">
        <tr *ngFor="let order of ordersList">
          <td>
            <a [routerLink]="['/dealer', order.dealer_id]" target="_blank">
              {{ order.ragsociale }}
            </a>
          </td>
          <td>
            <p *ngIf="order.type == 'plafond-kena'">Kena</p>
            <p *ngIf="order.type == 'prodotti'">Prodotti</p>
            <p *ngIf="order.type == ''">-</p>
          </td>
          <td>{{ order.totale_ordine | currency : '€' }}</td>
          <td>{{ order.created | amDateFormat : 'DD/MM/YYYY' }}</td>
          <td>{{ order.welding_date | amDateFormat : 'DD/MM/YYYY' }}</td>
          <td class="text-center">
            <i *ngIf="order.welded == 1" class="fa fa-check text-success mr-5"></i>
            <i *ngIf="order.welded == 0" class="fa fa-times text-danger mr-5"></i>
          </td>
          <td>{{ order.stato_nome }}</td>
        </tr>
        <tr *ngIf="ordersList?.length <= 0">
          <td translate colspan="10" class="text-muted">List is empty</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" *ngIf="ordersList?.length > 0 && pagination">
    <span
      ><span translate>There are</span><b> {{ pagination.totalCount }}</b> {{ pagination.totalCount <= 1 ? 'order' : 'orders' }}.</span
    >
    <ngb-pagination
      *ngIf="pagination.pageCount > 1"
      size="sm"
      [pageSize]="pagination.perPage"
      [maxSize]="10"
      [(page)]="pagination.currentPage"
      [collectionSize]="pagination.totalCount"
      (pageChange)="loadOrders()"
      [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
</div>
