<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add Product</h4>
    <button (click)="dismissModal()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <form [formGroup]="productForm" (ngSubmit)="saveProduct()">
        <div class="form-row">
            <div class="col-12 col-lg-12 form-group">
                <label for="productName" translate>Name</label>
                <input type="text" class="form-control" id="productName" formControlName="name">
                <val-errors controlName="name">
                    <ng-template valError="required">
                        <span translate>Name is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="price" translate>Price</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="price" aria-describedby="basic-addon"
                           formControlName="price">
                    <div class="input-group-append">
                        <div class="input-group-text bg-white" id="basic-addon"><strong>€</strong></div>
                    </div>
                </div>
                <val-errors controlName="price">
                    <ng-template valError="required">
                        <span translate>Price is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="price" translate>Dealer price</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="dealerPrice" aria-describedby="basic-addon2"
                           formControlName="dealer_price">
                    <div class="input-group-append">
                        <div class="input-group-text bg-white" id="basic-addon2"><strong>€</strong></div>
                    </div>
                </div>
                <val-errors controlName="dealer_price">
                    <ng-template valError="required">
                        <span translate>Price is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="price" translate>Our price</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="ourPrice" aria-describedby="basic-addon3"
                           formControlName="our_price">
                    <div class="input-group-append">
                        <div class="input-group-text bg-white" id="basic-addon3"><strong>€</strong></div>
                    </div>
                </div>
                <val-errors controlName="our_price">
                    <ng-template valError="required">
                        <span translate>Price is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12">
                <label for="brand" translate>Brand 2</label>
            </div>
            <div class="col-12 col-lg-8 form-group">
                <ng-select id="brand" formControlName="brand_id" bindValue="id" bindLabel="name"
                           [items]="brands" (search)="loadBrands($event?.term)" [loading]="brandsLoading"></ng-select>
                <val-errors controlName="brand_id">
                    <ng-template valError="required">
                        <span translate>Brand is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-lg-4 form-group">
                <button type="button" class="btn btn-secondary btn-block" translate (click)="showBrandModal()">
                    Add New Brand
                </button>
            </div>
            <div class="col-12">
                <label for="category" translate>Category</label>
            </div>
            <div class="col-12 col-lg-8 form-group">
                <ng-select id="category" formControlName="category_id" bindValue="id" bindLabel="name"
                           [items]="categories" (search)="loadCategories($event?.term)"
                           [loading]="categoriesLoading"></ng-select>
                <val-errors controlName="category_id">
                    <ng-template valError="required">
                        <span translate>Category is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <button type="button" class="btn btn-secondary btn-block" translate (click)="showCategoryModal()">
                    Add New Category
                </button>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="type" translate>Type</label>
                <ng-select id="type" formControlName="type">
                    <ng-option *ngFor="let productType of productTypes" [value]="productType.key"
                               translate>{{productType.name}}</ng-option>
                </ng-select>
                <val-errors controlName="type">
                    <ng-template valError="required">
                        <span translate>Type is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="vatType" translate>VAT type</label>
                <input type="text" class="form-control" id="vatType" formControlName="vat_type">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="ean" translate>EAN code</label>
                <input type="text" class="form-control" id="ean" formControlName="ean">
                <val-errors controlName="ean">
                    <ng-template valError="required">
                        <span translate>EAN is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="pan" translate>PAN code</label>
                <input type="text" class="form-control" id="pan" formControlName="pan">
                <val-errors controlName="pan">
                    <ng-template valError="required">
                        <span translate>PAN is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="productImage" translate>Image</label>
                <div class="custom-file" *ngIf="!imageName">
                    <input id="productImage" accept="image/*" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0])">
                    <label class="custom-file-label" for="productImage" translate>
                        Upload image...
                    </label>
                </div>
                <div *ngIf="imageName">
                    <span>{{imageName}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button" (click)="handleFileRemoveModal()">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
            </div>
            <div class="col-12 col-lg-3 form-group">
                <label for="model" translate>Model</label>
                <input type="text" class="form-control" id="model" formControlName="model">
                <val-errors controlName="model">
                    <ng-template valError="required">
                        <span translate>Model is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="form-group d-flex flex-column align-items-center justify-content-center">
                <label for="productStatus" class="mb-2" translate>Active</label>
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="productStatus" formControlName="is_active">
                    <label class="custom-control-label" for="productStatus"></label>
                </div>
            </div>
            
            <div class="col-12 form-group">
                <label for="productDescription" translate>Description</label>
                <editor [init]="{base_url: '/tinymce', suffix: '.min'}" id="productDescription" formControlName="description"></editor>
            </div>
            <div class="col-12 col-lg-8 form-group">
                <h5 translate>Distributor:</h5>
            </div>
            <div class="col-12 col-lg-4 form-group d-flex align-items-end">
                <button type="button" class="btn btn-secondary btn-block" translate (click)="showDistributorModal()">
                    Add Distributor
                </button>
            </div>
            <div class="col-12">
                <div class="variation-container">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0" *ngFor="let distributor of distributors;index as i">
                            {{distributor?.company_name ? distributor?.company_name : distributor?.dealer_id}}
                            <span class="float-right">
                          <strong class="mr-4">{{distributor?.amount|currency:currency}}</strong>
                               <button type="button" class="btn btn-link text-danger m-0 p-0"
                                       (click)="removeDistributor(i, false)"><i
                                       class="fas fa-trash"></i></button>
                        </span>
                        </li>
                        <li *ngIf="distributors.length<=0" class="text-muted list-group-item px-0" translate>No
                            distributors
                            added
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-8 form-group">
                <h5 translate>Variants:</h5>
            </div>
            <div class="col-12 col-lg-4 form-group d-flex align-items-end">
                <button type="button" class="btn btn-secondary btn-block" translate (click)="showVariationModal()">
                    Add Variant
                </button>
            </div>
            <div class="col-12">
                <div class="variation-container">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0" *ngFor="let variant of variants;index as i">
                            {{variant?.name}}
                            <span class="float-right">
                          <strong class="mr-4">{{variant?.price|currency:currency}}</strong>
                               <button type="button" class="btn btn-link text-danger m-0 p-0"
                                       (click)="removeVariation(i, variant)"><i
                                       class="fas fa-trash"></i></button>
                        </span>
                            <p class="text-muted" [innerHTML]="variant.description"></p>
                            <p *ngIf="variant?.product_prices?.length>0">Distributor: </p>
                            <ul class="list-group list-group-flush" *ngIf="variant?.product_prices?.length>0">
                                <li class="list-group-item px-0"
                                    *ngFor="let productPrice of variant?.product_prices;index as i">
                                    {{productPrice?.company_name}}
                                    <span class="float-right">
                          <strong class="mr-4">{{productPrice?.amount|currency:currency}}</strong>
                        </span>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="variants?.length<=0" class="text-muted list-group-item px-0" translate>No variants
                            added
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 mt-2">
                <button type="submit" class="btn btn-success btn-block" [disabled]="productForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
            </div>
        </div>
    </form>
</div>

<ng-template #categoryFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add Category</h4>
        <button
                type="button"
                class="close"
                aria-label="Close" (click)="categoryModal.dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyCategory">
        <form [formGroup]="categoryForm" (ngSubmit)="saveCategory()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="categoryName" translate>Name</label>
                    <input type="text" class="form-control" id="categoryName" formControlName="name">
                    <val-errors controlName="name">
                        <ng-template valError="required">
                            <span translate>Name is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 form-group">
                    <label for="categoryDescription" translate>Description</label>
                    <editor [init]="{base_url: '/tinymce', suffix: '.min'}" id="categoryDescription" formControlName="description"></editor>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="categoryForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #variationFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add Variant</h4>
        <button
                type="button"
                class="close"
                aria-label="Close" (click)="variationModal.dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="variationForm" (ngSubmit)="saveVariation()">
            <div class="form-row">
                <div class="col-12 col-lg-12 form-group">
                    <label for="variationName" translate>Name</label>
                    <input type="text" class="form-control" id="variationName" formControlName="name">
                    <val-errors controlName="name">
                        <ng-template valError="required">
                            <span translate>Name is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-4 form-group">
                    <label for="vatTypeVariant" translate>VAT type</label>
                    <input type="text" class="form-control" id="vatTypeVariant" formControlName="vat_type">
                </div>
                <div class="col-12 col-lg-4 form-group">
                    <label for="variationPrice" translate>Price</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="variationPrice" aria-describedby="basic-addon1"
                               formControlName="price">
                        <div class="input-group-append">
                            <div class="input-group-text bg-white" id="basic-addon1"><strong>€</strong></div>
                        </div>
                    </div>
                    <val-errors controlName="price">
                        <ng-template valError="required">
                            <span translate>Price is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-4 form-group">
                    <label for="dealerPriceVariant" translate>Dealer price</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="dealerPriceVariant" aria-describedby="basic-addon5"
                               formControlName="dealer_price">
                        <div class="input-group-append">
                            <div class="input-group-text bg-white" id="basic-addon5"><strong>€</strong></div>
                        </div>
                    </div>
                    <val-errors controlName="dealer_price">
                        <ng-template valError="required">
                            <span translate>Price is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-4 form-group">
                    <label for="ourPriceVariant" translate>Our price</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="ourPriceVariant" aria-describedby="basic-addon6"
                               formControlName="our_price">
                        <div class="input-group-append">
                            <div class="input-group-text bg-white" id="basic-addon6"><strong>€</strong></div>
                        </div>
                    </div>
                    <val-errors controlName="our_price">
                        <ng-template valError="required">
                            <span translate>Price is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-4 form-group">
                    <label for="eanVariant" translate>EAN code</label>
                    <input type="text" class="form-control" id="eanVariant" formControlName="ean">
                    <val-errors controlName="ean">
                        <ng-template valError="required">
                            <span translate>EAN is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-4 form-group">
                    <label for="panVariant" translate>PAN code</label>
                    <input type="text" class="form-control" id="panVariant" formControlName="pan">
                    <val-errors controlName="pan">
                        <ng-template valError="required">
                            <span translate>PAN is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-4 form-group">
                    <label for="modelVariant" translate>Model</label>
                    <input type="text" class="form-control" id="modelVariant" formControlName="model">
                    <val-errors controlName="model">
                        <ng-template valError="required">
                            <span translate>MODEL is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-4 form-group d-flex flex-column justify-content-start align-items-start">
                    <p translate>Distributor:</p>
                    <button type="button" class="btn btn-secondary btn-block mt-2" translate
                            (click)="showDistributorModal(true)">
                        Add Distributor
                    </button>
                </div>
                <div class="col-12">
                    <div class="variation-container">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item px-0" *ngFor="let distributor of variantDistributors;index as i">
                                {{distributor.dealer_id}}
                                <span class="float-right">
                          <strong class="mr-4">{{distributor.amount|currency:currency}}</strong>
                               <button type="button" class="btn btn-link text-danger m-0 p-0"
                                       (click)="removeDistributor(i, true)"><i
                                       class="fas fa-trash"></i></button>
                        </span>
                            </li>
                            <li *ngIf="variantDistributors?.length<=0" class="text-muted list-group-item px-0" translate>
                                No
                                distributors
                                added
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 form-group">
                    <label for="variationDescription" translate>Description</label>
                    <editor [init]="{base_url: '/tinymce', suffix: '.min'}" id="variationDescription" formControlName="description"></editor>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="variationForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #brandFormTemplate let-modal>
    <app-brand-modal (newBrand)="addBrand($event)" [brandModal]="brandModal"></app-brand-modal>
</ng-template>
<ng-template #distributorFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add distributor price</h4>
        <button
                type="button"
                class="close"
                aria-label="Close" (click)="distributorModal.dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyDistributor">
        <form [formGroup]="distributorForm" (ngSubmit)="saveDistributorPrice()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="dealer" translate>Shop</label>
                    <app-retailer-typeahead (onChange)="changeDealer($event)" id="dealer"></app-retailer-typeahead>
                    <val-errors controlName="dealer_id">
                        <ng-template valError="required">
                            <span translate>Shop is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 form-group">
                    <label for="amount" translate>Price</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="amount" aria-describedby="basic-addon4"
                               formControlName="amount">
                        <div class="input-group-append">
                            <div class="input-group-text bg-white" id="basic-addon4"><strong>€</strong></div>
                        </div>
                    </div>
                    <val-errors controlName="amount">
                        <ng-template valError="required">
                            <span translate>Price is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="distributorForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
