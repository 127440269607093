import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class KpiService {
    ENDPOINT = 'kpi';

    constructor(public request: RequestService, public alertService: AlertService) {}

    overview(payload) {
        return this.request.post(`${this.ENDPOINT}/overview`, payload);
    }

    salesAgentTable(id, operatore, periodo, type) {
        return this.request.get(`${this.ENDPOINT}/agente`, {
            id,
            operatore,
            periodo,
            type,
        });
    }

    saveTarget(value: any) {
        return this.request.post(`${this.ENDPOINT}/save-target`, value);
    }

    public getKpiSlugList() {
        return this.request.get('v3/kpi-type/kpi-slug-list');
    }

    public getKpiCategoryList() {
        return this.request.get('v3/kpi-type/kpi-category-list');
    }

    public getKpiTypeList(params?): Observable<any> {
        return this.request.get('v3/kpi-type/list', params);
    }

    public getKpiTypeListForRecap(params?): Observable<any> {
        return this.request.get('v3/kpi-type/list-recap', params);
    }

    public postKpiTypeList(payload: any): Observable<any> {
        return this.request.post('v3/kpi-type/create', payload);
    }

    public putKpiTypeList(id, body: {}) {
        return this.request.post('v3/kpi-type/update/' + id, body);
    }

    public getAreaManagerRanking(params?): Observable<any> {
        return this.request.get(`v3/kpi-type/am-ranking`, params);
    }

    public getKeyAccountRanking(params?): Observable<any> {
        return this.request.get(`v3/kpi-type/ka-ranking`, params);
    }

    deleteKpiItem(id: number) {
        return this.request.delete(`v3/kpi-type/delete/${id}`);
    }

    selectKpiOptions(params = {}) {
        return this.request.get('v3/kpi-type/select-kpi-options', params);
    }

    public getAssociaTarget(params?): Observable<any> {
        return this.request.get('v3/kpi/list', params);
    }

    public getDealersKpiList(params?): Observable<any> {
        return this.request.get('v3/kpi/dealers-kpi-list', params);
    }

    public postAssociaTarget(payload: any): Observable<any> {
        return this.request.post('v3/kpi/create', payload);
    }

    public putTargetAssociato(id, body: {}): Observable<any> {
        return this.request.put('v3/kpi/update/' + id, body);
    }

    deleteTargetAssegnato(id: number) {
        return this.request.delete(`v3/kpi/delete/${id}`);
    }

    getDcSelect() {
        return this.request.get('v3/kpi/dc-select');
    }

    // KPI NOTES

    public listKpiNotes(params?): Observable<any> {
        return this.request.get('v3/kpi-notes/list', params);
    }

    public createKpiNotes(payload: any): Observable<any> {
        return this.request.post('v3/kpi-notes/create', payload);
    }

    public updateKpiNotes(id, body: {}): Observable<any> {
        return this.request.put('v3/kpi-notes/update/' + id, body);
    }

    deleteKpiNotes(id: number) {
        return this.request.delete(`v3/kpi-notes/delete/${id}`);
    }

    //

    public successKpiAlert(text) {
        this.alertService.show('', 'KPI creato ' + text, { classname: 'bg-success text-light', delay: 4000 });
    }

    public successKpiUpdateAlert(text) {
        this.alertService.show('', 'KPI modificato ' + text, { classname: 'bg-success text-light', delay: 4000 });
    }

    public successAlert(text) {
        this.alertService.show('', 'KPI cancellato ' + text, { classname: 'bg-success text-light', delay: 4000 });
    }

    public reloadAlert(text) {
        this.alertService.show('', text, { classname: 'bg-success text-light', delay: 4000 });
    }

    public successAlertTarget(text) {
        this.alertService.show('', 'Target cancellato ' + text, { classname: 'bg-success text-light', delay: 4000 });
    }

    public successAlertCreate(text) {
        this.alertService.show('', 'Hai assegnato il target ' + text, { classname: 'bg-success text-light', delay: 4000 });
    }

    public errorAlert(message?) {
        this.alertService.show('An error occurred!', message ? message : 'Try again!', { classname: 'bg-danger text-light', delay: 2000 });
    }
}
