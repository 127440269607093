<button type="button" class="btn btn-link text-dark text-decoration-none p-0"
        [ngbPopover]="additionalValueContent" popoverTitle="Altre informazioni"
        [disabled]="isDisabled()"
        autoClose="outside">
    {{formatNumber(item?.real)}}
    <app-plan-percentage [isCurrentMonth]="isCurrentMonth"
            [item]="item"></app-plan-percentage>
</button>
<ng-template #additionalValueContent><span translate>Proietti a: </span><span
        class="text-primary">{{formatNumber(additionalValue)}}</span>
</ng-template>
