import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

const version = 'v3';

@Injectable({
  providedIn: 'root',
})
export class AmazonCounterService {
  constructor(private request: RequestService, public alertService: AlertService) {}

  public getAmazonCounterOrders(params?): Observable<any> {
    return this.request.get('v2/amazon-counter/list', params);
  }
  public putAmazonCounterOrder(id, body: {}) {
    return this.request.put('v2/amazon-counter/update/' + id, body);
  }
}
