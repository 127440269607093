import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StoreProduct, StoreProductTemplate} from '@models/store/store-product';
import {StoreService} from '@services/store.service';
import {ConfirmOptionsService} from '@services/confirm-options.service';

@Component({
    selector: 'app-shop-product-template',
    templateUrl: './shop-product-template.component.html',
    styleUrls: ['./shop-product-template.component.css']
})
export class ShopProductTemplateComponent implements OnInit {
    @Input() product: StoreProduct;
    busy: any;
    productTemplateForm = new FormGroup({
        name: new FormControl('', Validators.required),
        template: new FormControl('', Validators.required),
        info: new FormControl(''),
        discount: new FormControl(''),
    });
    submittedFile: File;
    @ViewChild('productTemplate') private productTemplate;
    templateModal: NgbModalRef;
    selectedTemplate: StoreProductTemplate;
    deleteProductOptions: ConfirmOptionsService;

    constructor(public modalService: NgbModal,
                private storeService: StoreService) {
        this.deleteProductOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    ngOnInit(): void {
    }

    showTemplateModal(template?: StoreProductTemplate) {
        this.selectedTemplate = template;
        this.refreshTemplateForm();
        this.templateModal = this.modalService.open(this.productTemplate, {size: 'lg'});
        if (this.selectedTemplate) {
            this.productTemplateForm.setValue({
                name: this.selectedTemplate?.nome,
                template: this.selectedTemplate?.template,
                info: this.selectedTemplate?.info,
                discount: this.selectedTemplate?.sconto,
            });
        }
    }

    saveTemplate() {
        const template = new StoreProductTemplate();
        template.id_prodotto = this.product.id;
        template.nome = this.productTemplateForm.value.name;
        template.info = this.productTemplateForm.value.info;
        template.sconto = this.productTemplateForm.value.discount;
        template.file = this.submittedFile;
        if (this.selectedTemplate) {
            return this.busy = this.storeService.putProductTemplate(this.selectedTemplate?.id, template).subscribe(
                (response: StoreProductTemplate) => {
                    const index = this.product?.templates.findIndex(data => data.id === response?.id);
                    if (index >= 0) {
                        this.product.templates[index] = response;
                    }
                    this.templateModal.dismiss();
                }, error => {
                    this.templateModal.dismiss();
                });
        }
        return this.busy = this.storeService.postProductTemplate(template).subscribe(
            (response: StoreProductTemplate) => {
                this.product.templates.unshift(response);
                this.templateModal.dismiss();
            }, error => {
                this.templateModal.dismiss();
            });
    }

    deleteTemplate(id) {
        this.storeService.deleteProductTemplate(id).subscribe(() => {
            const index = this.product?.templates?.findIndex(product => product.id === id);
            if (index >= 0) {
                this.product?.templates?.splice(index, 1);
            }
        });
    }

    handleFileInput(file: File) {
        this.submittedFile = file;
        this.productTemplateForm.get('template').setValue(file.name);
    }

    handleFileRemoveModal() {
        this.productTemplateForm.get('template').setValue('');
        this.submittedFile = null;
    }

    dismissAll() {
        this.modalService.dismissAll();
    }

    refreshTemplateForm() {
        this.productTemplateForm.setValue({
            name: '',
            template: '',
            info: '',
            discount: '',
        });
        this.submittedFile = null;
    }

    get templateName() {
        return this.productTemplateForm.get('template').value;
    }
}
