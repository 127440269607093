import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import * as moment from 'moment';
import {EPayService} from '@services/e-pay.service';
import {ShopBrand} from '@models/e-pay/shop-brand';
import {AuthenticationService} from '@services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ActivatedRoute} from '@angular/router';
import {User} from '@models/user';
import {KdOrderService} from '@services/kd-order.service';
import {Moment} from 'moment';
import {Packages, PackagesProvider, Status, Tickets} from '@models/kd-orders';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {

  orders: Packages[] = [];
  //selectedOrder: ShopOrder;
  ordersPagination: MetaPagination;

  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    }
  };

  pickerOptions2 = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    }
  };

  selectedData: Packages;
  @ViewChild('detailTemplate') private detailTemplate;

  filter = {
    dealer_id: '',
    date: "",
    status: '',
    shipping_code: "",
    reference: "",
    pickup_date: "",
    dealer_price: "",
    courier: ""
  };


  apiDate = {
    from: '',
    to: ''
  };

  apiDate2 = {
    from_pickup: '',
    to_pickup: ''
  };

  localFormat = 'dd/MM/yyyy HH:MM';

  status = [
    {
      lable: "Completed",
      key: "completed"
    },
    {
      lable: "Failed",
      key: "failed"
    },
    {
      lable: "Pending",
      key: "pending"
    }
  ]

  types = EPayService.productTypes;
  currency = EPayService.currency;
  statuses = Status.list;
  @ViewChild('receiptFormTemplate') private receiptFormTemplate;
  brandsLoading = false;
  brands: ShopBrand[] = [];
  isDealer = false;

  constructor(
      private ePayService: EPayService,
      private authenticationService: AuthenticationService,
      private spinner: NgxSpinnerService,
      public modalService: NgbModal,
      private dateRangePickerOptions: DaterangepickerConfig,
      private PackageService: KdOrderService,
      public route: ActivatedRoute
  ) {
      this.authenticationService.currentUser.subscribe((user: User) => {
        this.isDealer = user && user.profile.role === 'dealer';
      });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadOrders();
    setTimeout(() => {
    });
  }

  loadOrders() {
    this.spinner.show('table-orders');
    const params = {};
    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }
    if (this.filter.shipping_code) {
      params['shipping_code'] = this.filter.shipping_code;
    }
    if (this.filter.reference) {
      params['reference'] = this.filter.reference;
    }
    if (this.filter.pickup_date) {
      params['from_pickup_date'] = this.apiDate2.from_pickup;
      params['to_pickup_date'] = this.apiDate2.to_pickup;
    }
    if (this.filter.dealer_price) {
      params['dealer_price'] = this.filter.dealer_price;
    }
    if (this.filter.courier) {
      params['courier'] = this.filter.courier;
    }
    this.PackageService.getPackages(params).subscribe((response: PackagesProvider) => {
      this.orders = response.data;
      this.ordersPagination = response._meta;
      this.spinner.hide('table-orders');
    }, err => {
      this.spinner.hide('table-orders');
    });
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date = startDate.format(localDateFormat)
        + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  public generateDateRangeString2(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate2.from_pickup = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate2.to_pickup = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.pickup_date = startDate.format(localDateFormat)
        + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  showDetailModal(data?: Packages) {
    this.selectedData = data;
    this.modalService.open(this.detailTemplate, {size: 'lg', scrollable: true});
  }

}
