import { Component, OnInit, ViewChild } from '@angular/core';
import { RiaService } from '@app/services/ria.service';
import { MetaPagination } from '@models/meta-pagination';
import { NgxSpinnerService } from 'ngx-spinner';
import { Ria } from '@app/models/ria';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/models/user';
import { AuthenticationService } from '@app/services/authentication.service';
import { RequestService } from '@app/services/request.service';
import * as XLSX from 'xlsx';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';

@Component({
  selector: 'app-ria',
  templateUrl: './ria.component.html',
  styleUrls: ['./ria.component.css'],
})
export class RiaComponent implements OnInit {
  riaOrders: Ria[] = [];
  ordersPagination: MetaPagination = new MetaPagination();
  deleteSubscriptionOptions: ConfirmOptionsService;
  pickerOptions = {
    singleDatePicker: false,
    ranges: {
      'Last Month': [moment().subtract(1, 'month'), moment()],
      'Last 6 Months': [moment().subtract(6, 'month'), moment()],
      'Last Year': [moment().subtract(12, 'month'), moment()],
    },
  };

  filter = {
    id: '',
    dealer_id: '',
    company_name: '',
    status: '',
    created_at: '',
    date: '',
  };
  apiDate = {
    from: '',
    to: '',
  };

  isDealer = false;
  isEdit = true;
  riaStatus = Ria.riaTypes;

  @ViewChild('popContent') private popContent;
  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };
  router: any;

  constructor(
    public request: RequestService,
    private riaService: RiaService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private dateRangePickerOptions: DaterangepickerConfig,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
  }

  ngOnInit(): void {
    this.filter.dealer_id = this.route.snapshot.paramMap.get('dealerId');
    this.loadOrders();
    setTimeout(() => {});
  }

  loadOrders(hideSpinner = false) {
    this.spinner.show('table-orders');
    const params = {};

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.status) {
      params['status'] = this.filter.status;
    }
    if (this.filter.date) {
      params['from_date'] = this.apiDate.from;
      params['to_date'] = this.apiDate.to;
    }

    this.riaService.getRiaOrders(params).subscribe(
      (response: any) => {
        this.riaOrders = new Ria().fromArray(response.data);
        this.ordersPagination = response._meta;
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  public generateDateRangeString(startDate: Moment, endDate: Moment) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.apiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    this.filter.date =
      startDate.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
  }

  save(value) {
    const body = {};
    body[this.popupConfig['key']] = value;
    this.spinner.show('popupSpinner');
    return this.changeOrder(this.popupConfig.id, body);
  }

  exportSdd() {
    this.spinner.show('table-dealers');
    const params = {
      'per-page': 0,
    };

    if (this.filter.company_name) {
      params['companyName'] = this.filter.company_name;
    }
    if (this.filter.dealer_id) {
      params['dealer_id'] = this.filter.dealer_id;
    }
    if (this.filter.date) {
      params['created_at'] = this.filter.created_at;
    }

    this.riaService.getRiaOrders(params).subscribe((data) => {
      this.spinner.hide('table-dealers');

      let rows = data.data;

      let output = rows.map((row) => {
        return {
          Id: row.id,
          'Ragione Sociale': row.company_name,
          Note: row.notes,
          Data: row.created_at,
          Stato: row.status,
        };
      });

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'export-ria.xlsx');
    });
  }

  deleteRiaSubscription(id: number) {
    this.riaService.deleteRiaSubscription(id).subscribe(
      () => {
        const index = this.riaOrders.findIndex((dealer) => dealer.id === id);
        if (index > -1) {
          this.riaService.successAlert('Deleted Service!');
          this.loadOrders();
        }
      },
      (err) => {
        this.riaService.errorAlert(err.message);
      },
    );
  }

  changeOrder(orderId, body) {
    this.riaService.putRiaOrder(orderId, body).subscribe(
      (response) => {
        this.loadOrders(true);
        this.spinner.hide('popupSpinner');
      },
      (err) => {
        this.loadOrders(true);
        this.spinner.hide('popupSpinner');
      },
    );
  }

  openPopover(popOver: any, orderId, item, key, selectItems = []) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }

  downloadZip(id) {
    this.spinner.show('table-orders');
    this.riaService.getRiaZip(id).subscribe(
      (res: any) => {
        this.request.downloadFile(res, 'application/zip');
        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }
}
