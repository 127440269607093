import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DBColumn, DBColumnSet, DBSortAsc, DBSortDesc, DBTable,} from '@app/models/table';
import {RequestService} from '@app/services/request.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import * as XLSX from 'xlsx';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'data-builder-table',
    templateUrl: './data-builder-table.component.html',
    styleUrls: ['./data-builder-table.component.scss'],
})
export class DataBuilderTableComponent implements OnInit {
    @ViewChild('campaignFormTemplate') private campaignFormTemplate;
    @Output() onRowsChange = new EventEmitter();

    _url;
    _completeUrl;

    somethingChanged = false;

    @Input() set url(value: string) {
        this._url = value;

        if (this._url !== undefined && this._url !== null) {
            this.resetData();
            this.refresh();
        }
    }

    get url() {
        return this._url;
    }

    get completeUrl() {
        return this._completeUrl;
    }

    set completeUrl(value: string) {
        this._completeUrl = value;
    }

    table: DBTable;

    page = 1;
    pageSize = 10;

    sessionSubset = {};
    sessionSort;
    sessionFilters;
    sessionDateRanges;
    sessionProviders = {};
    isLoading = false;

    resetData() {
        this.sessionSubset = {};
        this.sessionSort = null;
        this.sessionFilters = null;
        this.sessionDateRanges = null;
        this.sessionProviders = {};
        this.page = 1;
    }

    dataProviderActions(item) {
        let options = {};

        for (let i = 0; i < 12; i++) {
            const date = moment().subtract(i, 'M');
            options[date.format('YYYY-MM')] = date.format('MMMM YYYY')
        }

        return {
            title: `Scegli il mese che vuoi mostrare per le nuove ${item.label.toLowerCase()}`,
            input: 'select',
            inputOptions: options,
            inputPlaceholder: 'Seleziona il mese',
            showCancelButton: true,
            confirmButtonText: 'Sì',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    constructor(
        private service: RequestService,
        public modalService: NgbModal,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {
    }

    public setUrl(url: string) {
        this.url = url;
    }

    saveAction($event, item) {
        if (!this.sessionProviders.hasOwnProperty(item.key)) {
            this.sessionProviders[item.key] = [];
        }

        this.sessionProviders[item.key].push({
            key: item.key + (this.sessionProviders[item.key].length + 1),
            data: $event
        });

        this.somethingChanged = true;
    }

    saveActionNoCalendar(item) {
        if (!this.sessionProviders.hasOwnProperty(item.key)) {
            this.sessionProviders[item.key] = [];
        }

        this.sessionProviders[item.key].push({
            key: item.key + (this.sessionProviders[item.key].length + 1),
            data: null
        });

        this.refresh();
    }

    setDateRange(col: DBColumn, from: Moment, to: Moment) {
        this.table.dateRanges[col.key] = {
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD'),
        };

        this.sessionDateRanges = this.formatDateRanges(this.table.dateRanges);

        this.somethingChanged = true;
    }

    toggleSort(col: DBColumn) {
        let sort = {};
        if (!this.table.sort.hasOwnProperty(col.key)) {
            sort[col.key] = DBSortDesc;
        } else {
            if (this.table.sort[col.key].toString() === DBSortDesc.toString()) {
                sort[col.key] = DBSortAsc;
            }
        }
        this.table.sort = sort;
        this.sessionSort = this.formatSort(sort);

        this.somethingChanged = true;
    }

    setFilters() {
        this.sessionFilters = this.formatFilters(this.table.filters);
        this.somethingChanged = true;
    }

    formatProviders(providers) {
        let params = {};
        for (let key in providers) {
            let values: string[] = providers[key];

            values.forEach((item) => {
                params[`provider[${key}][${item['key']}]`] = item['data'];
            });
        }
        return params;
    }

    formatSort(sort) {
        let params = {};
        Object.entries(sort).forEach(([key, value]) => {
            params[`sort[${key}]`] = value;
        });
        return params;
    }

    formatFilters(filter) {
        let params = {};

        for (let key in filter) {
            let values: string[] = filter[key];
            if (values.length > 0) {
                params[`filter[${key}]`] = values.join(';');
            }
        }
        return params;
    }

    formatDateRanges(dateRanges) {
        let params = {};

        for (let key in dateRanges) {
            let pair: {} = dateRanges[key];
            params[`date[${key}]`] = `${pair['from']};${pair['to']}`;
        }
        return params;
    }

    updateSubset(col: DBColumnSet, item) {
        this.sessionSubset[`subset[${col.key}]`] = col.subset
            .filter((item) => item.checked)
            .map((item) => item.key)
            .concat(
                col.percentageSubset
                    .filter((item) => item.checked)
                    .map((item) => item.key)
            )
            .join(',');

        this.somethingChanged = true;
            // this.refresh();
    }

    refresh() {
        this.isLoading = true;
        this.spinner.show();

        let params = Object.assign(
            {},
            this.sessionSort,
            this.sessionFilters,
            this.sessionDateRanges,
            this.sessionSubset,
            this.formatProviders(this.sessionProviders),
        );

        const urlParams = new URLSearchParams(params);

        this.completeUrl = this.url + "&" + urlParams;

        this.service.get(this.url, params).subscribe((data) => {
            this.table = new DBTable(data);
            this.table.init();
            this.pageSize = this.table.pageSize;
            this.isLoading = false;
            this.spinner.hide();
            this.onRowsChange.emit(this.table.originalRows);
            this.somethingChanged = false;
        });
    }

    exportTable() {
        let columnsNames = this.table.columns.map((item) => item.label);
        let rows = [columnsNames];

        this.table.rows.forEach((row) => {
            let newRow = [];
            this.table.columns.forEach((col) => {
                newRow.push(row[col.key]);
            });
            rows.push(newRow);
        });

        if(this.table.totalRow) {
            let newRow = [];
            this.table.columns.forEach((col) => {
                newRow.push(this.table.totalRow[col.key]);
            });
            rows.push(newRow);
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Dati');
        XLSX.writeFile(wb, 'esportazione_' + moment().format('DD_MM_YY') + '.xlsx');
    }

    showPbxCampaign() {
        this.modalService.open(this.campaignFormTemplate, {size: 'xl'});
    }

    handleSavedCampaign($event: boolean) {
        console.log($event);
    }

    //replace all params with :param with the value of the row
    convertUrlParams(url, row) {
        return url.replace(/:([^\/]+)/g, (match, key) => {
            return row[key];
        });
    }
}
