import {Component, Input, OnInit} from '@angular/core';
import {MasterPlanningCalculationService} from '@services/master-planning-calculation.service';
import {AffiliationPlan} from '@models/master-plan';
import * as moment from 'moment';

@Component({
    selector: 'app-plan-percentage',
    templateUrl: './plan-percentage.component.html',
    styleUrls: ['./plan-percentage.component.css']
})
export class PlanPercentageComponent implements OnInit {
    @Input() item: AffiliationPlan;
    @Input() isCurrentMonth = false;
    currentDay = moment().format('D');
    daysInMonth = moment().daysInMonth();

    constructor(private masterPlanningCalculationService: MasterPlanningCalculationService) {
    }

    ngOnInit(): void {

    }

    percentagePerMonth() {
        const a = this.item?.plan ? Number(this.item?.plan.value) : 0;
        const b = this.isCurrentMonth ? Number(this.item.real) / Number(this.currentDay) * this.daysInMonth : Number(this.item.real);
        return this.getPercentage(a, b);
    }

    getPercentage(a, b) {
        const percentage = this.absNumber(this.masterPlanningCalculationService.percentage(a, b));
        return this.masterPlanningCalculationService.percentageFormat(percentage);
    }

    isIncreasePercentage() {
        const real = this.isCurrentMonth ? Number(this.item.real) / Number(this.currentDay) * this.daysInMonth : Number(this.item.real);
        const plan = this.item?.plan ? Number(this.item?.plan?.value) : 0;
        return real >= plan;
    }

    absNumber(num) {
        return Math.abs(num);
    }
}
