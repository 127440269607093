<app-master-header
  cardClass="bg-gradient-3"
  title="Controllo Estratti Conto Tim"
  [image]="'flats/payment.png' | assets"
>
  <div class="d-flex align-items-center justify-content-between">
    <p class="“lead”">Da qui puoi effettuare dei controlli sugli estratti conto di Tim</p>
    <img style="height:auto; width: 150px;" src="/assets/img/brands/tim.png" alt="">
  </div>

</app-master-header>


<!-- filter -->

<div>
  <div style="background:#053B88;" class="card elevation-1 category text-white p-3 mb-4">
    <form>
      <div class="form-row">
        <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
          <div class="form-group">
            <label translate for="companyName">Company name</label>
            <app-retailer-typeahead id="dealer_id" (onChange)="filters.dealer_id = $event"></app-retailer-typeahead>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
          <div class="form-group">
            <label translate for="status">Status</label>
            <!--STATUS -->
            <ng-select
              [clearable]="true"
              class="disableClear"
              bindLabel="label"
              name="status"
              [items]="ecStatuses"
              id="status"
              bindValue="key"
              [searchable]="true"
              [(ngModel)]="filters.welding_status"
            ></ng-select>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
          <div class="form-group">
            <label>Numero Documento</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="filters.number_doc"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>
        <div class="col-xl-4 col-md-4 col-sm-12 mt-auto mb-0">
          <div class="form-group">
            <label>Anno</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="filters.year"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
          <div class="form-group">
            <label translate>Date</label>
            <input
              id="documentDate"
              type="text"
              daterangepicker
              [options]="pickerOptions"
              [(ngModel)]="filters.date"
              name="date"
              class="form-control"
              autocomplete="off"
              (selected)="generateDateRangeString($event.start, $event.end)"
            />
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
          <div class="form-group">
            <button class="btn btn-warning btn-block" translate type="submit" (click)="loadList()">Filter</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Cards -->
  <div class="row" >
    <div class="col-md-3 col-sm-4 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-info"><i class="fa fa-euro-sign"></i></span>
        <div class="info-box-content">
          <span class="info-box-text">Da Saldare</span>
          <span class="info-box-number">{{ totalsData.da_saldare | currency : '€' }}</span>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-4 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-success"><i class="fa fa-cart-plus"></i></span>
        <div class="info-box-content">
          <span class="info-box-text">Saldato</span>
          <span class="info-box-number">{{ totalsData.saldato | currency : '€' }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-warning"><i class="far fa-copy"></i></span>
        <div class="info-box-content">
          <span class="info-box-text">In corso</span>
          <span class="info-box-number">{{ totalsData.in_corso | currency : '€' }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-12" style="margin-bottom: 16px">
      <div class="d-flex h-100">
        <button class="btn btn-info p-1 flex-grow-1 mr-2" translate [disabled]="!isInvoiceSelected" (click)="export()">Esporta dati</button>
        <button class="btn btn-info p-1 flex-grow-1 mr-2" translate [disabled]="!isInvoiceSelected" (click)="showModal()">Registra selezionati</button>
        <button class="btn btn-info p-1 flex-grow-1 mr-2" translate [disabled]="!isInvoiceSelected" (click)="openSaldaAddebitiModal()">Salda selezionati</button>
      </div>
    </div>
  </div>
</div>

<ng-template #timFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title text-center" translate>Sei sicuro di voler proseguire?</h4>
    <button type="button" class="close px-3" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-confirm-popup (confirm)="massPendingUpdate()"></app-confirm-popup>
</ng-template>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="mainSpinner">
  <p style="color: white">Caricamento...</p>
</ngx-spinner>

<ng-container *ngIf="!isLoading">
  <app-dealer-ec-detail-table [isLoading]="isLoading" [data]="data" [ecStatuses]="ecStatuses" (refreshEvent)="loadList()"></app-dealer-ec-detail-table>
</ng-container>

<ng-container *ngIf="!isLoading">
  <p *ngIf="data?.length <= 0" class="display-6 text-muted text-center my-5" translate>List is empty</p>
</ng-container>
