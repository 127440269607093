import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RendicontiAddebitoService {

  constructor(private request: RequestService) { }

  getList(params?): Observable<any> {
    return this.request.get('v2/kd-recharge/get-receipts', params);
  }

}
