import {MetaPagination} from '@models/meta-pagination';
import {BaseModel} from '@app/models/base-model';


export class Cu extends BaseModel {
    id: number;
    dealer_id: number;
    company_name: string;
    file_name: string;
    year: number;
    created: string;
    from: string;
    deleted: number;

    fromArray(result: []): Cu[] {
        const array: Cu[] = [];
        for (const item of result) {
            array.push(new Cu(item));
        }
        return array;
    }
}

export class CuProvider extends BaseModel {
    data: Cu[];
    _meta: MetaPagination;
    _links: Array<any>;
}
