import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
// @ts-ignore
import {} from '@types/googlemaps';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';

@Component({
    selector: 'app-autocomplete-address',
    templateUrl: './autocomplete-address.component.html',
    styleUrls: ['./autocomplete-address.component.css']
})
export class AutocompleteAddressComponent implements OnInit, AfterViewInit {

    @Input() small = false;
    @Input() adressType: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('placesRef') placesRef: GooglePlaceDirective;

    data = {
        streetNumber: null,
        address: null,
        postalCode: null,
        city: null,
        province: null
    };

    autocompleteInput: string;
    queryWait: boolean;

    constructor() {
    }

    ngOnInit() {
    }


    public handleAddressChange(place: Address) {
        console.log(place);
        const streetNumber = place.address_components
            .find(item => item.types.includes('street_number'));
        const address = place.address_components
            .find(item => item.types.includes('route'));
        let city = place.address_components
            .find(item => item.types.includes('locality'));
        if(!city){
            city = place.address_components
                .find(item => item.types.includes('administrative_area_level_3'));
        }
        const province = place.address_components
            .find(item => item.types.includes('administrative_area_level_2'));
        const postalCode = place.address_components
            .find(item => item.types.includes('postal_code'));
        this.data.streetNumber = streetNumber ? streetNumber.long_name : null;
        this.data.address = address ? address.long_name : null;
        this.data.city = city ? city.long_name : null;
        this.data.province = province ? province.short_name : null;
        this.data.postalCode = postalCode ? postalCode.long_name : null;

        this.setAddress.emit(this.data);
        this.autocompleteInput = address.long_name;
    }

    ngAfterViewInit(): void {
        this.placesRef.reset();
        this.placesRef.ngAfterViewInit();
    }
}
