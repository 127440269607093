<div class="row">
    <div class="col-12 col-lg-4">
        <div class="info-text">
            <p><i class="fa fa-user-circle"></i> Ragione Sociale</p>
            <span class="lead">{{dealer.ragsociale}}</span>
        </div>
        <div class="info-text">
            <p><i class="fa fa-stream"></i> Partita IVA</p>
            <span class="lead">{{dealer.piva}}</span>
        </div>
        <div class="info-text">
            <p><i class="fa fa-address-card"></i> Titolare</p>
            <span class="lead">{{dealer.nome}} {{dealer.cognome}}</span>
        </div>
        <div class="info-text">
            <p><i class="fa fa-clock"></i> Data Creazione</p>
            <span class="lead">{{dealer.created | amDateFormat: 'DD/MM/YYYY' }}</span>
        </div>
    </div>
    <div class="col-12 col-lg-4">
        <div class="info-text">
            <p><i class="fa fa-mobile"></i> Cellulare</p>
            <span class="lead">{{dealer.cellulare}}</span>
        </div>
        <div class="info-text">
            <p><i class="fa fa-phone-alt"></i> Telefono</p>
            <span class="lead">{{dealer.telefono}}</span>
        </div>
        <div class="info-text">
            <p><i class="fa fa-envelope"></i> E-Mail</p>
            <span class="lead">{{dealer.email}}</span>
        </div>
        <div class="info-text">
            <p><i class="fa fa-envelope"></i> PEC</p>
            <span class="lead">{{dealer.pec}}</span>
        </div>
    </div>
    <div class="col-12 col-lg-4">
        <div class="info-text" *ngIf="affiliazione(6)">
            <p><i class="fa fa-arrow-circle-right"></i> Kena Mobile con {{affiliazione(6).agente}}</p>
            <span class="lead">{{affiliazione(6).code}} <span
                    class="float-right">{{affiliazione(6).created | amDateFormat: 'DD/MM/YYYY'}}</span></span>
        </div>
        <div class="info-text" *ngIf="affiliazione(11)">
            <p><i class="fa fa-arrow-circle-right"></i> Tim con {{affiliazione(11).agente}}</p>
            <span class="lead">{{affiliazione(11).code}} <span
                    class="float-right">{{affiliazione(11).created | amDateFormat: 'DD/MM/YYYY'}}</span></span>
        </div>
        <div class="info-text" *ngIf="affiliazione(4)">
            <p><i class="fa fa-arrow-circle-right"></i> Tiscali con {{affiliazione(4).agente}}</p>
            <span class="lead">{{affiliazione(4).code}} <span
                    class="float-right">{{affiliazione(4).created | amDateFormat: 'DD/MM/YYYY'}}</span></span>
        </div>
        <div class="info-text" *ngIf="affiliazione(9)">
            <p><i class="fa fa-arrow-circle-right"></i> Eni gas e luce con {{affiliazione(9).agente}}
            </p>
            <span class="lead">{{affiliazione(9).code}} <span
                    class="float-right">{{affiliazione(9).created | amDateFormat: 'DD/MM/YYYY'}}</span></span>
        </div>
        <div class="info-text" *ngIf="data && data.pro.isPro">
            <p><i class="fa fa-star"></i> Dealer Pro</p>
            <span class="lead">{{this.paymentTypesPair[data.pro.payment]}}</span>
        </div>
    </div>
</div>