import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';


const version = 'v3';


@Injectable({
  providedIn: 'root',
})

export class RagsocialeMatchService {
  constructor(private request: RequestService, public alertService: AlertService) {
  }

  public getRagSocialeList(params?): Observable<any> {
    return this.request.get(version + '/match-ragioni-sociali/list', params);
  }

  public deleteMatch(id :number) {
    return this.request.delete(version +`/match-ragioni-sociali/delete/${id}`);
  }

  public updateRagsociale(id, body: {}){
    return this.request.put(version + '/match-ragioni-sociali/update/' + id, body);
  }

  public successAlert(text) {
    this.alertService.show('', 'File ' + text, { classname: 'bg-success text-light', delay: 2000 });
  }

  public errorAlert(message?) {
    this.alertService.show('Errore nel caricamento ', message ? message : 'Riprova di nuovo!', {
      classname: 'bg-danger text-light',
      delay: 2000,
    });
  }


}