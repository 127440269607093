<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
  <form (ngSubmit)="loadCategories()">
    <div class="form-row d-flex justify-content-between">
      <div class="col-xl col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="name">Name</label>
          <input type="text" class="form-control" id="name" [(ngModel)]="filter.name" name="name" />
        </div>
      </div>
      <div class="col-xl col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="category">Category</label>
          <ng-select
            name="category"
            id="category"
            [(ngModel)]="filter.category_id"
            [items]="parentCategories"
            bindValue="id"
            bindLabel="nome"
            (search)="loadFilterCategories($event?.term)"
            [loading]="categoriesLoading"
          ></ng-select>
        </div>
      </div>
      <div class="col-xl col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="operator">Operator</label>
          <ng-select
            name="category"
            id="operator"
            [(ngModel)]="filter.operator_id"
            [items]="operators"
            bindValue="id"
            bindLabel="name"
            (search)="loadOperators($event?.term)"
            [loading]="operatorsLoading"
          ></ng-select>
        </div>
      </div>
      <div class="col-xl col-md-6 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <label translate for="brand">Brand</label>
          <ng-select
            name="category"
            id="brand"
            [(ngModel)]="filter.brand_id"
            [items]="brands"
            bindValue="id"
            bindLabel="nome"
            (search)="loadBrands($event?.term)"
            [loading]="brandsLoading"
          ></ng-select>
        </div>
      </div>
      <div class="col-xl-2 col-md-4 col-sm-12 mt-auto mb-0">
        <div class="form-group">
          <button class="btn btn-info btn-block" type="submit" translate>Filter</button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-10 col-12">
        <h4 class="mb-0" translate>Categories</h4>
      </div>
      <div class="col-lg-2 col-12">
        <button class="btn btn-success btn-sm float-right" translate (click)="showModal()">Add Category</button>
      </div>
    </div>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th translate>Category</th>
          <th translate class="text-center">Parent Category</th>
          <th translate class="text-center">Brand</th>
          <th translate class="text-center">Operator</th>
          <th translate class="text-center">Consigliata</th>
          <th translate class="text-center">Ordine</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="bg-gradient-white">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="square-jelly-box" name="table-categories" [fullScreen]="false">
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngFor="let category of categories">
          <td>
            <div class="media d-flex align-items-center">
              <div class="logo-img">
                <img class="mr-3" [src]="category.immagine_path" alt="" />
              </div>
              <div class="media-body ml-2">
                <h5 class="mt-0 mb-0">
                  <strong>{{ category.nome }}</strong>
                </h5>
                <small>
                  <app-rich-text-truncate [text]="category.descrizione"></app-rich-text-truncate>
                </small>
              </div>
            </div>
          </td>
          <td class="text-center">{{ category.parent_category }}</td>
          <td class="text-center">{{ category.brand }}</td>
          <td class="text-center">{{ category.operator }}</td>
          <td class="text-center display-7 font-weight-bold">
            <span *ngIf="category.featured == 1" class="text-success">&#10003;</span>
            <span *ngIf="category.featured == 0" class="text-danger">&#10007;</span>
          </td>
          <td class="text-center">{{ category.order }}</td>
          <td class="text-right">
            <button class="btn btn-link">
              <i class="fa fa-edit text-muted" (click)="showModal(category)"></i>
            </button>
            <button class="btn btn-link" [swal]="deleteCategoryOptions" (confirm)="deleteCategory(category.id)">
              <i class="fa fa-trash text-danger"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="categories.length <= 0">
          <td translate colspan="5" class="text-muted">List is empty</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" *ngIf="categories.length > 0 && categoriesPagination">
    <span
      ><span translate>There are</span><b> {{ this.categoriesPagination.totalCount }}</b> {{ this.categoriesPagination.totalCount <= 1 ? 'category' : 'categories' }}.</span
    >
    <ngb-pagination
      *ngIf="categoriesPagination.pageCount > 1"
      size="sm"
      [pageSize]="categoriesPagination.perPage"
      [maxSize]="10"
      [(page)]="categoriesPagination.currentPage"
      [collectionSize]="categoriesPagination.totalCount"
      (pageChange)="loadCategories()"
      [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
</div>
<ng-template #categoryFormTemplate let-modal>
  <app-category-modal [categories]="categories" [selectedCategory]="selectedCategory"></app-category-modal>
</ng-template>
