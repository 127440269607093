import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { MetaPagination } from '@models/meta-pagination';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '@services/request.service';
import { AuthenticationService } from '@services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@models/user';
import { RagSociale } from '@models/rag-sociale';
import { RagsocialeMatchService } from '@services/ragsociale-match.service';

@Component({
  selector: 'app-match-ragsociale',
  templateUrl: './match-ragsociale.component.html',
  styleUrls: ['./match-ragsociale.component.scss']
})
export class MatchRagsocialeComponent implements OnInit {
  @ViewChild('editRecordTemplate') private editRecordTemplate;

  deleteSubscriptionOptions: ConfirmOptionsService;
  ordersPagination: MetaPagination;
  ragsocialeList : RagSociale[];
  isDealer = false;
  isEdit = true;
  editForm: FormGroup;
  currentItem: RagSociale;
  isFormUpdate = false;
  isSaving = false;

  filter = {
    id_dealer: '',
    ragsoc: '',
    vat: '',
    codfisc: '',
    providerType: '',
  };

  providerText = [
    { key: 0, label: 'Cliente' },
    { key: 1, label: 'Fornitore' },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public request: RequestService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    public ragSocialeService: RagsocialeMatchService,
  ) {
    this.authenticationService.currentUser.subscribe((user: User) => {
      this.isDealer = user && user.profile.role === 'dealer';
    });
    this.deleteSubscriptionOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
    });
  }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      id_dealer: ['', Validators.required],
      ragsociale: ['',Validators.required],
      ragione_sociale: ['',Validators.required],
      piva: ['',Validators.required],
      codfiscale: ['',Validators.required],
      provier: ['',Validators.required],
    })

    this.editForm.valueChanges.subscribe(() => {
      if (!this.isFormUpdate) {
        this.isFormUpdate = true;
      }
    });

    this.loadList();
  }

  loadList(){
    this.spinner.show('table-orders');

    const params = {}

    if (this.ordersPagination?.currentPage) {
      params['page'] = this.ordersPagination?.currentPage;
    }
    if (this.filter.id_dealer) {
      params['idDealer'] = this.filter.id_dealer;
    }
    if (this.filter.ragsoc) {
      params['ragsoc'] = this.filter.ragsoc;
    }
    if (this.filter.vat) {
      params['vat'] = this.filter.vat;
    }
    if (this.filter.codfisc) {
      params['codfisc'] = this.filter.codfisc;
    }
    if (this.filter.providerType !== '') {
      params['providerId'] = this.filter.providerType;
    }

    console.log(params);
    this.ragSocialeService.getRagSocialeList(params).subscribe(
      (response: any) => {
        this.ragsocialeList = response;

        this.ragsocialeList = new RagSociale().fromArray(response.data);
        this.ordersPagination = response._meta;

        this.spinner.hide('table-orders');
      },
      (err) => {
        this.spinner.hide('table-orders');
      },
    );
  }

  editRecord(item: RagSociale){
    this.currentItem = item;
    this.editForm.patchValue({
      id_dealer: item.id_dealer,
      ragsociale: item.ragsociale,
      ragione_sociale: item.ragione_sociale,
      piva: item.piva,
      codfiscale: item.codfiscale,
      provider: item.provider,
    });
    this.modalService.open(this.editRecordTemplate, { size: 'lg' });
  }

  dismissModal() {
    this.editForm.reset();
    this.modalService.dismissAll();
    this.loadList();
  }

  save() {
    this.isSaving = true;
    const id = this.currentItem.id;
    const body = this.editForm.value;
    this.ragSocialeService.updateRagsociale(id, body).subscribe(
      (response) => {
        this.ragSocialeService.successAlert('Salvato con successo');
        setTimeout(() => {
          this.dismissModal();
          this.isSaving = false;
        }, 2000); // Imposta il ritardo in millisecondi, ad esempio 2000ms (2 secondi)
      },
      (err) => {
        this.ragSocialeService.errorAlert(err);
        this.dismissModal();
        this.isSaving = false;
      }
    );
  }


  deleteMatch(id : number){
    this.ragSocialeService.deleteMatch(id).subscribe(
      () => {
        const index = this.ragsocialeList.findIndex((dealer) => dealer.id === id);
        if (index > -1) {
          this.ragSocialeService.successAlert('Deleted Service!');
          this.loadList();
        }
      },
      (err) => {
        this.ragSocialeService.errorAlert(err.message);
      },
    );
  }

}
