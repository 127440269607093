import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  ENDPOINT = 'cities';

  constructor(public request: RequestService) {

  }

  list(params = {}) {
    return this.request.get(`${this.ENDPOINT}/list`, params);
  }

  clusters() {
    return this.request.get(`${this.ENDPOINT}/cluster-options`);
  }
}
