import {Component, OnInit, ViewChild} from '@angular/core';
import {OperatorService} from "@services/operator.service";
import {Subscription} from "rxjs";
import 'handsontable/languages/it-IT';
import * as XLSX from "xlsx";
import {ModalComponent} from "@widgets/modal/modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MasterSubheaderComponent} from "@app/pages/master-dashboard/partials/master-subheader/master-subheader.component";


@Component({
    selector: 'app-gestione-incentivazione',
    templateUrl: './gestione-incentivazione.component.html',
    styleUrls: ['./gestione-incentivazione.component.css']
})
export class GestioneIncentivazioneComponent implements OnInit {
    @ViewChild('messageModal') messageModal: ModalComponent;
    @ViewChild('dupModal') dupModal: ModalComponent;
    @ViewChild('upModal') upModal: ModalComponent;
    @ViewChild('storniModal') storniModal: ModalComponent;
    @ViewChild('subheader') subheader: MasterSubheaderComponent;

    modal = {
        title: '',
        icon: '',
        message: '',
        description: '',
    };

    _periodo = null;
    _operatore = null;
    _segmento = null;
    startup: any;

    urlSubheader: string;
    data: any;
    dupMonth: any;

    set segmento(value: string) {
        if (!this._segmento) {
            this._segmento = value;
        } else {
            this._segmento = value
            this.refresh();
        }
    }

    get segmento() {
        return this._segmento;
    }

    set periodo(value: string) {
        if (!this._periodo) {
            this._periodo = value;
        } else {
            this._periodo = value
            this.refresh();
        }
    }

    get periodo() {
        return this._periodo;
    }

    set operatore(value: number) {
        if (!this._operatore) {
            this._operatore = value;
        } else {
            this._operatore = value
            this.refresh();
        }
    }

    get operatore() {
        return this._operatore;
    }

    busy: Subscription;
    excelReport = '';
    excelStorni = '';

    constructor(
        public modalService: NgbModal,
        private operatorService: OperatorService
    ) {
    }

    ngOnInit() {
        this.busy = this.operatorService.get('/incentive-period')
            .subscribe((data) => {
                this.startup = data.result;
                this.periodo = this.startup.periodo;
                this.dupMonth = this.startup.periodo;
                this.operatore = this.startup.operatore;
                this.segmento = this.startup.segmento;

                this.refresh();
            });
    }

    refresh() {
        this.urlSubheader = `/gestione-incentivazione-header?operatore=${this.operatore}&periodo=${this.periodo}`;

        this.busy = this.operatorService.get(`/gestione-incentivazione?segmento=${this.segmento}&operatore=${this.operatore}&periodo=${this.periodo}`)
            .subscribe((data) => {
                this.data = data.result;
            });
    }

    calculate() {
        this.busy = this.operatorService.get(`/calcola-incentivazione?operatore=${this.operatore}&periodo=${this.periodo}`)
            .subscribe((data) => {
                if (data.success) {
                    this.subheader.load();
                    this.showModal('Ottimo', 'fa-check-circle text-success',
                        'Operazione completata',
                        'Le provvigioni sono state calcolate, puoi vedere i dati aggiornati scaricando il dettaglio o dalla fatturazione.');
                } else {
                    this.showModal('Peccato', 'fa-times-circle text-danger',
                        'Errore',
                        'Il calcolo non è andato a buon fine...');
                }
            });
    }

    download() {
        this.busy = this.operatorService.get(`/scarica-incentivazione?operatore=${this.operatore}&periodo=${this.periodo}`)
            .subscribe((data) => {
                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.result);
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Dettaglio');
                XLSX.writeFile(wb, `dettaglio_${this.periodo}.xlsx`);
            });
    }

    save() {
        this.busy = this.operatorService.post(`/gestione-incentivazione?segmento=${this.segmento}&operatore=${this.operatore}&periodo=${this.periodo}`, {data: this.data})
            .subscribe((data) => {
                this.data = data.result;

                if (data.success) {
                    this.showModal('Ottimo', 'fa-check-circle text-success',
                        'Operazione completata',
                        'I dati sono stati salvati correttamente.');
                } else {
                    this.showModal('Peccato', 'fa-times-circle text-danger',
                        'Errore',
                        'Il salvataggio non è andato a buon fine...');
                }
            });
    }

    showModal(title, icon, message, description) {
        this.modal.title = title;
        this.modal.icon = icon;
        this.modal.message = message;
        this.modal.description = description;
        this.messageModal.open();
    }

    getRows(card: any) {
        if (card.hasOwnProperty('parent')) {
            return this.data[card.parent][card.data].rows;
        }

        return this.data[card.data].rows;
    }

    getCols(card: any) {
        if (card.hasOwnProperty('parent')) {
            return this.data[card.parent][card.data].cols;
        }

        return this.data[card.data].cols;
    }

    duplicate() {
        this.dupModal.open();
    }

    saveGara() {
        this.busy = this.operatorService.get(`/duplica-gara?segmento=${this.segmento}&operatore=${this.operatore}&periodo=${this.periodo}&target=${this.dupMonth}`)
            .subscribe((data) => {
                this.modalService.dismissAll();
                if (data.success) {
                    this.showModal('Ottimo', 'fa-check-circle text-success',
                        'Operazione completata',
                        'La gara è stata duplicata correttamente.');
                } else {
                    this.showModal('Peccato', 'fa-times-circle text-danger',
                        'Errore',
                        'La duplicazione non è andata a buon fine...');
                }
            });
    }

    update() {
        this.upModal.open();
    }
    storni() {
        this.storniModal.open();
    }

    saveReport() {
        this.busy = this.operatorService.post(
            `/aggiorna-report?segmento=${this.segmento}&operatore=${this.operatore}&periodo=${this.periodo}`,
            { 'excel': JSON.stringify(this.excelReport)}
        )
            .subscribe((data) => {
                this.modalService.dismissAll();
                if (data.success) {
                    this.excelReport = '';
                    this.showModal('Ottimo', 'fa-check-circle text-success',
                        'Operazione completata',
                        'Il report è stato aggiornato correttamente.');
                } else {
                    this.showModal('Peccato', 'fa-times-circle text-danger',
                        'Errore',
                        'Riprova a riaggiornarlo, se dopo un paio di tentativi ti da ancora errore chiedi a Lucio :D');
                }
            });
    }

    caricaStorni() {
        this.busy = this.operatorService.post(
            `/carica-storni?segmento=${this.segmento}&operatore=${this.operatore}&periodo=${this.periodo}`,
            { 'excel': JSON.stringify(this.excelStorni)}
        )
            .subscribe((data) => {
                this.modalService.dismissAll();
                if (data.success) {
                    this.excelStorni = '';
                    this.showModal('Ottimo', 'fa-check-circle text-success',
                        'Operazione completata',
                        'Il report è stato aggiornato correttamente.');
                } else {
                    this.showModal('Peccato', 'fa-times-circle text-danger',
                        'Errore',
                        'Riprova a riaggiornarlo, se dopo un paio di tentativi ti da ancora errore chiedi a Lucio :D');
                }
            });
    }

    callAction(url: any) {
        this.busy = this.operatorService.get(
            `/${url}&segmento=${this.segmento}&operatore=${this.operatore}&periodo=${this.periodo}`)
            .subscribe((data) => {
                // this.modalService.dismissAll();
                if (data.success) {
                    this.data = data.result;
                //     this.excelStorni = '';
                //     this.showModal('Ottimo', 'fa-check-circle text-success',
                //         'Operazione completata',
                //         'Il report è stato aggiornato correttamente.');
                } else {
                //     this.showModal('Peccato', 'fa-times-circle text-danger',
                //         'Errore',
                //         'Riprova a riaggiornarlo, se dopo un paio di tentativi ti da ancora errore chiedi a Lucio :D');
                }
            });
    }

    toggleLock() {
        this.busy = this.operatorService.get(`/toggle-lock?segmento=${this.segmento}&operatore=${this.operatore}&periodo=${this.periodo}`)
            .subscribe((data) => {
                if (data.success) {
                    this.data = data.result;
                } else {
                    this.showModal('Peccato', 'fa-times-circle text-danger',
                        'Errore',
                        'Riprova.');
                }
            });
    }
}
