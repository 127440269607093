import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit{
    @ViewChild('content') modal: NgbActiveModal;

    @Input()
    title: string;
    @Input()
    showFooter = true;

    @Input()
    bodyClass = '';

    constructor(public modalService: NgbModal) {
    }

    ngOnInit() {
    }

    open (size = null, windowsClass = null) {
        let options = {};
        if (size) {
            options = { size: size };
        }
        if (windowsClass) {
            options = { windowClass: windowsClass };
        }
        this.modalService.open(this.modal, options);
    }

    dismiss () {
        this.modalService.dismissAll(this.modal);
    }
}
