import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-text-popup',
  templateUrl: './text-popup.component.html',
  styleUrls: ['./text-popup.component.css']
})
export class TextPopupComponent implements OnInit {

  @Input() config;
  @Output() onSave = new EventEmitter<any>();
  orderForm = new FormGroup({
      value: new FormControl('', Validators.required)
  });

  
  constructor() { }

  ngOnInit(): void {
    this.orderForm.get('value').setValue(this.config?.inputValue);
  }

  closePopover() {
    this.config.popover.close();
    this.orderForm.reset();
  }
  
  save() {
    this.onSave.emit(this.orderForm.value.value);
  }

}
