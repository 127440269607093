import {environment} from '@environments/environment';
import * as moment from 'moment';

export class Helpers {
    static groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );

    static convertDictToFormData(formData, data, parentKey = null) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.convertDictToFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    static getPeriods(untilMonths = 0) {
        let result = [];
        let endDate = moment().subtract(untilMonths);
        let startDate = moment(environment.startDate + '-01T00:00:00.000Z');
        while (endDate.isAfter(startDate)) {
            result.push({
                value: endDate.format('YYYY-MM'),
                label: endDate.format('MMMM YYYY').charAt(0).toUpperCase() + endDate.format('MMMM YYYY').slice(1),
            });
            endDate.subtract(1, 'month');
        }

        return result;
    }

    static dateRange(date1: Date, date2: Date) {
        return this._dateRange(date1.toISOString().split('T')[0],
            date2.toISOString().split('T')[0]).map(dateString => new Date(dateString));
    }

    static capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }

    static is18yearsOld(date: Date) {
        const TODAY = new Date();
        return date <= new Date((new Date(TODAY).getFullYear() - 18) + '-' + new Date(TODAY).getMonth() + '-' + new Date(TODAY).getDate());
    }

    // https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
    static compare(a, b, key) {
        let aParam = a[key];
        let bParam = b[key];
        if (this.isNumeric(aParam)) {
            aParam = parseFloat(aParam);
        } else {
            aParam = aParam.toString().toLowerCase();
        }
        if (this.isNumeric(bParam)) {
            bParam = parseFloat(bParam);
        } else {
            bParam = bParam.toString().toLowerCase();
        }

        if (aParam < bParam) {
            return -1;
        }
        if (aParam > bParam) {
            return 1;
        }
        return 0;
    }

    // Usata per ordinare un'array per una proprietà

    static isNumeric(str: any) {
        return /^\-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(str);
        // if (typeof str != "string") return false;
        // return !isNaN(Number(str)) && !isNaN(parseFloat(str));
    }

    private static _dateRange(startDate, endDate) {
        const start = startDate.split('-');
        const end = endDate.split('-');
        const startYear = parseInt(start[0], 10);
        const endYear = parseInt(end[0], 10);
        const dates = [];

        for (let i = startYear; i <= endYear; i++) {
            const endMonth = i !== endYear ? 11 : parseInt(end[1], 10) - 1;
            const startMon = i === startYear ? parseInt(start[1], 10) - 1 : 0;
            for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                const month = j + 1;
                const displayMonth = month < 10 ? '0' + month : month;
                dates.push([i, displayMonth, '01'].join('-'));
            }
        }
        return dates;
    }

}
