import { BaseModel } from './base-model';
import { Company } from './company';
import { Transaction } from './transaction';
import { Vendor } from './vendor';

export class DealerVendor extends BaseModel {
    id_dealer: number;
    id_operator: number;
    id_agente: number;
    vendor_name: string;
    agent_name: string;
    code: string;
    subcode: string;

    fromArray(result: []) {
        const array: DealerVendor[] = [];
        for (const item of result) {
            array.push(new DealerVendor(item));
        }
        return array;
    }
}

export class ShopAccess extends BaseModel {
    id: number;
    id_dealer: number;
    id_operator: number;
    from: string;
    to: string;

    fromArray(result: []) {
        const array: ShopAccess[] = [];
        for (const item of result) {
            array.push(new ShopAccess(item));
        }
        return array;
    }
}

export class Dealer extends Company {
    dealer_type: string;
    spid_ir_enabled: boolean;
    spid_codice_sede: string;
    spid_user: string;
    spid_progressivo: number;
    visibile: any;
    lat: any;
    lon: any;
    address_id: number;
    comune_legale: any;
    indirizzo_legale: number;
    provincia_legale: any;
    cap_legale: number;
    ragsociale_fatt: string;
    unweldedTransactions: Transaction[];
    enabledVendors: Vendor[];
    vendors: DealerVendor[];
    shopAccesses: ShopAccess[];
    agents: DealerVendor[];
    is_2fa?: any;
    cattivo_pagatore: boolean;
    facebook_page: string;
    inpost: string;
    kdsocial: number;
    sdd_enabled: number;
    sdd_iban: number;
    sdd_signature_date: string;
    sdd_type: string;
    position: string;
    priority: any;

    get value() {
        return this.company_name;
    }

    get nameWithLocation() {
        return `${this.company_name} - ${this.city} (${this.province})`;
    }

    get unweldedBalance() {
        if (this.unweldedTransactions) {
            return this.unweldedTransactions.reduce((a, b) => a + (b.segno === 0 ? b.valore : -b.valore), 0);
        }
    }

    get childModels() {
        return {
            unweldedTransactions: Transaction,
            enabledVendors: Vendor,
            vendors: DealerVendor,
            agents: DealerVendor,
            shopAccesses: ShopAccess,
        };
    }

    fromArray(result: []) {
        const array: Dealer[] = [];
        for (const item of result) {
            array.push(new Dealer(item));
        }
        return array;
    }
}
