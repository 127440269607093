import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';


const version = 'v3'; 

@Injectable({
  providedIn: 'root'
})
export class KaskoService {

  constructor(private request: RequestService, public alertService: AlertService) { }


  public getKaskoList(params?): Observable<any> {
    return this.request.get(version + '/kasko/list', params);
  }
//   public putKaskoOrder(id, body: {}) {
//     return this.request.put(version + '/application/update/' + id, body);
// }
}
