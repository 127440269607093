<div class="card" *ngIf="showForm">
    <div class="card-header">
        <div class="card-title">Nuova Tipologia</div>
    </div>
    <div class="card-body">
        <div class="w-100 text-center" *ngIf="loadingForm">
            <img src="assets/img/svgs/loading_gears.svg" />
        </div>
        <form [formGroup]="proFormaTypeForm" *ngIf="!loadingForm">
            <div class="row">
                <div class="col-12 col-md-6 form-group">
                    <label>Descrizione</label>
                    <input type="text" class="form-control" formControlName="descrizione" />
                </div>
                <div class="col-12 col-md-6 form-group">
                    <label>Operatore</label>
                    <app-vendor-select (onChange)="setOperator($event)"></app-vendor-select>
                </div>
            </div>
        </form>
    </div>
    <div class="card-footer">
        <button
                (click)="submit()"
                [disabled]="proFormaTypeForm.invalid"
                class="btn btn-sm btn-primary">Aggiungi Nuova Tipologia</button>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <div class="card-title">Tipologie Voci</div>
        <div class="card-tools">
            <button class="btn btn-sm btn-success" (click)="showFormCard()">
                Aggiunti Tipo
            </button>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="w-100 text-center" *ngIf="loading">
            <img src="assets/img/svgs/loading_gears.svg" />
        </div>
        <table class="table table-sm" *ngIf="!loading">
            <thead>
            <tr>
                <th>ID</th>
                <th>Descrizione</th>
                <th>Operatore</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of proFormaTypes">
                <td>{{row.id}}</td>
                <td>{{row.descrizione}}</td>
                <td>{{row.operatore}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
