import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShopOrderService } from '@services/shop-order.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmOptionsService } from '@services/confirm-options.service';
import { ShopOrderFile, ShopOrderList } from '@models/shop-order';
import { StoreProduct, StoreProductPagination, StoreProductVariant } from '@models/store/store-product';
import { StoreService } from '@services/store.service';
import { ToastrService } from 'ngx-toastr';
import { SuretyService } from '@services/surety.service';
import { SuretyCredit } from '@models/surety';
import * as moment from 'moment';
import { AuthenticationService } from '@app/services/authentication.service';

@Component({
  selector: 'app-shop-order-detail',
  templateUrl: './shop-order-detail.component.html',
  styleUrls: ['./shop-order-detail.component.css'],
})
export class ShopOrderDetailComponent implements OnInit {
  token: string = '';

  currency = '€';
  localFormat = 'DD/MM/YYYY';
  @Input() order: ShopOrderList;
  selectedKey = null;
  popupConfig = {
    type: null,
    key: null,
    items: null,
    id: null,
    isSelect: false,
    popover: null,
    inputValue: null,
  };
  @Output() refresh = new EventEmitter<any>();
  @Output() sectional = new EventEmitter<any>();
  deleteDocumentOptions: ConfirmOptionsService;
  productForm = new FormGroup({
    product: new FormControl('', Validators.required),
    quantity: new FormControl(1, Validators.required),
    variant: new FormControl(''),
  });
  costForm = new FormGroup({
    cost: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    total_price: new FormControl(null, Validators.required),
  });
  fileForm = new FormGroup({
    file: new FormControl('', Validators.required),
  });
  products: StoreProduct[] = [];
  productLoading = false;
  busyProduct: any;
  busyFile: any;
  busyCost: any;
  submittedFile: File;
  noteForm = new FormGroup({
    note_op: new FormControl('', Validators.required),
  });
  busyNote: any;
  costTypes = [
    {
      id: 0,
      type: 'vat',
      description: '22%',
      name: 'IVA al 22%',
    },
    {
      id: 1,
      type: 'credit-card',
      description: '1.5%',
      name: 'Fee Carta di credito del 1.5%',
    },
    {
      id: 2,
      type: 'shipping',
      description: 'Stock',
      name: 'Spedizione Standard',
    },
    {
      id: 3,
      type: 'credit',
      description: 'Compensazione parziale',
      name: 'Compensazione Parziale',
    },
    {
      id: 4,
      type: 'pro-discount',
      description: 'Sconto Dealer Pro',
      name: 'Sconto Dealer Pro',
    },
    {
      id: 5,
      type: 'shipping',
      description: 'Tech',
      name: 'Spedizione Smartness',
    },
  ];
  loadingInvoice = false;
  weldingOptions = [
    {
      label: '30 gg. data fattura',
      key: 30,
    },
    {
      label: '60 gg. data fattura',
      key: 60,
    },
  ];
  @ViewChild('productFormTemplate') private productFormTemplate;
  @ViewChild('confirmFicIdTemplate') private confirmFicIdTemplate;
  @ViewChild('costFormTemplate') private costFormTemplate;
  @ViewChild('fileFormTemplate') private fileFormTemplate;
  @ViewChild('noteFormTemplate') private noteFormTemplate;
  @ViewChild('imageModal') private imageModal;

  loadingPaid = false;
  loadingXml = false;

  newFicId: any = null;

  _orderId: any = null;

  constructor(
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private shopOrderService: ShopOrderService,
    private suretyService: SuretyService,
    private storeService: StoreService,
    private authenticationService: AuthenticationService,
  ) {
    this.deleteDocumentOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro!',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
    });

    this.token = this.authenticationService.currentUserToken;
  }

  get totalProductPrice() {
    return (Number(this.unitProductPrice) * Number(this.productForm.value.quantity ? this.productForm.value.quantity : 1)).toFixed(2);
  }

  get unitProductPrice() {
    if (this.selectedVariant) {
      return this.selectedVariant.price;
    }
    return this.order.payment === 2 ? this.selectedProduct?.prezzoacquisto : this.selectedProduct?.prezzo;
  }

  get selectedProduct() {
    return this.productForm.get('product').value as StoreProduct;
  }

  get selectedVariant() {
    return this.productForm.get('variant').value as StoreProductVariant;
  }

  get variants() {
    if (!this.selectedProduct) {
      return [];
    }
    return this.selectedProduct.variants as StoreProductVariant[];
  }

  get file() {
    return this.fileForm.get('file').value;
  }

  ngOnInit(): void {}

  openPopover(popOver: any, id, item, key, type) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = id;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.type = type;
    popOver.open();
  }

  openConfirmFicIdModal(orderId) {
    this._orderId = orderId;

    const modalRef = this.modalService.open(this.confirmFicIdTemplate, {
      centered: true,
    });

    if (modalRef.componentInstance) {
      modalRef.componentInstance.orderId = this._orderId;
    }

    modalRef.result.then(
      (result) => {},
      (reason) => {},
    );
  }

  saveFicId(orderId) {
    const body = {};
    body['fic_id'] = this.newFicId;
    this.shopOrderService.putShopOrder(orderId, body).subscribe(
      (response) => {
        this.modalService.dismissAll();
        this.refresh.emit(true);
        this._orderId = null;
      },
      (err) => {},
    );
  }

  save(value) {
    const body = {};
    body[this.popupConfig.key] = value;
    this.spinner.show('popupSpinner');
    if (this.popupConfig.type === 'product') {
      this.shopOrderService.putShopOrderProduct(this.popupConfig?.id, body).subscribe(
        (response) => {
          this.refresh.emit(true);
          this.spinner.hide('popupSpinner');
          this.popupConfig.popover.close();
        },
        (err) => {
          this.spinner.hide('popupSpinner');
          this.popupConfig.popover.close();
        },
      );
    } else if (this.popupConfig.type === 'tax') {
      this.shopOrderService.putShopOrderTax(this.popupConfig?.id, body).subscribe(
        (response) => {
          this.refresh.emit(true);
          this.spinner.hide('popupSpinner');
          this.popupConfig.popover.close();
        },
        (err) => {
          this.spinner.hide('popupSpinner');
          this.popupConfig.popover.close();
        },
      );
    } else if (this.popupConfig.type === 'welding_date') {
      const credit = new SuretyCredit({
        order_id: this.order.id,
        dealer_id: this.order.dealer.id,
        amount: -this.order.amount,
        welded: 0,
        welding_date: moment(this.order.created).add(value, 'days').format('YYYY-MM-DD'),
      });

      let suretyRequest =
        this.popupConfig.inputValue !== null ? this.suretyService.putDealerCredits(this.popupConfig?.inputValue?.id, credit) : this.suretyService.postDealerCredits(credit);

      suretyRequest.subscribe(
        (response) => {
          this.refresh.emit(true);
          this.spinner.hide('popupSpinner');
          this.popupConfig.popover.close();
        },
        (err) => {
          this.spinner.hide('popupSpinner');
          this.popupConfig.popover.close();
        },
      );
    } else {
      this.shopOrderService.putShopOrder(this.popupConfig?.id, body).subscribe(
        (response) => {
          this.refresh.emit(true);
          this.spinner.hide('popupSpinner');
          this.popupConfig.popover.close();
        },
        (err) => {
          this.spinner.hide('popupSpinner');
          this.popupConfig.popover.close();
        },
      );
    }
  }

  decreaseProductQuantity(item) {
    if (Number(item.quantity) > 1) {
      item.quantity = Number(item.quantity) - 1;
      this.saveProduct(item);
    }
  }

  increaseProductQuantity(item) {
    if (Number(item.product?.quantity) >= Number(item.quantity)) {
      item.quantity = Number(item.quantity) + 1;
      this.saveProduct(item);
    }
  }

  saveProduct(item) {
    const body = {
      quantita: item?.quantity,
    };
    this.shopOrderService.putShopOrderProduct(item?.id, body).subscribe(
      () => {
        this.refresh.emit(false);
      },
      (err) => {
        this.refresh.emit(false);
      },
    );
  }

  deleteProduct(id) {
    this.shopOrderService.deleteShopOrderProduct(id).subscribe(() => {
      this.refresh.emit(false);
    });
  }

  addProduct() {
    const body = {
      id_prodotto: this.productForm.value?.product?.id,
      quantita: this.productForm.value?.quantity,
      prezzo: this.unitProductPrice,
      id_ordine: this.order?.id,
    };
    if (this.selectedVariant) {
      body['product_variant_id'] = this.selectedVariant?.id;
    }
    this.busyProduct = this.shopOrderService.postShopOrderProduct(body).subscribe(
      () => {
        this.refresh.emit(false);
        this.modalService.dismissAll();
      },
      (err) => {
        this.modalService.dismissAll();
      },
    );
  }

  loadProducts(term?) {
    this.productLoading = true;
    const params = {
      // 'id_deleted': 0,
      // 'is_visible': 1
    };
    if (term) {
      params['name_or_id'] = term;
    }
    this.storeService.getProducts(params).subscribe(
      (response: StoreProductPagination) => {
        this.products = response?.data;
        this.productLoading = false;
      },
      (err) => {
        this.productLoading = false;
      },
    );
  }

  showProductModal() {
    this.productForm.reset();
    this.loadProducts();
    this.modalService.open(this.productFormTemplate, { size: 'md' });
  }

  showCostModal() {
    this.costForm.reset();
    this.modalService.open(this.costFormTemplate, { size: 'md' });
  }

  showFileModal() {
    this.fileForm.reset();
    this.modalService.open(this.fileFormTemplate, { size: 'md' });
  }

  replace() {
    this.shopOrderService.putShopOrder(this.order?.id, { replace_serial: 1 }).subscribe(
      (response) => {
        this.shopOrderService.successAlert();
      },
      (err) => {
        this.shopOrderService.errorAlert();
      },
    );
  }

  uploadFile() {
    const orderFile = new ShopOrderFile();
    orderFile.file = this.submittedFile;
    this.busyFile = this.shopOrderService.uploadFile(this.order?.id, orderFile).subscribe(
      (response) => {
        this.modalService.dismissAll();
        this.refresh.emit(false);
      },
      (err) => {
        this.modalService.dismissAll();
        this.refresh.emit(false);
      },
    );
  }

  handleFileInput(file: File) {
    this.submittedFile = file;
    this.fileForm.get('file').setValue(file.name);
  }

  handleFileRemoveModal() {
    this.fileForm.get('file').setValue('');
    this.submittedFile = null;
  }

  showNoteModal(order: ShopOrderList) {
    this.noteForm.setValue({
      note_op: order?.note_op,
    });
    this.modalService.open(this.noteFormTemplate, { size: 'md' });
  }

  saveNote() {
    this.busyNote = this.shopOrderService.putShopOrder(this.order?.id, this.noteForm.value).subscribe(
      (response) => {
        this.refresh.emit(false);
        this.modalService.dismissAll();
      },
      (err) => {
        this.modalService.dismissAll();
      },
    );
  }

  loadCostType() {
    const currentValue = this.costForm.get('cost').value;
    const cost = this.costTypes.find((c) => c.id === currentValue);

    this.costForm.patchValue({
      type: cost.type,
      description: cost.description,
    });
  }

  addCost() {
    const cost = this.costForm.value;
    cost.order_id = this.order?.id;

    this.busyCost = this.shopOrderService.postShopOrderTax(this.order?.id, cost).subscribe(
      (response) => {
        this.refresh.emit(false);
        this.modalService.dismissAll();
      },
      (err) => {
        this.modalService.dismissAll();
      },
    );
  }

  deleteTax(id: number) {
    this.shopOrderService.deleteShopOrderTax(id).subscribe(() => {
      this.refresh.emit(false);
    });
  }

  createInvoice() {
    this.loadingInvoice = true;
    this.shopOrderService.createInvoice(this.order?.id).subscribe(
      (response: any) => {
        if (response !== false) {
          this.toast.success('Fattura creata con successo', 'Fatture in cloud');
          if (response.notFoundProducts.length > 0) {
            let message = '';
            response.notFoundProducts.forEach((x: any) => {
              message += x.name + ' - seriale: ' + x.serial + ' \n';
            });
            this.toast.warning(message, 'Prodotti non trovati', { timeOut: 20000 });
          }
        }
        this.refresh.emit(false);
        this.modalService.dismissAll();
        this.loadingInvoice = false;
      },
      (err) => {
        this.modalService.dismissAll();
        this.loadingInvoice = false;
      },
    );
  }

  downloadXml() {
    this.loadingXml = true;
    this.shopOrderService.downloadXml(this.order?.id).subscribe(
      (data: any) => {
        let element = document.createElement('a');
        let blob = new Blob([data], {
          type: 'text/xml',
        });
        let url = URL.createObjectURL(blob);
        element.href = url;
        element.setAttribute('download', `${this.order?.id}.xml`);
        document.body.appendChild(element);
        element.click();
        // this.request.downloadFile(response, 'application/xml')
        this.sectional.emit(true);
        this.loadingXml = false;
        this.toast.success('File XML scaricato con successo', 'Ordine');
      },
      (err) => {
        this.loadingXml = false;
        this.toast.error('Errore durante il download del file XML', 'Ordine');
      },
    );
  }

  openSuretyPopover(popOver: any, orderId, item, key, selectItems = []) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.type = key;
    this.popupConfig.popover = popOver;
    this.popupConfig.isSelect = selectItems?.length > 0;
    this.popupConfig.items = selectItems;
    popOver.open();
  }

  setPaid() {
    this.loadingPaid = true;
    this.shopOrderService.setPaid(this.order?.id).subscribe(
      (response: any) => {
        if (response !== false) {
          this.toast.success('Operazione effettuata con successo', 'Ordine pagato');
        }
        this.refresh.emit(false);
        this.modalService.dismissAll();
        this.loadingPaid = false;
      },
      (err) => {
        this.modalService.dismissAll();
        this.loadingPaid = false;
      },
    );
  }

  downloadDdt() {
    window.open(`https://api.master-attivazioni.it/v1/get-ddt?id=${this.order.id}&access_token=${this.token}`);
  }
}
