<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add Brand</h4>
    <button
            type="button"
            class="close"
            aria-label="Close" (click)="dismissModal()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <form [formGroup]="brandForm" (ngSubmit)="saveBrands()">
        <div class="form-row">
            <div class="col-12 col-lg-5 form-group">
                <label for="name" translate>Name</label>
                <input type="text" class="form-control" id="name" formControlName="name">
                <val-errors controlName="name">
                    <ng-template valError="required">
                        <span translate>Name is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-5 form-group">
                <label for="image" translate>Image</label>
                <div class="custom-file" *ngIf="!imageName">
                    <input id="image" accept="image/*" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0])">
                    <label class="custom-file-label" for="image" translate>
                        Upload image...
                    </label>
                </div>
                <div *ngIf="imageName">
                    <span>{{imageName}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button" (click)="handleFileRemoveModal()">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
                <val-errors controlName="image_name">
                    <ng-template valError="required">
                        <span translate>Image is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col form-group d-flex align-items-center">
                <div class="custom-control custom-switch ml-2">
                    <input type="checkbox" class="custom-control-input" id="isActive"
                           formControlName="is_active">
                    <label class="custom-control-label" for="isActive" translate>Active</label>
                </div>
            </div>
            <div class="col-12 form-group">
                <label for="description" translate>Description</label>
                <editor [init]="{base_url: '/tinymce', suffix: '.min'}" id="description" formControlName="description"></editor>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
            </div>
        </div>
    </form>
</div>
