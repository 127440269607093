<div class="modal-body" [ngBusy]="busy">

    <form [formGroup]="shopAccessForm" (ngSubmit)="save()" class="form-height">
        <div class="form-row">
            <div class="col-12 col-lg-4 col-xl-6 form-group mb-xl-2" *ngIf="!data">
                <label translate>Dealer</label>
                <app-retailer-typeahead ngDefaultControl formControlName="dealers" (onChange)="setDealerId($event)"></app-retailer-typeahead>
                <val-errors controlName="dealers">
                    <ng-template valError="required">
                        <span translate>Dealer is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="col-12 col-lg-4 col-xl-6 form-group mb-xl-2" *ngIf="!data">
                <label translate>Service</label>
                <ng-select formControlName="serviceType" [items]="serviceType" bindLabel="lable" id="key"
                           bindValue="key"></ng-select>
                <val-errors controlName="serviceType">
                    <ng-template valError="required">
                        <span translate>Service is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="col-12 form-group mb-xl-2" [ngClass]="{'col-xl-6':!data}">
                <label translate>Tipo Pagamento</label>
                <ng-select formControlName="paymentType" [items]="paymentType" bindLabel="lable" id="key2"
                           bindValue="key"></ng-select>
                <val-errors controlName="paymentType">
                    <ng-template valError="required">
                        <span translate>Payment Type is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="col-12 form-group mb-xl-2" [ngClass]="{'col-xl-6':!data}">
                <label translate>Date Range</label>
                <input formControlName = "dateRange" daterangepicker [options]="pickerOptions" name="date" class="form-control"
                           (selected)="generateDateRangeString($event.start, $event.end)"/>
                <val-errors controlName="dateRange">
                    <ng-template valError="required">
                        <span translate>Date Range is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="col-6 form-group d-flex align-items-center" [ngClass]="{'col-xl-6':!data}">
                <div class="custom-control custom-switch mt-2">
                    <input type="checkbox" class="custom-control-input" id="isCredit" formControlName="autoRenew">
                    <label class="custom-control-label" for="isCredit" translate>Rinnovo Automatico</label>
                </div>
            </div>

            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block" [disabled]="shopAccessForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
                </button>
            </div>
        </div>
    </form>
</div>
